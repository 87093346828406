import { string, object, number, array, bool, mixed } from 'yup';
import { EMPTY_STRING, EMPTY_ARRAY } from '~constants/index';
import { RESOURCE_TYPES } from '~enums/posts';
import {
  compiledAuthorSchema,
  compiledBlockSchema,
  compiledBlockGroupSchema,
  compiledCategorySchema,
  compiledFileSchema,
  compiledSectionSchema,
  compiledTagSchema,
  compiledRegionSchema,
  flagsSchema,
} from '~schemas';
import { advTypeSchema } from '~schemas/advType';
import { CONVERT, NORMALIZE } from '~schemas/castGroups';
import { prioritySchema } from '~schemas/priority';
import { statusSchema } from '~schemas/status';
import { makeDictionary } from '~schemas/transformations/makeDictionary';
import { strippedSchemaWhenNull } from '~schemas/transformations/stripSchemaWhenNull';
import { transformEmptyString } from '~schemas/transformations/transfomEmptyString';
import { normalizeCollection, normalizeOne } from '~schemas/transformations/normalize';
import { typeSchema } from '~schemas/type';
import { castBySchema } from '~utils/castBySchema';
import { isValidBySchema } from '~utils/isValidBySchema';

const RE_LATIN_CHARS_AND_DASH = /^[0-9a-zA-Z\\-]+$/;

/** @type object */
export const compiledPostSchema = object()
  .default(undefined)
  .shape({
    _id: string()
      .default(EMPTY_STRING)
      .required(),
    advType: mixed().when('$castGroup', castGroup => {
      switch (castGroup) {
        case CONVERT:
          return string().transform(normalizeOne('id'));
        case NORMALIZE:
          return string();
        default:
          return advTypeSchema;
      }
    }),
    // yandexTag: mixed().when('$castGroup', castGroup => {
    //   switch (castGroup) {
    //     case CONVERT:
    //       return string().transform(normalizeOne('id'));
    //     case NORMALIZE:
    //       return string();
    //     default:
    //       return advTypeSchema;
    //   }
    // }),
    alias: string()
      .default(EMPTY_STRING)
      .defined()
      .min(1)
      .matches(RE_LATIN_CHARS_AND_DASH),
    authors: array()
      .default(EMPTY_ARRAY)
      .when('$castGroup', (castGroup, schema) => {
        switch (castGroup) {
          case CONVERT:
            return schema.transform(makeDictionary('name'));
          case NORMALIZE:
            return schema.transform(normalizeCollection());
          default:
            return schema.of(compiledAuthorSchema);
        }
      }),
    blockGroups: array(compiledBlockGroupSchema).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    blocks: array(compiledBlockSchema).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    categories: array()
      .default(EMPTY_ARRAY)
      .when('$castGroup', (castGroup, schema) => {
        switch (castGroup) {
          case CONVERT:
            return schema.transform(normalizeCollection());
          case NORMALIZE:
            return schema.of(string());
          default:
            return schema.of(compiledCategorySchema);
        }
      }),
    coauthors: array()
      .default(EMPTY_ARRAY)
      .when('$castGroup', (castGroup, schema) => {
        switch (castGroup) {
          case CONVERT:
            return schema.transform(makeDictionary('name'));
          case NORMALIZE:
            return schema.transform(normalizeCollection());
          default:
            return schema.of(compiledAuthorSchema);
        }
      }),
    createdAt: string().when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    createdBy: object(/* ToDo fill */).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    draftPriorities: array(/* ToDo fill */).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    end: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    flags: flagsSchema,
    flows: array()
      .default(EMPTY_ARRAY)
      .when('$castGroup', (castGroup, schema) => {
        switch (castGroup) {
          case NORMALIZE:
            return schema.of(string()).transform(normalizeCollection());
          default:
            return schema;
        }
      }),
    index: number().when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema.default(0).required();
      }
    }),
    locks: array(/* ToDo fill */).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    note: string().default(EMPTY_STRING),
    popular: bool().when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    priority: mixed().when('$castGroup', castGroup => {
      switch (castGroup) {
        case CONVERT:
          return string().transform(normalizeOne('id'));
        case NORMALIZE:
          return string();
        default:
          return prioritySchema;
      }
    }),
    publicationDate: string().when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    publishedBy: object(/* ToDo fill */).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    relativePosts: array(/* ToDo CompiledPostSchema */).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    resourceType: string()
      .default(EMPTY_STRING)
      .oneOf(RESOURCE_TYPES),
    section: mixed().when('$castGroup', castGroup => {
      switch (castGroup) {
        case CONVERT:
          return string().transform(normalizeOne());
        case NORMALIZE:
          return string();
        default:
          return compiledSectionSchema;
      }
    }),
    start: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    status: statusSchema.when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    tags: array()
      .default(EMPTY_ARRAY)
      .when('$castGroup', (castGroup, schema) => {
        switch (castGroup) {
          case NORMALIZE:
            return schema.of(string()).transform(normalizeCollection());
          default:
            return schema.of(compiledTagSchema);
        }
      }),
    regions: array()
      .default(EMPTY_ARRAY)
      .when('$castGroup', (castGroup, schema) => {
        switch (castGroup) {
          case NORMALIZE:
            return schema.of(string()).transform(normalizeCollection());
          default:
            return schema.of(compiledRegionSchema);
        }
      }),
    type: mixed().when('$castGroup', castGroup => {
      switch (castGroup) {
        case CONVERT:
          return string().transform(normalizeOne('id'));
        case NORMALIZE:
          return string();
        default:
          return typeSchema;
      }
    }),
    unpublishReason: string().when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    updatedAt: string().when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    updatedBy: object(/* ToDo fill */).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    validatedAt: string().when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
    validatedBy: object(/* ToDo fill */).when('$castGroup', (castGroup, schema) => {
      switch (castGroup) {
        case CONVERT:
          return schema.strip();
        case NORMALIZE:
        default:
          return schema;
      }
    }),
  });

export const isValidCompiledPost = isValidBySchema(compiledPostSchema);
export const castByCompiledPostSchema = castBySchema(compiledPostSchema);
