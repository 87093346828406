import { CopyOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Switch } from 'antd';
import { observer } from 'mobx-react';
import { omit } from 'ramda';
import React, { useMemo, useState } from 'react';
import { createModal } from 'react-modal-promise';
import { useHistory } from 'react-router-dom';

import { Role } from '~common';
import { DeleteButton, ItemWithLabel, Modal } from '~components';
import { useDelay, useRolesStore, useUserStore } from '~hooks';

import styles from './styles.module.scss';

const RoleModal = observer(({ isOpen, role, onResolve }) => {
  const [changed, setChanged] = useState(false);
  const { checkPermissions } = useUserStore();
  const { remove, update, setCopyRole } = useRolesStore();
  const history = useHistory();
  const [form] = Form.useForm<Role>();

  const onClose = () => {
    onResolve(changed);
  };

  const openFullSettings = () => {
    history.push(`/roles/${role._id}`);
    onResolve(false);
  };

  const handleDelete = async () => {
    await remove(role._id);
    onResolve(true);
  };

  const onValuesChange = useDelay(async changedValues => {
    await update(role._id, changedValues);
    setChanged(true);
  }, 300);

  const onDuplicateRoleClick = () => {
    setCopyRole(omit(['_id'], role));
    history.push(`/roles/new`);
    onResolve(false);
  };

  const modalButtons = useMemo(() => {
    return (
      <Row justify="center" gutter={[20, 20]} className={styles.buttons}>
        <Col span={24}>
          <Button
            onClick={openFullSettings}
            disabled={!checkPermissions(`roles.update`)}
            type="primary"
          >
            ВСЕ НАСТРОЙКИ
          </Button>
        </Col>
        <Col span={24}>
          <DeleteButton
            text="Удалить роль"
            onDelete={handleDelete}
            disabled={!checkPermissions('roles.delete')}
          />
        </Col>
      </Row>
    );
  }, [checkPermissions, openFullSettings]);

  return (
    <Modal title="БЫСТРЫЕ НАСТРОЙКИ" isVisible={isOpen} onClose={onClose} footer={modalButtons}>
      <Form form={form} initialValues={role} onValuesChange={onValuesChange}>
        <ItemWithLabel label="Название роли">
          <Form.Item name="name">
            <Input
              autoFocus
              value={role}
              disabled={!checkPermissions('roles.update')}
              maxLength={40}
            />
          </Form.Item>
        </ItemWithLabel>
        <Row gutter={[40, 20]}>
          <Col span={12} className={styles.border}>
            <Row>
              <Col span={24}>
                <Row justify="space-between" align="middle">
                  <Col className={styles.active}>Активна</Col>
                  <Col>
                    <Form.Item name="active" valuePropName="checked" style={{ margin: 0 }}>
                      <Switch
                        title="Активна"
                        className={styles.switch}
                        disabled={!checkPermissions('roles.update')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className={styles.description}>
                Переключатель изменяет статус активности роли
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={10}>
              <Col span={24}>
                <Button
                  icon={<CopyOutlined />}
                  type="text"
                  disabled={!checkPermissions('roles.create')}
                  onClick={onDuplicateRoleClick}
                  className={styles.duplicate}
                >
                  <span className={styles.duplicated}>ДУБЛИРОВАТЬ РОЛЬ</span>
                </Button>
              </Col>
              <Col span={24} className={styles.description}>
                Нажмите, чтобы создать новую роль с текущими настройками
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export const roleModal = createModal(RoleModal);
