function debounce(fn: (...args) => void, delay: number) {
  let timerId: NodeJS.Timeout | null;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    // @ts-ignore
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export { debounce };
