import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styles from './styles.scss'

const NavigationItem = ({ path, text }) => (
  <li className={styles.navigationItem}>
    <NavLink to={path} className={styles.navigationItemLink} activeClassName={styles.active}>
      {text}
    </NavLink>
  </li>
)

NavigationItem.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string
}

export default NavigationItem
