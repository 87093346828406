import React, { Component } from 'react'
import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import { SymbolsModal, SymbolsList } from '~components/Symbols'

const PAGE_TITLE = 'Настройка количества символов блоков публикации'

class SettingsSymbolsPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <SymbolsList />
        </ContentWrapper>
        <SymbolsModal />
      </>
    )
  }
}

export default SettingsSymbolsPage
