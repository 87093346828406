import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { ContentWrapper, HeaderPageSearchBar } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import {
  DEPARTMENTS_COUNT_DECLINATIONS,
  DEPARTMENTS_CREATE_BTN_TEXT,
  DEPARTMENTS_PAGE_TITLE,
  DEPARTMENTS_SEARCH_BTN_TEXT,
} from '~constants';
import { DepartmentList, DepartmentsModal } from '~containers';
import {
  useDepartmentsStore,
  useFetchPaginationStore,
  useFilterParamsByListStore,
  useUserStore,
} from '~hooks';

const DepartmentsPage = observer(() => {
  const { setFilterParams } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const {
    setShowModal,
    setIsFirstLoading,
    fetchDepartments,
    allItemsCount,
    departmentsLength,
    isFirstLoading,
  } = useDepartmentsStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    return () => {
      setFilterParams();
      resetPaginationParams();
    };
  }, []);

  const handleSearchPublications = useCallback(() => {
    setIsFirstLoading(true);
    fetchDepartments();
  }, [fetchDepartments, setIsFirstLoading]);

  return (
    <>
      <HeaderPageSearchBar
        searchButtonText={DEPARTMENTS_SEARCH_BTN_TEXT}
        createButtonText={DEPARTMENTS_CREATE_BTN_TEXT}
        onCreateElement={checkPermissions('departments.create') && setShowModal}
        onSearch={handleSearchPublications}
      />
      <ContentWrapper
        title={DEPARTMENTS_PAGE_TITLE}
        countItems={allItemsCount}
        countDeclinations={DEPARTMENTS_COUNT_DECLINATIONS}
      >
        <InfinityScroll
          fetchItems={fetchDepartments}
          hasMore={allItemsCount !== departmentsLength}
          isFetching={isFirstLoading}
        >
          <DepartmentList />
        </InfinityScroll>
      </ContentWrapper>

      <DepartmentsModal />
    </>
  );
});

export default DepartmentsPage;
