import { DatePicker, DatePickerProps } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useState } from 'react';

import ItemWithLabel from '~components/ItemWithLabel';
import { useFetcher, useSettingsStore, useUserStore } from '~hooks';

import s from './styles.scss';

const INPUT_LABEL = 'Дата, с которой действует ограничение на длину заголовков';
const INPUT_PLACEHOLDER = 'Укажите дату';
const DATE_VIEW_FORMAT = 'DD.MM.YYYY';
const DATE_FORMAT = 'YYYY-MM-DD';

export const SettingInputLimitTitlesDate = observer(props => {
  const { globalSettings, updateGlobalSettings } = useSettingsStore();
  const { checkPermissions } = useUserStore();

  const [limitTitlesDate, setLimitTitlesDate] = useState(moment(globalSettings?.limitTitlesDate));
  const [updateSettings, isFetching] = useFetcher(updateGlobalSettings);

  const handleChange: DatePickerProps['onChange'] = date => {
    date &&
      updateSettings({ limitTitlesDate: date?.format(DATE_FORMAT) }).then(() => {
        setLimitTitlesDate(date);
      });
  };

  return (
    <ItemWithLabel outerClass={s.root} label={INPUT_LABEL}>
      <DatePicker
        placeholder={INPUT_PLACEHOLDER}
        disabled={isFetching || !checkPermissions('global.update')}
        allowClear={false}
        value={limitTitlesDate}
        onChange={handleChange}
        format={DATE_VIEW_FORMAT}
      />
    </ItemWithLabel>
  );
});
