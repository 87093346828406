import { useEffect } from 'react';

import { useFetchPaginationStore } from './useFetchPaginationStore';
import { useFilterParamsByListStore } from './useFilterParamsByListStore';

export const useClearHandBooksStores = () => {
  const { resetPaginationParams } = useFetchPaginationStore();
  const { setFilterParams } = useFilterParamsByListStore();

  useEffect(() => {
    return () => {
      setFilterParams();
      resetPaginationParams();
    };
  }, []);
};
