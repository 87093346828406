import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import VideoItem from '~components/Videos/VideoItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

import styles from './styles.scss'

@inject(stores => {
  const { videos, isFirstLoading, isEmptyVideos } = stores.VideosStore

  return {
    videos,
    isFirstLoading,
    isEmptyVideos
  }
})
@observer
class VideoList extends Component {
  render() {
    const { videos, isFirstLoading, isEmptyVideos } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (isEmptyVideos) return <EmptyContent text="Нет видеотрансляций" />

    return (
      <div className={styles.root}>
        <GridWrapper gridSize={4} maxItemWidth={285}>
          {videos.map(video => (
            <VideoItem key={video._id} video={video} />
          ))}
        </GridWrapper>
      </div>
    )
  }
}

export default VideoList

VideoList.propTypes = {
  isFirstLoading: PropTypes.bool,
  isEmptyVideos: PropTypes.bool,
  videos: PropTypes.array
}
