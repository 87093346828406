import { Button, Col, Form, notification, Row, Space, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { InjectedModalProps } from '~common';
import { DiffBlock, Modal } from '~components';
import { useDraftStore, usePublicationStore } from '~hooks';

import { HALF } from '..';
import styles from './styles.scss';

interface IDraftModalProps extends InjectedModalProps {}

type TDecision = 'my' | 'server';
type TDecisions = { [key: string]: TDecision };

interface IDraftDecisions {
  blocks: TDecisions;
  fields: TDecisions;
}

const { Title } = Typography;

const DraftModal: FC<IDraftModalProps> = observer(props => {
  const { isOpen, close } = props;
  const { blocks = [], publicationBlocksContent, apiLayer, resourceType } = usePublicationStore();
  const {
    draftBlocks = [],
    draftSettings = { field: 'settings', lockedBy: null, locketAt: '' },
  } = useDraftStore();
  const [form] = Form.useForm();
  const [isFetching, setIsFetching] = useState(false);

  const renderSettingsDiffs = useCallback(() => {
    if (draftSettings.lockedBy) {
      return (
        <div
          style={{ textAlign: 'center' }}
        >{`Поле Настройки заблокировано пользователем: ${draftSettings.lockedBy.firstName} ${draftSettings.lockedBy.lastName}`}</div>
      );
    }
    return null;
  }, [draftSettings]);

  const renderBlockDiffs = useCallback(
    () =>
      draftBlocks.map((draftBlock, i) => {
        const serverBlock = blocks.find(serverBlock => serverBlock.id === draftBlock.id) || {};
        return (
          <DiffBlock
            key={i}
            resourceType={resourceType}
            leftBlock={draftBlock}
            rightBlock={serverBlock}
          />
        );
      }),
    [draftBlocks, blocks, resourceType],
  );

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleClose = useCallback(() => {
    const draftBlocksToClear = draftBlocks.map(draftBlock => draftBlock.id);
    onClose({ blocks: draftBlocksToClear });
  }, [onClose, draftBlocks]);

  const handleValidate = useCallback(() => {
    form.validateFields().then(async ({ blocks = {} }: IDraftDecisions) => {
      setIsFetching(true);

      const getDecisions = (decisions: TDecisions, decisionToFind: TDecision) =>
        Object.entries(decisions).reduce<string[]>((decisions, [field, decision]) => {
          if (decision === decisionToFind) {
            decisions.push(field);
          }
          return decisions;
        }, []);

      const draftBlocksToClear: string[] = getDecisions(blocks, 'server');
      const draftBlocksToApply = getDecisions(blocks, 'my');
      const draftBlocksLockFailed: string[] = [];

      for (const draftBlockToApplyId of draftBlocksToApply) {
        const draftBlockToApply = draftBlocks.find(
          draftBlock => draftBlock.id === draftBlockToApplyId,
        );
        await apiLayer
          .lockBlock(draftBlockToApplyId)
          .then(
            ({
              data: {
                meta: { success },
              },
            }) => {
              if (success) {
                draftBlockToApply?.setForceUpdate(true);
                publicationBlocksContent.set(draftBlockToApplyId, draftBlockToApply);
                draftBlocksToClear.push(draftBlockToApplyId);
              } else {
                draftBlocksLockFailed.push(draftBlockToApplyId);
              }
            },
          )
          .catch(() => {
            draftBlocksLockFailed.push(draftBlockToApplyId);
          });
      }

      setIsFetching(false);

      if (draftBlocksLockFailed.length > 0) {
        const draftFailedLocks = [...draftBlocksLockFailed]
          .map(draftLockFailed => {
            switch (draftLockFailed) {
              case 'settings':
                return `поле Настройки`;
              default: {
                const draftBlock = draftBlocks.find(
                  draftBlock => draftBlock.id === draftLockFailed,
                );
                return `блок ${draftBlock?.order || -1}`;
              }
            }
          })
          .join(', ');

        notification.warn({
          message: 'Не удалось заблокировать блоки',
          description: `Часть блоков не удалось заблокировать (${draftFailedLocks}). Разблокируйте их или выберите версию на сервере, а затем повторите попытку.`,
          duration: 0,
        });
        return;
      }

      onClose({ blocks: draftBlocksToClear });
    });
  }, [form, draftBlocks, onClose]);

  return (
    <Modal
      title="Черновик"
      width="95%"
      className={styles.draftModal}
      bodyStyle={{ overflow: 'scroll', maxHeight: '80vh' }}
      isVisible={isOpen}
      isFooterHidden={false}
      centered
      footer={[
        <Button key="back" onClick={handleClose}>
          Закрыть
        </Button>,
        <Button key="submit" type="primary" loading={isFetching} onClick={handleValidate}>
          Применить
        </Button>,
      ]}
      onClose={handleClose}
      onOk={handleValidate}
      onCancel={handleClose}
    >
      <Form form={form}>
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
          <Title level={2}>На сервере обнаружено расхождение данных</Title>
        </Space>
        <Row gutter={[16, 16]}>
          <Col span={HALF}>
            <Space direction="vertical" align="center" style={{ width: '100%' }}>
              <Title level={3}>Ваша версия</Title>
            </Space>
          </Col>
          <Col span={HALF}>
            <Space direction="vertical" align="center" style={{ width: '100%' }}>
              <Title level={3}>Версия на сервере</Title>
            </Space>
          </Col>
        </Row>
        {renderSettingsDiffs()}
        {renderBlockDiffs()}
      </Form>
    </Modal>
  );
});

export const openDraftModal = createModal(DraftModal);
