import { DatePicker, TimePicker } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useIsMounted, usePublicationStore } from '~hooks';
import CurrentTime from '../CurrentTime';

import styles from './styles.scss';

const DATE_FORMAT = 'DD.MM.YYYY';
const TIME_FORMAT = 'HH:mm';

const PostponeInput = observer(props => {
  const { publicationDate, setPublicationDate, dictionaries } = usePublicationStore();
  const isMounted = useIsMounted();
  const date = useMemo(() => (publicationDate ? moment(publicationDate) : moment()), [
    publicationDate,
  ]);

  const maxPostponeDate = moment(new Date()).subtract(
    dictionaries?.timers?.postponeRangeSeconds,
    'seconds',
  );

  const [postponeDate, setPostponeDate] = useState(date.format(DATE_FORMAT));
  const [postponeTime, setPostponeTime] = useState(date.format(TIME_FORMAT));

  const setDate = date => {
    setPostponeDate(date.format(DATE_FORMAT));
  };

  const setTime = date => {
    setPostponeTime(date.format(TIME_FORMAT));
  };

  useEffect(() => {
    if (isMounted) {
      const publicationDate = moment(
        `${postponeDate} ${postponeTime}`,
        'DD.MM.YYYY HH:mm',
      ).toISOString();
      setPublicationDate(publicationDate);
    }
  }, [isMounted, postponeDate, postponeTime]);

  return (
    <div>
      <div className={styles.row}>
        <b>Сейчас: </b>
        <CurrentTime />
      </div>
      <div className={styles.row}>
        <b>Опубликовать:</b>
        <DatePicker
          className={styles.input}
          placeholder="Выберите дату"
          allowClear={false}
          disabledDate={currentDate => currentDate < maxPostponeDate}
          value={moment(postponeDate, DATE_FORMAT)}
          onChange={setDate}
          format={DATE_FORMAT}
          size={'small'}
        />
        <TimePicker
          className={styles.input}
          popupClassName={'timepicker'}
          placeholder="Выберите время"
          allowClear={false}
          defaultValue={moment().add(30, 'minutes')}
          value={moment(postponeTime, TIME_FORMAT)}
          onSelect={setTime}
          format={TIME_FORMAT}
          size={'small'}
        />
      </div>
    </div>
  );
});

export default PostponeInput;
