import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import DayPicker from 'react-day-picker'

import Cell from '../SideCalendar/Cell'
import { WEEKDAYS_SHORT, MONTHS, modifiers } from '../SideCalendar'
import ToolBar from '../ToolBar'

import styles from './styles.scss'

import '~stylesheets/calendar/sideCalendar.scss'

@inject(stores => {
  const {
    setSelectedDate,
    selectedDate,
    setCalendarType,
    events,
    fetchEventsByCalendar
  } = stores.ScheduleStore

  return {
    setSelectedDate,
    setCalendarType,
    selectedDate,
    events,
    fetchEventsByCalendar
  }
})
@observer
class YearView extends Component {
  handleDayClick = date => {
    const { setSelectedDate, setCalendarType } = this.props

    setCalendarType('day')
    setSelectedDate(date)
  }

  getModifers = () => {
    const { events } = this.props

    return {
      ...modifiers,
      event: events.map(event => new Date(event.start))
    }
  }

  render() {
    const { selectedDate } = this.props

    const currentYear = selectedDate.getFullYear()

    const modif = this.getModifers()

    return (
      <div className={styles.root}>
        <ToolBar date={selectedDate} />

        <div className={styles.content}>
          <DayPicker
            selectedDays={new Date()}
            onDayClick={this.handleDayClick}
            month={new Date(currentYear, 0)}
            renderDay={Cell}
            firstDayOfWeek={1}
            showOutsideDays
            numberOfMonths={12}
            weekdaysShort={WEEKDAYS_SHORT}
            months={MONTHS}
            modifiers={modif}
          />
        </div>
      </div>
    )
  }
}

export default YearView

YearView.propTypes = {
  events: PropTypes.array,
  selectedDate: PropTypes.string,
  setCalendarType: PropTypes.func,
  setSelectedDate: PropTypes.func
}
