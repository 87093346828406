import PropTypes from 'prop-types'
import React from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import noPhoto from './img/no_photo.jpg'

import styles from './styles.scss'

const ImageLazyLoad = ({ src, width, height, effect }) => {
  return (
    <LazyLoadImage
      height={height}
      src={src || noPhoto}
      width={width}
      effect={effect}
      wrapperClassName={styles.wrapper}
    />
  )
}

export default ImageLazyLoad

ImageLazyLoad.propTypes = {
  effect: PropTypes.string,
  height: PropTypes.number,
  src: PropTypes.string,
  width: PropTypes.number
}

ImageLazyLoad.defaultProps = {
  effect: 'blur',
  height: 160,
  width: '100%'
}
