import { Button, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import ContentSpinner from '~components/ContentSpinner';
import { EMPTY_STRING } from '~constants';
import { useFetchPaginationStore, useFilterParamsByListStore } from '~hooks';

import styles from './styles.scss';

interface IProps {
  checkInterval?: number;
  defaultFilter?: string;
  fetchItems: (defaultFilter?: string) => Promise<any>;
  hasMore: boolean;
  isFetching: boolean;
  threshold?: number;
}

export const InfinityScroll: React.FC<IProps> = observer(
  (props): JSX.Element => {
    const { resetPaginationParams } = useFetchPaginationStore();
    const { setFilterValue } = useFilterParamsByListStore();

    const { children } = props;
    const { isFetching = false } = props;
    const { hasMore = false } = props;
    const { threshold = 2000, checkInterval = 200 } = props;
    const { fetchItems } = props;
    const { defaultFilter = EMPTY_STRING } = props;

    const [hasFetchError, setHasFetchError] = useState(false);
    const [isFetchingNext, setIsFetchingNext] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(hasMore);

    const handleFetchNext = () => {
      setHasFetchError(false);
      setIsFetchingNext(true);
      return fetchItems()
        .then(() => {
          setHasNextPage(hasMore);
        })
        .catch(() => {
          setHasFetchError(true);
          setHasNextPage(false);
        })
        .finally(() => {
          setIsFetchingNext(false);
        });
    };

    const infiniteRef = useInfiniteScroll<HTMLDivElement>({
      hasNextPage,
      loading: isFetchingNext,
      onLoadMore: handleFetchNext,
      threshold,
      checkInterval,
    });

    useEffect(() => {
      resetPaginationParams();
      const [filterKey = null, filterValue = null] = defaultFilter.split('=');
      if (filterKey && filterValue) {
        setFilterValue(filterKey, filterValue);
      }
      fetchItems();
    }, []);

    useEffect(() => {
      setHasNextPage(hasMore);
    }, [hasMore]);

    const Items = () => {
      return (
        <div ref={infiniteRef}>
          {children}
          {hasFetchError && (
            <div className={styles.root}>
              <Button type="primary" onClick={handleFetchNext}>
                Загрузить данные
              </Button>
            </div>
          )}
          {isFetchingNext && (
            <div className={styles.root}>
              <Spin size="large" tip="Идет загрузка..." />
            </div>
          )}
        </div>
      );
    };

    return isFetching ? <ContentSpinner /> : <Items />;
  },
);
