import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import isUrl from 'is-url';
import { observer } from 'mobx-react';
import { prop } from 'ramda';
import React from 'react';

import { ItemWithLabel } from '~components';
import { resourceTypes } from '~constants';
import { usePublicationStore, useUserStore } from '~hooks';
import { getAuthorityByResourceType } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const LinksSelect = observer(() => {
  const { newsLinks, resourceType, set } = usePublicationStore();
  const { checkPermissions } = useUserStore();

  const selectedOptions: LabeledValue[] = newsLinks.map((value: string) => ({
    value,
    label: value,
  }));

  const onChange = (values: LabeledValue[] = []) => {
    set({
      name: 'newsLinks',
      value: values.map(prop('value')).filter(isUrl),
    });
  };

  return (
    <ItemWithLabel label="Ссылки">
      <Select
        mode="tags"
        style={inputStyle}
        placeholder="Введите ссылку"
        value={selectedOptions}
        options={selectedOptions}
        onChange={onChange}
        notFoundContent={null}
        filterOption={false}
        labelInValue
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
