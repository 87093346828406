import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { IBlockComponent } from '~common';
import { Button } from '~components';
import { ButtonShape, ButtonType } from '~constants';

import { BlockButton } from '../../BlockButton';
import styles from './styles.styl';

export interface IAddButtonProps {
  isCreating?: boolean;
  menuButtons?: IBlockComponent[];
  onClick(button: IBlockComponent): void;
}

const AddButton: React.FC<IAddButtonProps> = props => {
  const { menuButtons = [], onClick, isCreating } = props;
  const [isOpen, changeIsOpen] = useState(false);

  const handleClick = () => {
    changeIsOpen(!isOpen);
  };

  return (
    <div className={styles.addButtonWrapper}>
      {isOpen && (
        <>
          <div className={styles.menu}>
            {React.Children.toArray(
              menuButtons.map(button => (
                <BlockButton
                  shape={ButtonShape.Circle}
                  iconNode={button.icon}
                  title={button.blockProps?.title as string}
                  onClick={() => {
                    onClick(button);
                    handleClick();
                  }}
                />
              )),
            )}
          </div>
          <div className={styles.overflow} onClick={handleClick} />
        </>
      )}

      <Button
        className={styles.addButton}
        onClick={handleClick}
        disabled={isCreating}
        shape={ButtonShape.Circle}
        type={ButtonType.Primary}
        ghost
      >
        <PlusOutlined style={{ fontSize: '24px' }} />
      </Button>
    </div>
  );
};

export default AddButton;
