import React, { FC } from 'react';

import Logo from '~assets/logo.svg';

import LoginForm from './components/LoginForm';
import styles from './styles.scss';

export const LoginPage: FC = () => {
  return (
    <div className={styles.loginLayout}>
      <Logo fill="white" />
      <LoginForm />
    </div>
  );
};
