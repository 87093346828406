import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback } from 'react';

import { IRegion } from '~common';
import { WhiteBlockWithShadow } from '~components';
import { useRegionsStore } from '~hooks';
import { Pencil } from '~icons';

import styles from './styles.scss';

interface IProps {
  region: IRegion;
}

const RegionItem = observer((props: IProps) => {
  const { region } = props;
  const { setEditableRegion } = useRegionsStore();

  const handleClickItem = useCallback(() => {
    setEditableRegion(region);
  }, [setEditableRegion, region]);

  const { title, index, createdAt } = region;

  return (
    <WhiteBlockWithShadow>
      <div className={styles.root} onClick={handleClickItem}>
        <div className={styles.ico}>
          <Pencil fill="#D3D3D3" />
        </div>

        <div className={styles.title}>{title}</div>

        <div className={styles.id}>ID {index}</div>

        <div className={styles.date}>{moment(createdAt).format('DD.MM.YYYY')}</div>
      </div>
    </WhiteBlockWithShadow>
  );
});

export default RegionItem;
