import { string, object, number, mixed } from 'yup'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const compiledBlockSchema = object()
  .default(undefined)
  .shape({
    _id: string().required(),
    content: string(),
    createAt: string(),
    index: number(),
    lockedBy: mixed(),
    lockedTo: mixed(),
    post: string(),
    type: string(),
    updateAt: string()
  })

export const isValidCompiledBlock = isValidBySchema(compiledBlockSchema)
