import { CameraOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Spin, Upload } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { IFile } from '~common';
import { useCheckStatusUpload, useFlagsStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { isFileAcceptable } from '~utils';

import styles from './styles.scss';

interface IProps {
  onChange?(data: any): void;
  onClearAvatar?(): void;
  value?: IFile;
}

const AvatarUpload: React.FC<IProps> = observer(props => {
  const { value, onClearAvatar, onChange, ...uploadProps } = props;
  const { fileUploadWorker } = useFlagsStore();

  const token = localStorage.getItem('token') || '';

  const isAvatar = useMemo(() => !!value && value.status !== 'uploading' && value.url, [value]);
  const isDeleteBtnShow = useMemo(() => isAvatar && onClearAvatar, [isAvatar, onClearAvatar]);

  const { checkStatus, status, isFetching } = useCheckStatusUpload(data => {
    onChange &&
      onChange({
        file: {
          response: {
            data,
          },
          status,
        },
      });
  });

  const onChangeHandler = useCallback(
    info => {
      if (info.file.status === 'done') {
        if (fileUploadWorker) {
          checkStatus(info.file.response.data.id);
        } else {
          message.success(`${info.file.name} успешно загружен!`);
          onChange && onChange(info.file.response.data);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} не загружен!`);
      }
    },
    [checkStatus, fileUploadWorker, onChange],
  );

  return (
    <div className={styles.root}>
      <Upload
        name="avatar"
        listType="picture-card"
        headers={{ Authorization: token ? `Bearer ${JSON.parse(token)}` : '' }}
        withCredentials
        showUploadList={false}
        data={{ type: 'avatar' }}
        accept="image/*"
        action={`${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`}
        {...uploadProps}
        onChange={onChangeHandler}
        beforeUpload={async file => {
          return isFileAcceptable(file);
        }}
      >
        {!value?.url && value?.status !== 'uploading' && (
          <CameraOutlined className={styles.addIcon} />
        )}

        {(value?.status === 'uploading' || isFetching) && <Spin className={styles.spin} />}

        {isAvatar && (
          <div className={styles.imgWrapper}>
            <img className={styles.img} src={value?.url} alt="avatar" />
          </div>
        )}
      </Upload>

      {isDeleteBtnShow && (
        <div className={styles.deleteButton} onClick={onClearAvatar}>
          <Button size="small" type="link">
            <DeleteOutlined />
          </Button>
        </div>
      )}
    </div>
  );
});

export default AvatarUpload;
