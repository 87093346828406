import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { IExperiment } from '~common';
import { EmptyContent } from '~components';
import { ExperimentsItem } from '~containers';
import s from '~containers/Redirects/RedirectsList/styles.scss';
import { useExperimentsStore } from '~hooks/useExperimentsStore';

const ExperimentsList = observer(() => {
  const { experimentsItems, isEmptyExperiments } = useExperimentsStore();

  const experimentsList = useMemo(
    () => (
      <>
        <Row align="middle" gutter={24} className={s.headerRow}>
          <Col span={6}>Название эксперимента</Col>
          <Col span={8}>Описание</Col>
          <Col span={10}>Переменные</Col>
        </Row>
        {experimentsItems.map((experiment: IExperiment) => (
          <ExperimentsItem data={experiment} />
        ))}
      </>
    ),
    [experimentsItems],
  );

  if (isEmptyExperiments) return <EmptyContent text="Нет экспериментов" />;

  return <>{experimentsList}</>;
});

export default ExperimentsList;
