import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { IFlow, IRegion } from '~common';
import { ItemWithLabel } from '~components';
import { resourceTypes } from '~constants';
import { usePublicationStore, useRegionsStore, useUserStore } from '~hooks';
import { getAuthorityByResourceType, getUniqueListBy } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const RegionsSelect = observer(() => {
  const { regions, resourceType, set } = usePublicationStore();
  const { checkPermissions } = useUserStore();
  const { fetchRegionsBySearch, regionsSearches } = useRegionsStore();

  const selectedOptions: LabeledValue[] = regions.map((region: IRegion) => ({
    value: region._id,
    label: region.title,
  }));

  const options: LabeledValue[] = getUniqueListBy<LabeledValue>(
    [...regions, ...regionsSearches].map((flow: IFlow) => ({
      value: flow._id,
      label: flow.title,
    })),
    'value',
  );

  const onChange = (regions: LabeledValue[] = []) => {
    set({ name: 'regions', value: regions.map(({ key, label }) => ({ _id: key, title: label })) });
  };

  const onSearch = term => {
    fetchRegionsBySearch(term);
  };

  useEffect(() => {
    fetchRegionsBySearch('');
  }, []);

  return (
    <ItemWithLabel label="Регионы">
      <Select
        mode="multiple"
        maxTagCount={1}
        style={inputStyle}
        placeholder="Поиск регионов"
        value={selectedOptions}
        options={options}
        onChange={onChange}
        onSearch={onSearch}
        notFoundContent={null}
        filterOption={false}
        showSearch
        labelInValue
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
