import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import GridWrapper from '~components/GridWrapper';
import SpecProjectsItem from '~components/SpecProjects/SpecProjectsItem';
import AddNew from '~components/AddNew';
import { useSpecProjectsStore, useUserStore } from '~hooks';

const SortableItem = sortableElement(({ project }) => <SpecProjectsItem data={project} />);

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  );
});

const SpecProjectsList = observer(() => {
  const { specProjects, setShowModal, changeOrdering } = useSpecProjectsStore();

  const { checkPermissions } = useUserStore();

  return (
    <SortableContainer
      distance={1}
      axis="xy"
      onSortEnd={checkPermissions('specProjects.update') ? changeOrdering : undefined}
    >
      {checkPermissions('specProjects.create') && <AddNew onClick={setShowModal} />}
      {specProjects.map(project => (
        <SortableItem key={project._id} project={project} index={project.order} />
      ))}
    </SortableContainer>
  );
});

SpecProjectsList.propTypes = {
  SpecProjectsStore: PropTypes.any,
};

export default SpecProjectsList;
