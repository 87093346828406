import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Checkbox, Button } from 'antd';

import DeleteButton from '~components/DeleteButton';
import ItemWithLabel from '~components/ItemWithLabel';
import Modal from '~components/Modal';
import s from './styles.scss';

const CREATE_TITLE = 'Создание плейлиста';
const UPDATE_TITLE = 'Редактирование плейлиста';

@inject('PlaylistsStore', 'UserStore')
@observer
class PlaylistsModal extends Component {
  handleSubmit = e => {
    e.preventDefault();

    const {
      PlaylistsStore: { editablePlaylist, updatePlaylist, createPlaylist },
      form,
    } = this.props;

    const formData = form.getFieldsValue();
    const playlist = { data: { ...formData } };
    const action = editablePlaylist ? updatePlaylist : createPlaylist;

    action(playlist);
  };

  render() {
    const {
      PlaylistsStore: {
        setHideModal,
        isShowModal,
        resetEditablePlaylist,
        editablePlaylist,
        deletePlaylist,
      },
      UserStore: { checkPermissions },
      form,
    } = this.props;
    const { getFieldDecorator } = form;
    const ModalTitle = editablePlaylist ? UPDATE_TITLE : CREATE_TITLE;

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={ModalTitle}
        afterClose={resetEditablePlaylist}
      >
        <Form onSubmit={this.handleSubmit}>
          <ItemWithLabel label="Название">
            <Form.Item>
              {getFieldDecorator('title', {
                initialValue: editablePlaylist && editablePlaylist.title,
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(<Input placeholder="Название плейлиста" />)}
            </Form.Item>
          </ItemWithLabel>

          <Form.Item>
            {getFieldDecorator('visible', {
              initialValue: (editablePlaylist && editablePlaylist.visible) || false,
              valuePropName: 'checked',
            })(<Checkbox>Видимость</Checkbox>)}
          </Form.Item>

          <div className={s.modalControls}>
            <Button
              htmlType="submit"
              type="primary"
              disabled={!checkPermissions(`playlists.${editablePlaylist ? 'update' : 'create'}`)}
            >
              Сохранить изменения
            </Button>
            {editablePlaylist && (
              <div className={s.deleteBtnWrapper}>
                <DeleteButton
                  text="Удалить плейлист"
                  onDelete={deletePlaylist}
                  disabled={!checkPermissions('playlists.delete')}
                />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    );
  }
}

PlaylistsModal.propTypes = {
  PlaylistsStore: PropTypes.any,
  form: PropTypes.any,
};

export default Form.create({ name: 'playlistsForm' })(PlaylistsModal);
