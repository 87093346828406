import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Button, DatePicker, Input, TimePicker, message } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { EMPTY_STRING } from '~constants';
import { showErrorNotification } from '~utils';

import ParticipantsItem from './ParticipantsItem';
import ItemWithLabel from '~components/ItemWithLabel';
import DeleteButton from '~components/DeleteButton';
import SelectRender from '~components/Publications/Filter/SelectRender';
import { AuthorsSelect } from '~containers';

import styles from './styles.scss';

const { TextArea } = Input;

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'DD.MM.YYYY';

let participantsId = 0;

@inject(stores => {
  const {
    isShowModalEdit,
    setHideModalEdit,
    editableEvent,
    resetEditableEvent,
    updateEvent,
    createEvent,
    deleteEvent,
    isActionModal,
    isDuplicateEvent,
  } = stores.ScheduleStore;

  const { checkPermissions } = stores.UserStore;

  return {
    isShowModalEdit,
    setHideModalEdit,
    editableEvent,
    resetEditableEvent,
    updateEvent,
    createEvent,
    deleteEvent,
    isActionModal,
    isDuplicateEvent,
    checkPermissions,
  };
})
@observer
class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstSave: false,
    };
  }

  componentDidMount() {
    const { validateFields } = this.props.form;

    this.textAreaAdjust();

    validateFields();

    const startDate = this.props.form.getFieldValue('startDate');

    if (!startDate) {
      this.setState({
        firstSave: true,
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    const { form, updateEvent, editableEvent, createEvent, isDuplicateEvent } = this.props;

    if (
      this.state.firstSave &&
      !form.getFieldValue('checkOutDateDate') &&
      !form.getFieldValue('checkOutDateTime')
    ) {
      const checkoutDateDate = form
        .getFieldValue('startDate')
        .clone()
        .startOf('minute')
        .subtract(1, 'hours');
      const checkoutDateTime = form
        .getFieldValue('startTime')
        .clone()
        .startOf('minute')
        .subtract(1, 'hours');

      form.setFieldsValue({
        checkOutDateDate: checkoutDateDate,
        checkOutDateTime: checkoutDateTime,
      });
    }

    const formData = form.getFieldsValue();

    const start = formData.startDate
      .set({
        hour: formData.startTime.format('HH'),
        minute: formData.startTime.format('mm'),
      })
      .toISOString();

    const end = formData.endDate
      .set({
        hour: formData.endTime.format('HH'),
        minute: formData.endTime.format('mm'),
      })
      .toISOString();

    const checkOutDate =
      formData.checkOutDateDate &&
      formData.checkOutDateDate
        .set({
          hour: formData.checkOutDateTime.format('HH'),
          minute: formData.checkOutDateTime.format('mm'),
        })
        .toISOString();

    const event = {
      data: {
        address: formData.address || EMPTY_STRING,
        author: formData.author || null,
        car: formData.car || EMPTY_STRING,
        checkOutDate,
        description: formData.description || EMPTY_STRING,
        end,
        equipment: formData.equipment || EMPTY_STRING,
        format: formData.format || EMPTY_STRING,
        operator: formData.operator || null,
        participants: formData.participants || [],
        start,
        title: formData.title || EMPTY_STRING,
        types: formData.types || [],
        correspondent: formData.correspondent || null,
      },
    };

    let action = createEvent;

    if (isDuplicateEvent) {
      action = createEvent;
    }

    if (editableEvent && !isDuplicateEvent) {
      action = updateEvent;
    }

    action(event)
      .then(() => {
        message.success('Успешно!');
      })
      .catch(reason => {
        showErrorNotification('Ошибка сохранения изменений', reason);
      });
  };

  handleAddParticipants = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');

    const nextKeys = keys.concat((participantsId += 1));

    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleDeleteParticipants = index => {
    const { form } = this.props;

    const keys = form.getFieldValue('keys');

    form.setFieldsValue({
      keys: keys.filter((key, idx) => idx !== index),
    });

    form.resetFields([`participants[${index}].name`]);
  };

  getDisabledHours = () => {
    return [0, 1, 2, 3, 4];
  };

  hasErrors = fieldsError => {
    const participantsErrArr = get(fieldsError, 'participants', []);

    const justFieldError = Object.keys(fieldsError).some(
      field => field !== 'participants' && fieldsError[field],
    );
    const participantsError =
      !!participantsErrArr.length && participantsErrArr.some(field => field.name);

    return justFieldError || participantsError;
  };

  textAreaAdjust = () => {
    const el = document.getElementById('eventForm_description');

    setTimeout(() => {
      el.style.cssText = 'height:auto;';
      // for box-sizing other than "content-box" use:
      // el.style.cssText = '-moz-box-sizing:content-box';
      el.style.cssText = `height:${el.scrollHeight + 5}px`;
    }, 0);
  };

  render() {
    const {
      form,
      editableEvent,
      deleteEvent,
      isActionModal,
      isDuplicateEvent,
      checkPermissions,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldValue,
      getFieldsError,
      isFieldTouched,
      getFieldError,
    } = form;

    const startDate = get(editableEvent, 'start', null) ? moment(editableEvent.start) : null;
    const endDate = get(editableEvent, 'end', null) ? moment(editableEvent.end) : null;

    const checkOutDateDate = get(editableEvent, 'checkOutDate', null)
      ? moment(editableEvent.checkOutDate)
      : null;

    const titleError = isFieldTouched('title') && getFieldError('title');

    const startDateError = isFieldTouched('startDate') && getFieldError('startDate');
    const startTimeError = isFieldTouched('startTime') && getFieldError('startTime');

    const endDateError = isFieldTouched('endDate') && getFieldError('endDate');
    const endTimeError = isFieldTouched('endTime') && getFieldError('endTime');

    const checkOutDateDateError =
      isFieldTouched('checkOutDateDate') && getFieldError('checkOutDateDate');
    const checkOutDateTimeError =
      isFieldTouched('checkOutDateTime') && getFieldError('checkOutDateTime');

    const participants = get(editableEvent, 'participants', []);

    getFieldDecorator('keys', { initialValue: participants });

    return (
      <div className={styles.root}>
        <Form onSubmit={this.handleSubmit}>
          <div className={styles.forms}>
            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Наименование мероприятия *">
                <Form.Item validateStatus={titleError ? 'error' : ''} help={titleError || ''}>
                  {getFieldDecorator('title', {
                    initialValue: get(editableEvent, 'title', ''),
                    rules: [{ required: true, message: 'Обязательное поле!' }],
                  })(<Input disabled={isActionModal} />)}
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Дата и время начала *">
                <Input.Group compact style={{ display: 'flex' }}>
                  <Form.Item
                    validateStatus={startDateError ? 'error' : ''}
                    help={startDateError || ''}
                  >
                    {getFieldDecorator('startDate', {
                      initialValue: startDate,
                    })(
                      <DatePicker
                        disabled={isActionModal}
                        format={DATE_FORMAT}
                        placeholder="Дата"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item
                    validateStatus={startTimeError ? 'error' : ''}
                    help={startTimeError || ''}
                  >
                    {getFieldDecorator('startTime', {
                      initialValue: startDate,
                      rules: [{ required: true, message: 'Обязательное поле!' }],
                    })(
                      <TimePicker
                        disabled={isActionModal}
                        disabledHours={this.getDisabledHours}
                        format={TIME_FORMAT}
                        placeholder="Время"
                        minuteStep={10}
                      />,
                    )}
                  </Form.Item>
                </Input.Group>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Дата и время окончания мероприятия *">
                <Input.Group compact style={{ display: 'flex' }}>
                  <Form.Item validateStatus={endDateError ? 'error' : ''} help={endDateError || ''}>
                    {getFieldDecorator('endDate', {
                      initialValue: endDate,
                      rules: [{ required: true, message: 'Обязательное поле!' }],
                    })(
                      <DatePicker
                        disabled={isActionModal}
                        format={DATE_FORMAT}
                        placeholder="Дата"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item validateStatus={endTimeError ? 'error' : ''} help={endTimeError || ''}>
                    {getFieldDecorator('endTime', {
                      initialValue: endDate,
                      rules: [{ required: true, message: 'Обязательное поле!' }],
                    })(
                      <TimePicker
                        disabled={isActionModal}
                        format={TIME_FORMAT}
                        placeholder="Время"
                        minuteStep={10}
                      />,
                    )}
                  </Form.Item>
                </Input.Group>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Типы события">
                <Form.Item>
                  {getFieldDecorator('types', {
                    initialValue: get(editableEvent, 'types', []).map(item => item._id),
                  })(
                    <SelectRender
                      mode="multiple"
                      maxTagCount={1}
                      disabled={isActionModal}
                      optionsChildren={data => (
                        <div className={styles.typeOption}>
                          {data.title}

                          <div className={styles.color} style={{ background: data.color }} />
                        </div>
                      )}
                      item={{
                        type: 'select',
                        name: 'filter.eventType',
                      }}
                    />,
                  )}
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Формат">
                <Form.Item>
                  {getFieldDecorator('format', {
                    initialValue: get(editableEvent, 'format'),
                  })(<Input disabled={isActionModal} />)}
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Дата и время выезда">
                <Input.Group compact style={{ display: 'flex' }}>
                  <Form.Item
                    validateStatus={checkOutDateDateError ? 'error' : ''}
                    help={checkOutDateDateError || ''}
                  >
                    {getFieldDecorator('checkOutDateDate', {
                      initialValue: checkOutDateDate || startDate,
                    })(
                      <DatePicker
                        disabled={isActionModal}
                        format={DATE_FORMAT}
                        placeholder="Дата"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item
                    validateStatus={checkOutDateTimeError ? 'error' : ''}
                    help={checkOutDateTimeError || ''}
                  >
                    {getFieldDecorator('checkOutDateTime', {
                      initialValue: checkOutDateDate || startDate,
                    })(
                      <TimePicker
                        disabled={isActionModal}
                        format={TIME_FORMAT}
                        placeholder="Время"
                        minuteStep={10}
                      />,
                    )}
                  </Form.Item>
                </Input.Group>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Кореспондент">
                <Form.Item>
                  {getFieldDecorator('correspondent', {
                    initialValue: get(editableEvent, 'correspondent._id'),
                  })(
                    <SelectRender
                      disabled={isActionModal}
                      optionsChildren={data => (
                        <div>
                          {data.firstName} {data.lastName}
                        </div>
                      )}
                      filter="filter.position=EMPLOYEE_POSITION_PRODUCER"
                      item={{
                        type: 'select',
                        name: 'employees',
                      }}
                    />,
                  )}
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Оператор">
                <Form.Item>
                  {getFieldDecorator('operator', {
                    initialValue: get(editableEvent, 'operator._id'),
                  })(
                    <SelectRender
                      disabled={isActionModal}
                      filter="filter.position=EMPLOYEE_POSITION_PRODUCER"
                      optionsChildren={data => (
                        <div>
                          {data.firstName} {data.lastName}
                        </div>
                      )}
                      item={{
                        type: 'select',
                        name: 'employees',
                      }}
                    />,
                  )}
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Автор">
                <Form.Item>
                  {getFieldDecorator('author', {
                    initialValue: get(editableEvent, 'author._id'),
                  })(<AuthorsSelect disabled={isActionModal} />)}
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Оборудование">
                <Form.Item>
                  {getFieldDecorator('equipment', {
                    initialValue: get(editableEvent, 'equipment'),
                  })(<Input disabled={isActionModal} />)}
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Автомобиль">
                <Form.Item>
                  {getFieldDecorator('car', {
                    initialValue: get(editableEvent, 'car'),
                  })(<Input disabled={isActionModal} />)}
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper} />
          </div>

          <ItemWithLabel label="Место">
            <Form.Item>
              {getFieldDecorator('address', {
                initialValue: get(editableEvent, 'address'),
              })(<Input disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Участники">
            {getFieldValue('keys').map((item, index) => (
              <ParticipantsItem
                key={index}
                getFieldDecorator={getFieldDecorator}
                index={index}
                item={item}
                onDeleteParticipant={this.handleDeleteParticipants}
                isShowDeleteButton
                form={form}
                disabled={isActionModal}
              />
            ))}

            <div className={styles.addParticipants}>
              <Button
                size="small"
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={this.handleAddParticipants}
              />
            </div>
          </ItemWithLabel>

          <ItemWithLabel label="Описание">
            <Form.Item>
              {getFieldDecorator('description', {
                initialValue: get(editableEvent, 'description'),
              })(
                <TextArea
                  id="textArea"
                  onKeyDown={this.textAreaAdjust}
                  onPaste={this.textAreaAdjust}
                  disabled={isActionModal}
                  placeholder="Примечание"
                  style={{
                    width: '100%',
                  }}
                />,
              )}
            </Form.Item>
          </ItemWithLabel>

          <div className={styles.modalControls}>
            <Button
              loading={isActionModal}
              htmlType="submit"
              type="primary"
              disabled={
                this.hasErrors(getFieldsError()) ||
                !checkPermissions(`events.${isDuplicateEvent ? 'create' : 'update'}`)
              }
            >
              {isDuplicateEvent ? 'Создать дубликат' : 'Сохранить изменения'}
            </Button>

            {editableEvent && !isDuplicateEvent && (
              <div className={styles.deleteBtnWrapper}>
                <DeleteButton text="Удалить съемку" onDelete={deleteEvent} />
              </div>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const WrappedEventsForm = Form.create({ name: 'eventForm' })(ModalWrapper);

export default WrappedEventsForm;

ModalWrapper.propTypes = {
  createEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
  editableEvent: PropTypes.func,
  form: PropTypes.any,
  isActionModal: PropTypes.bool,
  isDuplicateEvent: PropTypes.any,
  isShowModalEdit: PropTypes.bool,
  resetEditableEvent: PropTypes.func,
  setHideModalEdit: PropTypes.func,
  updateEvent: PropTypes.func,
};
