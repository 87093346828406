import { Button, Checkbox, Form, Input } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { IFile, InjectedModalProps, ISubCategory } from '~common';
import { ButtonUpload, DeleteButton, ItemWithLabel, Modal } from '~components';
import SelectRender from '~components/Publications/Filter/SelectRender';
import { EMPTY_STRING } from '~constants';
import styles from '~containers/Tags/TagModal/styles.scss';
import { useSubcategoriesStore, useUserStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { getUrlFromFile } from '~utils';

import s from './styles.scss';

const CREATE_TITLE = 'Создание новой подкатегории';
const UPDATE_TITLE = 'Редактирование подкатегории';

const SubcategoriesModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;
  const {
    subcategories,
    editableSubcategory,
    isActionModal,
    resetEditableSubcategory,
    createSubcategory,
    updateSubcategoryById,
    deleteSubcategoryById,
  } = useSubcategoriesStore();
  const { checkPermissions } = useUserStore();
  const [form] = Form.useForm<ISubCategory>();

  const [cover, setCover] = useState(editableSubcategory?.cover?.url || EMPTY_STRING);

  const fileUploadProps = useMemo(
    () => ({
      name: 'file',
      action: `${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`,
      withCredentials: true,
      data: { type: 'card' },
      accept: 'image/*',
    }),
    [FileTransportLayer.fileUploadUrl],
  );

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const subcategory = {
        data: {
          ...values,
          cover: getUrlFromFile(values?.cover),
        },
        meta: {
          before: subcategories.length > 0 ? subcategories[0]?._id : null,
        },
      };
      if (editableSubcategory) {
        updateSubcategoryById(subcategory).then(handleClose);
      } else {
        createSubcategory(subcategory).then(handleClose);
      }
    });
  }, [editableSubcategory]);

  const handleDelete = () => {
    deleteSubcategoryById().then(handleClose);
  };

  const handleClose = useCallback(() => {
    resetEditableSubcategory();
    form.resetFields();
    onClose(true);
  }, [form]);

  const ModalTitle = editableSubcategory ? UPDATE_TITLE : CREATE_TITLE;

  return (
    <Modal destroyOnClose width={590} onClose={handleClose} isVisible={isOpen} title={ModalTitle}>
      <Form form={form}>
        <ItemWithLabel label="Категория">
          <Form.Item
            name="category"
            initialValue={get(editableSubcategory, 'category._id')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <SelectRender
              item={{
                label: 'Категории',
                type: 'select',
                name: 'filter.categories',
              }}
            />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Название подкатегории">
          <Form.Item
            name="title"
            initialValue={get(editableSubcategory, 'title')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите название" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Описание подкатегории">
          <Form.Item name="description" initialValue={get(editableSubcategory, 'description')}>
            <Input placeholder="Введите описание" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Псевдоним подкатегории (латиница)">
          <Form.Item name="alias" initialValue={get(editableSubcategory, 'alias')}>
            <Input placeholder="Будет создан автоматически" disabled />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="SEO заголовок">
          <Form.Item
            name={['seo', 'title']}
            initialValue={get(editableSubcategory, 'seo.title')}
            rules={[{ required: false }]}
          >
            <Input placeholder="Введите SEO заголовок" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="SEO описание">
          <Form.Item
            name={['seo', 'description']}
            initialValue={get(editableSubcategory, 'seo.description')}
            rules={[{ required: false }]}
          >
            <Input placeholder="Введите SEO описание" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Обложка">
          <>
            {cover && (
              <div>
                <img className={styles.cardItemCover} src={cover} alt="Cover" />
              </div>
            )}
            <Form.Item name="cover" initialValue={get(editableSubcategory, 'cover')}>
              <ButtonUpload
                uploadProps={fileUploadProps}
                onChange={(data: IFile) => {
                  setCover(data?.url || EMPTY_STRING);
                }}
              />
            </Form.Item>
          </>
        </ItemWithLabel>

        <Form.Item
          name="visible"
          initialValue={get(editableSubcategory, 'visible') || false}
          valuePropName="checked"
        >
          <Checkbox>Опубликована на сайте</Checkbox>
        </Form.Item>

        <div className={s.modalControls}>
          <Button
            onClick={handleSubmit}
            type="primary"
            loading={isActionModal}
            disabled={
              !checkPermissions(`subcategories.${editableSubcategory ? 'update' : 'create'}`)
            }
          >
            Сохранить изменения
          </Button>
          {editableSubcategory && (
            <div className={s.deleteBtnWrapper}>
              <DeleteButton
                text="Удалить подкатегорию"
                onDelete={handleDelete}
                disabled={!checkPermissions(`subcategories.delete`)}
              />
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
});

export const openSubcategoriesModal = createModal(SubcategoriesModal);
