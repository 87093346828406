import PropTypes from 'prop-types'
import React from 'react'

import classNames from 'classnames'
import styles from './styles.scss'

import FilterIcon from '~assets/icons/filter.svg'
import CloseIcon from '~assets/icons/close.svg'

const FilterToggleControl = ({ label, onClick, isActive, isHideMobile, wrapperStyle = {} }) => {
  return (
    <div
      className={classNames(styles.root, isHideMobile && styles.isHideMobile)}
      style={wrapperStyle}
      onClick={onClick}
    >
      {label}
      <div className={styles.ico}>
        {isActive ? <CloseIcon width={15} /> : <FilterIcon width={15} />}
      </div>
    </div>
  )
}

export default FilterToggleControl

FilterToggleControl.defaultProps = {
  label: 'Все фильтры'
}
FilterToggleControl.propTypes = {
  isActive: PropTypes.bool,
  isHideMobile: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  wrapperStyle: PropTypes.object
}
