import { observable, action, computed } from 'mobx';

import API from '~api';
import FetchPaginationStore from './fetchPaginationStore';
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists';
import FilterParamsByListStore from './filterParamsByList';

const CHANGE_LOG_URL = '/history';

export class ChangeLogStore {
  @observable isShowModal = false;

  @observable isFirstLoading = true;

  @observable allItemsCount = 0;

  @observable isLoadingMore = false;

  @observable editableChangeLog = null;

  @observable changeLogs = [];

  @observable isShowFilter = this.getIsShowFilterFromUrlParams();

  @observable selectedFilterItems = [];

  @observable filtersItem = [
    {
      label: 'Сотрудник',
      type: 'select',
      name: 'filter.employee',
      fix: true,
    },
    {
      label: 'Тип',
      type: 'select',
      name: 'filter.resourceType',
      fix: true,
    },
    {
      label: 'Идентификаторо ресурса',
      type: 'string',
      name: 'filter.resourceId',
      fix: true,
    },
    {
      label: 'Дата создания',
      type: 'dateRange',
      name: 'filter.createdAt',
      fix: true,
    },
  ];

  @action
  getIsShowFilterFromUrlParams = () => {
    const { filterParams } = FilterParamsByListStore;

    const filter = Object.keys(filterParams).filter(item => item !== 'search');

    return !!filter.length;
  };

  @action
  fetchChangeLog = () => {
    const { offset } = FetchPaginationStore;
    const { getPromiseUrl, SET_FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore;

    SET_FILTER_PARAMS_FOR_BACK({
      'filter.changes': params => `elemMatch({"field": "${params}"})`,
    });

    const promiseUrl = getPromiseUrl({
      url: CHANGE_LOG_URL,
    });

    const fetchChangeLogPromise = API.get(promiseUrl);

    this.setIsLoadingMore(true);
    return fetchChangeLogPromise
      .then(res => {
        const { data, meta } = res.data;

        if (offset === 0) {
          this.setChangeLogs(data);
        } else {
          this.setChangeLogs([...this.changeLogs, ...data]);
        }

        this.setAllItemsCount(meta.count);
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      })
      .catch(() => {
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      });
  };

  @computed
  get isEmptyChangeLog() {
    return !this.changeLogs.length;
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value;
  };

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value;
  };

  @action
  setChangeLogs = data => {
    this.changeLogs = data;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @action
  toggleIsShowFilter = () => {
    this.isShowFilter = !this.isShowFilter;
  };

  @action
  removeFileItem = item => {
    this.selectedFilterItems.remove(item);
  };
}

export default new ChangeLogStore();
