import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { IRedirect } from '~common';
import { EmptyContent } from '~components';
import { RedirectsItem } from '~containers/Redirects';
import { useRedirectsStore } from '~hooks';

import s from './styles.scss';

const RedirectsList = observer(() => {
  const { redirectsItems, isEmptyRedirects } = useRedirectsStore();

  const redirectsList = useMemo(
    () => (
      <>
        <Row align="middle" gutter={24} className={s.headerRow}>
          <Col span={4}>Исходная ссылка</Col>
          <Col span={4}>Целевая ссылка</Col>
          <Col span={5}>Описание</Col>
          <Col span={3}>Дата</Col>
          <Col span={4}>Тип перемещения</Col>
          <Col span={4}>Статус</Col>
        </Row>
        {redirectsItems.map((redirect: IRedirect) => (
          <RedirectsItem data={redirect} />
        ))}
      </>
    ),
    [redirectsItems],
  );

  if (isEmptyRedirects) return <EmptyContent text="Нет редиректов" />;

  return <>{redirectsList}</>;
});

export default RedirectsList;
