import { convertToHTML } from 'draft-convert';
import {
  ContentBlock,
  ContentState,
  convertFromRaw,
  DraftInlineStyleType,
  RawDraftEntity,
} from 'draft-js';
import { allPass, identity, ifElse, is, pipe, replace, tryCatch } from 'ramda';
import React from 'react';

import { isNotNil } from './functional';

const styleToHTML = (style: DraftInlineStyleType) => {
  const [type, color] = style.split('-');

  switch (type) {
    case 'ITALIC':
      return <em style={{ fontStyle: 'italic' }} />;
    case 'BOLD':
      return <strong style={{ fontWeight: 'bold' }} />;
    case 'HIGHLIGHT':
      return <strong className="highlight" />;
    case 'STRIKETHROUGH':
      return <span style={{ textDecoration: 'line-through' }} />;
    case 'SUBSCRIPT':
      return <span style={{ verticalAlign: 'sub', fontSize: '70%' }} />;
    case 'SUPERSCRIPT':
      return <span style={{ verticalAlign: 'super', fontSize: '70%' }} />;
    case 'color':
      return <span style={{ color: `#${color}` }} />;
    default:
      return null;
  }
};

const blockToHTML = (block: ContentBlock) => {
  // @ts-ignore
  const { data } = block;

  if (data.textAlignment) {
    // @ts-ignore
    return <p style={{ textAlignment: data.textAlignment }} />;
  }
  return null;
};

const entityToHTML = (entity: RawDraftEntity, text: string) => {
  if (entity.type === 'LINK') {
    return (
      <a className="link" href={entity.data.url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  }
  return text;
};

export const options = {
  styleToHTML,
  blockToHTML,
  entityToHTML,
};

const converterFunction = convertToHTML(options);

export const convertFromDraft = tryCatch(
  pipe(
    ifElse(allPass([is(String), isNotNil]), JSON.parse, identity),
    convertFromRaw,
    converterFunction,
    replace(/"/gi, "'"),
    replace(/&amp;quot;/gi, '"'),
  ),
  (err, caption) => caption,
);

export const toHTML = (contentState: ContentState) => converterFunction(contentState);

export const toHTMLTry = tryCatch(toHTML, e => {
  // eslint-disable-next-line no-console
  console.error(e);

  return null;
});
