import { observer } from 'mobx-react';
import React from 'react';

import { ITag } from '~common';
import { ISelectProps, Select } from '~components';
import { useTagsStore } from '~hooks';

export const TagsSelect: React.FC<ISelectProps<ITag>> = observer(props => {
  const { fetchTagsBySearch, tagsSearches } = useTagsStore();

  return (
    <Select
      {...props}
      fetch={fetchTagsBySearch}
      options={tagsSearches}
      keyField="_id"
      labelField="title"
    />
  );
});
