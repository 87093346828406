import { message } from 'antd';
import { action, observable, runInAction } from 'mobx';

import API from '~api';
import { showErrorNotificationInPromise } from '~utils';

const SETTINGS_GLOBAL_URL = '/settings/global';
const SETTINGS_POST_FOOTER_URL = '/settings/postFooter';
const SETTINGS_PROMO_URL = '/settings/promo';
const SETTINGS_LOGO_URL = '/settings/logo';
const SETTINGS_QUERY_ORDER_URL = '/settings/queryOrder';

export class SettingsGlobalStore {
  @observable globalSettings = {};

  @observable postId = '';

  @observable promoId = '';

  @observable logoType = 'default';

  @observable isFirstLoading = true;

  @observable isPulsating = false;

  @observable queryOrderTypes = [];

  @action
  fetchGlobalSettings = () => {
    const promiseGlobal = API.get(SETTINGS_GLOBAL_URL);
    const promisePostId = API.get(SETTINGS_POST_FOOTER_URL);
    const promisePromoId = API.get(SETTINGS_PROMO_URL);

    const promises = Promise.all([promiseGlobal, promisePostId, promisePromoId]);

    return promises
      .then(res => {
        const { data } = res[0].data;
        const { data: post } = res[1].data;
        const { data: promo } = res[2].data;
        runInAction(() => {
          this.globalSettings = data;
          this.isFirstLoading = false;
          this.postId = post.post;
          this.promoId = promo.post;
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка загрузки глобальных настроек',
          callback: () => {
            this.isFirstLoading = false;
          },
        }),
      );
  };

  @action
  fetchQueryOrderTypesDictionary = () => {
    const promise = API.get('dictionaries/queryOrderTypes');

    return promise
      .then(res => {
        const { data } = res;

        this.queryOrderTypes = data.queryOrderTypes;
      })
      .catch(() => {
        message.error('Ошибка загрузки настроек поиска');
      });
  };

  @action
  updateGlobalSettings = settings => {
    const promise = API.patch(SETTINGS_GLOBAL_URL, { data: settings });
    return promise
      .then(res => {
        const { data } = res.data;

        this.globalSettings = data;
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка обновления настроек' }));
  };

  @action
  fetchQueryOrderTypesSettings = () => {
    const promise = API.get('settings/queryOrder');

    return promise
      .then(res => {
        const { data } = res;

        if (data.data.queryTypes.length <= 0) {
          this.fetchQueryOrderTypesDictionary();
        }
        this.queryOrderTypes = data.data.queryTypes;
      })
      .catch(() => {
        message.error('Ошибка загрузки настроек поиска');
      });
  };

  updateQueryOrder = queryTypes => {
    const promise = API.patch(SETTINGS_QUERY_ORDER_URL, { data: { queryTypes } });

    return promise
      .then(() => {
        message.success('Новый порядок поиска установлен успешно');
      })
      .catch(() => {
        message.error('Ошибка установки порядка поиска');
      });
  };

  updateIdPost = () => {
    const promise = API.patch(SETTINGS_POST_FOOTER_URL, { data: { post: this.postId } });

    return promise;
  };

  deleteIdPost = () => {
    const promise = API.delete(SETTINGS_POST_FOOTER_URL);

    return promise;
  };

  @action
  setPostId = value => {
    this.postId = value;
  };

  @action
  updatePromoId = promoId => {
    const promise = API.patch(SETTINGS_PROMO_URL, { data: { post: promoId } });

    return promise
      .then(() => {
        message.success('ID промо-материала успешно установлен');
        this.promoId = promoId;
      })
      .catch(({ response: { data: { error: { message: errorMessage } = {} } = {} } = {} }) => {
        message.error(`Ошибка установки ID промо-материала: ${errorMessage}`);
      });
  };

  @action deletePromoId = () => {
    const promise = API.delete(SETTINGS_PROMO_URL);

    return promise
      .then(() => {
        message.success('ID промо-материала успешно удален');
        this.promoId = '';
      })
      .catch(({ response: { data: { error: { message: errorMessage } = {} } = {} } = {} }) => {
        message.error(`Ошибка удаления ID промо-материала: ${errorMessage}`);
      });
  };

  @action
  pulse = () => {
    this.isPulsating = true;
    setTimeout(() => {
      this.isPulsating = false;
    }, 3000);
  };

  @action
  removePulsation = () => {
    this.isPulsating = false;
  };

  @action
  getLogoType = async () => {
    const promise = API.get('settings/logo');

    return promise
      .then(res => {
        const { data } = res;

        this.setLogoType(data?.data?.logo);
      })
      .catch(() => {
        message.error('Ошибка загрузки настроек логотипа');
      });
  };

  @action
  setLogoType = value => {
    this.logoType = value;
  };

  updateLogo = logoString => {
    const promise = API.patch(SETTINGS_LOGO_URL, { data: { logo: logoString } });

    return promise
      .then(() => {
        message.success('Лого установлено успешно');
      })
      .catch(() => {
        message.error('Ошибка установки Лого');
      });
  };
}

export default new SettingsGlobalStore();
