import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { IExperiment } from '~common';
import { useExperimentsStore } from '~hooks/useExperimentsStore';

import s from './styles.scss';

const ExperimentsItem: React.FC<{ data: IExperiment }> = observer(({ data = {} }) => {
  const { setEditableExperiment } = useExperimentsStore();

  const handleClick = () => {
    setEditableExperiment && setEditableExperiment(data);
  };

  return (
    <Row align="middle" gutter={24} className={s.historyRow} onClick={handleClick}>
      <Col span={6}>
        <Row>
          <Col>{data.name}</Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row>
          <Col>{data.description}</Col>
        </Row>
      </Col>
      <Col span={10}>
        <Row>
          {data.variables?.map(variable => (
            <>
              <Col span={12}>Имя: {variable.name}</Col>
              <Col span={12}>Значение: {variable.linkedValue}</Col>
            </>
          ))}
        </Row>
      </Col>
    </Row>
  );
});

export default ExperimentsItem;
