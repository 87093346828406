import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toJS } from 'mobx'
import { observer, inject } from 'mobx-react'
import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'
import s from './styles.scss'

@inject('EmailsStore')
@observer
class EmailsItem extends Component {
  handleClick = () => {
    const {
      EmailsStore: { setEditableEmail },
      data
    } = this.props

    setEditableEmail(data)
  }

  render() {
    const {
      data: { description, address, visibility, type },
      dictionaries
    } = this.props
    const emailsType = toJS(dictionaries).find(({ id }) => id === type)

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{description}</h2>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Адрес электронной почты:</h3>
            <p className={s.cardItemValue}>{address}</p>
          </div>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Видна на сайте:</h3>
            <p className={s.cardItemValue}>{visibility ? 'Да' : 'Нет'}</p>
          </div>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Тематика обращения:</h3>
            <p className={s.cardItemValue}>{emailsType && emailsType.ru}</p>
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

EmailsItem.propTypes = {
  EmailsStore: PropTypes.any,
  data: PropTypes.object,
  dictionaries: PropTypes.array
}

export default EmailsItem
