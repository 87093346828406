import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC } from 'react';

import { Notification } from '~common';
import { Button } from '~components';
import { settingsNotificationModal } from '~containers';
import { useNotificationsStore } from '~hooks';

import styles from './styles.module.scss';

interface VisibilityProps {
  visibility: boolean;
}

interface ItemProps {
  notification: Notification;
  onEditSuccess(): void;
}

const Visibility: FC<VisibilityProps> = ({ visibility }) => {
  if (visibility) {
    return (
      <Row gutter={4} className={styles.visible}>
        <Col>
          <EyeOutlined />
        </Col>
        <Col>Показано</Col>
      </Row>
    );
  }

  return (
    <Row gutter={4} className={styles.hidden}>
      <Col>
        <EyeInvisibleOutlined />
      </Col>
      <Col>Скрыто</Col>
    </Row>
  );
};

const Item: FC<ItemProps> = ({ notification, onEditSuccess }) => {
  const { message, visibility, from, to, createdAt } = notification;

  const onEdit = async () => {
    if (await settingsNotificationModal({ notification })) {
      onEditSuccess();
    }
  };

  return (
    <Card>
      <Row gutter={[20, 20]} justify="space-between" align="middle">
        <Col span={18}>
          <Row gutter={[12, 12]}>
            <Col span={24} className={styles.text}>
              <span className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
            </Col>
            <Col>
              <Row gutter={18} align="middle">
                <Col>
                  <Visibility visibility={visibility} />
                </Col>
                <Col className={styles.time}>
                  <Row gutter={4} align="middle">
                    <Col>Интервал:</Col>
                    <Col>{moment(from || createdAt).format('DD.MM.YYYY HH:mm')}</Col>
                    {to && (
                      <>
                        <Col>—</Col>
                        <Col>{moment(to).format('DD.MM.YYYY HH:mm')}</Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button type="primary" onClick={onEdit}>
            Редактировать
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export const SettingsNotificationList = observer(() => {
  const { items, resetList } = useNotificationsStore();

  return (
    <Row gutter={[12, 12]}>
      {items.map((notification, index) => (
        <Col key={index} span={24}>
          <Item onEditSuccess={resetList} notification={notification} />
        </Col>
      ))}
    </Row>
  );
});
