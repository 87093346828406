import autosize from 'autosize';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { omit } from 'ramda';
import React, { useEffect, useRef } from 'react';

import { FontWeight, ITextarea } from '~common';
import { usePublicationStore, useSettingsStore } from '~hooks';
import { noop } from '~utils';

import styles from './styles.styl';

interface IProps extends ITextarea {
  value?: string;
}

const Textarea: React.FC<IProps> = observer(props => {
  const {
    readOnly = false,
    isEditing = false,
    placeholder = '',
    value = '',
    fontWeight = FontWeight.Bold,
    className,

    onChange = noop,
    onFocus,
    limit = { isLimitedByMaxSize: false, maxSize: 0 },
  } = props;

  const { pulse } = useSettingsStore();

  const { shouldLimitTitles } = usePublicationStore();

  const input = useRef<any>(null);

  useEffect(() => {
    Boolean(input.current) &&
      input.current?.addEventListener('focus', () => {
        autosize(input.current!);
      });
  }, [input]);

  const handleChange = (e: any) => {
    if (!isEditing) {
      return;
    }

    if (limit?.isLimitedByMaxSize && shouldLimitTitles) {
      if (e.target.value.length > limit.maxSize) {
        pulse();
      }

      e.target.value = e.target.value.slice(0, limit?.maxSize);
    }
    onChange({ raw: e.target.value, plainText: e.target.value });
  };

  const inputProps = {
    ref: input,
    className: cx(styles.textarea, styles[`fontWeight_${fontWeight}`], className),
    placeholder,
    value,
    rawData: value,
    onChange: handleChange,
    onFocus,
  };

  return <textarea {...omit(['rawData'], inputProps)} readOnly={readOnly} />;
});
export default Textarea;
