import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'
import s from './styles.scss'

@inject('InfoPagesStore')
@observer
class InfoPagesItem extends Component {
  handleClick = () => {
    const {
      InfoPagesStore: { setEditablePage },
      data
    } = this.props

    setEditablePage(data)
  }

  render() {
    const {
      data: { title, _id }
    } = this.props

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{title}</h2>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>ID:</h3>
            <p className={s.cardItemValue}>{_id}</p>
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

InfoPagesItem.propTypes = {
  InfoPagesStore: PropTypes.any,
  data: PropTypes.object
}

export default InfoPagesItem
