import { observer } from 'mobx-react';
import React from 'react';

import User from '~components/User';
import { EMPTY_OBJECT } from '~constants';
import { usePublicationStore } from '~hooks';

import styles from '../../components/Settings/styles.scss';

export const IsBlocked = observer(() => {
  const { settingsBlocked, unlockSettings } = usePublicationStore();

  const { blocked: isBlocked, lockedBy } = settingsBlocked || EMPTY_OBJECT;

  return isBlocked ? (
    <div className={styles.overlay}>
      {lockedBy && (
        <div>
          <User
            person={{
              ...lockedBy,
              name: `${lockedBy.firstName} ${lockedBy.lastName}`,
              position: lockedBy.email,
            }}
          />
          <div className={styles.unlock} onClick={() => unlockSettings()}>
            Разблокировать
          </div>
        </div>
      )}
    </div>
  ) : null;
});
