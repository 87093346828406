import { Input, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { ChangeEventHandler, useState } from 'react';

import { IDictionary } from '~common';
import { ItemWithLabel } from '~components';
import { usePublicationStore } from '~hooks';

import { inputStyle } from '../../components/Settings/constants';

export const AliasInput = observer(() => {
  const { alias, status, set } = usePublicationStore();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = ({ target: { value: alias } }) => {
    const cleanAlias = alias
      .replace(/[_–—]/g, '-')
      .replace(/[а-яА-Я]/g, '')
      .replace(/\s/g, '')
      .replace(/`/g, '');
    set({ name: 'alias', value: cleanAlias });
  };

  const onFocusHandler = () => {
    const { id } = status as IDictionary;
    setIsTooltipVisible(['POST_STATUS_UNPUBLISHED', 'POST_STATUS_PUBLISHED'].includes(id));
  };

  const onBlurHandler = () => {
    setIsTooltipVisible(false);
  };

  return (
    <ItemWithLabel label="Алиас">
      <Tooltip
        visible={isTooltipVisible}
        placement="topLeft"
        title="Изменение алиаса у опубликованного материала пессимизирует его индекасацию в поисковых ресурсах"
      >
        <Input
          style={inputStyle}
          placeholder="Введите алиас"
          value={alias}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
        />
      </Tooltip>
    </ItemWithLabel>
  );
});
