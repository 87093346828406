import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { Facebook, Instagram, Ok, Telegram, Twitter, Vk } from '~icons';

import SOCIALS_TYPE from '../config';
import styles from './styles.scss';

const ICON_FILL = '#D3D3D3';

type SocialTypes = typeof SOCIALS_TYPE[keyof typeof SOCIALS_TYPE];

interface IProps {
  data?: SocialTypes[];
  onClick?(e: React.MouseEvent): void;
}

const getSocialIconByType = cond<SocialTypes, React.ReactNode>([
  [equals(SOCIALS_TYPE.VKONTAKTE), always(<Vk fill={ICON_FILL} />)],
  [equals(SOCIALS_TYPE.ODNOKLASSNIKI), always(<Ok fill={ICON_FILL} />)],
  [equals(SOCIALS_TYPE.FACEBOOK), always(<Facebook fill={ICON_FILL} />)],
  [equals(SOCIALS_TYPE.INSTAGRAM), always(<Instagram fill={ICON_FILL} />)],
  [equals(SOCIALS_TYPE.TELEGRAM), always(<Telegram fill={ICON_FILL} />)],
  [T, always(<Twitter fill={ICON_FILL} />)],
]);

const Socials: React.FC<IProps> = ({ data = {}, onClick }) => (
  <div className={styles.root}>
    {Object.keys(data).map(
      (item, idx) =>
        !!data[item].length && (
          <span key={idx} className={styles.socialIco} onClick={onClick}>
            <a href={data[item]} target="_blank" rel="noreferrer noopener">
              {getSocialIconByType(item)}
            </a>
          </span>
        ),
    )}
  </div>
);

export default Socials;
