import { object, ObjectSchema, string } from 'yup';

export interface IApprover {
  firstName: string;
  lastName: string;
}

export const approverSchema: ObjectSchema<IApprover> = object({
  firstName: string().defined(),
  lastName: string().defined(),
})
  .default(undefined)
  .defined();
