import { action, computed, observable } from 'mobx';

import API, { getData } from '~api';
import { IS_PRODUCTION } from '~constants';
import { showErrorNotificationInPromise } from '~utils';

export interface IFlags {
  checkBadWords: boolean;
  compileWorker: boolean;
  fileUploadWorker: boolean;
  unlockOnExit: boolean;
  useDraft: boolean;
  useWebVisor: boolean;
  videoPosts: boolean;
}

export class FlagsStore {
  apiLayer = API;

  @observable
  flags: Partial<IFlags> = {};

  @observable
  dictionaries = [];

  @observable
  isFetching = false;

  @observable
  isLoaded = false;

  @computed
  get unlockOnExit() {
    const { unlockOnExit = false } = this.flags;
    return unlockOnExit;
  }

  @computed
  get useWebVisor() {
    const { useWebVisor = false } = this.flags;
    return IS_PRODUCTION && useWebVisor;
  }

  @computed
  get videoPosts() {
    const { videoPosts = false } = this.flags;
    return videoPosts;
  }

  @computed
  get fileUploadWorker() {
    return this.flags?.fileUploadWorker || false;
  }

  @computed
  get useDraft() {
    return this.flags?.useDraft || true;
  }

  @action
  setFlags = flags => {
    this.flags = flags;
  };

  @action
  fetchDictionaries = () => {
    const promise = API.get('dictionaries/flags');

    return promise.then(res => {
      const { data } = res;

      this.dictionaries = data.flagsTypes;
    });
  };

  @action
  getFlags = () => {
    this.setIsFetching(true);
    return this.apiLayer
      .get('/settings/flags')
      .then(getData)
      .then(({ data: flags }) => {
        this.setFlags(flags);
        this.setIsLoaded(true);
        return Promise.resolve(flags);
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка получения настроек' }))
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  updateFlags = flags => {
    this.setIsFetching(true);
    return this.apiLayer
      .patch('/settings/flags', { data: flags })
      .then(getData)
      .then(({ data: flags }) => {
        this.setFlags(flags);
        return Promise.resolve(flags);
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка обновления настроек' }))
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  setIsFetching(data: boolean) {
    this.isFetching = data;
  }

  @action
  setIsLoaded(data: boolean) {
    this.isLoaded = data;
  }
}

export default new FlagsStore();
