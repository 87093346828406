import classNames from 'classnames';
import React, { ChangeEventHandler, useContext, useState } from 'react';

import { BlockType, DropType, IBlock, IBlockProps, IFile } from '~common';
import { OnChangeBlockDataHandler } from '~components/RichEditor';
import { ButtonShape, EMPTY_STRING } from '~constants';
import { useBlockStateReducer } from '~hooks';
import { Close } from '~icons';
import { isAnyTruthy } from '~utils';

import { BlockButton } from '../../BlockButton';
import { DropZone } from '../../DropZone';
import { EditorContext } from '../../Editor';
import { Textarea } from '../../Textarea';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

type IAudioData = {
  alt?: string;
  charactersCount?: number;
  cover?: IFile;
  loop?: boolean;
  mimetype?: string;
  size?: number;
  url?: string;
};

interface IProps extends IBlock {
  blockProps?: IBlockProps;
  data?: IAudioData;
  onChange(data: IAudioData): void;
  placeholder?: string;
  uploadUrl?: string;
}

type TState = IAudioData;

const AudioBlock: React.FC<IProps> = props => {
  const { uploadUrl: defaultUrl = '' } = useContext(EditorContext);
  const [isUploading, setIsUploading] = useState(false);

  const {
    id = EMPTY_STRING,
    isFetching = false,
    isEditing = false,
    isForceUpdate = false,
    data = {},
    blockProps = {
      title: 'Подкаст и аудио',
    },
    uploadUrl = defaultUrl,
    placeholder = 'Подпись к аудио',
    onChange,
  } = props;

  const emptyAudio: TState = {
    url: EMPTY_STRING,
    size: 0,
    mimetype: EMPTY_STRING,
  };

  const emptyState: TState = {
    ...emptyAudio,
    cover: {
      url: EMPTY_STRING,
      alt: EMPTY_STRING,
      size: 0,
      mimetype: '',
    },
    alt: EMPTY_STRING,
    loop: false,
  };
  const initialState: TState = {
    ...emptyState,
    ...data,
  };

  const [state, dispatch] = useBlockStateReducer<TState>({
    blockId: id,
    blockType: BlockType.AudioBlock,
    initialState,
    incomingData: data,
    isEditing,
    isForceUpdate,
    onChange,
  });

  const {
    url = EMPTY_STRING,
    mimetype,
    alt = EMPTY_STRING,
    size = 0,
    loop = false,
    cover = {},
  } = state;
  const audio = { url, size, mimetype };

  const handleChangeAudio = ({ url, mimetype, size }: IFile) => {
    dispatch({
      url,
      mimetype,
      size,
    });
  };

  const handleChangeCover = cover => {
    dispatch({
      cover,
    });
  };

  const handleChangeDescription: OnChangeBlockDataHandler = ({ plainText: alt = EMPTY_STRING }) => {
    const charactersCount = alt.length;
    dispatch({
      alt,
      charactersCount,
    });
  };

  const handleChangeLoop: ChangeEventHandler<HTMLInputElement> = ({
    target: { checked: loop = false },
  }) => {
    dispatch({
      loop,
    });
  };

  const handleDelete = () => {
    dispatch(emptyAudio);
  };

  const handleProgressChange = (progress: number) => {
    setIsUploading(progress > 0);
  };

  return (
    <BlockWrapper
      {...blockProps}
      wrapperClassname={classNames(styles.wrapper, isUploading && styles.disabled)}
    >
      <div className={styles.dropZoneWrapper}>
        {size > 0 && (
          <div className={styles.controls}>
            <BlockButton shape={ButtonShape.Round} onClick={handleDelete} className={styles.btn}>
              <span
                style={{
                  marginRight: '5px',
                  width: 11,
                  height: 11,
                  fill: '#9A9A9A',
                }}
              >
                <Close />
              </span>{' '}
              Удалить
            </BlockButton>
          </div>
        )}
        <DropZone
          type={DropType.Audio}
          accept={'audio/*'}
          uploadUrl={uploadUrl}
          files={[audio]}
          onChange={handleChangeAudio}
          onProgressChange={handleProgressChange}
          noDragEventsBubbling={false}
        />
      </div>

      <div className={styles.captionHeader}>Заголовок</div>
      <Textarea
        placeholder={placeholder}
        value={alt}
        onChange={handleChangeDescription}
        className={styles.input}
        readOnly={isAnyTruthy([isUploading, isFetching])}
        isEditing={isEditing}
      />

      <div className={styles.settings}>
        <div>
          <div className={styles.captionHeader}>Обложка для аудио</div>
          <div className={styles.cover}>
            <DropZone
              accept={'image/*'}
              uploadUrl={uploadUrl}
              files={[cover]}
              onChange={handleChangeCover}
              onProgressChange={handleProgressChange}
              uploadData={{ type: 'avatar' }}
            />
          </div>
        </div>
        <div>
          <div className={styles.captionHeader}>Дополнительно</div>
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={loop}
              onChange={handleChangeLoop}
              disabled={isUploading}
            />
            <span>Зацикленное</span>
          </label>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default AudioBlock;
