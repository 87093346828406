import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { IAuthor } from '~common';
import { ContentSpinner, EmptyContent, GridWrapper } from '~components';
import { AuthorsStore } from '~store/AuthorsStore';
import { getFromStore } from '~utils';

import AuthorItem from '../AuthorItem';

type IProps = FromStore<AuthorsStore, 'authors' | 'isEmptyAuthors' | 'isFirstLoading'>;

@inject(getFromStore('AuthorsStore', ['authors', 'isFirstLoading', 'isEmptyAuthors']))
@observer
class AuthorList extends Component<IProps> {
  render() {
    const { authors, isFirstLoading, isEmptyAuthors } = this.props;

    if (isFirstLoading) {
      return <ContentSpinner />;
    }

    if (isEmptyAuthors) return <EmptyContent text="Нет авторов" />;

    return (
      <GridWrapper gridSize={2} maxItemWidth={590}>
        {authors?.map((author: IAuthor) => (
          <AuthorItem key={author._id} author={author} />
        ))}
      </GridWrapper>
    );
  }
}

export default AuthorList;
