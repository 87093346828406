import React, { Component } from 'react';

import styles from './style.styl';

interface IState {
  filename: string;
  height: number;
  isDrag: boolean;
  mimetype: string;
  path: string;
  placeholderUrl: string;
  progress: string;
  showProgress: boolean;
  size: number;
  url: string;
  width: number;
}

interface IProps {
  defaultProps?: any;
  filename?: string;
  height?: number;
  mimetype?: string;
  onChange?(state: IState): void;
  onlyVideo?: boolean;
  path?: string;
  placeholderUrl?: string;
  size?: number;
  uploadUrl?: string;
  url?: string;
  width?: number;
}

export default class MediaUpload extends Component<IProps, IState> {
  static defaultProps = {
    uploadUrl: '/v1/media/single-upload',
    onlyVideo: false,
    placeholderUrl: 'http://super.ru/upload/images/preview-default.jpg',
    filename: '',
    height: 0,
    mimetype: '',
    path: '',
    url: '',
    width: 0,
    size: 0,
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      filename: this.props.filename,
      height: this.props.height,
      mimetype: this.props.mimetype,
      path: this.props.path,
      url: this.props.url,
      width: this.props.width,
      size: this.props.size,

      isDrag: false,

      showProgress: false,
      progress: '0 %',

      ...(props.defaultProps || {}),
    };
  }

  onChange = () => {
    const { onChange } = this.props;

    onChange && setTimeout(() => onChange(this.state), 0);
  };

  dropEnter = e => {
    e.stopPropagation();
    e.preventDefault();
    e.target.classList.add(styles.active);
    this.setState({ isDrag: true });
  };

  dropLeave = e => {
    e.target.classList.remove(styles.active);
    this.setState({ isDrag: false });
  };

  printProgress = e => {
    this.setState({ progress: `${Math.round((100 * e.loaded) / e.total)} %` });
  };

  loadFile = (file: File) => {
    const { uploadUrl } = this.props;

    if (file.type.indexOf('video') === -1 && this.props.onlyVideo) return;

    this.setState({ size: file.size, showProgress: true, progress: '0 %' });

    const form = new FormData();
    form.append('file', file);

    const ajax = new XMLHttpRequest();
    ajax.upload.addEventListener('progress', this.printProgress, false);
    ajax.addEventListener(
      'load',
      e => {
        this.setState({
          // @ts-ignore
          ...JSON.parse(e.target?.responseText).data,
          showProgress: false,
        });
        this.onChange();
      },
      false,
    );
    ajax.open('POST', uploadUrl || MediaUpload.defaultProps.uploadUrl);
    ajax.send(form);

    // fetch(uploadUrl, {
    //   method: 'POST',
    //   body: form
    // }).then(response => response.json()).then(response => {
    //   this.setState({
    //     ...response.data
    //   });
    //   this.onChange()
    // });
  };

  doDrop = (e: React.DragEvent<HTMLDivElement>) => {
    // @ts-ignore
    e.target.classList.remove(styles.active);
    this.setState({ isDrag: false });

    const dt = e.dataTransfer;
    // @ts-ignore
    if (!dt && !dt.files) {
      return false;
    }

    // for IE
    dt.dropEffect = 'copy';

    this.loadFile(dt.files[0]);

    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  deleteMedia = () => {
    this.setState({
      filename: '',
      height: 0,
      mimetype: '',
      path: '',
      url: '',
      width: 0,
      size: 0,
    });
    this.onChange();
  };

  // style={{height: height === 0 ? '120px' : height + 'px' }}

  render() {
    const { url, isDrag, showProgress, progress } = this.state;
    // const { type } = this.props;

    return (
      <div
        className={styles['media-upload-root']}
        onDragEnter={this.dropEnter}
        onDragOver={this.dropEnter}
        onDragLeave={this.dropLeave}
        onDrop={this.doDrop}
      >
        {url && (
          <div className={styles['media-upload-delete']} onClick={this.deleteMedia}>
            X Удалить
          </div>
        )}
        {showProgress && <div className={styles['media-upload-progress']}>{progress}</div>}
        {/* <img className="media-upload-preview" src={url ? url : require('assets/photo-placeholder.jpg')}/> */}
        {isDrag ? null : (
          <input
            type="file"
            className={styles['media-upload-file']}
            onChange={e => {
              this.loadFile(e.target.files?.item(0)!);
            }}
          />
        )}
        {this.state.mimetype.indexOf('video') > -1 ? (
          <video autoPlay loop muted className={styles['media-upload-preview']} src={url} />
        ) : (
          <img
            alt=""
            className={styles['media-upload-preview']}
            src={url || this.props.placeholderUrl}
          />
        )}
      </div>
    );
  }
}
