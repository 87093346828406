import { string, object, number } from 'yup'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const departmentSchema = object()
  .default(undefined)
  .shape({
    _id: string().required(),
    createdAt: string(),
    index: number(),
    name: string(),
    updatedAt: string()
  })

export const isValidDepartment = isValidBySchema(departmentSchema)
