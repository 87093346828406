/* eslint-disable */
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import SliderWrapper from '../SliderWrapper'
import SliderItem from '../components/SliderItem'
import CategoryMenuItem from './CategoryMenuItem'

import styles from './styles.scss'

const actualValue = [0, 9, 8, 7, 6, 5, 4, 3, 2, 1]

@inject(stores => {
  const {
    selectedCategories,
    setSelectedCategory,
    categories,
    removeSelectedCategory,
    setCategoryValue
  } = stores.PublicationStore.priorities

  return {
    categories,
    selectedCategories,
    setSelectedCategory,
    removeSelectedCategory,
    setCategoryValue
  }
})
@observer
class PriorityCategory extends Component {
  handleRemoveCategory = categoryId => {
    const { removeSelectedCategory } = this.props

    removeSelectedCategory(categoryId)
  }

  handleMenuClick = ({ id, title }) => {
    const { setSelectedCategory } = this.props

    setSelectedCategory({ id, title })
  }

  handleChangeSlider = ({ id, value }) => {
    const { setCategoryValue } = this.props

    setCategoryValue({ id, value: actualValue[value] })
  }

  render() {
    const { label, selectedCategories, categories } = this.props

    const popover = {
      content: (
        <div className={styles.categoryMenu}>
          {categories
            .filter(category => !category.isSelected)
            .map(({ id, title }) => (
              <CategoryMenuItem key={id} id={id} title={title} onMenuClick={this.handleMenuClick} />
            ))}
        </div>
      )
    }

    return (
      <SliderWrapper label={label} popover={popover}>
        <div className={styles.categoryList}>
          {selectedCategories.map(category => (
            <SliderItem
              key={category.id}
              label={category.title}
              id={category.id}
              value={category.value}
              onClick={this.handleRemoveCategory}
              onChangeSlider={this.handleChangeSlider}
              disabled
            />
          ))}
        </div>
      </SliderWrapper>
    )
  }
}

export default PriorityCategory
