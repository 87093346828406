import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'

import classNames from 'classnames'

import styles from './styles.scss'

import ArrowDown from '~assets/icons/arrowDown.svg'
import ClearIcon from '~assets/icons/close.svg'

@observer
class SortingItem extends Component {
  handleClickItem = () => {
    const { data, onClick } = this.props

    onClick(data)
  }

  handleClear = () => {
    const { data, onClick } = this.props

    onClick(data, null)
  }

  render() {
    const { data } = this.props

    return (
      <div
        className={classNames(styles.sortingItem, data.value && styles.active)}
        onClick={this.handleClickItem}
      >
        <div>{data.label}</div>

        {data.value && (
          <div className={classNames(styles.icon, data.value === '-' && styles.rotate)}>
            <ArrowDown />
          </div>
        )}

        {data.value && (
          <ClearIcon
            className={styles.clear}
            onClick={e => {
              e.stopPropagation()
              this.handleClear()
            }}
          />
        )}
      </div>
    )
  }
}

export default SortingItem

SortingItem.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}
