import { get } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';

import { IPriority } from '~common';
import { ISelectProps, Select } from '~components';
import { usePublicationStore } from '~hooks';

export const PostTypesSelect: React.FC<ISelectProps<IPriority>> = observer(props => {
  const { dictionaries } = usePublicationStore();

  return (
    <Select {...props} options={get(dictionaries, 'postTypes', [])} keyField="id" labelField="ru" />
  );
});
