import { action, computed, observable, runInAction } from 'mobx';

import API from '~api';

import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists';
import FetchPaginationStore from './fetchPaginationStore';
import { EMPTY_STRING } from '~constants';

const AUTHORS_URL = '/authors';

export class AuthorsStore {
  @observable isShowModalEdit = false;

  @observable isShowModalInfo = false;

  @observable isFirstLoading = true;

  @observable allItemsCount = 0;

  @observable isLoadingMore = false;

  @observable isActionModal = false;

  @observable editableAuthor = null;

  @observable authors = [];

  @observable authorsSearches = [];

  @observable categoriesSearches = [];

  @observable sectionsSearches = [];

  @action
  fetchAuthorsBySearch = term => {
    let url = AUTHORS_URL;

    if (term) {
      url = `${url}?search=${term}`;
    }

    return API.get(url).then(res => {
      const { data } = res.data;

      runInAction(() => {
        this.authorsSearches = data;
      });

      return data;
    });
  };

  @action
  fetchCategoriesBySearch = term => {
    let url = '/categories';

    if (term) {
      url = `${url}?search=${term}`;
    }

    return API.get(url).then(res => {
      const { data } = res.data;

      runInAction(() => {
        this.categoriesSearches = data;
      });

      return data;
    });
  };

  @action
  fetchSectionsBySearch = term => {
    let url = '/sections';

    if (term) {
      url = `${url}?search=${term}`;
    }

    return API.get(url).then(res => {
      const { data } = res.data;

      runInAction(() => {
        this.sectionsSearches = data;
      });

      return data;
    });
  };

  @action
  fetchAuthors = () => {
    const { offset } = FetchPaginationStore;
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;

    const promiseUrl = getPromiseUrl({
      url: AUTHORS_URL,
    });

    const fetchAuthorsPromise = API.get(promiseUrl);

    this.setIsLoadingMore(true);
    return fetchAuthorsPromise
      .then(res => {
        const { data, meta } = res.data;

        if (offset === 0) {
          this.setAuthors(data);
        } else {
          this.setAuthors([...this.authors, ...data]);
        }

        this.setAllItemsCount(meta.count);
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      })
      .catch(() => {
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      });
  };

  @action
  updateAuthor = author => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const { _id } = this.editableAuthor;

    const updateAuthorPromise = API.patch(`${AUTHORS_URL}/${_id}`, author);

    return updateAuthorPromise
      .then(res => {
        const { data } = res.data;
        this.setHideModalEdit();

        const editableAuthorIndex = this.authors.indexOf(this.editableAuthor);

        runInAction(() => {
          this.isActionModal = false;
          this.authors[editableAuthorIndex] = data;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  createAuthor = author => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const createAuthorPromise = API.post(AUTHORS_URL, author);

    return createAuthorPromise
      .then(res => {
        const { data } = res.data;
        this.setHideModalEdit();

        this.setAllItemsCount(this.allItemsCount + 1);

        runInAction(() => {
          this.isActionModal = false;
          this.authors.unshift(data);
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  deleteAuthor = () => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const { _id } = this.editableAuthor;

    const deleteAuthorPromise = API.delete(`${AUTHORS_URL}/${_id}`);

    return deleteAuthorPromise
      .then(() => {
        this.authors.remove(this.editableAuthor);

        this.resetEditableAuthor();
        this.setHideModalEdit();
        this.setAllItemsCount(this.allItemsCount - 1);

        runInAction(() => {
          this.isActionModal = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @computed
  get isEmptyAuthors() {
    return !this.authors.length;
  }

  @action
  setEditableAuthor = (value, type) => {
    this.editableAuthor = value;

    if (type === 'edit') this.setShowModalEdit();
    if (type === 'info') this.setShowModalInfo();
  };

  @action
  resetEditableAuthor = () => {
    this.editableAuthor = null;
  };

  @action
  setShowModalEdit = () => {
    this.isShowModalEdit = true;
  };

  @action
  setHideModalEdit = () => {
    this.isShowModalEdit = false;
  };

  @action
  setShowModalInfo = () => {
    this.isShowModalInfo = true;
  };

  @action
  setHideModalInfo = () => {
    this.isShowModalInfo = false;
  };

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value;
  };

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value;
  };

  @action
  setAuthors = data => {
    this.authors = data;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @computed
  get authorsLength() {
    return this.authors.length;
  }
}

export default new AuthorsStore();
