import '@ant-design/compatible/assets/index.css';

import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Button, Input } from 'antd';
import { partial } from 'ramda';
import React, { FC, useCallback, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { IComment, InjectedModalProps } from '~common';
import { ItemWithLabel, Modal } from '~components';
import { useCommentsStore } from '~hooks';

interface ICommentModalProps extends InjectedModalProps, FormComponentProps {
  comment: IComment;
}

const EditCommentModal: FC<ICommentModalProps> = props => {
  const { editComment } = useCommentsStore();
  const [isFetchingEditComment, setIsFetchingEditComment] = useState(false);

  const {
    comment: { _id: commentId, content },
  } = props;
  const { isOpen, close } = props;
  const {
    form: { getFieldDecorator, getFieldsValue, validateFields },
  } = props;

  const onClose = useCallback(
    (result = false) => {
      close && close(result);
    },
    [close],
  );

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      const { content } = getFieldsValue();
      await validateFields();
      setIsFetchingEditComment(true);
      editComment(commentId, content)
        .then(onClose)
        .finally(() => {
          setIsFetchingEditComment(false);
        });
    },
    [commentId, editComment, getFieldsValue, onClose, validateFields],
  );

  return (
    <Modal
      width={600}
      isVisible={isOpen}
      onClose={partial(onClose, [false])}
      title="Редактирование комментария"
    >
      <Form onSubmit={handleSubmit}>
        <ItemWithLabel label="Комментарий">
          <Form.Item>
            {getFieldDecorator('content', {
              initialValue: content,
              rules: [{ required: true, message: 'Обязательное поле!' }],
            })(<Input.TextArea rows={4} autoFocus />)}
          </Form.Item>
        </ItemWithLabel>

        <Button
          loading={isFetchingEditComment}
          disabled={isFetchingEditComment}
          htmlType="submit"
          type="primary"
        >
          Сохранить изменения
        </Button>
      </Form>
    </Modal>
  );
};

export const editCommentModal = createModal(
  // @ts-ignore
  Form.create({ name: 'editCommentForm' })(EditCommentModal),
);
