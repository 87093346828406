import { message, notification } from 'antd';

import { noop, rejectPromise } from '~utils/functional';

notification.config({ placement: 'bottomRight' });

export default function getError(err) {
  let message = err.message || JSON.stringify(err);

  if (err.response) {
    message = `Статус ${err.response?.status}: \n${err.response?.data?.error?.message ??
      JSON.stringify(err.response?.data)}`;
  }

  return message;
}

export const getErrorMessage = error =>
  error?.response?.data?.error?.message ??
  error?.error?.message ??
  error?.message ??
  error ??
  'Неизвестная ошибка';

export const showErrorNotificationInPromise = (args: {
  callback?: () => void;
  duration?: number;
  title: string;
}) => error => {
  const { title, duration = 0, callback = noop } = args;
  showErrorNotification(title, error, duration);
  callback && callback();
  return rejectPromise(error);
};

/** ToDo arguments as object */
export const showErrorNotification = (title, error, duration = 0) => {
  console.log(`showErrorNotification: ${title}`, error);
  notification.error({ message: title, description: getErrorMessage(error), duration });
};

export const showErrorMessage = (title, error, duration = 5) => {
  console.log(`showErrorMessage: ${title}`, error);
  message.error(`${title}: ${getErrorMessage(error)}`, duration);
};
