import { Select, Spin } from 'antd';
import propTypes from 'prop-types';
import React from 'react';
import API from '~api';
import { BlockWrapper } from '~components/Blocks';

// components
import ItemWithLabel from '~components/ItemWithLabel';

// assets
import styles from './styles.scss';

// services
const { Option } = Select;

// helpers
const delayedExecute = (f, delay) => {
  let timerId = null;

  return value => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      f(value);
    }, delay);
  };
};

class FlowBlock extends React.Component {
  constructor(props) {
    super(props);
    this.delayedRequest = delayedExecute(this.fetchFlows, 300);

    this.state = {
      data: [],
      value: {},
      fetching: false,
    };
  }

  componentDidMount() {
    if (this.props.flowId) {
      this.fetchFlow(this.props.flowId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.flowId !== this.props?.flowId) {
      this.fetchFlow(this.props.flowId);
    }
  }

  handleChange = value => {
    const flow = this.state.data.find(
      ({ title, alias, value: id }) =>
        title === value.label || alias === value.label || id === value.key,
    );
    this.setState(
      {
        value,
        data: [],
        fetching: false,
      },
      () => {
        this.props.onChange({
          flowId: flow.value,
          title: flow.text,
          alias: flow.alias,
        });
      },
    );
  };

  fetchFlow = async id => {
    const { data } = await API.get(`/flows/${id}`);
    const flow = data.data;

    this.setState({
      flow,
      value: {
        key: flow._id,
        label: flow.title,
        alias: flow.alias,
      },
    });
  };

  fetchFlows = value => {
    const searchUrl = ['/flows', value ? `?search=${value}` : ''].join('');
    this.setState({ data: [], fetching: true });

    API.get(searchUrl).then(body => {
      const { data } = body.data;

      const newData = data.map(flow => ({
        text: flow.title,
        value: flow._id,
        alias: flow.alias,
      }));
      this.setState({ data: newData, fetching: false });
    });
  };

  render() {
    const {
      blockProps: { title },
    } = this.props;
    const { flow } = this.state;
    const { fetching, data, value } = this.state;

    return (
      <BlockWrapper {...this.props} {...this.props.blockProps} title={title}>
        <div className={styles.container}>
          <div>
            <ItemWithLabel label="Поток">
              <Select
                labelInValue
                value={value}
                showSearch={true}
                placeholder="Выберите поток"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.delayedRequest}
                onChange={this.handleChange}
                style={{ width: '100%' }}
              >
                {data.map(d => (
                  <Option value={d.value}>{d.text}</Option>
                ))}
              </Select>
            </ItemWithLabel>
          </div>
          {flow && <div></div>}
        </div>
      </BlockWrapper>
    );
  }
}

FlowBlock.propTypes = {
  blockProps: propTypes.object,
  flowId: propTypes.string,

  onChange: propTypes.func,
};

FlowBlock.defaultProps = {
  flowId: null,
};

export default FlowBlock;
