import { Result } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '~components';
import { useFilterParamsByListStore, useUserStore } from '~hooks';

import styles from './styles.module.scss';

export const OAuthCallback: FC = observer(() => {
  const { replace } = useHistory();
  const { oAuth } = useUserStore();
  const [errorMessage, setErrorMessage] = useState(null);
  const { setFilterParams } = useFilterParamsByListStore();

  const oauthCallbackUrl = new URLSearchParams(window.location.search);

  const code = oauthCallbackUrl.get('code');

  useEffect(() => {
    oAuth(code)
      .then(() => {
        setFilterParams();
        replace('/');
      })
      .catch(e => {
        setErrorMessage(e.message);
      });
  }, []);

  const goToLogin = () => {
    window.location.href = `${process.env.API_ROOT}/connect/life`;
  };

  return (
    <div className={styles.wrapper}>
      <Result
        status={errorMessage ? 'error' : 'info'}
        title={errorMessage ? 'Ошибка авторизации через SSO' : 'Идет авторизация через SSO...'}
        subTitle={errorMessage}
      />
      {errorMessage && <Button onClick={goToLogin}>Повторить</Button>}
    </div>
  );
});
