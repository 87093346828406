import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { ContentWrapper, HeaderPageSearchBar, InfinityScroll } from '~components';
import { AuthorList, AuthorModal, AuthorModalInfo } from '~containers';
import {
  useAuthorsStore,
  useFetchPaginationStore,
  useFilterParamsByListStore,
  useUserStore,
} from '~hooks';

const PAGE_TITLE = 'Все авторы';
const COUNT_DECLINATIONS = ['автор', 'автора', 'авторов'];
const SEARCH_BTN_TEXT = 'Найти в авторах';
const CREATE_BTN_TEXT = 'Добавить автора';

const AuthorsPage: React.FC = observer(() => {
  const {
    setIsFirstLoading,
    setShowModalEdit,
    fetchAuthors,
    allItemsCount = 0,
    authorsLength = 0,
    isFirstLoading,
    isLoadingMore,
  } = useAuthorsStore();
  const { checkPermissions } = useUserStore();
  const { setFilterParams } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();

  useEffect(() => {
    return () => {
      setFilterParams();
      resetPaginationParams();
    };
  }, []);

  const handleSearchPublications = useCallback(() => {
    setIsFirstLoading(true);
    fetchAuthors();
  }, [setIsFirstLoading, fetchAuthors]);

  return (
    <>
      <HeaderPageSearchBar
        searchButtonText={SEARCH_BTN_TEXT}
        createButtonText={CREATE_BTN_TEXT}
        onCreateElement={checkPermissions('authors.create') && setShowModalEdit}
        onSearch={handleSearchPublications}
      />
      <ContentWrapper
        title={PAGE_TITLE}
        countItems={allItemsCount}
        countDeclinations={COUNT_DECLINATIONS}
      >
        <InfinityScroll
          loadItems={fetchAuthors}
          isEndLoading={allItemsCount <= authorsLength}
          isFirstLoading={isFirstLoading}
          isLoadMore={isLoadingMore}
        >
          <AuthorList />
        </InfinityScroll>
      </ContentWrapper>

      <AuthorModal />
      <AuthorModalInfo />
    </>
  );
});

export default AuthorsPage;
