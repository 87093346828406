import { AxiosResponse } from 'axios';
import { action, computed, observable } from 'mobx';
import queryString from 'query-string';

import { FilterItem, ListResponse, Role, RoleCreateResponse, RoleUpdateResponse } from '~common';
import { EmployeeTransportLayer } from '~services/employeeApi';
import { RolesTransportLayer } from '~services/rolesApi';
import { AbstractStore } from '~store/AbstractStore';

export class RolesStore extends AbstractStore<Role, RoleCreateResponse, RoleUpdateResponse> {
  api: RolesTransportLayer;

  employeeApi: EmployeeTransportLayer;

  @observable
  copyRole: Omit<Role, '_id'> | undefined | null = null;

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();

    this.api = new RolesTransportLayer();
    this.employeeApi = new EmployeeTransportLayer();
  }

  @computed
  get filterItems(): FilterItem[] {
    return [];
  }

  protected createHandler(data: RoleCreateResponse): Promise<AxiosResponse<Role>> {
    return this.api.create(data);
  }

  protected updateHandler(id: string, data: RoleUpdateResponse): Promise<AxiosResponse<Role>> {
    return this.api.update(id, data);
  }

  protected deleteHandler(id: string): Promise<AxiosResponse> {
    return this.api.delete(id);
  }

  protected getHandler(id: string): Promise<AxiosResponse<Role>> {
    return this.api.get(id);
  }

  protected getListHandler(filter: string | undefined): Promise<AxiosResponse<ListResponse<Role>>> {
    return this.api.getList(filter);
  }

  getEmployees = (roleId?: string, params = {}) => {
    return this.employeeApi.getList(
      `?${queryString.stringify(
        { 'filter.role': roleId, ...params },
        { skipNull: true, skipEmptyString: true, arrayFormat: 'comma' },
      )}`,
    );
  };

  updateEmployeeRole = (employeeId: string, roleId?: string | null) => {
    return this.employeeApi.update(employeeId, {
      // @ts-ignore
      role: roleId,
    });
  };

  addRoleToEmployees = (roleId: string, employeeIds: string[]) => {
    return this.api.addRoleToEmployees(roleId, { employeeIds });
  };

  @action
  setCopyRole = (role: Omit<Role, '_id'> | null) => {
    this.copyRole = role;
  };
}

export default new RolesStore();
