import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { ContentWrapper, SettingsNavigation } from '~components';
import { useUserStore } from '~hooks';

const { Dragger } = Upload;

const props = {
  name: 'indexNow',
  multiple: false,
  accept: 'text/*',
  withCredentials: true,
  showUploadList: false,
  action: `${process.env.API_ROOT}/indexNow`,
  onChange(info) {
    const { status, response } = info.file;
    if (status === 'done') {
      message.success(`Файл ${info.file.name} загружен успешно.`);
    } else if (status === 'error') {
      const {
        error: { message: errorMessage = 'Неизвестная ошибка' },
      } = response;
      message.error(`Ошибка загрузки файла ${info.file.name}: ${errorMessage}`);
    }
  },
};

const PAGE_TITLE = 'Отправка в IndexNow';

const SettingsIndexNowPage = observer(() => {
  const { checkPermissions } = useUserStore();

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        <Dragger {...props} disabled={!checkPermissions('indexNow.update')}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите файл для загрузки</p>
        </Dragger>
      </ContentWrapper>
    </>
  );
});

export default React.memo(SettingsIndexNowPage);
