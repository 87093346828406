import { EditOutlined, HistoryOutlined, LinkOutlined, LockOutlined } from '@ant-design/icons';
import { Button, notification, Popover, Spin, Tooltip } from 'antd';
import cx from 'classnames';
import { write } from 'clipboardy';
import { observer } from 'mobx-react';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { IBlock, IBlockProps, IFile, NonValidatableBlocks } from '~common';
import Validated from '~components/Publications/Validated';
import { EMPTY_STRING } from '~constants';
import {
  useFilterParamsByListStore,
  usePublicationStore,
  useSettingsStore,
  useUserStore,
} from '~hooks';
import { isBoolean, isNotNil, noop, role } from '~utils';

import styles from './styles.styl';

interface IUserProps {
  avatar?: IFile;
  firstName?: string;
  lastName?: string;
}

interface IProps extends IBlockProps {
  footerComponent?: ComponentType;
  headerComponent?: ComponentType;
  id?: string;
  isEditing?: boolean;
  maxSize?: string;
  onKeyDown?(event: React.KeyboardEvent, id?: string): void;
  onMouseUp?(): void;
  onUnlockBlock?(block: Pick<IBlock, 'id' | 'lockedBy'> & { force: boolean }): void;
  orderNumber?: string;
  withCopyId?: boolean;
  withViewChanges?: boolean;
}

notification.config({ placement: 'bottomRight' });

const User: React.FC<IUserProps> = props => {
  const { firstName = '', lastName = '', avatar } = props;

  return (
    <div className={styles.user}>
      {avatar ? (
        <img src={avatar.url} alt="" className={styles.avatar} />
      ) : (
        <div className={styles.avatar}>{firstName && firstName[0]}</div>
      )}
      <div className={styles.name}>{`${firstName && firstName} ${lastName && lastName}`}</div>
    </div>
  );
};

const BlockWrapper: React.FC<IProps> = observer(props => {
  const {
    id,
    type,

    blocked,
    readOnly = false,
    isEditing,
    isFetching = false,
    lockedBy,

    validatedAt,
    validatedBy,

    title,
    children,
    wrapperClassname,

    header = true,
    footer = true,
    footerComponent,

    orderNumber,
    maxSize,

    onMouseUp = noop,
    onUnlockBlock = noop,
    onKeyDown = noop,

    withCopyId = false,
    withViewChanges = false,

    isLimitedByMaxSize,
  } = props;

  const classNames = {
    block: '',
    head: '',
    content: '',
    contentWrapper: '',
    footer: '',
    ...props.classNames,
  };

  const { setFilterValue } = useFilterParamsByListStore();
  const { user, canUnlockBlock, checkPermissions } = useUserStore();
  const { approveBlock } = usePublicationStore();
  const history = useHistory();
  const { isPulsating } = useSettingsStore();

  const stopBubbling = e => {
    e.stopPropagation();
  };

  const correctorClickHandler = async name => {
    await approveBlock(id, name);
  };

  const renderFooter = () =>
    footer ? (
      <div className={styles.footer}>
        {isBoolean(footer)
          ? isNotNil(orderNumber) && <span className={styles.orderNumber}>{orderNumber}</span>
          : footer}

        {maxSize && (
          <span className={cx(styles.maxSize, isLimitedByMaxSize && isPulsating && styles.pulse)}>
            {maxSize}
          </span>
        )}
      </div>
    ) : null;

  const handleForceUnlock = useCallback(() => {
    onUnlockBlock && onUnlockBlock({ id, lockedBy, force: true });
  }, [onUnlockBlock, id, lockedBy]);

  const onKeyDownHandler = useCallback(
    (e: React.KeyboardEvent) => {
      onKeyDown && onKeyDown(e, id);
    },
    [onKeyDown, id],
  );

  const onClickHistory = useCallback(() => {
    history.push('/changelog');
    setFilterValue('filter.resourceId', id);
    setFilterValue('filter.resourceType', 'blocks');
  }, [history, id, setFilterValue]);

  const lockPopover = useMemo(() => {
    return (
      <div className={styles.popover}>
        {lockedBy && <User {...lockedBy} />}
        {onUnlockBlock && canUnlockBlock && (
          <Button onClick={handleForceUnlock}>Разблокировать</Button>
        )}
      </div>
    );
  }, [handleForceUnlock, lockedBy, onUnlockBlock]);

  const blockInfoPopover = useMemo(() => {
    return (
      <div className={styles.blockInfo}>
        {withCopyId && (
          <Tooltip title="Скопировать идентификатор блока">
            <h3 className={styles.icon}>
              <LinkOutlined
                onClick={() => {
                  write(id || EMPTY_STRING).then(() => {
                    notification.success({ message: 'Скопировано в буфер обмена!' });
                  });
                }}
              />
            </h3>
          </Tooltip>
        )}
        {withViewChanges && checkPermissions('histories.view') && (
          <Tooltip title="Перейти в журнал изменения">
            <h3 className={styles.icon}>
              <HistoryOutlined onClick={onClickHistory} />
            </h3>
          </Tooltip>
        )}
      </div>
    );
  }, [id, onClickHistory, user.role, withCopyId, withViewChanges]);

  return (
    <div
      onMouseUp={onMouseUp}
      className={cx(styles.blk, classNames.block, blocked && styles.blocked)}
      id={id}
    >
      <Spin spinning={isFetching}>
        <div className={cx(styles['block--head'], classNames.head)}>
          <div className={styles.title}>
            {blocked && (
              <Popover content={lockPopover}>
                <h2 className={styles.icon}>
                  <LockOutlined />
                </h2>
              </Popover>
            )}
            {isEditing && (
              <Tooltip title="Заблокировано вами">
                <h2 className={styles.icon}>
                  <EditOutlined />
                </h2>
              </Tooltip>
            )}

            <Popover overlayClassName="clean-popover" content={blockInfoPopover} placement="right">
              <span className={styles.titleText}>{title}</span>
            </Popover>
          </div>

          {header && header}

          {type && !NonValidatableBlocks.includes(type) && (
            <div
              className={styles.validItemWrapper}
              onMouseUp={stopBubbling}
              onMouseDown={stopBubbling}
              onClick={stopBubbling}
            >
              <Validated
                corrector={validatedBy?.corrector}
                validatedAt={validatedAt}
                onClick={correctorClickHandler}
              />
            </div>
          )}
        </div>

        <div
          className={cx(styles['block--content'], classNames.content)}
          onKeyDown={onKeyDownHandler}
        >
          <div
            className={cx(styles.overlay, blocked && styles.blocked, readOnly && styles.readOnly)}
          />
          <div className={cx(styles.wrapper, wrapperClassname, classNames.contentWrapper)}>
            {children}
          </div>
        </div>

        <div className={cx(styles['block--footer'], classNames.footer)}>
          {footerComponent || renderFooter()}
        </div>
      </Spin>
    </div>
  );
});

export default BlockWrapper;
