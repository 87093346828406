import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import classNames from 'classnames'
import DayPicker from 'react-day-picker'

import styles from './styles.scss'

import '~stylesheets/calendar/sideCalendar.scss'

export const WEEKDAYS_SHORT = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

export const modifiers = {
  weekend: {
    daysOfWeek: [6, 0]
  }
}

@inject(stores => {
  const { setSelectedDate, selectedDate } = stores.ScheduleStore

  return {
    setSelectedDate,
    selectedDate
  }
})
@observer
class SideCalendar extends Component {
  render() {
    const { selectedDate, className, ...props } = this.props

    return (
      <DayPicker
        className={classNames(className, styles.root)}
        firstDayOfWeek={1}
        months={MONTHS}
        weekdaysShort={WEEKDAYS_SHORT}
        showOutsideDays
        {...props}
      />
    )
  }
}

export default SideCalendar

SideCalendar.propTypes = {
  className: PropTypes.string,
  handleDayClick: PropTypes.func,
  onClickDay: PropTypes.func,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func
}
