import { Button, Checkbox, Form, Input } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect } from 'react';
import { createModal } from 'react-modal-promise';

import { IHeading, InjectedModalProps } from '~common';
import { DeleteButton, ItemWithLabel, Modal } from '~components';
import s from '~containers/Subcategory/SubcategoriesModal/styles.scss';
import { useHeadingsStore, useUserStore } from '~hooks';

const CREATE_TITLE = 'Создание новой рубрики';
const UPDATE_TITLE = 'Редактирование рубрики';

const HeadingsModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;
  const {
    headings,
    editableHeading,
    isActionModal,
    resetEditableHeading,
    createHeading,
    updateHeadingById,
    deleteHeadingById,
  } = useHeadingsStore();
  const { checkPermissions } = useUserStore();

  const [form] = Form.useForm<IHeading>();

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const heading = {
        data: {
          ...values,
        },
        meta: {
          before: headings.length > 0 ? headings[0]?._id : null,
        },
      };
      if (editableHeading) {
        updateHeadingById(heading).then(handleClose);
      } else {
        createHeading(heading).then(handleClose);
      }
    });
  }, [editableHeading]);

  const handleDelete = () => {
    deleteHeadingById().then(handleClose);
  };

  const handleClose = useCallback(() => {
    resetEditableHeading && resetEditableHeading();
    form.resetFields();
    onClose(true);
  }, [form]);

  const ModalTitle = editableHeading ? UPDATE_TITLE : CREATE_TITLE;

  return (
    <Modal destroyOnClose width={590} onClose={handleClose} isVisible={isOpen} title={ModalTitle}>
      <Form form={form}>
        <ItemWithLabel label="Название рубрики">
          <Form.Item
            name="title"
            initialValue={get(editableHeading, 'title')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите название" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Ссылка для перехода">
          <Form.Item
            name="link"
            initialValue={get(editableHeading, 'link')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите ссылку для перехода" />
          </Form.Item>
        </ItemWithLabel>

        <Form.Item
          name="visible"
          initialValue={get(editableHeading, 'visible') || false}
          valuePropName="checked"
        >
          <Checkbox>Показывать в Актуальном</Checkbox>
        </Form.Item>

        <Form.Item
          name="isOnHeader"
          initialValue={get(editableHeading, 'isOnHeader') || false}
          valuePropName="checked"
        >
          <Checkbox>Видимость на хедере</Checkbox>
        </Form.Item>

        <div className={s.modalControls}>
          <Button
            onClick={handleSubmit}
            type="primary"
            loading={isActionModal}
            disabled={!checkPermissions(`headings.${editableHeading ? 'update' : 'create'}`)}
          >
            Сохранить изменения
          </Button>
          {editableHeading && (
            <div className={s.deleteBtnWrapper}>
              <DeleteButton
                text="Удалить рубрику"
                onDelete={handleDelete}
                disabled={!checkPermissions('headings.delete')}
              />
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
});

export const openHeadingsModal = createModal(HeadingsModal);
