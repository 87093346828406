import { StopTwoTone } from '@ant-design/icons';
import { Avatar, Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';

import { IComment } from '~common';
import { StatusLabel } from '~components';
import { EMPTY_STRING } from '~constants';
import { editCommentModal } from '~containers';
import { useCommentsStore, useUserStore } from '~hooks';
import { getTimeByString } from '~utils';

import styles from './styles.scss';

interface CommentItemProps {
  comment: IComment;
}

export const CommentItem = observer((props: CommentItemProps) => {
  const [isBlockingUser, setIsBlockingUser] = useState(false);
  const [isApprovingComment, setIsApprovingComment] = useState(false);
  const [isDecliningComment, setIsDecliningComment] = useState(false);
  const { getStatus, approveComment, declineComment, blockUser } = useCommentsStore();
  const { checkPermissions } = useUserStore();

  const { comment } = props;
  const { content, page, createdAt, createdBy } = comment;
  const commentId = comment?._id;
  const userId = createdBy?._id;
  const pageId = page?._id;

  const status = useMemo(() => getStatus(comment.status), [comment.status, getStatus]);
  const isCommentDeclined = useMemo(() => comment.status === 'COMMENT_STATUS_DECLINED', [
    comment.status,
  ]);
  const isCommentApproved = useMemo(() => comment.status === 'COMMENT_STATUS_APPROVED', [
    comment.status,
  ]);
  const authorName = useMemo(() => createdBy?.name || EMPTY_STRING, [createdBy?.name]);
  const avatar = useMemo(() => createdBy?.avatar || EMPTY_STRING, [createdBy?.avatar]);
  const isAuthorBanned = useMemo(() => !createdBy?.active || false, [createdBy?.active]);
  const createDate = useMemo(() => getTimeByString(createdAt), [createdAt]);

  const handleLoadPublication = useCallback(() => {
    window.open(`/publications/${pageId}`);
  }, [pageId]);

  const handleDeclineComment = useCallback(() => {
    setIsDecliningComment(true);
    declineComment(commentId).then(() => {
      setIsDecliningComment(false);
    });
  }, [commentId, declineComment]);

  const handleApproveComment = useCallback(() => {
    setIsApprovingComment(true);
    approveComment(commentId).then(() => {
      setIsApprovingComment(false);
    });
  }, [approveComment, commentId]);

  const handleEditComment = useCallback(() => {
    // @ts-ignore
    editCommentModal({ comment });
  }, [comment]);

  const handleBlockUser = useCallback(() => {
    setIsBlockingUser(true);
    blockUser(commentId, userId).then(() => {
      setIsBlockingUser(false);
    });
  }, [blockUser, commentId, userId]);

  return (
    <div className={styles.root}>
      <div className={styles.statusWrapper}>
        <div className={styles.date}>{createDate}</div>
        {status && <StatusLabel color={status.color} label={status.ru} />}
      </div>

      <div className={styles.publicationWrapper}>
        <h3 className={styles.publicationHeader}>{page?.title || EMPTY_STRING}</h3>
        <Button type="link" onClick={handleLoadPublication} className={styles.publicationLink}>
          Смотреть пост
        </Button>
      </div>

      <div className={styles.avatarWrapper}>
        <Row justify="start" align="middle">
          <Col span={2}>
            <Avatar size={44} src={avatar}>
              {authorName[0]}
            </Avatar>
          </Col>
          <Col>
            <div>
              {authorName}
              {isAuthorBanned && <StopTwoTone twoToneColor="#ff0a0a" className={styles.banned} />}
            </div>
            <Button
              type="link"
              disabled={isBlockingUser || isAuthorBanned || !checkPermissions('users.update')}
              onClick={handleBlockUser}
              className={styles.blockUser}
            >
              Заблокировать пользователя
            </Button>
          </Col>
        </Row>
      </div>

      <div className={styles.contentWrapper}>{content}</div>

      <div className={styles.actionsWrapper}>
        <Row justify="start" align="middle">
          <Col>
            <Button
              type="link"
              disabled={!checkPermissions('comments.update')}
              onClick={handleEditComment}
              className={styles.editComment}
            >
              Редактировать
            </Button>
          </Col>
          <Col>
            <Button
              type="link"
              disabled={
                isDecliningComment || isCommentDeclined || !checkPermissions('comments.update')
              }
              onClick={handleDeclineComment}
              className={styles.declineComment}
            >
              Заблокировать
            </Button>
          </Col>
          <Col offset={1}>
            <Button
              type="link"
              disabled={
                isApprovingComment || isCommentApproved || !checkPermissions('comments.update')
              }
              onClick={handleApproveComment}
              className={styles.approveComment}
            >
              Опубликовать
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
});
