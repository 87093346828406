import { useCallback, useMemo, useState } from 'react';

import { FileTransportLayer } from '~services';
import { isNotNil, showErrorNotification } from '~utils';

const defaultMessage = 'Ожидание очереди на обработку файла';

export const useCheckStatusUpload = onSuccess => {
  const [status, setStatus] = useState<string | number | null | undefined>();
  const isFetching = useMemo(() => isNotNil(status) && status !== 0, [status]);

  const checkStatus = useCallback(
    async (jobId: number) => {
      setStatus(defaultMessage);

      const { finishedOn, failedReason, progress, returnvalue: response } =
        (await FileTransportLayer.checkStatus(jobId)) || {};

      if (failedReason) {
        console.error(failedReason);
        setStatus(null);
        showErrorNotification('Ошибка загрузки файла', failedReason);
      } else if (finishedOn) {
        setStatus(null);
        onSuccess && onSuccess(response);
      } else {
        setStatus(progress || defaultMessage);
        await checkStatus(jobId);
      }
    },
    [onSuccess],
  );

  return { checkStatus, status, isFetching };
};
