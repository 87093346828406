import { observable, action, runInAction } from 'mobx'
import API from '~api'

const EMAIL_URL = '/emails'

class EmailsStore {
  @observable emails = []

  @observable dictionaries = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editableEmail = null

  @action
  fetchDictionaries = () => {
    const promise = API.get('/dictionaries/settings')

    return promise.then(res => {
      const { data } = res

      runInAction(() => {
        this.dictionaries = data.emailTypes
      })
    })
  }

  @action
  fetchEmails = () => {
    const fetchEmailsPromise = API.get(EMAIL_URL)

    return fetchEmailsPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.emails = data
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  createEmail = email => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createEmailPromise = API.post(EMAIL_URL, email)

    return createEmailPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.emails.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  updateEmail = email => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableEmail
    const updateEmailPromise = API.patch(`${EMAIL_URL}/${_id}`, email)

    return updateEmailPromise
      .then(res => {
        const { data } = res.data
        const editableEmailIndex = this.emails.indexOf(this.editableEmail)

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.emails[editableEmailIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteEmail = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableEmail
    const deleteEmailPromise = API.delete(`${EMAIL_URL}/${_id}`)

    return deleteEmailPromise
      .then(() => {
        this.emails.remove(this.editableEmail)

        this.resetEditableEmail()
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditableEmail = value => {
    this.editableEmail = value

    this.setShowModal()
  }

  @action
  resetEditableEmail = () => {
    this.editableEmail = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new EmailsStore()
