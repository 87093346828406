import { observer } from 'mobx-react';
import React from 'react';

import { IHeaderPreview } from '~common';
import { Image, WhiteBlockWithShadow } from '~components';
import styles from '~containers/Categories/CategoriesItem/styles.scss';
import { useHeaderPreviewsStore } from '~hooks';

import s from './styles.scss';

const HeaderPreviewsItem: React.FC<{ data: IHeaderPreview }> = observer(({ data }) => {
  const { setEditableHeaderPreview } = useHeaderPreviewsStore();

  const { title, link, cover, isOnHeader } = data;

  const plainTitle = title => {
    try {
      return JSON.parse(title)?.rawText?.blocks[0].text;
    } catch (e) {
      return '';
    }
  };

  const handleClick = () => {
    setEditableHeaderPreview && setEditableHeaderPreview(data);
  };

  return (
    <WhiteBlockWithShadow>
      <div className={s.card} onClick={handleClick}>
        <h2 className={s.cardTitle}>Заголовок превью:</h2>
        <h3 className={s.cardLink}>{plainTitle(title)}</h3>
        <h3 className={s.cardSubtitle}>Ссылка для перехода:</h3>
        <h3 className={s.cardLink}>{link}</h3>
        <div className={s.cardItem}>
          {cover && <Image className={styles.cardItemCover} url={cover?.url} alt="Cover" />}
          <div>
            <h3 className={s.cardItemTitle}>Видимость на хедере:</h3>
            <p className={s.cardItemValue}>{isOnHeader ? 'Да' : 'Нет'}</p>
          </div>
        </div>
      </div>
    </WhiteBlockWithShadow>
  );
});

export default HeaderPreviewsItem;
