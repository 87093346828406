import { ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import { cond, is, isNil } from 'ramda';

export * from './isNeedReplace';
export const convertValueFromProps = cond<any, ContentState>([
  [isNil, () => ContentState.createFromText('')],
  [is(Object), convertFromRaw],
  [
    is(String),
    (value: string) => {
      if (value.match(/(<\w*)((\s\/>)|(.*<\/\w*>))/g)) {
        const blocksFromHTML = convertFromHTML(value);

        return blocksFromHTML.contentBlocks.length > 0
          ? ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap,
            )
          : ContentState.createFromText('');
      }
      try {
        const rawData = JSON.parse(value);
        return convertFromRaw(rawData);
      } catch (e) {
        return ContentState.createFromText('');
      }
    },
  ],
]);
