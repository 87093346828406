import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';

import { FilterItem } from '~common';

interface Props {
  item: FilterItem;

  onChange(name: string, value: any): void;

  value: string;
}

export const Date: React.FC<Props> = ({ onChange, value, item }) => {
  const { name } = item;
  const date = value ? moment(value) : null;

  const handleChange = useCallback(
    (event, value) => {
      onChange(name, value || null);
    },
    [name, onChange],
  );

  return (
    <DatePicker
      style={{
        width: '100%',
      }}
      onChange={handleChange}
      value={date}
    />
  );
};
