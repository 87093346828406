import { v0 } from 'life-schema';
import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { allPass, apply, complement, equals, isNil, juxt, path, pipe, prop } from 'ramda';
import { EMPTY_STRING } from '~constants';
import { isNotNil } from '~utils';

/** ToDo check if export default is observable */
import UserStore from '../UserStore';
import PublicationsStore from './index';

export default class Block {
  id = null;

  @observable blocked = false;

  @observable lockedBy = { _id: '' };

  @observable lockedTo = '';

  @observable createdAt = '';

  @observable updatedAt = '';

  @observable validatedBy = {
    corrector: { _id: '' },
  };

  @observable validatedAt = { corrector: '' };

  @observable type = '';

  @observable content = '';

  @observable compiled = '';

  @observable data = {};

  @observable isError = false;

  @observable isCorrect = true;

  @observable isEditing = false;

  @observable isFetching = false;

  @observable isForceUpdate = false;

  @observable hash = null;

  @computed get order() {
    return PublicationsStore.publicationBlocksOrder.indexOf(this.id) + 1;
  }

  @computed get me() {
    return UserStore?.user || null;
  }

  constructor(id, data) {
    this.id = id;

    this.type = data.type;

    try {
      this.content = data.content || EMPTY_STRING;
      this.data = JSON.parse(this.content || '{}');
      this.blocked = this.isLockedByOthers(data);
      this.isEditing = this.isLockedByMe(data);
      this.isForceUpdate = data.isForceUpdate ?? false;
      this.lockedBy = data.lockedBy;
      this.lockedTo = data.lockedTo;
      this.createdAt = data.createdAt;
      this.updatedAt = data.updatedAt;
      this.validatedBy = data.validatedBy;
      this.validatedAt = data.validatedAt;
      this.hash = data.hash;
      this.isCorrect = !!data.validatedBy.corrector;
    } catch (e) {
      console.error(e);
    }

    this.correctorHandler = reaction(
      () => this.validatedBy,
      validatedBy => {
        this.isCorrect = !!validatedBy.corrector;
      },
    );

    reaction(
      () => this.lockedBy,
      lockedBy => {
        this.blocked = this.isLockedByOthers(this);
        this.isEditing = this.isLockedByMe(this);
      },
      {
        name: 'lockedBy change reaction',
      },
    );

    reaction(
      () => ({
        isFetching: this.lockProcessing,
      }),
      ({ isFetching }) => {
        this.isFetching = isFetching;
      },
      {
        name: 'isFetching reaction',
      },
    );
  }

  @action
  setContent(data) {
    this.content = JSON.stringify(data);
    this.data = data;
    this.isForceUpdate = false;
  }

  @observable lockProcessing = false;

  /**
   *
   * @param isLocked {boolean}
   */
  @action
  setLockProcessing = isLocked => {
    // console.log('setLockProcessing', isLocked)
    this.lockProcessing = isLocked;
  };

  @action
  setLockedToNoone = () => {
    this.lockedBy = null;
    this.lockedTo = '';
  };

  @action
  setForceUpdate = isForceUpdate => {
    this.isForceUpdate = isForceUpdate;
  };

  @action
  lock() {
    if (this.lockProcessing) return Promise.resolve();
    this.setLockProcessing(true);
    return PublicationsStore.apiLayer
      .lockBlock(this.id)
      .then(
        ({
          data: {
            data: { lockedBy, lockedTo, content, updatedAt, hash },
          },
        }) => {
          content && this.setContent(JSON.parse(content));
          runInAction(() => {
            this.lockedBy = lockedBy;
            this.lockedTo = lockedTo;
            this.updatedAt = updatedAt;
            this.hash = hash;
          });
        },
      )
      .catch(e => {
        return Promise.reject(e);
      })
      .finally(() => {
        this.setLockProcessing(false);
      });
  }

  @action
  unlock() {
    this.setLockProcessing(true);
    return PublicationsStore.apiLayer
      .unlockBlock(this.id)
      .then(() => {
        this.setLockedToNoone();
      })
      .finally(() => {
        this.setLockProcessing(false);
      });
  }

  checkBlocking = () => {
    const currentTime = new Date();
    const blockedTo = new Date(this.lockedTo);
    if (blockedTo - currentTime < 60000) {
      this.save();
    }
  };

  // WithoutLock for TEXT STREAM type publications. Where is no lock
  @action.bound
  async save() {
    console.log('Saving block...', this.id);
    const content = JSON.stringify(toJS(this.data));

    const dataToCompile = {
      _id: this.id,
      type: this.type,
      content,
      compiled: null,
    };

    let compiled = null;
    try {
      const convertResult = await v0.convertBlock(dataToCompile);
      compiled = convertResult?.compiled || null;
    } catch (e) {
      console.error(e);
    }

    return {
      blockId: this.id,
      hash: this.hash,
      data: {
        content,
        compiled,
        type: this.type,
      },
    };
  }

  delete() {
    this.setLockProcessing(true);
    PublicationsStore.apiLayer.deleteBlock(this.id).finally(() => {
      this.setLockProcessing(false);
    });
    this.remove();
  }

  remove() {
    PublicationsStore.removeBlock(this);
  }

  dispose() {
    typeof this.correctorHandler === 'function' && this.correctorHandler();
  }

  /** ToDo reuse */

  getMe = () => path(['_id'], this.me);

  isLocked = pipe(prop('lockedBy'), isNotNil);

  isNotLocked = pipe(prop('lockedBy'), isNil);

  isMe = pipe(juxt([path(['lockedBy', '_id']), this.getMe]), apply(equals));

  isNotMe = complement(this.isMe);

  isLockedByMe = allPass([this.isLocked, this.isMe]);

  isLockedByOthers = allPass([this.isLocked, this.isNotMe]);
}
