export const POST_TYPES = [
  'POST_TYPE_NEWS',
  'POST_TYPE_OPINION',
  'POST_TYPE_LONGREAD',
  'POST_TYPE_WIDELONGREAD',
  'POST_TYPE_ARTICLE'
]

export const RESOURCE_TYPES = ['RESOURCE_TYPE_PUBLICATION', 'RESOURCE_TYPE_TEXT_STREAM']

export const POST_ADV_TYPES = [
  'POST_ADV_TYPE_NONE',
  'POST_ADV_TYPE_SPECIAL',
  'POST_ADV_TYPE_PARTNER'
]

export const POST_PRIORITIES = [
  'POST_PRIORITY_LENTA',
  'POST_PRIORITY_1B',
  'POST_PRIORITY_1A',
  'POST_PRIORITY_2A',
  'POST_PRIORITY_3A',
  'POST_PRIORITY_2B',
  'POST_PRIORITY_1C',
  'POST_PRIORITY_2C',
  'POST_PRIORITY_MAIN_LENTA',
  'POST_PRIORITY_MAIN_LENTA_A',
  'POST_PRIORITY_MAIN_LENTA_B',
  'POST_PRIORITY_HIDDEN'
]

export const POST_STATUSES = [
  'POST_STATUS_DRAFT',
  'POST_STATUS_PUBLISHED',
  'POST_STATUS_UNPUBLISHED',
  'POST_STATUS_POSTPONED'
]
