import { Select } from 'antd';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import {
  useAuthorsStore,
  useFlowsStore,
  useRegionsStore,
  useSubcategoriesStore,
  useTagsStore,
} from '~hooks';

const { Option } = Select;

interface IItem {
  label: string;
  name: string;
  type: string;
}

interface IFetchOptions {
  fetchCategoriesFilter?: string;
  fetchFlowsFilter?: string;
  fetchRegionsFilter?: string;
  fetchSectionFilter?: string;
  fetchSubcategoriesFilter?: string;
  fetchTagsFilter?: string;
}

interface IProps {
  className?: string;
  fetchOptions?: IFetchOptions;
  item: IItem;
  labelInValue?: boolean;

  onChange?(value: unknown): void;

  value?: unknown;
}

const SelectRender: React.FC<IProps> = observer(props => {
  const { onChange, value, item, labelInValue = false, fetchOptions = {}, ...propsSelect } = props;

  const { fetchTagsBySearch, tagsSearches } = useTagsStore();
  const { fetchFlowsBySearch, flowsSearches } = useFlowsStore();
  const {
    fetchCategoriesBySearch,
    fetchSectionsBySearch,
    categoriesSearches,
    sectionsSearches,
  } = useAuthorsStore();
  const { fetchSubcategoriesBySearch, subcategoriesSearches } = useSubcategoriesStore();
  const { fetchRegionsBySearch, regionsSearches } = useRegionsStore();

  const fetchDataByType = useMemo(() => {
    const { fetchTagsFilter = '' } = fetchOptions;

    if (item.name === 'filter.tags') {
      return {
        fetch: fetchTagsBySearch,
        fetchFilter: fetchTagsFilter,
        options: tagsSearches,
        key: '_id',
        label: 'title',
      };
    }

    if (item.name === 'filter.categories') {
      return {
        fetch: fetchCategoriesBySearch,
        options: categoriesSearches,
        key: '_id',
        label: 'title',
      };
    }

    if (item.name === 'filter.subcategories') {
      return {
        fetch: fetchSubcategoriesBySearch,
        options: subcategoriesSearches,
        key: '_id',
        label: 'title',
        category: 'category',
      };
    }

    if (item.name === 'filter.section') {
      return {
        fetch: fetchSectionsBySearch,
        options: sectionsSearches,
        key: '_id',
        label: 'title',
      };
    }
    if (item.name === 'filter.flows') {
      return {
        fetch: fetchFlowsBySearch,
        options: flowsSearches,
        key: '_id',
        label: 'title',
      };
    }
    if (item.name === 'filter.regions') {
      return {
        fetch: fetchRegionsBySearch,
        options: regionsSearches,
        key: '_id',
        label: 'title',
      };
    }

    return {};
  }, [
    item.name,
    fetchTagsBySearch,
    tagsSearches,
    fetchCategoriesBySearch,
    categoriesSearches,
    fetchSubcategoriesBySearch,
    subcategoriesSearches,
    fetchSectionsBySearch,
    sectionsSearches,
    fetchFlowsBySearch,
    flowsSearches,
    fetchRegionsBySearch,
    regionsSearches,
  ]);

  const handleSelectChange = useCallback(
    value => {
      const { fetch, fetchFilter = '' } = fetchDataByType;

      onChange && onChange(value);

      if (!value) {
        fetch && fetch('', fetchFilter);
      }
    },
    [fetchDataByType, onChange],
  );

  const handleSearch = debounce(term => {
    const { fetch, fetchFilter = '' } = fetchDataByType;

    fetch && fetch(term, fetchFilter);
  }, 300);

  const handleBlur = () => {
    const { fetch, fetchFilter = '' } = fetchDataByType;
    if (fetch) {
      fetch && fetch('', fetchFilter);
    }
  };

  const { options, key, label, category } = fetchDataByType;

  return (
    <Select
      style={{
        width: '100%',
      }}
      labelInValue={labelInValue}
      showSearch
      allowClear
      onChange={handleSelectChange}
      onSearch={handleSearch}
      // @ts-ignore
      value={value}
      placeholder="Выбрать"
      optionFilterProp="children"
      onBlur={handleBlur}
      {...propsSelect}
    >
      {/* @ts-ignore */}
      {options?.map(option => {
        return (
          <Option value={option[key || '']}>
            {option[label || '']}
            {category && ' ('.concat(option[category]?.title, ')')}
          </Option>
        );
      })}
    </Select>
  );
});

export default SelectRender;
