import { Col, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { BlockType, IBlock, IMeta } from '~common';
import { ItemWithLabel } from '~components';
import { disabledButtonsInStandardInput, RichEditor } from '~components/RichEditor';
import { EMPTY_STRING } from '~constants';
import { useBlockStateReducer } from '~hooks';

import { Textarea } from '../../Textarea';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

interface IProps extends IBlock {
  alt?: string;
  content?: string;
  onChange(block: IBlock & { content: unknown; meta: IMeta }): void;
  placeholder?: string;
}

type TState = Pick<IProps, 'content' | 'alt'>;

const WidgetBlock: React.FC<IProps> = props => {
  const {
    id = EMPTY_STRING,
    blockProps = { title: 'Код виджета' },

    placeholder = 'Вставьте код',
    content = EMPTY_STRING,
    alt = EMPTY_STRING,
    onChange,
    isFetching = false,
    isEditing = false,
    isForceUpdate = false,
  } = props;

  const initialState: TState = {
    content,
    alt,
  };

  const data: TState = useMemo(
    () => ({
      alt,
      content,
    }),
    [alt, content],
  );

  const [state, dispatch] = useBlockStateReducer<TState>({
    blockId: id,
    blockType: BlockType.WidgetBlock,
    incomingData: data,
    initialState,
    isEditing,
    isForceUpdate,
    onChange,
  });

  const handleChange = useCallback(
    ({ raw: content }) => {
      dispatch({
        content,
      });
    },
    [dispatch],
  );

  const onChangeAlt = useCallback(
    ({ raw: alt }) => {
      dispatch({
        alt: JSON.stringify(alt),
      });
    },
    [dispatch],
  );

  return (
    <BlockWrapper {...blockProps} id={id}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Textarea
            className={styles.textarea}
            placeholder={placeholder}
            value={state.content}
            onChange={handleChange}
            readOnly={isFetching}
            isEditing={isEditing}
          />
        </Col>

        <Col span={24}>
          <div className={styles.title}>Предпросмотр</div>
          <div className={styles.wrapper}>
            <div dangerouslySetInnerHTML={{ __html: state.content ?? '' }} />
          </div>
        </Col>

        <Col span={24}>
          <ItemWithLabel label="Подпись" labelClass={styles.captionLabel}>
            <RichEditor
              className={styles.input}
              rawContent={state.alt}
              disabledButtons={disabledButtonsInStandardInput}
              onChange={onChangeAlt}
              readOnly={isFetching}
              isEditing={isEditing}
              placeholder="Введите текст"
            />
          </ItemWithLabel>
        </Col>
      </Row>
    </BlockWrapper>
  );
};

export default WidgetBlock;
