import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React from 'react';

import { IDictionary } from '~common';
import { ItemWithLabel } from '~components';
import { resourceTypes } from '~constants';
import { usePublicationStore, useUserStore } from '~hooks';
import { getAuthorityByResourceType } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const PrioritySelect = observer(() => {
  const { priority, dictionariesList, resourceType, set } = usePublicationStore();
  const { checkPermissions } = useUserStore();

  const postPriorities: IDictionary[] = dictionariesList?.dictionaries?.postPriorities ?? [];
  const options: LabeledValue[] = postPriorities
    .filter(({ availableResourceType = [] }) => availableResourceType.includes(resourceType))
    .map(postType => ({
      value: postType.id,
      label: postType.ru,
    }));

  const onChange = type => {
    set({ name: 'priority', value: type });
  };

  return (
    <ItemWithLabel label="Приоритет поста">
      <Select
        style={inputStyle}
        placeholder="Выберите приоритет"
        value={priority}
        options={options}
        onChange={onChange}
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
