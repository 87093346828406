import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'
import Image from '~components/Image'

import s from './styles.scss'

@inject('SpecProjectsStore')
@observer
class SpecProjectsItem extends Component {
  handleClick = () => {
    const {
      SpecProjectsStore: { setEditableSpecProjects },
      data
    } = this.props

    setEditableSpecProjects(data)
  }

  render() {
    const {
      data: { title, description, url, visible, cover, flags: { isMain = false } = {} }
    } = this.props

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{title}</h2>
          <div className={s.cardItem}>
            {cover && <Image className={s.cardItemCover} url={cover.url} alt="Cover" />}
            <div className={s.cardItem}>
              <h3 className={s.cardItemTitle}>Описание:</h3>
              <p className={s.cardItemValue}>{description}</p>
            </div>
          </div>

          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Ссылка на спец.проект:</h3>
            <p className={s.cardItemValue}>{url}</p>
          </div>

          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Видимость на сайте:</h3>
            <p className={s.cardItemValue}>{visible ? 'Да' : 'Нет'}</p>
          </div>

          {isMain && (
            <div className={s.cardItem}>
              <h3 className={s.cardItemTitle}>Главный проект:</h3>
              <p className={s.cardItemValue}>Да</p>
            </div>
          )}
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

SpecProjectsItem.propTypes = {
  SpecProjectsStore: PropTypes.any,
  data: PropTypes.object
}

export default SpecProjectsItem
