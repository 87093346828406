import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { ContentWrapper, HeaderPageSearchBar, InfinityScroll, Sorting } from '~components';
import { FeedbackFilter, FeedbackList } from '~containers/Feedbacks';
import { useFeedbackStore, useFetchPaginationStore } from '~hooks';

import styles from './styles.scss';

const SORTING = [
  {
    label: 'По дате отправления',
    type: 'createdAt',
  },
  {
    label: 'По названию',
    type: 'subject',
  },
];

const FeedbackPage = observer(() => {
  const {
    fetchDictionaries,
    setIsFirstLoading,
    fetchFeedbacks,
    allItemsCount,
    feedbacksLength,
    isFirstLoading,
    isLoadingMore,
  } = useFeedbackStore();
  const { resetPaginationParams } = useFetchPaginationStore();

  useEffect(() => {
    fetchDictionaries();
  }, []);

  const handleSearchPublications = useCallback(() => {
    setIsFirstLoading(true);
    resetPaginationParams();
    fetchFeedbacks();
  }, [fetchFeedbacks, resetPaginationParams, setIsFirstLoading]);

  return (
    <>
      <HeaderPageSearchBar searchButtonText="Найти в письмах" onSearch={handleSearchPublications} />
      <ContentWrapper
        title="Обратная связь"
        countItems={allItemsCount}
        countDeclinations={['письмо', 'письма', 'писем']}
      >
        <FeedbackFilter />
        <Sorting className={styles.sorting} onSort={handleSearchPublications} data={SORTING} />
        <InfinityScroll
          loadItems={fetchFeedbacks}
          isEndLoading={allItemsCount <= feedbacksLength}
          isFirstLoading={isFirstLoading}
          isLoadMore={isLoadingMore}
        >
          <FeedbackList />
        </InfinityScroll>
      </ContentWrapper>
    </>
  );
});

export default FeedbackPage;
