import { Select } from 'antd';
import { SelectProps } from 'antd/es';
import { debounce } from 'lodash';
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

const { Option } = Select;

interface IProps<T> extends Partial<SelectProps<T>> {
  fetch?(term?: string | boolean, filter?: unknown): void;
  filter?: unknown;
  keyField: string;
  labelField: string;
  onChange?(value: T): void;
  options: any[];
  optionsChildren?(option): React.ReactNode;
  value?: T;
}

export type ISelectProps<T> = Omit<IProps<T>, 'fetch' | 'options' | 'keyField' | 'labelField'>;

const SelectRender = <T,>(props: PropsWithChildren<IProps<T>>) => {
  const {
    filter,
    fetch,
    options,
    keyField,
    labelField,
    onChange,
    value,
    optionsChildren,
    ...selectProps
  } = props;

  useEffect(() => {
    fetch && fetch(false, filter);
  }, []);

  const handleSearch = useMemo(
    () =>
      debounce(term => {
        fetch && fetch(term, filter);
      }, 300),
    [fetch, filter],
  );

  const handleSelectChange = useCallback(
    value => {
      onChange && onChange(value);

      if (!value) {
        fetch && fetch();
      }
    },
    [fetch, onChange],
  );

  return (
    // @ts-ignore
    <Select
      style={{
        width: '100%',
      }}
      showSearch
      allowClear
      optionFilterProp="children"
      onChange={handleSelectChange}
      // @ts-ignore
      value={value}
      placeholder="Выбрать"
      onSearch={handleSearch}
      {...selectProps}
    >
      {options?.map(option => {
        return (
          <Option value={option[keyField]}>
            {optionsChildren ? optionsChildren(option) : option[labelField]}
          </Option>
        );
      })}
    </Select>
  );
};

export default React.memo(SelectRender) as typeof SelectRender;
