import { Button, Checkbox, Form, Input, Select, Switch } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { IFile, InjectedModalProps, IRegion, ITag } from '~common';
import { ButtonUpload, DeleteButton, ItemWithLabel, Modal } from '~components';
import { EMPTY_OBJECT, EMPTY_STRING } from '~constants';
import styles from '~containers/Tags/TagModal/styles.scss';
import { useFetchPaginationStore, useRegionsStore, useUserStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { getUrlFromFile } from '~utils';

const CREATE_TITLE = 'Создание нового региона';
const UPDATE_TITLE = 'Редактирование региона';

export const RegionModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;
  const {
    editableRegion,
    isActionModal,
    fetchRegions,
    resetEditableRegion,
    createRegion,
    updateRegion,
    deleteRegion,
  } = useRegionsStore();
  const { checkPermissions } = useUserStore();

  const { resetPaginationParams } = useFetchPaginationStore();

  const [form] = Form.useForm<IRegion>();
  const [cover, setCover] = useState(editableRegion?.cover?.url || EMPTY_STRING);

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const region = {
        data: {
          ...values,
          cover: getUrlFromFile(values?.cover),
        },
      };

      const action = editableRegion ? updateRegion : createRegion;
      action(region)
        .then(handleClose)
        .then(() => {
          resetPaginationParams();
          fetchRegions();
        });
    });
  }, [form, createRegion, editableRegion, updateRegion]);

  const handleClose = useCallback(() => {
    resetEditableRegion();
    form.resetFields();
    onClose(true);
  }, [form, resetEditableRegion]);

  const handleDelete = useCallback(() => {
    deleteRegion().then(handleClose);
  }, [deleteRegion]);

  const modalTitle = useMemo(() => (editableRegion ? UPDATE_TITLE : CREATE_TITLE), [
    editableRegion,
  ]);

  const modalButtons = useMemo(() => {
    return (
      <div className={styles.modalControls}>
        <Button
          key="submit"
          loading={isActionModal}
          onClick={handleSubmit}
          disabled={!checkPermissions(`regions.${editableRegion ? 'update' : 'create'}`)}
          type="primary"
        >
          Сохранить изменения
        </Button>
        {editableRegion && (
          <div className={styles.deleteBtnWrapper}>
            <DeleteButton
              text="Удалить регион"
              onDelete={handleDelete}
              disabled={!checkPermissions('regions.delete')}
            />
          </div>
        )}
      </div>
    );
  }, [isActionModal, handleSubmit, checkPermissions, editableRegion, handleDelete]);

  const fileUploadProps = useMemo(
    () => ({
      name: 'file',
      action: `${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`,
      withCredentials: true,
      data: { type: 'card' },
      accept: 'image/*',
    }),
    [FileTransportLayer.fileUploadUrl],
  );

  return (
    <Modal
      destroyOnClose
      width={600}
      bodyStyle={{ overflow: 'scroll', maxHeight: '80vh' }}
      isFooterHidden={false}
      centered
      isVisible={isOpen}
      title={modalTitle}
      footer={modalButtons}
      onClose={handleClose}
    >
      <div className={styles.root} id="divTagModalContainer" style={{ position: 'relative' }}>
        {editableRegion?.createdAt && (
          <ItemWithLabel label="Дата создания">
            <div className={styles.date}>
              {moment(editableRegion.createdAt).format('DD.MM.YYYY')}
            </div>
          </ItemWithLabel>
        )}
        <Form
          form={form}
          initialValues={editableRegion || EMPTY_OBJECT}
          validateTrigger="onSubmit"
          onFinish={handleSubmit}
        >
          <ItemWithLabel label="Название региона">
            <Form.Item name="title" rules={[{ required: true, message: 'Обязательное поле!' }]}>
              <Input autoFocus disabled={isActionModal} />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Родительный падеж">
            <Form.Item name="genitive" rules={[{ required: true, message: 'Обязательное поле!' }]}>
              <Input placeholder="Вы смотрите новости..." disabled={isActionModal} />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Псевдоним региона">
            <Form.Item name="alias">
              <Input placeholder="Будет создан автоматически" disabled />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Заголовок">
            <Form.Item
              name={['site', 'title']}
              initialValue={get(editableRegion, 'site.title')}
              rules={[{ required: false }]}
            >
              <Input placeholder="Введите заголовок" />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Описание">
            <Form.Item
              name={['site', 'description']}
              initialValue={get(editableRegion, 'site.description')}
              rules={[{ required: false }]}
            >
              <Input placeholder="Введите описание" />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="SEO заголовок">
            <Form.Item
              name={['seo', 'title']}
              initialValue={get(editableRegion, 'seo.title')}
              rules={[{ required: false }]}
            >
              <Input placeholder="Введите SEO заголовок" />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="SEO описание">
            <Form.Item
              name={['seo', 'description']}
              initialValue={get(editableRegion, 'seo.description')}
              rules={[{ required: false }]}
            >
              <Input placeholder="Введите SEO описание" />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Обложка">
            <>
              {cover && (
                <div>
                  <img className={styles.cardItemCover} src={cover} alt="Cover" />
                </div>
              )}
              <Form.Item name="cover" initialValue={get(editableRegion, 'cover')}>
                <ButtonUpload
                  uploadProps={fileUploadProps}
                  onChange={(data: IFile) => {
                    setCover(data?.url || EMPTY_STRING);
                  }}
                />
              </Form.Item>
            </>
          </ItemWithLabel>
          <Form.Item
            name="visible"
            initialValue={get(editableRegion, 'visible') || false}
            valuePropName="checked"
          >
            <Checkbox>Опубликован на сайте</Checkbox>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
});

export const openRegionModal = createModal(RegionModal);
