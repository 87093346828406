import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { ContentWrapper, HeaderPageSearchBar, InfinityScroll } from '~components';
import {
  EMPLOYEES_COUNT_DECLINATIONS,
  EMPLOYEES_CREATE_BTN_TEXT,
  EMPLOYEES_PAGE_TITLE,
  EMPLOYEES_SEARCH_BTN_TEXT,
} from '~constants';
import { EmployeeList, EmployeesModal } from '~containers';
import {
  useEmployeesStore,
  useFetchPaginationStore,
  useFilterParamsByListStore,
  useUserStore,
} from '~hooks';

const EmployeesPage = observer(() => {
  const {
    fetchEmployeePositions,
    setShowModal,
    fetchEmployees,
    allItemsCount,
    employeesLength,
    isFirstLoading,
    isLoadingMore,
    setIsFirstLoading,
  } = useEmployeesStore();
  const { checkPermissions } = useUserStore();
  const { setFilterParams } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();

  useEffect(() => {
    fetchEmployeePositions();

    return () => {
      setFilterParams();
      resetPaginationParams();
    };
  }, []);

  const handleSearchPublications = useCallback(() => {
    setIsFirstLoading(true);
    fetchEmployees();
  }, [fetchEmployees, setIsFirstLoading]);

  return (
    <>
      <HeaderPageSearchBar
        searchButtonText={EMPLOYEES_SEARCH_BTN_TEXT}
        createButtonText={EMPLOYEES_CREATE_BTN_TEXT}
        onSearch={handleSearchPublications}
      />
      <ContentWrapper
        title={EMPLOYEES_PAGE_TITLE}
        countItems={20}
        countDeclinations={EMPLOYEES_COUNT_DECLINATIONS}
      >
        <InfinityScroll
          loadItems={fetchEmployees}
          isEndLoading={allItemsCount <= employeesLength}
          isFirstLoading={isFirstLoading}
          isLoadMore={isLoadingMore}
        >
          <EmployeeList />
        </InfinityScroll>
      </ContentWrapper>

      <EmployeesModal />
    </>
  );
});

export default EmployeesPage;
