import PropTypes from 'prop-types'
import React from 'react'

import { Avatar } from 'antd'
import classNames from 'classnames'

import styles from './styles.scss'

const Person = ({ src, name, id, type, inline, onClick }) => {
  return (
    <div className={classNames(styles.root, onClick && styles.withHover)} onClick={onClick}>
      <Avatar size={28} src={src}>
        {name && name[0]}
      </Avatar>

      <div className={classNames(styles.personInfo, inline && styles.inline)}>
        <div className={styles.name}>{name}</div>

        {id && type && (
          <div className={styles.serviceInfo}>
            ID: {id}, {type}
          </div>
        )}
      </div>
    </div>
  )
}

export default Person

Person.propTypes = {
  id: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func
}
