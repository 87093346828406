import { observer } from 'mobx-react';
import React from 'react';

import { useEventTypeBackgroundColor, useScheduleStore } from '~hooks';

import styles from './styles.scss';

const MonthEvent = observer(({ event }) => {
  const { setShowModalInfo, setEditableEvent } = useScheduleStore();
  const { background, border } = useEventTypeBackgroundColor(event);

  const handleShowModalInfo = () => {
    setEditableEvent(event);
    setShowModalInfo();
  };

  return (
    <div
      onClick={handleShowModalInfo}
      className={styles.root}
      style={{
        color: 'red',
        background: `rgba(${background})`,
        borderLeft: `3px solid rgba(${border})`,
      }}
    >
      <div className={styles.title}>{event?.title}</div>
    </div>
  );
});

export default MonthEvent;
