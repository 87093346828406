import { SelectOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { BlockType, IBlockComponent } from '~common';
import { Editor } from '~components/Editor';
import { withErrorBoundary } from '~components/ErrorBoundary';
import {
  defaultBlockComponents,
  defaultBlocks,
  EMPTY_STRING,
  QUIZ_BLOCK_TYPE,
  resourceTypes,
  typeConstants,
} from '~constants';
import { usePublicationStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { noop } from '~utils';

const QuizBlock: IBlockComponent = {
  component: QUIZ_BLOCK_TYPE,
  type: BlockType.QuizBlock,
  icon: <SelectOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
  blockProps: {
    title: 'Опрос',
    postResourceType: resourceTypes.quiz,
  },
};

export const editorBlockComponents = [...defaultBlockComponents, QuizBlock];

interface IProps {
  onEditImage(): void;

  onKeyDown(): void;
}

export const EditorQuiz: React.FC<IProps> = observer(props => {
  const { onEditImage = noop, onKeyDown = noop } = props;

  const {
    blocksArray,
    mandatoryBlocksArray,
    createBlock,
    lockBlock,
    unlockBlock,
    changeBlockPosition,
    createBlocksFromArray,
    updateBlock,
    deleteBlockById,
    symbols,
    status,
  } = usePublicationStore();

  const onFocusBlock = useCallback(
    ({ id = EMPTY_STRING }) => {
      lockBlock(id);
    },
    [lockBlock],
  );

  const onDragBlock = useCallback(
    data => {
      changeBlockPosition(data);
    },
    [changeBlockPosition],
  );

  const handleUnblock = useCallback(
    ({ id = EMPTY_STRING }) => {
      unlockBlock(id);
    },
    [unlockBlock],
  );

  return (
    <Editor
      blocks={blocksArray}
      mandatoryBlocks={mandatoryBlocksArray}
      canAddBlock={false}
      onUnlockBlock={handleUnblock}
      onCreateBlock={createBlock}
      onCreateBlocksFromArray={createBlocksFromArray}
      onFocusBlock={onFocusBlock}
      onChangeBlock={updateBlock}
      onDragBlock={onDragBlock}
      onDeleteBlock={deleteBlockById}
      onEditImage={onEditImage}
      onKeyDown={onKeyDown}
      publicationStatus={status?.id}
      uploadPlaceholder={require('~assets/uploadPlaceholder.jpg')}
      uploadUrl={`${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`}
      toolbarBlocks={[QUIZ_BLOCK_TYPE]}
      defaultBlocks={[...defaultBlocks]}
      blockComponents={editorBlockComponents}
      maxSize={{
        [typeConstants.HEADER_BLOCK]: symbols.title,
        [typeConstants.IMAGE_BLOCK]: symbols.mediaCaption,
        [typeConstants.VIDEO_BLOCK]: symbols.mediaCaption,
      }}
    />
  );
});

export default withErrorBoundary(EditorQuiz);
