import prependHttp from 'prepend-http';

import mailRegex from './mailRegex';
import urlRegex from './urlRegex';

export default {
  isUrl: (text: string) => urlRegex({ exact: true }).test(text),

  isMail: (text: string) => mailRegex().test(text),

  normaliseMail: (email: string) => {
    if (email.toLowerCase().startsWith('mailto:')) {
      return email;
    }
    return `mailto:${email}`;
  },

  normalizeUrl: (url: string) => prependHttp(url.trim()),
};
