import React from 'react';

import { IFile } from '~common';

import { DropZone } from '../../DropZone';

interface IProps {
  files?: IFile[];
  uploadUrl?: string;
}

const MediaUpload: React.FC<IProps> = props => {
  return (
    <div>
      <DropZone uploadUrl={props.uploadUrl} onChange={console.warn} files={props.files} />
    </div>
  );
};

export default MediaUpload;
