import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { EditOutlined } from '@ant-design/icons';

import ImageLazyLoad from '~components/ImageLazyLoad';
import ClickAndViewButton from '~components/ClickAndViewButton';
import Person from '~components/Person';
import StatusLabel from '~components/StatusLabel';
import Validated from '~components/Publications/Validated';

import getTimeByString from '~utils/getTimeByString';
import getShortText from '~utils/getShortText';

import styles from './styles.scss';

// const AVATAR_SIZE = 60
const SHORT_TITLE_LENGTH = 100;

@inject(stores => {
  const { setEditableAuthor } = stores.AuthorsStore;
  const { getPreviewToken } = stores.PublicationsStore;

  return {
    setEditableAuthor,
    getPreviewToken,
  };
})
@observer
class PublicationsItemMobile extends Component {
  @observable isLoadingView = false;

  handleClickPerson = () => {
    const { publication, setEditableAuthor, history } = this.props;

    const firstAuthor = get(publication, 'author', {});

    setEditableAuthor(firstAuthor, 'info');
    history.push('/authors');
  };

  onClickView = () => {
    const {
      publication: { status, alias, index, _id },
    } = this.props;

    if (status.id === 'POST_STATUS_PUBLISHED') {
      window.open(`${process.env.PUBLIC_ORIGIN}/p/${alias || index}`, '_blank');
    } else {
      window.open(
        `${process.env.PUBLIC_ORIGIN}/preview/p/${alias || index}/${_id}`,
        '_blank',
      );
    }
  };

  render() {
    const {
      publication: {
        _id,
        title,
        updatedAt,
        // publicationDate,
        validatedBy,
        validatedAt,
        cover,
        status,
        author = {},
        // authorsCount = 1,
        type,
        index,
      },
      // viewType
    } = this.props;

    const shortTitle = getShortText(title, SHORT_TITLE_LENGTH);
    const updateDate = getTimeByString(updatedAt, 'DD.MM');
    // const publicationDateFormat = getTimeByString(publicationDate)
    const coverUrl = get(cover, 'url', '');
    const firstAuthor = author;
    const authorUrl = get(firstAuthor, 'avatar.url', '');
    // const moreAuthorsCount = authorsCount - 1

    return (
      <div className={styles.root}>
        <div className={styles.top}>
          <ImageLazyLoad width={68} height={51} src={coverUrl} />

          <div className={styles.title}>{shortTitle}</div>
        </div>

        <div className={styles.statusWrapper}>
          <div className={styles.serviceInfo}>
            ID: {index}, {type.ru}
          </div>

          <StatusLabel color={status.color} label={status.ru} />
        </div>

        <div className={styles.validatedWrapper}>
          <div className={styles.updateTime}>Изменено {updateDate}</div>
          <Validated isMobile {...validatedBy} validatedAt={validatedAt} />
        </div>

        <div className={styles.controls}>
          <Person
            inline
            src={authorUrl}
            name={firstAuthor.name}
            // id={index}
            // type={type.ru}
            onClick={this.handleClickPerson}
          />

          <ClickAndViewButton
            editLink={`/publications/${_id}`}
            onView={this.onClickView}
            isLoadingView={this.isLoadingView}
            clickButtonText={<EditOutlined />}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(PublicationsItemMobile);

PublicationsItemMobile.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
  publication: PropTypes.object,
  viewType: PropTypes.string,
  getPreviewToken: PropTypes.func,
  setEditableAuthor: PropTypes.func,
};
