import { Col, Popover, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { IRedirect } from '~common';
import { redirectResponseCodes } from '~constants';
import { useRedirectsStore } from '~hooks';
import { getShortText, getTimeByString } from '~utils';

import s from './styles.scss';

const TEXT_LENGTH = 15;

const getCollapsedText = (text): string => {
  return text?.length <= TEXT_LENGTH ? (
    text
  ) : (
    <Popover content={text}>{getShortText(text, TEXT_LENGTH)}</Popover>
  );
};

const RedirectsItem: React.FC<{ data: IRedirect }> = observer(({ data = {} }) => {
  const { setEditableRedirect, redirectTypes } = useRedirectsStore();

  const handleClick = () => {
    setEditableRedirect && setEditableRedirect(data);
  };

  return (
    <Row align="middle" gutter={24} className={s.historyRow} onClick={handleClick}>
      <Col span={4}>
        <Row>
          <Col>{getCollapsedText(data.fromLink)}</Col>
        </Row>
      </Col>
      <Col span={4}>
        <Row>
          <Col>{getCollapsedText(data.toLink)}</Col>
        </Row>
      </Col>
      <Col span={5}>
        <Row>
          <Col>{getCollapsedText(data.description)}</Col>
        </Row>
      </Col>
      <Col span={3}>
        <Row>
          <Col>{getTimeByString(data.updatedAt, 'DD/MM/YYYY')}</Col>
        </Row>
      </Col>
      <Col span={4}>
        <Row>
          <Col>
            {data?.type &&
              redirectTypes.find(({ id }) => {
                return id === data.type;
                // @ts-ignore
              })?.ru}
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <Row>
          <Col>
            {data?.status &&
              redirectResponseCodes.find(({ id }) => {
                return id === data.status;
              })?.ru}
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default RedirectsItem;
