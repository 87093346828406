import { Button, Checkbox, Form, Input } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { ICategory, IFile, InjectedModalProps } from '~common';
import { ButtonUpload, DeleteButton, ItemWithLabel, Modal } from '~components';
import { EMPTY_STRING } from '~constants';
import styles from '~containers/Tags/TagModal/styles.scss';
import { useCategoriesStore, useUserStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { getUrlFromFile } from '~utils';

import s from './styles.scss';

const CREATE_TITLE = 'Создание новой категории';
const UPDATE_TITLE = 'Редактирование категории';

const CategoriesModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;
  const {
    categories,
    editableCategory,
    isActionModal,
    resetEditableCategory,
    createCategory,
    updateCategory,
    deleteCategory,
  } = useCategoriesStore();
  const { checkPermissions } = useUserStore();
  const [form] = Form.useForm<ICategory>();

  console.log('editableCategory', editableCategory);

  const [cover, setCover] = useState(editableCategory?.cover?.url || EMPTY_STRING);

  const fileUploadProps = useMemo(
    () => ({
      name: 'file',
      action: `${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`,
      withCredentials: true,
      data: { type: 'card' },
      accept: 'image/*',
    }),
    [FileTransportLayer.fileUploadUrl],
  );

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const category = {
        data: {
          ...values,
          cover: getUrlFromFile(values?.cover),
        },
        meta: {
          before: categories.length > 0 ? categories[0]?._id : null,
        },
      };
      if (editableCategory) {
        updateCategory(category).then(handleClose);
      } else {
        createCategory(category).then(handleClose);
      }
    });
  }, [editableCategory]);

  const handleDelete = () => {
    deleteCategory().then(handleClose);
  };

  const handleClose = useCallback(() => {
    resetEditableCategory();
    form.resetFields();
    onClose(true);
  }, [form]);

  const ModalTitle = editableCategory ? UPDATE_TITLE : CREATE_TITLE;

  return (
    <Modal destroyOnClose width={590} onClose={handleClose} isVisible={isOpen} title={ModalTitle}>
      <Form form={form}>
        <ItemWithLabel label="Название категории">
          <Form.Item
            name="title"
            initialValue={get(editableCategory, 'title')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите название" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Описание категории">
          <Form.Item name="description" initialValue={get(editableCategory, 'description')}>
            <Input placeholder="Введите описание" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Псевдоним категории (латиница)">
          <Form.Item name="alias" initialValue={get(editableCategory, 'alias')}>
            <Input placeholder="Будет создан автоматически" disabled />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="SEO заголовок">
          <Form.Item
            name={['seo', 'title']}
            initialValue={get(editableCategory, 'seo.title')}
            rules={[{ required: false }]}
          >
            <Input placeholder="Введите SEO заголовок" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="SEO описание">
          <Form.Item
            name={['seo', 'description']}
            initialValue={get(editableCategory, 'seo.description')}
            rules={[{ required: false }]}
          >
            <Input placeholder="Введите SEO описание" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Обложка">
          <>
            {cover && (
              <div>
                <img className={styles.cardItemCover} src={cover} alt="Cover" />
              </div>
            )}
            <Form.Item name="cover" initialValue={get(editableCategory, 'cover')}>
              <ButtonUpload
                uploadProps={fileUploadProps}
                onChange={(data: IFile) => {
                  setCover(data?.url || EMPTY_STRING);
                }}
              />
            </Form.Item>
          </>
        </ItemWithLabel>

        <Form.Item
          name="visible"
          initialValue={get(editableCategory, 'visible') || false}
          valuePropName="checked"
        >
          <Checkbox>Опубликована на сайте</Checkbox>
        </Form.Item>

        <div className={s.modalControls}>
          <Button
            onClick={handleSubmit}
            type="primary"
            disabled={!checkPermissions(`categories.${editableCategory ? 'update' : 'create'}`)}
            loading={isActionModal}
          >
            Сохранить изменения
          </Button>
          {editableCategory && (
            <div className={s.deleteBtnWrapper}>
              <DeleteButton
                text="Удалить категорию"
                onDelete={handleDelete}
                disabled={!checkPermissions('categories.delete')}
              />
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
});

export const openCategoriesModal = createModal(CategoriesModal);
