import { CloseCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Checkbox, Col, Input, message, Modal, Row } from 'antd';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import {
  __,
  identity,
  ifElse,
  inc,
  includes,
  lensProp,
  pick,
  pipe,
  prop,
  propEq,
  set,
} from 'ramda';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { fetcher } from '~api';
import { BlockType, IBlock, IFile, IMeta } from '~common';
import { BlockButton } from '~components';
import { DropZone } from '~components/DropZone';
import { EditorContext } from '~components/Editor';
import { ButtonShape, EMPTY_RAW, EMPTY_STRING, resourceTypes } from '~constants';
import { useBlockStateReducer, useCheckStatusUpload } from '~hooks';
import { Close, Edit } from '~icons';
import publicationApi from '~services/publicationApi';
import { debounce, getText, getTextsCount, isAnyTruthy, noop } from '~utils';

import ItemWithLabel from '../../ItemWithLabel';
import {
  disabledButtonsInStandardInput,
  OnChangeBlockDataHandler,
  RichEditor,
} from '../../RichEditor';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

const { confirm } = Modal;

const MAX_ANSWERS = 4;
const MAX_LENGTH = 75;

interface IProps extends IBlock {
  alt?: string;
  altHTML?: string;
  answers?: Array<{ additionalVotes?: number; id: string; isRight?: boolean; text: string }>;
  charactersCount?: number;
  image?: IFile;

  link?: string;

  onBlur?(block: IBlock & { meta: IMeta }): void;

  onChange?(block: IBlock & IProps & { meta: IMeta }): void;

  placeholder?: string;
  post?: string;
  uploadData?: object;
  uploadPlaceholder?: string;
}

type TState = Pick<
  IProps,
  | 'text'
  | 'textHTML'
  | 'answers'
  | 'post'
  | 'link'
  | 'image'
  | 'charactersCount'
  | 'alt'
  | 'altHTML'
>;

const getPreviewFromPost = pick(['_id', 'title', 'subtitle', 'clickbait', 'cover']);
const getIdFromLink = link => link.split('/').slice(-1)[0];

const getAnswerPercent = (votes, count) => Math.round((votes * 100) / count) || 0;

const QuizBlock: React.FC<IProps> = props => {
  const {
    answers,
    post,
    link,
    id = EMPTY_STRING,
    blockProps,
    isFetching = false,
    isEditing = false,
    isForceUpdate = false,
    text = EMPTY_RAW,
    alt,
    altHTML,
    image,
    placeholder = 'Введите вопрос',
    textHTML = EMPTY_STRING,
    charactersCount = 0,
    onChange = noop,
    uploadUrl,
    uploadPlaceholder,
    uploadData = { type: 'block' },
  } = props;
  const [preview, setPreview] = useState<any>(null);

  const { data: results } = useSWR(id ? `/quiz/results/${id}` : null, fetcher);
  const [countResults, setCountResults] = useState(0);
  const [countAdditionalVotes, setCountAdditionalVotes] = useState(0);
  const { onEditImage } = useContext(EditorContext);

  const initialState: TState = {
    answers: answers || [],
    text,
    post,
    link,
    textHTML,
    charactersCount,
    alt,
    altHTML,
  };

  const data: TState = useMemo(
    () => ({
      alt,
      altHTML,
      text,
      answers,
      textHTML,
      charactersCount,
      post,
      link,
      image,
    }),
    [alt, altHTML, text, answers, textHTML, charactersCount, post, link, image],
  );

  const [state, dispatch] = useBlockStateReducer<TState>({
    blockId: id,
    blockType: BlockType.QuoteBlock,
    incomingData: data,
    initialState,
    isEditing,
    isForceUpdate,
    onChange,
  });

  const isImageHere = useMemo(() => state?.image?.url, [state?.image?.url]);

  const { checkStatus, isFetching: isFetchingJob, status } = useCheckStatusUpload(image => {
    dispatch({ image });
  });

  useEffect(() => {
    const charactersCount = getTextsCount(getText(state.text));
    if (charactersCount !== state.charactersCount) {
      dispatch({ charactersCount });
    }
  }, [state.textHTML]);

  useEffect(() => {
    const ids = state.answers?.map(prop('id')) || [];

    setCountResults(
      results?.data
        // @ts-ignore
        ?.filter(pipe(prop('_id'), includes(__, ids)))
        .reduce((acc, { count = 0 }) => acc + Number(count), 0) || 0,
    );

    setCountAdditionalVotes(
      state.answers?.reduce((acc, { additionalVotes = 0 }) => acc + Number(additionalVotes), 0) ||
        0,
    );
  }, [results, state.answers]);

  const onChangeText: OnChangeBlockDataHandler = ({ raw: text, html: textHTML }) => {
    dispatch({
      text,
      textHTML,
    });
  };

  const onAddAnswer = () => {
    if (!isEditing || isFetching) {
      return;
    }

    dispatch({
      answers: [...(state?.answers || []), { id: nanoid(), text: EMPTY_STRING }],
    });
  };

  const onRemoveAnswer = (id: string) => () => {
    if (!isEditing || isFetching) {
      return;
    }

    const deleteAnswer = () => {
      dispatch({
        answers: state?.answers?.filter(value => value.id !== id),
      });
    };

    if (blockProps?.publicationStatus === 'POST_STATUS_PUBLISHED') {
      confirm({
        title:
          'Если удалить ответ, то сотрутся все ответы на сайте. Вы уверены что хотите это сделать?',
        okText: 'Да',
        cancelText: 'Нет',
        onOk: deleteAnswer,
      });
    } else {
      deleteAnswer();
    }
  };

  const onChangeAnswer = (id: string) => ({ currentTarget: { value } }) => {
    if (value.length > MAX_LENGTH) {
      return;
    }

    dispatch({
      answers: state?.answers?.map(
        ifElse(propEq('id', id), set(lensProp('text'), value), identity),
      ),
    });
  };

  const onChangeVotes = (id: string) => ({ currentTarget: { value } }) => {
    const newValue = Number(value);

    dispatch({
      answers: state?.answers?.map(
        ifElse(
          propEq('id', id),
          set(lensProp('additionalVotes'), newValue < 0 ? 0 : newValue),
          identity,
        ),
      ),
    });
  };

  const onChangeRightAnswer = (id: string) => ({ target: { checked } }) => {
    dispatch({
      answers: state?.answers?.map(
        ifElse(
          propEq('id', id),
          set(lensProp('isRight'), checked),
          set(lensProp('isRight'), false),
        ),
      ),
    });
  };

  const onChangeAlt = ({ raw: alt, html }) => {
    dispatch({
      alt,
      altHTML: html,
    });
  };

  const onChangeImage = image => {
    dispatch({ image });
  };

  const onClickDelete = () => {
    dispatch({ image: undefined });
  };

  const onClickEdit = () => {
    onEditImage &&
      onEditImage(state?.image?.url || '', data => {
        checkStatus(data.id);
      });
  };

  const requestPreviewByLink = useMemo(
    () => link => {
      const id = getIdFromLink(link);

      publicationApi
        .fetchPublication(id)
        .then(post => {
          if (!post) {
            throw new Error('Not found');
          }

          dispatch({ post: post._id });
        })
        .catch(() => {
          message.info(
            'Публикации по заданому значению не найдено! Проверьте правильность введеного значения.',
          );
        });
    },
    [],
  );

  const delayedRequest = useMemo(() => debounce(requestPreviewByLink, 600), [requestPreviewByLink]);

  useEffect(() => {
    if (post) {
      publicationApi.fetchPublication(post).then(pipe(getPreviewFromPost, setPreview));
    }
  }, [post]);

  const handleChangeLink = event => {
    const link = event.target.value;
    dispatch({ link });
    delayedRequest(link);
  };

  return (
    <BlockWrapper {...blockProps} id={id}>
      {blockProps?.postResourceType !== resourceTypes.quiz && (
        <>
          <RichEditor
            value={text}
            placeholder={placeholder}
            onChange={onChangeText}
            readOnly={isFetching}
            isEditing={isEditing}
          />

          <br />
        </>
      )}

      {blockProps?.postResourceType === resourceTypes.publication && (
        <Row>
          <Col className={styles.dropZoneWrapper} xs={18} offset={3}>
            {isImageHere && (
              <div className={styles.controls}>
                <BlockButton
                  shape={ButtonShape.Round}
                  onClick={onClickDelete}
                  className={styles.btn}
                >
                  <Close style={{ width: '12px', height: '12px' }} />
                  Удалить
                </BlockButton>
                <BlockButton shape={ButtonShape.Round} onClick={onClickEdit} className={styles.btn}>
                  <Edit style={{ width: '24px', height: '24px' }} />
                </BlockButton>{' '}
              </div>
            )}
            <DropZone
              noDragEventsBubbling
              accept="image/*"
              uploadUrl={uploadUrl}
              uploadPlaceholder={uploadPlaceholder}
              files={[state.image || {}]}
              onChange={onChangeImage}
              uploadData={uploadData}
              status={status}
            />
          </Col>
          <Col xs={18} offset={3}>
            <div className={styles.captionHeader}>Подпись к фотографии</div>
            <Row align="middle">
              <Col xs={24} sm={24} xl={24}>
                <RichEditor
                  rawContent={state.alt}
                  disabledButtons={disabledButtonsInStandardInput}
                  onChange={onChangeAlt}
                  readOnly={isAnyTruthy([isFetchingJob, isFetching])}
                  isEditing={isEditing}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <div className={styles.wrapper}>
        <div className={styles.answers}>
          <table>
            <thead>
              <th style={{ minWidth: '150px' }} colSpan={2}>
                Ответы
              </th>
              <th colSpan={4}>Результаты</th>
            </thead>
            <tbody>
              {(state.answers?.length || 0) > 0 && (
                <tr>
                  <td colSpan={2} />
                  <td className={styles.header}>Верно:</td>
                  <td className={styles.header}>Проценты:</td>
                  <td className={styles.header}>Кол-во голосов:</td>
                  <td className={styles.header}>Доп. голоса:</td>
                </tr>
              )}
              {state.answers?.map((answer, index) => (
                <tr className={styles.answer}>
                  <td>{inc(index)}</td>
                  <td className={styles.background}>
                    <input
                      type="text"
                      name={`${index}_answer`}
                      onChange={onChangeAnswer(answer.id)}
                      value={answer.text}
                      readOnly={isFetching}
                      disabled={!isEditing}
                    />
                  </td>
                  <td className={styles.background}>
                    <Checkbox
                      checked={!!answer.isRight}
                      onChange={onChangeRightAnswer(answer.id)}
                    />
                  </td>
                  <td className={classNames(styles.percent, styles.background)} width={90}>
                    {getAnswerPercent(
                      (Number(answer.additionalVotes) || 0) +
                        (results?.data?.find(propEq('_id', answer.id))?.count || 0),
                      countResults + countAdditionalVotes,
                    )}
                    %
                  </td>
                  <td className={classNames(styles.votes, styles.background)} width={120}>
                    {results?.data?.find(propEq('_id', answer.id))?.count || 0}
                  </td>
                  <td width={120} className={styles.background}>
                    <input
                      type="number"
                      name={`${index}_answer_additional_votes`}
                      onChange={onChangeVotes(answer.id)}
                      value={answer.additionalVotes || 0}
                      readOnly={isFetching}
                      disabled={!isEditing}
                      className={styles.additionalVotes}
                    />
                  </td>
                  <td width={40}>
                    <CloseCircleOutlined
                      onClick={onRemoveAnswer(answer.id)}
                      style={{ fontSize: '20px' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            {(state.answers?.length || 0) > 0 && (
              <tfoot className={styles.footer}>
                <td colSpan={3} />
                <td className={styles.results}>
                  {(state.answers?.length || 0) > 0 ? '100%' : '-'}
                </td>
                <td className={styles.results}>{countResults}</td>
                <td className={styles.results}>{countAdditionalVotes}</td>
              </tfoot>
            )}
          </table>
        </div>
        {(state.answers?.length || 0) < MAX_ANSWERS && (
          <div className={styles.addButton}>
            <PlusSquareOutlined onClick={onAddAnswer} style={{ fontSize: '26px' }} />
          </div>
        )}
      </div>

      {blockProps?.postResourceType === resourceTypes.quiz && (
        <div className={styles.container}>
          <div>
            <ItemWithLabel label="Ссылка на публикацию или id">
              <Input value={state.link} onChange={handleChangeLink} />
            </ItemWithLabel>
          </div>
          {preview && (
            <div className={styles.preview}>
              <div className={styles.previewImageWrapper}>
                <div className={styles.previewImageContainer}>
                  <img
                    className={styles.previewImage}
                    src={preview.cover && preview.cover.url}
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.previewContent}>
                <h3 className={styles.previewTitle}>{preview.title}</h3>
                <span className={styles.previewSubtitle}>{preview.subtitle}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </BlockWrapper>
  );
};

export default QuizBlock;
