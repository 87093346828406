import { Button, Input, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';

import { ImageEditor } from '~components';
import { BlockWrapper, HeadingBlock, ImageBlock, VideoBlock } from '~components/Blocks';
import { useVideoStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { noop } from '~utils';

import styles from './styles.scss';

const VIDEO_EMBED_TYPE = 'VIDEO_TYPE_EMBED';
const VIDEO_STREAM_TYPE = 'VIDEO_TYPE_STREAM';

const VideoEditor = observer(() => {
  const [isOpenImageEditor, setIsOpenImageEditor] = useState(false);
  const [editableImage, setEditableImage] = useState('');
  const [editHandler, setEditHandler] = useState(noop);

  const {
    type,
    videoFile,
    videoFileTitle,
    streamId,
    embedLink,
    c1Link,
    availableStreams,
    initialized,
    cover,
    coverTitle,
    title,
    description,
    onChangeVideo,
    onChangeEmbedLink,
    onChangeVideoStream,
    clearVideoStream,
    onChangeTitle,
    onChangeCover,
    onChangeCoverTitle,
    onChangeDescription,
    onChangeC1Link,
  } = useVideoStore();

  const openImageEditor = useCallback((url, editHandler) => {
    setIsOpenImageEditor(true);
    setEditableImage(url);
    setEditHandler(editHandler);
  }, []);

  const closeImageEditor = useCallback(() => {
    setIsOpenImageEditor(false);
    setEditableImage('');
  }, []);

  const renderContent = useCallback(() => {
    if (type === VIDEO_EMBED_TYPE) {
      return (
        <BlockWrapper title="Ссылка для вставки">
          <div className={styles.embed}>
            <Input
              placeholder="Укажите ссылку для вставки"
              value={embedLink}
              onChange={onChangeEmbedLink}
            />

            {embedLink && (
              <div className={styles.embedIframeWrapper}>
                <iframe title="embedLink" src={embedLink} className={styles.embedIframe} />
              </div>
            )}
          </div>
        </BlockWrapper>
      );
    }

    if (type === VIDEO_STREAM_TYPE) {
      return (
        <BlockWrapper title="Стрим">
          <div className={styles.streams}>
            <Select
              style={{ width: 300, marginRight: '20px' }}
              onChange={onChangeVideoStream}
              value={streamId || undefined}
            >
              {availableStreams.map(stream => (
                <Select.Option key={stream.streamId} value={stream._id}>
                  {stream.streamId}
                </Select.Option>
              ))}
            </Select>
            <Button onClick={clearVideoStream}>Очистить</Button>
          </div>
          <div className={styles.streamsUrl}>Public Стрим ID: {streamId || ''}</div>
        </BlockWrapper>
      );
    }

    return (
      <VideoBlock
        onChange={onChangeVideo}
        uploadUrl={`${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`}
        {...(videoFile || {})}
        alt={videoFileTitle || ''}
        footer={false}
        isEditing
      />
    );
  }, [
    availableStreams,
    clearVideoStream,
    embedLink,
    onChangeEmbedLink,
    onChangeVideo,
    onChangeVideoStream,
    streamId,
    type,
    videoFile,
    videoFileTitle,
  ]);

  const changeCover = data => {
    if (data._id && data.url !== cover) {
      return onChangeCover(data);
    }
    if (data.alt) {
      return onChangeCoverTitle(data.alt);
    }

    return null;
  };

  if (!initialized) {
    return (
      <div>
        <Spin /> Loading...
      </div>
    );
  }

  return (
    <>
      <HeadingBlock
        richEditor={false}
        id="title"
        level={1}
        text={title}
        onChange={onChangeTitle}
        isEditing
      />
      <HeadingBlock
        id="description"
        placeholder="Добавить описание"
        text={description}
        onChange={onChangeDescription}
        richEditor={false}
        blockProps={{
          title: 'Описание',
        }}
        isEditing
      />
      <ImageBlock
        blockProps={{ title: 'Обложка' }}
        uploadUrl={`${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`}
        url={cover?.url ?? ''}
        alt={coverTitle}
        onChange={changeCover}
        onEdit={openImageEditor}
        isEditing
      />

      <BlockWrapper title="Ссылка для блока С1">
        <div className={styles.embed}>
          <Input
            placeholder="Укажите ссылку для блока С1"
            value={c1Link}
            onChange={onChangeC1Link}
          />
        </div>
      </BlockWrapper>

      {renderContent()}

      <ImageEditor
        shown={isOpenImageEditor}
        src={editableImage}
        onClose={closeImageEditor}
        onChange={editHandler}
      />
    </>
  );
});

export default VideoEditor;
