import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { observer } from 'mobx-react';
import { prop } from 'ramda';
import React from 'react';

import { FilterItem } from '~common';
import { useFetchPaginationStore, useFilterParamsByListStore, useSortingStore } from '~hooks';

import GridWrapper from '../../components/GridWrapper';
import { DynamicField, FilterDropDown } from './components';
import styles from './styles.scss';

interface Props {
  defaultFilter?: Record<string, any>;

  fetching(filter?: Record<string, any>): void | Promise<void>;

  filtersItem: FilterItem[];

  onReset?(): void;

  onSetFilter?(): void;

  removeFilterItem(item: FilterItem): void;

  selectedFilterItems: FilterItem[];

  setFilterItemActive(item: FilterItem): void;
}

export const Filter: React.FC<Props> = observer(props => {
  const {
    filtersItem,
    selectedFilterItems,
    setFilterItemActive,
    removeFilterItem,
    fetching,
    defaultFilter,
    onReset,
    onSetFilter,
  } = props;
  const { resetOptions } = useSortingStore();
  const { setFilterParams } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();

  const handleResetFilter = () => {
    setFilterParams();
    resetPaginationParams();
    resetOptions();
    onReset && onReset();
    fetching(defaultFilter);
  };

  const handleSetFilter = () => {
    onSetFilter && onSetFilter();
    resetPaginationParams();
    fetching(defaultFilter);
  };

  return (
    <div className={styles.root}>
      <GridWrapper gridSize={5} maxItemWidth={285}>
        {filtersItem
          .filter(item => !!item.fix)
          .map((item, index) => (
            <DynamicField key={index} item={item} />
          ))}

        {selectedFilterItems
          .filter(item => item.type !== 'bool')
          .map((item, index) => (
            <DynamicField onRemove={removeFilterItem} key={index} item={item} />
          ))}
      </GridWrapper>

      <div className={styles.boolFilterWrapper}>
        {selectedFilterItems
          .filter(item => item.type === 'bool')
          .map((item, index) => (
            <DynamicField onRemove={removeFilterItem} key={index} item={item} />
          ))}
      </div>
      <div className={styles.filterControls}>
        <Dropdown
          trigger={['click']}
          placement="bottomLeft"
          disabled={filtersItem.filter(item => !item.fix).length === 0}
          overlay={
            <FilterDropDown
              filtersItem={filtersItem.filter(item =>
                selectedFilterItems.map(prop('name')).indexOf(item.name),
              )}
              selectedFilterItems={selectedFilterItems}
              setFilterItemActive={setFilterItemActive}
            />
          }
        >
          <div className={styles.addNewItem}>
            <Button shape="circle" icon={<PlusOutlined />} size="small" type="primary" />

            <span className={styles.addBtnText}>Добавить фильтр</span>
          </div>
        </Dropdown>

        <div className={styles.serviceControl}>
          <Button type="ghost" onClick={handleResetFilter}>
            Отменить
          </Button>
          <Button type="primary" onClick={handleSetFilter}>
            Применить
          </Button>
        </div>
      </div>
    </div>
  );
});
