import { observer } from 'mobx-react';
import React from 'react';

import { ICategory } from '~common';
import { ISelectProps, Select } from '~components';
import { useAuthorsStore } from '~hooks';

export const CategoriesSelect: React.FC<ISelectProps<ICategory>> = observer(props => {
  // TODO Убрать категории из AuthorsStore
  const { fetchCategoriesBySearch, categoriesSearches } = useAuthorsStore();

  return (
    <Select
      {...props}
      fetch={fetchCategoriesBySearch}
      options={categoriesSearches}
      keyField="_id"
      labelField="title"
    />
  );
});
