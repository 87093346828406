import { Select as AntdSelect } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { FilterItem } from '~common';

const { Option } = AntdSelect;

interface Props {
  item: FilterItem;

  onChange(name: string, value: any): void;

  value: any;
}

export const Select: React.FC<Props> = props => {
  const { onChange, value, item, ...selectProps } = props;
  const { name } = item;

  const { selectOptions: { options = [], key = '', label = '', fetch } = {} } = item;

  const handleSearch = useMemo(
    () =>
      debounce(term => {
        fetch && fetch(term);
      }, 300),
    [fetch],
  );

  const handleSelectChange = value => {
    onChange(name, value);

    if (!value) {
      fetch && fetch();
    }
  };

  useEffect(() => {
    fetch && fetch();
  }, [fetch]);

  return (
    <AntdSelect
      style={{
        width: '100%',
      }}
      showSearch
      allowClear
      optionFilterProp="children"
      onChange={handleSelectChange}
      value={value}
      placeholder="Выбрать"
      onSearch={handleSearch}
      {...selectProps}
    >
      {options && options.map(option => <Option value={option[key]}>{option[label]}</Option>)}
    </AntdSelect>
  );
};
