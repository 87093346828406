import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import TimersItem from '~components/Timers/TimersItem'

@inject('TimersStore')
@observer
class TimersList extends Component {
  componentDidMount() {
    this.props.TimersStore.fetchTimers()
  }

  render() {
    const {
      TimersStore: { timers, isFirstLoading }
    } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    return (
      <GridWrapper gridSize={3} maxItemWidth={285}>
        {timers &&
          Object.keys(timers).map((key, idx) => (
            <TimersItem title={key} value={timers[key]} key={idx} />
          ))}
      </GridWrapper>
    )
  }
}

TimersList.propTypes = {
  TimersStore: PropTypes.any
}

export default TimersList
