import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Button, Input } from 'antd'

import GridWrapper from '~components/GridWrapper'
import ItemWithLabel from '~components/ItemWithLabel'
import SelectRender from '~components/Publications/Filter/SelectRender'

import styles from './styles.scss'

@inject(stores => {
  const {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    fetchEventsByCalendar,
    fetchEventsByList,
    isGrid,
    setIsFirstLoading
  } = stores.ScheduleStore

  const { resetPaginationParams } = stores.FetchPaginationStore
  const { setFilterParams, setFilterValue, filterParams } = stores.FilterParamsByListStore
  const { resetOptions } = stores.SortingStore

  return {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    resetPaginationParams,
    fetchEventsByCalendar,
    fetchEventsByList,
    isGrid,
    setFilterParams,
    setFilterValue,
    setIsFirstLoading,
    resetOptions,
    filterParams
  }
})
@observer
class EventsFilter extends Component {
  handleSetFilter = () => {
    const {
      resetPaginationParams,
      setIsFirstLoading,
      isGrid,
      fetchEventsByCalendar,
      fetchEventsByList
    } = this.props

    setIsFirstLoading(true)
    resetPaginationParams()

    if (isGrid) {
      fetchEventsByCalendar()
    } else {
      fetchEventsByList()
    }
  }

  handleResetFilter = () => {
    const {
      setFilterParams,
      setIsFirstLoading,
      isGrid,
      resetPaginationParams,
      resetOptions,
      fetchEventsByCalendar,
      fetchEventsByList
    } = this.props

    setFilterParams()
    resetPaginationParams()
    resetOptions()
    setIsFirstLoading(true)

    if (isGrid) {
      fetchEventsByCalendar()
    } else {
      fetchEventsByList()
    }
  }

  handleChangeType = value => {
    const { setFilterValue } = this.props

    setFilterValue('filter.types', value)
  }

  handleChangeAddress = e => {
    const { value } = e.target
    const { setFilterValue } = this.props

    setFilterValue('filter.address', value)
  }

  render() {
    const { filterParams } = this.props

    return (
      <div className={styles.root}>
        <GridWrapper gridSize={5} maxItemWidth={285}>
          <ItemWithLabel label="Тип события">
            <SelectRender
              value={filterParams['filter.types']}
              maxTagCount={4}
              mode="multiple"
              onChange={this.handleChangeType}
              optionsChildren={data => (
                <div className={styles.typeOption}>
                  {data.title}

                  <div className={styles.color} style={{ background: data.color }} />
                </div>
              )}
              item={{
                type: 'select',
                name: 'filter.eventType'
              }}
            />
          </ItemWithLabel>

          <ItemWithLabel label="Место">
            <Input value={filterParams['filter.address']} onChange={this.handleChangeAddress} />
          </ItemWithLabel>
        </GridWrapper>

        <div className={styles.filterControls}>
          <div />

          <div className={styles.serviceControl}>
            <Button type="ghost" onClick={this.handleResetFilter}>
              Отменить фильтрацию
            </Button>
            <Button type="primary" onClick={this.handleSetFilter}>
              Применить
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default EventsFilter

EventsFilter.propTypes = {
  dictionaries: PropTypes.any,
  fetchDictionaries: PropTypes.func,
  fetchEvents: PropTypes.func,
  fetchEventsByCalendar: PropTypes.func,
  fetchEventsByList: PropTypes.func,
  fetchPublications: PropTypes.func,
  filterParams: PropTypes.any,
  filtersItem: PropTypes.object,
  isGrid: PropTypes.bool,
  removeFileItem: PropTypes.func,
  resetOptions: PropTypes.func,
  resetPaginationParams: PropTypes.func,
  selectedFilterItems: PropTypes.array,
  setFilterParams: PropTypes.func,
  setFilterValue: PropTypes.func,
  setIsFirstLoading: PropTypes.func
}
