/* eslint-disable */
import React from 'react'
import { Icon, Popover } from 'antd'
import cn from 'classnames'

import styles from './styles.scss'

const SliderWrapper = ({ children, label, popover: { content = null } }) => {
  const isContentHere = content != null

  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <Popover
          placement="bottom"
          content={content}
          trigger={isContentHere ? 'click' : ''}
          className={cn(styles.popover, !isContentHere && styles.disabled)}
        >
          {label} {isContentHere && <Icon type="plus" />}
        </Popover>
      </div>

      <div className={styles.slider}>{children}</div>
    </div>
  )
}

SliderWrapper.defaultProps = {
  popover: {
    content: null
  }
}

export default SliderWrapper
