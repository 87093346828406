import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React from 'react';

import { IDictionary } from '~common';
import { ItemWithLabel } from '~components';
import { usePublicationStore } from '~hooks';

import { inputStyle } from '../../components/Settings/constants';

export const AdvTypeSelect = observer(() => {
  const { advType, dictionariesList, set } = usePublicationStore();

  const postAdvTypes: IDictionary[] = dictionariesList?.dictionaries?.postAdvTypes ?? [];
  const options: LabeledValue[] = postAdvTypes.map(postAdvType => ({
    value: postAdvType.id,
    label: postAdvType.ru,
  }));

  const onChange = advType => {
    set({ name: 'advType', value: advType });
  };

  return (
    <ItemWithLabel label="Особый тип">
      <Select
        style={inputStyle}
        placeholder="Выберите особый тип"
        value={advType}
        options={options}
        onChange={onChange}
      />
    </ItemWithLabel>
  );
});
