import { Spin } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useOverlayStore } from '~hooks/useOverlayStore';

import styles from './styles.scss';

export const Overlay: FC = observer(props => {
  const { text = 'Идет загрузка. Пожалуйста, подождите...' } = useOverlayStore();
  const { isVisible = false } = useOverlayStore();
  const { children } = props;
  return (
    <>
      <div className={cn(styles.overlay, { [styles.hidden]: !isVisible })}>
        <div className={styles.block}>
          <div className={styles.spin}>
            <Spin />
          </div>
          <div className={styles.text}>
            <h4>{text}</h4>
          </div>
        </div>
      </div>
      {children}
    </>
  );
});
