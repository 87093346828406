import { Dispatch, SetStateAction, useState } from 'react';

type FunctionPromise = (query) => Promise<any>;

export const useFetcher = (
  promise: FunctionPromise,
): [FunctionPromise, boolean, boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const fetcher = query => {
    setIsFetching(true);
    setFetchError(false);
    return promise(query)
      .catch(reason => {
        setFetchError(true);
        return Promise.reject(reason);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };
  return [fetcher, isFetching, fetchError, setFetchError];
};
