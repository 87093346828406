import { Checkbox, Input } from 'antd';
import propTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BlockWrapper } from '~components/Blocks';
import ButtonUpload from '~components/ButtonUpload';

// components
import ItemWithLabel from '~components/ItemWithLabel';
import { RichEditor } from '~components/RichEditor';

import { debounce } from '~utils';
// assets
import { InputsTypes } from './blocks.config.example';
import styles from './styles.scss';

// helpers
const onEventChange = f => e => f({ name: e.target.name, value: e.target.value });
const onNameChange = (f, name) => value => f({ name, value });
const onCheckboxChange = f => e => f({ name: e.target.name, value: e.target.checked });

const CustomInput = ({
  type,
  value,
  name,
  label,
  onChange,
  blockProps = {},
  isFetching = false,
  isEditing = false,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onChangeDelay = useMemo(() => debounce(onChange, 1000), [onChange]);

  // TODO Временное решение, убрать когда не будет рендериться при каждом изменении поля
  const onChangeHandler = useCallback(
    e => {
      setCurrentValue(e.target.value);
      onChangeDelay({ name: e.target.name, value: e.target.value });
    },
    [onChangeDelay],
  );

  switch (type) {
    case InputsTypes.richInput:
      return (
        <ItemWithLabel label={label}>
          <RichEditor
            value={value}
            onChange={({ raw: value, plainText, html }) => {
              onChange({ name, value, plainText, html });
            }}
            readOnly={isFetching}
            isEditing={isEditing}
          />
        </ItemWithLabel>
      );
    case InputsTypes.input:
      return (
        <ItemWithLabel label={label}>
          <Input
            name={name}
            value={currentValue}
            onChange={onChangeHandler}
            readOnly={isFetching}
          />
        </ItemWithLabel>
      );

    case InputsTypes.textarea:
      return (
        <ItemWithLabel label={label}>
          <Input.TextArea
            rows={4}
            name={name}
            value={value}
            onChange={onEventChange(onChange)}
            readOnly={isFetching}
          />
        </ItemWithLabel>
      );

    case InputsTypes.upload:
      return (
        <ItemWithLabel label={label}>
          {value && value.url && <img className={styles.image} src={value.url} alt="" />}
          <ButtonUpload
            src={value}
            onChange={onNameChange(onChange, name)}
            uploadProps={{
              showUploadList: false,
              data: { type: 'block' },
              accept: 'image/*',
            }}
          />
        </ItemWithLabel>
      );
    case InputsTypes.checkbox:
      return (
        <Checkbox name={name} checked={value} onChange={onCheckboxChange(onChange)}>
          {label}
        </Checkbox>
      );
    default:
      return (
        <div>
          Инпут {name} с типом {type} недоступен! Сообщите технической поддержке. Вы можете
          продолжить работу
        </div>
      );
  }
};

CustomInput.propTypes = {
  type: propTypes.string,
  value: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,

  onChange: propTypes.func,
};

class CustomBlock extends React.Component {
  handleChange = ({ name, value }) => {
    const { template, inputs, customType } = this.props;
    const values = { ...this.props.values, [name]: value };

    this.props.onChange({
      values,
      template,
      inputs,
      customType,
    });
  };

  render() {
    const {
      inputs,
      values,
      blockProps,
      blockProps: { title },
      isEditing = false,
      isFetching = false,
    } = this.props;

    return (
      <BlockWrapper title={title} {...this.props} {...blockProps}>
        <div className={styles.container}>
          {(inputs || []).map(input => {
            return (
              <div className={styles.input} key={input.name}>
                <CustomInput
                  type={input.type}
                  name={input.name}
                  label={input.label}
                  value={values[input.name]}
                  onChange={this.handleChange}
                  isEditing={isEditing}
                  isFetching={isFetching}
                />
              </div>
            );
          })}
        </div>
      </BlockWrapper>
    );
  }
}

CustomBlock.propTypes = {
  blockProps: propTypes.object,

  template: propTypes.string,
  inputs: propTypes.array,
  values: propTypes.object,
  customType: propTypes.string,

  onChange: propTypes.func,
  onFocus: propTypes.func,

  isEditing: propTypes.bool,
  isFetching: propTypes.bool,
};

CustomBlock.defaultProps = {
  values: {},
  customType: '',
};

export default CustomBlock;
