import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.scss'

const Navigation = ({ title, children }) => (
  <>
    {title ? <div className={styles.title}>{title}</div> : null}
    <ul className={styles.navigation}>{children}</ul>
  </>
)

Navigation.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
}

export default Navigation
