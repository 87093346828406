import API from '~api';
import { IEmployee } from '~common';
import { AbstractTransportLayer } from '~services/abstract';

const API_URL_OAUTH = '/auth/oauthorize';
const API_URL_EMPLOYEE = '/employees';

const getData = ({ data }) => data;

export class EmployeeTransportLayer extends AbstractTransportLayer<IEmployee> {
  constructor() {
    super(API_URL_EMPLOYEE);
  }

  oAuth = (code: string) => {
    return API.post(
      `${API_URL_OAUTH}?provider=life&callbackUrl=${window.location.origin}/oauth/callback`,
      { code },
    ).then(data => {
      return getData(data);
    });
  };

  getOAuthCallbackUrl = query => {
    return `${API.defaults.baseURL}/connect/life/callback${query}`;
  };

  login = (data: { login: string; password: string }) => {
    return API.post(`${API_URL_EMPLOYEE}/login`, data).then(getData);
  };

  getEmployeeInfo = () => {
    return API.get(`${API_URL_EMPLOYEE}/me`).then(getData);
  };

  logout = () => {
    return API.post(`${API_URL_EMPLOYEE}/logout`).then(getData);
  };
}
