import { observer } from 'mobx-react';
import React, { ComponentType, FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Authorized } from '~containers';
import { useUserStore } from '~hooks';
import AppLayout from '~layouts/AppLayout';

interface IPrivateRouteProps extends RouteProps {
  authority?:
    | string
    | string[]
    | ((role: Record<string, Record<string, boolean>>) => boolean | Promise<boolean>)
    | Promise<string>;
  component: ComponentType;
  defaultFilter?: string;
  hideFilters?: string[];
  showPostsHeader?: boolean;
}

export const PrivateRoute: FC<IPrivateRouteProps> = observer(props => {
  const { isAuthenticated } = useUserStore();
  const { component: Component, showPostsHeader, authority = [], ...rest } = props;

  return isAuthenticated ? (
    <Route
      {...rest}
      render={() => (
        <AppLayout showPostsHeader={showPostsHeader}>
          <Authorized authority={authority}>
            <Component {...props} />
          </Authorized>
        </AppLayout>
      )}
    />
  ) : (
    <Redirect to="/login" />
  );
});
