export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_PRODUCTION = !IS_DEVELOPMENT;
export const EMPTY_STRING = '';
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const DEFAULT_VALUE = undefined;
export const EMPTY_RAW = { blocks: [{ text: EMPTY_STRING }], entityMap: {} };

export * from './analytics';
export * from './blockTypes';
export * from './button';
export * from './changeLog';
export * from './departments';
export * from './employees';
export * from './fields';
export * from './publication';
export * from './redirects';
export * from './sort';
