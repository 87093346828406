import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import ViewButton from './ViewButton';

import styles from './styles.scss';
import { BarsOutlined, AppstoreOutlined, CalendarOutlined } from '@ant-design/icons';

@inject(stores => {
  const { PublicationsStore, FetchPaginationStore } = stores;

  const { setViewType, viewType, fetchPublications, setIsFirstLoading } = PublicationsStore;
  const { resetPaginationParams } = FetchPaginationStore;

  return {
    setViewType,
    viewType,
    fetchPublications,
    setIsFirstLoading,
    resetPaginationParams,
  };
})
@observer
class ViewToggleButton extends Component {
  handleClick = type => {
    const {
      setViewType,
      defaultFilter,
      fetchPublications,
      setIsFirstLoading,
      resetPaginationParams,
    } = this.props;

    setViewType(type);

    setIsFirstLoading(true);
    resetPaginationParams();
    fetchPublications(defaultFilter);
  };

  render() {
    const { viewType } = this.props;

    return (
      <div className={styles.root}>
        <ViewButton
          icon={<BarsOutlined style={{ fontSize: '24px' }} />}
          type="list"
          onClick={this.handleClick}
          currentType={viewType}
        />
        <ViewButton
          hideMobile
          icon={<AppstoreOutlined style={{ fontSize: '24px' }} />}
          type="grid"
          onClick={this.handleClick}
          currentType={viewType}
        />
        <ViewButton
          icon={<CalendarOutlined style={{ fontSize: '24px' }} />}
          type="calendar"
          onClick={this.handleClick}
          currentType={viewType}
        />
      </div>
    );
  }
}

export default ViewToggleButton;

ViewToggleButton.propTypes = {
  defaultFilter: PropTypes.any,
  fetchPublications: PropTypes.any,
  resetPaginationParams: PropTypes.any,
  setIsFirstLoading: PropTypes.any,
  setViewType: PropTypes.func,
  viewType: PropTypes.string,
};
