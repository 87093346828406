import { List, Modal, Typography } from 'antd';
import { identity, partial } from 'ramda';
import React, { useCallback } from 'react';
import { createModal } from 'react-modal-promise';

import { IBlock, InjectedModalProps } from '~common';
import { EMPTY_STRING } from '~constants';

interface IProps extends InjectedModalProps {
  blocks?: IBlock[];
  locks?: IFields[];
}

enum IFields {
  Blocks = 'blocks',
  Cover = 'cover',
  CoverTitle = 'coverTitle',
  Settings = 'settings',
  Subtitle = 'subtitle',
  Title = 'title',
}

const FieldTitle = {
  [IFields.Title]: 'Заголовок',
  [IFields.Subtitle]: 'Подзаголовок',
  [IFields.Cover]: 'Обложка',
  [IFields.CoverTitle]: 'Подпись к обложке',
  [IFields.Settings]: 'Настройки',
  [IFields.Blocks]: 'Блоки',
};

const TemplateRestoreModal: React.FC<IProps> = props => {
  const { isOpen, close, locks = [], blocks = [] } = props;

  const onClose = useCallback(
    (result = false) => {
      close && close(result);
    },
    [close],
  );

  return (
    <Modal
      title="Восстановление из черновика"
      visible={isOpen}
      onOk={partial(onClose, [true])}
      onCancel={partial(onClose, [false])}
    >
      <Typography.Title level={4}>
        {`Некоторые ${locks.length > 0 ? 'поля' : ''} ${
          locks.length > 0 && blocks.length > 0 ? 'и' : ''
        } ${
          blocks.length > 0 ? 'блоки' : ''
        } не могут быть восстановлены, так как заблокированы другим
        пользователями. Хотите продолжить?`}
      </Typography.Title>
      {locks.length > 0 && (
        <List
          size="small"
          header={<h3>Заблокированные поля</h3>}
          dataSource={locks}
          rowKey={identity}
          renderItem={item => <List.Item>{FieldTitle[item.toString()]}</List.Item>}
        />
      )}
      {blocks.length > 0 && (
        <List
          size="small"
          header={<h3>Заблокированные блоки</h3>}
          dataSource={blocks}
          rowKey={item => item?.id || EMPTY_STRING}
          renderItem={item => <List.Item>{`${item?.id} - блок № ${item?.order}`}</List.Item>}
        />
      )}
      <Typography.Text type="warning">
        Внимание! После восстановления черновик будет удален!
      </Typography.Text>
    </Modal>
  );
};

export const templateRestoreModal = createModal(TemplateRestoreModal);
