import classNames from 'classnames';
import React, { useCallback } from 'react';

import { ITab } from '~common';

import styles from './styles.scss';

interface IProps {
  data?: ITab;
  isActive?: boolean;
  onClick?(data?: ITab): void;
}

const TabsItem: React.FC<IProps> = props => {
  const { data, onClick, isActive } = props;

  const handleClick = useCallback(() => {
    onClick && onClick(data);
  }, [data, onClick]);

  return (
    <div onClick={handleClick} className={classNames(styles.tabItem, isActive && styles.active)}>
      {data?.label}
    </div>
  );
};

export default TabsItem;
