import cx from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { FontWeight, IBlock, ITextarea } from '~common';
import { RichEditor } from '~components/RichEditor';
import { noop } from '~utils';

import styles from './styles.scss';

interface IRichTextareaProps extends ITextarea {
  value?: IBlock['text'];
}

const RichTextarea: React.FC<IRichTextareaProps> = observer(props => {
  const {
    readOnly = false,
    isEditing = false,
    placeholder = '',
    value = '',
    fontWeight = FontWeight.Bold,
    className,

    onChange = noop,
    onFocus,
  } = props;

  const handleChange = (e: any) => {
    if (!isEditing) {
      return;
    }
    onChange(e);
  };

  const inputProps = {
    className: cx(styles.textarea, styles[`fontWeight_${fontWeight}`], className),
    placeholder,
    value,
    rawData: value,
    onChange: handleChange,
    onFocus,
    isEditing,
  };

  return <RichEditor {...inputProps} inline={false} readOnly={readOnly} />;
});

export default RichTextarea;
