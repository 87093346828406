import { Modal } from 'antd';
import cx from 'classnames';
import React, { ComponentType, useCallback, useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { IBlock, NonValidatableBlocks } from '~common';
import { Close, Draggable as DraggableIcon } from '~icons';
import { debounce, noop } from '~utils';

import styles from './styles.styl';

const { confirm } = Modal;

interface IProps extends IBlock {
  addButton?(): void;
  isCorrect?: boolean;
  isEditing?: boolean;
  isError?: boolean;
  manualDrag?(id?: string, value?: any): void;
  onDelete?(): void;
  onFocus?(): void;
}

export const draggableBlockHOC = (
  WrappedComponent: ComponentType<IBlock>,
): React.FC<IProps> => props => {
  const {
    id,
    index,
    type,

    lockedBy = { _id: '' },

    validatedBy,
    validatedAt,

    blocked = false,
    isEditing = false,

    isCorrect,
    isError,

    addButton = null,
    onFocus,
    onDelete,
    manualDrag,
  } = props;

  const [inputVal, setIndex] = useState(Number(index) + 1);
  useEffect(() => {
    setIndex(Number(index) + 1);
  }, [index]);

  const handleFocus = useCallback(
    e => {
      !blocked && !lockedBy?._id && onFocus && onFocus();

      return e;
    },
    [blocked, lockedBy?._id, onFocus],
  );

  const dChangePosition = useMemo(
    () =>
      debounce((value: any) => {
        manualDrag && manualDrag(id, value);
      }, 250),
    [id, manualDrag],
  );

  const changePosition = ({ target: { value } }) => {
    if (value > 0 && value !== index) {
      setIndex(value);
      dChangePosition(value);
    }
  };

  const onDeleteHandler = useCallback(
    event => {
      event.preventDefault();
      confirm({
        title: 'Вы уверены что хотите удалить блок?',
        content: 'При нажатии кнопки "Да", блок удалится и его нельзя будет восстановить!',
        okText: 'Да',
        cancelText: 'Нет',
        onOk() {
          onDelete && onDelete();
        },
      });
    },
    [onDelete],
  );

  return (
    <Draggable key={id} draggableId={id || ''} index={index || 0} isDragDisabled={!!blocked}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cx(
            styles.draggable,
            !isCorrect && type && !NonValidatableBlocks.includes(type) && styles.isCorrectError,
            isError && styles.isError,
          )}
        >
          <div className={cx(styles.header, blocked && styles.blocked)}>
            <span {...(blocked ? {} : provided.dragHandleProps)} className={styles.dragIcon}>
              <DraggableIcon />
            </span>

            <span className={styles.deleteIcon} onClick={blocked ? noop : onDeleteHandler}>
              <Close />
            </span>
          </div>

          <div onMouseUp={handleFocus} onSelect={handleFocus} onDrop={handleFocus}>
            <WrappedComponent
              {...props}
              disabled={blocked}
              blockProps={{
                ...(props.blockProps || {}),
                lockedBy,
                validatedBy,
                validatedAt,
                type,
                blocked,
                isEditing,
                id,
                withCopyId: true,
                withViewChanges: true,
              }}
            />
          </div>

          <div className={cx(styles.footer)}>
            <input
              disabled={blocked}
              type="text"
              value={inputVal}
              onFocus={({ target }) => {
                target.select();
              }}
              onChange={changePosition}
            />
          </div>

          {addButton && (
            <div className={cx(styles.addBtn, snapshot.isDragging && styles.hidden)}>
              {addButton}
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
draggableBlockHOC.displayName = 'Draggable';
