import { Avatar } from 'antd';
import { always, cond, equals, T } from 'ramda';
import React, { useMemo } from 'react';

import { IChangeLog } from '~common';
import { Globus } from '~icons';
import { getTimeByString } from '~utils';

import ChangeSection from '../ChangeSection';
import styles from './styles.scss';

interface IProps {
  data: IChangeLog;
}

const getType = cond<string, string>([
  [equals('HISTORY_CHANGE_TYPE_CREATE'), always('создана')],
  [equals('HISTORY_CHANGE_TYPE_UPDATE'), always('обновлена')],
  [T, always('удалена')],
]);

const ChangeLogItem: React.FC<IProps> = props => {
  const { data } = props;
  const {
    _id: index,
    userAgent,
    ip,
    uri,
    adminVersion,
    changes,
    changeType,
    updatedAt,
    employee,
  } = data;
  const { avatar, firstName, lastName, email } = employee || { firstName: 'Робот cron' };

  const typeChange = useMemo(() => getType(data.changeType), [data]);
  const updateDate = getTimeByString(updatedAt);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.person}>
          <Avatar size={48} src={avatar}>
            {firstName && firstName[0]}
          </Avatar>
          <div className={styles.personInfo}>
            <div className={styles.name}>
              {firstName && firstName} {lastName && lastName}
            </div>

            <div className={styles.email}>{email}</div>
          </div>
        </div>

        <div className={styles.serviceInfo}>
          <div className={styles.ip}>{ip}</div>
          <div className={styles.path}>{uri}</div>
          <div className={styles.path}>{adminVersion}</div>
        </div>

        <div className={styles.userAgent}>
          <Globus fill="#9A9A9A" />

          <span className={styles.userAgentInfo}>{userAgent}</span>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.titles}>
          <div className={styles.state}>
            <div className={styles.sateTitle}>Состояние контента #{index}</div>

            <div className={styles.sateAction}>Действие над моделью: {typeChange}</div>
          </div>

          <div className={styles.updateDate}>изменено {updateDate}</div>
        </div>

        {changes.map((item, idx) => (
          <ChangeSection key={idx} type={changeType} data={item} />
        ))}
      </div>
    </div>
  );
};

export default ChangeLogItem;
