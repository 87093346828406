import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
} from 'react-sortable-hoc';

import { IHeading } from '~common';
import { AddNew, ContentSpinner, GridWrapper } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { useHeadingsStore, useUserStore } from '~hooks';

import { HeadingsItem, openHeadingsModal } from '..';

const SortableItem = sortableElement(({ heading }: { heading: IHeading }) => (
  <HeadingsItem data={heading} />
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  );
});

const HeadingsList = observer(() => {
  const {
    fetchHeadings,
    isFirstLoading,
    headings,
    changeOrdering,
    allItemsCount,
    headingsLength,
  } = useHeadingsStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    fetchHeadings && fetchHeadings();
  }, []);

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  return (
    <InfinityScroll
      fetchItems={fetchHeadings}
      hasMore={allItemsCount !== headingsLength}
      isFetching={isFirstLoading}
    >
      <SortableContainer
        distance={1}
        axis="xy"
        onSortEnd={checkPermissions('headings.update') ? changeOrdering : undefined}
      >
        {headings &&
          headings.map((heading: IHeading) => (
            <SortableItem key={heading._id} heading={heading} index={heading.order} />
          ))}
        {checkPermissions('headings.create') && (
          <AddNew
            onClick={async () => {
              await openHeadingsModal();
            }}
          />
        )}
      </SortableContainer>
    </InfinityScroll>
  );
});

export default HeadingsList;
