import {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { blockReducer, BlockType } from '~common';
import { useDelay } from '~hooks/useDelay';

type TAction<TState> = Partial<TState>;
type TReducer<TState> = Reducer<TState, TAction<TState>>;
type TBlockState<TState> = {
  blockId: string;
  blockType: BlockType;
  incomingData: any;
  initialState: TState;
  isEditing: boolean;
  isForceUpdate: boolean;
  onChange: (data: any) => void;
};

export function useBlockStateReducer<TState>(
  blockState: TBlockState<TState>,
): [ReducerState<TReducer<TState>>, Dispatch<ReducerAction<TReducer<TState>>>] {
  const {
    initialState,
    blockId,
    blockType,
    incomingData,
    isEditing,
    isForceUpdate,
    onChange,
  } = blockState;
  const [state, dispatch] = useReducer<TReducer<TState>>(blockReducer, initialState);
  const [shouldFireEvent, setShouldFireEvent] = useState(false);

  const dispatchWithEvent = (action: TAction<TState>): void => {
    dispatch(action);
    setShouldFireEvent(true);
  };

  const onStateChange = useCallback(useDelay(onChange, 1000), [onChange]);

  useEffect(() => {
    if (!isEditing || isForceUpdate) {
      dispatch(incomingData);
    }
  }, [incomingData, isEditing, isForceUpdate]);

  useEffect(() => {
    if (shouldFireEvent) {
      const eventData = {
        type: blockType,
        meta: { blockId },
        ...state,
      };
      onStateChange(eventData);
      setShouldFireEvent(false);
    }
  }, [shouldFireEvent, state, onStateChange]);

  return [state, dispatchWithEvent];
}
