import React from 'react';

import { IBlock } from '~common';
import { noop } from '~utils';

import { MediaUpload } from '../../MediaUpload';
import { Textarea } from '../../Textarea';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

interface IProps extends IBlock {
  avatar?: unknown;
  onChange?(): void;
  onUpload?(): void;
  placeholder?: string;
  title?: string;
  uploadTitle?: string;
  uploadUrl?: string;
  value?: string;
}

const StoryCoverBlock: React.FC<IProps> = ({
  title = 'Заголовок темы',
  uploadTitle = 'Обложка',
  uploadUrl = '/v1/media/single-upload',
  avatar,
  onUpload = noop,
  placeholder = 'Заголовок',
  value = '',
  onChange = noop,
  blockProps = {},
  isFetching = false,
  isEditing = false,
}) => {
  return (
    <BlockWrapper title={title} wrapperClassname={styles.wrapper}>
      <div style={{ textAlign: 'center', color: 'gray', fontSize: '14px' }}>
        <div className={styles.avatar}>
          <MediaUpload {...avatar} onChange={onUpload} uploadUrl={uploadUrl} />
        </div>
        <div>{uploadTitle}</div>
      </div>

      <Textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={isFetching}
        isEditing={isEditing}
      />
    </BlockWrapper>
  );
};

export default StoryCoverBlock;
