import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

import { Button } from 'antd'
import { DateUtils } from 'react-day-picker'

import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'
import EventItem from './EventItem'
import SideCalendar from '../CalendarView/SideCalendar'

import styles from './styles.scss'

@inject(stores => {
  const {
    events,
    isFirstLoading,
    isEmptyEvents,
    setIsFirstLoading,
    fetchEventsByList,
    setSelectedDateEnd,
    selectedDate,
    setSelectedDate,
    selectedDateEnd
  } = stores.ScheduleStore
  const { resetPaginationParams } = stores.FetchPaginationStore
  const { setFilterValue } = stores.FilterParamsByListStore

  return {
    events,
    isFirstLoading,
    isEmptyEvents,
    setIsFirstLoading,
    fetchEventsByList,
    resetPaginationParams,
    setSelectedDate,
    setSelectedDateEnd,
    selectedDateEnd,
    selectedDate,
    setFilterValue
  }
})
@observer
class ListView extends Component {
  componentWillUnmount() {
    const { resetPaginationParams } = this.props

    resetPaginationParams()
  }

  handleDayClick = day => {
    const {
      selectedDate,
      selectedDateEnd,
      resetPaginationParams,
      setSelectedDate,
      setSelectedDateEnd,
      fetchEventsByList,
      setIsFirstLoading
    } = this.props

    const range = DateUtils.addDayToRange(day, {
      from: selectedDate,
      to: selectedDateEnd
    })

    setSelectedDate(range.from)
    setSelectedDateEnd(range.to)

    if (range.to) {
      setIsFirstLoading(true)
      resetPaginationParams()
      fetchEventsByList()
    }
  }

  handleResetList = () => {
    const {
      resetPaginationParams,
      setSelectedDate,
      setSelectedDateEnd,
      fetchEventsByList,
      setIsFirstLoading,
      setFilterValue
    } = this.props

    resetPaginationParams()
    setSelectedDate(undefined)
    setSelectedDateEnd(undefined)

    setFilterValue('filter.start')
    setFilterValue('filter.end')

    setIsFirstLoading(true)
    fetchEventsByList()
  }

  render() {
    const { events, isFirstLoading, isEmptyEvents, selectedDate, selectedDateEnd } = this.props

    const endDate =
      selectedDateEnd === undefined
        ? selectedDateEnd
        : new Date(
            moment(selectedDateEnd)
              .startOf('day')
              .utc()
              .format()
          )
    const startDate =
      selectedDate === undefined
        ? selectedDate
        : new Date(
            moment(selectedDate)
              .startOf('day')
              .utc()
              .format()
          )

    return (
      <div className={styles.root}>
        <div className={styles.list}>
          {isEmptyEvents && !isFirstLoading && <EmptyContent text="Нет мероприятий" />}

          {isFirstLoading ? (
            <ContentSpinner />
          ) : (
            events.map(event => (
              <div key={event._id} className={styles.itemRoot}>
                <EventItem event={event} />
              </div>
            ))
          )}
        </div>

        <div className={styles.calendar}>
          <SideCalendar
            className="Range"
            selectedDays={[selectedDate, { from: startDate, to: endDate }]}
            month={selectedDate}
            modifiers={{
              weekend: {
                daysOfWeek: [6, 0]
              },
              start: selectedDate,
              end: endDate
            }}
            onDayClick={this.handleDayClick}
          />

          <div className={styles.resetButton}>
            <Button disabled={isFirstLoading} onClick={this.handleResetList} type="link">
              Сбросить
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default ListView

ListView.propTypes = {
  events: PropTypes.array,
  fetchEvents: PropTypes.func,
  fetchEventsByList: PropTypes.func,
  isEmptyEvents: PropTypes.bool,
  isFirstLoading: PropTypes.bool,
  resetPaginationParams: PropTypes.any,
  selectedDate: PropTypes.any,
  selectedDateEnd: PropTypes.any,
  setFilterValue: PropTypes.func,
  setIsFirstLoading: PropTypes.func,
  setSelectedDate: PropTypes.func,
  setSelectedDateEnd: PropTypes.func
}
