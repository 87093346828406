export enum ButtonType {
  Border = 'border',
  Danger = 'danger',
  Default = 'default',
  Ghost = 'ghost',
  Link = 'link',
  Primary = 'primary',
}

export enum ButtonShape {
  Circle = 'circle',
  CircleOutline = 'circle-outline',
  Round = 'round',
}

export enum ButtonSize {
  Default = 'default',
  Large = 'large',
  Small = 'small',
}
