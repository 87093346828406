import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Popconfirm } from 'antd';

import styles from './styles.scss';

const DeleteButton = ({ text, onDelete, disabled = false }) => (
  <Popconfirm
    title={`${text} ?`}
    disabled={disabled}
    onConfirm={onDelete}
    okText="Да"
    cancelText="Нет"
  >
    <div className={styles.root}>
      <Icon type="close" />
      &nbsp;
      {text}
    </div>
  </Popconfirm>
);

export default DeleteButton;

DeleteButton.propTypes = {
  onDelete: PropTypes.func,
  text: PropTypes.string,
};

DeleteButton.defaultProps = {
  text: 'Удалить',
};
