import { Skeleton } from 'antd';
import React from 'react';

export interface IBlockSkeleton {
  isFetching: boolean;
}

export const BlockSkeleton: React.FC<IBlockSkeleton> = props => {
  const { children } = props;
  const { isFetching } = props;
  return (
    <Skeleton active title={false} paragraph={{ rows: 4 }} loading={isFetching}>
      {children}
    </Skeleton>
  );
};
