import { HistoryOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { always, apply, cond, ifElse, juxt, pipe, propEq, propOr, T } from 'ramda';
import React, { ReactNode, useCallback } from 'react';
import ReactJson from 'react-json-view';

import { IChange } from '~common';
import { fieldNames } from '~constants';
import { useChangeLogStore, useFetchPaginationStore, useFilterParamsByListStore } from '~hooks';
import { ArrowRight } from '~icons';
import { getNewBlockId, nullable } from '~utils';

import styles from './styles.scss';

interface IBlockPopover {
  resourceId: string;
}

interface IItemWrapperProps {
  data: IChange;
  popoverContent?: ReactNode;
}

interface IChangeSectionProps {
  data: IChange;
  type?: string;
}

const getPrettyString = (changeValue: string) => {
  try {
    const parsedJson = JSON.parse(changeValue);
    if (parsedJson.content) {
      return <ReactJson src={parsedJson.content} />;
    }

    if (typeof parsedJson === 'object') {
      return <ReactJson src={parsedJson} />;
    }

    return parsedJson.toString();
  } catch (e) {
    return changeValue;
  }
};

const getLabel = cond<Partial<IChange>, string>([
  [
    ({ oldValue = '', newValue = '', field = '' }) =>
      field === 'blocks' && getNewBlockId(newValue, oldValue).length > 0,
    always('Добавление блока'),
  ],
  [
    ({ oldValue = '', newValue = '', field = '' }) =>
      field === 'blocks' && getNewBlockId(oldValue, newValue).length > 0,
    always('Удаление блока'),
  ],
  [T, pipe(({ field = '' }) => fieldNames[field] ?? field)],
]);

const BlockPopover: React.FC<IBlockPopover> = observer(({ resourceId }) => {
  const { setFilterValue } = useFilterParamsByListStore();
  const { fetchChangeLog, setIsFirstLoading } = useChangeLogStore();
  const { resetPaginationParams } = useFetchPaginationStore();

  const onClick = useCallback(() => {
    setFilterValue('filter.resourceId', resourceId);
    setFilterValue('filter.resourceType', 'blocks');
    setIsFirstLoading(true);
    resetPaginationParams();
    fetchChangeLog();
    window.scrollTo(0, 0);
  }, [fetchChangeLog, resetPaginationParams, resourceId, setFilterValue, setIsFirstLoading]);

  return (
    <Tooltip title="Посмотреть историю по блоку">
      <h3 className={styles.icon}>
        <HistoryOutlined onClick={onClick} />
      </h3>
    </Tooltip>
  );
});

const getBlockPopover = ifElse(
  propEq('field', 'blocks'),
  pipe(
    juxt([propOr('', 'newValue'), propOr('', 'oldValue')]),
    apply(getNewBlockId),
    ([value]: string[]) => (value ? <BlockPopover resourceId={value} /> : null),
  ),
  nullable,
);

const ItemWrapper: React.FC<IItemWrapperProps> = React.memo(({ children, data }) => {
  return (
    <div className={styles.root}>
      <Popover overlayClassName="clean-popover" content={getBlockPopover(data)} placement="right">
        <Tooltip title={data.field}>
          <div className={styles.title}>{getLabel(data)}</div>
        </Tooltip>
      </Popover>

      <div className={styles.changes}>{children}</div>
    </div>
  );
});

const ChangeSection: React.FC<IChangeSectionProps> = props => {
  const { data, type } = props;
  const { newValue, oldValue } = data;

  if (type === 'HISTORY_CHANGE_TYPE_UPDATE') {
    return (
      <ItemWrapper data={data}>
        {oldValue && (
          <div className={classNames(styles.text, styles.old)}>{getPrettyString(oldValue)}</div>
        )}

        {newValue && oldValue && (
          <div className={styles.arrow}>
            <ArrowRight fill="#9A9A9A" />
          </div>
        )}

        {newValue && (
          <div className={classNames(styles.text, styles.new)}>{getPrettyString(newValue)}</div>
        )}
      </ItemWrapper>
    );
  }

  if (type === 'HISTORY_CHANGE_TYPE_CREATE') {
    return (
      <ItemWrapper data={data}>
        <div className={classNames(styles.text, styles.new)}>{getPrettyString(newValue)}</div>
      </ItemWrapper>
    );
  }

  return (
    <ItemWrapper data={data}>
      <div className={classNames(styles.text, styles.old)}>{getPrettyString(oldValue)}</div>
    </ItemWrapper>
  );
};

export default React.memo(ChangeSection);
