import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
} from 'react-sortable-hoc';

import { ICategory } from '~common';
import { AddNew, ContentSpinner, GridWrapper } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { openCategoriesModal } from '~containers';
import { useCategoriesStore, useUserStore } from '~hooks';

import CategoriesItem from '../CategoriesItem';

const SortableItem = sortableElement(({ category }: { category: ICategory }) => (
  <CategoriesItem data={category} />
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  );
});

const CategoriesList = observer(() => {
  const {
    fetchCategories,
    isFirstLoading,
    categories,
    changeOrdering,
    allItemsCount,
    categoriesLength,
  } = useCategoriesStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    fetchCategories && fetchCategories();
  }, []);

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  return (
    <InfinityScroll
      fetchItems={fetchCategories}
      hasMore={allItemsCount !== categoriesLength}
      isFetching={isFirstLoading}
    >
      <SortableContainer
        distance={1}
        axis="xy"
        onSortEnd={checkPermissions('categories.update') ? changeOrdering : undefined}
      >
        {categories &&
          categories.map((category: ICategory) => (
            <SortableItem key={category._id} category={category} index={category.order} />
          ))}
        {checkPermissions('categories.create') && (
          <AddNew
            onClick={async () => {
              await openCategoriesModal();
            }}
          />
        )}
      </SortableContainer>
    </InfinityScroll>
  );
});

export default CategoriesList;
