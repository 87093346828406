import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { DeleteOutlined } from '@ant-design/icons';

import { Button, Input, Spin } from 'antd';
import { noop } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { lazy, object, string } from 'yup';
import DeleteButton from '~components/DeleteButton';
import ItemWithLabel from '~components/ItemWithLabel';

import Modal from '~components/Modal';
import { EMPTY_STRING } from '~constants';
import { useFlowsStore } from '~hooks/useFlowsStore';

import declOfNum from '~utils/declOfNum';

import styles from './styles.scss';
import { useUserStore } from '~hooks';

const CREATE_TITLE = 'Создание нового потока';
const UPDATE_TITLE = 'Редактирование потока';
const COUNT_DECLINATIONS = ['публикация', 'публикации', 'публикаций'];

const PostsList = ({ posts = [], onDelete = () => {}, isLoading }) => {
  const countDeclination = declOfNum(posts.length, COUNT_DECLINATIONS);
  return (
    <div>
      <div className={styles.postsHead}>
        <div>Публикации в потоке</div>
        <div className={styles.count}>{`${posts.length} ${countDeclination}`}</div>
      </div>
      <div className={styles.posts}>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          posts.map(({ cover, title, _id }, index) => (
            <div key={_id} className={styles.post}>
              {cover && <img src={cover.url} alt="" />}
              <div className={styles.title}>{title}</div>
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                type="danger"
                size="small"
                ghost
                onClick={() => {
                  onDelete(_id, index);
                }}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

PostsList.propTypes = {
  posts: PropTypes.array,
  isLoading: PropTypes.bool,
  onDelete: PropTypes.func,
};

export const FlowsModal = Form.create({ name: 'flowsForm' })(
  observer(props => {
    const {
      isShowModal,
      setHideModal,
      editableFlow,
      updateFlow,
      resetEditableFlow,
      isActionModal,
      deleteFlow,
      createFlow,
      onSearch,
      searchTerm,
      setSearchTerm,
      editableFlowPostsLoading,
      editableFlowPosts,
      deletePost,
    } = useFlowsStore();
    const { checkPermissions } = useUserStore();

    const {
      form: { getFieldDecorator = noop, getFieldsValue = noop, validateFields = noop } = {},
    } = props;

    const modalTitle = editableFlow ? UPDATE_TITLE : CREATE_TITLE;

    const handleSubmit = async e => {
      e.preventDefault();

      const schema = object()
        .default(undefined)
        .shape({
          title: string(),
          alias: string(),
          description: string(),
          seo: object()
            .default(undefined)
            .shape({
              title: lazy((value, options) => string().default(options?.context?.title)),
              description: lazy((value, options) =>
                string().default(options?.context?.description),
              ),
            }),
        });

      await validateFields();
      const formData = getFieldsValue();

      const action = editableFlow ? updateFlow : createFlow;
      action(schema.cast(formData, { context: formData }));
    };

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={modalTitle}
        afterClose={resetEditableFlow}
      >
        <div className={styles.root}>
          <Form onSubmit={handleSubmit}>
            <ItemWithLabel label="Название потока">
              <Form.Item>
                {getFieldDecorator('title', {
                  initialValue: editableFlow?.title || EMPTY_STRING,
                  rules: [{ required: true, message: 'Обязательное поле!' }],
                })(<Input placeholder={'Введите название'} autoFocus disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="Описание">
              <Form.Item>
                {getFieldDecorator('description', {
                  initialValue: editableFlow?.description || EMPTY_STRING,
                })(<Input.TextArea placeholder={'Описание'} disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="Алиас">
              <Form.Item>
                {getFieldDecorator('alias', {
                  initialValue: editableFlow?.alias || EMPTY_STRING,
                })(
                  <Input
                    placeholder={'Алиас для URL (будет сгенерирован автоматически)'}
                    disabled={true}
                  />,
                )}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="SEO заголовок">
              <Form.Item>
                {getFieldDecorator('seo.title', {
                  initialValue: editableFlow?.seo?.title,
                })(<Input placeholder={'SEO заголовок'} disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="SEO описание">
              <Form.Item>
                {getFieldDecorator('seo.description', {
                  initialValue: editableFlow?.seo?.description,
                })(<Input.TextArea placeholder={'SEO описание'} disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <ItemWithLabel label="Добавить публикацию">
              <Input.Search
                className={styles.search}
                placeholder={'Вставьте ссылку или id'}
                disabled={isActionModal || editableFlowPostsLoading}
                onSearch={onSearch}
                search={'true'}
                text={'true'}
                value={searchTerm}
                onChange={setSearchTerm}
                enterButton
              />
            </ItemWithLabel>

            <PostsList
              posts={editableFlowPosts || []}
              onDelete={deletePost}
              isLoading={editableFlowPostsLoading}
            />

            <div className={styles.modalControls}>
              <Button
                loading={isActionModal}
                disabled={
                  isActionModal || !checkPermissions(`flows.${editableFlow ? 'update' : 'create'}`)
                }
                htmlType="submit"
                type="primary"
              >
                Сохранить изменения
              </Button>

              {editableFlow && (
                <DeleteButton
                  text="Удалить поток"
                  onDelete={deleteFlow}
                  disabled={!checkPermissions('flows.delete')}
                />
              )}
            </div>
          </Form>
        </div>
      </Modal>
    );
  }),
);

FlowsModal.defaultProps = {
  onSearch: () => {},
};

FlowsModal.propTypes = {
  editableFlow: PropTypes.any,
  form: PropTypes.any,
  editableFlowPostsLoading: PropTypes.bool,
  isActionModal: PropTypes.bool,
  isShowModal: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  getFlowPosts: PropTypes.func,
  deletePost: PropTypes.func,
  onSearch: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  resetEditableFlow: PropTypes.func,
  setHideModal: PropTypes.func,
  updateFlow: PropTypes.func,
  deleteFlow: PropTypes.func,
  createFlow: PropTypes.func,
  tempFlow: PropTypes.object,
  countDeclinations: PropTypes.array,
  editableFlowPosts: PropTypes.array,
};
