import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { ICategory } from '~common';
import { Image, WhiteBlockWithShadow } from '~components';
import { InjectStores } from '~store';

import styles from './styles.scss';

interface IProps extends InjectStores<'CategoriesStore'> {
  data?: ICategory;
}

@inject('CategoriesStore')
@observer
class CategoriesItem extends Component<IProps> {
  handleClick = () => {
    const { CategoriesStore: { setEditableCategory } = {}, data } = this.props;

    setEditableCategory && setEditableCategory(data);
  };

  render() {
    const { data: { title, alias, visible, cover } = {} } = this.props;

    return (
      <WhiteBlockWithShadow>
        <div className={styles.card} onClick={this.handleClick}>
          <h2 className={styles.cardTitle}>{title}</h2>
          <h3 className={styles.cardSubtitle}>{alias}</h3>
          <div className={styles.cardItem}>
            {cover && <Image className={styles.cardItemCover} url={cover?.url} alt="Cover" />}
            <div>
              <h3 className={styles.cardItemTitle}>Опубликована на сайте:</h3>
              <p className={styles.cardItemValue}>{visible ? 'Да' : 'Нет'}</p>
            </div>
          </div>
        </div>
      </WhiteBlockWithShadow>
    );
  }
}

export default CategoriesItem;
