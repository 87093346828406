import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';

const getData = ({ data }) => data;

const REDIRECTS_URL = '/redirects';
const DICTIONARIES_URL = '/dictionaries/redirects';

export class RedirectsTransportLayer {
  getDictionaries = () => {
    return API.get(`${DICTIONARIES_URL}`).then(getData);
  };

  getRedirects = (defaultFilter = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: REDIRECTS_URL, defaultFilter });

    return API.get(promiseUrl).then(getData);
  };

  createRedirect = redirect => {
    return API.post(`${REDIRECTS_URL}`, { ...redirect }).then(getData);
  };

  updateRedirectById = (id, redirect) => {
    return API.patch(`${REDIRECTS_URL}/${id}`, {
      data: { ...redirect },
    }).then(getData);
  };

  deleteRedirectById = id => {
    return API.delete(`${REDIRECTS_URL}/${id}`).then(getData);
  };
}
