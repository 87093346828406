import { Col, Row } from 'antd';
import classNames from 'classnames';
import { always, pipe } from 'ramda';
import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface Props {
  onTabChange?(tab: number): void;
  tabs: Array<{
    component: React.ReactNode;
    disabled?: boolean;
    title: string;
  }>;
}

export const Tabs: React.FC<Props> = ({ tabs, onTabChange }) => {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    onTabChange && onTabChange(tab);
  }, [tab]);

  return (
    <Row gutter={[0, 40]}>
      <Col span={24}>
        <Row gutter={[10, 10]}>
          {tabs.map(({ title, disabled = false }, index) => (
            <Col key={index}>
              <button
                type="button"
                onClick={pipe(always(index), setTab)}
                disabled={disabled}
                className={classNames(
                  styles.tab,
                  tab === index && styles.active,
                  disabled && styles.disabled,
                )}
              >
                {title}
              </button>
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24}>
        {tabs.map(({ component }, index) => (
          <div className={classNames(tab !== index && styles.hidden)} key={index}>
            {component}
          </div>
        ))}
      </Col>
    </Row>
  );
};
