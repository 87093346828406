import { action, computed, observable, runInAction } from 'mobx';
import { inc, propEq } from 'ramda';

import { IRegion, Maybe } from '~common';
import { EMPTY_STRING } from '~constants';
import { openRegionModal } from '~containers/Regions';
import { RegionsTransportLayer } from '~services/regionsApi';
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotificationInPromise } from '~utils';

export class RegionsStore {
  apiLayer = new RegionsTransportLayer();

  @observable
  regions: IRegion[] = [];

  @observable isFirstLoading = true;

  @observable isLoadingMore = false;

  @observable isActionModal = false;

  @observable
  regionsSearches: IRegion[] = [];

  @observable allItemsCount = 0;

  @observable editableRegion: Maybe<IRegion> = null;

  @action
  fetchRegions = (defaultFilter: Record<string, any> | string = EMPTY_STRING) => {
    const { offset, setPageOffset } = FetchPaginationStore;

    return this.apiLayer
      .getRegions(defaultFilter)
      .then(res => {
        const { data, meta } = res;

        if (offset === 0) {
          this.setRegions(data);
        } else {
          this.setRegions([...this.regions, ...data]);
        }

        setPageOffset(inc(offset));
        this.setAllItemsCount(meta.count);
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      })
      .catch(() => {
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      });
  };

  @action
  fetchRegionsBySearch = (term?) => {
    return this.apiLayer.getRegionsBySearch(term).then(res => {
      const { data } = res;

      runInAction(() => {
        this.regionsSearches = data;
      });

      return data;
    });
  };

  @action
  createRegion = region => {
    const { resetPaginationParams } = FetchPaginationStore;

    this.isActionModal = true;

    return this.apiLayer
      .createRegion(region)
      .then(({ data }) => {
        runInAction(() => {
          this.isActionModal = false;
          this.regions.unshift(data);
          this.setAllItemsCount(this.allItemsCount + 1);
          resetPaginationParams();
          this.fetchRegions();
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания региона',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  updateRegion = region => {
    const id = this.editableRegion?._id || null;

    this.isActionModal = true;

    const { data } = region;

    return this.apiLayer
      .updateRegionById(id, data)
      .then(({ data }) => {
        const editableRegionIndex = this.regions.findIndex(propEq('_id', id));

        runInAction(() => {
          this.isActionModal = false;
          this.regions[editableRegionIndex] = data;
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка обновления региона',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  deleteRegion = () => {
    const id = this.editableRegion?._id || null;

    this.isActionModal = true;
    return this.apiLayer
      .deleteRegionById(id)
      .then(() => {
        const editableRegionIndex = this.regions.findIndex(propEq('_id', id));

        runInAction(() => {
          this.isActionModal = false;
          this.regions.splice(editableRegionIndex, 1);
          this.resetEditableRegion();
          this.setAllItemsCount(this.allItemsCount - 1);
          return Promise.resolve(true);
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка удаления региона',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  setRegions = data => {
    this.regions = data;
  };

  @action
  setEditableRegion = async value => {
    this.editableRegion = value;

    await openRegionModal();
  };

  @action
  resetEditableRegion = () => {
    this.editableRegion = null;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value;
  };

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value;
  };

  @computed
  get regionsLength() {
    return this.regions.length;
  }

  @computed
  get isEmptyRegions() {
    return !this.regions.length;
  }
}

export default new RegionsStore();
