import React, { useCallback, useMemo } from 'react';

import { BlockType, IBlock, IMeta, IRaw, TypeBlock } from '~common';
import { TEXT_BLOCK } from '~constants';
import { useWindowDimensions } from '~hooks';

import { RichEditor } from '../../RichEditor';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

interface IChangeBlockData {
  charactersCount: number;
  editor: string;
  meta: IMeta;
  rawText: IRaw;
  rawTextHTML: string;
  type: TypeBlock;
}

export interface ITextBlockProps extends IBlock {
  addBlocks?(blocks: IBlock[], insertIndex: number, type: TypeBlock): void;
  editor?: string;
  onBlur?(data: IChangeBlockData): void;
  onChange?(data: IChangeBlockData): void;
}

const TextBlock: React.FC<ITextBlockProps> = props => {
  const {
    onChange,
    onBlur,
    addBlocks,
    blockProps = {},
    isFetching = false,
    isEditing = false,
  } = props;

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width < 480, [width]);

  const getData = useCallback(
    ({ raw: rawText, html }): IChangeBlockData => ({
      type: TEXT_BLOCK,
      rawText,
      rawTextHTML: html,
      charactersCount: rawText.blocks.reduce((acc, { text }) => acc + text.length, 0),
      editor: props.editor || 'draft-js',
      meta: { blockId: props.id },
    }),
    [props.editor, props.id],
  );

  const onChangeHandler = useCallback(
    data => {
      onChange && onChange(getData(data));
    },
    [getData, onChange],
  );

  const onBlurHandler = useCallback(
    data => {
      onBlur && onBlur(getData(data));
    },
    [getData, onBlur],
  );

  const onAddBlocks = useCallback(
    (blocks: IBlock[], insertIndex: number) =>
      addBlocks && addBlocks(blocks, insertIndex, BlockType.TextBlock),
    [addBlocks],
  );

  return (
    <BlockWrapper title="Текст" {...props.blockProps}>
      <div className={styles.root}>
        <RichEditor
          inline={false}
          needInline={!isMobile}
          blockIndex={props.index}
          rawData={props.rawText}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          addBlocks={onAddBlocks}
          readOnly={isFetching}
          isEditing={isEditing}
          preContentBlock={props.type === 'TEXT_DESCRIPTION'}
        />
      </div>
    </BlockWrapper>
  );
};

export default TextBlock;
