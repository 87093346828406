import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'

import Modal from '~components/Modal'
import ModalWrapper from './ModalWrapper'

const UPDATE_TITLE = 'Редактирование съемки'
const CREATE_TITLE = 'Создание съемки'
const DUPLICATE_TITLE = 'Редактирование дубликата съемки'

@inject(stores => {
  const {
    isShowModalEdit,
    setHideModalEdit,
    editableEvent,
    resetEditableEvent,
    isDuplicateEvent
  } = stores.ScheduleStore

  return {
    isShowModalEdit,
    setHideModalEdit,
    editableEvent,
    resetEditableEvent,
    isDuplicateEvent
  }
})
@observer
class EditModal extends Component {
  afterCloseModal = () => {
    const { resetEditableEvent } = this.props

    resetEditableEvent()
  }

  render() {
    const { editableEvent, setHideModalEdit, isShowModalEdit, isDuplicateEvent } = this.props

    let modalTitle = CREATE_TITLE

    if (isDuplicateEvent) {
      modalTitle = DUPLICATE_TITLE
    }

    if (editableEvent && !isDuplicateEvent) {
      modalTitle = UPDATE_TITLE
    }

    return (
      <Modal
        destroyOnClose
        width={1100}
        onClose={setHideModalEdit}
        isVisible={isShowModalEdit}
        title={modalTitle}
        afterClose={this.afterCloseModal}
      >
        <ModalWrapper />
      </Modal>
    )
  }
}

export default EditModal

EditModal.propTypes = {
  editableEvent: PropTypes.object,
  isDuplicateEvent: PropTypes.any,
  isShowModalEdit: PropTypes.bool,
  resetEditableEvent: PropTypes.func,
  setHideModalEdit: PropTypes.func
}
