import API from '~api';
import { AddRoleToEmployeesResponse, Role, RoleCreateResponse, RoleUpdateResponse } from '~common';
import { AbstractTransportLayer } from '~services/abstract';

const ROLES_URL = '/roles';

export class RolesTransportLayer extends AbstractTransportLayer<
  Role,
  RoleCreateResponse,
  RoleUpdateResponse
> {
  constructor() {
    super(ROLES_URL);
  }

  addRoleToEmployees = (roleId: string, data: AddRoleToEmployeesResponse) =>
    API.patch(`${ROLES_URL}/${roleId}/employees`, { data });
}
