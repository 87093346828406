import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { ISelectProps, Select } from '~components';
import { withFilterSelect } from '~containers/Selects/withFilterSelect';
import { useFilterParamsByListStore } from '~hooks';

const options = [
  { value: 'note', label: 'Примечание' },
  { value: 'alias', label: 'Алиас' },
  { value: 'type', label: 'Тип' },
  { value: 'advType', label: 'Особый тип' },
  { value: 'priority', label: 'Приоритет' },
  { value: 'section', label: 'Секции' },
  { value: 'categories', label: 'Категории' },
  { value: 'tags', label: 'Теги' },
  { value: 'flows', label: 'Потоки' },
  { value: 'authors', label: 'Авторы' },
  { value: 'coauthors', label: 'Соавторы' },
  { value: 'flags', label: 'Флаги' },
  { value: 'publicationDate', label: 'Дата публикации' },
  { value: 'draftPriorities', label: 'Приоритеты' },
];

const ChangesSelect: React.FC<ISelectProps<object>> = observer(props => {
  const { setFilterValue } = useFilterParamsByListStore();
  const { disabled } = props;

  useEffect(() => {
    disabled && setFilterValue('filter.changes', null);
  }, [disabled]);

  return <Select {...props} options={options} keyField="id" labelField="name" />;
});

export default withFilterSelect(ChangesSelect, { label: 'Изменения', name: 'filter.changes' });
