import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowRight } from '~icons';

import styles from './styles.scss';

const Header = () => {
  const history = useHistory();

  const onClickExit = useCallback(() => {
    history.push('/broadcasts/video');
  }, [history]);

  return (
    <div className={styles.editorHeader}>
      <div style={{ display: 'inline-block' }} onClick={onClickExit}>
        <div className={styles.backButton}>
          <ArrowRight fill="currentColor" style={{ transform: 'rotate(180deg) scale(.5)' }} />
          <span>Выйти из редактирования</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
