import { observable, action, computed } from 'mobx'

import API from '~api'

import FetchPaginationStore from './fetchPaginationStore'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'

const COMMENTS_URL = '/comments'

class PublicationCommentsStore {
  @observable comments = []

  @observable allItemsCount = 0

  @observable openedPost = null

  @observable isFirstLoading = true

  @observable isLoadingMore = false

  @action
  fetchComments = () => {
    const { offset } = FetchPaginationStore
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore

    const promiseUrl = getPromiseUrl({
      url: COMMENTS_URL,
      defaultFilter: `filter.page=${this.openedPost.id}`
    })

    const fetchCommentsPromise = API.get(promiseUrl)

    this.setIsLoadingMore(true)
    return fetchCommentsPromise
      .then(res => {
        const { data, meta } = res.data

        if (offset === 0) {
          this.setComments(data)
        } else {
          this.setComments([...this.comments, ...data])
        }

        this.setAllItemsCount(meta.count)
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
      .catch(() => {
        this.setIsFirstLoading(false)
        this.setIsLoadingMore(false)
      })
  }

  @action
  deleteComment = data => {
    const deleteCommentPromise = API.delete(`${COMMENTS_URL}/${data._id}`)

    return deleteCommentPromise
      .then(() => {
        this.setAllItemsCount(this.allItemsCount - 1)

        this.comments.forEach(comment => {
          if (comment._id === data._id) {
            this.comments.remove(comment)
          }
        })
      })
      .catch(() => {})
  }

  @action
  blockUser = props => {
    const { createdBy } = props

    const blockUserPromise = API.post(`/users/${createdBy._id}/ban`)

    return blockUserPromise.then(() => {
      this.comments.forEach(item => {
        if (item.createdBy._id === createdBy._id) {
          this.comments.remove(item)
        }
      })
    })
  }

  @action
  deleteReplies = reply => {
    let commentId = ''

    this.comments.forEach(comment => {
      if (comment.replies.length > 0) {
        comment.replies.forEach(replick => {
          if (replick._id === reply._id) {
            commentId = comment._id
          }
        })
      }
    })

    const deleteCommentPromise = API.delete(`${COMMENTS_URL}/${commentId}/replies/${reply._id}`)

    return deleteCommentPromise
      .then(() => {
        this.setAllItemsCount(this.allItemsCount - 1)

        const index = this.comments.findIndex(item => item._id === commentId)

        this.comments[index].replies.remove(reply)
      })
      .catch(() => {})
  }

  @action
  setComments = data => {
    this.comments = data
  }

  @action
  setOpenedPost = data => {
    this.openedPost = data
  }

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value
  }

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value
  }

  @action
  setHideModal = () => {
    this.openedPost = null
  }

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count
  }

  @computed
  get isShowModal() {
    return !!this.openedPost
  }

  @computed
  get commentsLength() {
    return this.comments.length
  }

  get isEmptyComments() {
    return !this.comments.length
  }
}

export default new PublicationCommentsStore()
