import { Avatar, Button, Checkbox, Col, Input, Pagination, Row } from 'antd';
import { get } from 'lodash';
import { assoc, dec, dissoc, inc, join, map, omit, pathOr, pipe, toPairs } from 'ramda';
import React, { useEffect, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { Delete, FilterArrow } from '~assets/icons';
import { IEmployee } from '~common';
import { Modal } from '~components';
import { useRolesStore } from '~hooks';
import SearchIco from '~icons/search.svg';

import styles from './styles.module.scss';

interface EmployeeBadgeProps {
  employee: IEmployee;
  onDelete: (employee: IEmployee) => void;
}

const SortView: React.FC<{ sort?: string }> = ({ sort, children }) => (
  <Row gutter={[10, 0]} className={styles.sort} align="middle">
    <Col>{children}</Col>
    {sort && (
      <Col>
        <FilterArrow />
      </Col>
    )}
  </Row>
);

const EmployeeBadge: React.FC<EmployeeBadgeProps> = ({ employee, onDelete }) => {
  const onClickDelete = () => {
    onDelete(employee);
  };

  return (
    <Row className={styles.badge} gutter={[5, 0]}>
      <Col className={styles.employeeName}>
        {employee.firstName} {employee.lastName}
      </Col>
      <Col>
        <Delete className={styles.deleteIcon} onClick={onClickDelete} />
      </Col>
    </Row>
  );
};

const getSort: (sort: Record<string, any>) => string = pipe(
  toPairs,
  map(([key, value]) => `${value === 'asc' ? '' : '-'}${key}`),
  join(','),
);

const RoleEmployeesModal = ({ isOpen, roleId, onResolve }) => {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<Record<string, string>>({});
  const [selected, setSelected] = useState<IEmployee[]>([]);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const { getEmployees, addRoleToEmployees } = useRolesStore();

  const fetchEmployees = async () => {
    try {
      setIsFetching(true);
      const {
        data: { data, meta },
      } = await getEmployees(`neq(${roleId})`, {
        offset: 10 * currentPage,
        limit: 10,
        search,
        sort: getSort(sort),
      });

      setCount(meta.count);
      setEmployees(data);
    } finally {
      setIsFetching(false);
    }
  };

  const onChangePage = page => {
    setCurrentPage(dec(page));
  };

  const onClose = () => {
    onResolve(false);
  };

  const onAdd = async () => {
    await addRoleToEmployees(
      roleId,
      selected.map(({ _id }) => _id),
    );

    onResolve(true);
  };

  const onTriggerSelect = (employee: IEmployee) => ({
    currentTarget: { checked },
  }: React.MouseEvent<HTMLInputElement>) => {
    if (checked) {
      setSelected([...selected, employee]);
    } else {
      setSelected(selected.filter(({ _id }) => _id !== employee._id));
    }
  };

  const onDeleteEmployee = (employee: IEmployee) => {
    setSelected(selected.filter(({ _id }) => employee._id !== _id));
  };

  const reset = () => {
    setSelected([]);
  };

  const onSearch = () => {
    setCurrentPage(0);
    fetchEmployees();
  };

  const onClickSort = (key: string) => () => {
    switch (sort[key]) {
      case 'asc':
        setSort(assoc(key, 'desc', sort));
        break;
      case 'desc':
        setSort(dissoc(key, sort));
        break;
      default:
        setSort(assoc(key, 'asc', sort));
    }
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [sort]);

  useEffect(() => {
    fetchEmployees();
  }, [currentPage, sort]);

  return (
    <Modal isVisible={isOpen} onClose={onClose} width={1200}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row gutter={10}>
            <Col span={24} className={styles.title}>
              Добавление сотрудника
            </Col>
            <Col span={24} className={styles.description}>
              При добавлении сотрудника его текущая роль будет заменена
            </Col>
          </Row>
        </Col>
        {selected.length > 0 && (
          <Col>
            <Row gutter={[14, 14]}>
              <Col className={styles.selected}>
                {selected.map(employee => (
                  <EmployeeBadge
                    employee={employee}
                    key={employee._id}
                    onDelete={onDeleteEmployee}
                  />
                ))}
              </Col>
              <Col span={24}>
                <Row gutter={[10, 0]}>
                  <Col>
                    <Button type="primary" onClick={onAdd}>
                      Добавить
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={reset}>Отмена</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Row>
            <Col span={18}>
              <Input
                value={search}
                className={styles.input}
                prefix={<SearchIco fill="#9A9A9A" width={24} height={24} />}
                placeholder="Найти"
                onChange={pipe(pathOr('', ['currentTarget', 'value']), setSearch)}
                onPressEnter={onSearch}
              />
            </Col>
            <Col span={6}>
              <Button onClick={onSearch} className={styles.dskSearchButton} type="primary">
                НАЙТИ В СОТРУДНИКАХ
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row gutter={[20, 20]}>
                <Col
                  span={6}
                  offset={2}
                  className={styles.columnName}
                  onClick={onClickSort('lastName')}
                >
                  <SortView sort={sort?.lastName}>ФИО</SortView>
                </Col>
                <Col span={6} className={styles.columnName} onClick={onClickSort('email')}>
                  <SortView sort={sort?.email}>E-MAIL</SortView>
                </Col>
                <Col span={2} className={styles.columnName} onClick={onClickSort('index')}>
                  <SortView sort={sort?.index}>ID</SortView>
                </Col>
                <Col span={4} className={styles.columnName}>
                  ОТДЕЛ
                </Col>
                <Col span={4} className={styles.columnName}>
                  ТЕКУЩАЯ РОЛЬ
                </Col>
              </Row>
            </Col>

            {employees.map(employee => (
              <Col span={24} className={styles.row} key={employee.index}>
                <Row justify="space-between" gutter={[20, 20]} align="middle">
                  <Col span={2}>
                    <Checkbox
                      checked={!!selected.find(selected => selected._id === employee._id)}
                      onClick={onTriggerSelect(employee)}
                    />
                  </Col>
                  <Col className={styles.fio} span={6}>
                    <Row gutter={[20, 0]} align="middle">
                      <Col span={6}>
                        <Avatar src={get(employee.avatar, 'url', '')} size={32}>
                          {employee.firstName && employee.firstName[0]}
                        </Avatar>
                      </Col>
                      <Col span={18}>
                        {employee.firstName && employee.firstName}{' '}
                        {employee.lastName && employee.lastName}
                      </Col>
                    </Row>
                  </Col>
                  <Col className={styles.email} span={6}>
                    {employee.email}
                  </Col>
                  <Col className={styles.index} span={2}>
                    {employee.index}
                  </Col>
                  <Col className={styles.index} span={4}>
                    {employee.department?.name}
                  </Col>
                  <Col className={styles.index} span={4}>
                    {employee?.role?.name}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          <Pagination
            current={inc(currentPage)}
            onChange={onChangePage}
            total={count}
            defaultCurrent={6}
            hideOnSinglePage
            showSizeChanger={false}
            defaultPageSize={10}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export const roleEmployeesModal = createModal(RoleEmployeesModal);
