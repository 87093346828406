import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Event extends Component {
  render() {
    const { title } = this.props

    return <div>{title}</div>
  }
}

export default Event

Event.propTypes = {
  title: PropTypes.string
}
