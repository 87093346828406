import { CheckOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserStore, useVideoStore } from '~hooks';
import { Close } from '~icons';

import styles from './styles.scss';

const Actions = observer(() => {
  const history = useHistory();
  const {
    isPublished,
    streamStatus,
    type,
    publish,
    unpublish,
    save,
    startStream,
    stopStream,
    deleteVideo,
  } = useVideoStore();
  const { checkPermissions } = useUserStore();

  const onClickPublish = useCallback(() => {
    if (isPublished) {
      unpublish().then(() => {
        message.success('Видео успешно снято с публикации!');
      });
    } else {
      publish().then(() => {
        message.success('Видео успешно опубликовано!');
      });
    }
  }, [isPublished, publish, unpublish]);

  const onClickSave = useCallback(() => {
    save()
      ?.then(() => {
        message.success('Видео успешно сохранено!');
      })
      .catch(err => {
        message.error(`Произошла ошибка! \n${JSON.stringify(err)}`);
      });
  }, [save]);

  const streamControl = useCallback(() => {
    if (streamStatus === 'STREAM_STATUS_NOT_STARTED') {
      startStream()?.catch(err => {
        message.error(`Произошла ошибка! \n${JSON.stringify(err)}`);
      });
    }

    if (streamStatus === 'STREAM_STATUS_RECORDING') {
      stopStream()?.catch(err => {
        message.error(`Произошла ошибка! \n${JSON.stringify(err)}`);
      });
    }
  }, [startStream, stopStream, streamStatus]);

  const onClickDelete = useCallback(() => {
    Modal.confirm({
      title: 'Вы действительно хотите удалить видео?',
      onOk: () => {
        deleteVideo().then(() => {
          message.success('Видео успешно удалено!');
          history.push('/broadcasts/video');
        });
      },
    });
  }, [deleteVideo, history]);

  // TODO: вернуть если запустим стримы
  const btnProps = useMemo(() => {
    switch (streamStatus) {
      case 'STREAM_STATUS_NOT_STARTED':
        return {
          title: 'Начать запись',
          ico: <PlayCircleOutlined />,
          styles: styles.save,
          disabled: false,
        };
      case 'STREAM_STATUS_RECORDING':
        return {
          title: 'Завершить запись',
          ico: <StopOutlined />,
          styles: null,
          disabled: false,
        };
      default:
        return {
          title: 'Запись завершена',
          ico: <CheckOutlined />,
          styles: null,
          disabled: true,
        };
    }
  }, [streamStatus]);

  return (
    <div className={styles.buttons}>
      <div className={styles.left}>
        {checkPermissions(`videos.publish`) && (
          <Button type="primary" className={styles.button} onClick={onClickPublish}>
            {isPublished ? 'Снять с публикации' : 'Опубликовать'}
          </Button>
        )}

        {checkPermissions('videos.delete') && (
          <Button type="link" className={cn(styles.button, styles.delete)} onClick={onClickDelete}>
            <Close style={{ fill: 'currentColor' }} /> Удалить видео
          </Button>
        )}

        {/* TODO: вернуть если запустим стримы */}
        {type === 'VIDEO_TYPE_STREAM' && (
          <Button
            disabled={btnProps.disabled}
            onClick={streamControl}
            icon={btnProps.ico}
            type="primary"
            className={cn(styles.button, btnProps.styles)}
          >
            {btnProps.title}
          </Button>
        )}
        {/* TODO: вернуть если запустим стримы */}
      </div>

      <Button
        type="primary"
        className={cn(styles.button, styles.save)}
        onClick={onClickSave}
        disabled={!checkPermissions('videos.update')}
      >
        Сохранить
      </Button>
    </div>
  );
});

export default Actions;
