import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { IHeaderPreview } from '~common';
import { AddNew, ContentSpinner, GridWrapper } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { useHeaderPreviewsStore, useUserStore } from '~hooks';

import { HeaderPreviewsItem, openHeaderPreviewsModal } from '..';

const Item = ({ headerPreview }: { headerPreview: IHeaderPreview }) => (
  <HeaderPreviewsItem data={headerPreview} />
);

const Container = ({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  );
};

const HeaderPreviewsList = observer(() => {
  const {
    fetchHeaderPreviews,
    isFirstLoading,
    headerPreviews,
    allItemsCount,
    headerPreviewsLength,
  } = useHeaderPreviewsStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    fetchHeaderPreviews && fetchHeaderPreviews();
  }, []);

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  return (
    <InfinityScroll
      fetchItems={fetchHeaderPreviews}
      hasMore={allItemsCount !== headerPreviewsLength}
      isFetching={isFirstLoading}
    >
      <Container>
        {headerPreviews &&
          headerPreviews.map((headerPreview: IHeaderPreview) => (
            <Item key={headerPreview._id} headerPreview={headerPreview} />
          ))}
        {checkPermissions('headerPreviews.create') && (
          <AddNew
            onClick={async () => {
              await openHeaderPreviewsModal();
            }}
          />
        )}
      </Container>
    </InfinityScroll>
  );
});

export default HeaderPreviewsList;
