import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { ContentWrapper, HeaderPageSearchBar, SettingsNavigation } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { openRedirectsModal, RedirectsFilter, RedirectsList } from '~containers/Redirects';
import { useRedirectsStore, useUserStore } from '~hooks';

import s from './styles.scss';

const PAGE_TITLE = 'Все редиректы';
const COUNT_DECLINATIONS = ['редирект', 'редиректа', 'редиректов'];
const SEARCH_BTN_TEXT = 'Найти в редиректах';
const CREATE_BTN_TEXT = 'Добавить редирект';

const SettingsRedirectsPage = observer(() => {
  const {
    getRedirects,
    fetchDictionaries,
    isFetching,
    allItemsCount,
    hasMoreRedirectItemsToFetch,
  } = useRedirectsStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    fetchDictionaries && fetchDictionaries();
  }, []);

  return (
    <>
      <SettingsNavigation />
      <div className={s.search}>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={checkPermissions('redirects.update') && openRedirectsModal}
          onSearch={getRedirects}
        />
      </div>
      <RedirectsFilter />
      <ContentWrapper
        title={PAGE_TITLE}
        countItems={allItemsCount}
        countDeclinations={COUNT_DECLINATIONS}
      >
        <InfinityScroll
          fetchItems={getRedirects}
          hasMore={hasMoreRedirectItemsToFetch}
          isFetching={isFetching}
        >
          <RedirectsList />
        </InfinityScroll>
      </ContentWrapper>
    </>
  );
});

export default React.memo(SettingsRedirectsPage);
