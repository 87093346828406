import { notification } from 'antd';
import { action, computed, observable, ObservableMap } from 'mobx';

import { IMovePostHistoryItem } from '~common';
import { MovePostsTransportLayer } from '~services/movePostsApi';
// eslint-disable-next-line import/no-named-as-default
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotification } from '~utils';

import OverlayStore from './OverlayStore';

export class MovePublicationsStore {
  apiLayer = new MovePostsTransportLayer();

  @observable
  moveFromToTypes = [];

  @observable
  movePostTypes = [];

  @observable
  migrationStatuses = [];

  @observable
  movePostsHistory: ObservableMap<string, IMovePostHistoryItem> = observable.map([]);

  @observable movePostsHistoryItemsCount = 0;

  @observable isFetching = false;

  @observable isMigrationProcessing = false;

  @computed
  get movePostsHistoryItems(): IMovePostHistoryItem[] {
    return [...this.movePostsHistory.values()];
  }

  @computed
  get historyFetchedCount() {
    return this.movePostsHistory.size;
  }

  @computed
  get isEmptyHistory(): boolean {
    return this.historyFetchedCount === 0;
  }

  @computed
  get hasMoreHistoryItemsToFetch(): boolean {
    return this.historyFetchedCount !== this.movePostsHistoryItemsCount;
  }

  @action
  setMovePostsHistoryItemsCount = (movePostsHistoryItemsCount: number) => {
    this.movePostsHistoryItemsCount = movePostsHistoryItemsCount;
  };

  @action
  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };

  @action
  setIsMigrationProcessing = isMigrationProcessing => {
    this.isMigrationProcessing = isMigrationProcessing;
  };

  @action
  setProcessing = processing => {
    const { setVisible, setText } = OverlayStore;
    setText('Идет регистрация миграции. Пожалуйста, подождите...');
    setVisible(processing);
    this.setIsMigrationProcessing(processing);
  };

  @action
  setMovePostHistory = (movePostsHistory: IMovePostHistoryItem[] = []) => {
    this.movePostsHistory.replace(
      new Map(
        movePostsHistory.map(movePostsHistoryItem => {
          return [movePostsHistoryItem._id, movePostsHistoryItem];
        }),
      ),
    );
  };

  @action
  fetchDictionaries = () => {
    return this.apiLayer
      .getDictionaries()
      .then(({ movePostTypes, moveFromToTypes, migrationStatuses }) => {
        this.movePostTypes = movePostTypes;
        this.moveFromToTypes = moveFromToTypes;
        this.migrationStatuses = migrationStatuses;
      });
  };

  @action
  movePost = data => {
    const { resetPaginationParams } = FetchPaginationStore;
    this.setProcessing(true);
    return this.apiLayer
      .movePost(data)
      .then(() => {
        notification.success({ message: 'Миграция зарегистрирована', duration: 3 });
        this.setProcessing(false);
        return Promise.resolve(true);
      })
      .catch(reason => {
        showErrorNotification('Ошибка регистрации миграции', reason);
        this.setProcessing(false);
        return Promise.reject(reason);
      })
      .finally(() => {
        resetPaginationParams();
        this.fetchMovedPosts();
      });
  };

  @action
  restorePost = id => {
    const { resetPaginationParams } = FetchPaginationStore;
    this.setProcessing(true);
    return this.apiLayer
      .restorePost(id)
      .then(() => {
        notification.success({ message: 'Обратная миграция зарегистрирована', duration: 3 });
        this.setProcessing(false);
        return Promise.resolve(true);
      })
      .catch(reason => {
        showErrorNotification('Ошибка регистрации обратной миграции', reason);
        this.setProcessing(false);
        return Promise.reject(reason);
      })
      .finally(() => {
        resetPaginationParams();
        this.fetchMovedPosts();
      });
  };

  @action
  fetchMovedPosts = (defaultFilter = 'sort=-createdAt') => {
    const { offset, setPageOffset } = FetchPaginationStore;
    const isNewFetch = offset === 0;

    if (isNewFetch) {
      this.setIsFetching(true);
      this.setMovePostHistory([]);
      this.setMovePostsHistoryItemsCount(0);
    }

    return this.apiLayer
      .getMovedPosts(defaultFilter)
      .then(({ data: movePostsHistory = [], meta }) => {
        if (isNewFetch) {
          this.setMovePostHistory(movePostsHistory);
        } else {
          this.setMovePostHistory([...this.movePostsHistory.values(), ...movePostsHistory]);
        }
        this.setMovePostsHistoryItemsCount(meta.count);
        setPageOffset(offset + 1);
        return Promise.resolve(movePostsHistory);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки истории миграций', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.setIsFetching(false);
      });
  };
}

export default new MovePublicationsStore();
