import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ContentWrapper, HeaderPageSearchBar, Sorting } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { RoleList } from '~containers';
import { useRolesStore, useUserStore } from '~hooks';

const COUNT_DECLINATIONS = ['роль', 'роли', 'ролей'];

const SORTING = [
  {
    label: 'По активности',
    type: 'active',
  },
  {
    label: 'По названию',
    type: 'name',
  },
];

export const RolesPage = observer(() => {
  const { allItemsCount, isFirstLoading, getList, countItems, resetList } = useRolesStore();
  const { checkPermissions } = useUserStore();
  const history = useHistory();

  const onCreate = async () => {
    history.push('/roles/new');
  };

  return (
    <>
      <HeaderPageSearchBar
        searchButtonText="Найти в ролях"
        createButtonText="создать роль"
        onCreateElement={checkPermissions('roles.create') && onCreate}
        onSearch={resetList}
      />
      <ContentWrapper
        title="Все роли"
        countItems={allItemsCount}
        countDeclinations={COUNT_DECLINATIONS}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Sorting onSort={resetList} data={SORTING} />
          </Col>
          <Col span={24}>
            <InfinityScroll
              fetchItems={getList}
              hasMore={allItemsCount !== countItems}
              isFetching={isFirstLoading}
            >
              <RoleList />
            </InfinityScroll>
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
});
