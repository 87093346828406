import { Checkbox, DatePicker, Select } from 'antd'; // TODO: вернуть если запустим стримы
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { IDictionary } from '~common';
import { ItemWithLabel as Labeled } from '~components';
import { useFlagsStore, useUserStore, useVideoStore } from '~hooks';

import styles from './styles.scss';

const { Option } = Select;
const inputStyle = {
  width: '250px',
};

const flagTitle = {
  isMain: 'Главный стрим',
  isMainSide: 'Видео для 1С',
  visible: 'Видимость на сайте',
  // blockedByRKN: 'Блокировка РКН',
  // darkSide: 'Тёмная сторона',
  // finishRecord: 'Не завершать стрим',
  // commentsAllowed: 'Комментарии в посте',
};

const Settings = observer(() => {
  const {
    availablePlaylists,
    dictionaries,
    playlists,
    videoPriority,
    type,
    section,
    priority,
    streamStatus, // TODO: вернуть если запустим стримы
    start, // TODO: вернуть если запустим стримы
    end, // TODO: вернуть если запустим стримы
    setVideoPriority,
    setPriority,
    setPlaylists,
    setType,
    setSection,
    onChangeFlags,
    setStart, // TODO: вернуть если запустим стримы
    setEnd, // TODO: вернуть если запустим стримы
    onChangeRecording,
    flags,
    recording,
  } = useVideoStore();

  const { checkPermissions } = useUserStore();

  const flagsOptions = Object.entries(flagTitle).map(([key, value]) => ({
    value: key,
    label: value,
    disabled: !checkPermissions('videos.update'),
  }));

  const { videoPosts } = useFlagsStore();
  const viewPriority = useMemo(() => videoPosts, [videoPosts]);

  const {
    videoPriorities = [],
    videoTypes = [],
    sections = [],
    postPriorities = [],
  } = dictionaries;

  return (
    <div className={styles.wrapper}>
      <Labeled label="Плейлист" outerClass={styles.field}>
        <Select
          mode="multiple"
          style={{ width: '400px' }}
          value={playlists}
          placeholder="Выберите плейлист"
          defaultValue={undefined}
          disabled={!checkPermissions('videos.update')}
          onChange={setPlaylists}
        >
          {availablePlaylists.map(({ title, _id }) => (
            <Option value={_id}>{title}</Option>
          ))}
        </Select>
      </Labeled>

      <Labeled label="Приоритет видео" outerClass={styles.field}>
        <Select
          style={inputStyle}
          placeholder="Выберите приоритет"
          defaultValue={0}
          value={videoPriority}
          onChange={setVideoPriority}
          disabled={!checkPermissions('videos.update')}
        >
          {videoPriorities.map(priority => (
            <Option value={priority.id}>{priority.id}</Option>
          ))}
        </Select>
      </Labeled>

      <Labeled label="Тип видео" outerClass={styles.field}>
        <Select
          style={inputStyle}
          placeholder="Выберите тип"
          defaultValue="video"
          // @ts-ignore
          value={type || undefined}
          onChange={setType}
          disabled={!checkPermissions('videos.update')}
        >
          {videoTypes.map(type => (
            <Option value={type.id}>{type.ru}</Option>
          ))}
        </Select>
      </Labeled>

      {viewPriority && (
        <>
          <Labeled label="Раздел" outerClass={classNames(styles.field, styles.section)}>
            <Select
              style={{ width: '400px' }}
              placeholder="Выберите раздел"
              value={section || undefined}
              onChange={setSection}
              disabled={!checkPermissions('videos.update')}
            >
              {sections?.map(value => (
                <Option value={value._id}>{value.title}</Option>
              ))}
            </Select>
          </Labeled>
          {/* TODO добавить отображение */}
          {false && (
            <Labeled label="Приоритет поста" outerClass={styles.field}>
              <Select
                style={inputStyle}
                placeholder="Выберите приоритет"
                value={priority || undefined}
                onChange={setPriority}
                disabled={!checkPermissions('videos.update')}
              >
                {postPriorities?.map(value => (
                  <Option value={value.id}>{value.ru}</Option>
                ))}
              </Select>
            </Labeled>
          )}
        </>
      )}

      {/* TODO: вернуть если запустим стримы */}
      {(videoPosts ? (type as IDictionary)?.id : type) === 'VIDEO_TYPE_STREAM' && (
        <div className={styles.streamDateWrapper}>
          <Labeled label="Начать Запись" outerClass={styles.field}>
            <DatePicker
              format="DD.MM.YYYY в HH:mm:ss"
              onChange={setStart}
              value={start}
              placeholder="Дата и время"
              showTime
              style={inputStyle}
              disabled={streamStatus === 'STREAM_STATUS_FINISHED'}
            />
          </Labeled>

          <Labeled label="Закончить запись" outerClass={styles.field}>
            <DatePicker
              format="DD.MM.YYYY в HH:mm:ss"
              onChange={setEnd}
              value={end}
              placeholder="Дата и время"
              showTime
              style={inputStyle}
              disabled={streamStatus === 'STREAM_STATUS_FINISHED'}
            />
          </Labeled>
        </div>
      )}
      {/* TODO: вернуть если запустим стримы */}
      <div className={styles.row}>
        {flagsOptions.map(flag => (
          <Checkbox
            onChange={e => {
              const newFlags = { ...flags };
              newFlags[flag.value] = e.target.checked;
              onChangeFlags(newFlags);
            }}
            checked={flags[flag.value]}
            value={flag.value}
          >
            {flag.label}
          </Checkbox>
        ))}
        <Checkbox
          onChange={e => onChangeRecording(!e.target.checked)}
          checked={!recording.autoFinish}
        >
          Не завершать стрим
        </Checkbox>
      </div>
    </div>
  );
});

export default Settings;
