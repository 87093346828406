import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import FlowItem from '~components/Flows/FlowItem'
import GridWrapper from '~components/GridWrapper'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

@inject(stores => {
  const { flows, isFirstLoading, isEmptyFlows } = stores.FlowsStore

  return {
    flows,
    isFirstLoading,
    isEmptyFlows
  }
})
@observer
class FlowList extends Component {
  render() {
    const { flows, isFirstLoading, isEmptyFlows } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (isEmptyFlows) return <EmptyContent text="Нет потоков" />

    return (
      <GridWrapper gridSize={5} maxItemWidth={285}>
        {flows.map(flow => (
          <FlowItem key={flow._id} flow={flow} />
        ))}
      </GridWrapper>
    )
  }
}

export default FlowList

FlowList.propTypes = {
  isFirstLoading: PropTypes.bool,
  isEmptyFlows: PropTypes.bool,
  flows: PropTypes.array
}
