import { observer } from 'mobx-react';
import { isEmpty } from 'ramda';
import React from 'react';

import { ContentSpinner, EmptyContent, GridWrapper } from '~components';
import { useDepartmentsStore } from '~hooks';

import DepartmentItem from '../DepartmentItem';

const DepartmentList = observer(() => {
  const { departments, isFirstLoading } = useDepartmentsStore();

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  if (isEmpty(departments)) return <EmptyContent text="Нет отделов" />;

  return (
    <GridWrapper gridSize={5} maxItemWidth={285}>
      {departments.map(department => (
        <DepartmentItem key={department._id} department={department} />
      ))}
    </GridWrapper>
  );
});

export default DepartmentList;
