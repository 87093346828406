import { DatePicker } from 'antd';
import moment from 'moment';
import { always, ifElse, isNil, map, pipe, split } from 'ramda';
import React, { useCallback } from 'react';

import { FilterItem } from '~common';

const { RangePicker } = DatePicker;

interface Props {
  item: FilterItem;

  onChange(name: string, value: any): void;

  value: string;
}

const parseValue = ifElse(isNil, always(undefined), pipe(split(','), map(moment)));

export const DateRange: React.FC<Props> = ({ onChange, value, item }) => {
  const { name } = item;
  const dateValue = parseValue(value);

  const handleChange = useCallback(
    (event, dates) => {
      const [startDate, endDate] = dates;
      onChange(name, startDate && endDate ? `${startDate},${endDate}` : undefined);
    },
    [name, onChange],
  );

  return (
    <RangePicker
      style={{
        width: '100%',
      }}
      onChange={handleChange}
      value={dateValue}
      placeholder={['Начальная', 'Конечная']}
    />
  );
};
