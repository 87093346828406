const config = {
  cropPresets: [
    { name: 'Исходный', value: 0 },
    { name: '3 : 2', value: 1.5 },
    { name: '16 : 9', value: 1.7777 }
  ],
  tools: ['filters', 'crop', 'resize'],
  watermark: {
    urls: [
      'https://li-dev-info-bucket-1.s3.eu-west-3.amazonaws.com/wow-03.png',
      'https://li-dev-info-bucket-1.s3.eu-west-3.amazonaws.com/%D0%B4%D0%B8%D1%87%D1%8C-14.png'
    ]
  },
  theme: {
    colors: {
      primaryBg: '#1f232d',
      primaryBgHover: '#637381',
      secondaryBg: '#263138',
      secondaryBgHover: '#34444c',
      text: '#F9FAFB',
      textHover: '#fff',
      textMute: '#aaa',
      textWarn: '#f7931e',
      secondaryBgOpacity: 'rgba(0, 0, 0, 0.75)',
      accent: '#34c759',

      border: '#161e23',
      borderLight: '#70777f'
    }
  },
  translations: {
    ru: {
      'header.image_editor_title': 'Редактор изображений',
      'toolbar.download': 'Загрузить',
      'toolbar.save': 'Сохранить',
      'toolbar.apply': 'Применить',
      'toolbar.cancel': 'Отмена',
      'toolbar.go_back': 'Назад',
      'toolbar.adjust': 'Выравнивание',
      'toolbar.effects': 'Эффекты',
      'toolbar.filters': 'Фильтры',
      'toolbar.orientation': 'Положение',
      'toolbar.crop': 'Обрезать',
      'toolbar.resize': 'Resize',
      'toolbar.watermark': 'Watermark',
      'adjust.brightness': 'Brightness',
      'adjust.contrast': 'Contrast',
      'adjust.exposure': 'Exposure',
      'adjust.saturation': 'Saturation',
      'orientation.rotate_l': 'Повернуть влево',
      'orientation.rotate_r': 'Повернуть вправо',
      'orientation.flip_h': 'Перевернуть по горизонтали',
      'orientation.flip_v': 'Перевернуть по вертикали',
      'pre_resize.title': 'Вы хотите уменьшить разрешение перед редактированием изображения?',
      'pre_resize.keep_original_resolution': 'Сохранить оригинальное разрешение',
      'pre_resize.resize_n_continue': 'Изменить размер и продолжить',
      'footer.reset': 'Сбросить',
      'footer.undo': 'Вперед',
      'footer.redo': 'Назад',
      'spinner.label': 'Обработка...',
      'warning.too_big_resolution':
        'Разрешение изображения слишком велико для Интернета. Это может вызвать проблемы с производительностью редактора изображений.',
      'common.width': 'ширина',
      'common.height': 'высота',
      'common.custom': 'custom',
      'common.original': 'original',
      'common.square': 'квадрат',
      'common.opacity': 'непрозрачность',
      'common.apply_watermark': 'Применить watermark'
    }
  }
}

export default config
