import { Button, Form, Input, Select } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';
import { createModal } from 'react-modal-promise';

import { InjectedModalProps, IRedirect, IRedirectType } from '~common';
import { DeleteButton, ItemWithLabel, Modal } from '~components';
import { redirectResponseCodes } from '~constants';
import s from '~containers/Subcategory/SubcategoriesModal/styles.scss';
import { useRedirectsStore, useUserStore } from '~hooks';

const { Option } = Select;

const CREATE_TITLE = 'Создание нового редиректа';
const UPDATE_TITLE = 'Редактирование редиректа';

const RedirectsModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;

  const {
    editableRedirect,
    resetEditableRedirect,
    createRedirect,
    isActionModal,
    redirectTypes,
    updateRedirectById,
    deleteRedirectById,
  } = useRedirectsStore();
  const { checkPermissions } = useUserStore();

  const [form] = Form.useForm<IRedirect>();

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleClose = useCallback(() => {
    resetEditableRedirect();
    form.resetFields();
    onClose(true);
  }, [form]);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const redirect = {
        data: {
          ...values,
        },
      };
      if (editableRedirect) {
        updateRedirectById(redirect).then(handleClose);
      } else {
        createRedirect(redirect).then(handleClose);
      }
    });
  }, [editableRedirect]);

  const handleDelete = () => {
    deleteRedirectById().then(handleClose);
  };

  const ModalTitle = editableRedirect ? UPDATE_TITLE : CREATE_TITLE;

  return (
    <Modal destroyOnClose isVisible={isOpen} onClose={handleClose} title={ModalTitle}>
      <Form form={form}>
        <ItemWithLabel label="Исходная ссылка">
          <Form.Item
            name="fromLink"
            initialValue={get(editableRedirect, 'fromLink')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите исходную ссылку" />
          </Form.Item>
        </ItemWithLabel>
        <ItemWithLabel label="Целевая ссылка">
          <Form.Item
            name="toLink"
            initialValue={get(editableRedirect, 'toLink')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите целевую ссылку" />
          </Form.Item>
        </ItemWithLabel>
        <ItemWithLabel label="Описание">
          <Form.Item name="description" initialValue={get(editableRedirect, 'description')}>
            <Input placeholder="Введите описание редиректа" />
          </Form.Item>
        </ItemWithLabel>
        <ItemWithLabel label="Тип перенаправления">
          <Form.Item
            name="type"
            initialValue={get(editableRedirect, 'type')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Select>
              {redirectTypes.map((redirectType: IRedirectType) => {
                return <Option value={redirectType.id}>{redirectType.ru}</Option>;
              })}
            </Select>
          </Form.Item>
        </ItemWithLabel>
        <ItemWithLabel label="Статус перенаправления">
          <Form.Item
            name="status"
            initialValue={get(editableRedirect, 'status')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Select>
              {redirectResponseCodes.map(({ id, ru }) => {
                return <Option value={id}>{ru}</Option>;
              })}
            </Select>
          </Form.Item>
        </ItemWithLabel>
        <div className={s.modalControls}>
          <Button
            onClick={handleSubmit}
            type="primary"
            loading={isActionModal}
            disabled={!checkPermissions('redirects.update')}
          >
            Сохранить изменения
          </Button>
          {editableRedirect && (
            <div className={s.deleteBtnWrapper}>
              <DeleteButton
                text="Удалить редирект"
                onDelete={handleDelete}
                disabled={!checkPermissions('redirects.update')}
              />
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
});

export const openRedirectsModal = createModal(RedirectsModal);
