import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';
import { createModal } from 'react-modal-promise';

import { InjectedModalProps } from '~common';
import { Modal } from '~components';
import { usePublicationStore } from '~hooks';

import s from './styles.scss';

interface IValidationError {
  blockOrder: number;
  blockOrderString: string;
  errors: [];
}

const ValidationModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;

  const {
    validationResult: {
      blockValidationErrors: { globalError, validationErrors: blockErrors = [] } = {},
      fieldsValidationErrors: { validationErrors: fieldErrors = [] } = {},
    } = {},
  } = usePublicationStore();

  const onClose = useCallback((result: any = false) => {
    close && close(result);
  }, []);

  return (
    <Modal
      destroyOnClose
      width={590}
      onClose={onClose}
      isVisible={isOpen}
      title="Валидация содержимого статьи"
    >
      {fieldErrors.length > 0 && (
        <div className={s.errorSectionHeader}>В следующих полях обнаружены ошибки: </div>
      )}
      {fieldErrors.map((elem: IValidationError) => {
        return (
          elem.errors.length > 0 && (
            <>
              <div>Поле {elem.blockOrderString}</div>
              <ul className={s.errorList}>
                {elem.errors.map(error => (
                  <li>{error}</li>
                ))}
              </ul>
            </>
          )
        );
      })}
      {globalError}
      {!globalError && blockErrors.length > 0 && (
        <>
          <div className={s.errorSectionHeader}>В следующих блоках обнаружены ошибки: </div>
          {_.sortBy(blockErrors, 'blockOrder').map((elem: IValidationError) => {
            return (
              <>
                <div>Блок {elem.blockOrderString}</div>
                <ul className={s.errorList}>
                  {elem.errors.map(error => (
                    <li>{error}</li>
                  ))}
                </ul>
              </>
            );
          })}
        </>
      )}
    </Modal>
  );
});

export const openValidationModal = createModal(ValidationModal);
