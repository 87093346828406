import { object, string } from 'yup';
import { EMPTY_STRING } from '~constants/index';
import { transformEmptyString } from '~schemas/transformations/transfomEmptyString';
import { castBySchema } from '~utils/castBySchema';

/** @type object */
export const titleBlockSchema = object()
  .default(undefined)
  .shape({
    title: string()
      .default(EMPTY_STRING)
      .required()
      .min(1),
  });
/** @type object */
export const subTitleBlockSchema = object()
  .default(undefined)
  .shape({
    subtitle: string()
      .defined()
      .transform(transformEmptyString),
  });
/** @type object */
export const clickbaitBlockSchema = object()
  .default(undefined)
  .shape({
    clickbait: string()
      .defined()
      .transform(transformEmptyString),
  });

export const castByTitleBlockSchema = castBySchema(titleBlockSchema, { stripUnknown: true });
export const castBySubTitleBlockSchema = castBySchema(subTitleBlockSchema, { stripUnknown: true });
export const castByClickbaitBlockSchema = castBySchema(clickbaitBlockSchema, { stripUnknown: true });