import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import CommentItem from '~components/PublicationComments/CommentItem'
import ContentSpinner from '~components/ContentSpinner'
import EmptyContent from '~components/EmptyContent'

import styles from './styles.scss'

@inject(stores => {
  const {
    comments,
    isFirstLoading,
    isEmptyComments,
    deleteComment,
    blockUser
  } = stores.PublicationCommentsStore

  return {
    comments,
    isFirstLoading,
    isEmptyComments,
    deleteComment,
    blockUser
  }
})
@observer
class CommentList extends Component {
  handleDelete = data => {
    const { deleteComment } = this.props

    return deleteComment(data)
  }

  handleBlockUser = comment => {
    const { blockUser } = this.props

    return blockUser(comment)
  }

  render() {
    const { comments, isFirstLoading, isEmptyComments } = this.props

    if (isFirstLoading) {
      return <ContentSpinner />
    }

    if (isEmptyComments) return <EmptyContent text="Нет комментариев" />

    return (
      <div className={styles.root}>
        {comments.map(comment => (
          <CommentItem
            key={comment._id}
            onBlockUser={this.handleBlockUser}
            onDelete={this.handleDelete}
            comment={comment}
          />
        ))}
      </div>
    )
  }
}

export default CommentList

CommentList.propTypes = {
  blockUser: PropTypes.any,
  comments: PropTypes.array,
  deleteComment: PropTypes.func,
  isEmptyComments: PropTypes.bool,
  isFirstLoading: PropTypes.bool
}
