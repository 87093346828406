import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import GridWrapper from '~components/GridWrapper';
import AddNew from '~components/AddNew';
import ContentSpinner from '~components/ContentSpinner';
import EmailsItem from '~components/Emails/EmailsItem';

@inject('EmailsStore', 'UserStore')
@observer
class EmailsList extends Component {
  componentDidMount() {
    const {
      EmailsStore: { fetchEmails, fetchDictionaries },
    } = this.props;

    fetchEmails();
    fetchDictionaries();
  }

  render() {
    const {
      EmailsStore: { dictionaries, emails, setShowModal, isFirstLoading },
      UserStore: { checkPermissions },
    } = this.props;

    if (isFirstLoading) {
      return <ContentSpinner />;
    }

    return (
      <GridWrapper gridSize={3} maxItemWidth={285}>
        {emails &&
          emails.map(email => (
            <EmailsItem data={email} dictionaries={dictionaries} key={email.id} />
          ))}
        {checkPermissions('emails.update') && <AddNew onClick={setShowModal} />}
      </GridWrapper>
    );
  }
}

EmailsList.propTypes = {
  EmailsStore: PropTypes.any,
};

export default EmailsList;
