import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useCallback } from 'react';

import CloseIcon from '~assets/icons/close.svg';
import { FilterItem } from '~common';

import styles from './styles.scss';

interface Props {
  checked?: boolean;
  item: FilterItem;

  onChange(name: string, value: any): void;

  onRemove(): void;
}

export const CheckBox: React.FC<Props> = props => {
  const { item, checked, onRemove, onChange } = props;
  const { label, name } = item;

  const handleChange = useCallback(
    (event: CheckboxChangeEvent) => {
      onChange(name, event.target.checked);
    },
    [name, onChange],
  );

  return (
    <div className={styles.selectItem}>
      <Checkbox onChange={handleChange} checked={checked}>
        {label}
      </Checkbox>
      <span onClick={onRemove} className={styles.closeIcon}>
        <CloseIcon width={12} fill="#9A9A9A" />
      </span>{' '}
    </div>
  );
};
