import { EditOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Radio, Row, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { ComponentType, FC } from 'react';

import { BlockType, IBlock, Maybe } from '~common';
import * as Blocks from '~components/Blocks';
import { EMPTY_OBJECT, resourceTypes } from '~constants';
import { FULL, HALF, LockedBy } from '~containers/Draft';
import { usePublicationStore } from '~hooks';
import { editorBlockComponents } from '~pages/EditorPage/components/EditorPublication/EditorPublication';
import { editorBlockComponents as quizEditorBlockComponents } from '~pages/EditorPage/components/EditorQuiz/EditorQuiz';
import Block from '~store/PublicationStore/BlockModel';
import { getBlockName, getTimeByString, isString } from '~utils';

import styles from './styles.scss';

const { Text, Title, Paragraph } = Typography;

const availableEditorModules = (resourceType: string) => {
  let blockComponents = editorBlockComponents;

  if (resourceType === resourceTypes.quiz) {
    blockComponents = quizEditorBlockComponents;
  }

  return blockComponents.reduce((availableModules, blockComponent) => {
    if (isString(blockComponent.component)) {
      const blockName = getBlockName(blockComponent.component as string);

      return {
        ...availableModules,
        [blockComponent.type]: Blocks[blockName],
      };
    }

    return {
      ...availableModules,
      [blockComponent.type]: blockComponent.component as ComponentType<IBlock>,
    };
  }, {});
};

interface IDiffBlockProps {
  leftBlock: Block;
  resourceType: string;
  rightBlock: Maybe<Block>;
}

export const DiffBlock: FC<IDiffBlockProps> = observer(props => {
  const { leftBlock, rightBlock, resourceType } = props;
  const { isLockedByOthers } = usePublicationStore();

  const LeftBlock: FC<IBlock> = availableEditorModules(resourceType)[
    leftBlock.type || BlockType.TextBlock
  ];
  const RightBlock: FC<IBlock> = availableEditorModules(resourceType)[
    rightBlock?.type || BlockType.TextBlock
  ];

  const isNotModifiedByOther = leftBlock.hash === rightBlock?.hash;
  const isModifiedByOther = !isNotModifiedByOther;
  const isNotLockedByOther = !isLockedByOthers({ lockedBy: rightBlock?.lockedBy });

  return (
    <>
      <Row>
        <Col span={FULL}>
          <Divider>
            <Title level={4}>{`Блок №${leftBlock.order}`}</Title>
          </Divider>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={HALF}>
          <Text type="secondary">Дата изменения: {getTimeByString(leftBlock?.updatedAt)}</Text>
        </Col>
        <Col span={HALF}>
          <Text type="secondary">Дата изменения: {getTimeByString(rightBlock?.updatedAt)}</Text>
          {isNotModifiedByOther && <LockedBy lockedBy={rightBlock?.lockedBy} />}
          {isModifiedByOther && (
            <Paragraph type="warning">
              {`Содержимое изменено `}
              <EditOutlined />
            </Paragraph>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={HALF}>
          <LeftBlock
            {...leftBlock.data}
            data={leftBlock.data}
            blockProps={{
              classNames: {
                head: styles.draftPadding,
                content: styles.draftPadding,
              },
            }}
            id={leftBlock.id}
            type={leftBlock.type as BlockType}
          />
        </Col>
        <Col span={HALF}>
          <RightBlock
            {...(rightBlock?.data || EMPTY_OBJECT)}
            data={rightBlock?.data || EMPTY_OBJECT}
            blockProps={{
              readOnly: true,
              classNames: {
                head: styles.draftPadding,
                content: styles.draftPadding,
              },
            }}
            id={rightBlock?.id}
            type={rightBlock?.type as BlockType}
          />
        </Col>
      </Row>
      {isNotModifiedByOther && isNotLockedByOther && (
        <Row gutter={16} style={{ marginTop: '16px' }}>
          <Col span={HALF}>
            <Form.Item name={['blocks', leftBlock.id]} initialValue="my">
              <Radio.Group
                options={[
                  {
                    label: 'Оставить свою версию',
                    value: 'my',
                  },
                ]}
                optionType="button"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={['blocks', leftBlock.id]}>
              <Radio.Group
                options={[
                  {
                    label: 'Оставить версию на сервере',
                    value: 'server',
                  },
                ]}
                optionType="button"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
});
