export default (name = '', limit = 25, file = false) => {
  if (name.length < limit) return name;

  if (file) {
    const arr = name.split('.');
    return `${arr[0].slice(0, limit - 5)}...${arr[1]}`;
  }

  return `${name.slice(0, limit)}...`;
};
