import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toJS } from 'mobx'
import { observer, inject } from 'mobx-react'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'
import s from './styles.scss'

@inject('SymbolsStore')
@observer
class SymbolsItem extends Component {
  handleClick = () => {
    const {
      SymbolsStore: { setEditableSymbol },
      title,
      value
    } = this.props

    setEditableSymbol({
      title,
      value
    })
  }

  render() {
    const { title, value, dictionaries } = this.props
    const pageType = toJS(dictionaries).find(({ id }) => id === title)

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{pageType && pageType.ru}</h2>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Количество симовлов:</h3>
            <p className={s.cardItemValue}>{value}</p>
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

SymbolsItem.propTypes = {
  SymbolsStore: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.number,
  dictionaries: PropTypes.array
}

export default SymbolsItem
