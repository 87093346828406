import { observer } from 'mobx-react';
import React from 'react';

import { ISelectProps, Select } from '~components';
import { useScheduleStore } from '~hooks';

export const EventTypesSelect: React.FC<ISelectProps<object>> = observer(props => {
  const { fetchEventsTypesBySearch, eventTypesSearches } = useScheduleStore();

  return (
    <Select
      {...props}
      fetch={fetchEventsTypesBySearch}
      options={eventTypesSearches}
      keyField="_id"
      labelField="title"
    />
  );
});
