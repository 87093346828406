import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'

import styles from './styles.scss'

import Pencil from '~assets/icons/pencil.svg'

@inject(stores => {
  const { setEditableFlow } = stores.FlowsStore

  return {
    setEditableFlow
  }
})
@observer
class FlowItem extends Component {
  handleClickItem = () => {
    const { flow, setEditableFlow } = this.props

    setEditableFlow(flow)
  }

  render() {
    const { flow } = this.props
    const { title, index } = flow

    return (
      <WhiteBlockWithShadow>
        <div className={styles.root} onClick={this.handleClickItem}>
          <div className={styles.ico}>
            <Pencil fill="#D3D3D3" />
          </div>

          <div className={styles.title}>{title}</div>

          <div className={styles.id}>ID {index}</div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

export default FlowItem

FlowItem.propTypes = {
  setEditableFlow: PropTypes.func,
  flow: PropTypes.object
}
