import '@ant-design/compatible/assets/index.css';

import { Button, Form, Input, Switch } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { IAuthor } from '~common';
import { AvatarUpload, DeleteButton, ItemWithLabel, Modal } from '~components';
import SelectRender from '~components/Publications/Filter/SelectRender';
import { useAuthorsStore, useUserStore } from '~hooks';

import styles from './styles.scss';

const CREATE_TITLE = 'Создание нового автора';
const UPDATE_TITLE = 'Редактирование автора';

const { TextArea } = Input;

const AuthorModal: FC = observer(() => {
  const [form] = Form.useForm<IAuthor>();
  const { setFieldsValue, resetFields } = form || {};
  const {
    isShowModalEdit,
    setHideModalEdit,
    editableAuthor,
    resetEditableAuthor,
    isActionModal,
    deleteAuthor,
    updateAuthor,
    createAuthor,
  } = useAuthorsStore();
  const { checkPermissions } = useUserStore();

  const [published, setPublished] = useState(editableAuthor?.published || false);

  useEffect(() => {
    setPublished(editableAuthor?.published);
  }, [editableAuthor?.published]);

  const handleSubmit = async data => {
    const author = {
      data: {
        ...data,
        department: data.department?._id,
        avatar: data.avatar?._id || null,
      },
    };

    const action = editableAuthor ? updateAuthor : createAuthor;

    action && action(author);
  };

  const handleClearAvatar = () => {
    setFieldsValue &&
      setFieldsValue({
        avatar: {
          url: '',
          _id: '',
        },
      });
  };

  const normFile = useCallback(e => {
    const file = get(e, 'file.response.data', null);
    const status = get(e, 'file.status', null);

    return {
      ...file,
      status,
    };
  }, []);

  const modalTitle = editableAuthor ? UPDATE_TITLE : CREATE_TITLE;

  return (
    <Modal
      destroyOnClose
      width={770}
      onClose={setHideModalEdit}
      isVisible={isShowModalEdit}
      title={modalTitle}
      afterClose={() => {
        resetFields && resetFields(['avatar']);
        resetEditableAuthor && resetEditableAuthor();
      }}
    >
      <div className={styles.root}>
        <Form onFinish={handleSubmit} initialValues={editableAuthor}>
          <div className={styles.forms}>
            <div className={styles.formItemWrapper}>
              <Form.Item name="avatar" normalize={normFile}>
                <AvatarUpload onClearAvatar={handleClearAvatar} />
              </Form.Item>
            </div>

            <div className={styles.formItemWrapper} />

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Имя и фамилия автора">
                <Form.Item name="name" rules={[{ required: true, message: 'Обязательное поле!' }]}>
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="E-mail">
                <Form.Item name="email">
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Должность">
                <Form.Item name="position">
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Выбрать отдел">
                <Form.Item
                  name={['department', '_id']}
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                  <SelectRender
                    item={{
                      label: 'Выбрать отдел',
                      type: 'select',
                      name: 'filter.department',
                    }}
                  />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Вконтакте">
                <Form.Item name={['socials', 'vk']}>
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Фейсбук">
                <Form.Item name={['socials', 'facebook']}>
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Твитер">
                <Form.Item name={['socials', 'twitter']}>
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Телеграм">
                <Form.Item name={['socials', 'telegram']}>
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Инстаграм">
                <Form.Item name={['socials', 'instagram']}>
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>

            <div className={styles.formItemWrapper}>
              <ItemWithLabel label="Одноклассники">
                <Form.Item name={['socials', 'ok']}>
                  <Input />
                </Form.Item>
              </ItemWithLabel>
            </div>
          </div>

          <ItemWithLabel label="Биография автора">
            <Form.Item name="bio">
              <TextArea />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Краткая биография автора">
            <Form.Item
              name="shortBio"
              rules={[{ max: 200, message: 'Максимальная длина 200 символов' }]}
            >
              <TextArea />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Опубликован на сайте">
            <Form.Item name="published">
              <Switch checked={published} onChange={setPublished} />
            </Form.Item>
          </ItemWithLabel>

          <div className={styles.modalControls}>
            <Button
              loading={isActionModal}
              disabled={
                isActionModal ||
                !checkPermissions(`authors.${editableAuthor ? 'update' : 'create'}`)
              }
              htmlType="submit"
              type="primary"
            >
              Сохранить изменения
            </Button>

            {editableAuthor && (
              <div className={styles.deleteBtnWrapper}>
                <DeleteButton
                  text="Удалить автора"
                  onDelete={deleteAuthor}
                  disabled={!checkPermissions('authors.delete')}
                />
              </div>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
});

export default AuthorModal;
