import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { ITag } from '~common';
import { ItemWithLabel } from '~components';
import { resourceTypes } from '~constants';
import { usePublicationStore, useTagsStore, useUserStore } from '~hooks';
import { debounce, getAuthorityByResourceType, getUniqueListBy } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const TagsSelect = observer(() => {
  const { tags, set, resourceType } = usePublicationStore();
  const { checkPermissions } = useUserStore();
  const { fetchTagsBySearch, tagsSearches } = useTagsStore();

  const selectedOptions: LabeledValue[] = tags.map((tag: ITag) => ({
    value: tag._id,
    label: tag.title,
  }));

  const options: LabeledValue[] = getUniqueListBy<LabeledValue>(
    [...tags, ...tagsSearches].map((tag: ITag) => ({
      value: tag._id,
      label: tag.title,
    })),
    'value',
  );

  const onChange = (tags: LabeledValue[] = []) => {
    set({ name: 'tags', value: tags.map(({ key, label }) => ({ _id: key, title: label })) });
  };

  const onSearch = useMemo(
    () =>
      debounce(term => {
        fetchTagsBySearch(term, 'filter.blackListed=false');
      }, 1000),
    [],
  );

  useEffect(() => {
    fetchTagsBySearch('', 'filter.blackListed=false');
  }, []);

  return (
    <ItemWithLabel label="Теги">
      <Select
        mode="multiple"
        maxTagCount={1}
        style={inputStyle}
        placeholder="Поиск тегов"
        value={selectedOptions}
        options={options}
        onChange={onChange}
        onSearch={onSearch}
        notFoundContent={null}
        filterOption={false}
        showSearch
        labelInValue
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
