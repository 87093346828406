import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';

const API_URL_REGIONS = '/regions';

const getData = ({ data }) => data;

export class RegionsTransportLayer {
  getRegions = (defaultFilter: Record<string, any> | string = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({
      url: API_URL_REGIONS,
      defaultFilter,
    });

    return API.get(promiseUrl).then(getData);
  };

  createRegion = region => {
    return API.post(`${API_URL_REGIONS}`, { ...region }).then(getData);
  };

  updateRegionById = (id, region) => {
    return API.patch(`${API_URL_REGIONS}/${id}`, {
      data: { ...region },
    }).then(getData);
  };

  deleteRegionById = id => {
    return API.delete(`${API_URL_REGIONS}/${id}`).then(getData);
  };

  getRegionsBySearch = term => {
    let url = API_URL_REGIONS;

    if (term) {
      url = `${API_URL_REGIONS}?search=${term}`;
    }

    return API.get(`${url}`).then(getData);
  };
}
