import axios from 'axios';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { always, cond, equals } from 'ramda';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';

import { DropType, IFile } from '~common';
import { useFlagsStore } from '~hooks';
import { PublicationContext } from '~pages/EditorPage';
import { FileTransportLayer } from '~services';
import { isFileAcceptable, isNotNil, showErrorNotification } from '~utils';

import { EditorContext } from '../Editor';
import styles from './styles.styl';

const maxImageSize = process.env.MAX_IMG_SIZE || '5242880';

interface IProps {
  accept?: string | string[];
  files?: IFile[];
  noDragEventsBubbling?: boolean;
  onChange?(response: any): void;
  onProgressChange?(progress: number): void;
  status?: string | number | null;
  type?: DropType;
  uploadData?: object;
  uploadPlaceholder?: string;
  uploadUrl?: string;
}

const DropZone: React.FC<IProps> = observer(props => {
  const { fileUploadWorker } = useFlagsStore();
  const {
    noDragEventsBubbling = true,
    type = DropType.Photo,
    accept = 'image/jpeg, image/png, image/gif, video/*',
    onChange,
    files: defaultFiles = [],
    uploadPlaceholder: uploadPlaceholderDefault = '',
    uploadUrl: uploadUrlDefault = '',
    onProgressChange,
    uploadData = {},
  } = props;

  const { uploadUrl = uploadUrlDefault, uploadPlaceholder = uploadPlaceholderDefault } = useContext(
    EditorContext,
  );
  const { postId } = useContext(PublicationContext);

  const getPlaceholder = useMemo(
    () =>
      cond<any, IFile>([
        [
          equals(DropType.Photo),
          always({
            name: 'placeholder',
            preview: uploadPlaceholder,
            mimetype: 'image/jpeg',
          }),
        ],
        [
          equals(DropType.Video),
          always({
            name: 'placeholder',
            preview: require('~assets/uploadPlaceholder.jpg'),
            mimetype: 'image/jpeg',
          }),
        ],
        [
          equals(DropType.Audio),
          always({
            name: 'placeholder',
            url: '',
            mimetype: 'audio/*',
          }),
        ],
      ]),
    [uploadPlaceholder],
  );

  const placeholders: IFile[] = useMemo(() => [getPlaceholder(type)], [getPlaceholder, type]);
  const [files, setFiles] = useState(defaultFiles);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<string | number | null | undefined>(props.status);

  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  const onUploadProgress = (progressEvent: ProgressEvent) =>
    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));

  useEffect(() => {
    onProgressChange && onProgressChange(progress);
  }, [onProgressChange, progress]);

  const checkStatus = useCallback(
    async (jobId: number) => {
      const { finishedOn, failedReason, progress, returnvalue: response } =
        (await FileTransportLayer.checkStatus(jobId)) || {};

      if (failedReason) {
        console.error(failedReason);
        setStatus(null);
        showErrorNotification('Ошибка загрузки файла', failedReason);
      } else if (finishedOn) {
        setStatus(null);
        onChange && onChange(response);
      } else {
        setStatus(progress || '');
        await checkStatus(jobId);
      }
    },
    [onChange],
  );

  const errorMessage = `Допустимый формат: ${accept}`;
  // type === DropType.Photo
  //   ? `Допустимый формат: ${accept}. Максимальный вес ${parseInt(maxImageSize, 10) /
  //       1024 /
  //       1024} мегабайт.`
  //   :
  //   `Допустимый формат: ${accept}`;

  const onDrop = useCallback(
    async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        showErrorNotification('Неверный формат', errorMessage);
        return;
      }

      const type = acceptedFiles[0].type.split('/')[0];

      if (type === 'image') {
        if (!(await isFileAcceptable(acceptedFiles[0]))) return;
      }

      if (accept?.indexOf(type) === -1) return;

      const data = new FormData();

      Object.keys(uploadData).forEach(key => {
        data.append(key, uploadData[key]);
      });

      data.append('file', acceptedFiles[0]);
      if (postId) {
        data.append('post', postId);
      }
      const token = localStorage.getItem('token') || '';

      axios
        .post(uploadUrl, data, {
          headers: { Authorization: token ? `Bearer ${JSON.parse(token)}` : '' },
          withCredentials: true,
          onUploadProgress,
        })
        .then(({ data }) => {
          setProgress(0);
          return data;
        })
        .then(({ data }) => {
          if (fileUploadWorker) {
            setStatus(data.progress);
            checkStatus(data.id);
          } else {
            onChange && onChange(data);
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          setProgress(0);
          showErrorNotification('Ошибка загрузки файла', e);
        });
    },
    [accept, uploadData, uploadUrl, fileUploadWorker, checkStatus, onChange, postId],
  );

  const dropzoneOptions: DropzoneOptions = {
    accept,
    onDrop,
    noDragEventsBubbling,
  };

  // if (type === DropType.Photo) {
  //   dropzoneOptions.maxSize = parseInt(maxImageSize, 10);
  // }

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  const getItem = useMemo(
    () =>
      cond<IFile, JSX.Element>([
        [
          always(equals(DropType.Video, type)),
          (file: IFile) =>
            file && file.url ? (
              <ReactPlayer url={file.url} controls />
            ) : (
              <div className={styles.videoPlaceholder}>Загрузите видео</div>
            ),
        ],
        [
          always(equals(DropType.Audio, type)),
          (file: IFile) =>
            file.url ? (
              <audio src={file.url} controls />
            ) : (
              <div className={styles.audioPlaceholder}>Загрузите аудио</div>
            ),
        ],
        [
          always(equals(DropType.Photo, type)),
          (file: IFile) => <img alt={file.filename || file.url} src={file.preview || file.url} />,
        ],
      ]),
    [type],
  );

  const items = useMemo(
    () =>
      (files?.filter(({ url, preview }) => url || preview) || []).length > 0 ? files : placeholders,
    [files, placeholders],
  );

  // TODO: refactor
  // componentDidMount
  useEffect(() => {
    setFiles(defaultFiles);
  }, [defaultFiles]);

  return (
    <div {...getRootProps()} className={styles.wrapper}>
      {progress > 0 && <div className={styles.progress}>{progress}%</div>}
      {isNotNil(status) && status !== 0 && <div className={styles.progress}>{status}</div>}
      <input {...getInputProps()} />

      <div className={cx(styles.content, isDragActive && styles.active)}>
        {items.map((file, index) => (
          <div key={`${file.url}-${index}`}>{getItem(file)}</div>
        ))}
      </div>
    </div>
  );
});

export default DropZone;
