import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles.scss'

@inject('PlaylistsStore', 'FilterParamsByListStore')
@observer
class PlaylistsFilterItem extends Component {
  handleClick = () => {
    const { FilterParamsByListStore, PlaylistsStore, filter } = this.props

    FilterParamsByListStore.setFilterValue('filter.visible', filter.value)
    PlaylistsStore.setCurrentFilter(filter.value)
    PlaylistsStore.fetchPlaylists()
  }

  render() {
    const {
      PlaylistsStore: { currentFilter },
      filter
    } = this.props

    return (
      <li className={styles.navigationItem}>
        <span
          className={cn(styles.navigationItemText, {
            [styles.navigationItemTextActive]: filter.value === currentFilter
          })}
          onClick={this.handleClick}
        >
          {filter.text}
        </span>
      </li>
    )
  }
}

PlaylistsFilterItem.propTypes = {
  PlaylistsStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  FetchPaginationStore: PropTypes.any,
  filter: PropTypes.object
}

export default PlaylistsFilterItem
