import { action, computed, observable, ObservableMap, runInAction } from 'mobx';

import { IRedirect, Maybe } from '~common';
import { EMPTY_STRING } from '~constants';
import { openRedirectsModal } from '~containers/Redirects';
import { RedirectsTransportLayer } from '~services/redirectsApi';
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotification, showErrorNotificationInPromise } from '~utils';

export class RedirectsStore {
  @observable
  redirects: ObservableMap<string, IRedirect> = observable.map([]);

  @observable redirectSearches: Array<IRedirect> = [];

  @observable redirectTypes = [];

  @observable editableRedirect: Maybe<IRedirect> = null;

  @observable isActionModal = false;

  @observable allItemsCount = 0;

  @observable isFetching = false;

  apiLayer = new RedirectsTransportLayer();

  @action
  fetchDictionaries = () => {
    return this.apiLayer.getDictionaries().then(({ redirectTypes }) => {
      this.redirectTypes = redirectTypes;
    });
  };

  @action
  setEditableRedirect = async value => {
    this.editableRedirect = { ...value };
    await openRedirectsModal();
  };

  @action
  setRedirects = (redirects: IRedirect[] = []) => {
    this.redirects.replace(
      new Map(
        redirects.map(redirect => {
          return [redirect._id, redirect];
        }),
      ),
    );
  };

  @action
  getRedirects = (defaultFilter = EMPTY_STRING) => {
    const { offset, setPageOffset } = FetchPaginationStore;
    const isNewFetch = offset === 0;

    if (isNewFetch) {
      this.setIsFetching(true);
      this.setRedirects([]);
      this.setRedirectsItemsCount(0);
    }

    return this.apiLayer
      .getRedirects(defaultFilter)
      .then(({ data: redirects = [], meta }) => {
        if (isNewFetch) {
          this.setRedirects(redirects);
        } else {
          this.setRedirects([...this.redirects.values(), ...redirects]);
        }
        this.setRedirectsItemsCount(meta.count);
        setPageOffset(offset + 1);
        return Promise.resolve(redirects);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки редиректов', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  createRedirect = redirect => {
    const { resetPaginationParams } = FetchPaginationStore;

    this.isActionModal = true;

    return this.apiLayer
      .createRedirect(redirect)
      .then(({ data }) => {
        runInAction(() => {
          this.isActionModal = false;
          resetPaginationParams();
          this.getRedirects();
        });
        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания редиректа',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  updateRedirectById = redirect => {
    const { resetPaginationParams } = FetchPaginationStore;

    const id = this.editableRedirect?._id || null;

    this.isActionModal = true;

    const { data } = redirect;

    return this.apiLayer
      .updateRedirectById(id, data)
      .then(({ data }) => {
        runInAction(() => {
          this.isActionModal = false;
          resetPaginationParams();
          this.getRedirects();
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка обновления редиректа',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  deleteRedirectById = () => {
    const { resetPaginationParams } = FetchPaginationStore;

    const id = this.editableRedirect?._id || null;

    this.isActionModal = true;
    return this.apiLayer
      .deleteRedirectById(id)
      .then(() => {
        runInAction(() => {
          this.isActionModal = false;
          this.resetEditableRedirect();
          resetPaginationParams();
          this.getRedirects();
          return Promise.resolve(true);
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка удаления редиректа',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  setRedirectsItemsCount = (redirectsItemsCount: number) => {
    this.allItemsCount = redirectsItemsCount;
  };

  @action
  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };

  @action
  resetEditableRedirect = () => {
    this.editableRedirect = null;
  };

  @computed
  get redirectsItems(): IRedirect[] {
    return [...this.redirects.values()];
  }

  @computed
  get redirectsFetchedCount() {
    return this.redirects.size;
  }

  @computed
  get isEmptyRedirects(): boolean {
    return this.redirectsFetchedCount === 0;
  }

  @computed
  get hasMoreRedirectItemsToFetch(): boolean {
    return this.redirectsFetchedCount !== this.allItemsCount;
  }
}

export default new RedirectsStore();
