import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';

const getData = ({ data }) => data;

const HEADER_PREVIEWS_URL = '/headerPreviews';

export class HeaderPreviewsTransportLayer {
  getHeaderPreviews = (defaultFilter = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: HEADER_PREVIEWS_URL, defaultFilter });

    return API.get(promiseUrl).then(getData);
  };

  getHeaderPreviewsBySearch = term => {
    let url = HEADER_PREVIEWS_URL;

    if (term) {
      url = `${HEADER_PREVIEWS_URL}?search=${term}`;
    }

    return API.get(url).then(getData);
  };

  createHeaderPreview = headerPreview => {
    return API.post(`${HEADER_PREVIEWS_URL}`, {
      ...headerPreview,
    }).then(getData);
  };

  updateHeaderPreviewById = (id, headerPreview) => {
    return API.patch(`${HEADER_PREVIEWS_URL}/${id}`, {
      data: { ...headerPreview },
    }).then(getData);
  };

  deleteHeaderPreviewById = id => {
    return API.delete(`${HEADER_PREVIEWS_URL}/${id}`).then(getData);
  };
}
