import { get } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';

import { IPriority } from '~common';
import { ISelectProps, Select } from '~components';
import { useEmployeesStore } from '~hooks';

export const EmployeePositionsSelect: React.FC<ISelectProps<IPriority>> = observer(props => {
  const { dictionariesEmployees } = useEmployeesStore();

  return (
    <Select
      {...props}
      options={get(dictionariesEmployees, 'employeePositions', [])}
      keyField="id"
      labelField="ru"
    />
  );
});
