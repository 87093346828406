import React from 'react';
import PropTypes from 'prop-types';
import {
  AppstoreOutlined,
  DesktopOutlined,
  MobileOutlined,
  TabletOutlined,
} from '@ant-design/icons';
import { Radio } from 'antd';

import styles from './index.scss';

// TODO get from env
const ORIGIN = process.env.PUBLIC_ORIGIN;

const PreviewTypes = [
  {
    type: 'mobile',
    width: 375,
    height: 812,
    icon: <MobileOutlined style={{ fontSize: '18px' }} />,
  },
  {
    type: 'tablet',
    width: 768,
    height: 1024,
    icon: <TabletOutlined style={{ fontSize: '18px' }} />,
  },
  {
    type: 'desktop',
    width: 1440,
    height: 768,
    icon: <DesktopOutlined style={{ fontSize: '18px' }} />,
  },
  {
    type: 'main',
    width: 1440,
    height: 768,
    icon: <AppstoreOutlined style={{ fontSize: '18px' }} />,
  },
];

class PagePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentType: PreviewTypes[2].type,
    };
  }

  onChangeType = event => {
    this.setState({
      currentType: event.target.value,
    });
  };

  render() {
    const currentTypeData = PreviewTypes.find(e => e.type === this.state.currentType);
    const url =
      this.state.currentType === 'main'
        ? `${ORIGIN}/preview/${this.props.postId}`
        : this.props.url;

    if (!this.props.visible) return null;

    return (
      <section className={styles.root}>
        <header className={styles.header}>
          <Radio.Group
            onChange={this.onChangeType}
            value={this.state.currentType}
            buttonStyle="solid"
          >
            {PreviewTypes.map(data => (
              <Radio.Button key={data.type} value={data.type} className={styles.action}>
                {data.icon}
              </Radio.Button>
            ))}
          </Radio.Group>
        </header>
        <div
          className={styles.iframeWrapper}
          style={{
            transform: `scale(${(window.innerHeight - 140) / 1024})`,
          }}
        >
          <iframe src={url} width={currentTypeData.width} height={currentTypeData.height} />
        </div>
        <div className={styles.background} onClick={this.props.onClose} />
      </section>
    );
  }
}

PagePreview.propTypes = {
  url: PropTypes.string,
  postId: PropTypes.string,
  visible: PropTypes.bool,

  onClose: PropTypes.func,
};

export default PagePreview;
