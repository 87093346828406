import { Result } from 'antd';
import React, { FC } from 'react';

interface IErrorWrapper {
  hasError: boolean;
  subTitle?: string;
  title?: string;
}
export const ErrorWrapper: FC<IErrorWrapper> = props => {
  const {
    children,
    hasError = false,
    title = 'Ошибка',
    subTitle = 'Произошла непредвиденная ошибка ',
  } = props;
  return <>{hasError ? <Result status="500" title={title} subTitle={subTitle} /> : children}</>;
};
