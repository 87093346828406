import { Icon as LegacyIcon } from '@ant-design/compatible';

import { BarsOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { propOr, propEq } from 'ramda';

import { resourceTypes } from '~constants/publication';

import SearchIco from '~assets/icons/search.svg';

import styles from './styles.scss';
import { SORT_FLAGS } from '~constants/sort';

const isSortExist = propOr(false, 'sort');

@inject(stores => {
  const {
    FilterParamsByListStore,
    FetchPaginationStore,
    SortingStore: { options, toggleOptions },
  } = stores;
  const { filterParams, setFilterValue } = FilterParamsByListStore;
  const { resetPaginationParams } = FetchPaginationStore;

  return {
    filterParams,
    setFilterValue,
    resetPaginationParams,
    toggleOptions,
    options,
  };
})
@observer
class HeaderPageSearchBar extends Component {
  componentWillUnmount() {
    const { setFilterValue, searchField } = this.props;

    setFilterValue(searchField, '');
  }

  checkAndSetDefaultSort() {
    const { filterParams, toggleOptions, options, sortItem } = this.props;
    if (sortItem && !isSortExist(filterParams)) {
      const item = options.find(propEq('type', sortItem));
      if (item) {
        toggleOptions(item, SORT_FLAGS.DESC);
      }
    }
  }

  handleKeyPress = event => {
    const { onSearch, resetPaginationParams } = this.props;

    if (event.key === 'Enter') {
      this.checkAndSetDefaultSort();
      resetPaginationParams();
      onSearch();
    }
  };

  handleButtonClick = event => {
    const { onSearch, resetPaginationParams } = this.props;

    this.checkAndSetDefaultSort();
    resetPaginationParams();
    onSearch();
  };

  handleChangeSearchTerm = e => {
    const { value } = e.target;
    const { setFilterValue, onSearch, resetPaginationParams, searchField } = this.props;

    setFilterValue(searchField, value);

    if (value.length <= 0) {
      resetPaginationParams();
      onSearch();
    }
  };

  render() {
    const {
      searchButtonText,
      createButtonText,
      isGrid,
      onToggleView,
      onCreateElement,
      filterParams,
      gridIconType,
      searchField,
      customButtons,
      resourceType,
    } = this.props;
    const viewIco = isGrid ? (
      <BarsOutlined style={{ fontSize: '26px' }} />
    ) : (
      <LegacyIcon type={gridIconType ?? 'appstore'} style={{ fontSize: '26px' }} />
    );

    const menu = (
      <Menu>
        <Menu.Item
          onClick={() => {
            onCreateElement && onCreateElement({ resourceType: 'news' });
          }}
        >
          <div>Создать Новость</div>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            onCreateElement && onCreateElement({ resourceType: 'other' });
          }}
        >
          <div>Создать Статью</div>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className={styles.root}>
        <div className={styles.left}>
          <Input
            value={filterParams[searchField]}
            className={styles.input}
            prefix={<SearchIco fill="#9A9A9A" width={24} height={24} />}
            placeholder="Найти"
            onChange={this.handleChangeSearchTerm}
            onKeyPress={this.handleKeyPress}
          />

          <Button
            onClick={this.handleButtonClick}
            className={styles.dskSearchButton}
            type="primary"
          >
            {searchButtonText}
          </Button>

          <Button
            type="primary"
            className={styles.mobSearchButton}
            icon={<SearchOutlined />}
            onClick={this.handleButtonClick}
          />

          {customButtons && <div className={styles.toogleViewButton}>{customButtons}</div>}

          {onToggleView && (
            <div className={styles.toogleViewButton}>
              <Button shape="circle" icon={viewIco} onClick={onToggleView} />
            </div>
          )}
        </div>

        {onCreateElement && (
          <div className={styles.right}>
            <div>
              {customButtons && (
                <div className={styles.toogleViewButtonMobile}>{customButtons}</div>
              )}
            </div>
            <div>
              <span className={styles.createButtonText}>{createButtonText}</span>
              <span className={styles.createButtonTextMobile}>Создать</span>
              {resourceType === resourceTypes.publication ? (
                <Dropdown overlay={menu} placement="bottomLeft" trigger="click" arrow>
                  <Button type="primary" icon={<PlusOutlined />} shape="circle" />
                </Dropdown>
              ) : (
                <Button
                  onClick={onCreateElement}
                  type="primary"
                  icon={<PlusOutlined />}
                  shape="circle"
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default HeaderPageSearchBar;

HeaderPageSearchBar.propTypes = {
  createButtonText: PropTypes.string,
  filterParams: PropTypes.any,
  isGrid: PropTypes.bool,
  searchField: PropTypes.string,
  onCreateElement: PropTypes.func,
  onSearch: PropTypes.any,
  onToggleView: PropTypes.func,
  customButtons: PropTypes.any,
  resetPaginationParams: PropTypes.func,
  searchButtonText: PropTypes.string,
  searchTerm: PropTypes.string,
  gridIconType: PropTypes.string,
  setFilterValue: PropTypes.func,
  setSearchTerm: PropTypes.func,
  resourceType: PropTypes.string,
  sortItem: PropTypes.string,
};

HeaderPageSearchBar.defaultProps = {
  createButtonText: 'Создать',
  searchButtonText: 'Найти',
  searchField: 'search',
};
