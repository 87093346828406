import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Radio, Checkbox } from 'antd';

import DeleteButton from '~components/DeleteButton';
import ItemWithLabel from '~components/ItemWithLabel';
import Modal from '~components/Modal';
import s from './styles.scss';

const CREATE_TITLE = 'Создание новой социальной сети';
const UPDATE_TITLE = 'Редактирование социальной сети';

@inject('SocialsStore', 'UserStore')
@observer
class SocialsModal extends Component {
  componentDidMount() {
    this.props.SocialsStore.fetchDictionaries();
  }

  handleSubmit = e => {
    e.preventDefault();

    const {
      SocialsStore: { editableSocial, updateSocial, createSocial },
      form,
    } = this.props;
    const formData = form.getFieldsValue();
    const social = { data: { ...formData } };
    const action = editableSocial ? updateSocial : createSocial;

    action(social);
  };

  render() {
    const {
      SocialsStore: {
        dictionaries,
        setHideModal,
        isShowModal,
        resetEditableSocial,
        editableSocial,
        isActionModal,
        deleteSocial,
      },
      UserStore: { checkPermissions },
      form,
    } = this.props;
    const { getFieldDecorator } = form;
    const ModalTitle = editableSocial ? UPDATE_TITLE : CREATE_TITLE;

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={ModalTitle}
        afterClose={resetEditableSocial}
      >
        <Form onSubmit={this.handleSubmit}>
          <ItemWithLabel label="Название">
            <Form.Item>
              {getFieldDecorator('title', {
                initialValue: editableSocial && editableSocial.title,
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(<Input placeholder="Введите название" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Социальная сеть">
            <Form.Item>
              {getFieldDecorator('type', {
                initialValue: editableSocial && editableSocial.type,
                rules: [{ required: true, message: 'Обязательное поле!' }],
                valuePropName: 'value',
              })(
                <Radio.Group>
                  {dictionaries.map(item => (
                    <Radio value={item.id} key={item.id}>
                      {item.ru}
                    </Radio>
                  ))}
                </Radio.Group>,
              )}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Ссылка социальной сети">
            <Form.Item>
              {getFieldDecorator('url', {
                initialValue: editableSocial && editableSocial.url,
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(<Input placeholder="Введите ссылку" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <Form.Item>
            {getFieldDecorator('visibility', {
              initialValue: (editableSocial && editableSocial.visibility) || false,
              valuePropName: 'checked',
            })(<Checkbox>Видимость на сайте</Checkbox>)}
          </Form.Item>

          <div className={s.modalControls}>
            <Button
              htmlType="submit"
              type="primary"
              disabled={
                isActionModal ||
                !checkPermissions(`socials.${editableSocial ? 'update' : 'create'}`)
              }
            >
              Сохранить изменения
            </Button>
            {editableSocial && (
              <div className={s.deleteBtnWrapper}>
                <DeleteButton
                  text="Удалить социальную сеть"
                  disabled={isActionModal || !checkPermissions('socials.delete')}
                  onDelete={deleteSocial}
                />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    );
  }
}

SocialsModal.propTypes = {
  SocialsStore: PropTypes.any,
  form: PropTypes.any,
};

export default Form.create({ name: 'socialForm' })(SocialsModal);
