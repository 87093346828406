import { Spin } from 'antd';
import React from 'react';

import styles from './styles.scss';

const ContentSpinner: React.FC = () => (
  <div className={styles.root}>
    <Spin size="large" tip="Идет загрузка..." />
  </div>
);

export default React.memo(ContentSpinner);
