import { Button } from 'antd';
import React from 'react';

import { FilterToggleControl } from '~components';

import styles from './styles.scss';

interface IProps {
  isAllPost: boolean;
  isShowFilter: boolean;
  onToggleMyPost(): void;
  onToggleShowFilter(): void;
}

const ChangeLogFilterControl: React.FC<IProps> = props => {
  const { onToggleShowFilter, isShowFilter, onToggleMyPost, isAllPost } = props;

  return (
    <div className={styles.root}>
      <Button type="link" onClick={onToggleMyPost} className={styles.customFilterBtn}>
        {isAllPost ? 'Показать все изменения' : 'Показать мои изменения'}
      </Button>
      <div>
        <FilterToggleControl onClick={onToggleShowFilter} isActive={isShowFilter} />
      </div>
    </div>
  );
};

export default React.memo(ChangeLogFilterControl);
