import React, { ChangeEventHandler, useEffect, useMemo } from 'react';

import { BlockType, IBlock, IMeta, IRaw, Maybe } from '~common';
import { EMPTY_RAW, EMPTY_STRING } from '~constants';
import { useBlockStateReducer } from '~hooks';
import { getText, getTextsCount, noop } from '~utils';

import { BlockItemWithLabel } from '../../BlockItemWithLabel';
import { DropZone } from '../../DropZone';
import { OnChangeBlockDataHandler, RichEditor } from '../../RichEditor';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

interface IProps extends IBlock {
  avatar?: string;
  caption?: string;
  charactersCount?: number;
  media?: unknown;
  name?: IRaw;
  nameHTML?: Maybe<string>;
  onBlur?(block: IBlock & { meta: IMeta }): void;
  onChange?(block: IBlock & IProps & { meta: IMeta }): void;
  placeholder?: string;
  text?: IRaw;
  textHTML?: Maybe<string>;
}

type TState = Pick<
  IProps,
  'text' | 'textHTML' | 'name' | 'nameHTML' | 'caption' | 'avatar' | 'media' | 'charactersCount'
>;

const QuoteBlock: React.FC<IProps> = props => {
  const {
    id = EMPTY_STRING,
    blockProps,
    isFetching = false,
    isEditing = false,
    isForceUpdate = false,
    avatar = EMPTY_STRING,
    text = EMPTY_RAW,
    name = EMPTY_RAW,
    caption = EMPTY_STRING,
    placeholder = 'Введите цитату',
    media,
    textHTML = EMPTY_STRING,
    nameHTML = EMPTY_STRING,
    charactersCount = 0,
    onChange = noop,
  } = props;

  const initialState: TState = {
    text,
    textHTML,
    name,
    nameHTML,
    caption,
    avatar,
    media,
    charactersCount,
  };

  const data: TState = useMemo(
    () => ({
      text,
      textHTML,
      name,
      nameHTML,
      caption,
      avatar,
      media,
      charactersCount,
    }),
    [text, textHTML, name, nameHTML, caption, avatar, media, charactersCount],
  );

  const [state, dispatch] = useBlockStateReducer<TState>({
    blockId: id,
    blockType: BlockType.QuoteBlock,
    incomingData: data,
    initialState,
    isEditing,
    isForceUpdate,
    onChange,
  });

  useEffect(() => {
    const charactersCount = getTextsCount(getText(state.text), getText(state.name), state.caption);
    if (charactersCount !== state.charactersCount) {
      dispatch({ charactersCount });
    }
  }, [state.nameHTML, state.textHTML, state.caption]);

  const handleChangeText: OnChangeBlockDataHandler = ({
    raw: text,
    plainText: quote,
    html: textHTML,
  }) => {
    dispatch({
      text,
      textHTML,
    });
  };

  const handleChangeCaption: ChangeEventHandler<HTMLInputElement> = ({
    target: { value: caption },
  }) => {
    dispatch({
      caption,
    });
  };

  const handleChangeName: OnChangeBlockDataHandler = ({ raw: name, html: nameHTML }) => {
    dispatch({
      name,
      nameHTML,
    });
  };

  const handleChangeAvatar = media => {
    const avatar = media?.url;
    dispatch({
      avatar,
      media,
    });
  };

  return (
    <BlockWrapper {...blockProps} id={id}>
      <RichEditor
        className={styles.input}
        inline={false}
        placeholder={placeholder}
        rawData={state.text}
        onChange={handleChangeText}
        readOnly={isFetching}
        isEditing={isEditing}
      />

      <br />

      <div className={styles.wrapper}>
        <div className={styles.dropZoneWrapper}>
          <DropZone
            files={[{ url: state.avatar }]}
            onChange={handleChangeAvatar}
            uploadData={{ type: 'avatar' }}
            noDragEventsBubbling={false}
          />
        </div>
        <BlockItemWithLabel tag="div" label="ФИО Автора">
          <RichEditor
            className={styles.nameInput}
            onChange={handleChangeName}
            rawData={state.name}
            readOnly={isFetching}
            isEditing={isEditing}
          />
        </BlockItemWithLabel>

        <BlockItemWithLabel label="Подпись к автору">
          <input
            type="text"
            name="stateCaption"
            onChange={handleChangeCaption}
            value={state.caption}
            readOnly={isFetching}
          />
        </BlockItemWithLabel>
      </div>
    </BlockWrapper>
  );
};

export default QuoteBlock;
