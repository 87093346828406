import { Select } from 'antd';
import { noop } from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { useCommentsStore } from '~hooks';

const { Option } = Select;

interface IItem {
  label: string;
  name: string;
  type: string;
}

interface IProps {
  item: IItem;
  onChange?(value: unknown): void;
  value: unknown;
}

const SelectRender: React.FC<IProps> = observer(props => {
  const { statuses } = useCommentsStore();
  const { onChange, value, item, ...propsSelect } = props;

  const fetchDataByType = useMemo(() => {
    if (item.name === 'filter.status') {
      return {
        fetch: noop,
        options: statuses,
        key: 'id',
        label: 'ru',
      };
    }

    return {};
  }, [item, statuses]);

  const handleSelectChange = useCallback(
    value => {
      const { fetch } = fetchDataByType;

      onChange && onChange(value);

      if (!value) {
        fetch && fetch();
      }
    },
    [fetchDataByType, onChange],
  );

  const { options, key, label } = fetchDataByType;

  return (
    <Select
      style={{
        width: '100%',
      }}
      onChange={handleSelectChange}
      // @ts-ignore
      value={value}
      {...propsSelect}
    >
      {options?.map(option => (
        <Option value={option[key || '']}>{option[label || '']}</Option>
      ))}
    </Select>
  );
});

export default SelectRender;
