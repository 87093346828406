import { observable, action, runInAction } from 'mobx'
import API from '~api'

const SOCIALS_URL = '/sociallinks'

class SocialsStore {
  @observable socials = []

  @observable dictionaries = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editableSocial = null

  @action
  fetchDictionaries = () => {
    const promise = API.get('/dictionaries/settings')

    return promise.then(res => {
      const { data } = res

      runInAction(() => {
        this.dictionaries = data.socialLinkTypes
      })
    })
  }

  @action
  fetchSocials = () => {
    const fetchSocialsPromise = API.get(SOCIALS_URL)

    return fetchSocialsPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.socials = data
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  createSocial = social => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createSocialPromise = API.post(SOCIALS_URL, social)

    return createSocialPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.socials.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  updateSocial = social => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableSocial
    const updateSocialPromise = API.patch(`${SOCIALS_URL}/${_id}`, social)

    return updateSocialPromise
      .then(res => {
        const { data } = res.data
        const editableSocialIndex = this.socials.indexOf(this.editableSocial)

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.socials[editableSocialIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteSocial = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editableSocial
    const deleteSocialPromise = API.delete(`${SOCIALS_URL}/${_id}`)

    return deleteSocialPromise
      .then(() => {
        this.socials.remove(this.editableSocial)

        this.resetEditableSocial()
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditableSocial = value => {
    this.editableSocial = value

    this.setShowModal()
  }

  @action
  resetEditableSocial = () => {
    this.editableSocial = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new SocialsStore()
