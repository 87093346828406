import { always, complement, isEmpty, join, map, pipe, toPairs, when } from 'ramda';

import { isNotNil } from './functional';

export const generateUrlWithSearch = (url: string, search?: string) =>
  `${url}${when(isNotNil, always(`?search=${search}`))(search)}`;

export const getFilterParamsString = pipe<
  { [k: string]: string },
  Array<[string, string]>,
  string[],
  string,
  string
>(
  toPairs,
  map(([key, value]) => `${key}=${value}`),
  join('&'),
  when(complement(isEmpty), url => `?${url}`),
);
