import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'
import s from './styles.scss'

@inject('PlaylistsStore')
@observer
class PlaylistsItem extends Component {
  handleClick = () => {
    const {
      PlaylistsStore: { setEditablePlaylist },
      playlist
    } = this.props

    setEditablePlaylist(playlist)
  }

  render() {
    const {
      playlist: { title, _id, visible }
    } = this.props

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{title}</h2>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>ID:</h3>
            <p className={s.cardItemValue}>{_id}</p>
          </div>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Видимость:</h3>
            <p className={s.cardItemValue}>{visible ? 'да' : 'нет'}</p>
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

PlaylistsItem.propTypes = {
  PlaylistsStore: PropTypes.any,
  playlist: PropTypes.object
}

export default PlaylistsItem
