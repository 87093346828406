import { find, findIndex } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import API from '~api';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';
import FetchPaginationStore from '~store/fetchPaginationStore';
import { inc } from 'ramda';
import { showErrorNotification } from '~utils';
import { openSectionsModal } from '~containers';

const SECTIONS_URL = '/sections';

export class SectionsStore {
  @observable sections = [];

  @observable isFirstLoading = true;

  @observable isActionModal = false;

  @observable isShowModal = false;

  @observable editableSection = null;

  @observable allItemsCount = 0;

  @action
  fetchSections = (defaultFilter = 'sort=+order') => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const { offset, setPageOffset } = FetchPaginationStore;

    const isNewFetch = offset === 0;
    if (isNewFetch) {
      this.sections = [];
      this.setAllItemsCount(0);
    }

    const promiseUrl = getPromiseUrl({ url: SECTIONS_URL, defaultFilter });

    return API.get(promiseUrl)
      .then(res => {
        const { data: sections = [], meta } = res.data;

        runInAction(() => {
          if (offset === 0) {
            this.sections = sections;
          } else {
            this.sections = [...this.sections, ...sections];
          }
          this.isFirstLoading = false;
          setPageOffset(inc(offset));
          this.setAllItemsCount(meta.count);
          return Promise.resolve(sections);
        });
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки разделов', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.isFirstLoading = false;
      });
  };

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    const { resetPaginationParams } = FetchPaginationStore;

    if (oldIndex === newIndex) return;

    const current = find(this.sections, { order: oldIndex });
    const target = find(this.sections, { order: newIndex });

    const currentId = find(this.sections, { order: oldIndex })._id;
    const targetId = findIndex(this.sections, ({ order }) => order === newIndex);

    let toEnd = false;

    let targetPlacement;

    if (targetId + 1 === this.sections.length) {
      targetPlacement = this.sections[this.sections.length - 1]._id;
      toEnd = true;
    } else {
      targetPlacement = this.sections[targetId + 1]._id;
    }

    if (oldIndex > newIndex) {
      targetPlacement = find(this.sections, { order: newIndex })._id;
    }

    let data;

    if (toEnd) {
      data = {
        meta: {
          after: targetPlacement,
        },
      };
    } else {
      data = {
        meta: {
          before: targetPlacement,
        },
      };
    }

    const changeOrderingPromise = API.post(`${SECTIONS_URL}/${currentId}/placement`, data);

    current.order = newIndex;
    target.order = newIndex + 0.5;

    this.sections = this.sections.slice().sort(({ order: a }, { order: b }) => a - b);

    changeOrderingPromise.then(() => {
      resetPaginationParams();
      this.fetchSections();
    });
  };

  @action
  createSection = section => {
    const { resetPaginationParams } = FetchPaginationStore;

    runInAction(() => {
      this.isActionModal = true;
    });

    const createSectionPromise = API.post(SECTIONS_URL, section);

    return createSectionPromise
      .then(res => {
        const { data } = res.data;
        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.sections.unshift(data);
          resetPaginationParams();
          this.fetchSections();
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  updateSection = section => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const { _id } = this.editableSection;
    const updateSectionPromise = API.patch(`${SECTIONS_URL}/${_id}`, section);

    return updateSectionPromise
      .then(res => {
        const { data } = res.data;
        const editableSectionIndex = this.sections.indexOf(this.editableSection);

        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.sections[editableSectionIndex] = data;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  deleteSection = () => {
    const { resetPaginationParams } = FetchPaginationStore;

    runInAction(() => {
      this.isActionModal = true;
    });

    const { _id } = this.editableSection;
    const deleteSectionPromise = API.delete(`${SECTIONS_URL}/${_id}`);

    return deleteSectionPromise
      .then(() => {
        this.sections.remove(this.editableSection);
        resetPaginationParams();
        this.fetchSections();

        this.resetEditableSection();
        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  setEditableSection = async value => {
    this.editableSection = { seo: { title: '', description: '' }, ...value };

    await openSectionsModal();
  };

  @action
  resetEditableSection = () => {
    this.editableSection = null;
  };

  @action
  setShowModal = () => {
    this.isShowModal = true;
  };

  @action
  setHideModal = () => {
    this.isShowModal = false;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @computed
  get sectionsLength() {
    return this.sections.length;
  }
}

export default new SectionsStore();
