import { action, computed, observable } from 'mobx';
import { pipe, prop, tap } from 'ramda';

import { EMPTY_STRING } from '~constants';
import { EmployeeTransportLayer } from '~services/employeeApi';
import { checkPermissions, resolvePromise, showErrorNotificationInPromise } from '~utils';

export class UserStore {
  apiLayer = new EmployeeTransportLayer();

  @observable user = JSON.parse(localStorage.getItem('user') || 'null') || null;

  @observable token = JSON.parse(localStorage.getItem('token') || 'null') || null;

  @computed
  get isAuthenticated() {
    return !!this.user || !!this.token;
  }

  @computed
  get employeeRole() {
    return this.user?.role || null;
  }

  @computed
  get roleRights() {
    return this.user?.role?.allRights || this.user?.role?.rights || {};
  }

  @computed
  get employeeId() {
    return this.user?._id || null;
  }

  @computed
  get canUnlockBlock() {
    return this.checkPermissions('posts.unlockBlocks');
  }

  @action
  setUser = user => {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  };

  @action
  setToken = token => {
    this.token = token;
    localStorage.setItem('token', JSON.stringify(token));
  };

  prepareUser = user => ({
    ...user,
    name: `${user?.firstName || EMPTY_STRING} ${user?.lastName || EMPTY_STRING}`,
    role: user?.role || {},
    avatar: user?.avatar?.url || EMPTY_STRING,
  });

  @action
  oAuth = data => {
    return this.apiLayer
      .oAuth(data)
      .then(pipe(prop('token'), tap(this.setToken), resolvePromise))
      .catch(showErrorNotificationInPromise({ title: 'Ошибка авторизации' }));
  };

  @action
  getOAuthCallbackUrl = query => {
    return this.apiLayer.getOAuthCallbackUrl(query);
  };

  @action
  login = ({ login, password }) => {
    return this.apiLayer
      .login({ login, password })
      .then(pipe(prop('token'), tap(this.setToken), resolvePromise))
      .catch(showErrorNotificationInPromise({ title: 'Ошибка авторизации' }));
  };

  @action
  getUserInfo = () => {
    return this.apiLayer
      .getEmployeeInfo()
      .then(pipe(prop('data'), this.prepareUser, tap(this.setUser), resolvePromise))
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка получения данных пользователя',
          callback: () => {
            console.log('Ошибка получения данных пользователя');
            this.setUser(null);
            this.setToken(null);
          },
        }),
      );
  };

  @action
  logout = () => {
    return this.apiLayer
      .logout()
      .then(() => {
        this.setUser(null);
        this.setToken(null);
        localStorage.clear();
        return Promise.resolve(true);
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка выхода из учетной записи' }));
  };

  checkPermissions = (
    authority:
      | string
      | string[]
      | ((rights: Record<string, Record<string, boolean>>) => boolean | Promise<boolean>)
      | Promise<string>,
  ) => {
    return checkPermissions(authority, this.roleRights);
  };
}

export default new UserStore();
