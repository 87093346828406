import { bool, object } from 'yup';
import { rssSchema } from '~schemas/rss';
import { isValidBySchema } from '~utils/isValidBySchema';

/** @type object */
export const flagsSchema = object()
  .default({
    RSS: {},
  })
  .shape({
    commentsAllowed: bool(),
    advDisabled: bool(),
    pushAllowed: bool(),
    pushSent: bool(),
    adultContent: bool(),
    criminalContent: bool(),
    shockContent: bool(),
    clickContent: bool(),
    // blockedByRKN: bool(),
    // darkSide: bool(),
    coverHidden: bool(),
    // isWide: bool(),
    RSS: rssSchema,
  });

export const isValidFlags = isValidBySchema(flagsSchema);
