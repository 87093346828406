import '@ant-design/compatible/assets/index.css';

import { Button, Checkbox, Form, Input, message } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { IFile, InjectedModalProps, ISection } from '~common';
import { ButtonUpload, DeleteButton, ItemWithLabel, Modal } from '~components';
import { EMPTY_STRING } from '~constants';
import styles from '~containers/Tags/TagModal/styles.scss';
import { useSectionsStore, useUserStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { getUrlFromFile } from '~utils';

import s from './styles.scss';

const CREATE_TITLE = 'Создание нового раздела';
const UPDATE_TITLE = 'Редактирование раздела';

const SectionsModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;
  const {
    sections,
    editableSection,
    isActionModal,
    resetEditableSection,
    createSection,
    updateSection,
    deleteSection,
  } = useSectionsStore();
  const { checkPermissions } = useUserStore();
  const [form] = Form.useForm<ISection>();

  const [cover, setCover] = useState(editableSection?.cover?.url || EMPTY_STRING);

  const fileUploadProps = useMemo(
    () => ({
      name: 'file',
      action: `${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`,
      withCredentials: true,
      data: { type: 'card' },
      accept: 'image/*',
    }),
    [FileTransportLayer.fileUploadUrl],
  );

  const onClose = useCallback(
    (result: any = []) => {
      close && close(result);
    },
    [close],
  );

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const section = {
        data: {
          ...values,
          cover: getUrlFromFile(values?.cover),
        },
        meta: {
          before: sections.length > 0 ? sections[0]?._id : null,
        },
      };
      if (editableSection) {
        updateSection(section).then(handleClose);
      } else {
        createSection(section).then(handleClose);
      }
    });
  }, [editableSection]);

  const handleDelete = () => {
    deleteSection().then(handleClose);
  };

  const handleClose = useCallback(() => {
    resetEditableSection();
    form.resetFields();
    onClose(true);
  }, [form]);

  const ModalTitle = editableSection ? UPDATE_TITLE : CREATE_TITLE;

  return (
    <Modal destroyOnClose width={590} onClose={handleClose} isVisible={isOpen} title={ModalTitle}>
      <Form form={form}>
        <ItemWithLabel label="Название раздела">
          <Form.Item
            name="title"
            initialValue={get(editableSection, 'title')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите название" disabled={isActionModal} />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Описание раздела">
          <Form.Item
            name="description"
            initialValue={get(editableSection, 'description')}
            rules={[{ required: false }]}
          >
            <Input placeholder="Введите описание" disabled={isActionModal} />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="SEO заголовок">
          <Form.Item
            name={['seo', 'title']}
            initialValue={get(editableSection, 'seo.title')}
            rules={[{ required: false }]}
          >
            <Input placeholder="Введите SEO заголовок" disabled={isActionModal} />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="SEO описание">
          <Form.Item
            name={['seo', 'description']}
            initialValue={get(editableSection, 'seo.description')}
            rules={[{ required: false }]}
          >
            <Input placeholder="Введите SEO описание" disabled={isActionModal} />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Обложка">
          <>
            {cover && (
              <div>
                <img className={styles.cardItemCover} src={cover} alt="Cover" />
              </div>
            )}
            <Form.Item name="cover" initialValue={get(editableSection, 'cover')}>
              <ButtonUpload
                uploadProps={fileUploadProps}
                onChange={(data: IFile) => {
                  setCover(data?.url || EMPTY_STRING);
                }}
              />
            </Form.Item>
          </>
        </ItemWithLabel>

        <Form.Item
          name="visible"
          initialValue={get(editableSection, 'visible') || false}
          valuePropName="checked"
        >
          <Checkbox>Опубликована на сайте</Checkbox>
        </Form.Item>

        <div className={s.modalControls}>
          <Button
            onClick={handleSubmit}
            type="primary"
            loading={isActionModal}
            disabled={!checkPermissions(`sections.${editableSection ? 'update' : 'create'}`)}
          >
            Сохранить изменения
          </Button>
          {editableSection && (
            <div className={s.deleteBtnWrapper}>
              <DeleteButton
                text="Удалить раздел"
                onDelete={handleDelete}
                disabled={!checkPermissions('sections.delete')}
              />
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
});

export const openSectionsModal = createModal(SectionsModal);
