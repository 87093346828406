import '@ant-design/compatible/assets/index.css';

import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Button, Input } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { AvatarUpload, DeleteButton, ItemWithLabel, Modal } from '~components';
// TODO Убрать SelectRender в components
import SelectRender from '~components/Publications/Filter/SelectRender';
import { EMPLOYEES_CREATE_TITLE, EMPLOYEES_UPDATE_TITLE } from '~constants';
import { AuthorsSelect } from '~containers';
import { useEmployeesStore, useFormHasErrors, useUserStore } from '~hooks';

import styles from './styles.scss';

type IProps = Partial<FormComponentProps>;

const EmployeesModal: React.FC<IProps> = observer(props => {
  const {
    isShowModal,
    setHideModal,
    resetEditableEmployee,
    isActionModal,
    updateEmployee,
    editableEmployee,
    createEmployee,
  } = useEmployeesStore();
  const { checkPermissions } = useUserStore();

  const { form } = props;
  const { setFieldsValue, getFieldsValue, getFieldDecorator, resetFields } = form || {};
  const formData = getFieldsValue && getFieldsValue();
  const isHasErrors = useFormHasErrors(form);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      const employee = {
        data: {
          ...formData,
          department: formData?.department._id,
          role: formData?.role._id,
          avatar: formData?.avatar._id || null,
        },
      };

      const action = editableEmployee ? updateEmployee : createEmployee;

      action(employee);
    },
    [createEmployee, editableEmployee, formData, updateEmployee],
  );

  const toggleBlockUser = useCallback(
    e => {
      e.preventDefault();

      const employee = {
        data: {
          active: !editableEmployee?.active,
        },
      };

      updateEmployee(employee);
    },
    [editableEmployee?.active, updateEmployee],
  );

  const normFile = useCallback(e => {
    const file = get(e, 'file.response.data', null);
    const status = get(e, 'file.status', null);

    return {
      ...file,
      status,
    };
  }, []);

  const handleClearAvatar = useCallback(() => {
    setFieldsValue &&
      setFieldsValue({
        avatar: {
          url: '',
        },
      });
  }, [setFieldsValue]);

  const modalTitle = useMemo(
    () => (editableEmployee ? EMPLOYEES_UPDATE_TITLE : EMPLOYEES_CREATE_TITLE),
    [editableEmployee],
  );

  return (
    <Modal
      destroyOnClose
      width={790}
      onClose={setHideModal}
      isVisible={isShowModal}
      title={modalTitle}
      afterClose={() => {
        resetFields && resetFields(['avatar']);
        resetEditableEmployee && resetEditableEmployee();
      }}
    >
      <div className={styles.root}>
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator &&
              getFieldDecorator('avatar', {
                initialValue: { url: get(editableEmployee, 'avatar.url', '') },
                getValueFromEvent: normFile,
              })(<AvatarUpload onClearAvatar={handleClearAvatar} />)}
          </Form.Item>

          <ItemWithLabel label="Имя сотрудника">
            <Form.Item>
              {getFieldDecorator &&
                getFieldDecorator('firstName', {
                  initialValue: get(editableEmployee, 'firstName', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }],
                })(<Input disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Фамилия сотрудника">
            <Form.Item>
              {getFieldDecorator &&
                getFieldDecorator('lastName', {
                  initialValue: get(editableEmployee, 'lastName', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }],
                })(<Input disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="E-mail сотрудника">
            <Form.Item>
              {getFieldDecorator &&
                getFieldDecorator('email', {
                  initialValue: get(editableEmployee, 'email', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }],
                })(<Input disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Выбрать отдел">
            <Form.Item>
              {getFieldDecorator &&
                getFieldDecorator('department._id', {
                  initialValue: get(editableEmployee, 'department._id', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }],
                })(
                  <SelectRender
                    item={{
                      type: 'select',
                      name: 'filter.department',
                    }}
                  />,
                )}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Роль">
            <Form.Item>
              {getFieldDecorator &&
                getFieldDecorator('role._id', {
                  initialValue: get(editableEmployee, 'role._id', ''),
                  rules: [{ required: true, message: 'Обязательное поле!' }],
                })(
                  <SelectRender
                    item={{
                      type: 'select',
                      name: 'filter.role',
                    }}
                  />,
                )}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Добавить автора">
            <Form.Item>
              {getFieldDecorator &&
                getFieldDecorator('authors', {
                  initialValue: get(editableEmployee, 'authors', []).map(item => item._id),
                  rules: [{ required: true, message: 'Обязательное поле!' }],
                })(<AuthorsSelect mode="multiple" />)}
            </Form.Item>
          </ItemWithLabel>

          <div className={styles.modalControls}>
            <Button
              disabled={
                isActionModal ||
                isHasErrors ||
                !checkPermissions(`employees.${editableEmployee ? 'update' : 'create'}`)
              }
              htmlType="submit"
              type="primary"
            >
              Сохранить изменения
            </Button>

            {editableEmployee && (
              <div className={styles.deleteBtnWrapper}>
                <DeleteButton
                  text={`${
                    !editableEmployee.active ? 'Разблокировать' : 'Заблокировать'
                  } сотрудника`}
                  onDelete={toggleBlockUser}
                  disabled={
                    !checkPermissions(`employees.${!editableEmployee.active ? 'delete' : 'create'}`)
                  }
                />
              </div>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
});

const WrappedEmployeesForm = Form.create({ name: 'employeesForm' })(EmployeesModal);

export default WrappedEmployeesForm;
