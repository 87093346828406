import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import GridWrapper from '~components/GridWrapper';
import AddNew from '~components/AddNew';
import ContentSpinner from '~components/ContentSpinner';
import PlaylistsItem from '~components/Playlists/PlaylistsItem';

const SortableItem = sortableElement(({ playlist }) => <PlaylistsItem playlist={playlist} />);

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  );
});

@inject('PlaylistsStore', 'FetchPaginationStore', 'UserStore')
@observer
class PlaylistsList extends Component {
  componentDidMount() {
    const {
      PlaylistsStore: { fetchPlaylists },
      FetchPaginationStore: { setPageLimit },
    } = this.props;

    setPageLimit(60);
    fetchPlaylists();
  }

  render() {
    const {
      PlaylistsStore: { playlists, setShowModal, isFirstLoading, changeOrdering },
      UserStore: { checkPermissions },
    } = this.props;

    if (isFirstLoading) {
      return <ContentSpinner />;
    }

    return (
      <SortableContainer
        distance={1}
        axis="xy"
        onSortEnd={checkPermissions('playlists.update') ? changeOrdering : undefined}
      >
        {playlists.map(playlist => (
          <SortableItem key={playlist._id} playlist={playlist} index={playlist.order} />
        ))}
        {checkPermissions('playlists.create') && <AddNew onClick={setShowModal} />}
      </SortableContainer>
    );
  }
}

PlaylistsList.propTypes = {
  PlaylistsStore: PropTypes.any,
  FetchPaginationStore: PropTypes.any,
};

export default PlaylistsList;
