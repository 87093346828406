import { string, object, date, array } from 'yup'
import { compiledAuthorSchema } from '~schemas/author'
import { compiledBlockSchema } from '~schemas/block'
import { isValidBySchema } from '~utils/isValidBySchema'
import { transformDate } from '~schemas/transformations/transformDate'

/** @type object */
export const compiledBlockGroupSchema = object()
  .default(undefined)
  .shape({
    _id: string().required(),
    title: string(),
    blocks: array(compiledBlockSchema),
    author: compiledAuthorSchema,
    createdAt: date().transform(transformDate)
  })

export const isValidCompiledBlockGroup = isValidBySchema(compiledBlockGroupSchema)
