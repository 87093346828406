import { get } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { DateUtils, RangeModifier } from 'react-day-picker';

import { SideCalendar } from '~components';
import { modifiers } from '~components/Schedule/CalendarView/SideCalendar/index.js';
import {
  useFetchPaginationStore,
  useFilterParamsByListStore,
  useMovePublicationsStore,
} from '~hooks';

import s from './styles.scss';

export const MigrationsFilter = observer(() => {
  const { filterParams, setFilterValue } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const { fetchMovedPosts } = useMovePublicationsStore();

  const { start, end } = useMemo(() => {
    const dateArray = get(filterParams, 'filter.createdAt', '').split(',');

    const startDate = get(dateArray, '0', undefined);
    const endDate = get(dateArray, '1', undefined);

    return {
      start: startDate ? new Date(startDate) : undefined,
      end: endDate ? new Date(endDate) : undefined,
    };
  }, [filterParams['filter.createdAt']]);

  const handleDayClick = useCallback(
    day => {
      const dateArray = get(filterParams, 'filter.createdAt', '').split(',');

      const startDate = get(dateArray, '0', undefined);
      const endDate = get(dateArray, '1', undefined);

      const range = DateUtils.addDayToRange(day, {
        from: startDate ? new Date(startDate) : undefined,
        to: endDate ? new Date(endDate) : undefined,
      } as RangeModifier);

      if (range.from) {
        let formatDateByFilter = `${moment(range.from).format('YYYY-MM-DD')}`;

        if (range.to) {
          formatDateByFilter = `${formatDateByFilter},${moment(range.to).format('YYYY-MM-DD')}`;
        }
        setFilterValue('filter.createdAt', formatDateByFilter);
      } else {
        setFilterValue('filter.createdAt', null);
      }

      resetPaginationParams();
      fetchMovedPosts();
    },
    [fetchMovedPosts, filterParams, resetPaginationParams, setFilterValue],
  );
  return (
    <div className={s.calendar}>
      <SideCalendar
        className="Range"
        selectedDays={[start, { from: start, to: end }]}
        modifiers={{ modifiers, start, end }}
        onDayClick={handleDayClick}
      />
    </div>
  );
});
