import { observable, action, runInAction } from 'mobx'
import { find, findIndex } from 'lodash'
import API from '~api'
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists'

const PLAYLISTS_URL = '/playlists'

class PlaylistsStore {
  @observable playlists = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editablePlaylist = null

  @observable currentFilter = ''

  @action
  setCurrentFilter = filter => {
    this.currentFilter = filter
  }

  @action
  fetchPlaylists = () => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore
    const promiseUrl = getPromiseUrl({ url: PLAYLISTS_URL })
    const fetchPlaylistsPromise = API.get(promiseUrl)

    return fetchPlaylistsPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.playlists = data.sort(({ order: a }, { order: b }) => a - b)
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return

    const current = find(this.playlists, { order: oldIndex })
    const target = find(this.playlists, { order: newIndex })

    const currentId = find(this.playlists, { order: oldIndex })._id
    const targetId = findIndex(this.playlists, ({ order }) => order === newIndex)
    let targetPlacement = this.playlists[targetId + 1]._id

    if (oldIndex > newIndex) {
      targetPlacement = find(this.playlists, { order: newIndex })._id
    }

    const changeOrderingPromise = API.post(`${PLAYLISTS_URL}/${currentId}/placement`, {
      meta: {
        before: targetPlacement
      }
    })

    current.order = newIndex
    target.order = newIndex + 0.5
    // if (oldIndex < newIndex) {
    // target.order = newIndex+.5
    // target.order = newIndex-.5
    // }
    this.playlists = this.playlists.slice().sort(({ order: a }, { order: b }) => a - b)

    changeOrderingPromise.then(() => {
      this.fetchPlaylists()
    })
  }

  @action
  createPlaylist = playlist => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createPlaylistPromise = API.post(PLAYLISTS_URL, playlist)

    return createPlaylistPromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.playlists.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  updatePlaylist = playlist => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editablePlaylist
    const updatePlaylistPromise = API.patch(`${PLAYLISTS_URL}/${_id}`, playlist)

    return updatePlaylistPromise
      .then(res => {
        const { data } = res.data
        const editablePlaylistIndex = this.playlists.indexOf(this.editablePlaylist)

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.playlists[editablePlaylistIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deletePlaylist = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editablePlaylist
    const deletePlaylistPromise = API.delete(`${PLAYLISTS_URL}/${_id}`)

    return deletePlaylistPromise
      .then(() => {
        this.playlists.remove(this.editablePlaylist)

        this.resetEditablePlaylist()
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditablePlaylist = value => {
    this.editablePlaylist = value

    this.setShowModal()
  }

  @action
  resetEditablePlaylist = () => {
    this.editablePlaylist = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new PlaylistsStore()
