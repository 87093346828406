import { Spin } from 'antd';
import React, { FC } from 'react';

import styles from './styles.scss';

interface ILoadingWrapperProps {
  isLoading: boolean;
  title?: string;
}

export const LoadingWrapper: FC<ILoadingWrapperProps> = props => {
  const { children = null } = props;
  const { isLoading = false } = props;
  const { title = 'Идет загрузка...' } = props;

  return (
    <>
      {isLoading ? (
        <div className={styles.root}>
          <Spin size="large" tip={title} />
        </div>
      ) : (
        children
      )}
    </>
  );
};
