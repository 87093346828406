import { observable, action, computed } from 'mobx';

import API from '~api';
import FetchPaginationStore from './fetchPaginationStore';
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists';

const FEEDBACK_URL = '/feedbacks';

export class FeedbackStore {
  @observable feedbacks = [];

  @observable dictionaries = [];

  @observable currentType = '';

  @observable isFirstLoading = true;

  @observable isLoadingMore = false;

  @observable allItemsCount = 0;

  @action
  fetchDictionaries = () => {
    const promise = API.get('dictionaries/feedback');

    return promise.then(res => {
      const { data } = res;

      this.dictionaries = data.feedbackTypes;
    });
  };

  @action
  fetchFeedbacks = () => {
    const { offset } = FetchPaginationStore;
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: FEEDBACK_URL });
    const feedbackPromise = API.get(promiseUrl);

    this.setIsLoadingMore(true);

    return feedbackPromise
      .then(res => {
        const { data, meta } = res.data;

        if (offset === 0) {
          this.setFeedbacks(data);
        } else {
          this.setFeedbacks([...this.feedbacks, ...data]);
        }

        this.setAllItemsCount(meta.count);
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      })
      .catch(() => {
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      });
  };

  @action
  deleteFeedback = feedback => {
    const promise = API.delete(`${FEEDBACK_URL}/${feedback._id}`);

    return promise.then(() => {
      this.feedbacks.remove(feedback);
    });
  };

  @action
  setFeedbacks = data => {
    this.feedbacks = data;
  };

  @action
  setCurrentFeedbackType = type => {
    this.currentType = type;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value;
  };

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value;
  };

  @computed
  get feedbacksLength() {
    return this.feedbacks.length;
  }
}

export default new FeedbackStore();
