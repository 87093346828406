import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import clsx from 'classnames';
import EditorUtils from 'draft-js-plugins-utils';
import isPunycode from 'is-punycode';
import { toUnicode } from 'punycode-url';
import { dec } from 'ramda';
import React, { Component } from 'react';

import URLUtils from '../../../utils/URLUtils';

interface IProps {
  getEditorState(): void;
  onOverrideContent(data: undefined): void;
  onRemoveLinkAtSelection(): void;
  placeholder?: string;
  setEditorState(state: any): void;
  theme: any;
}

interface IState {
  isInvalid: boolean;
  value: string;
}

export default class AddLinkForm extends Component<IProps, IState> {
  static defaultProps = {
    placeholder: 'Введите ссылку и нажмите enter',
    value: '',
  };

  input: HTMLInputElement;

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      isInvalid: false,
    };
  }

  componentDidMount() {
    this.input.focus();
  }

  onRef = node => {
    this.input = node;
  };

  onChange = ({ target: { value } }) => {
    const nextState = { value, isInvalid: this.state.isInvalid };
    if (this.state.isInvalid && URLUtils.isUrl(URLUtils.normalizeUrl(value))) {
      nextState.isInvalid = false;
    }
    this.setState(nextState);
  };

  onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const value = e.clipboardData.getData('text');

    if (value === this.state.value) {
      const nextState = { value, isInvalid: this.state.isInvalid };
      if (this.state.isInvalid && URLUtils.isUrl(URLUtils.normalizeUrl(value))) {
        nextState.isInvalid = false;
      }
      this.setState(nextState);
      e.preventDefault();
    }
  };

  onClose = () => this.props.onOverrideContent(undefined);

  onMouseUp = event => {
    // event.preventDefault();
    event.stopPropagation();
  };

  onKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submit();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      this.onClose();
    }
  };

  submit() {
    const { getEditorState, setEditorState, onRemoveLinkAtSelection } = this.props;
    let { value: url } = this.state;

    if (!url) {
      onRemoveLinkAtSelection();
    }

    if (!URLUtils.isMail(URLUtils.normaliseMail(url))) {
      url = URLUtils.normalizeUrl(url);

      if (!isPunycode(url) && !URLUtils.isUrl(url) && !URLUtils.isUrl(url)) {
        this.setState({ isInvalid: true });
        return;
      }

      if (isPunycode(url) && !URLUtils.isUrl(toUnicode(url))) {
        this.setState({ isInvalid: true });
        return;
      }
    } else {
      url = URLUtils.normaliseMail(url);
    }
    setEditorState(EditorUtils.createLinkAtSelection(getEditorState(), url));
    this.input.blur();
    this.onClose();
  }

  render() {
    const { theme, placeholder } = this.props;
    const { value, isInvalid } = this.state;
    const className = isInvalid ? clsx(theme.input, theme.inputInvalid) : theme.input;

    return (
      <Input
        className={className}
        onClick={this.onMouseUp}
        onBlur={this.onClose}
        onChange={this.onChange}
        onPaste={this.onPaste}
        onKeyDown={this.onKeyDown}
        placeholder={placeholder}
        ref={this.onRef}
        type="text"
        value={value}
        suffix={
          isInvalid ? (
            <Tooltip title="Неправильная ссылка">
              <InfoCircleOutlined style={{ color: 'rgba(250,10,10,0.45)' }} />
            </Tooltip>
          ) : (
            <span />
          )
        }
      />
    );
  }
}
