import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { IEmployee } from '~common';
import { ISelectProps, Select } from '~components';
import { useEmployeesStore } from '~hooks';

interface IProps extends ISelectProps<IEmployee> {}

export const EmployeesSelect: React.FC<IProps> = observer(props => {
  const { fetchEmployeesBySearch, employeesSearches } = useEmployeesStore();

  const options = useMemo(
    () =>
      employeesSearches.map((item: IEmployee) => ({
        value: item._id,
        label: `${item.firstName} ${item.lastName}`,
      })),
    [employeesSearches],
  );

  return (
    <Select
      {...props}
      fetch={fetchEmployeesBySearch}
      options={options}
      keyField="_id"
      labelField="name"
    />
  );
});
