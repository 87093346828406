import { AxiosResponse } from 'axios';
import { action, computed, observable } from 'mobx';

import {
  FilterItem,
  ListResponse,
  Notification,
  NotificationCreateResponse,
  NotificationUpdateResponse,
} from '~common';
import { NotificationsTransportLayer } from '~services/notificationsApi';
import { AbstractStore } from '~store/AbstractStore';

export class NotificationsStore extends AbstractStore<
  Notification,
  NotificationCreateResponse,
  NotificationUpdateResponse
> {
  @observable isShowModal = false;

  apiLayer: NotificationsTransportLayer;

  @observable windowFocused = false;

  @action
  setIsWindowFocused = focus => {
    this.windowFocused = focus;
  };

  @computed
  get isWindowFocused() {
    return this.windowFocused;
  }

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();

    this.apiLayer = new NotificationsTransportLayer();
  }

  @computed
  get filterItems(): FilterItem[] {
    return [];
  }

  protected createHandler(data: NotificationCreateResponse): Promise<AxiosResponse<Notification>> {
    return this.apiLayer.create(data);
  }

  protected updateHandler(
    id: string,
    data: NotificationUpdateResponse,
  ): Promise<AxiosResponse<Notification>> {
    return this.apiLayer.update(id, data);
  }

  protected deleteHandler(id: string): Promise<AxiosResponse> {
    return this.apiLayer.delete(id);
  }

  protected getHandler(id: string): Promise<AxiosResponse<Notification>> {
    return this.apiLayer.get(id);
  }

  protected getListHandler(
    filter: string | undefined,
  ): Promise<AxiosResponse<ListResponse<Notification>>> {
    return this.apiLayer.getList(filter);
  }
}

export default new NotificationsStore();
