import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.scss'

const StatusLabel = ({ color, label }) => {
  return (
    <div className={styles.root}>
      <div style={{ backgroundColor: color }} className={styles.badge} />
      <span className={styles.label}>{label}</span>
    </div>
  )
}

export default StatusLabel

StatusLabel.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string
}
