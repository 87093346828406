import React, { Component } from 'react'
import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import { EmailsList, EmailsModal } from '~components/Emails'

const PAGE_TITLE = 'Настройка адресов электронной почты'

class SettingsPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <EmailsList />
        </ContentWrapper>
        <EmailsModal />
      </>
    )
  }
}

export default SettingsPage
