import { observer } from 'mobx-react';
import React from 'react';

import { EmptyContent } from '~components';
import { useCommentsStore } from '~hooks';

import { CommentItem } from '../CommentItem';
import styles from './styles.scss';

export const CommentsList = observer(() => {
  const { comments, isEmptyComments } = useCommentsStore();

  if (isEmptyComments) return <EmptyContent text="Нет комментариев" />;

  return (
    <div className={styles.root}>
      {comments.map((comment, i) => (
        <CommentItem key={i} comment={comment} />
      ))}
    </div>
  );
});
