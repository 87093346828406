/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { DeleteOutlined } from '@ant-design/icons';

import { Slider } from 'antd';
import { MARKS } from '../../constants';

import s from './styles.scss';

// const actualValues = [0,8,7,6,5,4,3,2,1]
const actualValues = [0, 1, 2, 3, 4, 5, 6, 7, 8];

const SliderItem = props => {
  const { id, label = '', disabled, allowDelete = true, value, ...rest } = props;

  const handleClick = () => {
    const { onClick } = props;

    onClick(id);
  };

  const handleChangeSlider = value => {
    const { onChangeSlider } = props;

    onChangeSlider({
      id,
      value,
    });
  };

  return (
    <div className={s.root}>
      {label && (
        <div className={s.title}>
          {label}{' '}
          {allowDelete && !disabled && (
            <span className={s.closeIcon} onClick={handleClick}>
              <DeleteOutlined />
            </span>
          )}
        </div>
      )}
      <div className={s.item}>
        <Slider
          disabled={disabled}
          handleStyle={{ marginBottom: 0 }}
          min={0}
          max={8}
          marks={MARKS}
          vertical
          onChange={handleChangeSlider}
          value={actualValues[value]}
          tooltipVisible={false}
          {...rest}
        />
      </div>
    </div>
  );
};

SliderItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onChangeSlider: PropTypes.func,
  disabled: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

export default SliderItem;
