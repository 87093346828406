import { Avatar } from 'antd';
import classNames from 'classnames';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { IEmployee } from '~common';
import { WhiteBlockWithShadow } from '~components';
import { useEmployeesStore } from '~hooks';
import { Pencil } from '~icons';

import styles from './styles.scss';

interface IProps {
  employee: IEmployee;
}

const EmployeeItem: React.FC<IProps> = observer(props => {
  const { setEditableEmployee } = useEmployeesStore();
  const { employee } = props;
  const { firstName, lastName, email, avatar, department, role, active, index } = employee;
  const handleClickItem = useCallback(() => {
    setEditableEmployee(employee);
  }, [employee, setEditableEmployee]);

  return (
    <WhiteBlockWithShadow>
      <div className={classNames(styles.root, !active && styles.disable)} onClick={handleClickItem}>
        <div className={styles.ico}>
          <Pencil fill="#D3D3D3" />
        </div>

        <div className={styles.person}>
          <Avatar src={get(avatar, 'url', '')} size={48}>
            {firstName && firstName[0]}
          </Avatar>

          <div className={styles.personInfo}>
            <div className={styles.personName}>
              {firstName && firstName} {lastName && lastName}
              <span className={styles.personId}>ID {index}</span>
            </div>

            <div className={styles.personEmail}>{email}</div>
          </div>
        </div>

        <div className={styles.authorRight}>
          {role && <div className={styles.personType}>{role.name}</div>}
          {department && <div className={styles.personDepartment}>Отдел «{department.name}»</div>}
        </div>
      </div>
    </WhiteBlockWithShadow>
  );
});

export default EmployeeItem;
