import { notification } from 'antd';
import { action, computed, observable, ObservableMap } from 'mobx';

import { IComment, ICommentStatus } from '~common';
import { EMPTY_STRING } from '~constants';
// eslint-disable-next-line import/no-named-as-default
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotification } from '~utils/getError';

import { CommentsTransportLayer } from '../services/commentsApi';

const ALL_STATUSES_FILTER = {
  id: 'COMMENT_STATUS_ALL',
  ru: 'Все',
};

notification.config({ placement: 'bottomRight' });

export class CommentsStore {
  apiLayer = new CommentsTransportLayer();

  @observable commentsMap: ObservableMap<string, IComment> = observable.map([]);

  @observable statuses: ICommentStatus[] = [];

  @observable commentsCount = 0;

  @observable isFetching = false;

  @computed
  get comments(): IComment[] {
    return [...this.commentsMap.values()];
  }

  @computed
  get commentsFetchedCount() {
    return this.commentsMap.size;
  }

  @computed
  get isEmptyComments(): boolean {
    return this.commentsFetchedCount === 0;
  }

  @computed
  get hasMoreCommentToFetch(): boolean {
    return this.commentsFetchedCount !== this.commentsCount;
  }

  @action
  setComments = (comments: IComment[] = []) => {
    this.commentsMap.replace(
      new Map(
        comments.map(comment => {
          return [comment._id, comment];
        }),
      ),
    );
  };

  @action
  setStatuses = (statuses: ICommentStatus[]) => {
    this.statuses = statuses;
  };

  @action
  getStatus = statusId => {
    return this.statuses.find(status => status.id === statusId);
  };

  @action
  setCommentsCount = (commentsCount: number) => {
    this.commentsCount = commentsCount;
  };

  @action
  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };

  @action
  fetchComments = (defaultFilter = EMPTY_STRING) => {
    const { offset, setPageOffset } = FetchPaginationStore;
    const isNewFetch = offset === 0;
    if (isNewFetch) {
      this.setComments([]);
      this.setIsFetching(true);
      this.setCommentsCount(0);
    }
    return this.apiLayer
      .getComments(defaultFilter)
      .then(({ data: comments = [], meta }) => {
        if (isNewFetch) {
          this.setComments(comments);
        } else {
          this.setComments([...this.commentsMap.values(), ...comments]);
        }
        this.setCommentsCount(meta.count);
        setPageOffset(offset + 1);
        return Promise.resolve(comments);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки комментариев', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  fetchStatuses = () => {
    return this.apiLayer
      .getDictionaries()
      .then(({ commentStatuses = [] }) => {
        this.setStatuses([ALL_STATUSES_FILTER, ...commentStatuses]);
        return Promise.resolve(commentStatuses);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки справочников комментариев', reason);
        return Promise.reject(reason);
      });
  };

  @action
  approveComment = (commentId: string) => {
    return this.apiLayer
      .approveComment(commentId)
      .then(({ data: comment }) => {
        this.commentsMap.set(commentId, comment);
        notification.success({ message: 'Комментарий опубликован', duration: 3 });
        return Promise.resolve(comment);
      })
      .catch(reason => {
        showErrorNotification('Ошибка публикации комментария', reason);
        return Promise.reject(reason);
      });
  };

  @action
  declineComment = (commentId: string) => {
    return this.apiLayer
      .declineComment(commentId)
      .then(({ data: comment }) => {
        this.commentsMap.set(commentId, comment);
        notification.success({ message: 'Комментарий заблокирован', duration: 3 });
        return Promise.resolve(comment);
      })
      .catch(reason => {
        showErrorNotification('Ошибка блокировки комментария', reason);
        return Promise.reject(reason);
      });
  };

  @action
  editComment = (commentId: string, content: string) => {
    return this.apiLayer
      .editComment(commentId, content)
      .then(({ data: comment }) => {
        this.commentsMap.set(commentId, comment);
        notification.success({ message: 'Комментарий отредактирован', duration: 3 });
        return Promise.resolve(comment);
      })
      .catch(reason => {
        showErrorNotification('Ошибка редактирования комментария', reason);
        return Promise.reject(reason);
      });
  };

  @action
  deleteComment = (commentId: string) => {
    return this.apiLayer
      .deleteComment(commentId)
      .then(() => {
        this.setCommentsCount(this.commentsCount - 1);
        this.commentsMap.delete(commentId);
        notification.success({ message: 'Комментарий удален', duration: 3 });
      })
      .catch(reason => {
        showErrorNotification('Ошибка удаления комментария', reason);
        return Promise.reject(reason);
      });
  };

  @action
  blockUser = (commentId: string, userId: string) => {
    return this.apiLayer
      .blockUser(userId)
      .then(({ data: user }) => {
        const comment = this.commentsMap.get(commentId);
        // TODO Убрать
        comment!.createdBy = user;
        notification.success({ message: 'Пользователь заблокирован', duration: 3 });
        return Promise.resolve(user);
      })
      .catch(reason => {
        showErrorNotification('Ошибка блокировки пользователя', reason);
        return Promise.reject(reason);
      });
  };
}

export default new CommentsStore();
