import React, { Component, useCallback } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { withErrorBoundary } from '~components/ErrorBoundary';
import BlockGroup from '../BlockGroup';
import AddIcon from '~assets/icons/add.svg';

import styles from '../../styles.scss';
import { Editor } from '~components/Editor';
import { FileTransportLayer } from '~services';
import { defaultBlocks, EMPTY_STRING, QUIZ_BLOCK_TYPE, typeConstants } from '~constants';
import { editorBlockComponents } from '~pages/EditorPage/components/EditorQuiz/EditorQuiz';

@inject('PublicationStore')
@observer
class EditorTextTranslation extends Component {
  render() {
    const {
      onEditImage,
      onKeyDown,
      PublicationStore: {
        blockGroups,
        createBlockGroup,
        symbols,
        blocksArray,
        mandatoryBlocksArray,
        lockBlock,
        unlockBlock,
        createBlocksFromArray,
        updateBlock,
      },
    } = this.props;

    const onFocusBlock = ({ id = EMPTY_STRING }) => {
      lockBlock(id);
    };

    const handleUnblock = ({ id = EMPTY_STRING }) => {
      unlockBlock(id);
    };

    return (
      <>
        <Editor
          blocks={blocksArray}
          mandatoryBlocks={mandatoryBlocksArray}
          canAddBlock={false}
          onUnlockBlock={handleUnblock}
          onCreateBlocksFromArray={createBlocksFromArray}
          onFocusBlock={onFocusBlock}
          onChangeBlock={updateBlock}
          onEditImage={onEditImage}
          onKeyDown={onKeyDown}
          uploadPlaceholder={require('~assets/uploadPlaceholder.jpg')}
          uploadUrl={`${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`}
          toolbarBlocks={[QUIZ_BLOCK_TYPE]}
          defaultBlocks={[...defaultBlocks]}
          blockComponents={editorBlockComponents}
          maxSize={{
            [typeConstants.HEADER_BLOCK]: symbols.title,
            [typeConstants.IMAGE_BLOCK]: symbols.mediaCaption,
            [typeConstants.VIDEO_BLOCK]: symbols.mediaCaption,
          }}
        />
        <div className={styles.addBtn}>
          <AddIcon onClick={createBlockGroup} />
        </div>
        {React.Children.toArray(
          blockGroups.map(group => (
            <BlockGroup
              key={group._id}
              group={group}
              symbols={symbols}
              onEditImage={onEditImage}
              onKeyDown={onKeyDown}
            />
          )),
        )}
      </>
    );
  }
}

EditorTextTranslation.propTypes = {
  onEditImage: PropTypes.func,
  onKeyDown: PropTypes.func,
  PublicationStore: PropTypes.any,
};

EditorTextTranslation.defaultProps = {
  onEditImage: () => {},
  onKeyDown: () => {},
};

export default withErrorBoundary(EditorTextTranslation);
