import Color from 'color';

export const useEventTypeBackgroundColor = event => {
  const firstTypeWithColor = event?.types.find(type => type.color);

  const eventColor = firstTypeWithColor?.color;

  let background = Color('#315885')
    .alpha(0.4)
    .array();

  let border = Color('#315885').array();

  try {
    if (eventColor) {
      background = Color(eventColor)
        .alpha(0.4)
        .array();
      border = Color(eventColor).array();
    }
  } catch (error) {
    console.error('useEventTypeBackgroundColor...', `Failed to set color: ${error.message}`);
  }

  return { background: background.join(), border: border.join() };
};
