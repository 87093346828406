import 'moment/locale/ru';

import moment from 'moment';

moment.locale('ru');

// const TIME_FORMAT = 'HH:mm'
// const DATE_FORMAT = 'DD MMMM YYYY'

export const format = (date: moment.MomentInput, formatStr = 'DD.MM.YYYY') => {
  return moment(date).format(formatStr);
};

export default format;
