import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.scss'

const WhiteBlockWithShadow = ({ children }) => {
  return <div className={styles.root}>{children}</div>
}
export default WhiteBlockWithShadow

WhiteBlockWithShadow.propTypes = {
  children: PropTypes.element
}
