import { observer } from 'mobx-react';
import { always, cond, pathEq, T } from 'ramda';
import React, { ReactNode, useCallback } from 'react';

import { FilterItem } from '~common';
import { ItemWithLabel } from '~components';
import { useFilterParamsByListStore } from '~hooks';

import { CheckBox, Date, DateRange, Input, Select } from './components';

interface Props {
  item: FilterItem;

  onRemove?(item: FilterItem): void;
}

const renderItem = cond<
  { item: FilterItem; onChange(name: string, value: any): void; value: any },
  ReactNode
>([
  [pathEq(['item', 'type'], 'string'), Input],
  [pathEq(['item', 'type'], 'date'), Date],
  [pathEq(['item', 'type'], 'dateRange'), DateRange],
  [pathEq(['item', 'type'], 'select'), Select],
  [T, always(null)],
]);

export const DynamicField: React.FC<Props> = observer(props => {
  const { setFilterValue, filterParams } = useFilterParamsByListStore();
  const { item, onRemove } = props;
  const { type, name, label } = item;

  const onClick = useCallback(() => {
    onRemove && onRemove(item);
    setFilterValue(item.name, null);
  }, [item, onRemove, setFilterValue]);

  if (type === 'bool')
    return (
      <CheckBox
        onChange={setFilterValue}
        item={item}
        checked={!!filterParams[name]}
        onRemove={onClick}
      />
    );

  return (
    <ItemWithLabel onRemove={onRemove && onClick} label={label}>
      <>{renderItem({ value: filterParams[name], item, onChange: setFilterValue })}</>
    </ItemWithLabel>
  );
});
