import { get } from 'lodash';
import { lte } from 'ramda';
import { action, observable, reaction, set } from 'mobx';
import { EMPTY_ARRAY, EMPTY_STRING } from '~constants';
import { findById } from '~utils/findById';

const actualLabels = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const actualValues = [0, 8, 7, 6, 5, 4, 3, 2, 1];

export default class PostPriorityStore {
  @observable sectionPriority = 0;

  @observable selectedCategories = [];

  @observable categories = this.getFormatCategories();

  @observable selectedTags = [];

  @observable draftPriorities = null;

  constructor(publicationStore) {
    this.publicationStore = publicationStore;

    reaction(
      () => this.publicationStore.isPostPriorityHidden,
      isPostPriorityHidden => {
        if (isPostPriorityHidden) {
          this.setDraftPriorities({ section: [], tags: [], categories: [] });
        }
      },
    );
    // this.init()
  }

  @action
  setDraftPriorities(draftPriorities = null) {
    console.log('setDraftPriorities...', draftPriorities);
    this.draftPriorities = draftPriorities;
  }

  @action
  update({ draftPriorities = null, realPriorities = null }) {
    const priorities = realPriorities || draftPriorities;
    if (priorities != null) {
      const hbCategories = this.publicationStore?.dictionaries?.categories || [];
      const hbTags = this.publicationStore?.tags || [];
      const { section = [], categories = [], tags = [] } = priorities;
      const [selectedSection = null] = section;

      if (selectedSection != null) {
        this.sectionPriority = actualValues[selectedSection?.value || 0];
      }

      const mapWithTitle = (elements = EMPTY_ARRAY, hb = EMPTY_ARRAY) =>
        elements.map(element => {
          const id = element?.id || 0;
          const value = actualValues[element?.value || 0];
          const elementFromHB = findById(hb, id);
          const title = elementFromHB?.title || EMPTY_STRING;
          return {
            id,
            value,
            title,
          };
        });

      this.selectedCategories = mapWithTitle(categories, hbCategories);
      this.selectedTags = mapWithTitle(tags, hbTags);
    }
  }

  @action
  init() {
    this.sectionPriority = 0;
    this.selectedSection = {};
    this.selectedCategories = [];
    this.selectedTags = [];

    const {
      dictionaries: { categories },
      categories: selectedCategories = [],
      section,
      tags,
    } = this.publicationStore;

    if (section !== '') {
      this.selectedSection = { id: section };
    }

    if (categories.length > 0) {
      const categoriesToObj = {};
      categories.forEach(category => (categoriesToObj[category._id] = category));
      selectedCategories.forEach(selectedCategoryId => {
        const { _id: id, title } = categoriesToObj[selectedCategoryId];

        this.setSelectedCategory({ id, title });
      });
      // const { _id, alias } = allCategories.find(({ _id }) => _id === section)
      // this.setSelectedCategory()
    }

    if (tags.length > 0) {
      tags.forEach(({ _id, title }) => {
        this.setSelectedTags([...this.selectedTags, { key: _id, label: title }]);
      });
    }
  }

  getFormatCategories = () => {
    const {
      publicationStore: { dictionariesList },
    } = this;

    const categories = get(dictionariesList, 'categories', []).map(category => ({
      id: category._id,
      title: category.title,
      isSelected: false,
    }));

    return categories;
  };

  @action
  setSelectedSection = id => {
    this.publicationStore.setSection(id);
  };

  @action
  setSectionPriority = value => {
    this.sectionPriority = actualLabels[value];
  };

  @action
  removeSelectedCategory = categoryId => {
    const categoryIndex = this.categories.findIndex(category => category.id === categoryId);

    this.selectedCategories = this.selectedCategories.filter(item => item.id !== categoryId);

    if (lte(0, categoryIndex)) {
      set(this.categories[categoryIndex], {
        isSelected: false,
      });
    }
  };

  @action
  setCategoryValue = ({ id, value }) => {
    const selectedCategoryIndex = this.selectedCategories.findIndex(category => category.id === id);

    if (lte(0, selectedCategoryIndex)) {
      set(this.selectedCategories[selectedCategoryIndex], {
        value: actualLabels[value],
      });
    }
  };

  @action
  setTagValue = ({ id, value }) => {
    const selectedTagIndex = this.selectedTags.findIndex(tag => tag.key === id);

    if (lte(0, selectedTagIndex)) {
      set(this.selectedTags[selectedTagIndex], {
        value: actualLabels[value],
      });
    }
  };

  @action
  setSelectedCategory = ({ id, title }) => {
    this.selectedCategories.push({
      id,
      title,
    });

    const categoryIndex = this.categories.findIndex(category => category.id === id);

    if (lte(0, categoryIndex)) {
      set(this.categories[categoryIndex], {
        isSelected: true,
      });
    }
  };

  @action
  setSelectedTags = tag => {
    this.selectedTags = tag;
  };

  @action
  removeTag = id => {
    const tagIndex = this.selectedTags.findIndex(item => item.key === id);

    this.selectedTags.remove(this.selectedTags[tagIndex]);
  };

  @action
  savePriority = () => {
    this.setDraftPriorities({
      section: [{ id: this.publicationStore.section, value: actualValues[this.sectionPriority] }],
      tags: [], //this.selectedTags.map(({ key, value }) => ({ id: key, value: actualValues[value] })),
      categories: [], //this.selectedCategories.map(({ id, value }) => ({ id, value: actualValues[value]})),
    });
  };

  @action
  resetPriority = () => {
    this.sectionPriority = 0;
  };
}
