import { observer } from 'mobx-react';
import React from 'react';

import { ContentSpinner, EmptyContent, GridWrapper } from '~components';
import { RegionItem } from '~containers/Regions';
import { useRegionsStore } from '~hooks';

const RegionList = observer(() => {
  const { regions, isFirstLoading, isEmptyRegions } = useRegionsStore();

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  if (isEmptyRegions) return <EmptyContent text="Нет регионов" />;

  return (
    <GridWrapper gridSize={5} maxItemWidth={285}>
      {regions.map(region => (
        <RegionItem key={region._id} region={region} />
      ))}
    </GridWrapper>
  );
});

export default RegionList;
