import { observer } from 'mobx-react';
import React from 'react';

import { ISubCategory } from '~common';
import { Image, WhiteBlockWithShadow } from '~components';
import { useSubcategoriesStore } from '~hooks';

import s from './styles.scss';

const SubcategoryItem: React.FC<{ data: ISubCategory }> = observer(({ data }) => {
  const { setEditableSubcategory } = useSubcategoriesStore();

  const { title, alias, visible, cover, category } = data;

  const handleClick = () => {
    setEditableSubcategory && setEditableSubcategory(data);
  };

  return (
    <WhiteBlockWithShadow>
      <div className={s.card} onClick={handleClick}>
        <h2 className={s.cardTitle}>{title}</h2>
        <h3 className={s.cardSubtitle}>{alias}</h3>
        <h3 className={s.cardSubtitle}>Категория: {category?.title}</h3>
        <div className={s.cardItem}>
          {cover && <Image className={s.cardItemCover} url={cover?.url} alt="Cover" />}
          <div>
            <h3 className={s.cardItemTitle}>Опубликована на сайте:</h3>
            <p className={s.cardItemValue}>{visible ? 'Да' : 'Нет'}</p>
          </div>
        </div>
      </div>
    </WhiteBlockWithShadow>
  );
});

export default SubcategoryItem;
