import { Select, SelectProps } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React from 'react';

import { IDictionary } from '~common';
import { ItemWithLabel } from '~components';
import { usePublicationStore, useUserStore } from '~hooks';

import { inputStyle } from '../../components/Settings/constants';

export const RssSelect = observer(() => {
  const { rssTypes = [], rssJson: rss = [], setRss } = usePublicationStore();
  const { checkPermissions } = useUserStore();
  const options: LabeledValue[] = (rssTypes as IDictionary[])
    .filter(({ id, isActive }) => isActive || rss.includes(id))
    .map(({ id, ru }) => ({ value: id, label: ru }))
    .sort((a, b) => rss.indexOf(b.value) - rss.indexOf(a.value));

  const onChange: SelectProps<string[]>['onChange'] = rss => {
    setRss(rss);
  };

  return (
    <ItemWithLabel label="RSS">
      <Select
        style={inputStyle}
        mode="multiple"
        placeholder="Выберите RSS"
        value={rss}
        onChange={onChange}
        options={options}
        maxTagCount={1}
        defaultActiveFirstOption={false}
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
