import { observer } from 'mobx-react';
import React from 'react';

import { ContentWrapper, HeaderPageSearchBar, SettingsNavigation } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { ExperimentsList, openExperimentsModal } from '~containers/Experiments';
import { useUserStore } from '~hooks';
import { useExperimentsStore } from '~hooks/useExperimentsStore';
import s from '~pages/SettingsRedirectsPage/styles.scss';

const PAGE_TITLE = 'Все эксперименты';
const COUNT_DECLINATIONS = ['эксперимент', 'эксперимента', 'экспериментов'];
const SEARCH_BTN_TEXT = 'Найти в экспериментах';
const CREATE_BTN_TEXT = 'Добавить эксперимент';

const SettingsExperimentsPage = observer(() => {
  const {
    getExperiments,
    isFetching,
    allItemsCount,
    hasMoreExperimentItemsToFetch,
  } = useExperimentsStore();
  const { checkPermissions } = useUserStore();

  return (
    <>
      <SettingsNavigation />
      <div className={s.search}>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={checkPermissions('experiments.update') && openExperimentsModal}
          onSearch={getExperiments}
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
        >
          <InfinityScroll
            fetchItems={getExperiments}
            hasMore={hasMoreExperimentItemsToFetch}
            isFetching={isFetching}
          >
            <ExperimentsList />
          </InfinityScroll>
        </ContentWrapper>
      </div>
    </>
  );
});

export default React.memo(SettingsExperimentsPage);
