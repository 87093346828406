import { useState, useEffect } from 'react'
import moment from 'moment'

const CurrentTime = () => {
  const [time, setTime] = useState(moment().format('DD MMM YYYY HH:mm:ss'))
  const interval = setInterval(() => setTime(moment().format('DD MMM YYYY HH:mm:ss')), 1000)

  useEffect(
    () => () => {
      interval && clearInterval(interval)
    },
    []
  )

  return time
}

export default CurrentTime
