import { observer } from 'mobx-react';
import React from 'react';

import { ISection } from '~common';
import { ISelectProps, Select } from '~components';
import { useAuthorsStore } from '~hooks';

export const SectionsSelect: React.FC<ISelectProps<ISection>> = observer(props => {
  // TODO Убрать секции из AuthorsStore
  const { fetchSectionsBySearch, sectionsSearches } = useAuthorsStore();

  return (
    <Select
      {...props}
      fetch={fetchSectionsBySearch}
      options={sectionsSearches}
      keyField="_id"
      labelField="title"
    />
  );
});
