import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Switch } from 'antd';

import styles from './styles.scss';

@inject('RssStore', 'UserStore')
@observer
class RssItem extends Component {
  handleChange = checked => {
    const { item, RssStore } = this.props;

    RssStore.updateRssItem(item.id, checked);
  };

  render() {
    const { item, value, RssStore, UserStore } = this.props;

    return (
      <div className={styles.rssItem}>
        {item.ru}
        <Switch
          defaultChecked={value}
          onChange={this.handleChange}
          loading={RssStore.isRequesting}
          disabled={!UserStore.checkPermissions('global.update')}
        />
      </div>
    );
  }
}

RssItem.propTypes = {
  item: PropTypes.object,
  value: PropTypes.bool,
  RssStore: PropTypes.any,
};

export default RssItem;
