import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

import ContentWrapper from '~components/ContentWrapper';
import { FlowList, FlowsModal } from '~components/Flows';
import HeaderPageSearchBar from '~components/HeaderPageSearchBar';
import { InfinityScroll } from '~components/InfinityScroll/ts';

const PAGE_TITLE = 'Потоки';
const COUNT_DECLINATIONS = ['поток', 'потока', 'потоков'];
const SEARCH_BTN_TEXT = 'Найти в потоках';
const CREATE_BTN_TEXT = 'Добавить поток';

@inject('FlowsStore', 'FilterParamsByListStore', 'FetchPaginationStore', 'UserStore')
@observer
class FlowsPage extends Component {
  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props;

    FilterParamsByListStore.setFilterParams();
    FetchPaginationStore.resetPaginationParams();
  }

  handleSearchPublications = () => {
    const { fetchFlows, setIsFirstLoading } = this.props.FlowsStore;

    setIsFirstLoading(true);
    fetchFlows();
  };

  render() {
    const { FlowsStore, UserStore } = this.props;
    const {
      setShowModal,
      isFirstLoading,
      allItemsCount,
      fetchFlows,
      flowsLength,
      editableFlowPosts,
    } = FlowsStore;
    const { checkPermissions } = UserStore;

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={checkPermissions('flows.create') && setShowModal}
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
        >
          <InfinityScroll
            fetchItems={fetchFlows}
            hasMore={allItemsCount !== flowsLength}
            isFetching={isFirstLoading}
          >
            <FlowList />
          </InfinityScroll>
        </ContentWrapper>

        <FlowsModal editableFlowPosts={editableFlowPosts} />
      </Fragment>
    );
  }
}

export default FlowsPage;

FlowsPage.propTypes = {
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  FlowsStore: PropTypes.any,
};
