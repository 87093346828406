import { Button } from 'antd';
import arrayMove from 'array-move';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
} from 'react-sortable-hoc';

import { IQueryOrderType } from '~common';
import { ContentWrapper, GridWrapper, SettingsNavigation } from '~components';
import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow';
import { useSettingsStore, useUserStore } from '~hooks';

import s from './styles.scss';

const PAGE_TITLE = 'Порядок подбора статей';

const SettingsQueryOrderPage = observer(() => {
  const { fetchQueryOrderTypesSettings, queryOrderTypes, updateQueryOrder } = useSettingsStore();
  const { checkPermissions } = useUserStore();

  const [queryTypes, setQueryTypes] = useState<IQueryOrderType[]>([]);

  useEffect(() => {
    fetchQueryOrderTypesSettings();
  }, []);

  useEffect(() => {
    setQueryTypes(queryOrderTypes);
  }, [queryOrderTypes]);

  const changeOrder = ({ oldIndex, newIndex }) => {
    const reorderedArray = arrayMove(queryTypes, oldIndex, newIndex);
    setQueryTypes(reorderedArray);
  };

  const handleSetNewOrder = () => {
    updateQueryOrder(queryTypes).then(() => {
      console.log('success');
    });
  };

  const SortableItem = sortableElement(({ type }) => {
    return (
      <div className={s.typeItem}>
        <WhiteBlockWithShadow>
          <>
            #{type.index + 1} {type.ru}
          </>
        </WhiteBlockWithShadow>
      </div>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <GridWrapper gridSize={1} maxItemWidth={285} listView>
        {children}
      </GridWrapper>
    );
  });

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        <SortableContainer
          distance={1}
          axis="xy"
          onSortEnd={checkPermissions('queryOrder.update') ? changeOrder : undefined}
        >
          {queryTypes &&
            queryTypes.map((type, index) => (
              <SortableItem type={{ ...type, index }} index={index} />
            ))}
        </SortableContainer>
        <Button
          onClick={handleSetNewOrder}
          type="primary"
          className={s.setOrderButton}
          disabled={!checkPermissions('queryOrder.update')}
        >
          Утвердить порядок
        </Button>
      </ContentWrapper>
    </>
  );
});

export default React.memo(SettingsQueryOrderPage);
