const labelStye = {
  color: '#9A9A9A',
  fontSize: 10,
  marginLeft: -29
}

export const actualValues = [0, 8, 7, 6, 5, 4, 3, 2, 1]

export const MARKS = {
  0: {
    style: labelStye,
    label: 0
  },
  1: {
    style: labelStye,
    label: 8
  },
  2: {
    style: labelStye,
    label: 7
  },
  3: {
    style: labelStye,
    label: 6
  },
  4: {
    style: labelStye,
    label: 5
  },
  5: {
    style: labelStye,
    label: 4
  },
  6: {
    style: labelStye,
    label: 3
  },
  7: {
    style: labelStye,
    label: 2
  },
  8: {
    style: labelStye,
    label: 1
  }
}
