import React, { FC } from 'react';

interface IHidden {
  isHidden: boolean;
}

export const Hidden: FC<IHidden> = props => {
  const { isHidden = false, children } = props;
  return isHidden ? null : <>{children}</>;
};
