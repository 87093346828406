import { Switch, Tooltip } from 'antd';
import arrayMove from 'array-move';
import axios from 'axios';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { append, filter, map, pipe, propOr, update } from 'ramda';
import React, { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { IBlock, IFile, IMeta } from '~common';
import { useFlagsStore } from '~hooks';
import { Close, Draggable, Plus } from '~icons';
import { FileTransportLayer } from '~services';
import { isNotNil, showErrorNotification } from '~utils';

import { EditorContext } from '../../Editor';
import { BlockWrapper } from '../BlockWrapper';
import { ImageBlock } from '../ImageBlock';
import styles from './styles.scss';

const imageBlockProps = {
  blockProps: {
    title: '',
    classNames: {
      head: styles.imageBlockHead,
      footer: styles.imageBlockHead,
      block: styles.imageBlock,
      content: styles.imageBlockContent,
      contentWrapper: styles.imageBlockContentWrapper,
    },
  },
};

const DragHandle = SortableHandle(() => (
  <span style={{ width: 20 }}>
    <Draggable />
  </span>
));

const SortableItem = SortableElement(props => {
  const {
    value: { image, index },
    onChangeImage,
    onClickDelete,
    onChangeProgressImage,
    isFetching = false,
    isEditing = false,
    status,
  } = props;

  const stopBubbling = e => {
    e.stopPropagation();
  };

  return (
    <div className={classNames(styles.draggable, isFetching && styles.disabled)}>
      <div className={styles.header}>
        <DragHandle />
        <div className={styles.deleteBtn} onClick={() => onClickDelete(index)}>
          <Close />
        </div>
      </div>
      <ImageBlock
        {...image}
        isInGallery
        onChange={data => {
          onChangeImage({ index, data });
        }}
        onProgressChange={onChangeProgressImage}
        status={status}
        blockProps={{
          title: '',
          classNames: {
            block: styles.imageBlock,
            content: styles.imageBlockContent,
            contentWrapper: styles.imageBlockContentWrapper,
          },
        }}
        isEditing={isEditing}
      />
    </div>
  );
});

const SortableList = SortableContainer(props => {
  const {
    items,
    addImage,
    onChangeImage,
    onClickDelete,
    onChangeProgressImage,
    isFetching,
    isEditing,
  } = props;
  return (
    <div className={styles.grid}>
      {items.map((image, index) =>
        typeof image === 'string' ? (
          <div
            key={`item-${index}-${image}`}
            onClick={addImage}
            className={classNames(styles.addBtn, isFetching && styles.disabled)}
          >
            <Plus />
          </div>
        ) : (
          <SortableItem
            onChangeImage={onChangeImage}
            onChangeProgressImage={onChangeProgressImage}
            onClickDelete={onClickDelete}
            key={`item-${index}`}
            index={index}
            value={{ image, index }}
            status={image.status}
            isFetching={isFetching}
            isEditing={isEditing}
          />
        ),
      )}
    </div>
  );
});

const accept = 'image/jpeg, image/png, image/gif';

const getJobsId = pipe<IFile[], IFile[], number[]>(
  filter<IFile>(pipe(propOr(null, 'jobId'), isNotNil)),
  map<IFile, number>(propOr(null, 'jobId')),
);

const Droppable = observer(({ children, onChange, uploadUrl, accept }) => {
  const { fileUploadWorker } = useFlagsStore();

  const checkStatus = useCallback(
    async (images: IFile[]) => {
      const { finishedOn, failedReason, progress, returnvalue: response, id } =
        (await FileTransportLayer.checkStatus(...getJobsId(images))) || {};

      let result: IFile[] = [];

      if (failedReason) {
        console.error(failedReason);
        // eslint-disable-next-line no-param-reassign
        result = images.filter(({ jobId }) => jobId !== id);
        showErrorNotification('Ошибка загрузки файла', failedReason);
      } else if (finishedOn) {
        // eslint-disable-next-line no-param-reassign
        result = images.map(file => (file.jobId && file.jobId === id ? response : file));
      } else {
        // eslint-disable-next-line no-param-reassign
        result = images.map(file =>
          file.jobId && file.jobId === id ? { ...file, status: progress } : file,
        );
      }

      onChange && onChange(result);
      if (getJobsId(result).length) {
        await checkStatus(result);
      }
    },
    [onChange],
  );

  const onDrop = useCallback(
    async acceptedFiles => {
      const promises: Promise<any>[] = [];

      acceptedFiles.forEach(file => {
        const [type] = file.type.split('/');
        const token = localStorage.getItem('token');
        const headers = new Headers();

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }

        if (accept.indexOf(type) > -1) {
          const data = new FormData();
          data.append('file', file);

          promises.push(axios.post(uploadUrl, data, { headers, withCredentials: true }));
        }
      });

      if (fileUploadWorker) {
        await Promise.all(promises)
          .then(res => {
            return res.map(({ data: { data } }) => ({
              status: data.progress,
              jobId: data.id,
            }));
          })
          .then(newImages => {
            checkStatus(newImages);
            onChange(newImages);
          })
          .catch(e => {
            console.error(e);
            return [];
          });
      } else {
        const responses: any[] = await Promise.all(promises)
          .then(res => {
            return res.map(({ data: { data } }) => data);
          })
          .catch(e => {
            console.error(e);
            return [];
          });

        const newImages = responses.map(image => ({
          ...imageBlockProps,
          ...image,
        }));

        onChange(newImages);
      }
    },
    [fileUploadWorker, accept, uploadUrl, checkStatus, onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    noClick: true,
  });

  return (
    <div style={{ outline: 'none' }} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
});

type OnChangeData = {
  charactersCount: number;
  images: IFile[];
  isVertical: boolean;
  meta: IMeta;
};

interface IProps extends IBlock {
  blockTitle?: string;
  header?: ComponentType;
  images?: IFile[];
  isVertical?: boolean;
  onChange?(data: OnChangeData): void;
  placeholder?: string;
  uploadUrl?: string;
}

const GalleryBlock: React.FC<IProps> = props => {
  const { uploadUrl: defaultUploadUrl = '' } = useContext(EditorContext);
  const { onChange, uploadUrl = defaultUploadUrl, isEditing = false } = props;
  const [isVertical, setIsVertical] = useState(!!props.isVertical);
  const [isFetching, setIsFetching] = useState(false);

  const images = useMemo(() => props.images || [], [props]);

  useEffect(() => {
    setIsVertical(!!props.isVertical);
  }, [props.isVertical]);

  const saveData = useCallback(
    (images: IFile[]) => {
      onChange &&
        onChange({
          images,
          isVertical,
          charactersCount: images.reduce(
            (acc, { charactersCount = 0 }) => acc + charactersCount,
            0,
          ),
          meta: { blockId: props.id },
        });
    },
    [isVertical, onChange, props.id],
  );

  const onChangeImage = useCallback(
    ({ index, data }) => {
      const newImages = update(index, { ...images[index], ...data }, images);
      saveData(newImages);
    },
    [images, saveData],
  );

  const onChangeOrientation = useCallback(
    (isVertical: boolean) => {
      setIsVertical(isVertical);
      onChange &&
        onChange({
          images,
          charactersCount: images.reduce(
            (acc, { charactersCount = 0 }) => acc + charactersCount,
            0,
          ),
          meta: { blockId: props.id },
          isVertical,
        });
    },
    [images, onChange, props.id],
  );

  const addImage = useCallback(
    e => {
      e.stopPropagation();
      const newImages = append({ ...imageBlockProps, url: '', alt: '' }, images);
      saveData(newImages);
      setIsFetching(false);
    },
    [images, saveData],
  );

  const onClickDelete = useCallback(
    (index: number) => {
      const newImages = images.filter((image, idx) => idx !== index);
      saveData(newImages);
    },
    [images, saveData],
  );

  const onChangeProgressImage = useCallback((progress: number) => {
    setIsFetching(progress > 0);
  }, []);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      saveData(arrayMove(images, oldIndex, newIndex));
    },
    [images, saveData],
  );

  const onDrop = useCallback(
    newImages => {
      saveData([...images, ...newImages]);
    },
    [images, saveData],
  );

  const { blockProps, blockTitle = 'Галерея', header } = props;

  return (
    <BlockWrapper
      {...blockProps}
      title={blockTitle}
      header={
        <div className={styles.title}>
          <div className={styles.isVertical}>
            <Tooltip title={isVertical ? 'Вертикальная' : 'Горизонтальная'}>
              <Switch
                onClick={onChangeOrientation}
                checked={isVertical}
                disabled={blockProps?.blocked || isFetching}
                checkedChildren="В"
                unCheckedChildren="Г"
              />
            </Tooltip>
          </div>
        </div>
      }
      classNames={{ contentWrapper: styles.wrapper }}
    >
      <Droppable onChange={onDrop} accept={accept} uploadUrl={uploadUrl}>
        {header && header}

        <SortableList
          useDragHandle
          axis="xy"
          items={[...images, 'add']}
          addImage={addImage}
          onChangeImage={onChangeImage}
          onChangeProgressImage={onChangeProgressImage}
          onClickDelete={onClickDelete}
          onSortEnd={onSortEnd}
          isFetching={isFetching}
          isEditing={isEditing}
        />
      </Droppable>
    </BlockWrapper>
  );
};

export default GalleryBlock;
