import { string, object, number } from 'yup'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const compiledFlowSchema = object()
  .default(undefined)
  .shape({
    _id: string().required(),
    alias: string(),
    createdAt: string(),
    index: number(),
    title: string(),
    updatedAt: string()
  })

export const isValidCompiledFlow = isValidBySchema(compiledFlowSchema)
