import { string, object, number } from 'yup'
import { compiledFileSchema } from '~schemas/file'
import { departmentSchema } from '~schemas/department'
import { socialsSchema } from '~schemas/socials'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const compiledAuthorSchema = object()
  .default(undefined)
  .shape({
    _id: string().required(),
    avatar: compiledFileSchema.nullable(true),
    bio: string(),
    createdAt: string(),
    department: departmentSchema,
    email: string(),
    index: number(),
    name: string(),
    socials: socialsSchema,
    updatedAt: string()
  })

export const isValidCompiledAuthor = isValidBySchema(compiledAuthorSchema)
