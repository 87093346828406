import React, { Component } from 'react'
import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import { InfoPagesModal, InfoPagesList } from '~components/InfoPages'

const PAGE_TITLE = 'Настройки управлению информационными страницами'

class SettingsInfoPagesPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <InfoPagesList />
        </ContentWrapper>
        <InfoPagesModal />
      </>
    )
  }
}

export default SettingsInfoPagesPage
