import { action, computed, observable, reaction } from 'mobx';
import { equals, isEmpty } from 'ramda';

import API from '~api';
import { Maybe } from '~common';

import { IFlags } from './FlagsStore';
import NotificationsStore from './NotificationsStore';
import UserStore from './UserStore';

interface IStatus {
  commit?: string;
  flags?: Partial<IFlags>;
  name?: string;
  notifications?: Array<unknown>;
  settings?: {
    advDisabled: boolean;
    limitTitlesDate: string;
    techMaintenance: boolean;
  };
  started?: string;
  version?: string;
}

export class StatusStore {
  @observable
  status: IStatus = {};

  @observable
  isChanged = false;

  @observable
  isChangedFlags = false;

  @observable
  timeout: Maybe<NodeJS.Timeout> = null;

  constructor() {
    if (UserStore.isAuthenticated && NotificationsStore.isWindowFocused) {
      this.fetchStatus();
    }
    reaction(
      () => {
        return {
          isAuthenticated: UserStore.isAuthenticated,
          isWindowFocused: NotificationsStore.isWindowFocused,
        };
      },
      ({ isAuthenticated, isWindowFocused }) => {
        if (isAuthenticated && isWindowFocused) {
          this.fetchStatus();
        } else {
          this.timeout && clearTimeout(this.timeout);
        }
      },
    );
  }

  @action
  fetchStatus() {
    return API.get('info')
      .then(res => {
        const { data } = res;

        this.setStatus(data);
      })
      .finally(() => {
        // @ts-ignore
        this.timeout = setTimeout(this.fetchStatus.bind(this), 10000);
      });
  }

  @action
  setStatus(status) {
    if (
      !isEmpty(this.status) &&
      // @ts-ignore
      !equals(this.status?.version, status?.version)
    ) {
      console.log('this.status.version', this.status?.version);
      console.log('status.version', status?.version);
      this.setIsChanged(true);
    }

    if (!isEmpty(this.status) && !equals(this.status?.flags, status?.flags)) {
      this.setIsChangedFlags(true);
    }

    this.status = status;
  }

  @action
  setIsChanged(isChanged) {
    this.isChanged = isChanged;
  }

  @action
  setIsChangedFlags(isChangedFlags) {
    this.isChangedFlags = isChangedFlags;
  }

  @computed
  get notifications() {
    return this.status?.notifications || [];
  }
}

export default new StatusStore();
