import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import moment from 'moment'

import Modal from '~components/Modal'

import styles from './styles.scss'

@inject(stores => {
  const {
    isShowModal,
    setSelectedEvents,
    selectedEvents,
    selectedEventsDate
  } = stores.ScheduleStore

  return {
    isShowModal,
    setSelectedEvents,
    selectedEvents,
    selectedEventsDate
  }
})
@observer
class EventsModal extends Component {
  handleClose = () => {
    const { setSelectedEvents } = this.props

    setSelectedEvents([])
  }

  render() {
    const { isShowModal, selectedEvents, selectedEventsDate } = this.props

    const titleDateFormat = moment(selectedEventsDate).format('DD MMMM')

    return (
      <Modal
        destroyOnClose
        width={1100}
        onClose={this.handleClose}
        isVisible={isShowModal}
        title={`Съемки на ${titleDateFormat}`}
        afterClose={this.handleClose}
      >
        <div className={styles.root}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Запланировано 3 съемки:</div>
          </div>

          {selectedEvents.map((event, index) => (
            <div className={styles.eventItem} key={index}>
              <div className={styles.title}>{event.title}</div>
              <div className={styles.time}>14:00 - 15:00</div>
            </div>
          ))}
        </div>
      </Modal>
    )
  }
}

export default EventsModal

EventsModal.propTypes = {
  isShowModal: PropTypes.bool,
  selectedEvents: PropTypes.func,
  selectedEventsDate: PropTypes.func,
  setSelectedEvents: PropTypes.func
}
