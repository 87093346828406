import { observer } from 'mobx-react';
import React from 'react';

import { ContentSpinner, EmptyContent, GridWrapper } from '~components';
import { useFeedbackStore } from '~hooks';

import { FeedbackItem } from '../FeedbackItem';

const FeedbackList = observer(() => {
  const { feedbacks, dictionaries, isFirstLoading, feedbacksLength } = useFeedbackStore();

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  if (!feedbacksLength) return <EmptyContent text="Нет писем" />;

  return (
    <GridWrapper gridSize={2}>
      {feedbacks &&
        feedbacks.map(feedback => (
          <FeedbackItem key={feedback._id} dictionaries={dictionaries} feedback={feedback} />
        ))}
    </GridWrapper>
  );
});

export default FeedbackList;
