import { string, object } from 'yup'
import { EMPTY_STRING } from '~constants/index'
import { POST_TYPES } from '~enums/posts'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const typeSchema = object()
  .default({ id: EMPTY_STRING })
  .shape({
    id: string().oneOf(POST_TYPES),
    ru: string()
  })

export const isValidType = isValidBySchema(typeSchema)
