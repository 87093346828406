import { Empty } from 'antd';
import React from 'react';

import styles from './styles.scss';

interface IProps {
  text: string;
}

const EmptyContent: React.FC<IProps> = ({ text }) => (
  <div className={styles.root}>
    <Empty description={text} />
  </div>
);

export default React.memo(EmptyContent);
