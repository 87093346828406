import React, { useCallback, useEffect, useMemo } from 'react';
import { noop } from 'lodash';
import { observer } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, message, Radio, Select } from 'antd';

import DeleteButton from '~components/DeleteButton';
import ItemWithLabel from '~components/ItemWithLabel';
import Modal from '~components/Modal';
import { ButtonUpload } from '~components';
import { useSpecProjectsStore } from '~hooks/useSpecProjectsStore';
import s from './styles.scss';
import styles from '~pages/EditorPage/components/Blocks/CustomBlock/styles.scss';
import { useFetchPaginationStore, useUserStore } from '~hooks';

const { Option } = Select;

const CREATE_TITLE = 'Создание спец.проекта';
const UPDATE_TITLE = 'Редактирование спец.проекта';

export const SpecProjectsModal = Form.create({ name: 'specProjectForm' })(
  observer(props => {
    const {
      form: {
        setFieldsValue = noop,
        getFieldDecorator = noop,
        getFieldsValue = noop,
        validateFields = noop,
        resetFields,
      } = {},
    } = props;
    const {
      specProjects,
      setHideModal,
      isShowModal,
      resetEditableSpecProjects,
      editableSpecProjects,
      isActionModal,
      deleteSpecProjects,
      dictionaries,
      updateSpecProjects,
      createSpecProjects,
      fetchSpecProjects,
      currentSpecTabFilter,
      currentSpecTabSection,
    } = useSpecProjectsStore();
    const { checkPermissions } = useUserStore();

    const { resetPaginationParams } = useFetchPaginationStore();

    const formData = getFieldsValue();
    const coverValue = useMemo(() => editableSpecProjects?.cover, [editableSpecProjects]);

    /**
     * Обработка загруженнего файла обложки
     */
    useEffect(() => {
      if (coverValue) {
        setFieldsValue({
          cover: coverValue,
        });
      }
      return () => {
        setFieldsValue({
          cover: null,
        });
      };
    }, [coverValue]);

    const ModalTitle = editableSpecProjects ? UPDATE_TITLE : CREATE_TITLE;

    const onChange = useCallback(cover => {
      setFieldsValue({ cover });
    }, []);

    const fileUploadProps = {
      data: { type: 'card' },
      accept: 'image/*',
      showUploadList: false,
    };

    const handleSubmit = async e => {
      e.preventDefault();

      const formData = getFieldsValue();
      await validateFields();

      try {
        formData.cover = formData.cover._id || formData.cover.file.response.data._id;
      } catch (e) {
        message.error('Обложка обязательна!');
        return;
      }

      const spec = {
        data: { ...formData },
        meta: {
          before: specProjects.length > 0 ? specProjects[0]?._id : null,
        },
      };
      const action = editableSpecProjects ? updateSpecProjects : createSpecProjects;

      action(spec).then(() => {
        resetPaginationParams();
        fetchSpecProjects(currentSpecTabFilter);
      });
    };

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={ModalTitle}
        afterClose={() => {
          resetFields && resetFields(['cover']);
          resetEditableSpecProjects && resetEditableSpecProjects();
        }}
      >
        <Form onSubmit={handleSubmit}>
          <ItemWithLabel label="Название спец.проекта">
            <Form.Item>
              {getFieldDecorator('title', {
                initialValue: editableSpecProjects && editableSpecProjects.title,
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(<Input placeholder="Введите название" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Описание спец.проекта">
            <Form.Item>
              {getFieldDecorator('description', {
                initialValue: editableSpecProjects && editableSpecProjects.description,
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(<Input.TextArea />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Тип материала">
            <Form.Item>
              {getFieldDecorator('type', {
                initialValue: editableSpecProjects?.type,
                valuePropName: 'value',
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(
                <Radio.Group>
                  {dictionaries.map(item => (
                    <Radio value={item?.id} key={item?.id}>
                      {item?.ru}
                    </Radio>
                  ))}
                </Radio.Group>,
              )}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Ссылка на спец.проект">
            <Form.Item>
              {getFieldDecorator('url', {
                initialValue: editableSpecProjects && editableSpecProjects.url,
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(<Input placeholder="Ссылка на спец.проект" disabled={isActionModal} />)}
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Обложка">
            {formData && formData.cover && formData.cover.url && (
              <img className={styles.image} src={formData.cover.url} alt="" />
            )}

            <Form.Item>
              {getFieldDecorator('cover', {
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(<ButtonUpload onChange={onChange} uploadProps={fileUploadProps} />)}
            </Form.Item>
          </ItemWithLabel>

          <Form.Item>
            {getFieldDecorator('visible', {
              initialValue: (editableSpecProjects && editableSpecProjects.visible) || false,
              valuePropName: 'checked',
            })(<Checkbox>Виден на сайте</Checkbox>)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('flags.isMain', {
              initialValue: editableSpecProjects?.flags?.isMain || false,
              valuePropName: 'checked',
            })(<Checkbox>Главный проект</Checkbox>)}
          </Form.Item>
          <ItemWithLabel label="Раздел спецпроекта">
            <Form.Item>
              {getFieldDecorator('section', {
                initialValue:
                  editableSpecProjects?.section ||
                  currentSpecTabSection ||
                  'SPECIAL_PROJECT_COMMON',
                valuePropName: 'value',
              })(
                <Select>
                  <Option key={'SPECIAL_PROJECT_COMMON'} label={'Общий'}>
                    Общий
                  </Option>
                  <Option key={'SPECIAL_PROJECT_SHOT_CHECK'} label={'SHOT-проверка'}>
                    SHOT-проверка
                  </Option>
                </Select>,
              )}
            </Form.Item>
          </ItemWithLabel>

          <div className={s.modalControls}>
            <Button
              htmlType="submit"
              type="primary"
              disabled={
                isActionModal ||
                !checkPermissions(`specProjects.${editableSpecProjects ? 'update' : 'create'}`)
              }
            >
              Сохранить изменения
            </Button>
            {editableSpecProjects && (
              <div className={s.deleteBtnWrapper}>
                <DeleteButton
                  text="Удалить спец.проект"
                  disabled={isActionModal || !checkPermissions('specProjects.delete')}
                  onDelete={deleteSpecProjects}
                />
              </div>
            )}
          </div>
        </Form>
      </Modal>
    );
  }),
);
