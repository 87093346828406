import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists.js';

const API_URL_COMMENTS = '/comments';
const API_URL_USERS = '/users';
const API_URL_DICTIONARIES = '/dictionaries/comment';

const getData = ({ data }) => data;

export class CommentsTransportLayer {
  getComments = (defaultFilter = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: API_URL_COMMENTS, defaultFilter });
    return API.get(promiseUrl).then(getData);
  };

  getDictionaries = () => {
    return API.get(`${API_URL_DICTIONARIES}`).then(getData);
  };

  approveComment = (commentId: string) => {
    return API.patch(`${API_URL_COMMENTS}/${commentId}`, {
      data: { status: 'COMMENT_STATUS_APPROVED' },
    }).then(getData);
  };

  declineComment = (commentId: string) => {
    return API.patch(`${API_URL_COMMENTS}/${commentId}`, {
      data: { status: 'COMMENT_STATUS_DECLINED' },
    }).then(getData);
  };

  editComment = (commentId: string, content: string) => {
    return API.patch(`${API_URL_COMMENTS}/${commentId}`, {
      data: { content },
    }).then(getData);
  };

  deleteComment = (commentId: string) => {
    return API.delete(`${API_URL_COMMENTS}/${commentId}`).then(getData);
  };

  blockUser = (userId: string) => {
    return API.post(`${API_URL_USERS}/${userId}/ban`).then(getData);
  };
}
