/*
 MIT
 @namespace TraceKit
*/
/* eslint-disable */
;(function(d, F) {
  function D(h) {
    return typeof h === 'undefined'
  }
  if (d) {
    const h = {}
    const J = d.TraceKit
    const M = [].slice
    const N = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?(.*)$/
    h.noConflict = function() {
      d.TraceKit = J
      return h
    }
    h.wrap = function(d) {
      return function() {
        try {
          return d.apply(this, arguments)
        } catch (u) {
          throw (h.report(u), u)
        }
      }
    }
    h.report = (function() {
      function m(x, a, b) {
        let e = null
        if (!a || h.collectWindowErrors) {
          for (const c in y)
            if (Object.prototype.hasOwnProperty.call(y, c))
              try {
                y[c](x, a, b)
              } catch (f) {
                e = f
              }
          if (e) throw e
        }
      }
      function u(x, a, b, e, c) {
        if (z) h.computeStackTrace.augmentStackTraceWithInitialElement(z, a, b, x), v()
        else if (c) {
          var f = h.computeStackTrace(c)
          m(f, !0, c)
        } else {
          f = { url: a, line: b, column: e }
          let d = x
          if ({}.toString.call(x) === '[object String]') {
            const K = x.match(N)
            if (K) {
              var k = K[1]
              d = K[2]
            }
          }
          f.func = h.computeStackTrace.guessFunctionName(f.url, f.line)
          f.context = h.computeStackTrace.gatherContext(f.url, f.line)
          f = { name: k, message: d, mode: 'onerror', stack: [f] }
          m(f, !0, null)
        }
        return B ? B.apply(this, arguments) : !1
      }
      function C(d) {
        const a = h.computeStackTrace(d.reason)
        m(a, !0, d.reason)
      }
      function v(d) {
        d = d === F ? !1 : d
        const a = z
        const b = w
        w = z = null
        if (d) return JSON.stringify({ errorReport: a })
        m(a, !1, b)
      }
      function E(d, a) {
        a = a === F ? !1 : a
        if (z) {
          if (w === d) return
          var b = v(a)
          if (a) return b
        }
        z = h.computeStackTrace(d)
        w = d
        if (w === d && ((b = v(a)), a)) return b
        throw d
      }
      var y = []
      var w = null
      var z = null
      let B
      let A
      let G
      let t
      E.subscribe = function(h) {
        !0 !== A && ((B = d.onerror), (d.onerror = u), (A = !0))
        !0 !== t && ((G = d.onunhandledrejection), (d.onunhandledrejection = C), (t = !0))
        y.push(h)
      }
      E.unsubscribe = function(h) {
        for (let a = y.length - 1; a >= 0; --a) y[a] === h && y.splice(a, 1)
        y.length === 0 &&
          (A && ((d.onerror = B), (A = !1)), t && ((d.onunhandledrejection = G), (t = !1)))
      }
      return E
    })()
    h.computeStackTrace = (function() {
      function m(a) {
        if (typeof a !== 'string') return []
        if (!Object.prototype.hasOwnProperty.call(x, a)) {
          let b = ''
          let e = ''
          try {
            e = d.document.domain
          } catch (L) {}
          const c = /(.*):\/\/([^:\/]+)([:\d]*)\/{0,1}([\s\S]*)/.exec(a)
          if (c && c[2] === e)
            if (h.remoteFetching)
              try {
                try {
                  var f = new d.XMLHttpRequest()
                } catch (L) {
                  f = new d.ActiveXObject('Microsoft.XMLHTTP')
                }
                f.open('GET', a, !1)
                f.send('')
                b = f.responseText
              } catch (L) {
                b = ''
              }
            else b = ''
          x[a] = b ? b.split('\n') : []
        }
        return x[a]
      }
      function u(a, b) {
        const e = /function ([^(]*)\(([^)]*)\)/
        const c = /['"]?([0-9A-Za-z$_]+)['"]?\s*[:=]\s*(function|eval|new Function)/
        let f = ''
        a = m(a)
        let d
        if (!a.length) return '?'
        for (let h = 0; h < 10; ++h)
          if (((f = a[b - h] + f), !D(f) && ((d = c.exec(f)) || (d = e.exec(f))))) return d[1]
        return '?'
      }
      function C(a, b) {
        a = m(a)
        if (!a.length) return null
        const e = []
        const c = Math.floor(h.linesOfContext / 2)
        const f = Math.min(a.length, b + (c + (h.linesOfContext % 2)) - 1)
        for (b = Math.max(0, b - c - 1); b < f; ++b) D(a[b]) || e.push(a[b])
        return e.length > 0 ? e : null
      }
      function v(a) {
        return a.replace(/[\-\[\]{}()*+?.,\\\^$|#]/g, '\\$&')
      }
      function E(a) {
        return v(a)
          .replace('<', '(?:<|&lt;)')
          .replace('>', '(?:>|&gt;)')
          .replace('&', '(?:&|&amp;)')
          .replace('"', '(?:"|&quot;)')
          .replace(/\s+/g, '\\s+')
      }
      function y(a, b) {
        for (var e, c, f = 0, d = b.length; f < d; ++f)
          if ((e = m(b[f])).length && ((e = e.join('\n')), (c = a.exec(e))))
            return {
              url: b[f],
              line: e.substring(0, c.index).split('\n').length,
              column: c.index - e.lastIndexOf('\n', c.index) - 1
            }
        return null
      }
      function w(a, b, e) {
        b = m(b)
        a = new RegExp(`\\b${v(a)}\\b`)
        let c
        --e
        return b && b.length > e && (c = a.exec(b[e])) ? c.index : null
      }
      function z(a) {
        if (!D(d && d.document)) {
          const b = [d.location.href]
          let e = d.document.getElementsByTagName('script')
          a = `${a}`
          let c
          for (c = 0; c < e.length; ++c) {
            var f = e[c]
            f.src && b.push(f.src)
          }
          ;(e = /^function(?:\s+([\w$]+))?\s*\(([\w\s,]*)\)\s*\{\s*(\S[\s\S]*\S)\s*\}\s*$/.exec(a))
            ? ((c = e[1] ? `\\s+${e[1]}` : ''),
              (f = e[2].split(',').join('\\s*,\\s*')),
              (e = v(e[3]).replace(/;$/, ';?')),
              (c = new RegExp(`function${c}\\s*\\(\\s*${f}\\s*\\)\\s*{\\s*${e}\\s*}`)))
            : (c = new RegExp(v(a).replace(/\s+/g, '\\s+')))
          if ((c = y(c, b))) return c
          if ((e = /^function on([\w$]+)\s*\(event\)\s*\{\s*(\S[\s\S]*\S)\s*\}\s*$/.exec(a))) {
            a = e[1]
            e = E(e[2])
            c = new RegExp(`on${a}=[\\'"]\\s*${e}\\s*[\\'"]`, 'i')
            if ((c = y(c, b[0]))) return c
            c = new RegExp(e)
            if ((c = y(c, b))) return c
          }
          return null
        }
      }
      function B(a) {
        if (!a.stack) return null
        for (
          var b = /^\s*at (.*?) ?\(((?:file|https?|blob|chrome-extension|native|eval|webpack|<anonymous>|\/).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i,
            e = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|\[native).*?|[^@]*bundle)(?::(\d+))?(?::(\d+))?\s*$/i,
            c = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i,
            f,
            d = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i,
            h = /\((\S*)(?::(\d+))(?::(\d+))\)/,
            k = a.stack.split('\n'),
            n = [],
            l,
            g,
            r = /^(.*) is undefined$/.exec(a.message),
            q = 0,
            p = k.length;
          q < p;
          ++q
        ) {
          if ((g = b.exec(k[q]))) {
            const m = g[2] && g[2].indexOf('native') === 0
            ;(f = g[2] && g[2].indexOf('eval') === 0) &&
              (l = h.exec(g[2])) &&
              ((g[2] = l[1]), (g[3] = l[2]), (g[4] = l[3]))
            f = {
              url: m ? null : g[2],
              func: g[1] || '?',
              args: m ? [g[2]] : [],
              line: g[3] ? +g[3] : null,
              column: g[4] ? +g[4] : null
            }
          } else if ((g = c.exec(k[q])))
            f = { url: g[2], func: g[1] || '?', args: [], line: +g[3], column: g[4] ? +g[4] : null }
          else if ((g = e.exec(k[q])))
            (f = g[3] && g[3].indexOf(' > eval') > -1) && (l = d.exec(g[3]))
              ? ((g[3] = l[1]), (g[4] = l[2]), (g[5] = null))
              : q !== 0 || g[5] || D(a.columnNumber) || (n[0].column = a.columnNumber + 1),
              (f = {
                url: g[3],
                func: g[1] || '?',
                args: g[2] ? g[2].split(',') : [],
                line: g[4] ? +g[4] : null,
                column: g[5] ? +g[5] : null
              })
          else continue
          !f.func && f.line && (f.func = u(f.url, f.line))
          f.context = f.line ? C(f.url, f.line) : null
          n.push(f)
        }
        if (!n.length) return null
        n[0] && n[0].line && !n[0].column && r && (n[0].column = w(r[1], n[0].url, n[0].line))
        return { mode: 'stack', name: a.name, message: a.message, stack: n }
      }
      function A(a, b, e, c) {
        b = { url: b, line: e }
        if (b.url && b.line) {
          a.incomplete = !1
          b.func || (b.func = u(b.url, b.line))
          b.context || (b.context = C(b.url, b.line))
          if ((c = / '([^']+)' /.exec(c))) b.column = w(c[1], b.url, b.line)
          if (a.stack.length > 0 && a.stack[0].url === b.url) {
            if (a.stack[0].line === b.line) return !1
            if (!a.stack[0].line && a.stack[0].func === b.func)
              return (a.stack[0].line = b.line), (a.stack[0].context = b.context), !1
          }
          a.stack.unshift(b)
          return (a.partial = !0)
        }
        a.incomplete = !0
        return !1
      }
      function G(a, b) {
        for (
          var e = /function\s+([_$a-zA-Z\xA0-\uFFFF][_$a-zA-Z0-9\xA0-\uFFFF]*)?\s*\(/i,
            c = [],
            f = {},
            d = !1,
            m,
            k,
            n,
            l = G.caller;
          l && !d;
          l = l.caller
        )
          if (l !== t && l !== h.report) {
            k = { url: null, func: '?', args: [], line: null, column: null }
            if (l.name) k.func = l.name
            else if ((m = e.exec(l.toString()))) k.func = m[1]
            if (typeof k.func === 'undefined')
              try {
                k.func = m.input.substring(0, m.input.indexOf('{'))
              } catch (r) {}
            if ((n = z(l))) {
              k.url = n.url
              k.line = n.line
              k.func === '?' && (k.func = u(k.url, k.line))
              const g = / '([^']+)' /.exec(a.message || a.description)
              g && (k.column = w(g[1], n.url, n.line))
            }
            f[`${l}`] ? (d = !0) : (f[`${l}`] = !0)
            c.push(k)
          }
        b && c.splice(0, b)
        b = { mode: 'callers', name: a.name, message: a.message, stack: c }
        A(b, a.sourceURL || a.fileName, a.line || a.lineNumber, a.message || a.description)
        return b
      }
      function t(a, b) {
        let e = null
        b = b == null ? 0 : +b
        try {
          var c = a.stacktrace
          if (c) {
            var f = / line (\d+).*script (?:in )?(\S+)(?:: in function (\S+))?$/i
            var h = / line (\d+), column (\d+)\s*(?:in (?:<anonymous function: ([^>]+)>|([^\)]+))\((.*)\))? in (.*):\s*$/i
            var v = c.split('\n')
            c = []
            for (var k, n = 0; n < v.length; n += 2) {
              let l = null
              if ((k = f.exec(v[n])))
                l = { url: k[2], line: +k[1], column: null, func: k[3], args: [] }
              else if ((k = h.exec(v[n])))
                l = {
                  url: k[6],
                  line: +k[1],
                  column: +k[2],
                  func: k[3] || k[4],
                  args: k[5] ? k[5].split(',') : []
                }
              if (l) {
                !l.func && l.line && (l.func = u(l.url, l.line))
                if (l.line)
                  try {
                    l.context = C(l.url, l.line)
                  } catch (I) {}
                l.context || (l.context = [v[n + 1]])
                c.push(l)
              }
            }
            e = c.length ? { mode: 'stacktrace', name: a.name, message: a.message, stack: c } : null
          } else e = void 0
          if (e) return e
        } catch (I) {}
        try {
          if ((e = B(a))) return e
        } catch (I) {}
        try {
          const g = a.message.split('\n')
          if (g.length < 4) e = null
          else {
            f = /^\s*Line (\d+) of linked script ((?:file|https?|blob)\S+)(?:: in function (\S+))?\s*$/i
            h = /^\s*Line (\d+) of inline#(\d+) script in ((?:file|https?|blob)\S+)(?:: in function (\S+))?\s*$/i
            v = /^\s*Line (\d+) of function script\s*$/i
            k = []
            let r = d && d.document && d.document.getElementsByTagName('script')
            c = []
            let q
            for (p in r) Object.prototype.hasOwnProperty.call(r, p) && !r[p].src && c.push(r[p])
            for (r = 2; r < g.length; r += 2) {
              var p = null
              if ((q = f.exec(g[r])))
                p = { url: q[2], func: q[3], args: [], line: +q[1], column: null }
              else if ((q = h.exec(g[r]))) {
                p = { url: q[3], func: q[4], args: [], line: +q[1], column: null }
                const t = +q[1]
                const x = c[q[2] - 1]
                if (x) {
                  let w = m(p.url)
                  if (w) {
                    w = w.join('\n')
                    const z = w.indexOf(x.innerText)
                    z >= 0 && (p.line = t + w.substring(0, z).split('\n').length)
                  }
                }
              } else if ((q = v.exec(g[r]))) {
                const A = d.location.href.replace(/#.*$/, '')
                const D = new RegExp(E(g[r + 1]))
                const F = y(D, [A])
                p = { url: A, func: '', args: [], line: F ? F.line : q[1], column: null }
              }
              if (p) {
                p.func || (p.func = u(p.url, p.line))
                const H = C(p.url, p.line)
                const J = H ? H[Math.floor(H.length / 2)] : null
                H && J.replace(/^\s*/, '') === g[r + 1].replace(/^\s*/, '')
                  ? (p.context = H)
                  : (p.context = [g[r + 1]])
                k.push(p)
              }
            }
            e = k.length ? { mode: 'multiline', name: a.name, message: g[0], stack: k } : null
          }
          if (e) return e
        } catch (I) {}
        try {
          if ((e = G(a, b + 1))) return e
        } catch (I) {}
        return { name: a.name, message: a.message, mode: 'failed' }
      }
      var x = {}
      t.augmentStackTraceWithInitialElement = A
      t.computeStackTraceFromStackProp = B
      t.guessFunctionName = u
      t.gatherContext = C
      t.ofCaller = function(a) {
        try {
          throw Error()
        } catch (b) {
          return t(b, (a == null ? 0 : +a) + 2)
        }
      }
      t.getSource = m
      return t
    })()
    h.extendToAsynchronousCallbacks = function() {
      const m = function(m) {
        const u = d[m]
        d[m] = function() {
          const d = M.call(arguments)
          const m = d[0]
          typeof m === 'function' && (d[0] = h.wrap(m))
          return u.apply ? u.apply(this, d) : u(d[0], d[1])
        }
      }
      m('setTimeout')
      m('setInterval')
    }
    h.remoteFetching || (h.remoteFetching = !0)
    h.collectWindowErrors || (h.collectWindowErrors = !0)
    if (!h.linesOfContext || h.linesOfContext < 1) h.linesOfContext = 11
    typeof define === 'function' && define.amd
      ? define('TraceKit', [], h)
      : typeof module !== 'undefined' && module.exports && d.module !== module
      ? (module.exports = h)
      : (d.TraceKit = h)
  }
})(typeof window !== 'undefined' ? window : global)
