import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React from 'react';

import { IAuthor } from '~common';
import { ItemWithLabel } from '~components';
import { useAuthorsStore, usePublicationStore } from '~hooks';
import { getUniqueListBy } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const CoauthorsSelect = observer(() => {
  const { coauthors, set } = usePublicationStore();
  const { fetchAuthorsBySearch, authorsSearches } = useAuthorsStore();

  const selectedOptions: LabeledValue[] = coauthors.map((coauthor: IAuthor) => ({
    value: coauthor._id,
    label: coauthor.name,
  }));

  const options: LabeledValue[] = getUniqueListBy<LabeledValue>(
    [...coauthors, ...authorsSearches].map((tag: IAuthor) => ({
      value: tag._id,
      label: tag.name,
    })),
    'value',
  );

  const onChange = (authors: LabeledValue[] = []) => {
    set({ name: 'coauthors', value: authors.map(({ key, label }) => ({ _id: key, name: label })) });
  };

  const onSearch = term => {
    fetchAuthorsBySearch(term);
  };

  return (
    <ItemWithLabel label="Соавторы">
      <Select
        mode="multiple"
        maxTagCount={1}
        style={inputStyle}
        placeholder="Выберите соавтора"
        value={selectedOptions}
        options={options}
        onChange={onChange}
        onSearch={onSearch}
        notFoundContent={null}
        filterOption={false}
        showSearch
        labelInValue
      />
    </ItemWithLabel>
  );
});
