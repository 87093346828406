import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { IDictionary } from '~common';
import { useFeedbackStore, useFetchPaginationStore, useFilterParamsByListStore } from '~hooks';

import styles from './styles.scss';

interface IProps {
  type: IDictionary;
}

const FeedbackFilterItem = observer((props: IProps) => {
  const { type } = props;
  const { currentType } = useFeedbackStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const { setFilterValue } = useFilterParamsByListStore();
  const { setCurrentFeedbackType, fetchFeedbacks } = useFeedbackStore();

  const handleClick = useCallback(() => {
    resetPaginationParams();
    setFilterValue('filter.type', type.id);
    setCurrentFeedbackType(type.id);
    fetchFeedbacks();
  }, [fetchFeedbacks, resetPaginationParams, setCurrentFeedbackType, setFilterValue, type.id]);

  return (
    <li className={styles.navigationItem}>
      <span
        className={cn(styles.navigationItemText, {
          [styles.navigationItemTextActive]: type.id === currentType,
        })}
        onClick={handleClick}
      >
        {type.ru}
      </span>
    </li>
  );
});

export default FeedbackFilterItem;
