import { observer } from 'mobx-react';
import React from 'react';

import { ContentSpinner, EmptyContent, GridWrapper } from '~components';
import { useBadWordsStore } from '~hooks';

import { BadWordItem } from '../BadWordItem';

export const BadWordList = observer(() => {
  const { badWords, isFirstLoading, isEmptyBadWords } = useBadWordsStore();

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  if (isEmptyBadWords) return <EmptyContent text="Нет запрещенных слов" />;

  return (
    <GridWrapper gridSize={5} maxItemWidth={285}>
      {badWords.map(badWord => (
        <BadWordItem key={badWord._id} badWord={badWord} />
      ))}
    </GridWrapper>
  );
});
