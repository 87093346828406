import { DownloadOutlined } from '@ant-design/icons';
import { Col, Row, Tabs, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';

import { ContentWrapper, FilterToggleControl, HeaderPageSearchBar } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { EMPTY_STRING } from '~constants';
import {
  Authorized,
  BlackListedTagsList,
  Filter,
  OnMainTagList,
  openTagModal,
  TagList,
  WhiteListedTagList,
} from '~containers';
import {
  useFetchPaginationStore,
  useFilterParamsByListStore,
  useTagsStore,
  useUserStore,
} from '~hooks';
import { role } from '~utils';

const { TabPane } = Tabs;

const ALL_TAGS_TITLE = 'Все теги';
const BLACK_LIST_PAGE_TITLE = 'Теги из черного списка';
const ON_MAIN_PAGE_TITLE = 'На главной';
const WHITE_LIST_PAGE_TITLE = 'Разрешенные теги';
const COUNT_DECLINATIONS = ['тег', 'тега', 'тегов'];
const SEARCH_BTN_TEXT = 'Найти в тегах';
const CREATE_BTN_TEXT = 'Добавить тег';

const TagsPage = observer(props => {
  const { resetPaginationParams, setPageLimit, setPageOffset } = useFetchPaginationStore();
  const { setFilterParams } = useFilterParamsByListStore();

  const { defaultFilter } = props;

  const [isExporting, setIsExporting] = useState(false);

  const {
    isFirstLoading,
    allItemsCount,
    fetchTags,
    tagsLength,
    setIsFirstLoading,
    fetchDictionaries,
    toggleIsShowFilter,
    isShowFilter,
    initFilterParams,
    filterItems,
    selectedFilterItems,
    removeFilterItem,
    setFilterItemActive,
    setCurrentTagTabFilter,
    resetFilterItems,
    isShowFilterOnWhiteListPage,
    toggleIsShowFilterWhiteListPage,
    filterItemsToWhiteListPage,
    exportTags,
  } = useTagsStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    initFilterParams();
    fetchDictionaries();

    return () => {
      resetPaginationParams();
    };
  }, [fetchDictionaries, initFilterParams, resetPaginationParams]);

  const handleSearchTags = useCallback(
    (defaultFilter = EMPTY_STRING) => {
      setIsFirstLoading(true);
      fetchTags(defaultFilter);
    },
    [fetchTags, setIsFirstLoading],
  );

  const handleOnTabClick = key => {
    let filter = '';

    setFilterParams();
    resetPaginationParams();
    resetFilterItems();

    switch (key) {
      case 'black':
        filter = 'filter.blackListed=true';
        break;
      case 'white':
        filter = 'filter.blackListed=false';
        break;
      case 'onMain':
        filter = 'filter.showInNewsByTagLayout=true';
        break;
      case 'all':
      default:
        break;
    }

    setCurrentTagTabFilter(filter);
  };

  const handleDownloadTags = async (defaultFilter = EMPTY_STRING) => {
    setIsExporting(true);
    setPageLimit(50000);
    setPageOffset(0);
    exportTags(defaultFilter);
    setIsExporting(false);
    resetPaginationParams();
  };

  return (
    <>
      <Tabs
        type="card"
        destroyInactiveTabPane
        onTabClick={handleOnTabClick}
        defaultActiveKey="white"
      >
        <TabPane tab="Все теги" key="all">
          <HeaderPageSearchBar
            searchButtonText={SEARCH_BTN_TEXT}
            createButtonText={CREATE_BTN_TEXT}
            onCreateElement={checkPermissions('tags.create') && openTagModal}
            onSearch={handleSearchTags}
          />
          <ContentWrapper
            title={ALL_TAGS_TITLE}
            countItems={allItemsCount}
            countDeclinations={COUNT_DECLINATIONS}
            rightContent={
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>
                  <Authorized authority={[role.CHIEF_EDITOR]} noMatch={null}>
                    <Tooltip title="Выгрузить все теги">
                      <DownloadOutlined
                        onClick={() => {
                          !isExporting && handleDownloadTags();
                        }}
                        style={{ marginLeft: '24px' }}
                      />
                    </Tooltip>
                  </Authorized>
                </Col>
                <Col>
                  <FilterToggleControl isActive={isShowFilter} onClick={toggleIsShowFilter} />
                </Col>
              </Row>
            }
          >
            {isShowFilter && (
              <Filter
                fetching={fetchTags}
                filtersItem={filterItems}
                selectedFilterItems={selectedFilterItems}
                removeFilterItem={removeFilterItem}
                setFilterItemActive={setFilterItemActive}
              />
            )}

            <InfinityScroll
              fetchItems={fetchTags}
              hasMore={allItemsCount !== tagsLength}
              isFetching={isFirstLoading}
            >
              <TagList />
            </InfinityScroll>
          </ContentWrapper>
        </TabPane>
        <TabPane tab="Разрешенные теги" key="white">
          <HeaderPageSearchBar
            searchButtonText={SEARCH_BTN_TEXT}
            onSearch={() => {
              handleSearchTags('filter.blackListed=false');
            }}
          />
          <ContentWrapper
            title={WHITE_LIST_PAGE_TITLE}
            countItems={allItemsCount}
            countDeclinations={COUNT_DECLINATIONS}
            rightContent={
              <Row justify="space-between" align="middle" gutter={[15, 15]}>
                <Col>
                  <Authorized authority={[role.CHIEF_EDITOR]} noMatch={null}>
                    <Tooltip title="Выгрузить разрешенные теги">
                      <DownloadOutlined
                        onClick={() => {
                          !isExporting && handleDownloadTags('filter.blackListed=false');
                        }}
                        style={{ marginLeft: '24px' }}
                      />
                    </Tooltip>
                  </Authorized>
                </Col>
                <Col>
                  <FilterToggleControl
                    isActive={isShowFilterOnWhiteListPage}
                    onClick={toggleIsShowFilterWhiteListPage}
                  />
                </Col>
              </Row>
            }
          >
            {isShowFilterOnWhiteListPage && (
              <Filter
                fetching={async () => {
                  await fetchTags('filter.blackListed=false');
                }}
                filtersItem={filterItemsToWhiteListPage}
                selectedFilterItems={selectedFilterItems}
                removeFilterItem={removeFilterItem}
                setFilterItemActive={setFilterItemActive}
              />
            )}
            <InfinityScroll
              fetchItems={async () => {
                await fetchTags('filter.blackListed=false');
              }}
              hasMore={allItemsCount !== tagsLength}
              isFetching={isFirstLoading}
            >
              <WhiteListedTagList />
            </InfinityScroll>
          </ContentWrapper>
        </TabPane>
        <TabPane tab="На главной" key="onMain">
          <ContentWrapper
            title={ON_MAIN_PAGE_TITLE}
            countItems={allItemsCount}
            countDeclinations={COUNT_DECLINATIONS}
          >
            <InfinityScroll
              fetchItems={async () => {
                await fetchTags('filter.showInNewsByTagLayout=true');
              }}
              hasMore={allItemsCount !== tagsLength}
              isFetching={isFirstLoading}
            >
              <OnMainTagList />
            </InfinityScroll>
          </ContentWrapper>
        </TabPane>
        <TabPane tab="Черный список" key="black">
          <HeaderPageSearchBar
            searchButtonText={SEARCH_BTN_TEXT}
            onSearch={() => {
              handleSearchTags('filter.blackListed=true');
            }}
          />
          <ContentWrapper
            title={BLACK_LIST_PAGE_TITLE}
            countItems={allItemsCount}
            countDeclinations={COUNT_DECLINATIONS}
            rightContent={
              <Authorized authority={[role.CHIEF_EDITOR]} noMatch={null}>
                <Tooltip title="Выгрузить теги черного списка">
                  <DownloadOutlined
                    onClick={() => {
                      !isExporting && handleDownloadTags('filter.blackListed=true');
                    }}
                    style={{ marginLeft: '24px' }}
                  />
                </Tooltip>
              </Authorized>
            }
          >
            <InfinityScroll
              fetchItems={async () => {
                await fetchTags('filter.blackListed=true');
              }}
              hasMore={allItemsCount !== tagsLength}
              isFetching={isFirstLoading}
            >
              <BlackListedTagsList />
            </InfinityScroll>
          </ContentWrapper>
        </TabPane>
      </Tabs>
    </>
  );
});

export default TagsPage;
