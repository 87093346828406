import { string, object } from 'yup'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const urlSchema = object()
  .default(undefined)
  .shape({
    url: string()
      .url()
      .required(),
    name: string().required()
  })

export const isValidUrl = isValidBySchema(urlSchema)
