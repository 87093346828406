import { observable, action } from 'mobx'

export class FetchPaginationStore {
  @observable limit = 20

  @observable offset = 0

  @action
  setPageLimit = value => {
    this.limit = value
  }

  @action
  setPageOffset = value => {
    this.offset = value
  }

  @action
  resetPaginationParams = () => {
    this.setPageLimit(20)
    this.setPageOffset(0)
  }
}

export default new FetchPaginationStore()
