import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { ContentWrapper, HeaderPageSearchBar, InfinityScroll, Sorting } from '~components';
import {
  CHANGE_LOG_CREATE_BTN_TEXT,
  CHANGE_LOG_PAGE_TITLE,
  CHANGE_LOG_SEARCH_BTN_TEXT,
} from '~constants';
import { ChangeLogFilter, ChangeLogFilterControl, ChangeLogList } from '~containers';
import {
  useChangeLogStore,
  useFetchPaginationStore,
  useFilterParamsByListStore,
  useUserStore,
} from '~hooks';

const SORTING = [
  {
    label: 'По дате создания',
    type: 'createdAt',
  },
];

const COUNT_DECLINATIONS = ['изменение', 'изменения', 'изменений'];

const ChangeLogPage = observer(() => {
  const { user } = useUserStore();
  const { setFilterParams, filterParams, setFilterValue } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const {
    fetchChangeLog,
    setIsFirstLoading,
    allItemsCount,
    changeLogs,
    isFirstLoading,
    isLoadingMore,
    isShowFilter,
    toggleIsShowFilter,
  } = useChangeLogStore();

  useEffect(() => {
    return () => {
      setFilterParams();
      resetPaginationParams();
    };
  }, []);

  const handleSearchPublications = useCallback(() => {
    setIsFirstLoading(true);
    resetPaginationParams();
    fetchChangeLog();
  }, [setIsFirstLoading, resetPaginationParams, fetchChangeLog]);

  const handleToggleMyPost = useCallback(() => {
    const isAllPost = filterParams['filter.employee'] === user._id;

    setIsFirstLoading(true);
    setFilterParams();
    resetPaginationParams();

    if (isAllPost) {
      setFilterValue('filter.employee');
    } else {
      setFilterValue('filter.employee', user._id);
    }

    fetchChangeLog();
  }, [
    filterParams,
    user._id,
    setIsFirstLoading,
    setFilterParams,
    resetPaginationParams,
    fetchChangeLog,
    setFilterValue,
  ]);

  return (
    <>
      <HeaderPageSearchBar
        searchButtonText={CHANGE_LOG_SEARCH_BTN_TEXT}
        createButtonText={CHANGE_LOG_CREATE_BTN_TEXT}
        onSearch={handleSearchPublications}
      />
      <ContentWrapper
        title={CHANGE_LOG_PAGE_TITLE}
        countItems={allItemsCount}
        countDeclinations={COUNT_DECLINATIONS}
        rightContent={
          <ChangeLogFilterControl
            onToggleMyPost={handleToggleMyPost}
            isShowFilter={isShowFilter}
            onToggleShowFilter={toggleIsShowFilter}
            isAllPost={filterParams['filter.employee'] === user._id}
          />
        }
      >
        {isShowFilter && <ChangeLogFilter />}

        <Sorting onSort={handleSearchPublications} data={SORTING} />

        <InfinityScroll
          loadItems={fetchChangeLog}
          isEndLoading={allItemsCount <= changeLogs.length}
          isFirstLoading={isFirstLoading}
          isLoadMore={isLoadingMore}
        >
          <ChangeLogList />
        </InfinityScroll>
      </ContentWrapper>
    </>
  );
});

export default ChangeLogPage;
