import { Select, SelectProps } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React from 'react';

import { ISection } from '~common';
import { ItemWithLabel } from '~components';
import { DEFAULT_VALUE, resourceTypes } from '~constants';
import { usePublicationStore, useUserStore } from '~hooks';
import { findOptionByValue, getAuthorityByResourceType } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const SectionSelect = observer(() => {
  const { section: sectionId, dictionariesList, setSection } = usePublicationStore();
  const { checkPermissions } = useUserStore();

  const getSections = sectionId => {
    const currentSection: ISection | undefined = dictionariesList?.sections.find(
      (section: ISection) => section._id === sectionId,
    );
    if (
      currentSection &&
      (currentSection!.alias === 'novosti' || currentSection!.alias === 'dich')
    ) {
      return dictionariesList?.sections.filter(
        (section: ISection) => section.alias === 'novosti' || section.alias === 'dich',
      );
    }
    return dictionariesList?.sections.filter(
      (section: ISection) => section.alias !== 'novosti' && section.alias !== 'dich',
    );
  };

  const sections: ISection[] = getSections(sectionId) ?? [];
  const options: LabeledValue[] = sections.map(section => ({
    value: section._id,
    label: section.title,
  }));

  const section = findOptionByValue(options, sectionId) ?? DEFAULT_VALUE;

  const onChange: SelectProps<LabeledValue>['onChange'] = section => {
    setSection(section);

    // const isYandexNewsEnabled = rssTypes.includes(
    //   ({ id, isActive }) => id === 'yandexNews' && isActive,
    // );
    // const isYandexNewsSelected = rss.includes('yandexNews');
    // const isNewsSection = section.label === 'Новости';
    //
    // if (isNewsSection && !isYandexNewsSelected) {
    //   setRss([...rss, 'yandexNews']);
    // }
    //
    // if (!isNewsSection && !isYandexNewsEnabled) {
    //   setRss([...rss.filter(rss => rss !== 'yandexNews')]);
    // }
  };

  return (
    <ItemWithLabel label="Раздел">
      <Select
        style={inputStyle}
        placeholder="Выберите раздел"
        value={section}
        onChange={onChange}
        options={options}
        labelInValue
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
