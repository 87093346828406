import { observer } from 'mobx-react';
import { always, find, ifElse, isNil, keys, pipe, prop, propEq } from 'ramda';
import React, { useCallback, useEffect } from 'react';

import { ContentWrapper, ListWrapper, SettingsNavigation } from '~components';
import { FlagItem } from '~containers';
import { useFlagsStore, useUserStore } from '~hooks';

const PAGE_TITLE = 'Настройка флагов';

const getTitle = (value, dictionaries) =>
  pipe(find(propEq('id', value)), ifElse(isNil, always(value), prop('ru')))(dictionaries);

const SettingsTimersPage = observer(() => {
  const {
    getFlags,
    isFetching,
    flags,
    dictionaries,
    updateFlags,
    fetchDictionaries,
  } = useFlagsStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    fetchDictionaries();
  }, []);

  const onClick = useCallback(
    async (checked: boolean, value: string) => {
      await updateFlags({ [value]: checked });
    },
    [updateFlags],
  );

  const renderItem = useCallback(
    value => {
      const title = getTitle(value, dictionaries);

      return (
        <FlagItem
          value={value}
          checked={(flags || {})[value] || false}
          title={title}
          onClick={onClick}
          disabled={!checkPermissions('flags.update')}
        />
      );
    },
    [dictionaries, flags, onClick],
  );

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        <ListWrapper
          fetch={getFlags}
          isFetching={isFetching}
          items={keys(flags || {})}
          renderItem={renderItem}
        />
      </ContentWrapper>
    </>
  );
});

export default React.memo(SettingsTimersPage);
