import { Select } from 'antd';

import { debounce, get, noop } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const { Option } = Select;

@inject(stores => {
  const { fetchTagsBySearch, tagsSearches } = stores.TagsStore;
  const { fetchRegionsBySearch, regionsSearches } = stores.RegionsStore;
  const {
    fetchAuthorsBySearch,
    authorsSearches,
    fetchCategoriesBySearch,
    categoriesSearches,
    sectionsSearches,
    fetchSectionsBySearch,
  } = stores.AuthorsStore;

  const { dictionaries } = stores.PublicationsStore;

  const { fetchSubcategoriesBySearch, subcategoriesSearches } = stores.SubcategoriesStore;

  const { fetchDepartmentBySearch, departmentsSearches } = stores.DepartmentsStore;
  const { search: fetchRolesBySearch, searches: rolesSearches } = stores.RolesStore;
  const { fetchEventsTypesBySearch, eventTypesSearches } = stores.ScheduleStore;
  const { fetchFlowsBySearch, flowsSearches } = stores.FlowsStore;
  const {
    fetchEmployeesBySearch,
    employeesSearches,
    dictionariesEmployees,
  } = stores.EmployeesStore;

  return {
    fetchTagsBySearch,
    fetchAuthorsBySearch,
    fetchCategoriesBySearch,
    fetchSectionsBySearch,
    fetchEventsTypesBySearch,
    fetchEmployeesBySearch,
    employeesSearches,
    eventTypesSearches,
    fetchDepartmentBySearch,
    tagsSearches,
    authorsSearches,
    dictionaries,
    categoriesSearches,
    departmentsSearches,
    sectionsSearches,
    dictionariesEmployees,
    fetchRegionsBySearch,
    regionsSearches,
    fetchSubcategoriesBySearch,
    subcategoriesSearches,
    fetchFlowsBySearch,
    flowsSearches,
    fetchRolesBySearch,
    rolesSearches,
  };
})
@observer
class SelectRender extends Component {
  componentDidMount() {
    const { filter } = this.props;
    const { fetch } = this.getFetchDataByType();

    fetch && fetch(false, filter);
  }

  handleSearch = debounce(term => {
    const { filter } = this.props;
    const { fetch } = this.getFetchDataByType();

    fetch && fetch(term, filter);
  }, 300);

  getFetchDataByType = () => {
    const {
      item,
      fetchTagsBySearch,
      tagsSearches,
      dictionaries,
      authorsSearches,
      fetchAuthorsBySearch,
      categoriesSearches,
      fetchCategoriesBySearch,
      fetchDepartmentBySearch,
      departmentsSearches,
      sectionsSearches,
      fetchSectionsBySearch,
      fetchEventsTypesBySearch,
      eventTypesSearches,
      fetchEmployeesBySearch,
      employeesSearches,
      dictionariesEmployees,
      fetchRegionsBySearch,
      regionsSearches,
      fetchSubcategoriesBySearch,
      subcategoriesSearches,
      fetchFlowsBySearch,
      flowsSearches,
      fetchRolesBySearch,
      rolesSearches,
    } = this.props;

    if (item.name === 'filter.tags') {
      return {
        fetch: fetchTagsBySearch,
        options: tagsSearches,
        key: '_id',
        label: 'title',
      };
    }

    if (item.name === 'filter.priority') {
      return {
        fetch: noop,
        options: get(dictionaries, 'postPriorities', []),
        key: 'id',
        label: 'ru',
      };
    }

    // if (item.name === 'filter.yandexTag') {
    //   return {
    //     fetch: noop,
    //     options: get(dictionaries, 'yandexTags', []),
    //     key: 'id',
    //     label: 'ru',
    //   };
    // }

    if (item.name === 'filter.flags.RSS') {
      return {
        fetch: noop,
        options: get(dictionaries, 'rssTypes', []),
        key: 'id',
        label: 'ru',
      };
    }

    if (item.name === 'filter.type') {
      return {
        fetch: noop,
        options: get(dictionaries, 'postTypes', []),
        key: 'id',
        label: 'ru',
      };
    }

    if (item.name === 'filter.role') {
      return {
        fetch: fetchRolesBySearch,
        options: rolesSearches,
        key: '_id',
        label: 'name',
      };
    }

    if (item.name === 'filter.status') {
      return {
        fetch: noop,
        options: get(dictionaries, 'postStatuses', []),
        key: 'id',
        label: 'ru',
      };
    }

    if (item.name === 'filter.authors') {
      return {
        fetch: fetchAuthorsBySearch,
        options: authorsSearches,
        key: '_id',
        label: 'name',
      };
    }

    if (item.name === 'filter.categories') {
      return {
        fetch: fetchCategoriesBySearch,
        options: categoriesSearches,
        key: '_id',
        label: 'title',
      };
    }

    if (item.name === 'filter.subcategories') {
      return {
        fetch: fetchSubcategoriesBySearch,
        options: subcategoriesSearches,
        key: '_id',
        label: 'title',
        extraLabel: 'category',
      };
    }

    if (item.name === 'filter.section') {
      return {
        fetch: fetchSectionsBySearch,
        options: sectionsSearches,
        key: '_id',
        label: 'title',
      };
    }

    if (item.name === 'filter.department') {
      return {
        fetch: fetchDepartmentBySearch,
        options: departmentsSearches,
        key: '_id',
        label: 'name',
      };
    }

    if (item.name === 'filter.eventType') {
      return {
        fetch: fetchEventsTypesBySearch,
        options: eventTypesSearches,
        key: '_id',
        label: 'title',
      };
    }

    if (item.name === 'employees') {
      return {
        fetch: fetchEmployeesBySearch,
        options: employeesSearches,
        key: '_id',
        label: 'name',
      };
    }

    if (item.name === 'filter.employee') {
      return {
        fetch: fetchEmployeesBySearch,
        options: employeesSearches.map(item => ({
          id: item._id,
          name: `${item.firstName} ${item.lastName}`,
        })),
        key: 'id',
        label: 'name',
      };
    }

    if (item.name === 'filter.resourceType') {
      return {
        fetch: noop,
        options: [
          { id: 'posts', name: 'Посты' },
          { id: 'blocks', name: 'Блоки' },
          { id: 'files', name: 'Файлы' },
          { id: 'comments', name: 'Комментарии' },
          { id: 'authors', name: 'Авторы' },
          { id: 'badWords', name: 'Запрещенные слова' },
          { id: 'blockgroups', name: 'Группы блоков' },
          { id: 'categories', name: 'Категории' },
          { id: 'departments', name: 'Отделы' },
          { id: 'events', name: 'События' },
          { id: 'pages', name: 'Страницы' },
          { id: 'playlists', name: 'Плейлисты' },
          { id: 'sections', name: 'Секции' },
          { id: 'settings', name: 'Настройки' },
          { id: 'sociallinks', name: 'Соц. страницы' },
          { id: 'specialProjects', name: 'Спецпроекты' },
          { id: 'streams', name: 'Стримы' },
          { id: 'tags', name: 'Теги' },
          { id: 'users', name: 'Пользователи' },
          { id: 'videos', name: 'Видео' },
        ],
        key: 'id',
        label: 'name',
      };
    }

    if (item.name === 'filter.validatedBy.corrector') {
      return {
        fetch: noop,
        options: [
          { id: '1', name: 'Без вычитки' },
          { id: '2', name: 'Вычитано' },
          { id: '3', name: 'Вычитать повторно' },
        ],
        key: 'id',
        label: 'name',
      };
    }

    if (item.name === 'filter.regions') {
      return {
        fetch: fetchRegionsBySearch,
        options: regionsSearches,
        key: '_id',
        label: 'title',
      };
    }

    if (item.name === 'filter.flows') {
      return {
        fetch: fetchFlowsBySearch,
        options: flowsSearches,
        key: '_id',
        label: 'title',
      };
    }
    return {};
  };

  handleSelectChange = value => {
    const { onChange } = this.props;
    const { fetch } = this.getFetchDataByType();

    onChange(value);

    if (!value) {
      fetch();
    }
  };

  render() {
    const {
      onChange,
      value,
      optionsChildren,
      item: { mode = false },
      ...props
    } = this.props;
    const { options, key, label, extraLabel } = this.getFetchDataByType();

    return (
      <Select
        style={{
          width: '100%',
        }}
        showSearch
        allowClear
        optionFilterProp="children"
        onChange={this.handleSelectChange}
        value={value}
        mode={mode}
        placeholder="Выбрать"
        onSearch={this.handleSearch}
        {...props}
      >
        {options &&
          options.map(option => {
            return (
              <Option value={option[key]}>
                {optionsChildren ? optionsChildren(option) : option[label]}
                {extraLabel && ' ('.concat(option[extraLabel]?.title, ')')}
              </Option>
            );
          })}
      </Select>
    );
  }
}

export default SelectRender;

SelectRender.propTypes = {
  authorsSearches: PropTypes.array,
  categoriesSearches: PropTypes.any,
  departmentsSearches: PropTypes.array,
  dictionaries: PropTypes.array,
  dictionariesEmployees: PropTypes.any,
  employeesSearches: PropTypes.any,
  eventTypesSearches: PropTypes.any,
  fetchAuthorsBySearch: PropTypes.func,
  fetchCategoriesBySearch: PropTypes.func,
  fetchDepartmentBySearch: PropTypes.func,
  fetchEmployeesBySearch: PropTypes.any,
  fetchEventsTypesBySearch: PropTypes.any,
  fetchSectionsBySearch: PropTypes.any,
  fetchTagsBySearch: PropTypes.func,
  filter: PropTypes.string,
  item: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionsChildren: PropTypes.any,
  sectionsSearches: PropTypes.any,
  tagsSearches: PropTypes.array,
  value: PropTypes.any,
};
