import React from 'react'

import styles from './styles.scss'

const Cell = date => {
  const day = date.getDate()

  return <div className={styles.cell}>{day}</div>
}

export default Cell
