import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Checkbox } from 'antd'
import CloseIcon from '~assets/icons/close.svg'

import styles from './styles.scss'

class CheckBoxRender extends Component {
  render() {
    const { label, checked, onRemove, onChange } = this.props

    return (
      <div className={styles.boolItem}>
        <Checkbox onChange={onChange} checked={checked}>
          {label}
        </Checkbox>
        <span onClick={onRemove} className={styles.closeIcon}>
          <CloseIcon width={12} fill="#9A9A9A" />
        </span>{' '}
      </div>
    )
  }
}

export default CheckBoxRender

CheckBoxRender.propTypes = {
  checked: PropTypes.bool,
  data: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
}
