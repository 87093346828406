import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow';
import s from './styles.scss';

const DICT = {
  publicationAutoSave: 'Автосохранение работы публикации',
  publicationGuestLinkTTL: 'Время жизни ссылки на гостевой просмотр публикации',
  widgetGuestLinkTTL: 'Время жизни ссылки на гостевой просмотр виджета публикации',
  autoExit: 'Автовыход',
  postponeRangeSeconds: 'Ограничение отложенной публикации',
};

@inject('TimersStore')
@observer
class TimersItem extends Component {
  handleClick = () => {
    const {
      TimersStore: { setEditableTimer },
      title,
      value,
    } = this.props;

    setEditableTimer({
      title,
      value,
    });
  };

  render() {
    const { title, value } = this.props;

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{DICT[title]}</h2>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Время в секундах:</h3>
            <p className={s.cardItemValue}>{value}</p>
          </div>
        </div>
      </WhiteBlockWithShadow>
    );
  }
}

TimersItem.propTypes = {
  TimersStore: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.number,
};

export default TimersItem;
