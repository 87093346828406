import {
  CodepenOutlined,
  FontSizeOutlined,
  IdcardOutlined,
  MessageOutlined,
  PicLeftOutlined,
} from '@ant-design/icons';
import React from 'react';

export const InputsTypes = {
  input: 'input',
  upload: 'upload',
  textarea: 'textarea',
  richInput: 'richInput',
  checkbox: 'checkbox',
};

export const BlockTypes = {
  gigarama: 'gigarama',
  person: 'person',
  cardText: 'card:text',
  cardImage: 'card:image',
  cardVideo: 'card:video',
  cardQuote: 'card:quote',
};

const cardInputs = {
  title: {
    name: 'title',
    label: 'Заголовок',
    type: InputsTypes.richInput,
  },
  text: {
    name: 'text',
    label: 'Текст',
    type: InputsTypes.richInput,
  },
  content: {
    name: 'content',
    label: 'Контент',
    type: InputsTypes.richInput,
  },
  description: {
    name: 'description',
    label: 'Описание',
    type: InputsTypes.richInput,
  },
  copyright: {
    name: 'copyright',
    label: 'Copyright',
    type: InputsTypes.richInput,
  },
  cite: {
    name: 'cite',
    label: 'Подпись',
    type: InputsTypes.richInput,
  },
  media: {
    name: 'image',
    label: 'Медиа',
    type: InputsTypes.upload,
  },
  link: {
    name: 'link',
    label: 'Ссылка',
    type: InputsTypes.input,
  },
  isClickable: {
    name: 'isClickable',
    label: 'Кликабельный блок',
    type: InputsTypes.checkbox,
  },
};

export default [
  {
    title: 'Гигарама',
    icon: <CodepenOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
    customType: BlockTypes.gigarama,
    inputs: [
      {
        name: 'title',
        label: 'Заголовок',
        type: InputsTypes.input,
      },
      {
        name: 'button_text',
        label: 'Текст на кнопке',
        type: InputsTypes.input,
      },
      {
        name: 'gigarama_url',
        label: 'Ссылка на гигараму',
        type: InputsTypes.input,
      },
      {
        name: 'background',
        label: 'Фоновое изображение',
        type: InputsTypes.upload,
      },
    ],
  },
  {
    title: 'Карточка персоны',
    icon: <IdcardOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
    customType: BlockTypes.person,
    inputs: [
      {
        name: 'name',
        label: 'Имя',
        type: InputsTypes.richInput,
      },
      {
        name: 'description',
        label: 'Описание',
        type: InputsTypes.richInput,
      },
      {
        name: 'avatar',
        label: 'Аватар',
        type: InputsTypes.upload,
      },
    ],
  },
  {
    title: 'Карточка. Текст',
    icon: <FontSizeOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
    customType: BlockTypes.cardText,
    inputs: [cardInputs.title, cardInputs.text, cardInputs.copyright, cardInputs.media],
  },
  {
    title: 'Карточка. Картинка',
    icon: <PicLeftOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
    customType: BlockTypes.cardImage,
    inputs: [
      cardInputs.text,
      cardInputs.copyright,
      cardInputs.isClickable,
      cardInputs.link,
      cardInputs.media,
    ],
  },
  // {
  //   title: 'Карточка. Видео',
  //   icon: ReactDOM.renderToString(
  //     <Icon type="video-camera" style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />
  //   ),
  //   customType: BlockTypes.cardVideo,
  //   inputs: defaultCardInputs
  // },
  {
    title: 'Карточка. Цитата',
    icon: <MessageOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
    customType: BlockTypes.cardQuote,
    inputs: [
      // cardInputs.title,
      { ...cardInputs.content, label: 'Цитата' },
      { ...cardInputs.cite, label: 'Автор' },
      cardInputs.media,
    ],
  },
];
