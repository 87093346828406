import { observer } from 'mobx-react';
import React from 'react';

import { ContentSpinner, EmptyContent, GridWrapper } from '~components';
import { useTagsStore } from '~hooks';

import { TagItem } from '../TagItem';

const TagList = observer(() => {
  const { tags, isFirstLoading, isEmptyTags } = useTagsStore();

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  if (isEmptyTags) return <EmptyContent text="Нет тегов" />;

  return (
    <GridWrapper gridSize={5} maxItemWidth={285}>
      {tags.map(tag => (
        <TagItem key={tag._id} tag={tag} />
      ))}
    </GridWrapper>
  );
});

export default TagList;
