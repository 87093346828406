import { isArray, isFunction } from 'lodash';
import { action, observable, reaction, toJS } from 'mobx';
import moment from 'moment';

// service
import FetchPaginationStore from './fetchPaginationStore';
import FilterParamsByListStore from './filterParamsByList';

class ConfigureFetchUrlByListsStore {
  @observable FILTER_PARAMS_FOR_BACK = {
    'filter.updatedAt': params => {
      return `day(${params})`;
    },
    'filter.createdAt': params => {
      return `day(${params})`;
    },
    'filter.publicationDate': params => {
      return `day(${params})`;
    },
    'filter.title': params => `match(${params})`,
  };

  constructor() {
    const { filterParams, setFilterValue } = FilterParamsByListStore;

    const startUrl = new URLSearchParams(window.location.search);
    const STRING_KEYS = ['sort'];

    startUrl.forEach((value = '', key) => {
      setFilterValue(key, STRING_KEYS.includes(key) ? value : value.split(','));
    });

    reaction(
      () => toJS(filterParams) || Object.keys(filterParams).length,
      () => {
        const filterUrlParams = Object.keys(filterParams)
          .map(key => {
            return `${key}=${filterParams[key]}`;
          })
          .join('&');

        let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        if (filterUrlParams) {
          newUrl = `?${filterUrlParams}`;
        }

        window.history.replaceState({ path: newUrl }, '', newUrl);
      },
    );
  }

  @action
  SET_FILTER_PARAMS_FOR_BACK = params => {
    this.FILTER_PARAMS_FOR_BACK = params;
  };

  @action
  getPromiseUrl = ({ url = '', defaultFilter = null }) => {
    const { offset, limit } = FetchPaginationStore;
    const { filterParams } = FilterParamsByListStore;

    let pageUrlParams = `limit=${limit}&offset=${offset * limit}`;

    if (defaultFilter) {
      pageUrlParams = `${pageUrlParams}&${defaultFilter}`;
    }

    const filterUrlParams = Object.entries(filterParams)
      .map(([key, value]) => {
        const actionGetValue = this.FILTER_PARAMS_FOR_BACK[key];
        if (isFunction(actionGetValue)) {
          value = actionGetValue(value);
        }
        if (isArray(value)) {
          value = `in(${JSON.stringify(value)})`;
        }
        return `${key}=${value}`;
      })
      .join('&');

    let fetchUrlWithParams = `${url}?${pageUrlParams}`;

    if (filterUrlParams) {
      fetchUrlWithParams = `${fetchUrlWithParams}&${filterUrlParams}`;
    }

    return fetchUrlWithParams;
  };

  @action
  getDateRangeFilterParams = params => {
    const dateArray = isArray(params) ? params : params.split(',');

    const startDate = moment(dateArray[0])
      .startOf('day')
      .utc()
      .format();

    const endDate = moment(dateArray[1])
      .endOf('day')
      .utc()
      .format();

    return `between(["${startDate}","${endDate}"])`;
  };

  @action
  getIsShowFilterFromUrlParams = () => {
    const { filterParams } = FilterParamsByListStore;

    const filter = Object.keys(filterParams).filter(item => item !== 'search');

    return !!filter.length;
  };
}

export default new ConfigureFetchUrlByListsStore();
