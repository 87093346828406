import { Button, Form, Input, Select, Switch, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { IFile, InjectedModalProps, ITag } from '~common';
import { ButtonUpload, DeleteButton, ItemWithLabel, Modal } from '~components';
import { EMPTY_OBJECT, EMPTY_STRING } from '~constants';
import { useFetchPaginationStore, useTagsStore, useUserStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { getUrlFromFile } from '~utils';

import styles from './styles.scss';

const { Option } = Select;

const CREATE_TITLE = 'Создание нового тега';
const UPDATE_TITLE = 'Редактирование тега';

export const TagModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;
  const {
    editableTag,
    resetEditableTag,
    updateTag,
    createTag,
    deleteTag,
    isActionModal,
    dictionary,
    fetchTags,
    currentTagTabFilter,
  } = useTagsStore();
  const { checkPermissions } = useUserStore();

  const { resetPaginationParams } = useFetchPaginationStore();

  const [form] = Form.useForm<ITag>();
  const [cover, setCover] = useState(editableTag?.cover?.url || EMPTY_STRING);
  const [icon, setIcon] = useState(editableTag?.icon?.url || EMPTY_STRING);
  const [blackListed, setBlackListed] = useState(editableTag?.blackListed || false);
  const [isVerified, setIsVerified] = useState(editableTag?.isVerified || false);
  const [showInNewsByTagLayout, setShowInNewsByTagLayout] = useState(
    editableTag?.showInNewsByTagLayout || false,
  );

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const tag = {
        data: {
          ...values,
          cover: getUrlFromFile(values?.cover),
          icon: getUrlFromFile(values?.icon),
        },
      };

      console.log('tag', tag);

      const action = editableTag ? updateTag : createTag;
      action(tag)
        .then(handleClose)
        .then(() => {
          resetPaginationParams();
          fetchTags(currentTagTabFilter);
        });
    });
  }, [form, createTag, editableTag, updateTag]);

  const handleDelete = useCallback(() => {
    deleteTag().then(handleClose);
  }, [deleteTag]);

  const handleClose = useCallback(() => {
    resetEditableTag();
    form.resetFields();
    onClose(true);
  }, [form, resetEditableTag]);

  const handleBlackList = value => {
    setBlackListed(value);
  };

  const handleShowOnMain = value => {
    setShowInNewsByTagLayout(value);
  };

  const handleVerified = value => {
    setIsVerified(value);
  };

  const modalTitle = useMemo(() => (editableTag ? UPDATE_TITLE : CREATE_TITLE), [editableTag]);

  const modalButtons = useMemo(() => {
    return (
      <div className={styles.modalControls}>
        <Button
          key="submit"
          loading={isActionModal}
          onClick={handleSubmit}
          disabled={!checkPermissions(`tags.${editableTag ? 'update' : 'create'}`)}
          type="primary"
        >
          Сохранить изменения
        </Button>
        {editableTag && (
          <div className={styles.deleteBtnWrapper}>
            <DeleteButton
              text="Удалить тег"
              onDelete={handleDelete}
              disabled={!checkPermissions('tags.delete')}
            />
          </div>
        )}
      </div>
    );
  }, [isActionModal, editableTag, handleSubmit, handleDelete, handleClose]);

  const fileUploadProps = useMemo(
    () => ({
      name: 'file',
      action: `${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`,
      withCredentials: true,
      data: { type: 'card' },
      accept: 'image/*',
    }),
    [FileTransportLayer.fileUploadUrl],
  );

  return (
    <Modal
      destroyOnClose
      width={600}
      bodyStyle={{ overflow: 'scroll', maxHeight: '80vh' }}
      isFooterHidden={false}
      centered
      isVisible={isOpen}
      title={modalTitle}
      footer={modalButtons}
      onClose={handleClose}
    >
      <div className={styles.root} id="divTagModalContainer" style={{ position: 'relative' }}>
        {editableTag?.createdAt && (
          <ItemWithLabel label="Дата создания">
            <div className={styles.date}>{moment(editableTag.createdAt).format('DD.MM.YYYY')}</div>
          </ItemWithLabel>
        )}
        <Form
          form={form}
          initialValues={editableTag || EMPTY_OBJECT}
          validateTrigger="onSubmit"
          onFinish={handleSubmit}
        >
          <ItemWithLabel label="Название тега (значение не должно содержать решетку)">
            <Form.Item name="title" rules={[{ required: true, message: 'Обязательное поле!' }]}>
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 1 }}
                autoFocus
                disabled={isActionModal}
                showCount
                maxLength={40}
              />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Псевдоним тега (латиница)">
            <Form.Item name="alias">
              <Input placeholder="Будет создан автоматически" disabled />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Тип">
            <Form.Item name="type" rules={[{ required: true, message: 'Обязательное поле!' }]}>
              <Select
                getPopupContainer={() =>
                  document.getElementById('divTagModalContainer') || document.body
                }
                placeholder="Выбрать тип"
              >
                {dictionary?.map(option => {
                  return (
                    <Option key={option.id} value={option.id}>
                      {option.ru}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Описание">
            <Form.Item
              name="description"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Input.TextArea
                placeholder="Введите описание"
                disabled={isActionModal}
                showCount
                maxLength={350}
              />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="SEO заголовок">
            <Form.Item name={['seo', 'title']} rules={[{ required: false }]}>
              <Input placeholder="Введите SEO заголовок" disabled={isActionModal} />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="SEO описание">
            <Form.Item name={['seo', 'description']} rules={[{ required: false }]}>
              <Input placeholder="Введите SEO описание" disabled={isActionModal} />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Обложка">
            <>
              {cover && (
                <div>
                  <img className={styles.cardItemCover} src={cover} alt="Cover" />
                </div>
              )}
              <Form.Item name="cover">
                <ButtonUpload
                  uploadProps={fileUploadProps}
                  onChange={(data: IFile) => {
                    setCover(data?.url || EMPTY_STRING);
                  }}
                />
              </Form.Item>
            </>
          </ItemWithLabel>

          <ItemWithLabel label="На Главной в блоке Новости по тегу">
            <Tooltip
              placement="topLeft"
              title={
                showInNewsByTagLayout
                  ? 'Внимание! Для снятия необходимо установить другой тег на Главную!'
                  : null
              }
            >
              <Form.Item name="showInNewsByTagLayout" rules={[{ required: false }]}>
                <Switch checked={showInNewsByTagLayout} onChange={handleShowOnMain} />
              </Form.Item>
            </Tooltip>
          </ItemWithLabel>

          <ItemWithLabel label="Название тега для вывода в блоке на Главной">
            <Form.Item name="newsByTagLayoutTitle" rules={[{ required: false }]}>
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 1 }}
                disabled={isActionModal || !showInNewsByTagLayout}
                showCount
                maxLength={40}
              />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="В черном списке тегов">
            <Form.Item name="blackListed" rules={[{ required: false }]}>
              <Switch checked={blackListed} onChange={handleBlackList} />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Проверенный">
            <Form.Item name="isVerified" rules={[{ required: false }]}>
              <Switch checked={isVerified} onChange={handleVerified} />
            </Form.Item>
          </ItemWithLabel>

          <ItemWithLabel label="Иконка">
            <>
              {icon && (
                <div>
                  <img className={styles.cardItemCover} src={icon} alt="Icon" />
                </div>
              )}
              <Form.Item name="icon">
                <ButtonUpload
                  uploadProps={fileUploadProps}
                  onChange={(data: IFile) => {
                    setIcon(data?.url || EMPTY_STRING);
                  }}
                />
              </Form.Item>
            </>
          </ItemWithLabel>
        </Form>
      </div>
    </Modal>
  );
});

export const openTagModal = createModal(TagModal);
