import { observer } from 'mobx-react';
import React from 'react';

import { ContentSpinner, EmptyContent, GridWrapper } from '~components';
import { useChangeLogStore } from '~hooks';

import ChangeLogItem from '../ChangeLogItem';
import styles from './styles.scss';

const ChangeLogList: React.FC = observer(() => {
  const { changeLogs, isFirstLoading, isEmptyChangeLog } = useChangeLogStore();

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  if (isEmptyChangeLog) return <EmptyContent text="Нет данных" />;

  return (
    <div className={styles.root}>
      <GridWrapper gridSize={1}>
        {changeLogs.map(changeLog => (
          <ChangeLogItem key={changeLog._id} data={changeLog} />
        ))}
      </GridWrapper>
    </div>
  );
});

export default ChangeLogList;
