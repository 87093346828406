import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styles from './styles.scss'

class DropDownItem extends Component {
  handleClickItem = () => {
    const { onClick, item } = this.props

    onClick(item)
  }

  render() {
    const { item } = this.props

    return (
      <div className={styles.option} onClick={this.handleClickItem}>
        {item.label}
      </div>
    )
  }
}

export default DropDownItem

DropDownItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}
