import PropTypes from 'prop-types'
import React from 'react'

import moment from 'moment'

import styles from './styles.scss'

const DAY_NAME_FORMAT = 'ddd'
const DAY_NUMBER_FORMAT = 'DD'

const MonthDateHeader = ({ date }) => {
  const dayName = moment(date).format(DAY_NAME_FORMAT)
  const dayNumber = moment(date).format(DAY_NUMBER_FORMAT)

  return (
    <div className={styles.root}>
      <div className={styles.title}>{dayName}</div>
      <div className={styles.subTitle}>{dayNumber}</div>
    </div>
  )
}

export default MonthDateHeader

MonthDateHeader.propTypes = {
  date: PropTypes.any
}
