import React from 'react';

import styles from './styles.styl';

interface IProps {
  label: string;
  tag?: string;
}

const BlockItemWithLabel: React.FC<IProps> = ({ label, children, tag = 'label' }) =>
  React.createElement(tag, null, <span className={styles.label}>{label}</span>, children);

export default BlockItemWithLabel;
