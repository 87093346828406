import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { throttle } from 'lodash'

import ContentSpinner from '~components/ContentSpinner'

const THROTTLE_TIME = 200

@inject('FetchPaginationStore')
@observer
class InfinityScroll extends Component {
  constructor(props) {
    super(props)
    this.layoutItem = null
  }

  componentDidMount() {
    const {
      loadItems,
      FetchPaginationStore,
      onScrollDidMount,
      defaultFilter,
      containerName
    } = this.props
    const { setPageOffset } = FetchPaginationStore

    const cont = containerName ? document.getElementById(containerName) : window

    cont.addEventListener('scroll', this.throttledScrollHandle)

    FetchPaginationStore.resetPaginationParams()

    if (onScrollDidMount) {
      onScrollDidMount()
    }

    loadItems(defaultFilter).then(() => {
      setPageOffset(1)
    })
  }

  componentWillUnmount() {
    const { onResetFilter, containerName } = this.props

    if (onResetFilter) {
      onResetFilter()
    }

    const cont = containerName ? document.getElementById(containerName) : window

    cont.removeEventListener('scroll', this.throttledScrollHandle)
  }

  scrollHandle = () => {
    const { isEndLoading, isLoadMore } = this.props

    if (isLoadMore || isEndLoading) return null

    const { bottom } = this.layoutItem.getBoundingClientRect()
    const bottomOffset = bottom - window.innerHeight

    if (bottomOffset < 100) {
      const { FetchPaginationStore, loadItems, defaultFilter } = this.props
      const { offset, setPageOffset } = FetchPaginationStore

      loadItems(defaultFilter).then(() => {
        const newOffset = offset + 1
        setPageOffset(newOffset)
      })

      return null
    }

    return null
  }

  throttledScrollHandle = throttle(this.scrollHandle, THROTTLE_TIME)

  render() {
    const { children, isFirstLoading, isLoadMore } = this.props

    return (
      // eslint-disable-next-line no-return-assign
      <div ref={node => (this.layoutItem = node)}>
        {children}

        {isLoadMore && !isFirstLoading && (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <ContentSpinner />
          </div>
        )}
      </div>
    )
  }
}

export default InfinityScroll

InfinityScroll.propTypes = {
  FetchPaginationStore: PropTypes.any,
  children: PropTypes.element,
  containerName: PropTypes.string,
  defaultFilter: PropTypes.string,
  isEndLoading: PropTypes.bool,
  isFirstLoading: PropTypes.bool,
  isLoadMore: PropTypes.bool,
  loadItems: PropTypes.func,
  onResetFilter: PropTypes.func,
  onScrollDidMount: PropTypes.func
}
