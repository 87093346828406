import { Icon as LegacyIcon } from '@ant-design/compatible';
import { EditOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { IFile } from '~common';
import { resourceTypes } from '~constants';
import {
  useDraftStore,
  useFilterParamsByListStore,
  usePublicationStore,
  useUserStore,
} from '~hooks';
import { ArrowRight } from '~icons';
import { role } from '~utils';

import styles from './styles.scss';

interface IUserOnPageProps {
  avatar?: IFile;
  firstName?: string;
  lastName?: string;
}

interface ICenterControlProps {
  iconType?: string;
  label?: string;
  onClick?: MouseEventHandler;
}

interface IHeaderProps {
  onClick: (any) => Promise<any>;
  validatedAt: {
    corrector: string;
  };
  validatedBy: {
    corrector: boolean;
  };
}

const { confirm } = Modal;

const UserOnPage: React.FC<IUserOnPageProps> = ({ firstName = '', lastName = '', avatar }) => (
  <Tooltip placement="bottom" title={`${firstName} ${lastName}`} arrowPointAtCenter>
    <div className={styles.user}>
      {avatar ? (
        <img src={avatar.url} alt={`${firstName} ${lastName}`} className={styles.userAvatar} />
      ) : (
        <span className={styles.userLetter}>{firstName && firstName[0]}</span>
      )}
    </div>
  </Tooltip>
);

const CenterControl: React.FC<ICenterControlProps> = ({ iconType, label, onClick }) => {
  return (
    <div className={styles.centerControlItem} onClick={onClick}>
      {iconType && (
        <>
          <LegacyIcon type={iconType} />{' '}
        </>
      )}
      {label}
    </div>
  );
};

const Header: React.FC<IHeaderProps> = observer(() => {
  const {
    usersOnPage,
    openCommentsModal,
    template,
    restoreTemplate,
    id,
    resourceType,
  } = usePublicationStore();
  const { setFilterValue } = useFilterParamsByListStore();
  const { checkPermissions } = useUserStore();
  const { isSomethingInDraft } = useDraftStore();

  const { path } = useRouteMatch();
  const history = useHistory();

  const shouldShowDraft = useMemo(() => {
    return template;
  }, [template]);

  const onClickExit = useCallback(() => {
    switch (resourceType) {
      case resourceTypes.quiz: {
        history.push('/quiz');
        break;
      }

      case resourceTypes.textTranslation: {
        history.push('/broadcasts/text');
        break;
      }

      default: {
        history.push(path.slice(0, -4));
        break;
      }
    }
  }, [history, path, resourceType]);

  const onClickHistory = useCallback(() => {
    history.push('/changelog');
    setFilterValue('filter.resourceId', id);
    setFilterValue('filter.resourceType', 'posts');
  }, [history, id, setFilterValue]);

  const handleClickDraft = async () => {
    confirm({
      title: 'Восстановление и черновика',
      icon: <EditOutlined />,
      content: `Восстановить черновик данной публикации от ${moment(template.savedAs).format(
        'DD.MM.YYYY HH:mm:ss',
      )}?`,
      okText: 'Да',
      cancelText: 'Нет',
      onOk: restoreTemplate,
    });
  };

  return (
    <div className={styles.editorHeader}>
      <div className={styles.wrapper}>
        <div className={styles.backButton} onClick={onClickExit}>
          <ArrowRight fill="currentColor" style={{ transform: 'rotate(180deg) scale(.5)' }} />
          <span>Выйти из редактирования</span>
        </div>

        <div className={styles.centerControl}>
          <CenterControl iconType="message" label="Комментарии" onClick={openCommentsModal} />
          {checkPermissions('histories.view') && (
            <CenterControl iconType="history" label="Журнал изменений" onClick={onClickHistory} />
          )}
          {shouldShowDraft && (
            <CenterControl iconType="edit" label="Черновик" onClick={handleClickDraft} />
          )}
        </div>

        <div className={styles.users}>
          {/* eslint-disable-next-line react/jsx-key */}
          {React.Children.toArray(usersOnPage?.map(user => <UserOnPage {...user} />))}
        </div>
      </div>
    </div>
  );
});

export default Header;
