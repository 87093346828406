import { Card, Col, Radio, Row } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { ContentWrapper, SettingsNavigation } from '~components';
import { useSettingsStore, useWindowDimensions } from '~hooks';

import styles from './styles.module.scss';

const PAGE_TITLE = 'Лого в хедере';

const DefaultLogo = (
  <svg width="106" height="40" viewBox="0 0 106 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11 0H0V40H26V31.0108H11V0Z" fill="#FF0A0A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31 40H44V0H31V40Z" fill="#FF0A0A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49 40H60V24.2045H73V15L60 15.0108V9L76 8.98916V0H49V40Z"
      fill="#FF0A0A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79 0V40H106V31.0099H90V24H102.895V15H90V8.93579H106V0H79Z"
      fill="#FF0A0A"
    />
  </svg>
);

const NewYearLogo = (
  <svg width="105" height="62" viewBox="0 0 105 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10988_34)">
      <g mask="url(#mask0_10988_34)">
        <path
          d="M11.1436 20.0067H0V62H26.927V52.5617H11.1436V20.0067ZM31.1018 20.0067H42.2985V62H31.1018V20.0049V20.0067ZM47.4949 62H58.6385V45.4166H71.0107V35.9783H58.6385V29.4395H74.3725V20.0067H47.4931L47.4949 62ZM88.9694 52.5617V45.4166H101.69V35.9783H88.9694V29.3882H105V20.0067H77.8807V62H104.998V52.5617H88.9694ZM23.3565 10.7021C26.4052 11.4476 28.1245 17.6915 28.1611 17.7611L47.5645 17.2849L48.2731 16.6878L56.3735 9.90169C56.3735 9.90169 51.5177 -5.14133 32.118 1.86458C29.6544 2.77177 27.264 3.86662 24.9678 5.1395L23.3565 10.7021Z"
          fill="#FF0A0A"
        />
        <path
          d="M44.922 12.2315C45.1655 14.6583 46.4289 16.0284 48.2472 16.6823L56.3476 9.89616C56.3476 9.89616 51.5174 -5.14137 32.1177 1.86454C32.1177 1.86454 43.8142 1.19051 44.922 12.2315Z"
          fill="#E61919"
        />
        <path
          d="M21.9374 11.7498C24.3873 11.0684 25.7935 8.39973 25.0794 5.79152C24.3653 3.18331 21.8019 1.62094 19.3538 2.30413C16.9039 2.98732 15.4977 5.65598 16.2118 8.2642C16.9259 10.8724 19.4893 12.4329 21.9374 11.7498Z"
          fill="#F1F2F2"
        />
        <path
          d="M22.2925 6.56631C22.0415 5.592 21.4961 4.71891 20.7307 4.06616C19.9682 3.41651 19.0222 3.02084 18.0244 2.93422C18.4291 2.65215 18.8795 2.43969 19.3537 2.30781C21.8018 1.62279 24.3652 3.18515 25.0793 5.79154C25.7916 8.39792 24.3836 11.0666 21.9336 11.7571C21.4576 11.8908 20.965 11.9403 20.4743 11.9073C21.2873 11.3175 21.9007 10.486 22.2266 9.52985C22.5525 8.57192 22.5745 7.53706 22.2925 6.56631Z"
          fill="#ECECEC"
        />
        <path
          d="M26.6047 20.6807L28.2307 26.6206C28.2976 26.9097 28.4249 27.1813 28.6041 27.4177C28.7834 27.6542 29.0105 27.85 29.2707 27.9925C29.53 28.1335 29.8165 28.2172 30.1108 28.238C30.4052 28.2589 30.7006 28.2164 30.9772 28.1134C40.1382 24.678 49.5803 22.0452 59.1969 20.2448C59.4895 20.19 59.7669 20.0728 60.0102 19.9012C60.2535 19.7296 60.457 19.5076 60.6068 19.2502C60.7582 18.9926 60.8531 18.7057 60.8853 18.4085C60.9175 18.1114 60.8862 17.8108 60.7936 17.5267L59.1676 11.5868C59.0015 10.9756 58.6188 10.4457 58.091 10.0958C57.5658 9.74794 56.9311 9.60518 56.3076 9.69472C53.4335 10.2342 50.5737 10.8474 47.731 11.5337C41.0659 13.14 34.5048 15.1512 28.0842 17.556C27.5 17.8059 27.0277 18.2614 26.7567 18.8363C26.4845 19.4124 26.4305 20.0678 26.6047 20.6807Z"
          fill="#F1F2F2"
        />
        <path
          d="M51.5162 21.8859C54.0635 21.2839 56.6246 20.7416 59.1973 20.2595C59.4916 20.2055 59.7708 20.0883 60.0155 19.9159C60.2601 19.7436 60.4645 19.5202 60.6145 19.2612C60.7666 19.0017 60.8613 18.7126 60.8923 18.4134C60.9232 18.1142 60.8897 17.8118 60.794 17.5267L59.168 11.5868C59.002 10.9755 58.6193 10.4456 58.0914 10.0958C57.5662 9.74794 56.9315 9.60518 56.308 9.69472C53.4339 10.2342 50.5741 10.8474 47.7314 11.5337C47.7314 11.5337 52.6423 14.7408 51.5162 21.8859Z"
          fill="#ECECEC"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_10988_34">
        <rect width="105" height="62" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SpecialLogo = (
  <svg width="106" height="40" viewBox="0 0 106 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11 0H0V40H26V31.0108H11V0Z" fill="#424242" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31 40H44V0H31V40Z" fill="#424242" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49 40H60V24.2045H73V15L60 15.0108V9L76 8.98916V0H49V40Z"
      fill="#424242"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79 0V40H106V31.0099H90V24H102.895V15H90V8.93579H106V0H79Z"
      fill="#424242"
    />
  </svg>
);

const CardData = observer(({ type }) => {
  const { logoType } = useSettingsStore();

  const getTitle = () => {
    switch (type) {
      case 'default': {
        return 'Классический';
      }
      case 'newYear': {
        return 'Новогодний';
      }
      case 'special': {
        return 'Специальный';
      }
      default: {
        return 'Неизвестный тип лого';
      }
    }
  };

  return (
    <Row>
      <Col>
        <Radio checked={logoType === type} />
        {getTitle()}
      </Col>
    </Row>
  );
});

const SettingsLogoPage = observer(() => {
  const { updateLogo, getLogoType } = useSettingsStore();

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width < 480, [width]);

  useEffect(() => {
    getLogoType();
  }, [getLogoType]);

  const handleLogoClick = async type => {
    await updateLogo(type);
    getLogoType();
  };

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        <Row gutter={[20, 20]} className={styles.wrapper}>
          <div
            className={classNames(styles.card, isMobile && styles.isMobile)}
            onClick={() => handleLogoClick('default')}
          >
            <CardData type="default" />
            <Row justify="center" align="middle" className={styles.logo}>
              {DefaultLogo}
            </Row>
          </div>
          <div
            className={classNames(styles.card, isMobile && styles.isMobile)}
            onClick={() => handleLogoClick('newYear')}
          >
            <CardData type="newYear" />
            <Row justify="center" align="middle" className={styles.logo}>
              {NewYearLogo}
            </Row>
          </div>
          <div
            className={classNames(styles.card, isMobile && styles.isMobile)}
            onClick={() => handleLogoClick('special')}
          >
            <CardData type="special" />
            <Row justify="center" align="middle" className={styles.logo}>
              {SpecialLogo}
            </Row>
          </div>
        </Row>
      </ContentWrapper>
    </>
  );
});

export default SettingsLogoPage;
