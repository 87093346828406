import { Button, Card, Col, Input, notification, Row, Spin, Switch } from 'antd';
import { observer } from 'mobx-react';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { Role } from '~common';
import { ContentWrapper, DeleteButton, Tabs } from '~components';
import { RightsActionsTab, RightsObjects, RoleEmployeesList } from '~containers';
import { useRolesStore, useUserStore } from '~hooks';
import { ArrowRight } from '~icons';

import styles from './styles.module.scss';

export const RolePage = observer(() => {
  const { checkPermissions } = useUserStore();
  const history = useHistory();
  const [role, setRole] = useState<Role>();
  const [isLoading, setIsLoading] = useState(false);
  const { getItem, update, create, remove, isFetching, copyRole } = useRolesStore();
  const { id } = useParams<{ id: string }>();
  const form = useForm<Role>({ shouldUnregister: true });

  const { control, reset } = form;

  const onDelete = async () => {
    await remove(id);

    history.push('/roles');
  };

  const onFinish = async data => {
    if (isNil(id)) {
      const result = await create(data);

      history.replace(`/roles/${result._id}`);
      setRole(result);
    } else {
      setRole(await update(id, data));
      notification.success({ message: 'Роль обновлена' });
    }
  };

  const onClickExit = () => {
    history.push('/roles');
  };

  const resetForm = () => {
    if (isNil(id)) {
      copyRole && reset(copyRole);
    } else {
      role && reset(role);
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getItem(id)
        .then(setRole)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    resetForm();
  }, [reset, role, copyRole, id]);

  return (
    <>
      <div className={styles.backButton} onClick={onClickExit}>
        <ArrowRight fill="currentColor" style={{ transform: 'rotate(180deg) scale(.5)' }} />
        <span>Выйти из редактирования</span>
      </div>
      <ContentWrapper title={id ? 'Редактирование роли' : 'Создать роль'}>
        {isLoading ? (
          <Spin />
        ) : (
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onFinish)}>
              <Row gutter={[20, 30]}>
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Row gutter={[20, 0]}>
                        <Col>
                          <Button
                            htmlType="submit"
                            type="primary"
                            loading={isFetching}
                            disabled={!checkPermissions(`roles.${isNil(id) ? 'create' : 'update'}`)}
                          >
                            СОХРАНИТЬ
                          </Button>
                        </Col>
                        {!isNil(id) && (
                          <Col>
                            <Button onClick={resetForm}>ОТМЕНИТЬ</Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col>
                      {!isNil(id) && (
                        <DeleteButton
                          onDelete={onDelete}
                          text="Удалить роль"
                          disabled={!checkPermissions('roles.delete') || isFetching}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Card className={styles.card}>
                    <Row justify="space-between">
                      <Col className={styles.role}>Название роли</Col>
                      <Col>
                        <Row align="middle" justify="space-between" gutter={[25, 0]}>
                          <Col className={styles.switch}>Активна</Col>
                          <Col>
                            <Controller
                              name="active"
                              control={control}
                              render={field => (
                                <Switch
                                  {...field}
                                  checked={field.value}
                                  className={styles.switch}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Controller
                          control={control}
                          name="name"
                          render={field => <Input {...field} className={styles.input} />}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card className={styles.card}>
                    <Tabs
                      tabs={[
                        {
                          title: 'Функциональные настройки',
                          component: <RightsObjects />,
                        },
                        {
                          title: 'Дополнительные настройки',
                          component: <RightsActionsTab />,
                        },
                        {
                          title: 'Список сотрудников',
                          component: <RoleEmployeesList />,
                          disabled: isNil(id),
                        },
                      ]}
                    />
                  </Card>
                </Col>
              </Row>
            </form>
          </FormProvider>
        )}
      </ContentWrapper>
    </>
  );
});
