import { WrappedFormUtils } from '@ant-design/compatible/es/form/Form';
import { flatten, is, isNil, map, pipe, reject, values, when } from 'ramda';

const convert = pipe<
  object,
  Array<string | undefined>,
  Array<string | undefined>,
  Array<string | undefined>,
  Array<string | undefined>
>(values, map(pipe(when(is(Object), values))), flatten, reject(isNil));

export const useFormHasErrors = (form?: Partial<WrappedFormUtils>): boolean => {
  const { getFieldsError } = form || {};

  const errors = (getFieldsError && getFieldsError()) || {};

  return convert(errors).length > 0;
};
