import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow'

import s from './styles.scss'

@inject('SocialsStore')
@observer
class SocialsItem extends Component {
  handleClick = () => {
    const {
      SocialsStore: { setEditableSocial },
      data
    } = this.props

    setEditableSocial(data)
  }

  render() {
    const {
      data: { title, url, visibility }
    } = this.props

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{title}</h2>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Ссылка:</h3>
            <p className={s.cardItemValue}>{url}</p>
          </div>
          <div className={s.cardItem}>
            <h3 className={s.cardItemTitle}>Видимость на сайте:</h3>
            <p className={s.cardItemValue}>{visibility ? 'Да' : 'Нет'}</p>
          </div>
        </div>
      </WhiteBlockWithShadow>
    )
  }
}

SocialsItem.propTypes = {
  SocialsStore: PropTypes.any,
  data: PropTypes.object
}

export default SocialsItem
