import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Input, Pagination, Row } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { always, dec, inc, pathOr, pipe } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Sorting } from '~components';
import { useFilterParamsByListStore, useRolesStore } from '~hooks';
import SearchIco from '~icons/search.svg';

import { roleEmployeesModal } from '../RoleEmployeesModal';
import styles from './styles.module.scss';

const SORTING = [
  {
    label: 'По имени',
    type: 'lastName',
  },
  {
    label: 'По ID',
    type: 'index',
  },
];

export const RoleEmployeesList = observer(() => {
  const [search, setSearch] = useState('');
  const [employees, setEmployees] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const { getEmployees, updateEmployeeRole } = useRolesStore();
  const { filterParams } = useFilterParamsByListStore();
  const { id } = useParams<{ id: string }>();

  const onAddEmployeeToRole = async () => {
    if (await roleEmployeesModal({ roleId: id })) {
      if (currentPage > 0) {
        setCurrentPage(0);
      } else {
        fetchEmployees();
      }
    }
  };

  const onDeleteEmployeeRole = employeeId => async () => {
    await updateEmployeeRole(employeeId, null);
    await fetchEmployees();
  };

  const onChangePage = page => {
    setCurrentPage(dec(page));
  };

  const fetchEmployees = async () => {
    const {
      data: { data, meta },
    } = await getEmployees(id, {
      offset: 10 * currentPage,
      limit: 10,
      search,
      ...filterParams,
    });

    setEmployees(data);
    setCount(meta?.count);
  };

  const onSort = () => {
    setCurrentPage(0);
    fetchEmployees();
  };

  useEffect(() => {
    setIsFetching(true);
    fetchEmployees().finally(pipe(always(false), setIsFetching));
  }, [id, filterParams, currentPage]);

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <Row>
          <Col span={18}>
            <Input
              value={search}
              className={styles.input}
              prefix={<SearchIco fill="#9A9A9A" width={24} height={24} />}
              placeholder="Найти"
              form="hidden"
              onPressEnter={fetchEmployees}
              onChange={pipe(pathOr('', ['currentTarget', 'value']), setSearch)}
            />
          </Col>
          <Col span={6}>
            <Button onClick={fetchEmployees} className={styles.dskSearchButton} type="primary">
              НАЙТИ В СОТРУДНИКАХ
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Sorting data={SORTING} onSort={onSort} />
      </Col>
      <Col span={24}>
        <Row gutter={[0, 18]}>
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={6} offset={2} className={styles.columnName}>
                ФИО
              </Col>
              <Col span={8} className={styles.columnName}>
                E-MAIL
              </Col>
              <Col span={4} className={styles.columnName}>
                ID
              </Col>
            </Row>
          </Col>

          {employees.map(({ firstName, lastName, email, index, avatar, _id }) => (
            <Col span={24} className={styles.row} key={index}>
              <Row justify="space-between" gutter={[20, 20]} align="middle">
                <Col span={2}>
                  <Avatar src={get(avatar, 'url', '')} size={32}>
                    {firstName && firstName[0]}
                  </Avatar>
                </Col>
                <Col className={styles.fio} span={6}>
                  {firstName && firstName} {lastName && lastName}
                </Col>
                <Col className={styles.email} span={8}>
                  {email}
                </Col>
                <Col className={styles.index} span={4}>
                  {index}
                </Col>
                <Col className={styles.delete} span={4}>
                  <Button type="link" onClick={onDeleteEmployeeRole(_id)}>
                    Удалить
                  </Button>
                </Col>
              </Row>
            </Col>
          ))}

          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <Pagination
                  current={inc(currentPage)}
                  onChange={onChangePage}
                  total={count}
                  defaultCurrent={6}
                  hideOnSinglePage
                  showSizeChanger={false}
                  defaultPageSize={10}
                />
              </Col>
              <Col>
                <Row align="middle" gutter={[10, 0]}>
                  <Col>Добавить сотрудника</Col>
                  <Col>
                    <Button onClick={onAddEmployeeToRole} icon={<PlusOutlined />} shape="circle" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});
