import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
} from 'react-sortable-hoc';

import { ISubCategory } from '~common';
import { AddNew, ContentSpinner, GridWrapper } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { useSubcategoriesStore, useUserStore } from '~hooks';

import { openSubcategoriesModal, SubcategoryItem } from '..';

const SortableItem = sortableElement(({ subcategory }: { subcategory: ISubCategory }) => (
  <SubcategoryItem data={subcategory} />
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  );
});

const SubcategoriesList = observer(() => {
  const {
    fetchSubcategories,
    isFirstLoading,
    subcategories,
    changeOrdering,
    allItemsCount,
    subcategoriesLength,
  } = useSubcategoriesStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    fetchSubcategories && fetchSubcategories();
  }, []);

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  return (
    <InfinityScroll
      fetchItems={fetchSubcategories}
      hasMore={allItemsCount !== subcategoriesLength}
      isFetching={isFirstLoading}
    >
      <SortableContainer
        distance={1}
        axis="xy"
        onSortEnd={checkPermissions('subcategories.update') ? changeOrdering : undefined}
      >
        {subcategories &&
          subcategories.map((subcategory: ISubCategory) => (
            <SortableItem
              key={subcategory._id}
              subcategory={subcategory}
              index={subcategory.order}
            />
          ))}
        {checkPermissions('subcategories.create') && (
          <AddNew
            onClick={async () => {
              await openSubcategoriesModal();
            }}
          />
        )}
      </SortableContainer>
    </InfinityScroll>
  );
});

export default SubcategoriesList;
