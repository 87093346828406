/* eslint-disable */

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Select } from 'antd'

import SliderWrapper from '../SliderWrapper'
import SliderItem from '../components/SliderItem'

import styles from './styles.scss'

const { Option } = Select

@inject(stores => {
  const {
    selectedTags,
    setSelectedTags,
    removeTag,
    setTagValue
  } = stores.PublicationStore.priorities
  const { fetchTagsBySearch, tagsSearches } = stores.TagsStore

  return {
    fetchTagsBySearch,
    tagsSearches,
    selectedTags,
    setSelectedTags,
    removeTag,
    setTagValue
  }
})
@observer
class PriorityTags extends Component {
  handleRemoveTag = id => {
    const { removeTag } = this.props

    removeTag(id)
  }

  handleChangeSlider = ({ id, value }) => {
    const { setTagValue } = this.props

    setTagValue({ id, value })
  }

  handleSelectTag = tag => {
    const { setSelectedTags } = this.props

    setSelectedTags(tag)
  }

  render() {
    const { label, tagsSearches, fetchTagsBySearch, selectedTags } = this.props
    const popover = {
      content: (
        <div style={{ minWidth: 200 }}>
          <Select
            size="small"
            style={{ width: '100%' }}
            mode="multiple"
            placeholder="Поиск тегов"
            autoFocus
            value={selectedTags}
            onChange={this.handleSelectTag}
            onSearch={fetchTagsBySearch}
            labelInValue
            optionFilterProp={'label'}
            maxTagCount={2}
            maxTagTextLength={10}
          >
            {tagsSearches.map(({ _id, title }) => (
              <Option key={_id} label={title}>
                {title}
              </Option>
            ))}
          </Select>
        </div>
      )
    }

    return (
      <SliderWrapper label={label} popover={popover}>
        <div className={styles.categoryList}>
          {selectedTags.map(tag => (
            <SliderItem
              key={tag.key}
              label={tag.label}
              id={tag.key}
              value={tag.value}
              onClick={this.handleRemoveTag}
              onChangeSlider={this.handleChangeSlider}
              disabled
            />
          ))}
        </div>
      </SliderWrapper>
    )
  }
}

export default PriorityTags
