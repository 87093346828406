import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import GridWrapper from '~components/GridWrapper';
import AddNew from '~components/AddNew';
import ContentSpinner from '~components/ContentSpinner';
import InfoPagesItem from '~components/InfoPages/InfoPagesItem';

@inject('InfoPagesStore', 'UserStore')
@observer
class InfoPagesList extends Component {
  componentDidMount() {
    const {
      InfoPagesStore: { fetchInfoPages },
    } = this.props;

    fetchInfoPages();
  }

  render() {
    const {
      InfoPagesStore: { pages, setShowModal, isFirstLoading },
      UserStore: { checkPermissions },
    } = this.props;

    if (isFirstLoading) {
      return <ContentSpinner />;
    }

    return (
      <GridWrapper gridSize={3} maxItemWidth={285}>
        {pages && pages.map(page => <InfoPagesItem data={page} key={page._id} />)}
        {checkPermissions('infoPages.create') && <AddNew onClick={setShowModal} />}
      </GridWrapper>
    );
  }
}

InfoPagesList.propTypes = {
  InfoPagesStore: PropTypes.any,
};

export default InfoPagesList;
