import { Card, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { useSettingsStore, useUserStore } from '~hooks';

import styles from './styles.module.scss';

export const SettingsTechMaintenance = observer(() => {
  const { globalSettings, updateGlobalSettings } = useSettingsStore();
  const { checkPermissions } = useUserStore();

  const handleChange = e => {
    const { name, checked } = e.target;

    updateGlobalSettings({ [name]: checked });
  };

  return (
    <Card className={styles.card}>
      <div className={styles.title}>Режим технических работ</div>
      <div className={styles.item}>
        <Checkbox
          name="techMaintenance"
          checked={globalSettings?.techMaintenance}
          onChange={handleChange}
          disabled={!checkPermissions('global.update')}
        >
          Включить режим технических работ
        </Checkbox>
      </div>
    </Card>
  );
});
