import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists.js';

const getData = ({ data }) => data;

const SUBCATEGORIES_URL = '/subcategories';

export class SubcategoriesTransportLayer {
  getSubcategories = (defaultFilter = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: SUBCATEGORIES_URL, defaultFilter });

    return API.get(promiseUrl).then(getData);
  };

  getSubcategoriesManualFilter = (defaultFilter = EMPTY_STRING) => {
    let url = SUBCATEGORIES_URL;

    if (defaultFilter.length > 0) {
      url = `${SUBCATEGORIES_URL}?${defaultFilter}`;
    }

    return API.get(url).then(getData);
  };

  getSubcategoriesBySearch = term => {
    let url = SUBCATEGORIES_URL;

    if (term) {
      url = `${SUBCATEGORIES_URL}?search=${term}`;
    }

    return API.get(url).then(getData);
  };

  createSubcategory = subcategory => {
    return API.post(`${SUBCATEGORIES_URL}`, { ...subcategory }).then(getData);
  };

  updateSubcategoryById = (id, subcategory) => {
    return API.patch(`${SUBCATEGORIES_URL}/${id}`, {
      data: { ...subcategory },
    }).then(getData);
  };

  deleteSubcategoryById = id => {
    return API.delete(`${SUBCATEGORIES_URL}/${id}`).then(getData);
  };

  changeOrdering = (id, targetPlacement, toEnd = false) => {
    let data;

    if (toEnd) {
      data = {
        meta: {
          after: targetPlacement,
        },
      };
    } else {
      data = {
        meta: {
          before: targetPlacement,
        },
      };
    }

    return API.post(`${SUBCATEGORIES_URL}/${id}/placement`, data);
  };
}
