import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { ContentWrapper } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import SettingsNavigation from '~components/SettingsNavigation';
import { SettingsTechMaintenance } from '~components/SettingsTechMaintenance';
import { SettingsNotificationList, settingsNotificationModal } from '~containers';
import { useNotificationsStore, useUserStore } from '~hooks';

import styles from './styles.module.scss';

const SettingsNotificationPage = observer(() => {
  const { allItemsCount, isFirstLoading, getList, countItems, resetList } = useNotificationsStore();
  const { checkPermissions } = useUserStore();

  const onCreate = async () => {
    if (await settingsNotificationModal()) {
      await resetList();
    }
  };

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title="Уведомление сотрудников">
        <Row gutter={[20, 20]}>
          {checkPermissions('notifications.view') && (
            <Col span={24}>
              <SettingsTechMaintenance />
            </Col>
          )}
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col span={8} className={styles.notify}>
                <ExclamationCircleFilled /> Уведомление для сотрудников
              </Col>
              <Col span={8}>
                <Row gutter={8} justify="end" align="middle">
                  <Col className={styles.newNotifyTitle}>Новое уведомление</Col>
                  <Col>
                    <Button
                      shape="circle"
                      onClick={onCreate}
                      type="primary"
                      icon={<PlusOutlined />}
                      disabled={!checkPermissions('notifications.update')}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <InfinityScroll
              fetchItems={getList}
              hasMore={allItemsCount !== countItems}
              isFetching={isFirstLoading}
            >
              <SettingsNotificationList />
            </InfinityScroll>
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
});

export default SettingsNotificationPage;
