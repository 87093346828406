import { array, number, object, ObjectSchema, string } from 'yup';

export interface IPriority {
  id: string;
  value: number;
}

export const prioritySchema: ObjectSchema<IPriority> = object({
  id: string(),
  value: number(),
}).defined();

export type SectionPriority = IPriority;
export type CategoryPriority = IPriority;
export type TagPriority = IPriority;

export type DraftPriorities = {
  categories: CategoryPriority[];
  section: SectionPriority[];
  tags: TagPriority[];
};

export const draftPrioritiesSchema: ObjectSchema<DraftPriorities> = object({
  categories: array()
    .of(prioritySchema)
    .default([])
    .defined(),
  section: array()
    .of(prioritySchema)
    .default([])
    .defined(),
  tags: array()
    .of(prioritySchema)
    .default([])
    .defined(),
}).defined();
