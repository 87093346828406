import { action, computed, observable, reaction } from 'mobx';

import API, { getData } from '~api';
import OverlayStore from '~store/OverlayStore';
import { showErrorNotificationInPromise } from '~utils';

interface IPushSettings {
  mobile: string;
  web: string;
}

export class PushStore {
  apiLayer = API;

  @observable
  push: Partial<IPushSettings> = {};

  @observable
  dictionaries: Record<string, string>[] = [];

  @observable
  isFetching = false;

  @observable
  isLoaded = false;

  constructor() {
    reaction(
      () => this.isFetching,
      isFetching => OverlayStore.setVisible(isFetching),
    );
  }

  @computed
  get webPush() {
    return this.push.web;
  }

  @computed
  get mobilePush() {
    return this.push.mobile;
  }

  @action
  setPush = push => {
    this.push = push;
  };

  @action
  fetchDictionaries = () => {
    const promise = API.get('dictionaries/push');

    return promise.then(res => {
      const { data } = res;

      this.dictionaries = data.pushSystems;
    });
  };

  @action
  getPush = () => {
    this.setIsFetching(true);
    return this.apiLayer
      .get('/settings/push')
      .then(getData)
      .then(({ data: push }) => {
        this.setPush(push);
        this.setIsLoaded(true);
        return Promise.resolve(push);
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка получения настроек' }))
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  updatePush = push => {
    this.setIsFetching(true);
    return this.apiLayer
      .patch('/settings/push', { data: push })
      .then(getData)
      .then(({ data: push }) => {
        this.setPush(push);
        return Promise.resolve(push);
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка обновления настроек' }))
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  setIsFetching(data: boolean) {
    this.isFetching = data;
  }

  @action
  setIsLoaded(data: boolean) {
    this.isLoaded = data;
  }
}

export default new PushStore();
