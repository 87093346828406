import { Input, message, Switch, Tooltip } from 'antd';
import propTypes from 'prop-types';
import React from 'react';
import { BlockWrapper } from '~components/Blocks';

// components
import ItemWithLabel from '~components/ItemWithLabel';

// services
// eslint-disable-next-line import/no-unresolved
import publicationApi from '~services/publicationApi';

// assets
import styles from './styles.scss';

// helpers
const getIdFromLink = link => link.split('/').slice(-1)[0];
const getPreviewFromPost = post => ({
  _id: post._id,
  title: post.title,
  subtitle: post.subtitle,
  clickbait: post.clickbait,
  cover: post.cover,
});
const delayedExecute = (f, delay) => {
  let timerId = null;

  return value => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      f(value);
    }, delay);
  };
};

class IncutBlock extends React.Component {
  constructor(props) {
    super(props);

    this.delayedRequest = delayedExecute(this.requestPreviewByLink, 600);
    this.state = {
      preview: null,
      value: (props.data && props.data.postId) || '',
      isNewsIncut: (props.data && props.data.isNewsIncut) || false,
    };
  }

  componentDidMount() {
    if (this.props.postId) {
      this.requestPreview(this.props.postId);
    }
  }

  componentWillUnmount() {
    this.setState({});
  }

  componentDidUpdate(prevProps) {
    if (this.props.postId !== prevProps.postId) {
      if (this.props.postId) {
        this.requestPreview(this.props.postId);
      } else {
        this.setState({ preview: null });
      }
    }
    if (this.props.isNewsIncut !== prevProps.isNewsIncut) {
      this.setState({ isNewsIncut: this.props.isNewsIncut });
    }
  }

  requestPreview = id => {
    publicationApi.fetchPublication(id).then(post => {
      this.setState({
        preview: getPreviewFromPost(post),
      });
    });
  };

  requestPreviewByLink = link => {
    const id = getIdFromLink(link);

    publicationApi
      .fetchPublication(id)
      .then(post => {
        if (!post) {
          throw new Error('Not found');
        }

        this.props.onChange({ postId: post._id, isNewsIncut: this.state.isNewsIncut });
      })
      .catch(() => {
        message.info(
          'Публикации по заданому значению не найдено! Проверьте правильность введеного значения.',
        );
      });
  };

  handleChangeInput = event => {
    const link = event.target.value;
    this.setState({ value: link });
    this.delayedRequest(link);
  };

  handleChangeNewsIncut = checked => {
    this.setState({ isNewsIncut: checked });
    this.props.onChange({ postId: getIdFromLink(this.state.value), isNewsIncut: checked });
  };

  header = () => {
    return (
      <div>
        <Tooltip title={this.state.isNewsIncut ? 'Новостная врезка' : 'Обычная врезка'}>
          <Switch
            onChange={this.handleChangeNewsIncut}
            checked={this.state.isNewsIncut}
            disabled={this.props?.blocked}
            checkedChildren="НВ"
            unCheckedChildren="ОВ"
          />
        </Tooltip>
      </div>
    );
  };

  render() {
    const {
      blockProps: { title },
    } = this.props;
    const { preview } = this.state;

    return (
      <BlockWrapper title={title} {...this.props} {...this.props.blockProps}>
        <div className={styles.container}>
          <div>
            <ItemWithLabel label="Ссылка на публикацию или id">
              <Input value={this.state.value} onChange={this.handleChangeInput} />
            </ItemWithLabel>
          </div>
          {preview && (
            <div className={styles.preview}>
              <div className={styles.previewImageWrapper}>
                <div className={styles.previewImageContainer}>
                  <img
                    className={styles.previewImage}
                    src={preview.cover && preview.cover.url}
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.previewContent}>
                <h3 className={styles.previewTitle}>{preview.title}</h3>
                <span className={styles.previewSubtitle}>{preview.subtitle}</span>
              </div>
            </div>
          )}
        </div>
      </BlockWrapper>
    );
  }
}

IncutBlock.propTypes = {
  data: propTypes.object,
  blockProps: propTypes.object,
  postId: propTypes.object,

  onChange: propTypes.func,
  onFocus: propTypes.func,
};

IncutBlock.defaultProps = {
  postId: null,
};

export default IncutBlock;
