import { string, object } from 'yup'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const socialsSchema = object()
  .default(undefined)
  .shape({
    facebook: string(),
    instagram: string(),
    ok: string(),
    telegram: string(),
    twitter: string(),
    vk: string()
  })

export const isValidSocials = isValidBySchema(socialsSchema)
