import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect } from 'react';

import { ContentWrapper, HeaderPageSearchBar, Sorting } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { CommentsFilter, CommentsList } from '~containers';
import { useCommentsStore, useFetchPaginationStore, useFilterParamsByListStore } from '~hooks';

const PAGE_TITLE = 'Комментарии';
const COUNT_DECLINATIONS = ['комментарий', 'комментария', 'комментариев'];
const SEARCH_BTN_TEXT = 'Найти в комментариях';
const CREATE_BTN_TEXT = 'Добавить запрещенное слово';
const SORTING = [
  {
    label: 'По дате создания',
    type: 'createdAt',
  },
];

interface ICommentsPageProps {
  defaultFilter?: string;
}
export const CommentsPage: FC<ICommentsPageProps> = observer(props => {
  const {
    commentsCount,
    commentsFetchedCount,
    isFetching,
    hasMoreCommentToFetch,
    fetchComments,
    fetchStatuses,
  } = useCommentsStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const { setFilterParams } = useFilterParamsByListStore();

  const { defaultFilter } = props;

  useEffect(() => {
    fetchStatuses();
    return () => {
      resetPaginationParams();
      setFilterParams();
    };
  }, []);

  const handleSearch = useCallback(() => {
    resetPaginationParams();
    fetchComments();
  }, [fetchComments, resetPaginationParams]);

  return (
    <>
      <HeaderPageSearchBar
        searchButtonText={SEARCH_BTN_TEXT}
        createButtonText={CREATE_BTN_TEXT}
        onSearch={handleSearch}
      />
      <ContentWrapper
        title={PAGE_TITLE}
        countItems={commentsCount}
        countFetched={commentsFetchedCount}
        countDeclinations={COUNT_DECLINATIONS}
      >
        <Sorting onSort={handleSearch} data={SORTING} />

        <Row align="top" gutter={16}>
          <Col span={16}>
            <InfinityScroll
              defaultFilter={defaultFilter}
              isFetching={isFetching}
              fetchItems={fetchComments}
              hasMore={hasMoreCommentToFetch}
            >
              <CommentsList />
            </InfinityScroll>
          </Col>
          <Col span={8}>
            <CommentsFilter />
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
});
