import { action, computed, observable, runInAction } from 'mobx';
import { inc, propEq } from 'ramda';

import { IHeaderPreview, Maybe } from '~common';
import { openHeaderPreviewsModal } from '~containers/HeaderPreview';
import { HeaderPreviewsTransportLayer } from '~services/headerPreviewsApi';
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotification, showErrorNotificationInPromise } from '~utils';

export class HeaderPreviewsStore {
  @observable headerPreviews: Array<IHeaderPreview> = [];

  @observable headerPreviewsSearches = [];

  @observable isFirstLoading = true;

  @observable isShowModal = false;

  @observable isActionModal = false;

  @observable editableHeaderPreview: Maybe<IHeaderPreview> = null;

  @observable allItemsCount = 0;

  apiLayer = new HeaderPreviewsTransportLayer();

  @action
  fetchHeaderPreviews = (filter = 'sort=-createdAt') => {
    const { offset, setPageOffset } = FetchPaginationStore;

    const isNewFetch = offset === 0;
    if (isNewFetch) {
      this.headerPreviews = [];
      this.setAllItemsCount(0);
    }
    return this.apiLayer
      .getHeaderPreviews(filter)
      .then(({ data: headerPreviews = [], meta }) => {
        if (offset === 0) {
          this.headerPreviews = headerPreviews;
        } else {
          this.headerPreviews = [...this.headerPreviews, ...headerPreviews];
        }

        setPageOffset(inc(offset));

        this.isFirstLoading = false;
        this.setAllItemsCount(meta.count);
        return Promise.resolve(headerPreviews);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки превью хедера', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.isFirstLoading = false;
      });
  };

  @action
  fetchHeaderPreviewsBySearch = (term?) => {
    return this.apiLayer.getHeaderPreviewsBySearch(term).then(res => {
      const { data } = res;

      runInAction(() => {
        this.headerPreviewsSearches = data;
      });

      return data;
    });
  };

  @action
  createHeaderPreview = headerPreview => {
    const { resetPaginationParams } = FetchPaginationStore;

    this.isActionModal = true;

    return this.apiLayer
      .createHeaderPreview(headerPreview)
      .then(({ data }) => {
        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.headerPreviews.unshift(data);
          resetPaginationParams();
          this.fetchHeaderPreviews();
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания превью хедера',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  updateHeaderPreviewById = headerPreview => {
    const id = this.editableHeaderPreview?._id || null;

    this.isActionModal = true;

    const { data } = headerPreview;

    return this.apiLayer
      .updateHeaderPreviewById(id, data)
      .then(({ data }) => {
        const editableHeaderPreviewIndex = this.headerPreviews.findIndex(propEq('_id', id));

        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.headerPreviews[editableHeaderPreviewIndex] = data;
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка обновления превью хедера',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  deleteHeaderPreviewById = () => {
    const id = this.editableHeaderPreview?._id || null;

    this.isActionModal = true;
    return this.apiLayer
      .deleteHeaderPreviewById(id)
      .then(() => {
        const editableHeaderPreviewIndex = this.headerPreviews.findIndex(propEq('_id', id));

        runInAction(() => {
          this.isActionModal = false;
          this.headerPreviews.splice(editableHeaderPreviewIndex, 1);
          this.resetEditableHeaderPreview();
          this.setHideModal();
          return Promise.resolve(true);
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка удаления подкатегории',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  setEditableHeaderPreview = async value => {
    this.editableHeaderPreview = { ...value };

    await openHeaderPreviewsModal();
  };

  @action
  resetEditableHeaderPreview = () => {
    this.editableHeaderPreview = null;
  };

  @action
  setHideModal = () => {
    this.isShowModal = false;
  };

  @action
  toggleActionModal = () => {
    this.isActionModal = !this.isActionModal;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @computed
  get headerPreviewsLength() {
    return this.headerPreviews.length;
  }
}

export default new HeaderPreviewsStore();
