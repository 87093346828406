import classNames from 'classnames';
import React, { FC } from 'react';

import CloseIcon from '~assets/icons/close.svg';

import styles from './styles.scss';

interface IItemWithLabel {
  label: string;
  labelClass?: string;
  onRemove?: () => void;
  outerClass?: string;
}

const ItemWithLabel: FC<IItemWithLabel> = ({
  label,
  children,
  onRemove,
  outerClass,
  labelClass,
}) => {
  return (
    <div className={outerClass}>
      <span className={classNames(styles.label, labelClass)}>
        {onRemove && (
          <div onClick={onRemove} className={styles.deleteButton}>
            <CloseIcon width={12} fill="#9A9A9A" />
          </div>
        )}
        {label}
      </span>

      {children}
    </div>
  );
};

export default ItemWithLabel;
