import { Avatar } from 'antd';
import React from 'react';

import { IAuthor } from '~common';

import styles from './styles.scss';

interface IProps {
  data: IAuthor;
}

const AuthorInfo: React.FC<IProps> = ({ data }) => {
  const { name, avatar, index, email, department } = data;

  return (
    <div className={styles.person}>
      <div className={styles.authorLeft}>
        <Avatar size={48} src={avatar?.url || ''}>
          {name[0]}
        </Avatar>

        <div className={styles.authorInfo}>
          <div className={styles.authorName}>
            {name}
            <div className={styles.authorId}>ID {index}</div>
          </div>

          <div className={styles.authorEmail}>{email}</div>
        </div>
      </div>

      <div className={styles.authorRight}>
        <div className={styles.authorType}>Кореспондент</div>

        {department && <div className={styles.authorDepartment}>Отдел «{department.name}»</div>}
      </div>
    </div>
  );
};

export default AuthorInfo;
