import { Button, Checkbox, Form, Input, Radio } from 'antd';
import cn from 'classnames';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { createModal } from 'react-modal-promise';

import { IFile, IHeaderPreview, InjectedModalProps } from '~common';
import { ButtonUpload, DeleteButton, ItemWithLabel, Modal } from '~components';
import { RichEditor, TOOLBAR_BUTTONS } from '~components/RichEditor';
import { EMPTY_RAW, EMPTY_STRING } from '~constants';
import s from '~containers/Subcategory/SubcategoriesModal/styles.scss';
import { useHeaderPreviewsStore, useUserStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { getUrlFromFile } from '~utils';

import styles from './styles.module.scss';

const CREATE_TITLE = 'Создание нового превью хедера';
const UPDATE_TITLE = 'Редактирование превью хедера';

const Desktop = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 3.5C1.5 2.94771 1.94772 2.5 2.5 2.5H15.5C16.0523 2.5 16.5 2.94772 16.5 3.5V12.5C16.5 13.0523 16.0523 13.5 15.5 13.5H2.5C1.94772 13.5 1.5 13.0523 1.5 12.5V3.5Z"
      stroke="#9A9A9A"
      strokeWidth="1.5"
    />
    <path d="M6 16.5H9M12 16.5H9M9 16.5V13.5" stroke="#9A9A9A" strokeWidth="1.5" />
  </svg>
);

const Tablet = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="15"
      y="2"
      width="14"
      height="12"
      rx="1"
      transform="rotate(90 15 2)"
      stroke="#9A9A9A"
      strokeWidth="1.5"
    />
    <path d="M7 14L11 14" stroke="#9A9A9A" strokeWidth="1.5" />
  </svg>
);

const Mobile = (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="3"
      y="2"
      width="8"
      height="14"
      rx="1"
      stroke="#9A9A9A"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M5 14H9" stroke="#9A9A9A" strokeWidth="1.5" />
  </svg>
);

const Left = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 4H2V6H18V4Z" fill="#9A9A9A" />
    <path d="M2 9H10V11H2V9Z" fill="#9A9A9A" />
    <path d="M2 14H14V16H2V14Z" fill="#9A9A9A" />
  </svg>
);

const Center = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4H2V6H18V4ZM6 9H14V11H6V9ZM4 14H16V16H4V14Z"
      fill="#9A9A9A"
    />
  </svg>
);

const Right = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4H2V6H18V4ZM10 9H18V11H10V9ZM6 14H18V16H6V14Z"
      fill="#9A9A9A"
    />
  </svg>
);

const HeaderPreviewsModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;
  const {
    editableHeaderPreview,
    isActionModal,
    toggleActionModal,
    resetEditableHeaderPreview,
    createHeaderPreview,
    updateHeaderPreviewById,
    deleteHeaderPreviewById,
  } = useHeaderPreviewsStore();
  const { checkPermissions } = useUserStore();

  const [currentAlignment, setCurrentAlignment] = useState(
    editableHeaderPreview?.textAlign || 'left',
  );

  const [form] = Form.useForm<IHeaderPreview>();

  const [cover, setCover] = useState(editableHeaderPreview?.cover?.url || EMPTY_STRING);

  const fileUploadProps = useMemo(
    () => ({
      name: 'file',
      action: `${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`,
      withCredentials: true,
      data: { type: 'card' },
      accept: 'image/*',
    }),
    [FileTransportLayer.fileUploadUrl],
  );

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleSubmit = useCallback(() => {
    toggleActionModal();
    setTimeout(() => {
      form.validateFields().then(values => {
        const heading = {
          data: {
            ...values,
            cover: getUrlFromFile(values?.cover),
            // @ts-ignore
            title: values?.title ? JSON.stringify(getData(values?.title)) : values?.title,
          },
        };
        if (editableHeaderPreview) {
          updateHeaderPreviewById(heading).then(handleClose);
        } else {
          createHeaderPreview(heading).then(handleClose);
        }
        toggleActionModal();
      });
    }, 3000);
  }, [editableHeaderPreview]);

  const handleDelete = () => {
    deleteHeaderPreviewById().then(handleClose);
  };

  const handleClose = useCallback(() => {
    resetEditableHeaderPreview && resetEditableHeaderPreview();
    form.resetFields();
    onClose(true);
  }, [form]);

  const getData = ({ raw: rawText }) => ({
    type: 'HeaderPreview',
    rawText,
  });

  const ModalTitle = editableHeaderPreview ? UPDATE_TITLE : CREATE_TITLE;

  return (
    <Modal destroyOnClose width={590} onClose={handleClose} isVisible={isOpen} title={ModalTitle}>
      <Form form={form}>
        <ItemWithLabel label="Обложка">
          <>
            {cover && (
              <div>
                <img className={styles.cardItemCover} src={cover} alt="Cover" />
              </div>
            )}
            <Form.Item
              name="cover"
              initialValue={get(editableHeaderPreview, 'cover')}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <ButtonUpload
                uploadProps={fileUploadProps}
                onChange={(data: IFile) => {
                  setCover(data?.url || EMPTY_STRING);
                }}
              />
            </Form.Item>
          </>
        </ItemWithLabel>
        <div className={styles.previewTitleWrapper}>
          <Form.Item
            name="title"
            rules={[
              () => ({
                validator(_, value) {
                  console.log('value', value);
                  if (value?.plainText) {
                    if (value.plainText.length <= 77) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Максимальная длина заголовка 77 символов!'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <RichEditor
              inline={false}
              withColorPicker
              disabledButtons={[
                TOOLBAR_BUTTONS.LINK,
                TOOLBAR_BUTTONS.UNORDERED_LIST,
                TOOLBAR_BUTTONS.ORDERED_LIST,
                TOOLBAR_BUTTONS.SUB,
                TOOLBAR_BUTTONS.SUP,
              ]}
              rawData={
                editableHeaderPreview?.title
                  ? JSON.parse(editableHeaderPreview?.title).rawText
                  : EMPTY_RAW
              }
              onChange={() => {
                console.log('onchange!');
              }}
            />
          </Form.Item>
          <div className={styles.sizesAndAlignmentWrapper}>
            <div className={styles.sizesWrapper}>
              <div className={styles.size}>
                <div style={{ margin: 'auto' }}>{Desktop}</div>
                <div>
                  <Form.Item
                    name="fontSizeDesktop"
                    initialValue={get(editableHeaderPreview, 'fontSizeDesktop') || 24}
                    style={{ margin: 0 }}
                  >
                    <Input className={styles.fontSize} />
                  </Form.Item>
                </div>
              </div>
              <div className={styles.size}>
                <div style={{ margin: 'auto' }}>{Tablet}</div>
                <div>
                  <Form.Item
                    name="fontSizeTablet"
                    initialValue={get(editableHeaderPreview, 'fontSizeTablet') || 16}
                    style={{ margin: 0 }}
                  >
                    <Input className={styles.fontSize} />
                  </Form.Item>
                </div>
              </div>
              <div className={styles.size}>
                <div style={{ margin: 'auto' }}>{Mobile}</div>
                <div>
                  <Form.Item
                    name="fontSizeMobile"
                    initialValue={get(editableHeaderPreview, 'fontSizeMobile') || 12}
                    style={{ margin: 0 }}
                  >
                    <Input className={styles.fontSize} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className={styles.alignmentWrapper}>
              <Form.Item
                name="textAlign"
                initialValue={get(editableHeaderPreview, 'textAlign') || 'left'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
                valuePropName="value"
                style={{ margin: 0 }}
              >
                <Radio.Group>
                  <Radio.Button
                    value="left"
                    key="left"
                    className={cn(currentAlignment === 'left' && styles.selected)}
                    onClick={() => setCurrentAlignment('left')}
                  >
                    {Left}
                  </Radio.Button>
                  <Radio.Button
                    value="center"
                    key="center"
                    className={cn(currentAlignment === 'center' && styles.selected)}
                    onClick={() => setCurrentAlignment('center')}
                  >
                    {Center}
                  </Radio.Button>
                  <Radio.Button
                    value="right"
                    key="right"
                    className={cn(currentAlignment === 'right' && styles.selected)}
                    onClick={() => setCurrentAlignment('right')}
                  >
                    {Right}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </div>

        <ItemWithLabel label="Ссылка для перехода">
          <Form.Item
            name="link"
            initialValue={get(editableHeaderPreview, 'link')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите ссылку для перехода" />
          </Form.Item>
        </ItemWithLabel>
        <Form.Item
          name="isOnHeader"
          initialValue={get(editableHeaderPreview, 'isOnHeader') || false}
          valuePropName="checked"
        >
          <Checkbox>Видимость в хедере</Checkbox>
        </Form.Item>
        <div className={s.modalControls}>
          <Button
            onClick={handleSubmit}
            type="primary"
            loading={isActionModal}
            disabled={
              !checkPermissions(`headerPreviews.${editableHeaderPreview ? 'update' : 'create'}`)
            }
          >
            Сохранить изменения
          </Button>
          {editableHeaderPreview && (
            <div className={s.deleteBtnWrapper}>
              <DeleteButton
                text="Удалить превью хедера"
                onDelete={handleDelete}
                disabled={!checkPermissions('headerPreviews.delete')}
              />
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
});

export const openHeaderPreviewsModal = createModal(HeaderPreviewsModal);
