import React from 'react';
import LazyLoad from 'react-lazy-load';

interface IProps {
  alt?: string;
  className?: string;
  url?: string;
}

const Image: React.FC<IProps> = props => {
  const { url, alt, ...rest } = props;
  return (
    <LazyLoad {...rest}>
      <img src={url} alt={alt} />
    </LazyLoad>
  );
};

export default Image;
