import { object, string, number } from 'yup';
import { EMPTY_STRING } from '~constants';
import { employeeSchema } from '~schemas/employee';
import { approverSchema } from '~schemas/approver';
import { statusSchema } from '~schemas/status';
import { strippedSchemaWhenNull } from '~schemas/transformations/stripSchemaWhenNull';
import { castBySchema } from '~utils/castBySchema';

/** @type object */
export const headBlockSchema = object()
  .default(undefined)
  .shape({
    id: string(),
    index: number(),
    createdAt: string()
      .defined()
      .default(null),
    end: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    /*
    publicationDate: string()
      .defined()
      .default(null),
    */
    resourceType: string(),
    start: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    status: statusSchema,
    updatedAt: string()
      .defined()
      .default(null),
    updatedBy: employeeSchema.defined().default(null),
    validatedAt: object()
      .default(undefined)
      .shape({
        corrector: string()
          .defined()
          .default(null),
      }),
    validatedBy: object()
      .default(undefined)
      .shape({
        corrector: approverSchema.defined(),
      }),
  })
  .from('_id', 'id');

export const castByHeadBlockSchema = castBySchema(headBlockSchema, { stripUnknown: true });
