import { string, object, number, array, bool } from 'yup'
import { compiledFileSchema, seoSchema } from '~schemas'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const compiledCategorySchema = object()
  .default(undefined)
  .shape({
    _id: string().required(),
    alias: string(),
    cover: compiledFileSchema,
    createdAt: string(),
    index: number(),
    order: number(),
    popularity: number(),
    postsByPriority: array(string()),
    seo: seoSchema,
    title: string(),
    updatedAt: string(),
    visible: bool()
  })

export const isValidCompiledCategory = isValidBySchema(compiledCategorySchema)
