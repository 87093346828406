import {
  always,
  complement,
  dissoc,
  head,
  identity,
  ifElse,
  is,
  isNil,
  map,
  pick,
  pipe,
  propEq,
  propOr,
  sum,
  unapply,
  when,
} from 'ramda';

import { IRaw } from '~common';
import { deepToJS } from '~utils/toJS';

export const isNotNil = complement(isNil);

export const isBoolean = is(Boolean);

export const isString = (value: any): value is string => typeof value === 'string';

export const noop = always(undefined);

// eslint-disable-next-line no-return-await
export const promiseAll = async promises => await Promise.all(promises);

// eslint-disable-next-line no-return-await
export const callAsync = async call => await call();

export const log = (...args) => data => {
  console.log.apply(null, args.concat([data]).map(deepToJS));
  return data;
};

export const resolvePromise = value => Promise.resolve(value);

export const rejectPromise = error => Promise.reject(error);

export const stripWhen = (key, value = null) => when(propEq(key, value), dissoc(key));

export const getFromStore = (store: string, fields: string[]) =>
  pipe(propOr({}, store), pick(fields));

export const stringLength = (value: string) => value.length;

export const nullable = always(null);

/**
 *
 */
export const getTextsCount = unapply(pipe(map(ifElse(isString, stringLength, always(0))), sum));

/**
 *
 */
export const getText = ifElse(
  isString,
  identity,
  (raw?: IRaw) => head(raw?.blocks || [])?.text || '',
);
