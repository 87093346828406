import {
  QuestionOutlined,
  SelectOutlined,
  UnorderedListOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react';
import { equals, not, pipe } from 'ramda';
import React, { useCallback } from 'react';

import { BlockType, IBlockComponent } from '~common';
import { Editor } from '~components/Editor';
import { withErrorBoundary } from '~components/ErrorBoundary';
import {
  CUSTOM_BLOCK_TYPE,
  defaultBlockComponents,
  defaultBlocks,
  defaultToolbarBlocks,
  EMPTY_STRING,
  INCUT_BLOCK_TYPE,
  NEWS_BLOCK_TYPE,
  QUIZ_BLOCK_TYPE,
  resourceTypes,
  STREAM_BLOCK_TYPE,
  typeConstants,
} from '~constants';
import { usePublicationStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { noop } from '~utils';

import customBlocks from '../../../../components/Blocks/CustomBlock/blocks.config.example';

const IncutBlock: IBlockComponent = {
  component: INCUT_BLOCK_TYPE,
  type: BlockType.IncutBlock,
  icon: <SelectOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
  blockProps: {
    title: 'Врезка',
  },
};

const StreamBlock: IBlockComponent = {
  component: STREAM_BLOCK_TYPE,
  type: BlockType.StreamBlock,
  icon: <VideoCameraOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
  blockProps: {
    title: 'Стрим',
  },
};

const NewsBlock: IBlockComponent = {
  component: NEWS_BLOCK_TYPE,
  type: BlockType.NewsBlock,
  icon: <UnorderedListOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
  blockProps: {
    title: 'Новости',
  },
};

const QuizBlock: IBlockComponent = {
  component: QUIZ_BLOCK_TYPE,
  type: BlockType.QuizBlock,
  icon: <QuestionOutlined style={{ width: 28, color: 'rgba(0,0,0,.3)' }} />,
  blockProps: {
    title: 'Опрос',
    postResourceType: resourceTypes.publication,
  },
  initialProps: {
    answers: [],
  },
};

const customBlocksToBlockComponent = (customBlocks: any[] = []) => {
  return customBlocks.map(({ title, icon, customType, ...rest }) => ({
    component: CUSTOM_BLOCK_TYPE,
    type: BlockType.CustomBlock,
    icon,
    initialProps: {
      customType,
      ...rest,
    },
    blockProps: { title },
  }));
};

export const editorBlockComponents = [
  ...defaultBlockComponents,
  StreamBlock,
  NewsBlock,
  IncutBlock,
  QuizBlock,
  ...customBlocksToBlockComponent(customBlocks),
];

interface IProps {
  onEditImage(): void;

  onKeyDown(): void;
}

export const EditorPublication: React.FC<IProps> = observer(props => {
  const { onEditImage = noop, onKeyDown = noop } = props;

  const {
    blocksArray,
    mandatoryBlocksArray,
    createBlock,
    lockBlock,
    unlockBlock,
    changeBlockPosition,
    createBlocksFromArray,
    updateBlock,
    deleteBlockById,
    symbols,
    status,
  } = usePublicationStore();

  const onFocusBlock = useCallback(
    ({ id = EMPTY_STRING }) => {
      lockBlock(id);
    },
    [lockBlock],
  );

  const onDragBlock = useCallback(
    data => {
      changeBlockPosition(data);
    },
    [changeBlockPosition],
  );

  const handleUnblock = useCallback(
    ({ id = EMPTY_STRING }) => {
      unlockBlock(id);
    },
    [unlockBlock],
  );

  return (
    <Editor
      blocks={blocksArray}
      mandatoryBlocks={mandatoryBlocksArray}
      onUnlockBlock={handleUnblock}
      onCreateBlock={createBlock}
      onCreateBlocksFromArray={createBlocksFromArray}
      publicationStatus={status?.id}
      onFocusBlock={onFocusBlock}
      onChangeBlock={updateBlock}
      onDragBlock={onDragBlock}
      onDeleteBlock={deleteBlockById}
      onEditImage={onEditImage}
      onKeyDown={onKeyDown}
      uploadPlaceholder={require('~assets/uploadPlaceholder.jpg')}
      uploadUrl={`${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`}
      toolbarBlocks={[
        ...defaultToolbarBlocks,
        STREAM_BLOCK_TYPE,
        NEWS_BLOCK_TYPE,
        INCUT_BLOCK_TYPE,
        CUSTOM_BLOCK_TYPE,
        QUIZ_BLOCK_TYPE,
      ]}
      defaultBlocks={[...defaultBlocks.filter(pipe(equals(QUIZ_BLOCK_TYPE), not))]}
      blockComponents={editorBlockComponents}
      maxSize={{
        [typeConstants.HEADER_BLOCK]: symbols.title,
        [typeConstants.SUBTITLE_BLOCK]: symbols.subtitle,
        [typeConstants.IMAGE_BLOCK]: symbols.mediaCaption,
        [typeConstants.VIDEO_BLOCK]: symbols.mediaCaption,
      }}
    />
  );
});

export default withErrorBoundary(EditorPublication);
