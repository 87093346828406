import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';

const getData = ({ data }) => data;

const HEADINGS_URL = '/headings';

export class HeadingsTransportLayer {
  getHeadings = (defaultFilter = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: HEADINGS_URL, defaultFilter });

    return API.get(promiseUrl).then(getData);
  };

  getHeadingsBySearch = term => {
    let url = HEADINGS_URL;

    if (term) {
      url = `${HEADINGS_URL}?search=${term}`;
    }

    return API.get(url).then(getData);
  };

  createHeading = heading => {
    return API.post(`${HEADINGS_URL}`, {
      ...heading,
    }).then(getData);
  };

  updateHeadingById = (id, heading) => {
    return API.patch(`${HEADINGS_URL}/${id}`, {
      data: { ...heading },
    }).then(getData);
  };

  deleteHeadingById = id => {
    return API.delete(`${HEADINGS_URL}/${id}`).then(getData);
  };

  changeOrdering = (id, targetPlacement, toEnd = false) => {
    let data;

    if (toEnd) {
      data = {
        meta: {
          after: targetPlacement,
        },
      };
    } else {
      data = {
        meta: {
          before: targetPlacement,
        },
      };
    }

    return API.post(`${HEADINGS_URL}/${id}/placement`, data);
  };
}
