import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { noop } from 'lodash'

import ToolBar from './ToolBar'
import Event from './Event'
import DayEvent from './DayEvent'
import WeekHeader from './WeekHeader'
import MonthHeader from './MonthHeader'
import MonthDateHeader from './MonthDateHeader'
import MonthEvent from './MonthEvent'
import ShowMoreButton from './ShowMoreButton'
import WeekEvent from './WeekEvent'
import SideCalendar from './SideCalendar'
import YearView from './YearView'

import '~stylesheets/calendar/styles.scss'

import styles from './styles.scss'

const localizer = momentLocalizer(moment)

@inject(stores => {
  const {
    setCalendarType,
    calendarType,
    selectedDate,
    setSelectedEvents,
    events,
    fetchEventsByCalendar,
    isCalendarViewLoading,
    setSelectedDate
  } = stores.ScheduleStore

  return {
    calendarType,
    setCalendarType,
    selectedDate,
    setSelectedEvents,
    events,
    fetchEventsByCalendar,
    isCalendarViewLoading,
    setSelectedDate
  }
})
@observer
class Schedule extends Component {
  componentDidMount() {
    const { fetchEventsByCalendar } = this.props
    fetchEventsByCalendar()
  }

  handleShowModalEvent = (props, date) => {
    const { setSelectedEvents } = this.props

    setSelectedEvents(props, date)
  }

  handleDayClick = day => {
    const { setSelectedDate } = this.props

    setSelectedDate(day)
  }

  render() {
    const { calendarType, selectedDate, events } = this.props

    const minTime = new Date()
    minTime.setHours(5, 0, 0, 0)

    return (
      <div className={styles.root}>
        <div className={styles.rootCalendar}>
          {calendarType === 'year' ? (
            <YearView />
          ) : (
            <Calendar
              localizer={localizer}
              date={selectedDate}
              min={minTime}
              onShowMore={this.handleShowModalEvent}
              messages={{
                showMore: ShowMoreButton
              }}
              components={{
                toolbar: ToolBar,
                event: Event,
                week: {
                  header: WeekHeader,
                  event: WeekEvent
                },
                day: {
                  event: DayEvent
                },
                month: {
                  header: MonthHeader,
                  dateHeader: MonthDateHeader,
                  event: MonthEvent
                }
              }}
              defaultView={calendarType}
              view={calendarType}
              onView={noop}
              onNavigate={noop}
              events={events}
              startAccessor={event => moment(event.start).toDate()}
              endAccessor={event => moment(event.end).toDate()}
            />
          )}
        </div>

        {calendarType !== 'year' && calendarType !== 'month' && (
          <div className={styles.sideCalendar}>
            <SideCalendar
              selectedDays={selectedDate}
              month={selectedDate}
              onDayClick={this.handleDayClick}
              modifiers={{
                weekend: {
                  daysOfWeek: [6, 0]
                }
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default Schedule

Schedule.propTypes = {
  calendarType: PropTypes.string,
  events: PropTypes.array,
  fetchEvents: PropTypes.func,
  fetchEventsByCalendar: PropTypes.any,
  isCalendarViewLoading: PropTypes.any,
  selectedDate: PropTypes.any,
  setSelectedDate: PropTypes.any,
  setSelectedEvents: PropTypes.func
}
