import { find, findIndex } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { inc, propEq } from 'ramda';
import API from '~api';
import { openCategoriesModal } from '~containers';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';
import FetchPaginationStore from '~store/fetchPaginationStore';
import {
  generateUrlWithSearch,
  showErrorNotification,
  showErrorNotificationInPromise,
} from '~utils';

const CATEGORIES_URL = '/categories';

export class CategoriesStore {
  @observable categories = [];

  @observable categoriesSearches = [];

  @observable isFirstLoading = true;

  @observable isActionModal = false;

  @observable isShowModal = false;

  @observable editableCategory = null;

  @observable allItemsCount = 0;

  @action
  fetchCategories = (defaultFilter = 'sort=+order') => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const { offset, setPageOffset } = FetchPaginationStore;

    const isNewFetch = offset === 0;
    if (isNewFetch) {
      this.categories = [];
      this.setAllItemsCount(0);
    }

    const promiseUrl = getPromiseUrl({ url: CATEGORIES_URL, defaultFilter });

    // TODO: FIXME: when backend realese sort by order

    return API.get(promiseUrl)
      .then(res => {
        const { data: categories = [], meta } = res.data;

        runInAction(() => {
          if (offset === 0) {
            this.categories = categories;
          } else {
            this.categories = [...this.categories, ...categories];
          }
          this.isFirstLoading = false;
          setPageOffset(inc(offset));
          this.setAllItemsCount(meta.count);
          return Promise.resolve(categories);
        });
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки категорий', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.isFirstLoading = false;
      });
  };

  @action
  fetchCategoriesBySearch = term => {
    const url = generateUrlWithSearch(CATEGORIES_URL, term);

    return API.get(url).then(res => {
      const { data } = res.data;

      runInAction(() => {
        this.categoriesSearches = data;
      });

      return data;
    });
  };

  @action
  createCategory = category => {
    const { resetPaginationParams } = FetchPaginationStore;

    runInAction(() => {
      this.isActionModal = true;
    });

    const createCategoryPromise = API.post(CATEGORIES_URL, category);

    return createCategoryPromise
      .then(res => {
        const { data } = res.data;
        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.categories.unshift(data);
          resetPaginationParams();
          this.fetchCategories();
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания категории',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    const { resetPaginationParams } = FetchPaginationStore;

    if (oldIndex === newIndex) return;

    const current = find(this.categories, { order: oldIndex });
    const target = find(this.categories, { order: newIndex });

    const currentId = find(this.categories, { order: oldIndex })._id;
    const targetId = findIndex(this.categories, ({ order }) => order === newIndex);

    let toEnd = false;

    let targetPlacement;

    if (targetId + 1 === this.categories.length) {
      targetPlacement = this.categories[this.categories.length - 1]._id;
      toEnd = true;
    } else {
      targetPlacement = this.categories[targetId + 1]._id;
    }

    if (oldIndex > newIndex) {
      targetPlacement = find(this.categories, { order: newIndex })._id;
    }

    let data;

    if (toEnd) {
      data = {
        meta: {
          after: targetPlacement,
        },
      };
    } else {
      data = {
        meta: {
          before: targetPlacement,
        },
      };
    }

    const changeOrderingPromise = API.post(`${CATEGORIES_URL}/${currentId}/placement`, data);

    current.order = newIndex;
    target.order = newIndex + 0.5;

    this.categories = this.categories.slice().sort(({ order: a }, { order: b }) => a - b);

    changeOrderingPromise.then(() => {
      resetPaginationParams();
      this.fetchCategories();
    });
  };

  @action
  updateCategory = category => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const { _id } = this.editableCategory || category;
    const updateCategoryPromise = API.patch(`${CATEGORIES_URL}/${_id}`, category);

    return updateCategoryPromise
      .then(res => {
        const { data } = res.data;
        const editableCategoryIndex = this.categories.findIndex(
          propEq('_id', this.editableCategory._id),
        );

        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.categories[editableCategoryIndex] = data;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  deleteCategory = () => {
    const { resetPaginationParams } = FetchPaginationStore;

    runInAction(() => {
      this.isActionModal = true;
    });

    const { _id } = this.editableCategory;
    const deleteCategoryPromise = API.delete(`${CATEGORIES_URL}/${_id}`);

    return deleteCategoryPromise
      .then(() => {
        this.categories.remove(this.editableCategory);

        resetPaginationParams();
        this.fetchCategories();

        this.resetEditableCategory();
        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  setEditableCategory = async value => {
    this.editableCategory = { seo: { title: '', description: '' }, ...value };

    await openCategoriesModal();
  };

  @action
  resetEditableCategory = () => {
    this.editableCategory = null;
  };

  @action
  setShowModal = () => {
    this.isShowModal = true;
  };

  @action
  setHideModal = () => {
    this.isShowModal = false;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @computed
  get categoriesLength() {
    return this.categories.length;
  }
}

export default new CategoriesStore();
