import { List } from 'antd';
import { isEmpty } from 'ramda';
import React, { useEffect } from 'react';

import ContentSpinner from '../ContentSpinner';

interface IProps<T = unknown> {
  fetch(): void;
  isFetching: boolean;
  items: T[];
  renderItem(item: T, index: number): React.ReactNode;
}

const ListWrapper: React.FC<IProps> = props => {
  const { items, renderItem, fetch, isFetching } = props;

  useEffect(() => {
    fetch();
  }, []);

  if (isEmpty(items) && isFetching) {
    return <ContentSpinner />;
  }

  return <List itemLayout="horizontal" dataSource={items} renderItem={renderItem} />;
};

export default ListWrapper;
