import React from 'react';
import FilerobotImageEditor from 'filerobot-image-editor';
import PropTypes from 'prop-types';
import { API, FileTransportLayer, fetchImageAsDataUrl } from '~services';
import { showErrorNotificationInPromise } from '~utils';
import config from './config';

function getFileInfoFromUrl(url = '') {
  const urlParts = url.split('/');
  const filename = urlParts[urlParts.length - 1];
  const filenameParts = filename.split('.');
  const name = filenameParts[0];
  const ext = filenameParts[1] || '';
  return {
    filename,
    name,
    ext,
  };
}

export default class ImageEditor extends React.Component {
  constructor() {
    super();
    this.state = { imgData: '' };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isShown = nextProps.shown;
    const isSrc = nextProps.src.length !== 0;
    const isData = nextState.imgData.length !== 0;

    // console.log('isShown', isShown, 'isSrc', isSrc, 'isData', isData)

    if (isShown && isSrc && isData) {
      return true;
    }

    if (isShown && isSrc && !isData) {
      // console.log('Загружаем картинку и обновляем стейт')
      fetchImageAsDataUrl(nextProps.src).then(data => {
        this.setState({ imgData: data });
      });
      return false;
    }

    if (!isShown && !isSrc && isData) {
      // console.log('Сбрасываем стэйт')
      this.setState({ imgData: '' });
      return true;
    }

    return false;
  }

  onComplete = result => {
    result.canvas.toBlob(
      blob => {
        const formData = new FormData();
        const { name, ext } = getFileInfoFromUrl(this.props.src);
        const filename = `${name}_${Date.now()}.${ext}`;
        const headers = {};

        formData.append('file', blob, filename);
        if (this.props.postId && this.props.postId !== 'new') {
          formData.append('post', this.props.postId);
        }

        API.post(FileTransportLayer.fileUploadUrl, formData, headers)
          .then(({ data: { data } }) => data)
          .then(response => {
            this.props.onChange(response);
          })
          .catch(showErrorNotificationInPromise({ title: 'Ошибка загрузки файла' }));
      },
      'image/jpeg',
      0.7,
    );

    // const blob = dataURItoBlob(dataUrl)
  };

  onBeforeComplete = () => {
    return false;
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    config.language = 'ru';
    const { imgData } = this.state;
    if (this.props.shown) {
      // console.log('render', '<FilerobotImageEditor />')
      return (
        <FilerobotImageEditor
          config={config}
          src={imgData}
          show={this.props.shown}
          onClose={this.onClose}
          onComplete={this.onComplete}
          onBeforeComplete={this.onBeforeComplete}
        />
      );
    }
    return null;
  }
}

ImageEditor.propTypes = {
  src: PropTypes.string,
  shown: PropTypes.string,
  postId: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};
