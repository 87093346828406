import { Col, Modal } from 'antd';
import cx from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { BlockType, IBlock, IBlockComponent, NonValidatableBlocks } from '~common';
import { AddButton, BlockSkeleton } from '~components';
import styles from '~components/Blocks/BlockWrapper/styles.styl';
import { EditorContext } from '~components/Editor';
import { EMPTY_OBJECT } from '~constants';
import { usePublicationStore } from '~hooks';
import { Close, Draggable as DraggableIcon } from '~icons';
import { debounce, noop, showErrorNotification } from '~utils';

const { confirm } = Modal;

interface IBlockWrapperProps {
  blockProps: IBlock;
  draggable?: boolean;
  index: number;
}

export const EditorBlockWrapper: React.FC<IBlockWrapperProps> = props => {
  const { blockProps, index, draggable = false } = props;
  const {
    type = BlockType.TextBlock,
    data,
    id,
    blocked,
    lockedBy,
    isCorrect,
    isError,
  } = blockProps;

  const {
    availableModules = [],
    canAddBlock,
    availableDefaultBlocks = [],
    onAddBlock,
    onAddBlocks,
    onChangeBlock,
    onDeleteBlock,
    onBlurBlock,
    onFocusBlock,
    blockComponents = [],
    onUnlockBlock,
    maxSize,
    onKeyDown,
    onDragBlock,
    onManualDragBlock,
    toolbar,
    onEditImage,
  } = useContext(EditorContext);

  const { getBlockOrderById } = usePublicationStore();

  const modulesArray = draggable ? availableModules : availableDefaultBlocks;

  const [isCreating, setIsCreating] = useState(false);

  const [inputVal, setIndex] = useState(Number(index) + 1);

  useEffect(() => {
    setIndex(Number(index) + 1);
  }, [index]);

  const dChangePosition = useMemo(
    () =>
      debounce((value: any) => {
        onManualDragBlock && onManualDragBlock(id, value);
      }, 250),
    [id, onManualDragBlock],
  );

  const changePosition = ({ target: { value } }) => {
    if (value > 0 && value !== index) {
      setIndex(value);
      dChangePosition(value);
    }
  };

  const onDelete = useCallback(
    event => {
      event.preventDefault();
      const x = window.scrollX;
      const y = window.scrollY;

      confirm({
        title: 'Вы уверены что хотите удалить блок?',
        content: 'При нажатии кнопки "Да", блок удалится и его нельзя будет восстановить!',
        okText: 'Да',
        cancelText: 'Нет',
        onOk() {
          onDeleteBlock && onDeleteBlock({ index, id });
          window.scrollTo({ left: x, top: y, behavior: 'smooth' });
        },
        onCancel() {
          window.scrollTo({ left: x, top: y, behavior: 'smooth' });
        },
      });
    },
    [onDeleteBlock],
  );

  const onFocus = useCallback(
    e => {
      !blocked &&
        !lockedBy?._id &&
        onFocusBlock &&
        onFocusBlock({ ...blockProps, type, id, data, index });

      return e;
    },
    [blocked, lockedBy?._id, onFocusBlock],
  );

  try {
    if (modulesArray[type]) {
      const Block = modulesArray[type];
      const handleBlockAdd = ({ type, initialProps = null }: IBlockComponent) => {
        setIsCreating(true);
        const canGetOrderById = getBlockOrderById(id) > 0;
        onAddBlock &&
          onAddBlock(
            { ...blockProps, type, index: canGetOrderById ? getBlockOrderById(id) : index + 1 },
            initialProps,
          ).finally(() => {
            setIsCreating(false);
          });
      };
      const onChange = data => onChangeBlock && onChangeBlock({ type, index, data, id });
      const onBlur = blockBlurData => {
        onBlurBlock && onBlurBlock({ type, id, data, blockBlurData });
      };

      const defaultBlockProperties = blockComponents.find(
        ({ type: blockType, initialProps = {} }) => {
          const { customType } = initialProps;
          return customType ? customType === data.customType : blockType === type;
        },
      );

      const props = {
        ...blockProps,
        ...(defaultBlockProperties?.blockProps || EMPTY_OBJECT),
        onUnlockBlock,
        onKeyDown,
        maxSize: maxSize?.[type],
      };

      let customProps = {};
      try {
        if (data.customType) {
          const { initialProps: { inputs = [] } = {} } =
            blockComponents.find(({ initialProps = null }) => {
              return (
                initialProps && initialProps?.inputs && initialProps?.customType === data.customType
              );
            }) || {};
          customProps = { inputs };
        }
      } catch (e) {
        showErrorNotification('Ошибка редактора', e);
      }

      const CommonBlock = () => (
        <div onMouseUp={onFocus} onSelect={onFocus} onDropCapture={onFocus}>
          <Block
            {...blockProps}
            {...data}
            {...customProps}
            data={data}
            blockProps={{ ...props, withCopyId: true, withViewChanges: true }}
            id={id}
            blocked={blocked}
            index={index}
            type={type}
            noDragEventsBubbling={type !== BlockType.ImageBlock}
            onChange={onChange}
            onDelete={onDelete}
            onDrag={onDragBlock}
            manualDrag={onManualDragBlock}
            onBlur={onBlur}
            onFocus={onFocus}
            onEditImage={onEditImage}
            addBlocks={onAddBlocks}
            addButton={
              canAddBlock && (
                <AddButton menuButtons={toolbar} onClick={handleBlockAdd} isCreating={isCreating} />
              )
            }
          />
        </div>
      );

      const DraggableBlock = () => (
        <>
          <Draggable key={id} draggableId={id || ''} index={index || 0} isDragDisabled={!!blocked}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={cx(
                  styles.draggable,
                  !isCorrect &&
                    type &&
                    !NonValidatableBlocks.includes(type) &&
                    styles.isCorrectError,
                  isError && styles.isError,
                )}
              >
                <div className={cx(styles.header, blocked && styles.blocked)}>
                  <span {...(blocked ? {} : provided.dragHandleProps)} className={styles.dragIcon}>
                    <DraggableIcon />
                  </span>

                  <span className={styles.deleteIcon} onClick={blocked ? noop : onDelete}>
                    <Close />
                  </span>
                </div>

                {CommonBlock()}

                <div className={cx(styles.footer)}>
                  <input
                    disabled={blocked}
                    type="text"
                    value={inputVal}
                    onFocus={({ target }) => {
                      target.select();
                    }}
                    onChange={changePosition}
                  />
                </div>

                <div className={cx(styles.addBtn, snapshot.isDragging && styles.hidden)}>
                  <AddButton
                    menuButtons={toolbar}
                    onClick={handleBlockAdd}
                    isCreating={isCreating}
                  />
                </div>
              </div>
            )}
          </Draggable>
          <BlockSkeleton isFetching={isCreating} />
        </>
      );

      const xsColProps = type => {
        switch (type) {
          case 'CLICKBAIT':
          case 'TITLE': {
            return 12;
          }
          default:
            return 24;
        }
      };

      const xlColProps = type => {
        switch (type) {
          case 'CLICKBAIT': {
            return 8;
          }

          case 'TITLE': {
            return 16;
          }
          default:
            return 24;
        }
      };

      const NonDraggableBlock = () => (
        <Col
          xs={xsColProps(type)}
          xl={xlColProps(type)}
          className={cx(
            styles.nonDraggable,
            styles.withPB,
            (type === 'TITLE' || type === 'CLICKBAIT') && styles.inRowBlocks,
            !isCorrect && type && !NonValidatableBlocks.includes(type) && styles.isCorrectError,
            isError && styles.isError,
          )}
        >
          {CommonBlock()}
        </Col>
      );

      return draggable ? DraggableBlock() : NonDraggableBlock();
    }
  } catch (e) {
    showErrorNotification('Ошибка редактора', e);
  }
  return null;
};
