import React, { Component } from 'react'
import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import { TimersModal, TimersList } from '~components/Timers'

const PAGE_TITLE = 'Настройка таймеров'

class SettingsTimersPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <TimersList />
        </ContentWrapper>
        <TimersModal />
      </>
    )
  }
}

export default SettingsTimersPage
