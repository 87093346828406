import { Result } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserStore } from '~hooks';

export const OAuthRedirect: FC = observer(() => {
  const { location } = useHistory();
  const { getOAuthCallbackUrl } = useUserStore();

  useEffect(() => {
    window.location.href = getOAuthCallbackUrl(location.search);
  }, []);

  return <Result status="info" title="Идет авторизация через SSO..." />;
});
