import { Modal } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback } from 'react';

import { IDictionary, IFeedback } from '~common';
import { AttachmentFileLink, WhiteBlockWithShadow } from '~components';
import { useFeedbackStore, useUserStore } from '~hooks';
import { Close, ReplyArrow } from '~icons';

import styles from './styles.scss';

const { confirm } = Modal;

interface IProps {
  dictionaries: IDictionary[];
  feedback: IFeedback;
}

const FeedbackItem = observer((props: IProps) => {
  const { dictionaries, feedback } = props;
  const { type, subject, content, attachments, senderName, senderEmail, createdAt } = feedback;
  const feedbackType = dictionaries.find(({ id }) => id === type);
  const { deleteFeedback } = useFeedbackStore();
  const { checkPermissions } = useUserStore();

  const onDelete = useCallback(() => {
    confirm({
      title: 'Вы уверены что хотите удалить это письмо?',
      okText: 'Да',
      cancelText: 'Отмена',
      onOk() {
        deleteFeedback(feedback);
      },
    });
  }, [deleteFeedback, feedback]);

  return (
    <WhiteBlockWithShadow>
      <div className={styles.root}>
        <a href={`mailto:${senderEmail}`} className={styles.replyLink}>
          <ReplyArrow className={styles.replyIcon} />
        </a>
        {checkPermissions('feedbacks.update') && (
          <Close className={styles.deleteIcon} onClick={onDelete} />
        )}
        <div className={styles.left}>
          <div className={styles.type}>{feedbackType && feedbackType.ru}</div>
          <h3 className={styles.subject}>{subject}</h3>
          <p className={styles.content}>{content}</p>
          {attachments && (
            <div className={styles.attachments}>
              {attachments.map((item, i) => (
                <AttachmentFileLink key={i} file={item} />
              ))}
            </div>
          )}
        </div>
        <div className={styles.right}>
          <div>
            <div className={styles.senderName}>{senderName}</div>
            <div className={styles.senderEmail}>{senderEmail}</div>
          </div>
          <div className={styles.createdAt}>{moment(createdAt).format('MM.DD.YYYY HH:mm:ss')}</div>
        </div>
      </div>
    </WhiteBlockWithShadow>
  );
});

export default FeedbackItem;
