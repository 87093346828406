import { EditorState } from 'draft-js';
import EditorUtils from 'draft-js-plugins-utils';
import React, { ComponentType } from 'react';

import { Link as DefaultLink, LinkButton } from './components';
import linkStrategy, { matchesEntityType } from './linkStrategy';
import theme from './styles.styl';

interface IConfig {
  Link?: ComponentType;
  linkTarget?: string;
  placeholder?: string;
}

export interface IStore {
  getEditorState?(): EditorState;
  setEditorState?(state: EditorState): void;
}

export default (config?: IConfig) => {
  const { placeholder, Link, linkTarget } = config || {};

  const store: IStore = {
    getEditorState: undefined,
    setEditorState: undefined,
  };

  const DecoratedDefaultLink = props => (
    <DefaultLink {...props} className={theme.link} target={linkTarget} />
  );

  const DecoratedLinkButton = props => (
    <span className={theme.wrapper}>
      <LinkButton
        {...props}
        ownTheme={theme}
        store={store}
        placeholder={placeholder}
        onRemoveLinkAtSelection={() =>
          store.setEditorState &&
          store.setEditorState(
            EditorUtils.removeLinkAtSelection(store.getEditorState && store.getEditorState()),
          )
        }
      />
    </span>
  );

  return {
    initialize: ({ getEditorState, setEditorState }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },

    decorators: [
      {
        strategy: linkStrategy,
        matchesEntityType,
        component: Link || DecoratedDefaultLink,
      },
    ],

    LinkButton: DecoratedLinkButton,
  };
};
