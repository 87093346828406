import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { ISection } from '~common';
import { Image, WhiteBlockWithShadow } from '~components';
import { InjectStores } from '~store';

import s from './styles.scss';

interface IProps extends InjectStores<'SectionsStore'> {
  data?: ISection;
}

@inject('SectionsStore')
@observer
class SectionsItem extends Component<IProps> {
  handleClick = () => {
    const { SectionsStore: { setEditableSection } = {}, data } = this.props;

    setEditableSection && setEditableSection(data);
  };

  render() {
    const { data: { title, visible, cover } = {} } = this.props;

    return (
      <WhiteBlockWithShadow>
        <div className={s.card} onClick={this.handleClick}>
          <h2 className={s.cardTitle}>{title}</h2>
          <div className={s.cardItem}>
            {cover && <Image className={s.cardItemCover} url={cover.url} alt="Cover" />}
            <div>
              <h3 className={s.cardItemTitle}>Опубликована на сайте:</h3>
              <p className={s.cardItemValue}>{visible ? 'Да' : 'Нет'}</p>
            </div>
          </div>
        </div>
      </WhiteBlockWithShadow>
    );
  }
}

export default SectionsItem;
