import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PlaylistsFilterItem from '~components/Playlists/PlaylistsFilterItem'
import styles from './styles.scss'

const FILTERS = [
  {
    value: '',
    text: 'Все'
  },
  {
    value: 'false',
    text: 'Скрытые'
  },
  {
    value: 'true',
    text: 'Активные'
  }
]

class PlaylistsFilter extends Component {
  render() {
    return (
      <ul className={styles.navigation}>
        {FILTERS.map(filter => (
          <PlaylistsFilterItem key={filter.text} filter={filter} />
        ))}
      </ul>
    )
  }
}

PlaylistsFilter.propTypes = {
  FeedbackStore: PropTypes.any
}

export default PlaylistsFilter
