import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import DropDownItem from '~components/Publications/FilterDropDown/dropDownItem'

import styles from '~components/Publications/FilterDropDown/styles.scss'

@inject(stores => {
  const { filtersItem, setFilterItemActive, selectedFilterItems } = stores.VideosStore

  return {
    filtersItem,
    setFilterItemActive,
    selectedFilterItems
  }
})
@observer
class FilterDropDown extends Component {
  render() {
    const { filtersItem, setFilterItemActive, selectedFilterItems } = this.props

    return (
      <div className={styles.root}>
        {filtersItem
          .filter(item => !item.fix)
          .map(
            (item, index) =>
              !selectedFilterItems.includes(item) && (
                <DropDownItem key={index} item={item} onClick={setFilterItemActive} />
              )
          )}
      </div>
    )
  }
}

export default FilterDropDown

FilterDropDown.propTypes = {
  filtersItem: PropTypes.object,
  setFilterItemActive: PropTypes.func,
  selectedFilterItems: PropTypes.array
}
