import { always, cond, equals, pathOr } from 'ramda';
import { ReactNode } from 'react';

import { resourceTypes } from '~constants';

export const checkPermissions = (
  authority:
    | string
    | string[]
    | ((rights: Record<string, Record<string, boolean>>) => boolean | Promise<boolean>)
    | Promise<string>,
  rights: boolean | Record<string, boolean | Record<string, boolean>>,
  target: ReactNode | boolean = true,
  Exception: ReactNode | boolean = false,
) => {
  // Retirement authority, return target;
  if (!authority) {
    return target;
  }

  if (typeof rights === 'boolean') {
    return rights ? target : Exception;
  }

  // array
  if (Array.isArray(authority)) {
    if (authority.some(item => pathOr(false, item.split('.'), rights))) {
      return target;
    }

    return Exception;
  }
  // string
  if (typeof authority === 'string') {
    if (pathOr(false, authority.trim().split('.'), rights)) {
      return target;
    }

    return Exception;
  }
  // Promise
  if (authority instanceof Promise) {
    return null;
    // return <PromiseRender ok={target} error={Exception} promise={authority} />;
  }
  // Function
  if (typeof authority === 'function') {
    // eslint-disable-next-line no-useless-catch
    try {
      // @ts-ignore
      const bool = authority(rights);
      // Promise
      if (bool instanceof Promise) {
        return null;
        // return <PromiseRender ok={target} error={Exception} promise={bool} />;
      }
      if (bool) {
        return target;
      }
      return Exception;
    } catch (error) {
      throw error;
    }
  }
  throw new Error('unsupported parameters');
};

export const getAuthorityByResourceType = cond<string, string>([
  [equals(resourceTypes.publication), always('posts')],
  [equals(resourceTypes.textTranslation), always('textTranslations')],
  [equals(resourceTypes.quiz), always('quiz')],
]);
