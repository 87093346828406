import { observable, action, runInAction } from 'mobx'
import API from '~api'

const INFO_PAGES_URL = '/pages'

class InfoPagesStore {
  @observable pages = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editablePage = null

  @action
  fetchInfoPages = () => {
    const fetchInfoPagesPromise = API.get(INFO_PAGES_URL)

    return fetchInfoPagesPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.pages = data
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  createInfoPage = page => {
    runInAction(() => {
      this.isActionModal = true
    })

    const createInfoPagePromise = API.post(INFO_PAGES_URL, page)

    return createInfoPagePromise
      .then(res => {
        const { data } = res.data
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.pages.unshift(data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  updateInfoPage = page => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editablePage
    const updateInfoPagePromise = API.patch(`${INFO_PAGES_URL}/${_id}`, page)

    return updateInfoPagePromise
      .then(res => {
        const { data } = res.data
        const editablePageIndex = this.pages.indexOf(this.editablePage)

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.pages[editablePageIndex] = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  deleteInfoPage = () => {
    runInAction(() => {
      this.isActionModal = true
    })

    const { _id } = this.editablePage
    const deleteInfoPagePromise = API.delete(`${INFO_PAGES_URL}/${_id}`)

    return deleteInfoPagePromise
      .then(() => {
        this.pages.remove(this.editablePage)

        this.resetEditablePage()
        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditablePage = value => {
    this.editablePage = value

    this.setShowModal()
  }

  @action
  resetEditablePage = () => {
    this.editablePage = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new InfoPagesStore()
