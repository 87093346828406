import { observer } from 'mobx-react';
import React from 'react';

import { IHeading } from '~common';
import { WhiteBlockWithShadow } from '~components';
import { useHeadingsStore } from '~hooks';

import s from './styles.scss';

const HeadingsItem: React.FC<{ data: IHeading }> = observer(({ data }) => {
  const { setEditableHeading } = useHeadingsStore();

  const { title, link, visible, isOnHeader } = data;

  const handleClick = () => {
    setEditableHeading && setEditableHeading(data);
  };

  return (
    <WhiteBlockWithShadow>
      <div className={s.card} onClick={handleClick}>
        <h2 className={s.cardTitle}>{title}</h2>
        <h3 className={s.cardSubtitle}>Ссылка для перехода:</h3>
        <h3 className={s.cardLink}>{link}</h3>
        <div className={s.cardItem}>
          <div>
            <h3 className={s.cardItemTitle}>Показывать в Актуальном:</h3>
            <p className={s.cardItemValue}>{visible ? 'Да' : 'Нет'}</p>
          </div>
        </div>
        <div className={s.cardItem}>
          <div>
            <h3 className={s.cardItemTitle}>Видимость на хедере:</h3>
            <p className={s.cardItemValue}>{isOnHeader ? 'Да' : 'Нет'}</p>
          </div>
        </div>
      </div>
    </WhiteBlockWithShadow>
  );
});

export default HeadingsItem;
