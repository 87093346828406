import React from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import { Card, Collapse, Button } from 'antd';

const { Panel } = Collapse;

function download(data, filename, type) {
  const file = new Blob([data], { type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

function makeErrorMsg(title, error) {
  if (title.includes('ImageEditorWrapper')) {
    if (error === 'RangeError: Invalid array length') {
      return 'Неожиданная ошибка. Возможно, из-за слишком высокого разрешения изображения. Попробуйте уменьшить разрешение или используйте другое изображение.';
    } else {
      return 'Неожиданная ошибка.';
    }
  } else {
    return null;
  }
}

class ErrorBoundary extends React.Component {
  state = { error: null, info: null };

  componentDidCatch(error, info) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    // logErrorToMyService(error, errorInfo);
    this.setState({ error, info, trace: global.TraceKit.report(error, true) });
    this.props?.onError(error, info?.componentStack);
    // console.error(error, info)
  }

  render() {
    const { error, info, trace } = this.state;
    const { fallbackRender, FallbackComponent, fallback } = this.props;

    if (error != null) {
      const props = {
        componentStack: info?.componentStack,
        error,
        info,
        trace,
      };

      if (React.isValidElement(fallback)) {
        return fallback;
      }

      if (typeof fallbackRender === 'function') {
        return fallbackRender(props);
      }

      if (typeof FallbackComponent === 'function') {
        return <FallbackComponent {...props} />;
      }

      throw new Error('error in error-boundary');
    }

    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  onError: () => {},
  /* eslint-disable react/prop-types,react/display-name,no-param-reassign */
  FallbackComponent: ({ error = null, componentStack = '', trace = '' }) => {
    let title;
    try {
      title = componentStack
        .trim()
        .split(' in ')[0]
        .slice(3);
    } catch (e) {
      title = 'Unknown';
    }

    return (
      <Collapse style={{ margin: '15px 0' }}>
        <Panel header={`Что-то пошло не так в ${title}`} key="1">
          <Button
            type="primary"
            style={{ margin: '0 0 16px 0' }}
            onClick={() => {
              download(
                JSON.stringify({ trace, error, componentStack }),
                `admin_${new Date().toISOString().replace('.', ';')}`,
                'txt',
              );
            }}
          >
            <DownloadOutlined style={{ fontSize: 20 }} />
            Скачать и передать разработчикам
          </Button>
          <Card title="Ошибка">{makeErrorMsg(title, String(error)) || String(error)}</Card>
          <br />
          <Card title="Стек компонентов" style={{ whiteSpace: 'break-spaces' }}>
            {componentStack.trim().replace(' in ', ' \nin ')}
          </Card>
        </Panel>
      </Collapse>
    );
  },
};

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  onError: PropTypes.func,
  fallback: PropTypes.element,
  fallbackRender: PropTypes.func,
  FallbackComponent: PropTypes.any,
};

export function withErrorBoundary(Component, errorBoundaryProps) {
  function Wrapped(props) {
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <Component {...props} />
      </ErrorBoundary>
    );
  }

  // Format for display in DevTools
  const name = Component.displayName || Component.name || 'Unknown';
  Wrapped.displayName = `withErrorBoundary(${name})`;

  return Wrapped;
}

export default ErrorBoundary;
