import '../../stylesheets/global.styl';
import '../../stylesheets/mixins.styl';

import { Row } from 'antd';
import { always, assoc, dec, ifElse, prop } from 'ramda';
import React, { ComponentType, FC, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import shortid from 'shortid';

import { BlockType, IBlock, IBlockComponent } from '~common';
import { BlockSkeleton } from '~components';
import {
  defaultBlockComponents,
  defaultBlocks as nonDraggableBlocks,
  defaultToolbarBlocks,
} from '~constants';
import { getBlockName, isNotNil, noop } from '~utils';

import * as Blocks from '../Blocks';
import { AddButton, EditorBlockWrapper } from '../Blocks';
import styles from './styles.scss';

interface IProps {
  blockComponents: IBlockComponent[];
  blocks: IBlock[];
  canAddBlock?: boolean;
  defaultBlocks: string[];
  mandatoryBlocks: IBlock[];
  maxSize?: { [key in BlockType]?: string };

  onBlurBlock?(block: IBlock & { blockBlurData: any }): void;

  onChange?(blocks: IBlock[]): void;

  onChangeBlock?(data: Pick<IBlock, 'id' | 'data'>): void;

  onCreateBlock?(block: IBlock, initialProps: IBlock['initialProps']): Promise<any>;

  onCreateBlocksFromArray?(blocks: IBlock[], before: IBlock['before']): void;

  onDeleteBlock?(id: IBlock['id']): void;

  onDragBlock?(data: Pick<IBlock, 'id' | 'before'> & { prevBefore?: string | null }): void;

  onEditImage(): void;

  onFocusBlock?(block: IBlock): void;

  onKeyDown?(event: React.KeyboardEvent): void;

  onManualDragBlock?(id?: string, value?: any): void;

  onUnlockBlock?(block: Pick<IBlock, 'id' | 'lockedBy'> & { force: boolean }): void;
  publicationStatus?: string;
  toolbarBlocks: string[];
  uploadPlaceholder: string;
  uploadUrl: string;
}

type AvailableModules = { [key: string]: ComponentType };

interface IEditorContext {
  availableDefaultBlocks: AvailableModules;
  availableModules: AvailableModules;
  blockComponents: IBlockComponent[];
  canAddBlock?: boolean;
  maxSize?: { [key in BlockType]?: string };

  onAddBlock?(
    block: IBlock,
    initialProps: IBlock['initialProps'],
    before?: IBlock['before'],
  ): Promise<any>;

  onAddBlocks?(blocks: IBlock[], insertIndex: number, type: BlockType): void;

  /** No use */
  onBlurBlock?(block: IBlock & { blockBlurData: any }): void;

  onChangeBlock?(block: IBlock): void;

  onDeleteBlock?(data: Pick<IBlock, 'id' | 'index'>): void;

  onDragBlock?(drop: DropResult): void;

  onEditImage?(url: string, cb?: (data: any) => void): void;

  onFocusBlock?(block: IBlock): void;

  onKeyDown?(event: React.KeyboardEvent, id?: string): void;

  onManualDragBlock?(id?: string, value?: any): void;

  onUnlockBlock?(block: Pick<IBlock, 'id' | 'lockedBy'> & { force: boolean }): void;

  toolbar?: IBlockComponent[];
  uploadPlaceholder?: string;
  uploadUrl?: string;
}

export const EditorContext = React.createContext<IEditorContext>({
  availableDefaultBlocks: {},
  availableModules: {},
  blockComponents: [],
});

const getBefore = ifElse(isNotNil, prop('id'), always(null));

const Editor: FC<IProps> = props => {
  const [isCreating, setIsCreating] = useState(false);

  const {
    blocks = [],
    mandatoryBlocks = [],
    toolbarBlocks = defaultToolbarBlocks,
    defaultBlocks = nonDraggableBlocks,
    blockComponents = defaultBlockComponents,
    canAddBlock = true,
    maxSize,
    uploadUrl,
    uploadPlaceholder,
    publicationStatus,
    onCreateBlock = () => Promise.resolve(null),
    onCreateBlocksFromArray = noop,
    onDeleteBlock = noop,
    onUnlockBlock = noop,
    onChangeBlock = noop,
    onBlurBlock = noop,
    onFocusBlock = noop,
    onDragBlock = noop,
    onChange = noop,
    onKeyDown = noop,
    onEditImage = noop,
  } = props;

  const availableModules = toolbarBlocks.reduce((availableModules, toolbarItem) => {
    const block = blockComponents.find(({ type }) => type === toolbarItem);

    if (block && block.component) {
      const blockName = getBlockName(block.component as string);

      return assoc(toolbarItem, Blocks[blockName], availableModules);
    }

    return availableModules;
  }, {});

  const availableDefaultBlocks = defaultBlocks.reduce((availableDefaultBlocks, simpleBlockKey) => {
    const block = blockComponents.find(({ type }) => type === simpleBlockKey);

    if (block && block.component) {
      const blockName = getBlockName(block.component as string);

      return assoc(simpleBlockKey, Blocks[blockName], availableDefaultBlocks);
    }

    return availableDefaultBlocks;
  }, {});

  const availableToolbar = blockComponents.filter(({ type }) => toolbarBlocks.indexOf(type) > -1);

  const handleAddBlocks = useCallback(
    (blocksToAdd: IBlock[], insertIndex: number, type: BlockType) => {
      const before = insertIndex > dec(blocks.length) ? null : blocks[insertIndex].id;
      const blocksToCreate = blocksToAdd.map(block => ({
        ...block,
        type,
        charactersCount: (block.rawText || { blocks: [] }).blocks.reduce(
          (acc, { text }) => acc + text.length,
          0,
        ),
      }));

      return onCreateBlocksFromArray && onCreateBlocksFromArray(blocksToCreate, before);
    },
    [onCreateBlocksFromArray, blocks],
  );

  const handleAddBlock = useCallback(
    (
      { type, index = 0 }: IBlock,
      initialProps: IBlock['initialProps'],
      before?: IBlock['before'],
    ) => {
      const newBlock = {
        type,
        index,
        id: shortid.generate(),
        data: initialProps || null,
      };

      const beforeId = before || (index > blocks.length - 1 ? null : blocks[index].id);

      return onCreateBlock && onCreateBlock({ ...newBlock, before: beforeId }, initialProps);
    },
    [blocks, onCreateBlock],
  );

  const handleDragBlock = useCallback(
    ({ draggableId, source, destination }: DropResult) => {
      if (!destination) return;

      const { index: dragIndex } = source;
      const { index: dropIndex } = destination;

      if (dragIndex === dropIndex) return;

      const lastIndex = blocks.length - 1;
      const insertIndex = dragIndex < dropIndex ? dropIndex + 1 : dropIndex;
      const prevIndex = dragIndex > dropIndex ? dragIndex + 1 : dragIndex;

      const beforeBlock = dropIndex === lastIndex ? null : blocks[insertIndex];
      const preBeforeBlock = dragIndex === lastIndex ? null : blocks[prevIndex];
      const before = beforeBlock != null ? beforeBlock.id : null;
      const prevBefore = preBeforeBlock != null ? preBeforeBlock.id : null;

      onDragBlock && onDragBlock({ id: draggableId, before, prevBefore });
    },
    [blocks, onDragBlock],
  );

  const handleManualDragBlock = useCallback(
    (id: string, index: number) => {
      const currentIdx = blocks.findIndex(b => b.id === id);
      let idx = Number(index) + (currentIdx + 1 > Number(index) ? -1 : 0);

      if (idx < 1) {
        idx = 0;
      }

      const before = getBefore(blocks[idx]);

      onDragBlock && onDragBlock({ id, before });
    },
    [blocks, onDragBlock],
  );

  const handleChangeBlock = useCallback(
    ({ index, data, id }: IBlock) => {
      onChangeBlock && onChangeBlock({ id, data });
    },
    [onChangeBlock],
  );

  const handleDeleteBlock = useCallback(
    ({ index, id }: Pick<IBlock, 'id' | 'index'>) => {
      onDeleteBlock && onDeleteBlock(id);
    },
    [onDeleteBlock],
  );

  const onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const context: IEditorContext = {
    availableModules,
    availableDefaultBlocks,
    blockComponents,
    canAddBlock,
    maxSize,
    toolbar: availableToolbar,
    uploadUrl,
    uploadPlaceholder,
    onAddBlock: handleAddBlock,
    onAddBlocks: handleAddBlocks,
    onDeleteBlock: handleDeleteBlock,
    onUnlockBlock,
    onChangeBlock: handleChangeBlock,
    onBlurBlock,
    onFocusBlock,
    onDragBlock: handleDragBlock,
    onManualDragBlock: handleManualDragBlock,
    onEditImage,
    onKeyDown,
  };

  if (Object.keys(availableModules).length === 0) return <div>Нет доступных модулей</div>;

  return (
    <EditorContext.Provider value={context}>
      <div className={styles.wrapper}>
        <Row className={styles.mandatoryBlocks}>
          {mandatoryBlocks.map((block, index) => (
            <EditorBlockWrapper
              index={index}
              blockProps={{ ...block, uploadUrl, publicationStatus }}
              key={block.id}
            />
          ))}
        </Row>
        {canAddBlock && (
          <AddButton
            menuButtons={availableToolbar}
            onClick={({ type, initialProps = null }: IBlock) => {
              setIsCreating(true);
              handleAddBlock &&
                handleAddBlock({ type }, initialProps).finally(() => {
                  setIsCreating(false);
                });
            }}
            isCreating={isCreating}
          />
        )}
        <BlockSkeleton isFetching={isCreating} />

        <DragDropContext onDragStart={onDragStart} onDragEnd={handleDragBlock}>
          <Droppable droppableId="main">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={styles.droppable}
              >
                {blocks
                  // @ts-ignore
                  .filter(block => !defaultBlocks.includes(block.type))
                  .map((block, index) => (
                    <EditorBlockWrapper
                      index={index}
                      blockProps={{ ...block, uploadUrl, publicationStatus }}
                      key={block.id}
                      draggable
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </EditorContext.Provider>
  );
};

export default Editor;
