import { Button, Form, Input } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';

import { DeleteButton, ItemWithLabel, Modal } from '~components';
import { useDepartmentsStore, useUserStore } from '~hooks';

import styles from './styles.scss';

const CREATE_TITLE = 'Создание нового отдела';
const UPDATE_TITLE = 'Редактирование отдела';

const DepartmentsModal = observer(() => {
  const {
    isShowModal,
    setHideModal,
    editableDepartment,
    createDepartment,
    updateDepartment,
    resetEditableDepartment,
    isActionModal,
    deleteDepartment,
  } = useDepartmentsStore();
  const { checkPermissions } = useUserStore();
  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    async values => {
      const department = {
        data: values,
      };

      const action = editableDepartment ? updateDepartment : createDepartment;

      await action(department);

      form.setFieldsValue({ name: editableDepartment?.name ?? '' });
    },
    [createDepartment, editableDepartment, updateDepartment],
  );

  const modalTitle = useMemo(() => (editableDepartment ? UPDATE_TITLE : CREATE_TITLE), [
    editableDepartment,
  ]);

  useEffect(() => {
    form.setFieldsValue({ name: editableDepartment?.name ?? '' });
  }, [editableDepartment]);

  return (
    <Modal
      destroyOnClose
      width={590}
      onClose={setHideModal}
      isVisible={isShowModal}
      title={modalTitle}
      afterClose={resetEditableDepartment}
    >
      <div className={styles.root}>
        <Form form={form} onFinish={handleSubmit}>
          <ItemWithLabel label="Название отдела">
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              required
            >
              <Input autoFocus disabled={isActionModal} placeholder="Введите название" />
            </Form.Item>
          </ItemWithLabel>

          <div className={styles.modalControls}>
            <Button
              loading={isActionModal}
              disabled={
                isActionModal ||
                !checkPermissions(`departments.${editableDepartment ? 'update' : 'create'}`)
              }
              htmlType="submit"
              type="primary"
            >
              Сохранить изменения
            </Button>

            {editableDepartment && (
              <div className={styles.deleteBtnWrapper}>
                <DeleteButton
                  text="Удалить отдел"
                  onDelete={deleteDepartment}
                  disabled={!checkPermissions('departments.delete')}
                />
              </div>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
});

export default DepartmentsModal;
