import classNames from 'classnames';
import React, { useCallback, useContext, useState } from 'react';

import { DropType, IBlock, IFile } from '~common';
import { ButtonShape } from '~constants';
import { Close } from '~icons';
import { isAnyTruthy } from '~utils';

import { BlockButton } from '../../BlockButton';
import { DropZone } from '../../DropZone';
import { EditorContext } from '../../Editor';
import { RichEditor } from '../../RichEditor';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

interface IProps extends IBlock {
  _id?: string;
  alt?: string;
  altHTML?: unknown;
  autoplay?: boolean;
  charactersCount?: number;
  cover?: IFile;
  footer?: boolean;
  format?: string;
  loop?: boolean;
  media?: unknown;
  mimeType?: string;
  mimetype?: string;
  mute?: boolean;
  onBlur?(): void;
  onChange?(data: IBlock): void;
  onDelete?(): void;
  size?: number;
  uploadUrl?: string;
  url?: string;
}

const VideoBlock: React.FC<IProps> = props => {
  const { uploadUrl: defaultUploadUrl = '' } = useContext(EditorContext);
  const { uploadUrl = defaultUploadUrl, onChange } = props;
  const [isUploading, setIsUploading] = useState(false);

  const save = useCallback(
    (data: any) => {
      const {
        url = '',
        alt = '',
        size,
        mimetype,
        mimeType,
        media,
        cover = {
          url: '',
          alt: '',
          size: 0,
          mimetype: '',
        },
        loop,
        mute,
        autoplay,
        format,
        altHTML,
        _id,
      } = props;

      onChange &&
        onChange({
          url,
          alt,
          size,
          mimetype,
          mimeType,
          media,
          cover,
          loop,
          mute,
          autoplay,
          format,
          altHTML,
          _id,
          ...data,
        });
    },
    [onChange, props],
  );

  const onChangeDescription = useCallback(
    ({ raw: alt, plainText, html }) => {
      save({
        alt: JSON.stringify(alt),
        charactersCount: plainText.length,
        altHTML: html,
      });
    },
    [save],
  );

  const onChangeFormat = useCallback(
    ({ target: { value } }) => {
      save({ format: value });
    },
    [save],
  );

  const onChangeCheckbox = useCallback(
    ({ target: { name, checked } }) => {
      save({ [name]: checked });
    },
    [save],
  );

  const onProgressChange = useCallback((progress: number) => {
    setIsUploading(progress > 0);
  }, []);

  const onClickDelete = useCallback(() => {
    save({
      url: '',
      size: 0,
      _id: '',
      mimeType: '',
    });
  }, [save]);

  const {
    blockProps = {
      title: 'Видео',
      maxSize: 'до Xмб',
    },
    charactersCount = 0,
    url = '',
    alt,
    size,
    footer = true,

    loop,
    mute,
    autoplay,

    mimetype,
    format,
    isFetching = false,
    isEditing = false,
  } = props;

  const { maxSize } = blockProps;

  const video = { url, size, mimetype };

  return (
    <BlockWrapper
      {...blockProps}
      maxSize={maxSize ? `${charactersCount}/${maxSize}` : ''}
      wrapperClassname={styles.wrapper}
    >
      <div className={styles.dropZoneWrapper}>
        {(size || 0) > 0 && (
          <div className={styles.controls}>
            <BlockButton shape={ButtonShape.Round} onClick={onClickDelete} className={styles.btn}>
              <span
                style={{
                  marginRight: '5px',
                  width: 11,
                  height: 11,
                  fill: '#9A9A9A',
                }}
              >
                <Close />
              </span>{' '}
              Удалить
            </BlockButton>
          </div>
        )}
        <DropZone
          type={DropType.Video}
          accept="video/mp4"
          uploadUrl={uploadUrl}
          files={[video]}
          onChange={save}
          onProgressChange={onProgressChange}
          uploadData={{ type: 'block' }}
        />
      </div>

      <div className={styles.captionHeader}>Подпись к видео</div>
      <RichEditor
        value={alt}
        onChange={onChangeDescription}
        readOnly={isAnyTruthy([isUploading, isFetching])}
        isEditing={isEditing}
      />

      {footer && (
        <div className={classNames(styles.footer, isUploading && styles.disabled)}>
          <div>
            <div className={styles.captionHeader}>Выберите формат видео</div>
            <form className={styles.radioButtons}>
              <label>
                <input
                  type="radio"
                  name="format"
                  value="square"
                  checked={format === 'square'}
                  onChange={onChangeFormat}
                />
                <span>Квадратное</span>
              </label>

              <label>
                <input
                  type="radio"
                  name="format"
                  value="narrow"
                  checked={format === 'narrow'}
                  onChange={onChangeFormat}
                />
                <span>Узкое</span>
              </label>

              <label>
                <input
                  type="radio"
                  name="format"
                  value="wide"
                  checked={format === 'wide'}
                  onChange={onChangeFormat}
                />
                <span>Широкое</span>
              </label>
            </form>
          </div>
          <div>
            <div className={styles.captionHeader}>Дополнительно</div>

            <div className={styles.radioButtons}>
              <label>
                <input type="checkbox" name="loop" checked={loop} onChange={onChangeCheckbox} />
                <span>Зацикленное</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  name="autoplay"
                  checked={autoplay}
                  onChange={onChangeCheckbox}
                />
                <span>Автовоспроизведение</span>
              </label>

              <label>
                <input type="checkbox" name="mute" checked={mute} onChange={onChangeCheckbox} />
                <span>Отключить звук</span>
              </label>
            </div>
          </div>
        </div>
      )}
      {/* {footer && footer} */}
    </BlockWrapper>
  );
};

export default VideoBlock;
