import { Checkbox } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { ContentSpinner, GridWrapper } from '~components';
import { InjectStores } from '~store';
import { noop } from '~utils';

import styles from './styles.scss';

type IProps = InjectStores<'SettingsGlobalStore' | 'UserStore'>;

@inject('SettingsGlobalStore', 'UserStore')
@observer
class Advertising extends Component<IProps> {
  componentDidMount() {
    this.props.SettingsGlobalStore?.fetchGlobalSettings();
  }

  handleChange = e => {
    const { name, checked } = e.target;

    this.props.SettingsGlobalStore?.updateGlobalSettings({ [name]: checked });
  };

  render() {
    const {
      SettingsGlobalStore: { globalSettings, isFirstLoading } = {},
      UserStore: { checkPermissions = noop } = {},
    } = this.props;

    if (isFirstLoading) {
      return <ContentSpinner />;
    }

    return (
      <GridWrapper gridSize={2}>
        <div className={styles.item}>
          <Checkbox
            name="advDisabled"
            checked={globalSettings?.advDisabled}
            onChange={this.handleChange}
            disabled={!checkPermissions('global.update')}
          >
            Отключить рекламу на сайте
          </Checkbox>
        </div>
      </GridWrapper>
    );
  }
}

export default Advertising;
