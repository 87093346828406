import { evolve, length, omit, pipe, prop } from 'ramda';

import { IAuthor } from '~common';

import { stripWhen } from './functional';

export function normalize(json) {
  return {
    ...json,
    // cover: json.cover && json.cover._id ? json.cover._id : null,
    authors:
      json.authors && json.authors.length
        ? json.authors.map(author => (typeof author === 'string' ? author : author._id))
        : [],
    coauthors:
      json.coauthors && json.coauthors.length
        ? json.coauthors.map(coauthor => (typeof coauthor === 'string' ? coauthor : coauthor._id))
        : [],
    tags:
      json.tags && json.tags.length
        ? json.tags.map(tag => (typeof tag === 'string' ? tag : tag._id))
        : [],
    regions:
      json.regions && json.regions.length
        ? json.regions.map(region => (typeof region === 'string' ? region : region._id))
        : [],
    flows:
      json.flows && json.flows.length
        ? json.flows.map(flow => (typeof flow === 'string' ? flow : flow._id))
        : [],
  };
}

export const getSettingsNormalizedData = pipe(
  normalize,
  evolve({
    flags: omit(['pushSent']), // иначе бек ругается на ключ pushSent, в дальнейшем на беке должны убрать это поле, т.к в админке мы его не используем
  }),
  stripWhen('draftPriorities', null),
);

export const isExistsAuthors = pipe<{ authors: IAuthor[] }, IAuthor[], number, boolean>(
  prop('authors'),
  length,
  Boolean,
);
