import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';

import ItemWithLabel from '~components/ItemWithLabel';
import Modal from '~components/Modal';
import s from './styles.scss';

@inject('TimersStore', 'UserStore')
@observer
class TimersModal extends Component {
  handleSubmit = e => {
    e.preventDefault();

    const {
      TimersStore: { updateTimer, editableTimer },
      form,
    } = this.props;

    const formData = form.getFieldsValue();
    const value = { data: { ...formData } };

    updateTimer({
      [editableTimer.title]: Number(value.data.time),
    });
  };

  render() {
    const {
      TimersStore: { setHideModal, isShowModal, resetEditableTimer, editableTimer },
      UserStore: { checkPermissions },
      form,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title="Редактирование таймера"
        afterClose={resetEditableTimer}
      >
        <Form onSubmit={this.handleSubmit}>
          <ItemWithLabel label="Введите время">
            <Form.Item>
              {getFieldDecorator('time', {
                initialValue: editableTimer && editableTimer.value,
                rules: [{ required: true, message: 'Обязательное поле!' }],
              })(<Input type="number" placeholder="Время в секундах" />)}
            </Form.Item>
          </ItemWithLabel>

          <div className={s.modalControls}>
            <Button htmlType="submit" type="primary" disabled={!checkPermissions('timers.update')}>
              Сохранить изменения
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

TimersModal.propTypes = {
  TimersStore: PropTypes.any,
  form: PropTypes.any,
};

export default Form.create({ name: 'timersForm' })(TimersModal);
