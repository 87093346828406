import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback } from 'react';

import { ITag } from '~common';
import { WhiteBlockWithShadow } from '~components';
import { useTagsStore } from '~hooks';
import { Pencil } from '~icons';

import styles from './styles.scss';

interface IProps {
  tag: ITag;
}

const TagItem = observer((props: IProps) => {
  const { tag } = props;
  const { setEditableTag } = useTagsStore();

  const handleClickItem = useCallback(() => {
    setEditableTag(tag);
  }, [setEditableTag, tag]);

  const { title, index, createdAt } = tag;

  return (
    <WhiteBlockWithShadow>
      <div className={styles.root} onClick={handleClickItem}>
        <div className={styles.ico}>
          <Pencil fill="#D3D3D3" />
        </div>

        <div className={styles.title}>{title}</div>

        <div className={styles.id}>ID {index}</div>

        <div className={styles.date}>{moment(createdAt).format('DD.MM.YYYY')}</div>
      </div>
    </WhiteBlockWithShadow>
  );
});

export default TagItem;
