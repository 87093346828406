import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import moment from 'moment'
import { DatePicker, TimePicker } from 'antd'

import Labeled from '~components/ItemWithLabel'

import styles from './styles.scss'

// eslint-disable-next-line react/prop-types
const DateTimePicker = ({ label, onChange, date }) => {
  return (
    <div className={styles.input}>
      <Labeled label={label}>
        <DatePicker
          placeholder="Выберите дату"
          allowClear={false}
          value={date}
          onChange={onChange}
          format={'DD.MM.YYYY'}
        />
        <TimePicker
          placeholder="Время"
          allowClear={false}
          value={date}
          onChange={onChange}
          format={'HH:mm'}
        />
      </Labeled>
    </div>
  )
}

@inject('PublicationStore')
@observer
class TranslationInputs extends Component {
  onChange = (name, value) => {
    this.props.PublicationStore.set({ name, value })
  }

  onChangeStart = date => {
    this.onChange('start', date.utc().format())
  }

  onChangeEnd = date => {
    this.onChange('end', date.utc().format())
  }

  render() {
    const { start, end } = this.props.PublicationStore

    return (
      <>
        <DateTimePicker
          label="Начало трансляции"
          onChange={this.onChangeStart}
          date={start ? moment(start) : null}
        />
        <DateTimePicker
          label="Окончание трансляции"
          onChange={this.onChangeEnd}
          date={end ? moment(end) : null}
        />
      </>
    )
  }
}

TranslationInputs.propTypes = {
  PublicationStore: PropTypes.any
}

export default TranslationInputs
