import { Notification, NotificationCreateResponse, NotificationUpdateResponse } from '~common';
import { AbstractTransportLayer } from '~services/abstract';

const NOTIFICATIONS_URL = '/notifications';

export class NotificationsTransportLayer extends AbstractTransportLayer<
  Notification,
  NotificationCreateResponse,
  NotificationUpdateResponse
> {
  constructor() {
    super(NOTIFICATIONS_URL);
  }
}
