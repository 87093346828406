import { createInlineStyleButton } from 'draft-js-buttons';
import React from 'react';

// TODO Убрать игнор когда выпустят фикс типов
// @ts-ignore
export default createInlineStyleButton({
  style: 'STRIKETHROUGH',
  children: (
    <svg viewBox="0 0 384 384">
      <g>
        <path
          d="M90.347,154.667c1.067,1.92,2.24,3.627,3.307,5.44H192c-13.653-4.693-21.973-9.707-29.973-14.933
          c-10.453-6.933-15.68-15.573-15.68-25.813c0-4.907,1.067-9.707,3.093-14.08s5.12-8.213,9.173-11.52
          c4.053-3.307,9.173-5.867,15.36-7.68c6.293-1.813,13.547-2.773,21.973-2.773c8.747,0,16.32,1.067,22.72,3.307
          c6.4,2.133,11.733,5.333,16,9.28s7.467,8.747,9.493,14.4c2.027,5.547,3.093,11.627,3.093,18.133h64.213
          c0-13.973-2.667-26.88-8.107-38.613c-5.44-11.84-13.12-21.973-23.147-30.507c-10.027-8.64-22.08-15.253-36.16-20.053
          c-14.08-4.8-29.76-7.147-47.04-7.147c-16.853,0-32.213,2.133-46.293,6.293c-14.08,4.16-26.133,10.027-36.373,17.6
          c-10.24,7.573-18.133,16.747-23.787,27.52s-8.427,22.613-8.427,35.627C82.133,132.693,84.907,144.533,90.347,154.667
          L90.347,154.667z"
        />
        <path
          d="M0,181.44v42.667h205.333c3.84,1.493,8.533,2.987,11.733,4.373c7.893,3.52,14.08,7.253,18.56,10.88
          c4.48,3.733,7.467,7.787,9.067,12.053c1.6,4.373,2.347,9.28,2.347,14.613c0,5.013-0.96,9.707-2.88,13.973
          c-1.92,4.373-4.907,8.107-8.853,11.2c-3.947,3.093-9.067,5.547-15.147,7.36c-6.187,1.813-13.333,2.667-21.653,2.667
          c-9.28,0-17.707-0.96-25.28-2.773c-7.573-1.813-13.973-4.8-19.307-8.853c-5.333-4.053-9.493-9.387-12.48-15.893
          C138.453,267.2,136,257.387,136,248H72.533c0,11.733,1.707,24.107,5.013,33.707c3.307,9.6,8,18.24,13.867,25.813
          c5.867,7.573,12.907,14.08,20.907,19.627c8,5.547,16.64,10.24,26.027,13.867c9.387,3.733,19.093,6.507,29.44,8.213
          c10.24,1.813,20.587,2.667,30.827,2.667c17.067,0,32.64-1.92,46.507-5.867c13.867-3.947,25.813-9.6,35.627-16.853
          c9.813-7.36,17.387-16.32,22.827-27.093c5.44-10.773,8-22.933,8-36.587c0-12.8-2.24-24.32-6.613-34.453
          c-1.067-2.453-2.24-4.8-3.627-7.147H384V181.44H0z"
        />
      </g>
    </svg>
  ),
});
