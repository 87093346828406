import React, { useEffect, useState } from 'react';

import { FontWeight, IBlock, IMedia } from '~common';
import { noop } from '~utils';

import { MediaUpload } from '../../MediaUpload';
import { Textarea } from '../../Textarea';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

interface IState extends IMedia {
  link: string;
  poll: string;
  text: string;
  type: string;
}

interface IProps extends IBlock {
  media?: IMedia;
  onChange?(state: IState): void;
  uploadUrl?: string;
  value?: string;
}

const StoryBlock: React.FC<IProps> = props => {
  const {
    uploadUrl = '/v1/media/single-upload',
    media = {
      mimetype: '',
      width: 0,
      height: 0,
      size: 0,
      filename: '',
      path: '',
      url: '',
    },
    isEditing = false,
    isFetching = false,
    value = '',
    onChange = noop,
  } = props;

  const [data, setData] = useState<IState>({
    link: '',
    text: value,
    poll: '',
    type: 'no',
    mimetype: '',
    width: 0,
    height: 0,
    size: 0,
    filename: '',
    path: '',
    url: '',
    ...media,
  });

  const onUpload = media => setData({ ...data, ...media });
  const onInput = text => setData({ ...data, text });
  const onInputLink = link => setData({ ...data, link });

  useEffect(() => onChange(data), [onChange, data]);

  return (
    <BlockWrapper wrapperClassname={styles['stories-editor']} title="Слайд">
      <div>
        <div className={styles.mediaUploaderWrapper}>
          <div>
            <MediaUpload {...media} uploadUrl={uploadUrl} onChange={onUpload} />
          </div>
        </div>
        <div className={styles.mediaCaption}>Разрешение 9:16</div>
      </div>

      <div>
        <Textarea
          placeholder={'Ссылка "подробнее"'}
          fontWeight={FontWeight.Medium}
          value={data.link}
          onChange={onInputLink}
          readOnly={isFetching}
          isEditing={isEditing}
        />

        <Textarea
          placeholder="Введите текст"
          fontWeight={FontWeight.Medium}
          value={data.text}
          onChange={onInput}
          readOnly={isFetching}
          isEditing={isEditing}
        />
      </div>
    </BlockWrapper>
  );
};

export default StoryBlock;
