import { Modal as AntModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import PropTypes from 'prop-types';
import React, { FC } from 'react';

import { DEFAULT_VALUE, EMPTY_STRING } from '~constants';

import styles from './styles.scss';

interface IModalProps extends ModalProps {
  isFooterHidden?: boolean;
  isVisible: ModalProps['visible'];
  onClose: ModalProps['onCancel'];
  title?: string;
}

const Modal: FC<IModalProps> = props => {
  const {
    isVisible,
    onClose,
    children,
    title = EMPTY_STRING,
    isFooterHidden = true,
    ...antProps
  } = props;
  const footer = isFooterHidden ? null : DEFAULT_VALUE;
  return (
    <AntModal footer={footer} visible={isVisible} onCancel={onClose} {...antProps}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </AntModal>
  );
};

export default Modal;

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

Modal.defaultProps = {
  title: EMPTY_STRING,
};
