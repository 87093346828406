import { Schema } from 'yup';

export const isValidBySchema = <T>(schema: Schema<T>) => (object: any) => {
  try {
    return schema.validateSync(object, {
      strict: false,
      abortEarly: false,
      context: object,
    });
  } catch (validationError) {
    const { errors = [], value = {} } = validationError;
    console.groupCollapsed('Validation failed...');
    console.log(value);
    console.table(errors);
    console.groupEnd();
    return false;
  }
};
