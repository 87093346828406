import { observer } from 'mobx-react';
import React from 'react';

import { useFeedbackStore } from '~hooks';

import { FeedbackFilterItem } from '../FeedbackFilterItem';
import styles from './styles.scss';

const FeedbackFilter = observer(() => {
  const { dictionaries } = useFeedbackStore();
  const allTypes = [{ id: '', ru: 'Все письма' }, ...dictionaries];

  return (
    <ul className={styles.navigation}>
      {allTypes && allTypes.map((type, i) => <FeedbackFilterItem key={i} type={type} />)}
    </ul>
  );
});

export default FeedbackFilter;
