import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists.js';

const API_URL_MOVE_POSTS = '/movePost';
const API_URL_DICTIONARIES = '/dictionaries/movePost';

const getData = ({ data }) => data;

export class MovePostsTransportLayer {
  getDictionaries = () => {
    return API.get(`${API_URL_DICTIONARIES}`).then(getData);
  };

  getMovedPosts = (defaultFilter = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: API_URL_MOVE_POSTS, defaultFilter });
    return API.get(promiseUrl).then(getData);
  };

  movePost = moveData => {
    return API.post(`${API_URL_MOVE_POSTS}`, { data: { ...moveData } }).then(getData);
  };

  restorePost = id => {
    return API.post(`movePost/${id}/restore`).then(getData);
  };
}
