import { Form, Input } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '~components/Button';
import { useUserStore } from '~hooks';

import styles from './styles.scss';

const LoginForm: FC = observer(props => {
  const [isFetching, setIsFetching] = useState(false);
  const { login } = useUserStore();
  const { push } = useHistory();
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    setIsFetching(true);
    login(values)
      .then(value => {
        push('/');
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <Form
      form={form}
      validateTrigger="onSubmit"
      onFinish={handleSubmit}
      className={styles.loginForm}
    >
      <Form.Item name="login" rules={[{ required: true, message: 'Обязательно для ввода' }]}>
        <Input placeholder="Логин" className={styles.loginInput} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Обязательно для ввода' }]}>
        <Input type="password" placeholder="Пароль" className={styles.loginInput} />
      </Form.Item>
      <Form.Item style={{ marginTop: '16px', marginBottom: 0 }}>
        <Button
          key="submit"
          loading={isFetching}
          size="large"
          type="primary"
          htmlType="submit"
          className={styles.loginFormButton}
        >
          Войти
        </Button>
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          disabled={isFetching}
          size="large"
          type="primary"
          onClick={() => {
            localStorage.clear();
            window.location.href = `${process.env.API_ROOT}/connect/life`;
          }}
          className={styles.loginFormButton}
        >
          Войти через SSO
        </Button>
      </Form.Item>
    </Form>
  );
});

export default LoginForm;
