import { message } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ContentWrapper, FilterToggleControl, StatusLabel } from '~components';
import { useVideoStore } from '~hooks';

import { Actions, Header, Settings, VideoEditor } from './components';
import styles from './styles.scss';

const VideoPage = observer(() => {
  const [isOpenSettings, setIsOpenSettings] = useState(true);
  const { init, clear, status, isNew } = useVideoStore();
  const history = useHistory();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  useEffect(() => {
    init(id).catch(() => {
      message.error(`Видео ${id} не найдено!`);
      history.replace('/broadcasts/video');
    });

    return clear;
  }, []);

  const toggleSettings = useCallback(() => {
    setIsOpenSettings(!isOpenSettings);
  }, [isOpenSettings]);

  return (
    <>
      <Header />
      <ContentWrapper
        title={`${isNew ? 'Создание' : 'Редактирование'} видео`}
        items={<StatusLabel color={status?.color} label={status?.ru} />}
        rightContent={
          <FilterToggleControl
            label="Все настройки"
            isActive={isOpenSettings}
            onClick={toggleSettings}
          />
        }
      >
        <div className={styles.content}>
          {isOpenSettings && <Settings />}
          <Actions />
          <VideoEditor />
        </div>
      </ContentWrapper>
    </>
  );
});

export default VideoPage;
