import { Icon as LegacyIcon } from '@ant-design/compatible';
import Icon from '@ant-design/icons';
import { Col, Divider, Modal, Popover, Row } from 'antd';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { always, find, ifElse, isNil, pipe, prop, propEq } from 'ramda';
import React, { useMemo } from 'react';

import { IMovePostHistoryItem } from '~common';
import { EmptyContent } from '~components';
import { useMovePublicationsStore, useWindowDimensions } from '~hooks';
import { Undo } from '~icons';
import { getShortText, getTimeByString } from '~utils';

import s from './styles.scss';

const TITLE_LENGTH = 10;

const { confirm } = Modal;

const getMainTitle = (value, dictionaries) =>
  pipe(find(propEq('id', value)), ifElse(isNil, always(value), prop('ru')))(dictionaries);

const getSubTitle = (title): string => {
  return title?.length <= TITLE_LENGTH ? (
    title
  ) : (
    <Popover content={title}>{getShortText(title, TITLE_LENGTH)}</Popover>
  );
};

const getStatusIcon = (status, result) => {
  switch (status) {
    case 'DONE': {
      if (isMigrationWithErrors(result)) {
        return getStatusPopover(
          'Миграция завершена с ошибками',
          'exclamation-circle',
          result,
          s.fail,
        );
      }
      return getStatusPopover('Миграция завершена', 'check-circle', result, s.success);
    }
    case 'CREATED': {
      return getStatusPopover('Миграция создана', 'plus-circle', result, s.new);
    }
    case 'IN_PROGRESS': {
      return getStatusPopover('Миграция в работе', 'clock-circle', result, s.inProgress);
    }
    default: {
      return getStatusPopover('Неизвестный статус', 'question-circle', result);
    }
  }
};

const getStatusPopoverContent = (text, result) => (
  <div className={s.popoverContent}>
    <p>{text}</p>
    {getResultString(result)}
  </div>
);

const getStatusPopover = (text, icon, result, className?) => {
  const popoverContent = getStatusPopoverContent(text, result);

  return (
    <Popover content={popoverContent} className={className}>
      <LegacyIcon type={icon} className={s.icon} />
    </Popover>
  );
};

const getResultString = result => (
  <>
    <p className={s.success}>Успешно: {result.success}</p>
    <p className={s.fail}>С ошибкой: {result.fail}</p>
    <p>Общее количество: {result.total}</p>
  </>
);

const isMigrationWithErrors = result => result.fail > 0;

const MigrationsList = observer(() => {
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width < 480, [width]);

  const {
    moveFromToTypes,
    movePostTypes,
    movePostsHistoryItems,
    isEmptyHistory,
    restorePost,
  } = useMovePublicationsStore();

  const handleRestoreButtonClick = id => {
    confirm({
      title: 'Вы уверены что хотите совершить обратную миграцию?',
      okText: 'Да',
      cancelText: 'Нет',
      onOk() {
        restorePost && restorePost(id);
      },
    });
  };

  const actionColumn = migration => (
    <Row align="middle">
      <Col span={16}>{getMainTitle(migration.changeType, movePostTypes)}</Col>
      <Col span={8}>
        <Popover content="Выполнить обратную миграцию">
          <Icon
            component={Undo}
            onClick={() => handleRestoreButtonClick(migration._id)}
            className={cx(s.icon, s.rollback)}
          />
        </Popover>
      </Col>
    </Row>
  );

  const revertActionColumn = migration => (
    <Row align="middle">
      <Col span={24}>
        {getMainTitle(migration.changeType, movePostTypes)} {migration.parentId && '(отмена)'}
      </Col>
    </Row>
  );

  const history = useMemo(
    () => (
      <>
        <Row align="middle" gutter={24} className={s.headerRow}>
          <Col span={4}>Откуда</Col>
          <Col span={4}>Куда</Col>
          <Col span={5}>Дата</Col>
          <Col span={3}>Статус</Col>
          <Col span={8}>Действие</Col>
        </Row>
        {movePostsHistoryItems.map((migration: IMovePostHistoryItem) => (
          <Row align="middle" gutter={24} className={s.historyRow}>
            <Col span={4}>
              <Row className={s.mainTitle}>
                <Col>{getMainTitle(migration.from, moveFromToTypes)}</Col>
              </Row>
              <Row className={s.subTitle}>
                <Col>
                  {!isEmpty(migration?.fromModel) && getSubTitle(migration?.fromModel[0]?.title)}
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Row className={s.mainTitle}>
                <Col>{getMainTitle(migration.to, moveFromToTypes)}</Col>
              </Row>
              <Row className={s.subTitle}>
                <Col>
                  {!isEmpty(migration?.toModel) && getSubTitle(migration?.toModel[0]?.title)}
                </Col>
              </Row>
            </Col>
            <Col span={5}>
              <Row align="middle">
                <Col>{getTimeByString(migration.createdAt, 'DD/MM/YYYY')}</Col>
              </Row>
            </Col>
            <Col span={3}>
              <Row align="middle" justify="center">
                {getStatusIcon(migration.status, migration.result)}
              </Row>
            </Col>
            <Col span={8}>
              {!migration.parentId ? actionColumn(migration) : revertActionColumn(migration)}
            </Col>
          </Row>
        ))}
      </>
    ),
    [movePostsHistoryItems],
  );

  const mobileHistory = useMemo(
    () => (
      <>
        {movePostsHistoryItems.map((migration: IMovePostHistoryItem) => (
          <Row align="middle" gutter={24} className={s.mobileHistoryRow}>
            <Col span={24}>
              <Row align="middle" gutter={24}>
                <Col span={6}>Откуда</Col>
                <Col span={6}>{getMainTitle(migration.from, moveFromToTypes)}</Col>
                <Col span={12}>
                  {!isEmpty(migration?.fromModel) && getSubTitle(migration?.fromModel[0]?.title)}
                </Col>
              </Row>
              <Row align="middle" gutter={24}>
                <Col span={6}>Куда</Col>
                <Col span={6}>{getMainTitle(migration.to, moveFromToTypes)}</Col>
                <Col span={12}>
                  {!isEmpty(migration?.toModel) && getSubTitle(migration?.toModel[0]?.title)}
                </Col>
              </Row>
              <Divider />
              <Row align="middle">
                <Col span={6}>Дата</Col>
                <Col span={18}>{getTimeByString(migration.createdAt, 'DD/MM/YYYY')}</Col>
              </Row>
              <Row align="middle">
                <Col span={6}>Статус</Col>
                <Col span={14}>{getStatusIcon(migration.status, migration.result)}</Col>
                <Col span={4}>
                  {!migration.parentId && (
                    <Popover content="Выполнить обратную миграцию">
                      <Icon
                        component={Undo}
                        onClick={() => handleRestoreButtonClick(migration._id)}
                        className={cx(s.icon, s.rollback)}
                      />
                    </Popover>
                  )}
                </Col>
              </Row>
              <Row align="middle">
                <Col span={6}>Действие</Col>
                <Col span={14}>
                  {getMainTitle(migration.changeType, movePostTypes)}{' '}
                  {migration.parentId && '(отмена)'}
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </>
    ),
    [movePostsHistoryItems],
  );

  if (isEmptyHistory) return <EmptyContent text="Нет миграций" />;

  return <>{!isMobile ? history : mobileHistory}</>;
});

export default MigrationsList;
