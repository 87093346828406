import { observer } from 'mobx-react';
import React from 'react';

import { ContentWrapper, HeaderPageSearchBar } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { BadWordList, BadWordModal } from '~containers';
import { useBadWordsStore, useClearHandBooksStores, useUserStore } from '~hooks';

const PAGE_TITLE = 'Запрещенные слова';
const COUNT_DECLINATIONS = ['запрещенное слово', 'запрещенных слова', 'запрещенных слов'];
const SEARCH_BTN_TEXT = 'Найти в запрещенных словах';
const CREATE_BTN_TEXT = 'Добавить запрещенное слово';

export const BadWordsPage = observer(() => {
  useClearHandBooksStores();

  const {
    setShowModal,
    isFirstLoading,
    allItemsCount,
    fetchBadWords,
    badWordsLength,
    setIsFirstLoading,
  } = useBadWordsStore();
  const { checkPermissions } = useUserStore();

  const handleSearchPublications = () => {
    setIsFirstLoading(true);
    fetchBadWords();
  };

  return (
    <>
      <HeaderPageSearchBar
        searchButtonText={SEARCH_BTN_TEXT}
        createButtonText={CREATE_BTN_TEXT}
        onCreateElement={checkPermissions('badWords.create') && setShowModal}
        onSearch={handleSearchPublications}
      />
      <ContentWrapper
        title={PAGE_TITLE}
        countItems={allItemsCount}
        countDeclinations={COUNT_DECLINATIONS}
      >
        <InfinityScroll
          fetchItems={fetchBadWords}
          hasMore={allItemsCount !== badWordsLength}
          isFetching={isFirstLoading}
        >
          <BadWordList />
        </InfinityScroll>
      </ContentWrapper>

      <BadWordModal />
    </>
  );
});
