import { string, object } from 'yup'
import { EMPTY_STRING } from '~constants/index'
import { compiledFileSchema } from '~schemas/file'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const compiledSectionSchema = object()
  .default({ _id: EMPTY_STRING })
  .shape({
    _id: string().required(),
    title: string(),
    cover: compiledFileSchema.when('$priority', (priority, schema) =>
      /** ToDo Проверить обработку свойства cover в компонентах */
      priority === 'POST_PRIORITY_2C' ? schema.required() : schema.defined()
    ),
    alias: string()
  })

export const isValidCompiledSection = isValidBySchema(compiledSectionSchema)
