import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
} from 'react-sortable-hoc';

import { ISection } from '~common';
import { AddNew, ContentSpinner, GridWrapper } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { openSectionsModal } from '~containers';
import { useSectionsStore, useUserStore } from '~hooks';

import SectionsItem from '../SectionsItem';

const SortableItem = sortableElement(({ section }) => <SectionsItem data={section} />);

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <GridWrapper gridSize={3} maxItemWidth={285}>
      {children}
    </GridWrapper>
  );
});

const SectionsList = observer(() => {
  const {
    fetchSections,
    isFirstLoading,
    sections,
    changeOrdering,
    allItemsCount,
    sectionsLength,
  } = useSectionsStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    fetchSections && fetchSections();
  }, []);

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  return (
    <InfinityScroll
      fetchItems={fetchSections}
      hasMore={allItemsCount !== sectionsLength}
      isFetching={isFirstLoading}
    >
      <SortableContainer
        distance={1}
        axis="xy"
        onSortEnd={checkPermissions(`sections.update`) ? changeOrdering : undefined}
      >
        {sections &&
          sections.map((section: ISection) => (
            <SortableItem key={section._id} section={section} index={section.order} />
          ))}
        {checkPermissions('sections.create') && (
          <AddNew
            onClick={async () => {
              await openSectionsModal();
            }}
          />
        )}
      </SortableContainer>
    </InfinityScroll>
  );
});

export default SectionsList;
