const JOURNALIST = 'EMPLOYEE_POSITION_JOURNALIST';
const EDITOR = 'EMPLOYEE_POSITION_EDITOR';
const COMM_EDITOR = 'EMPLOYEE_POSITION_COMM_EDITOR';
const CHIEF_EDITOR = 'EMPLOYEE_POSITION_CHIEF_EDITOR';
const BUILD_EDITOR = 'EMPLOYEE_POSITION_BUILD_EDITOR';
const CORRECTOR = 'EMPLOYEE_POSITION_CORRECTOR';
const CHIEF_PRODUCER = 'EMPLOYEE_POSITION_CHIEF_PRODUCER';
const PRODUCER = 'EMPLOYEE_POSITION_PRODUCER';
const STREAM_PROVIDER = 'STREAM_PROVIDER';
const INTERN = 'EMPLOYEE_POSITION_INTERN';

const DEFAULT_ACCESS_RIGHTS = {
  [JOURNALIST]: {
    authors: true,
    posts: true,
    videos: true,
    streams: true,
    settings: true,
    departments: true,
    employees: true,
    feedbacks: true,
    files: true,
    history: true,
    tags: true,
    flows: true,
  },
  [EDITOR]: {
    authors: true,
    posts: true,
    videos: true,
    streams: true,
    settings: true,
    departments: true,
    employees: true,
    feedbacks: true,
    files: true,
    history: true,
    tags: true,
    flows: true,
  },
  [COMM_EDITOR]: {
    authors: true,
    posts: true,
    videos: true,
    streams: true,
    settings: true,
    departments: true,
    employees: true,
    feedbacks: true,
    files: true,
    history: true,
    tags: true,
    flows: true,
  },
  [CHIEF_EDITOR]: true,
  [BUILD_EDITOR]: {
    authors: true,
    posts: true,
    videos: true,
    streams: true,
    settings: true,
    departments: true,
    employees: true,
    feedbacks: true,
    files: true,
    history: true,
    tags: true,
    flows: true,
  },
  [CORRECTOR]: {
    authors: true,
    posts: true,
    videos: true,
    streams: true,
    settings: {
      get: true,
      sections: {
        create: true,
        delete: true,
      },
    },
    departments: true,
    employees: true,
    feedbacks: true,
    files: true,
    history: true,
    tags: {
      get: true,
    },
    flows: true,
  },
  [CHIEF_PRODUCER]: {
    events: true,
    eventTypes: true,
    employees: {
      get: true,
    },
    authors: {
      get: true,
    },
  },
  [PRODUCER]: {
    events: {
      get: true,
    },
    eventTypes: {
      get: true,
    },
    employees: {
      get: true,
    },
    authors: {
      get: true,
    },
  },
  [STREAM_PROVIDER]: {
    streams: true,
  },
  [INTERN]: {
    posts: true,
    authors: { get: true },
    settings: { get: true },
    employees: { get: true },
    files: true,
    history: { get: true },
    tags: { get: true },
    flows: { get: true },
  },
};

export const role = {
  JOURNALIST,
  EDITOR,
  COMM_EDITOR,
  CHIEF_EDITOR,
  BUILD_EDITOR,
  CORRECTOR,
  CHIEF_PRODUCER,
  PRODUCER,
  STREAM_PROVIDER,
  INTERN,
};

export function checkAccessForEmployee(resource, action, accessRights) {
  const [primaryResource, secondaryResource] = resource.split('.');

  if (accessRights) {
    if (accessRights === true) {
      return true;
    }

    if (accessRights[primaryResource]) {
      if (accessRights[primaryResource] === true) {
        return true;
      }
      if (accessRights[primaryResource][action] === true) {
        return true;
      }
      if (accessRights[primaryResource][secondaryResource]) {
        if (accessRights[primaryResource][secondaryResource] === true) {
          return true;
        }
        if (accessRights[primaryResource][secondaryResource][action] === true) {
          return true;
        }
      }
    }
  }

  return false;
}

export const isEditor = role => [CHIEF_EDITOR].includes(role);
