export const fieldNames = {
  updatedAt: 'Время обновления',
  content: 'Содержимое',
  compiled: 'Скомпилированное содержимое',
  'compiled.type': 'Скомпилированный тип',
  'compiled._id': 'Скомпилированный идентификатор',
  'compiled.content.text': 'Скомпилированный текст',
  lockedTo: 'Заблокировано до',
  lockedBy: 'Кем заблокировано',
  hash: 'Хэш',
  updatedBy: 'Кем обновлено',
  blocks: 'Блоки',
  createdAt: 'Время создания',
  locks: 'Блокировки',
  createdBy: 'Кем создано',
  id: 'Идентификатор',
  _id: 'Идентификатор',
  publishError: 'Ошибка публикации',
  popular: 'Популярный',
  unpublishReason: 'Причина снятии с публикации',
  publishedBy: 'Кем опубликован',
  publicationDate: 'Дата публикации',
  status: 'Статус',
  // relativePosts: '',
  priority: 'Приоритет',
  advType: 'Особый тип',
  // yandexTag: 'Тег для Яндекс.Статьи',
  flows: 'Потоки',
  tags: 'Теги',
  categories: 'Категории',
  section: 'Секции',
  coauthors: 'Соавторы',
  authors: 'Авторы',
  // end: 'end',
  // start: 'start',
  resourceType: 'Тип публикации',
  blockGroups: 'Группа блоков',
  type: 'Тип',
  note: 'Примечание',
  alias: 'alias',
  index: 'Индекс',
  'oldDraftPriorities.categories': 'Черновые приоритеты(старые). Категории',
  'oldDraftPriorities.tags': 'Черновые приоритеты(старые). Теги',
  'oldDraftPriorities.section': 'Черновые приоритеты(старые). Секции',
  'serviceFields.shouldUpdateGridPriorities': 'Сервисные поля. Следует обновить приоритеты сетки',
  'serviceFields.shouldUpdateGridPriority': 'Сервисные поля. следует обновить приоритет сетки',
  'draftPriorities.categories': 'Черновые приоритеты. Категории',
  'draftPriorities.tags': 'Черновые приоритеты. Теги',
  'draftPriorities.section': 'Черновые приоритеты. Секции',
  'validatedAt.buildEditor': 'Время вычитки бильда',
  'validatedAt.corrector': 'Время вычитки корректора',
  'validatedAt.chiefEditor': 'Время вычитки редактора',
  'validatedBy.buildEditor': 'Вычитка бильда',
  'validatedBy.corrector': 'Вычитка корректора',
  'validatedBy.chiefEditor': 'Вычитка редактора',
  'flags.coverHidden': 'Флаги. Скрытая обложка',
  // 'flags.darkSide': 'Флаги. Тёмная сторона',
  // 'flags.blockedByRKN': 'Флаги. Блокировка РКН',
  'flags.clickContent': 'Флаги. Контент «Клик»',
  'flags.shockContent': 'Флаги. Контент «Шок»',
  'flags.criminalContent': 'Флаги. 18+ криминал',
  'flags.adultContent': 'Флаги. Взрослый контент',
  'flags.pushSent': 'Флаги. Отправлено',
  'flags.pushAllowed': 'Флаги. Отправка разрешена',
  'flags.advDisabled': 'Флаги. Отключить рекламу в публикации',
  'flags.commentsAllowed': 'Флаги. Комментарии доступны',
  'flags.RSS.facebookInstant': 'Флаги. RSS. Facebook',
  // 'flags.RSS.mailruInformer': 'Флаги. RSS. Mail.ru Informer',
  // 'flags.RSS.mailruNews': 'Флаги. RSS. Mail.ru News',
  // 'flags.RSS.mailruPulse': 'Флаги. RSS. Mail.ru Pulse',
  // 'flags.RSS.mailruPulsePromo': 'Флаги. RSS. Mail.ru Pulse Promo',
  'flags.RSS.yandexNewsSpb': 'Флаги. RSS. Yandex News SPb',
  'flags.RSS.yandexNews': 'Флаги. RSS. Яндекс Новости',
  'flags.RSS.yandexDzen': 'Флаги. RSS. Яндекс Дзен',
  'flags.RSS.yandexDzenNative': 'Флаги. RSS. Яндекс Дзен Native',
  'flags.RSS.googleEditorsChoice': 'Флаги. RSS. Google выбор пользователей',
  'flags.RSS.googleNewsStand': 'Флаги. RSS. Google NewsStand',
  'flags.RSS.googleNews': 'Флаги. RSS. Google News',
  'flags.RSS.instantFB': 'flags.RSS. Instant FB',
  'flags.RSS.exclusives': 'Флаги. RSS. Внутренняя Exclusives',
  'flags.RSS.brandAnalytics': 'Флаги. RSS. Внутренняя Brand analytics',
  'flags.RSS.mainFeed': 'Флаги. RSS. Основная внутренняя',
};
