import React from 'react';

import { ISelectProps, Select } from '~components';

const options = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
];

const ResourceTypesSelect: React.FC<ISelectProps<object>> = props => (
  <Select {...props} options={options} keyField="id" labelField="name" />
);

export default React.memo(ResourceTypesSelect) as typeof ResourceTypesSelect;
