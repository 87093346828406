import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'

import Modal from '~components/Modal'
import ModalWrapper from './ModalWrapper'

@inject(stores => {
  const {
    isShowModalInfo,
    setHideModalInfo,
    editableEvent,
    resetEditableEvent
  } = stores.ScheduleStore

  return {
    isShowModalInfo,
    setHideModalInfo,
    editableEvent,
    resetEditableEvent
  }
})
@observer
class InfoModal extends Component {
  render() {
    const { editableEvent, setHideModalInfo, isShowModalInfo } = this.props

    return (
      <Modal
        destroyOnClose
        width={800}
        onClose={setHideModalInfo}
        isVisible={isShowModalInfo}
        title="Информация о съемке"
      >
        <ModalWrapper data={editableEvent} />
      </Modal>
    )
  }
}

export default InfoModal

InfoModal.propTypes = {
  editableEvent: PropTypes.object,
  isShowModalInfo: PropTypes.bool,
  setHideModalInfo: PropTypes.func,
  resetEditableEvent: PropTypes.func
}
