import cn from 'classnames';
import React, { useMemo } from 'react';

import styles from './styles.scss';

interface IProps {
  gridSize?: number;
  listView?: boolean;
  maxItemWidth?: number;
}

const GridWrapper: React.FC<IProps> = props => {
  const { listView = false, gridSize = 4, maxItemWidth = '1fr' } = props;

  const className = useMemo(() => cn(styles.grid, listView && styles.list), [listView]);

  const style = useMemo(() => ({ '--grid-size': gridSize, '--max-item-width': maxItemWidth }), [
    gridSize,
    maxItemWidth,
  ]);

  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
};

export default GridWrapper;
