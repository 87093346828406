import axios from 'axios';

const blobToDataUrl = (blob: Blob): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

const fetchImageAsDataUrl = src => {
  return axios
    .get(src, {
      responseType: 'blob',
      timeout: 30000,
    })
    .then(res => {
      return blobToDataUrl(res.data);
    })
    .then(dataUrl => dataUrl?.toString().replace('data:application/', 'data:image/'))
    .catch(err => {
      console.error('Error in fetchImageAsData!', err);
      return '';
    });
};

export default fetchImageAsDataUrl;
