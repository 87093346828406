import { action, computed, observable, reaction, runInAction, toJS, when } from 'mobx';
import moment, { Moment } from 'moment';
import { isEmpty } from 'ramda';
import { IRecording } from 'src/common/models/recording';

import { IDictionary, IFile, Maybe, WithId } from '~common';
import publicationApiLayer, { PublicationTransportLayer } from '~services/publicationApi';
import videoApiLayer, { VideoTransportLayer } from '~services/videoApi';
import FlagsStore from '~store/FlagsStore';
import OverlayStore from '~store/OverlayStore';

export const constants = {
  statuses: {
    asPost: {
      published: { id: 'POST_STATUS_PUBLISHED', ru: 'POST_STATUS_PUBLISHED' },
      draft: { id: 'POST_STATUS_DRAFT', ru: 'POST_STATUS_DRAFT' },
    },
    asVideo: {
      published: { id: 'VIDEO_STATUS_PUBLISHED', ru: 'Опубликована' },
      draft: { id: 'VIDEO_STATUS_DRAFT', ru: 'Черновик' },
    },
  },
  types: {
    video: 'video',
    stream: 'stream',
  },
};

const DEFAULT_FLAGS = {
  isMain: false,
  isMainSide: false,
  visible: true,
  // blockedByRKN: false,
  // darkSide: false,
};
const flagsKeys = Object.keys(DEFAULT_FLAGS);

const DEFAULT_RECORDING_VALUES = {
  autoFinish: true,
  finishAfterSeconds: 10800,
};

const makeYoutubeEmbedLinkById = id => `https://www.youtube.com/embed/${id}`;

export class VideoStore {
  apiVideoLayer: Maybe<VideoTransportLayer> = null;

  apiPublicationLayer: Maybe<PublicationTransportLayer> = null;

  @observable isLoading = false;

  @observable start: Maybe<Moment> = null;

  @observable end: Maybe<Moment> = null;

  @observable initialized = false;

  @observable flags: Record<string, boolean> = { ...DEFAULT_FLAGS };

  @observable id: Maybe<string> = null;

  @observable title = '';

  @observable description = '';

  @observable videoPriority = 0;

  @observable cover: Maybe<IFile & WithId> = null;

  @observable coverTitle = '';

  @observable availablePlaylists = [];

  @observable availableStreams: Array<Record<string, any>> = [];

  @observable playlists = [];

  @observable type: Maybe<string | IDictionary> = 'video';

  @observable videoFile: Maybe<IFile & WithId> = null;

  @observable videoFileTitle: Maybe<string> = null;

  @observable embedLink = '';

  @observable c1Link = '';

  @observable streamStatus = '';

  @observable videoStream = '';

  @observable streamId: Maybe<string> = '';

  @observable status: Maybe<IDictionary> = null;

  @observable section: Maybe<string> = null;

  @observable visible = false;

  @observable dictionaries: Record<string, any> = {};

  @observable priority: Maybe<string> = null;

  @observable isFetching = false;

  @observable recording: IRecording = DEFAULT_RECORDING_VALUES;

  constructor(apiVideoLayer, apiPublicationLayer) {
    this.apiVideoLayer = apiVideoLayer;
    this.apiPublicationLayer = apiPublicationLayer;

    reaction(
      () => this.isLoading || this.isFetching,
      isLoading => {
        OverlayStore.setVisible(isLoading);
      },
    );

    reaction(
      () => this.id,
      id => {
        !isEmpty(id) && FlagsStore.videoPosts && this.fetchPriority();
      },
    );
  }

  @action
  init = async id => {
    await when(() => FlagsStore.isLoaded);

    const promises: Array<Promise<void> | undefined> = [
      this.fetchPlaylists(),
      this.fetchStreams(),
      this.fetchDictionaries(),
    ];

    if (id != null) {
      promises.push(this.fetchVideo(id));
    } else {
      promises.push(this.createNewVideo());
    }

    return Promise.all(promises);
  };

  @computed
  get isPublished() {
    return (
      this.status?.id ===
      (FlagsStore.videoPosts
        ? constants.statuses.asPost.published.id
        : constants.statuses.asVideo.published.id)
    );
  }

  @computed
  get isNew() {
    return this.id == null;
  }

  @computed get flagsArray() {
    return Object.keys(this.flags).filter(flag => this.flags[flag]);
  }

  getDataForServer() {
    const { videoPosts } = FlagsStore;

    const data = {
      title: this.title,
      [videoPosts ? 'videoPriority' : 'priority']: this.videoPriority,
      cover: this.cover ? this.cover?._id : null,
      coverTitle: this.coverTitle,
      description: this.description,
      videoFile: this.videoFile ? this.videoFile._id : null,
      videoStream: this.videoStream,
      embedLink: this.embedLink,
      c1Link: this.c1Link,
      streamId: this.streamId,
      playlists: this.playlists,
      type: videoPosts ? (this.type as IDictionary)?.id : this.type,
      flags: this.flags,
      recording: this.recording,
    };

    if (videoPosts) {
      data.section = this.section;
    }

    if (this.type === 'VIDEO_TYPE_STREAM') {
      if (this.start) {
        data[videoPosts ? 'start' : 'startDate'] = this.start.toISOString();
      }

      if (this.end) {
        data[videoPosts ? 'end' : 'finishDate'] = this.end.toISOString();
      }
    }

    if (this.videoFileTitle) {
      data.videoFileTitle = this.videoFileTitle;
    }

    return data;
  }

  @action updateFromServer(data) {
    const { videoPosts } = FlagsStore;

    const flags = {};
    Object.keys(data.flags).forEach(key => {
      if (flagsKeys.indexOf(key) > -1) {
        flags[key] = data.flags[key];
      }
    });

    this.id = data._id;
    this.status = FlagsStore.videoPosts ? data.status : this.getDictionaryFromString(data.status);
    this.title = data.title;
    this.videoPriority = videoPosts ? data.videoPriority : data.priority;
    this.coverTitle = data.coverTitle;
    this.cover = data.cover;
    this.description = data.description;
    this.visible = data.visible;
    this.flags = flags;
    this.type = videoPosts ? data.type.id : data.type;
    this.videoFile = data.videoFile;
    this.videoFileTitle = data.videoFileTitle;
    this.videoStream = data.videoStream;
    this.embedLink = data.embedLink;
    this.c1Link = data.c1Link;
    this.streamId = data.streamId;
    this.playlists = data.playlists;
    this.recording = {
      autoFinish: data.recording.autoFinish,
      finishAfterSeconds: data.recording.finishAfterSeconds,
    };
    this.start = data[videoPosts ? 'start' : 'startDate']
      ? moment(data[videoPosts ? 'start' : 'startDate'])
      : null;
    this.end = data[videoPosts ? 'end' : 'finishDate']
      ? moment(data[videoPosts ? 'end' : 'finishDate'])
      : null;
    this.streamStatus = data.streamStatus;

    if (videoPosts) {
      this.section = data.section?._id;
      this.priority = data.priority?.id;
    }
  }

  @action setLoading(value) {
    this.isLoading = value;
  }

  // Events
  @action onChangeTitle = ({ text }: any) => {
    this.title = text;
  };

  @action onChangeDescription = ({ text }: any) => {
    this.description = text;
  };

  @action onChangeCover = cover => {
    this.cover = cover;
  };

  @action onChangeCoverTitle = coverTitle => {
    this.coverTitle = coverTitle;
  };

  @action onChangeC1Link = event => {
    this.c1Link = typeof event === 'string' ? event : event.target.value;
  };

  @action onChangeVideo = data => {
    this.videoFileTitle = data.alt;
    this.videoFile = { ...toJS(this.videoFile), ...data };
  };

  @action onChangeRecording = autoFinish => {
    this.recording = { ...this.recording, autoFinish };
  };

  @action onChangeFlags = flags => {
    this.flags = flags;
  };

  @action onChangeVideoStream = value => {
    this.streamId = value;
  };

  @action onChangeEmbedLink = event => {
    let value = typeof event === 'string' ? event : event.target.value;
    const youtubeFullLinkRegexp = /youtube\.com\/watch\?v=([^&]+)/;
    const youtubeMatch = youtubeFullLinkRegexp.exec(value);

    if (youtubeMatch && youtubeMatch[1]) {
      value = makeYoutubeEmbedLinkById(youtubeMatch[1]);
    }

    this.embedLink = value;
  };

  @action clearVideoStream = () => {
    this.streamId = '';
  };

  @action
  setInitialized = () => {
    this.initialized = true;
  };

  @action clear = () => {
    this.id = '';
    this.status = FlagsStore.videoPosts
      ? constants.statuses.asPost.draft
      : constants.statuses.asVideo.draft;
    this.title = '';
    this.coverTitle = '';
    this.cover = null;
    this.description = '';
    this.flags = { ...DEFAULT_FLAGS };
    this.type = constants.types.video;
    this.videoFile = null;
    this.videoFileTitle = '';
    this.videoStream = '';
    this.embedLink = '';
    this.c1Link = '';
    this.streamId = '';
    this.playlists = [];
    this.availablePlaylists = [];
    this.availableStreams = [];
    this.initialized = false;
    this.section = null;
    this.recording = { ...DEFAULT_RECORDING_VALUES };
  };

  @action setPlaylists = value => {
    this.playlists = value;
  };

  @action setVideoPriority = value => {
    this.videoPriority = value;
  };

  @action setType = value => {
    this.type = value;
  };

  @action setSection = value => {
    this.section = value;
  };

  // API
  @action fetchVideo(id) {
    this.setLoading(true);

    return this.apiVideoLayer
      ?.fetchVideo(id)
      .then(video => {
        this.updateFromServer(video);
        this.initialized = true;
      })
      .catch(err => {
        throw err;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @action
  async fetchDictionaries() {
    const promises = [
      this.apiVideoLayer?.getDictionaries(),
      this.apiPublicationLayer?.getDictionaries(),
    ];

    Promise.all(promises).then(([videoDictionaries, publicationDictionaries]) => {
      runInAction(() => {
        this.dictionaries = {
          ...videoDictionaries,
          sections: publicationDictionaries.sections,
          ...publicationDictionaries.dictionaries,
        };
      });
    });
  }

  @action
  async fetchPlaylists() {
    this.availablePlaylists = await this.apiVideoLayer?.getPlaylists();
  }

  @action
  async fetchStreams() {
    const streams = await this.apiVideoLayer?.getStreams();

    this.availableStreams = streams.map(e => ({
      publicPath: `/streams/${e._id}/index.m3u8`,
      ...e,
    }));
  }

  @action
  async fetchPriority() {
    const { data } = await this.apiPublicationLayer?.getPostPriority(this.id);

    this.priority = data?.realPriority ?? data?.draftPriority;
  }

  @action createNewVideo() {
    this.setLoading(true);

    return this.apiVideoLayer
      ?.createVideo()
      .then(video => {
        return this.updateFromServer(video);
      })
      .then(() => {
        this.setInitialized();
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @action save = () => {
    const data = this.getDataForServer();
    this.setLoading(true);
    return this.apiVideoLayer
      ?.save({
        id: this.id,
        data,
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  @action publish = async () => {
    const res = await this.apiVideoLayer?.publish(this.id || '');
    const status = res?.data?.status;

    this.status = FlagsStore.videoPosts ? status : this.getDictionaryFromString(status);
  };

  @action unpublish = async () => {
    const res = await this.apiVideoLayer?.unpublish(this.id || '');
    const status = res?.data?.status;

    this.status = FlagsStore.videoPosts ? status : this.getDictionaryFromString(status);
  };

  @action deleteVideo = async () => {
    const res = await this.apiVideoLayer?.delete(this.id || '');

    return res;
  };

  @action
  startStream = () => {
    this.setIsFetching(true);
    return this.save()
      ?.then(() => {
        return this.apiVideoLayer?.startStream(this.id || '').then(data => {
          this.streamStatus = data.streamStatus;
          this.type = data.type;
        });
      })
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  stopStream = () => {
    this.setIsFetching(true);
    return this.apiVideoLayer
      ?.stopStream(this.id || '')
      .then(data => {
        this.streamStatus = data.streamStatus;
        this.videoFile = data.videoFile;
        this.streamId = null;
        this.flags = data.flags;
        this.type = data.type;
      })
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  setStart = value => {
    this.start = value;
  };

  @action
  setEnd = value => {
    this.end = value;
  };

  @action
  setPriority = value => {
    this.priority = value;
  };

  getDictionaryFromString = status => {
    const { ru = status } =
      this.dictionaries?.videoStatuses.find(videoStatus => status === videoStatus.id) || {};
    return { id: status, ru };
  };

  @action
  setIsFetching = isFetching => {
    this.isFetching = isFetching;
  };
}

export default new VideoStore(videoApiLayer, publicationApiLayer);
