import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

import styles from './styles.scss';

interface IProps {
  data: unknown[];
  onCancel?(): void;
  visible: boolean;
}

const SavingModal: React.FC<IProps> = props => {
  const { visible = false, onCancel, data = [] } = props;

  if (!Array.isArray(data)) return null;

  return (
    <Modal title="Сохранение" footer={null} visible={visible} onCancel={onCancel}>
      <div className={styles.savingModal}>
        {data.map(row => {
          const { title, isSaving = false, status = 'ok', error = null, refresh } = row;

          if (title === '') return null;

          return (
            <React.Fragment key={title}>
              <div className={styles.row}>
                {title && <div className={styles.title}>{title}</div>}
                {status && (
                  <div className={styles.status}>
                    {/* eslint-disable no-nested-ternary */
                    isSaving ? (
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    ) : status === 'ok' ? (
                      <CheckCircleOutlined
                        style={{ fontSize: 24 }}
                        className={styles.successIcon}
                      />
                    ) : (
                      <div className={styles.errorIconWrapper}>
                        {refresh && <RedoOutlined style={{ fontSize: 24 }} onClick={refresh} />}
                        <CloseCircleOutlined
                          style={{ fontSize: 24 }}
                          className={styles.errorIcon}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.row}>
                {status === 'error' &&
                  error &&
                  (Array.isArray(error) ? (
                    <div>
                      {error.map(err => (
                        <div key={err} className={styles.error}>
                          {err}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.error}>{error}</div>
                  ))}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </Modal>
  );
};
export default SavingModal;
