import cn from 'classnames';
import React, { ReactNode } from 'react';

import { declOfNum, formatNumber } from '~utils';

import styles from './styles.scss';

interface IProps {
  countDeclinations?: string[];
  countFetched?: number;
  countItems?: number;
  hideMobileTitle?: boolean;
  items?: ReactNode;
  rightContent?: ReactNode;
  title: string;
}

const ContentWrapper: React.FC<IProps> = ({
  title,
  children,
  countDeclinations = [],
  countItems = 0,
  countFetched = 0,
  items,
  rightContent,
  hideMobileTitle,
}) => {
  const withCountFetched = countItems > 0 && countFetched > 0;
  const withoutCountFetched = countItems > 0 && countFetched === 0;

  return (
    <div className={styles.root}>
      {title && (
        <div className={cn(styles.header, hideMobileTitle && styles.hideMobileTitle)}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{title}</div>

            {withoutCountFetched && (
              <div className={styles.count}>
                {`${countItems} ${declOfNum(countItems, countDeclinations)}`}
              </div>
            )}
            {withCountFetched && (
              <div className={styles.count}>
                {`${countFetched} ${declOfNum(countFetched, countDeclinations)} из ${formatNumber(
                  countItems,
                )}`}
              </div>
            )}
            {items && <div className={styles.count}>{items}</div>}
          </div>

          <div className={styles.rightContent}>{rightContent}</div>
        </div>
      )}

      <div className={cn({ [styles.content]: title })}>{children}</div>
    </div>
  );
};

export default ContentWrapper;
