import React from 'react';

import { ISelectProps, Select } from '~components';

const options = [
  { value: 1, label: 'Без вычитки' },
  { value: 2, label: 'Вычитано' },
  { value: 3, label: 'Вычитать повторно' },
];

const CorrectorSelect: React.FC<ISelectProps<object>> = props => (
  <Select {...props} options={options} keyField="id" labelField="name" />
);

export default React.memo(CorrectorSelect) as typeof CorrectorSelect;
