import { Input as AntdInput } from 'antd';
import React, { ChangeEvent, useCallback } from 'react';

import { FilterItem } from '~common';

interface Props {
  item: FilterItem;

  onChange(name: string, value: string);

  value: string;
}

export const Input: React.FC<Props> = props => {
  const { item, value, onChange } = props;
  const { name } = item;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(name, event.target.value);
    },
    [name, onChange],
  );

  return <AntdInput value={value} onChange={handleChange} />;
};
