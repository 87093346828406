import axios from 'axios';

const API = axios.create({
  baseURL: process.env.API_ROOT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Life-Admin-Version': process.env.VERSION,
  },
});

// eslint-disable-next-line func-names
API.interceptors.request.use(function(config) {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${JSON.parse(token)}` : '';
  return config;
});

export const getData = ({ data }) => data;

export const fetcher = url => API.get(url).then(res => res.data);

export default API;
