import { notification } from 'antd';
import { action, computed, observable } from 'mobx';

import { IDraftField } from '~common';
import { EMPTY_STRING } from '~constants';
import { openDraftModal } from '~containers/Draft';
import FlagsStore from '~store/FlagsStore';
import Block from '~store/PublicationStore/BlockModel';

const sortByOrder = ({ order: left = 0 }, { order: right = 0 }) => left - right;

export class DraftStore {
  @observable
  draftBlocksMap = observable.map<string, Block>([]);

  @observable draftSettings: IDraftField = {
    field: 'settings',
    lockedBy: null,
    locketAt: '',
  };

  isModalOpened = false;

  @computed
  get draftBlocks(): Block[] {
    return [...this.draftBlocksMap.values()].sort(sortByOrder);
  }

  @computed
  get isSomethingInDraft(): boolean {
    return this.draftBlocks.length > 0 || !!this.draftSettings.lockedBy;
  }

  @action
  setDraftBlock = (draftBlock: Block) => {
    this.draftBlocksMap.set(draftBlock.id, draftBlock);

    if (this.isModalOpened) {
      notification.warn({
        message: 'Произошли изменения на сервере',
        description: `Блок №${draftBlock.order} был изменен на сервере. Ознакомьтесь с новой версией.`,
        duration: 10,
      });
    }
  };

  @action
  deleteDraftBlock = (blockId: string) => {
    this.draftBlocksMap.delete(blockId);
  };

  @action
  setDraftSettings = (draftSettings: IDraftField) => {
    this.draftSettings = draftSettings;

    if (this.isModalOpened) {
      notification.warn({
        message: 'Произошли изменения на сервере',
        description: `Поле "Настройки" был изменено на сервере. Ознакомьтесь с новой версией.`,
        duration: 10,
      });
    }
  };

  @action
  setDraft = (
    draftBlocks: Block[] = [],
    draftSettings = { field: 'settings', lockedBy: null, locketAt: '' },
  ) => {
    if (!FlagsStore.useDraft) {
      return;
    }

    draftBlocks.forEach(draftBlock => {
      this.setDraftBlock(draftBlock);
    });

    this.draftSettings = draftSettings;

    if (draftBlocks.length === 0 && !draftSettings.lockedBy) {
      return;
    }

    if (!this.isModalOpened) {
      this.setIsModalOpened(true);
    }
  };

  @action
  setIsModalOpened = (isModalOpened = false) => {
    this.isModalOpened = isModalOpened;
    if (isModalOpened) {
      openDraftModal().then(({ blocks = [] }) => {
        this.setIsModalOpened(false);
        blocks.forEach(block => {
          this.deleteDraftBlock(block);
        });
      });
    }
  };

  @action
  clearDraft = () => {
    this.draftSettings = { field: 'settings', lockedBy: null, locketAt: '' };
    this.draftBlocksMap.clear();
  };
}

export default new DraftStore();
