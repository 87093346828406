import '@ant-design/compatible/assets/index.css';

import { Form } from '@ant-design/compatible';
import { Button, Modal, Row, Select } from 'antd';
import { observer } from 'mobx-react';
import { always, find, ifElse, isNil, pipe, prop, propEq } from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';

import { ArrowRight } from '~assets/icons';
import { ItemWithLabel } from '~components';
import { useMovePublicationsStore, useUserStore } from '~hooks';

import SelectRender from './SelectRender';
import s from './styles.scss';

const { Option } = Select;
const { confirm } = Modal;

const getTitle = (value, dictionaries) =>
  pipe(find(propEq('id', value)), ifElse(isNil, always(value), prop('ru')))(dictionaries);

export const MigrationForm = Form.create({ name: 'migrationsForm' })(
  observer(props => {
    const { moveFromToTypes, movePost } = useMovePublicationsStore();
    const { checkPermissions } = useUserStore();
    const [toFilter, setToFilter] = useState({
      label: 'Default',
      type: 'select',
      name: 'Default',
      fix: true,
    });
    const [fromFilter, setFromFilter] = useState({
      label: 'Default',
      type: 'select',
      name: 'Default',
      fix: true,
    });

    const { form } = props;
    const { getFieldDecorator, getFieldsValue, setFieldsValue, validateFields } = form;
    const formData = getFieldsValue();

    const clearValue = useCallback(
      fieldName => {
        setFieldsValue &&
          setFieldsValue({
            [`${fieldName}`]: undefined,
          });
      },
      [setFieldsValue],
    );

    const handleSelectObjectChange = async (value, setValue, clearFieldName) => {
      clearValue(clearFieldName);
      switch (value) {
        case 'tags': {
          setValue({ label: 'Тэг', type: 'select', name: 'filter.tags', fix: true });
          break;
        }
        case 'categories': {
          setValue({ label: 'Категории', type: 'select', name: 'filter.categories', fix: true });
          break;
        }
        case 'section': {
          setValue({ label: 'Разделы', type: 'select', name: 'filter.section', fix: true });
          break;
        }
        case 'flows': {
          setValue({ label: 'Потоки', type: 'select', name: 'filter.flows', fix: true });
          break;
        }
        case 'subcategories': {
          setValue({
            label: 'Подкатегории',
            type: 'select',
            name: 'filter.subcategories',
            fix: true,
          });
          break;
        }
        case 'regions': {
          setValue({
            label: 'Регионы',
            type: 'select',
            name: 'filter.regions',
            fix: true,
          });
          break;
        }
        default: {
          setValue({});
          break;
        }
      }
    };

    const isDisableMove = useMemo(() => {
      const { fromId, toId } = formData;
      return isNil(fromId) || isNil(toId);
    }, [formData]);

    const isDisableCopy = useMemo(() => {
      const { from, to } = formData;
      return (from === 'regions' && from === to) || isDisableMove;
    }, [formData, isDisableMove]);

    const isDisableDelete = useMemo(() => {
      const { fromId, to } = formData;
      return isNil(fromId) && isNil(to);
    }, [formData]);

    const handleMoveButtonClick = movePostType => {
      validateFields(err => {
        if (!err) {
          const formData = getFieldsValue();

          formData.type = `${movePostType}`;

          confirm({
            title: 'Вы уверены что хотите совершить миграцию?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
              movePost && movePost({ ...formData });
            },
          });
        }
      });
    };

    return (
      <Form layout="inline" className={s.movePublicationsForm}>
        <div className={s.movePublicationsFormContent}>
          <div>
            <ItemWithLabel label="Откуда">
              <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('from')(
                    <Select
                      className={s.inputStyle}
                      placeholder="Выберите объект"
                      onChange={value => {
                        handleSelectObjectChange(value, setFromFilter, 'fromId');
                      }}
                    >
                      {moveFromToTypes.map(
                        ({ id, canSelect }) =>
                          canSelect && <Option value={id}>{getTitle(id, moveFromToTypes)}</Option>,
                      )}
                    </Select>,
                  )}
              </Form.Item>
            </ItemWithLabel>
            <Form.Item>
              {getFieldDecorator &&
                getFieldDecorator('fromId')(
                  <SelectRender className={s.inputStyle} item={fromFilter} />,
                )}
            </Form.Item>
          </div>
          <ArrowRight className={s.arrowRight} />
          <div>
            <ItemWithLabel label="Куда">
              <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('to')(
                    <Select
                      className={s.inputStyle}
                      placeholder="Выберите объект"
                      allowClear
                      onChange={value => {
                        handleSelectObjectChange(value, setToFilter, 'toId');
                      }}
                    >
                      {moveFromToTypes.map(
                        ({ id, canSelect }) =>
                          canSelect && <Option value={id}>{getTitle(id, moveFromToTypes)}</Option>,
                      )}
                    </Select>,
                  )}
              </Form.Item>
            </ItemWithLabel>
            <Form.Item>
              {getFieldDecorator &&
                getFieldDecorator('toId')(
                  <SelectRender className={s.inputStyle} item={toFilter} />,
                )}
            </Form.Item>
          </div>
        </div>
        <Row align="middle">
          <Button
            className={s.button}
            disabled={isDisableMove || !checkPermissions('movePost.update')}
            onClick={() => {
              handleMoveButtonClick('MOVE_POST_TYPE_MOVE');
            }}
          >
            Переместить
          </Button>
          <Button
            className={s.button}
            disabled={isDisableCopy || !checkPermissions('movePost.update')}
            onClick={() => {
              handleMoveButtonClick('MOVE_POST_TYPE_ADD');
            }}
          >
            Копировать
          </Button>
          <Button
            className={s.button}
            disabled={isDisableDelete || !checkPermissions('movePost.update')}
            onClick={() => {
              handleMoveButtonClick('MOVE_POST_TYPE_DELETE');
            }}
          >
            Удалить
          </Button>
        </Row>
      </Form>
    );
  }),
);
