import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import styles from './styles.scss';

@observer
class ViewButton extends Component {
  handleToggleView = () => {
    const { type, onClick } = this.props;

    onClick(type);
  };

  render() {
    const { icon, currentType, type, hideMobile } = this.props;

    return (
      <Button
        className={classNames(
          styles.toggleButton,
          currentType === type && styles.active,
          hideMobile && styles.hideMobile,
        )}
        shape="circle"
        icon={icon}
        onClick={this.handleToggleView}
      />
    );
  }
}

export default ViewButton;

ViewButton.propTypes = {
  hideMobile: PropTypes.bool,
  currentType: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.string,
  type: PropTypes.string,
};
