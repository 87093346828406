import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React from 'react';

import { IDictionary } from '~common';
import { ItemWithLabel } from '~components';
import { resourceTypes } from '~constants';
import { usePublicationStore, useUserStore } from '~hooks';
import { getAuthorityByResourceType } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const YandexTagsSelect = observer(() => {
  const { yandexTag, dictionariesList, set, resourceType } = usePublicationStore();
  const { checkPermissions } = useUserStore();

  const yandexTags: IDictionary[] = dictionariesList?.dictionaries?.yandexTags ?? [];
  const options: LabeledValue[] = yandexTags.map(yandexTag => ({
    value: yandexTag.id,
    label: yandexTag.ru,
  }));

  const onChange = yandexTag => {
    const defaultTag = '';
    set({ name: 'yandexTag', value: yandexTag ?? defaultTag });
  };

  return (
    <ItemWithLabel label="Тег для Яндекс.Статьи">
      <Select
        style={inputStyle}
        placeholder="Выберите тег"
        value={yandexTag}
        options={options}
        onChange={onChange}
        allowClear
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
