import { observer } from 'mobx-react';
import React from 'react';

import { IDepartment } from '~common';
import { ISelectProps, Select } from '~components';
import { useDepartmentsStore } from '~hooks';

export const DepartmentsSelect: React.FC<ISelectProps<IDepartment>> = observer(props => {
  const { fetchDepartmentBySearch, departmentsSearches } = useDepartmentsStore();

  return (
    <Select
      {...props}
      fetch={fetchDepartmentBySearch}
      options={departmentsSearches}
      keyField="_id"
      labelField="name"
    />
  );
});
