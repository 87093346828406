import { LockOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { observer } from 'mobx-react';
import { isNil } from 'ramda';
import React, { FC } from 'react';

import { IUser } from '~common';
import { EMPTY_STRING } from '~constants';
import { usePublicationStore } from '~hooks';

interface ILockedToProps {
  lockedBy?: IUser<'full'>;
}

const { Paragraph } = Typography;

export const LockedBy: FC<ILockedToProps> = observer(props => {
  const { lockedBy = null } = props;
  const isNotLocked = isNil(lockedBy);
  const firstName = lockedBy?.firstName || EMPTY_STRING;
  const lastName = lockedBy?.lastName || EMPTY_STRING;
  const { isLockedByMe } = usePublicationStore();

  if (isNotLocked) {
    return null;
  }

  if (isLockedByMe({ lockedBy })) {
    return (
      <Paragraph type="success">
        {`Заблокировано вами `}
        <LockOutlined />
      </Paragraph>
    );
  }

  return (
    <Paragraph type="warning">
      {`Заблокировал(а) ${firstName} ${lastName} `}
      <LockOutlined />
    </Paragraph>
  );
});
