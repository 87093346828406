import { ExclamationCircleFilled, LogoutOutlined } from '@ant-design/icons';
import { Alert, Modal } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Burger from '~assets/icons/menu.svg';
import Logo from '~assets/logo.svg';
import Button from '~components/Button';
import ErrorBoundary from '~components/ErrorBoundary';
import PostsPageGlobalHeader from '~components/PostsPageGlobalHeader';
import User from '~components/User';
import { Overlay } from '~containers/Overlay';
import { useStatusStore, useUserStore } from '~hooks';

import Menu from './components/Menu';

import styles from './styles.scss';

const { confirm } = Modal;

const AppLayout = observer(props => {
  const { children, showPostsHeader } = props;
  const { logout, user } = useUserStore();
  const { isChanged, isChangedFlags, notifications } = useStatusStore();
  const [isOpen, setIsOpen] = useState(false);
  const { status } = useStatusStore();

  const toggleBurger = () => {
    if (document && document.scrollingElement)
      document.scrollingElement.style.overflow = isOpen ? 'auto' : 'hidden';

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isChanged) {
      confirm({
        content:
          'Вышла новая версия приложения. Необходимо обновить страницу. Сделать это сейчас? (Все несохраненные данные будут потеряны). После перезагрузки не забудьте почистить кеш!',
        onOk: () => {
          window.location.reload();
        },
      });
    }
  }, [isChanged]);

  useEffect(() => {
    if (isChangedFlags) {
      confirm({
        content:
          'Изменились настройки. Необходимо обновить страницу. Сделать это сейчас? (Все несохраненные данные будут потеряны)',
        onOk: () => {
          window.location.reload();
        },
      });
    }
  }, [isChangedFlags]);

  return (
    <div className={styles.appLayout}>
      <aside className={cn(styles.appSidebar, isOpen && styles.open)}>
        {showPostsHeader && <PostsPageGlobalHeader />}

        <div className={cn(styles.header, showPostsHeader && styles.showPostsHeader)}>
          <Link to={'/'} style={{ display: 'flex' }}>
            <Logo fill="#fff" />
          </Link>

          <div onClick={toggleBurger} className={styles.burger}>
            <Burger fill="#fff" />
          </div>
        </div>

        <div className={styles.content}>
          <Menu />

          <div className={styles.footer}>
            <User person={user} />
            <Button type="link" className={styles.logoutButton} onClick={logout}>
              <LogoutOutlined style={{ fontSize: 20, width: 20, height: 20 }} />
              Выйти
            </Button>
            <p />
            <p className={styles.version}>{`Версия life-admin: ${process.env.VERSION}`}</p>
            <p className={styles.version}>{`Версия life-schema: ${process.env.SCHEMA_VERSION}`}</p>
            <p className={styles.version}>{`Версия life-server: ${status.version}`}</p>
          </div>
        </div>

        <div className={cn(styles.overlay, isOpen && styles.open)} onClick={toggleBurger} />
      </aside>
      <Overlay>
        <div className={styles.layoutContent}>
          {notifications.length > 0 && (
            <div className={styles.notifications}>
              {notifications.map(({ message }) => (
                <Alert
                  className={styles.notification}
                  message={
                    <div className={styles.text}>
                      <ExclamationCircleFilled className={styles.icon} />
                      <span
                        className={styles.message}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    </div>
                  }
                  type="info"
                />
              ))}
            </div>
          )}
          <ErrorBoundary>
            <children.type {...children.props} />
          </ErrorBoundary>
        </div>
      </Overlay>
    </div>
  );
});

AppLayout.displayName = 'AppLayout';
export default AppLayout;
