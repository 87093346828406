import { observable, action, runInAction, computed } from 'mobx';
import { find, findIndex } from 'lodash';
import API from '~api';
import FetchPaginationStore from '~store/fetchPaginationStore';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';
import { identity } from 'ramda';

const SPEC_PROJECTS_URL = '/specialProjects';

export class SpecProjectsStore {
  @observable specProjects = [];

  @observable isFirstLoading = true;

  @observable isActionModal = false;

  @observable isShowModal = false;

  @observable editableSpecProjects = null;

  @observable dictionaries = [];

  @observable itemsCount = 0;

  @observable currentSpecTabFilter = '';
  @observable currentSpecTabSection = '';

  @action
  setCurrentSpecTabFilter = (filter, section) => {
    this.currentSpecTabFilter = filter;
    this.currentSpecTabSection = section;
  };

  @action
  fetchDictionaries = () => {
    const promise = API.get('/dictionaries/settings');

    return promise.then(res => {
      const { data } = res;

      runInAction(() => {
        this.dictionaries = data?.specialProjectTypes;
      });
    });
  };

  @action
  fetchSpecProjects = defaultFilter => {
    const { offset, setPageOffset } = FetchPaginationStore;
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: SPEC_PROJECTS_URL, defaultFilter });
    const fetchSpecProjectsPromise = API.get(promiseUrl);

    if (this.isFirstLoading || offset === 0) {
      runInAction(() => {
        this.specProjects = [];
      });
    }

    return fetchSpecProjectsPromise
      .then(res => {
        const { data, meta } = res.data;

        runInAction(() => {
          this.specProjects = [...this.specProjects, ...data].sort(
            ({ order: a }, { order: b }) => a - b,
          );
          this.isFirstLoading = false;
          this.itemsCount = meta.count;

          setPageOffset(offset + 1);
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false;
        });
      });
  };

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const current = find(this.specProjects, { order: oldIndex });
    const target = find(this.specProjects, { order: newIndex });

    const currentId = find(this.specProjects, { order: oldIndex })._id;
    const targetId = findIndex(this.specProjects, ({ order }) => order === newIndex);

    let toEnd = false;

    let targetPlacement;

    if (targetId + 1 === this.specProjects.length) {
      targetPlacement = this.specProjects[this.specProjects.length - 1]._id;
      toEnd = true;
    } else {
      targetPlacement = this.specProjects[targetId + 1]._id;
    }
    if (oldIndex > newIndex) {
      targetPlacement = find(this.specProjects, { order: newIndex })._id;
    }

    let data;

    if (toEnd) {
      data = {
        meta: {
          after: targetPlacement,
        },
      };
    } else {
      data = {
        meta: {
          before: targetPlacement,
        },
      };
    }

    const changeOrderingPromise = API.post(`${SPEC_PROJECTS_URL}/${currentId}/placement`, data);

    current.order = newIndex;
    target.order = newIndex + 0.5;

    this.specProjects = this.specProjects.slice().sort(({ order: a }, { order: b }) => a - b);

    changeOrderingPromise.then(() => {
      this.fetchSpecProjects(this.currentSpecTabFilter);
    });
  };

  @action
  createSpecProjects = project => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const createSpecProjectsPromise = API.post(SPEC_PROJECTS_URL, project);

    return createSpecProjectsPromise
      .then(res => {
        const { data } = res.data;
        this.setHideModal();
        runInAction(() => {
          this.isActionModal = false;
          this.specProjects.unshift(data);

          this.fetchSpecProjects(this.currentSpecTabFilter);
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  updateSpecProjects = project => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const { _id } = this.editableSpecProjects;
    const updateSpecProjectsPromise = API.patch(`${SPEC_PROJECTS_URL}/${_id}`, project);

    return updateSpecProjectsPromise
      .then(res => {
        const { data } = res.data;
        const editableSpecProjectsIndex = this.specProjects.indexOf(this.editableSpecProjects);

        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.specProjects[editableSpecProjectsIndex] = data;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  deleteSpecProjects = () => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const { _id } = this.editableSpecProjects;
    const deleteSpecProjectsPromise = API.delete(`${SPEC_PROJECTS_URL}/${_id}`);

    return deleteSpecProjectsPromise
      .then(() => {
        this.specProjects.remove(this.editableSpecProjects);
        this.fetchSpecProjects(this.currentSpecTabFilter);
        this.resetEditableSpecProjects();
        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false;
        });
      });
  };

  @action
  setEditableSpecProjects = value => {
    this.editableSpecProjects = value;

    this.setShowModal();
  };

  @action
  resetEditableSpecProjects = () => {
    this.editableSpecProjects = null;
  };

  @action
  setShowModal = () => {
    this.isShowModal = true;
  };

  @action
  setHideModal = () => {
    this.isShowModal = false;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @computed
  get hasMore() {
    return this.specProjects.length < this.itemsCount;
  }

  @computed
  get specLength() {
    return this.specProjects.length;
  }

  initFilterParams = () => {
    const { SET_FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore;

    SET_FILTER_PARAMS_FOR_BACK({
      'filter.section': identity,
    });
  };
}

export default new SpecProjectsStore();
