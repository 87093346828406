import { observable, action, runInAction } from 'mobx'
import API from '~api'

const TIMERS_URL = '/settings/timers'

class TimersStore {
  @observable timers = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editableTimer = null

  @action
  fetchTimers = () => {
    const fetchTimersPromise = API.get(TIMERS_URL)

    return fetchTimersPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.timers = data
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  updateTimer = timer => {
    runInAction(() => {
      this.isActionModal = true
    })

    const updateTimerPromise = API.patch(`${TIMERS_URL}`, {
      data: Object.assign(this.timers, timer)
    })

    return updateTimerPromise
      .then(res => {
        const { data } = res.data

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.timers = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditableTimer = value => {
    this.editableTimer = value

    this.setShowModal()
  }

  @action
  resetEditableTimer = () => {
    this.editableTimer = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new TimersStore()
