import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button } from 'antd'
import FilterToggleControl from '~components/FilterToggleControl'

import styles from './styles.scss'

class FilterControls extends Component {
  render() {
    const { onToggleShowFilter, isShowFilter, onToggleMyPost, isAllPost } = this.props

    return (
      <div className={styles.root}>
        <Button type="link" onClick={onToggleMyPost} className={styles.customFilterBtn}>
          {isAllPost ? 'Показать все посты' : 'Показать мои посты'}
        </Button>
        <div>
          <FilterToggleControl onClick={onToggleShowFilter} isActive={isShowFilter} />
        </div>
      </div>
    )
  }
}

export default FilterControls

FilterControls.propTypes = {
  isShowFilter: PropTypes.bool,
  isAllPost: PropTypes.bool,
  onToggleShowFilter: PropTypes.func,
  onToggleMyPost: PropTypes.func
}
