import PropTypes from 'prop-types'
import React from 'react'

import { DatePicker } from 'antd'
import moment from 'moment'

const { RangePicker } = DatePicker

const DateRangeRender = ({ onChange, value }) => {
  let dateValue = value

  if (dateValue) {
    const dateArray = value.split(',')

    dateValue = !!value && [moment(dateArray[0]), moment(dateArray[1])]
  } else {
    dateValue = undefined
  }

  return (
    <RangePicker
      style={{
        width: '100%'
      }}
      onChange={onChange}
      value={dateValue}
      placeholder={['Начальная', 'Конечная']}
    />
  )
}

export default DateRangeRender

DateRangeRender.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any
}
