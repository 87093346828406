import { AxiosResponse } from 'axios';

import API from '~api';

export abstract class AbstractTransportLayer<
  Model = Record<string, any>,
  CreateModel = Model,
  UpdateModel = Model
> {
  // eslint-disable-next-line no-useless-constructor
  protected constructor(protected readonly url: string) {}

  get = (id: string): Promise<AxiosResponse<Model>> => API.get(`${this.url}/${id}`);

  getList = (params = ''): Promise<AxiosResponse> => API.get(`${this.url}${params}`);

  create = (data: CreateModel): Promise<AxiosResponse<Model>> => API.post(this.url, { data });

  update = (id: string, data: UpdateModel): Promise<AxiosResponse<Model>> =>
    API.patch(`${this.url}/${id}`, {
      data,
    });

  delete = (id: string): Promise<AxiosResponse> => API.delete(`${this.url}/${id}`);
}
