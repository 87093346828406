import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { message } from 'antd';
import { LeftOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import styles from './styles.scss';

@inject('PublicationStore')
@observer
class PostsPageGlobalHeader extends Component {
  handleOpenSettingsDrawer = () => {
    const { PublicationStore } = this.props;

    PublicationStore.showSettingsDrawer();
  };

  handleClickExit = () => {
    const { history, match } = this.props;

    history.push(match.path.slice(0, -4));
  };

  handleSavePost = () => {
    const {
      PublicationStore: { isFetching = false, savePublication = () => {} },
    } = this.props;
    if (isFetching) {
      message.error('Сохранение недоступно. Повторите попытку позднее');
    } else {
      savePublication();
    }
  };

  render() {
    const { PublicationStore } = this.props;
    const { initialized, saving, isShowSettingsDrawer } = PublicationStore;

    if (!initialized) {
      return null;
    }

    return (
      <div className={styles.root}>
        <div
          className={classNames(styles.ico, saving && styles.disabled)}
          onClick={this.handleClickExit}
        >
          <LeftOutlined disabled /> Выход
        </div>

        <div className={styles.controls}>
          <div
            className={classNames(styles.ico, saving && styles.disabled)}
            onClick={this.handleSavePost}
          >
            <SaveOutlined />
          </div>

          <div
            className={classNames(
              styles.ico,
              saving && styles.disabled,
              isShowSettingsDrawer && styles.active,
            )}
            onClick={this.handleOpenSettingsDrawer}
          >
            <SettingOutlined />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PostsPageGlobalHeader);

PostsPageGlobalHeader.propTypes = {
  PublicationStore: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
};
