import { Card, Col, Row } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Role } from '~common';
import { roleModal } from '~containers';
import { useRolesStore } from '~hooks';

import styles from './styles.module.scss';

interface RoleCardProps {
  onChangedRole(role: Role): void;
  role: Role;
}

const RoleCard: React.FC<RoleCardProps> = ({ role, onChangedRole }) => {
  const onEdit = async () => {
    if (!role.allRights && (await roleModal({ role }))) {
      onChangedRole(role);
    }
  };

  return (
    <Card hoverable={!role.allRights} onClick={onEdit}>
      <Row gutter={[10, 10]}>
        <Col span={24} className={styles.name}>
          {role.name}
        </Col>
        <Col span={24} className={styles.status}>
          <Row align="middle" gutter={[4, 4]}>
            <Col>
              <div className={classNames(styles.dot, !role.active && styles.disabled)} />
            </Col>
            <Col>{role.active ? 'АКТИВНА' : 'НЕАКТИВНА'}</Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export const RoleList = observer(() => {
  const { items, resetList } = useRolesStore();

  const onChangeRole = () => {
    resetList();
  };

  return (
    <Row gutter={[20, 20]}>
      {items.map((item, index) => (
        <Col span={4} sm={12} md={6} key={index}>
          <RoleCard role={item} onChangedRole={onChangeRole} />
        </Col>
      ))}
    </Row>
  );
});
