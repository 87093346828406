import PropTypes from 'prop-types'
import React from 'react'

import classNames from 'classnames'

import styles from './styles.scss'

const DAY_NAME_FORMAT = 'ddd'
const DAY_NUMBER_FORMAT = 'DD'

const WeekHeader = ({ date, localizer }) => {
  const formatAction = localizer.format

  const dayName = formatAction(date, DAY_NAME_FORMAT)
  const dayNumber = formatAction(date, DAY_NUMBER_FORMAT)

  const isToday = formatAction(new Date(), 'YYYY-MM-DD') === formatAction(date, 'YYYY-MM-DD')

  return (
    <div className={classNames(styles.root, isToday && styles.today)}>
      <div className={styles.title}>{dayName}</div>
      <div className={styles.subTitle}>{dayNumber}</div>
    </div>
  )
}

export default WeekHeader

WeekHeader.propTypes = {
  date: PropTypes.string,
  localizer: PropTypes.object
}
