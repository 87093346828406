import { Popover } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { IApprover } from 'src/schemas/approver';

import CheckIcon from '~assets/icons/check.svg';

import styles from './styles.scss';

export const getColor = (isValidated = false, validatedAt = '') =>
  // eslint-disable-next-line no-nested-ternary
  isValidated && validatedAt ? '#34C759' : isValidated ? '#ff9500' : '#9D9D9D';

const getCorrectorName = (corrector): string => {
  if (corrector) {
    const { firstName = '', lastName = '' } = corrector;

    if (firstName || lastName) return `${firstName} ${lastName}`;
  }
  return '';
};

interface IProps {
  corrector?: IApprover | boolean;
  isMobile?: boolean;
  onClick?: Function;
  validatedAt?: IValidatedAt;
}

interface IValidItemProps {
  color?: string;
  isMobile?: boolean;
  isValid?: boolean;
  label?: string;
  name?: string;
  onClick?: Function;
}

interface IValidItemWithPopoverProps extends IValidItemProps {
  popoverContent: string;
}

interface IValidatedAt {
  corrector?: string;
}

export const ValidItem: React.FC<IValidItemProps> = observer(props => {
  const { isMobile = false, label = '', isValid, name = '', color = '', onClick } = props;

  return (
    <div
      {...props}
      className={classNames(styles.item, isMobile && styles.isMobile)}
      style={{ color }}
      onClick={() => {
        onClick && onClick(name);
      }}
    >
      {isValid && !isMobile && (
        <span className={styles.icon}>
          <CheckIcon fill={color} />
        </span>
      )}
      {label}
    </div>
  );
});

const ValidItemWithPopover: React.FC<IValidItemWithPopoverProps> = observer(props => {
  const { popoverContent, ...rest } = props;

  return popoverContent ? (
    <Popover content={popoverContent} placement="bottom">
      <ValidItem {...rest} />
    </Popover>
  ) : (
    <ValidItem {...rest} />
  );
});

const Validated: React.FC<IProps> = props => {
  const {
    corrector,
    validatedAt = {
      corrector: '',
    },
    onClick,
    isMobile = false,
  } = props;

  const toggle = name => {
    onClick && onClick({ [name]: !props[name] });
  };

  return (
    <div className={styles.root}>
      <ValidItemWithPopover
        isValid={!!corrector}
        color={getColor(!!corrector, validatedAt.corrector)}
        onClick={toggle}
        name="corrector"
        label="Корректор"
        isMobile={isMobile}
        popoverContent={getCorrectorName(corrector)}
      />
    </div>
  );
};

export default Validated;
