import { Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { GridWrapper } from '~components';
import { redirectResponseCodes } from '~constants';
import { DynamicField } from '~containers/Filter/components';
import { useFetchPaginationStore, useFilterParamsByListStore, useRedirectsStore } from '~hooks';
import { noop } from '~utils';

import s from './styles.scss';

const RedirectsFilter = observer(() => {
  const { redirectTypes, getRedirects } = useRedirectsStore();
  const { setFilterParams } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();

  const handleResetFilter = () => {
    setFilterParams();
    resetPaginationParams();
    getRedirects();
  };

  const handleSetFilter = () => {
    resetPaginationParams();
    getRedirects();
  };

  return (
    <>
      <div className={s.filterGrid}>
        <GridWrapper gridSize={5} maxItemWidth={285}>
          <DynamicField
            item={{
              type: 'select',
              name: 'filter.type',
              label: 'Тип',
              selectOptions: {
                options: redirectTypes,
                fetch: noop,
                key: 'id',
                label: 'ru',
              },
            }}
          />
          <DynamicField
            item={{
              type: 'select',
              name: 'filter.status',
              label: 'Статус',
              selectOptions: {
                options: redirectResponseCodes,
                fetch: noop,
                key: 'id',
                label: 'ru',
              },
            }}
          />
        </GridWrapper>
      </div>
      <div>
        <Button type="ghost" onClick={handleResetFilter}>
          Отменить
        </Button>
        <Button type="primary" onClick={handleSetFilter}>
          Применить
        </Button>
      </div>
    </>
  );
});

export default RedirectsFilter;
