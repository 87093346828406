import { observer } from 'mobx-react';
import React from 'react';

import { IAuthor } from '~common';
import { ISelectProps, Select } from '~components';
import { useAuthorsStore } from '~hooks';

export const AuthorsSelect: React.FC<ISelectProps<IAuthor>> = observer(props => {
  const { fetchAuthorsBySearch, authorsSearches } = useAuthorsStore();

  return (
    <Select
      {...props}
      fetch={fetchAuthorsBySearch}
      options={authorsSearches}
      keyField="_id"
      labelField="name"
    />
  );
});
