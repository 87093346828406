import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { IAuthor } from '~common';
import { ItemWithLabel } from '~components';
import { resourceTypes } from '~constants';
import { useAuthorsStore, usePublicationStore, useUserStore } from '~hooks';
import { getAuthorityByResourceType, getUniqueListBy } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const AuthorsSelect = observer(() => {
  const { authors, resourceType, set } = usePublicationStore();
  const { checkPermissions } = useUserStore();
  const { fetchAuthorsBySearch, authorsSearches } = useAuthorsStore();

  const selectedOptions: LabeledValue[] = authors.map((author: IAuthor) => ({
    value: author._id,
    label: author.name,
  }));

  const options: LabeledValue[] = getUniqueListBy<LabeledValue>(
    [...authors, ...authorsSearches].map((tag: IAuthor) => ({
      value: tag._id,
      label: tag.name,
    })),
    'value',
  );

  const onChange = (authors: LabeledValue[] = []) => {
    set({ name: 'authors', value: authors.map(({ key, label }) => ({ _id: key, name: label })) });
  };

  const onSearch = term => {
    fetchAuthorsBySearch(term);
  };

  useEffect(() => {
    fetchAuthorsBySearch('');
  }, []);

  return (
    <ItemWithLabel label="Авторы">
      <Select
        mode="multiple"
        maxTagCount={1}
        style={inputStyle}
        placeholder="Выберите автора"
        value={selectedOptions}
        options={options}
        onChange={onChange}
        onSearch={onSearch}
        notFoundContent={null}
        filterOption={false}
        showSearch
        labelInValue
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
