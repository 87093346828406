import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { IBadWord } from '~common';
import { WhiteBlockWithShadow } from '~components';
import { useBadWordsStore } from '~hooks';
import { Pencil } from '~icons';

import styles from './styles.scss';

interface IProps {
  badWord: IBadWord;
}

export const BadWordItem = observer((props: IProps) => {
  const { badWord } = props;
  const { name, index } = badWord;
  const { setEditableBadWord } = useBadWordsStore();

  const handleClickItem = useCallback(() => {
    setEditableBadWord(badWord);
  }, [setEditableBadWord, badWord]);

  return (
    <WhiteBlockWithShadow>
      <div className={styles.root} onClick={handleClickItem}>
        <div className={styles.ico}>
          <Pencil fill="#D3D3D3" />
        </div>
        <div className={styles.title}>{name}</div>
        <div className={styles.id}>ID {index}</div>
      </div>
    </WhiteBlockWithShadow>
  );
});
