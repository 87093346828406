import { Col, Row, Switch, Tooltip } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { IBlock, IMedia } from '~common';
import { ButtonShape } from '~constants';
import { useCheckStatusUpload, useFlagsStore, useUserStore } from '~hooks';
import { Close, Edit } from '~icons';
import { isAnyTruthy } from '~utils';

import { BlockButton } from '../../BlockButton';
import { DropZone } from '../../DropZone';
import { EditorContext } from '../../Editor';
import { disabledButtonsInStandardInput, RichEditor } from '../../RichEditor';
import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.styl';

interface IProps extends IBlock {
  _id?: string;
  alt?: string;
  altHTML?: string;
  altPlaceholder?: string;
  charactersCount?: number;
  fileName?: string;
  hideAltInput?: boolean;
  isCoverHidden?: boolean;
  isInGallery?: boolean;
  jobId?: number;
  media?: IMedia;
  mimeType?: string;
  mimetype?: string;
  noDragEventsBubbling?: boolean;
  onChange?(block: IBlock & { meta: { blockId?: string } }): void;
  onEdit?(url: string, cb?: (data: any) => void): void;
  onProgressChange?(progress: number): void;
  size?: number;
  status?: string;
  storageBucket?: string;
  uploadData?: object;
  uploadPlaceholder?: string;
  uploadUrl?: string;
  url?: string;
}

const ImageBlock: React.FC<IProps> = observer(props => {
  const {
    url,
    mimetype,
    size,
    onChange,
    onEdit,
    uploadPlaceholder,
    uploadData = { type: 'block' },
    isFetching = false,
    isEditing = false,
    isCoverHidden = true,
    alt = '',
    type,
    hideAltInput = false,
    altPlaceholder = 'Подпись к фотографии',
    charactersCount = 0,
    uploadUrl,
    blockProps = {
      title: 'Фотография',
      maxSize: 'до 10мб',
    },
    noDragEventsBubbling = true,
    blocked,
  } = props;

  const { fileUploadWorker } = useFlagsStore();
  const { checkPermissions } = useUserStore();
  const { onEditImage = onEdit } = useContext(EditorContext);
  const isImageHere = useMemo(() => url, [url]);
  const [isUploading, setIsUploading] = useState(false);

  const image = useMemo(() => ({ url, mimetype, size }), [mimetype, size, url]);

  const getData = useCallback(() => {
    const {
      _id = null,
      url = '',
      alt = '',
      altHTML = '',
      size = 0,
      mimeType = '',
      fileName = '',
      storageBucket,
      media,
      jobId,
      isCoverHidden = true,
      id: blockId,
    } = props;

    return {
      _id,
      url,
      alt,
      altHTML,
      size,
      fileName,
      storageBucket,
      mimeType,
      isCoverHidden,
      media,
      jobId,
      meta: {
        blockId,
      },
    };
  }, [props]);

  const save = useCallback(
    data => {
      onChange &&
        onChange({
          ...getData(),
          ...data,
        });
    },
    [getData, onChange],
  );

  const { checkStatus, isFetching: isFetchingJob, status } = useCheckStatusUpload(save);

  const onChangeAlt = useCallback(
    ({ raw: alt, plainText, html }) => {
      save({
        alt: JSON.stringify(alt),
        altHTML: html,
        charactersCount: plainText.length,
      });
    },
    [save],
  );

  const onClickCoverHiddenCheckBox = useCallback(
    checked => {
      save({
        isCoverHidden: checked,
      });
    },
    [save],
  );

  const onClickDelete = useCallback(() => {
    save({
      _id: null,
      size: 0,
      url: '',
      mimeType: '',
    });
  }, [save]);

  const onClickEdit = useCallback(() => {
    onEditImage &&
      onEditImage(props.url || '', data => {
        if (fileUploadWorker) {
          checkStatus(data.id);
        } else {
          save(data);
        }
      });
  }, [checkStatus, fileUploadWorker, onEditImage, props.url, save]);

  const onProgressChange = useCallback(
    (progress: number) => {
      props.onProgressChange && props.onProgressChange(progress);
      setIsUploading(progress > 0);
    },
    [props.onProgressChange],
  );

  const { maxSize } = blockProps;

  const isCover = type === 'COVER';

  const header = useMemo(() => {
    return (
      <div className={cn(styles.checkbox, blocked && styles.blocked)}>
        <Tooltip title={isCoverHidden ? 'Скрыть обложку' : 'Показать обложку'}>
          <Switch
            onChange={checked => {
              onClickCoverHiddenCheckBox(checked);
            }}
            checked={isCoverHidden}
            disabled={
              blockProps?.blocked ||
              isFetching ||
              !checkPermissions('posts.flagsBlackAdultCriminalWideCover')
            }
            checkedChildren="С"
            unCheckedChildren="О"
          />
        </Tooltip>
      </div>
    );
  }, [
    blockProps?.blocked,
    blocked,
    checkPermissions,
    isCoverHidden,
    isFetching,
    onClickCoverHiddenCheckBox,
  ]);

  return (
    <BlockWrapper
      {...blockProps}
      maxSize={maxSize ? `${charactersCount}/${maxSize}` : ''}
      wrapperClassname={styles.wrapper}
      header={isCover ? header : null}
    >
      <div className={styles.dropZoneWrapper}>
        {isImageHere && (
          <div className={styles.controls}>
            <BlockButton shape={ButtonShape.Round} onClick={onClickDelete} className={styles.btn}>
              <Close style={{ width: '12px', height: '12px' }} />
              Удалить
            </BlockButton>

            {onEditImage && (
              <BlockButton shape={ButtonShape.Round} onClick={onClickEdit} className={styles.btn}>
                <Edit style={{ width: '24px', height: '24px' }} />
              </BlockButton>
            )}
          </div>
        )}
        <DropZone
          noDragEventsBubbling={noDragEventsBubbling}
          accept="image/*"
          uploadUrl={uploadUrl}
          uploadPlaceholder={isCover ? require('~assets/uploadPlaceholder.jpg') : uploadPlaceholder}
          files={[image]}
          onChange={save}
          onProgressChange={onProgressChange}
          uploadData={isCover ? { type: 'cover' } : uploadData}
          status={props.status || status}
        />
      </div>

      {!hideAltInput && (
        <>
          <div className={styles.captionHeader}>{altPlaceholder}</div>
          <Row align="middle" className={cn(isCover && isCoverHidden && styles.coverWrapper)}>
            {isCover && isCoverHidden && (
              <Col xs="auto" sm={6} xl={3} className={styles.coverPrefix}>
                Обложка ©
              </Col>
            )}
            <Col
              xs={isCover && isCoverHidden ? 18 : 24}
              sm={isCover && isCoverHidden ? 18 : 24}
              xl={isCover && isCoverHidden ? 21 : 24}
            >
              <RichEditor
                rawContent={alt}
                disabledButtons={disabledButtonsInStandardInput}
                onChange={onChangeAlt}
                readOnly={isAnyTruthy([isUploading, isFetchingJob, isFetching])}
                isEditing={isEditing}
                withPrefix={isCover && isCoverHidden}
              />
            </Col>
          </Row>
        </>
      )}
    </BlockWrapper>
  );
});

export default ImageBlock;
