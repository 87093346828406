import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

interface IProps {
  className?: string;
  isDropDisabled?: boolean;
  items?: unknown[];
  listId?: string;
  listType?: string;
}

const DroppableList: React.FC<IProps> = props => {
  const {
    // ignoreContainerClipping,
    // internalScroll,
    // scrollContainerStyle,
    isDropDisabled,
    // isCombineEnabled,
    listId = 'LIST',
    listType,
    // style,
    // items,
    // title,
  } = props;

  return (
    <Droppable droppableId={listId} type={listType} isDropDisabled={isDropDisabled}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%' }}>
          {/* {items.map((item, index) => renderItem(item, index))} */}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableList;
