import React, { Component } from 'react'
import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import { SocialsList, SocialsModal } from '~components/Socials'

const PAGE_TITLE = 'Настройка ссылок на аккаунты в соцсетях и мессенджерах'

class SettingsPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <SocialsList />
        </ContentWrapper>
        <SocialsModal />
      </>
    )
  }
}

export default SettingsPage
