import React, { Component } from 'react'
import SettingsNavigation from '~components/SettingsNavigation'
import ContentWrapper from '~components/ContentWrapper'
import { PlaylistsModal, PlaylistsList, PlaylistsFilter } from '~components/Playlists'

const PAGE_TITLE = 'Настройки плейлистов'

class SettingsPlaylistsPage extends Component {
  render() {
    return (
      <>
        <SettingsNavigation />
        <ContentWrapper title={PAGE_TITLE}>
          <PlaylistsFilter />
          <PlaylistsList />
        </ContentWrapper>
        <PlaylistsModal />
      </>
    )
  }
}

export default SettingsPlaylistsPage
