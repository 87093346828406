import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { observer } from 'mobx-react';
import moment from 'moment';
import { applySpec, isNil, lensIndex, pipe, prop, view } from 'ramda';
import React, { FC, useMemo } from 'react';
import { createModal } from 'react-modal-promise';

import {
  InjectedModalProps,
  Notification,
  NotificationCreateResponse,
  NotificationUpdateResponse,
} from '~common';
import { useNotificationsStore, useUserStore } from '~hooks';

import styles from './styles.module.scss';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

interface Props extends InjectedModalProps {
  notification?: Notification;
}

const formatData = applySpec({
  message: prop('message'),
  from: pipe(prop('range'), view(lensIndex(0))),
  to: pipe(prop('range'), view(lensIndex(1))),
  visibility: prop('visibility'),
});

const SettingsNotificationModal: FC<Props> = observer(props => {
  const { isOpen, close, notification } = props;
  const { create, update, remove } = useNotificationsStore();
  const { checkPermissions } = useUserStore();

  const isNewNotify = useMemo(() => isNil(notification), [notification]);

  const initialValues = useMemo(() => {
    if (notification) {
      const { from, to } = notification;

      return {
        ...notification,
        range: [from ? moment(from) : null, to ? moment(to) : null],
      };
    }

    return {
      range: [moment(), null],
    };
  }, [notification]);

  const onFinish = async (values: any) => {
    const data = formatData(values);

    if (isNewNotify) {
      await create(data as NotificationCreateResponse);
    } else if (notification) {
      await update(notification._id, data as NotificationUpdateResponse);
    }

    close && close(true);
  };

  const onCancel = () => {
    close && close(false);
  };

  const onDelete = async () => {
    if (notification) {
      await remove(notification._id);

      close && close(true);
    }
  };

  const title = useMemo(
    () => (isNewNotify ? 'Создание уведомления' : 'Редактирование уведомление'),
    [isNewNotify],
  );

  const footer = useMemo(() => {
    if (isNewNotify) {
      return [
        <Button
          type="primary"
          htmlType="submit"
          form="notifications"
          disabled={!checkPermissions('notifications.update')}
        >
          Опубликовать
        </Button>,
      ];
    }

    return [
      <Button
        type="primary"
        htmlType="submit"
        form="notifications"
        disabled={!checkPermissions('notifications.update')}
      >
        Сохранить
      </Button>,
      <Button onClick={onDelete} disabled={!checkPermissions('notifications.update')}>
        Удалить
      </Button>,
    ];
  }, [checkPermissions, isNewNotify, onDelete]);

  return (
    <Modal
      title={
        <div className={styles.notify}>
          <ExclamationCircleFilled /> {title}
        </div>
      }
      visible={isOpen}
      footer={footer}
      onCancel={onCancel}
    >
      <Form name="notifications" initialValues={initialValues} onFinish={onFinish}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={24} className={styles.description}>
                Введите текст уведомления
              </Col>
              <Col span={24}>
                <Form.Item
                  name="message"
                  style={{ margin: 0 }}
                  rules={[{ required: true, message: 'Введите тест уведомления!' }]}
                >
                  <TextArea rows={4} placeholder="Текст уведомления" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={24} className={styles.description}>
                Интервал показа
              </Col>
              <Col span={24}>
                <Form.Item name="range" style={{ margin: 0 }}>
                  <RangePicker
                    ranges={{
                      Сегодня: [moment().startOf('day'), moment().endOf('day')],
                      '1 час': [moment(), moment().add(1, 'hours')],
                      '2 часа': [moment(), moment().add(2, 'hours')],
                      '6 часов': [moment(), moment().add(6, 'hours')],
                    }}
                    className={styles.datePicker}
                    locale={locale}
                    showTime
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item name="visibility" valuePropName="checked" style={{ margin: 0 }}>
              <Checkbox defaultChecked>Видимость</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export const settingsNotificationModal = createModal(SettingsNotificationModal);
