import PropTypes from 'prop-types'
import React from 'react'

import { Tag } from 'antd'

import styles from './styles.scss'

const EventTypes = ({ types }) => {
  return (
    <div className={styles.root}>
      {types.map(event => (
        <Tag key={event._id} color={event.color}>
          {event.title}
        </Tag>
      ))}
    </div>
  )
}

export default EventTypes

EventTypes.propTypes = {
  types: PropTypes.object
}
