import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { Input } from 'antd'

import DateRender from '~components/Publications/Filter/DateRender'
import SelectRender from './SelectRender'
import CheckBoxRender from '~components/Publications/Filter/CheckBoxRender'
import DateRangeRender from '~components/Publications/Filter/DateRangeRender'
import ItemWithLabel from '~components/ItemWithLabel'

const style = {
  width: '100%'
}

@inject(
  'FilterParamsByListStore',
  'FetchPaginationStore',
  'TagsStore',
  'ConfigureFetchUrlByListsStore',
  'UserStore'
)
@observer
class DynamicField extends Component {
  handleClick = () => {
    const { item, onRemove, FilterParamsByListStore } = this.props
    const { setFilterValue } = FilterParamsByListStore

    onRemove(item)
    setFilterValue(item.name, null)
  }

  handleChange = (value, valueString) => {
    const { item, FilterParamsByListStore } = this.props
    const { setFilterValue } = FilterParamsByListStore

    switch (item.type) {
      case 'date': {
        const dt = valueString || null
        setFilterValue(item.name, dt)

        break
      }
      case 'dateRange': {
        let dateRange = valueString

        if (dateRange[0] && dateRange[1]) {
          const startDate = valueString[0]
          const endDate = valueString[1]

          dateRange = `${startDate},${endDate}`
        } else {
          dateRange = undefined
        }

        setFilterValue(item.name, dateRange)

        break
      }
      case 'select': {
        setFilterValue(item.name, value)

        break
      }
      case 'bool': {
        setFilterValue(item.name, value.target.checked)

        break
      }
      default:
        setFilterValue(item.name, value.target.value)
    }
  }

  render() {
    const { item, FilterParamsByListStore, bool, onRemove, UserStore } = this.props

    const { filterParams } = FilterParamsByListStore
    const { user } = UserStore

    const selectRender = filterParams[item.name] !== user._id ? filterParams[item.name] : undefined

    if (bool)
      return (
        <CheckBoxRender
          onChange={this.handleChange}
          label={item.label}
          checked={!!filterParams[item.name]}
          onRemove={this.handleClick}
        />
      )

    return (
      <ItemWithLabel onRemove={onRemove && this.handleClick} label={item.label}>
        {item.type === 'string' && (
          <Input value={filterParams[item.name]} style={style} onChange={this.handleChange} />
        )}
        {item.type === 'date' && (
          <DateRender value={filterParams[item.name]} onChange={this.handleChange} />
        )}
        {item.type === 'dateRange' && (
          <DateRangeRender value={filterParams[item.name]} onChange={this.handleChange} />
        )}
        {item.type === 'select' && (
          <SelectRender item={item} value={selectRender} onChange={this.handleChange} />
        )}
      </ItemWithLabel>
    )
  }
}

export default DynamicField

DynamicField.propTypes = {
  FilterParamsByListStore: PropTypes.any,
  UserStore: PropTypes.any,
  bool: PropTypes.bool,
  item: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
}
