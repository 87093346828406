import { Col, Row } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { Hidden } from '~components';
import { EMPTY_OBJECT } from '~constants';
import { usePublicationStore } from '~hooks';

import {
  AdvTypeSelect,
  AliasInput,
  AuthorsSelect,
  CategoriesSelectGroup,
  CoauthorsSelect,
  FlagsCheckboxGroup,
  IsBlocked,
  LinksSelect,
  PrioritySelect,
  RegionsSelect,
  SectionSelect,
  TagsSelect,
  TypeSelect,
  // YandexTagsSelect,
} from '../../containers';
import styles from './styles.scss';

export const Settings = observer(() => {
  const { settingsBlocked, resourceType, isQuiz } = usePublicationStore();
  const isStream = resourceType === 'RESOURCE_TYPE_TEXT_STREAM';
  const { blocked: isBlocked } = settingsBlocked || EMPTY_OBJECT;
  const settingsColLayout = { xs: 24, xl: isQuiz ? 24 : 14 };
  const flagsColLayout = { xs: 24, xl: 10 };
  const fieldColLayout = { xs: 24, md: isQuiz ? 8 : 12 };

  return (
    <div className={cn(styles.blockSettings, isBlocked && styles.blocked)}>
      <IsBlocked />
      <Row gutter={[16, 16]}>
        <Col {...settingsColLayout}>
          <Row gutter={[16, 16]}>
            <Hidden isHidden={isStream}>
              <Col {...fieldColLayout}>
                <TypeSelect />
              </Col>
            </Hidden>
            <Col {...fieldColLayout}>
              <SectionSelect />
            </Col>
            <CategoriesSelectGroup />
            <Col {...fieldColLayout}>
              <PrioritySelect />
            </Col>
            <Col {...fieldColLayout}>
              <TagsSelect />
            </Col>
            {/* {resourceType === 'RESOURCE_TYPE_PUBLICATION' && ( */}
            {/*  <Col {...fieldColLayout}> */}
            {/*    <YandexTagsSelect /> */}
            {/*  </Col> */}
            {/* )} */}
            <Hidden isHidden={isQuiz}>
              <Col {...fieldColLayout}>
                <RegionsSelect />
              </Col>
              <Col {...fieldColLayout}>
                <AuthorsSelect />
              </Col>
              {/* <Hidden isHidden={isQuiz}> */}
              {/*  <Col {...fieldColLayout}> */}
              {/*    <LinksSelect /> */}
              {/*  </Col> */}
              {/* </Hidden> */}
            </Hidden>
            <Hidden isHidden>
              <Col {...fieldColLayout}>
                <CoauthorsSelect />
              </Col>
            </Hidden>
            <Hidden isHidden>
              <Col {...fieldColLayout}>
                <AdvTypeSelect />
              </Col>
            </Hidden>
            <Hidden isHidden>
              <Col {...fieldColLayout}>
                <AliasInput />
              </Col>
            </Hidden>
          </Row>
        </Col>
        <Hidden isHidden={isQuiz}>
          <Col {...flagsColLayout}>
            <FlagsCheckboxGroup />
          </Col>
        </Hidden>
      </Row>
    </div>
  );
});
