// TODO: json-mobx for editor undo/redo?
// import { observable } from 'mobx'
import AuthorsStore from './AuthorsStore';
import BadWordsStore from './BadWordsStore';
import CategoriesStore from './CategoriesStore';
import ChangeLogStore from './ChangeLogStore';
import CommentsStore from './CommentsStore';
import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists';
import DepartmentsStore from './DepartmentsStore';
import DraftStore from './DraftStore';
import EmailsStore from './EmailsStore';
import EmployeesStore from './EmployeesStore';
import ExperimentsStore from './ExperimentsStore';
import FeedbackStore from './FeedbackStore';
import FetchPaginationStore from './fetchPaginationStore';
import FilterParamsByListStore from './filterParamsByList';
import FlagsStore from './FlagsStore';
import FlowsStore from './FlowsStore';
import HeaderPreviewsStore from './HeaderPreviewsStore';
import HeadingsStore from './HeadingsStore';
import InfoPagesStore from './InfoPagesStore';
import MovePublicationsStore from './MovePublicationsStore';
import NotificationsStore from './NotificationsStore';
import OverlayStore from './OverlayStore';
import PlaylistsStore from './PlaylistsStore';
import PublicationCommentsStore from './PublicationCommentsStore';
import PublicationsStore from './PublicationsStore';
import PublicationStore from './PublicationStore';
import PushStore from './PushStore';
import RedirectsStore from './RedirectsStore';
import RegionsStore from './RegionsStore';
import RolesStore from './RolesStore';
import RssStore from './RssStore';
import ScheduleStore from './ScheduleStore';
import SectionsStore from './SectionsStore';
import SettingsGlobalStore from './SettingsGlobalStore';
import SocialsStore from './SocialsStore';
import SortingStore from './SortingStore';
import SpecProjectsStore from './SpecProjectsStore';
import StatusStore from './StatusStore';
import SubcategoriesStore from './SubcategoriesStore';
import SymbolsStore from './SymbolsStore';
import TagsStore from './TagsStore';
import TimersStore from './TimersStore';
import UserStore from './UserStore';
import VideosStore from './VideosStore';
import VideoStore from './VideoStore';

const stores = {
  UserStore,
  PublicationsStore,
  TagsStore,
  FlowsStore,
  FeedbackStore,
  AuthorsStore,
  DepartmentsStore,
  ChangeLogStore,
  EmployeesStore,
  FetchPaginationStore,
  FilterParamsByListStore,
  SocialsStore,
  EmailsStore,
  CategoriesStore,
  SectionsStore,
  SortingStore,
  ScheduleStore,
  TimersStore,
  ConfigureFetchUrlByListsStore,
  SymbolsStore,
  InfoPagesStore,
  PlaylistsStore,
  PublicationStore,
  VideoStore,
  VideosStore,
  SpecProjectsStore,
  SettingsGlobalStore,
  RssStore,
  PublicationCommentsStore,
  OverlayStore,
  BadWordsStore,
  CommentsStore,
  FlagsStore,
  PushStore,
  MovePublicationsStore,
  StatusStore,
  DraftStore,
  RegionsStore,
  SubcategoriesStore,
  HeadingsStore,
  RedirectsStore,
  ExperimentsStore,
  NotificationsStore,
  RolesStore,
  HeaderPreviewsStore,
};

export type InjectStores<K extends keyof typeof stores> = Partial<Pick<typeof stores, K>>;

export default stores;
