/* eslint-disable */
import React, { Component } from 'react'

class CategoryMenuItem extends Component {
  handleClick = () => {
    const { id, title, onMenuClick } = this.props

    onMenuClick({ id, title })
  }

  render() {
    const { title } = this.props

    return (
      <div className="ant-select-dropdown-menu-item" onClick={this.handleClick}>
        {title}
      </div>
    )
  }
}

export default CategoryMenuItem
