const RT_PUBLICATION = 'RESOURCE_TYPE_PUBLICATION';
const RT_TEXT_TRANSLATION = 'RESOURCE_TYPE_TEXT_STREAM';
const RT_QUIZ = 'RESOURCE_TYPE_QUIZ';

const BT_CUSTOM = 'CUSTOM_BLOCK_TYPE';

export const resourceTypes = {
  publication: RT_PUBLICATION,
  textTranslation: RT_TEXT_TRANSLATION,
  quiz: RT_QUIZ,
};

export const blockTypes = {
  custom: BT_CUSTOM,
};
