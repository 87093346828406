import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { IDepartment } from '~common';
import { WhiteBlockWithShadow } from '~components';
import { useDepartmentsStore } from '~hooks';
import { Pencil } from '~icons';

import styles from './styles.scss';

interface IProps {
  department: IDepartment;
}

const DepartmentItem: React.FC<IProps> = observer(props => {
  const { setEditableDepartment } = useDepartmentsStore();
  const { department } = props;

  const handleClickItem = useCallback(() => {
    setEditableDepartment(department);
  }, [department, setEditableDepartment]);

  const { name, index } = department;

  return (
    <WhiteBlockWithShadow>
      <div className={styles.root} onClick={handleClickItem}>
        <div className={styles.ico}>
          <Pencil fill="#D3D3D3" />
        </div>

        <div className={styles.title}>{name}</div>

        <div className={styles.id}>ID {index}</div>
      </div>
    </WhiteBlockWithShadow>
  );
});

export default DepartmentItem;
