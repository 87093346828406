import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cn from 'classnames';
import React from 'react';

import styles from './styles.scss';

const Btn: React.FC<ButtonProps> = props => (
  <Button {...props} className={cn(styles.button, props.className)} />
);

Btn.displayName = 'Button';

export default Btn;
