import React from 'react';
// import PropTypes from 'prop-types'
import { observer } from 'mobx-react';
import moment from 'moment';

import { Editor } from '~components/Editor';
import { typeConstants } from '~constants';
import CloseIcon from '~assets/icons/close.svg';
import User from '~components/User';
import { FileTransportLayer } from '~services';

import styles from './styles.scss';

const BlockGroup = observer(props => {
  const {
    symbols,
    onKeyDown,
    onEditImage,
    group: {
      author,
      lockBlock,
      unlockBlock,
      createBlock,
      updateBlock,
      removeBlock,
      changeBlockPosition,
      blocksArray,
      destroy,
      createdAt,
    },
  } = props;

  const handleFocusBlock = ({ id }) => {
    lockBlock(id);
  };

  const handleUnlockBlock = ({ id }) => {
    unlockBlock(id);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerItem}>
          <div>Дата публикации</div>
          <div className={styles.date}>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</div>
        </div>
        {author && (
          <div className={styles.headerItem}>
            <div>Автор</div>
            <div>
              <User
                person={{
                  avatar: `${process.env.API_ROOT}/files/${author?.avatar}/content?height=100&width=100`,
                  name: author?.name,
                }}
              />
            </div>
          </div>
        )}
        <div className={styles.delete} onClick={destroy}>
          <CloseIcon /> <span>Удалить</span>
        </div>
      </div>
      <Editor
        blocks={blocksArray}
        onFocusBlock={handleFocusBlock}
        handleUnblock={handleUnlockBlock}
        onEditImage={onEditImage}
        onCreateBlock={createBlock}
        onChangeBlock={updateBlock}
        onDeleteBlock={removeBlock}
        onKeyDown={onKeyDown}
        onDrag={changeBlockPosition}
        uploadPlaceholder={require('~assets/uploadPlaceholder.jpg')}
        uploadUrl={`${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`}
        maxSize={{
          [typeConstants.HEADER_BLOCK]: symbols.title,
          [typeConstants.IMAGE_BLOCK]: symbols.mediaCaption,
          [typeConstants.VIDEO_BLOCK]: symbols.mediaCaption,
        }}
      />
    </div>
  );
});

BlockGroup.propTypes = {};

export default BlockGroup;
