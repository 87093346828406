import { always, cond, equals } from 'ramda';
import React, { useCallback, useMemo } from 'react';

import { BlockType, IBlock } from '~common';
import { RichTextarea } from '~components';
import { EMPTY_STRING } from '~constants';
import { useBlockStateReducer } from '~hooks';
import { getText, getTextsCount, noop } from '~utils';

import { Textarea } from '../../Textarea';
import { BlockWrapper } from '../BlockWrapper';

export enum TitleLevel {
  SearchTitle = -4,
  YandexTitle = -3,
  Clickbait = -2,
  Subtitle = -1,
  Heading = 1,
  SecondLevelHeading = 2,
  ThirdLevelHeading = 3,
}

const getTitleByLevel = cond<number, string>([
  [equals(TitleLevel.SearchTitle), always('Поисковый заголовок')],
  [equals(TitleLevel.YandexTitle), always('Заголовок Яндекс.Новости')],
  [equals(TitleLevel.Subtitle), always('Подзаголовок')],
  [equals(TitleLevel.Heading), always('Заголовок')],
  [equals(TitleLevel.Clickbait), always('Кликбейт')],
  [equals(TitleLevel.SecondLevelHeading), always('Заголовок второго уровня')],
  [equals(TitleLevel.ThirdLevelHeading), always('Заголовок третьего уровня')],
]);

const getRichEditorByLevel = cond<number, boolean>([
  [equals(TitleLevel.SearchTitle), always(false)],
  [equals(TitleLevel.YandexTitle), always(false)],
  [equals(TitleLevel.Subtitle), always(false)],
  [equals(TitleLevel.Heading), always(false)],
  [equals(TitleLevel.Clickbait), always(false)],
  [equals(TitleLevel.SecondLevelHeading), always(true)],
  [equals(TitleLevel.ThirdLevelHeading), always(true)],
]);

const isString = (text: any): text is string => typeof text === 'string';

interface IProps extends IBlock {
  level?: TitleLevel;
  onChange?(block: IBlock & { level: TitleLevel; meta: { blockId?: string } }): void;
  onFocus?(block: IBlock & { level: TitleLevel; meta: { blockId?: string } }): void;
  placeholder?: string;
  richEditor?: boolean;
  text?: string;
}

type TState = Pick<IProps, 'text' | 'textHTML' | 'level' | 'charactersCount' | 'richEditor'>;

const HeadingBlock: React.FC<IProps> = props => {
  const {
    id = EMPTY_STRING,
    blockProps = {
      title: 'Заголовок',
      maxSize: 80,
      isLimitedByMaxSize: false,
    },
    isFetching = false,
    isEditing = false,
    isForceUpdate = false,
    richEditor,
    level = TitleLevel.SecondLevelHeading,
    text = EMPTY_STRING,
    textHTML = EMPTY_STRING,
    placeholder = 'Добавить заголовок',
    charactersCount = getTextsCount(getText(text)),
    onChange = noop,
    onFocus = noop,
  } = props;

  const isRichEditor = richEditor ?? getRichEditorByLevel(level);

  const { maxSize = 100 as number, isLimitedByMaxSize = false } = blockProps;

  const initialState: TState = {
    text,
    textHTML,
    level,
    charactersCount,
  };

  const data: TState = useMemo(
    () => ({
      text,
      textHTML,
      level,
      charactersCount,
    }),
    [text, textHTML, level, charactersCount],
  );

  const [state, dispatch] = useBlockStateReducer<TState>({
    blockId: id,
    blockType: BlockType.HeadingBlock,
    incomingData: data,
    initialState,
    isEditing,
    isForceUpdate,
    onChange,
  });

  const handleChange = ({ raw: text, plainText, html: textHTML = EMPTY_STRING }) => {
    dispatch({
      level,
      text,
      textHTML,
      charactersCount: plainText.length,
    });
  };

  const handleFocus = useCallback(
    (text: any) =>
      onFocus({
        meta: { blockId: id },
        type: BlockType.HeadingBlock,
        text,
        level,
        charactersCount: text.length,
      }),
    [id, level, onFocus],
  );

  const textareaProps = {
    placeholder,
    onChange: handleChange,
    onFocus: handleFocus,
    limit: { isLimitedByMaxSize, maxSize: maxSize as number },
    readOnly: isFetching,
    isEditing,
  };

  return (
    <BlockWrapper
      {...blockProps}
      title={getTitleByLevel(level)}
      maxSize={maxSize ? `${state.charactersCount || 0}/${maxSize}` : ''}
      id={id}
    >
      {!isRichEditor && isString(state.text) ? (
        <Textarea {...textareaProps} value={state.text} />
      ) : (
        <RichTextarea {...textareaProps} value={state.text} />
      )}
    </BlockWrapper>
  );
};

export default HeadingBlock;
