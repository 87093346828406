import { action, computed, observable, ObservableMap, runInAction } from 'mobx';

import { IExperiment, Maybe } from '~common';
import { EMPTY_STRING } from '~constants';
import { openExperimentsModal } from '~containers';
import { ExperimentsTransportLayer } from '~services/experimentsApi';
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotification, showErrorNotificationInPromise } from '~utils';

export class ExperimentsStore {
  @observable
  experiments: ObservableMap<string, IExperiment> = observable.map([]);

  @observable experimentSearches: Array<IExperiment> = [];

  @observable editableExperiment: Maybe<IExperiment> = null;

  @observable isActionModal = false;

  @observable allItemsCount = 0;

  @observable isFetching = false;

  apiLayer = new ExperimentsTransportLayer();

  @action
  setEditableExperiment = async value => {
    this.editableExperiment = { ...value };
    await openExperimentsModal();
  };

  @action
  setExperiments = (experiments: IExperiment[] = []) => {
    this.experiments.replace(
      new Map(
        experiments.map(experiments => {
          return [experiments._id, experiments];
        }),
      ),
    );
  };

  @action
  getExperiments = (defaultFilter = EMPTY_STRING) => {
    const { offset, setPageOffset } = FetchPaginationStore;
    const isNewFetch = offset === 0;

    if (isNewFetch) {
      this.setIsFetching(true);
      this.setExperiments([]);
      this.setExperimentsItemsCount(0);
    }

    return this.apiLayer
      .getExperiments(defaultFilter)
      .then(({ data: experiments = [], meta }) => {
        if (isNewFetch) {
          this.setExperiments(experiments);
        } else {
          this.setExperiments([...this.experiments.values(), ...experiments]);
        }
        this.setExperimentsItemsCount(meta.count);
        setPageOffset(offset + 1);
        return Promise.resolve(experiments);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки экспериментов', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  createExperiment = experiment => {
    const { resetPaginationParams } = FetchPaginationStore;

    this.isActionModal = true;

    return this.apiLayer
      .createExperiment(experiment)
      .then(({ data }) => {
        runInAction(() => {
          this.isActionModal = false;
          resetPaginationParams();
          this.getExperiments();
        });
        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания эксперимента',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  updateExperimentById = experiment => {
    const { resetPaginationParams } = FetchPaginationStore;

    const id = this.editableExperiment?._id || null;

    this.isActionModal = true;

    const { data } = experiment;

    return this.apiLayer
      .updateExperimentById(id, data)
      .then(({ data }) => {
        runInAction(() => {
          this.isActionModal = false;
          resetPaginationParams();
          this.getExperiments();
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка обновления эксперимента',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  setExperimentsItemsCount = (experimentsItemsCount: number) => {
    this.allItemsCount = experimentsItemsCount;
  };

  @action
  deleteExperimentById = () => {
    const { resetPaginationParams } = FetchPaginationStore;

    const id = this.editableExperiment?._id || null;

    this.isActionModal = true;
    return this.apiLayer
      .deleteExperimentById(id)
      .then(() => {
        runInAction(() => {
          this.isActionModal = false;
          this.resetEditableExperiment();
          resetPaginationParams();
          this.getExperiments();
          return Promise.resolve(true);
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка удаления эксперимента',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };

  @action
  resetEditableExperiment = () => {
    this.editableExperiment = null;
  };

  @computed
  get experimentsItems(): IExperiment[] {
    return [...this.experiments.values()];
  }

  @computed
  get isEmptyExperiments(): boolean {
    return this.experimentsFetchedCount === 0;
  }

  @computed
  get experimentsFetchedCount() {
    return this.experiments.size;
  }

  @computed
  get hasMoreExperimentItemsToFetch(): boolean {
    return this.experimentsFetchedCount !== this.allItemsCount;
  }
}

export default new ExperimentsStore();
