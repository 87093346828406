import { EditorState } from 'draft-js';
import React from 'react';

interface IProps {
  className?: string;
  entityKey?: string;
  getEditorState(): EditorState;
  target?: string;
}

const Link: React.FC<IProps> = ({ children, className, entityKey, getEditorState, target }) => {
  const entity = getEditorState()
    .getCurrentContent()
    .getEntity(entityKey || '');
  const entityData = entity ? entity.getData() : undefined;
  const href = (entityData && entityData.url) || undefined;

  return (
    <a className={className} title={href} href={href} target={target} rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default Link;
