import { find, findIndex } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { inc, propEq } from 'ramda';

import { IHeading, Maybe } from '~common';
import { openHeadingsModal } from '~containers/Heading';
import { HeadingsTransportLayer } from '~services/headingsApi';
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotification, showErrorNotificationInPromise } from '~utils';

export class HeadingsStore {
  @observable headings: Array<IHeading> = [];

  @observable headingsSearches = [];

  @observable isFirstLoading = true;

  @observable isShowModal = false;

  @observable isActionModal = false;

  @observable editableHeading: Maybe<IHeading> = null;

  @observable allItemsCount = 0;

  apiLayer = new HeadingsTransportLayer();

  @action
  fetchHeadings = (filter = 'sort=+order') => {
    const { offset, setPageOffset } = FetchPaginationStore;

    const isNewFetch = offset === 0;
    if (isNewFetch) {
      this.headings = [];
      this.setAllItemsCount(0);
    }
    return this.apiLayer
      .getHeadings(filter)
      .then(({ data: headings = [], meta }) => {
        if (offset === 0) {
          this.headings = headings;
        } else {
          this.headings = [...this.headings, ...headings];
        }

        setPageOffset(inc(offset));

        this.isFirstLoading = false;
        this.setAllItemsCount(meta.count);
        return Promise.resolve(headings);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки рубрик', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.isFirstLoading = false;
      });
  };

  @action
  fetchHeadingsBySearch = (term?) => {
    return this.apiLayer.getHeadingsBySearch(term).then(res => {
      const { data } = res;

      runInAction(() => {
        this.headingsSearches = data;
      });

      return data;
    });
  };

  @action
  createHeading = heading => {
    const { resetPaginationParams } = FetchPaginationStore;

    this.isActionModal = true;

    return this.apiLayer
      .createHeading(heading)
      .then(({ data }) => {
        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.headings.unshift(data);
          resetPaginationParams();
          this.fetchHeadings();
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания рубрики',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  updateHeadingById = subcategory => {
    const id = this.editableHeading?._id || null;

    this.isActionModal = true;

    const { data } = subcategory;

    return this.apiLayer
      .updateHeadingById(id, data)
      .then(({ data }) => {
        const editableHeadingIndex = this.headings.findIndex(propEq('_id', id));

        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.headings[editableHeadingIndex] = data;
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка обновления рубрики',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  deleteHeadingById = () => {
    const id = this.editableHeading?._id || null;

    this.isActionModal = true;
    return this.apiLayer
      .deleteHeadingById(id)
      .then(() => {
        const editableHeadingIndex = this.headings.findIndex(propEq('_id', id));

        runInAction(() => {
          this.isActionModal = false;
          this.headings.splice(editableHeadingIndex, 1);
          this.resetEditableHeading();
          this.setHideModal();
          return Promise.resolve(true);
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка удаления подкатегории',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    const { resetPaginationParams } = FetchPaginationStore;

    if (oldIndex === newIndex) return;

    const current = find(this.headings, { order: oldIndex });
    const target = find(this.headings, { order: newIndex });

    // @ts-ignore
    const currentId = find(this.headings, { order: oldIndex })._id;
    const targetId = findIndex(this.headings, ({ order }) => order === newIndex);

    let toEnd = false;

    let targetPlacement;

    if (targetId + 1 === this.headings.length) {
      targetPlacement = this.headings[this.headings.length - 1]._id;
      toEnd = true;
    } else {
      targetPlacement = this.headings[targetId + 1]._id;
    }

    if (oldIndex > newIndex) {
      // @ts-ignore
      targetPlacement = find(this.headings, { order: newIndex })._id;
    }

    const changeOrderingPromise = this.apiLayer.changeOrdering(currentId, targetPlacement, toEnd);

    // @ts-ignore
    current.order = newIndex;
    // @ts-ignore
    target.order = newIndex + 0.5;

    this.headings = this.headings.slice().sort(({ order: a }, { order: b }) => a - b);

    changeOrderingPromise.then(() => {
      resetPaginationParams();
      this.fetchHeadings();
    });
  };

  @action
  setEditableHeading = async value => {
    this.editableHeading = { ...value };

    await openHeadingsModal();
  };

  @action
  resetEditableHeading = () => {
    this.editableHeading = null;
  };

  @action
  setHideModal = () => {
    this.isShowModal = false;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @computed
  get headingsLength() {
    return this.headings.length;
  }
}

export default new HeadingsStore();
