import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import ContentWrapper from '~components/ContentWrapper';
import HeaderPageSearchBar from '~components/HeaderPageSearchBar';
import InfinityScroll from '~components/InfinityScroll';
import Sorting from '~components/Sorting';
import {
  TypeControls,
  EventsModal,
  CalendarView,
  ListView,
  EditModal,
  EventsFilter,
  InfoModal,
} from '~components/Schedule';
import FilterToggleControl from '~components/FilterToggleControl';

const PAGE_TITLE = 'Расписание съемок';
const COUNT_DECLINATIONS = ['съемка', 'съемки', 'съемок'];
const SEARCH_BTN_TEXT = 'Найти в съемках';
const CREATE_BTN_TEXT = 'Добавить съемку';
const SORTING = [
  {
    label: 'По дате создания',
    type: 'createdAt',
  },
  {
    label: 'По названию',
    type: 'title',
  },
  {
    label: 'По дате начала',
    type: 'start',
    value: '-',
  },
];

@inject('ScheduleStore', 'FetchPaginationStore', 'FilterParamsByListStore', 'UserStore')
@observer
class SchedulePage extends Component {
  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props;

    FilterParamsByListStore.setFilterParams();
    FetchPaginationStore.resetPaginationParams();
  }

  handleSearchPublications = () => {
    const {
      fetchEventsByList,
      setIsFirstLoading,
      isGrid,
      fetchEventsByCalendar,
    } = this.props.ScheduleStore;
    const { resetPaginationParams } = this.props.FetchPaginationStore;

    setIsFirstLoading(true);
    resetPaginationParams();

    if (isGrid) {
      fetchEventsByCalendar();
    } else {
      fetchEventsByList();
    }
  };

  render() {
    const { ScheduleStore, UserStore } = this.props;

    const {
      onToggleView,
      isGrid,
      fetchEventsByList,
      allItemsCount,
      isFirstLoading,
      isLoadingMore,
      eventsLength,
      setShowModalEdit,
      isShowFilter,
      toggleIsShowFilter,
      calendarType,
      resetEditableEvent,
    } = ScheduleStore;
    const { checkPermissions } = UserStore;

    const onCreateElementClick = () => {
      resetEditableEvent();
      setShowModalEdit();
    };

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={checkPermissions('events.create') && onCreateElementClick}
          onSearch={this.handleSearchPublications}
          onToggleView={onToggleView}
          isGrid={isGrid}
          gridIconType="calendar"
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
          rightContent={
            calendarType !== 'year' && (
              <FilterToggleControl isActive={isShowFilter} onClick={toggleIsShowFilter} />
            )
          }
        >
          {isShowFilter && calendarType !== 'year' && <EventsFilter />}

          {isGrid ? (
            <Fragment>
              <TypeControls /> <CalendarView />
            </Fragment>
          ) : (
            <Fragment>
              <Sorting onSort={this.handleSearchPublications} data={SORTING} />
              <InfinityScroll
                loadItems={fetchEventsByList}
                isEndLoading={allItemsCount <= eventsLength}
                isFirstLoading={isFirstLoading}
                isLoadMore={isLoadingMore}
              >
                <ListView />
              </InfinityScroll>
            </Fragment>
          )}
        </ContentWrapper>

        <EventsModal />
        <EditModal />
        <InfoModal />
      </Fragment>
    );
  }
}

export default SchedulePage;

SchedulePage.propTypes = {
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  ScheduleStore: PropTypes.any,
};
