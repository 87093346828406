import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import moment from 'moment';
import { CopyOutlined } from '@ant-design/icons';
import { Avatar, Tag, Button } from 'antd';

import EventTypes from '../../EventTypes';
import WhiteBlockWithShadow from '~components/WhiteBlockWithShadow';

import getShortText from '~utils/getShortText';
import Pencil from '~assets/icons/pencil.svg';

import styles from './styles.scss';

import PinIcon from '~assets/icons/pin.svg';

const SHORT_DESCRIPTION_LENGTH = 300;

const Person = ({ data }) => {
  const { avatar, firstName, lastName, email, phone } = data;

  return (
    <div className={styles.person}>
      <div className={styles.avatarWrapper}>
        <Avatar size={33} src={avatar}>
          {firstName && firstName[0]}
        </Avatar>
      </div>

      <div className={styles.personInfo}>
        <div className={styles.personTop}>
          <div className={styles.personName}>
            {firstName && firstName} {lastName && lastName}
          </div>
          <div className={styles.personPhone}>{phone}</div>
        </div>
        <div className={styles.personBottom}>
          <div className={styles.personRole}>Главный редактор</div>
          <div className={styles.personEmail}>{email}</div>
        </div>
      </div>
    </div>
  );
};

@inject(stores => {
  const {
    setEditableEvent,
    setShowModalEdit,
    setShowModalInfo,
    setDuplicateEvent,
  } = stores.ScheduleStore;

  return {
    setEditableEvent,
    setShowModalEdit,
    setShowModalInfo,
    setDuplicateEvent,
  };
})
@observer
class EventItem extends Component {
  handleClickItem = () => {
    const { event, setEditableEvent, setShowModalEdit } = this.props;

    setEditableEvent(event);
    setShowModalEdit();
  };

  handleShowModalInfo = () => {
    const { event, setEditableEvent, setShowModalInfo } = this.props;

    setEditableEvent(event);
    setShowModalInfo();
  };

  handleClickDuplicate = () => {
    const { event, setDuplicateEvent } = this.props;

    setDuplicateEvent(event);
  };

  getStartAndEndDate = () => {
    const { event } = this.props;
    const { start, end } = event;

    const startDate = moment(start).format('DD.MM.YYYY');
    const endDate = moment(end).format('DD.MM.YYYY');

    let startTime = '';
    let endTime = '';

    if (startDate === endDate) {
      startTime = moment(start).format('DD.MM.YYYY в HH:mm');
      endTime = moment(end).format('HH:mm');

      return `${startTime} - ${endTime}`;
    }

    startTime = moment(start).format('DD.MM.YYYY в HH:mm');
    endTime = moment(end).format('DD.MM.YYYY в HH:mm');

    return `${startTime} - ${endTime}`;
  };

  render() {
    const { event } = this.props;

    const {
      title,
      address,
      description,
      createdBy,
      updatedBy,
      createdAt,
      updatedAt,
      types,
    } = event;

    const createDate = moment(createdAt).format('DD.MM.YYYY в HH:mm');
    const updateDate = moment(updatedAt).format('DD.MM.YYYY в HH:mm');

    const shortDescription = getShortText(description, SHORT_DESCRIPTION_LENGTH);

    return (
      <WhiteBlockWithShadow>
        <div className={styles.root}>
          <div className={styles.controls}>
            <div className={styles.ico} onClick={this.handleClickDuplicate}>
              <CopyOutlined style={{ color: '#D3D3D3', fontSize: 17 }} />
            </div>

            <div className={styles.ico} onClick={this.handleClickItem}>
              <Pencil fill="#D3D3D3" />
            </div>
          </div>

          <div className={styles.blockWrapper}>
            <div className={styles.date}>
              {this.getStartAndEndDate()}

              <EventTypes types={event.types} />
            </div>

            <div className={styles.title}>{title}</div>

            <div className={styles.description}>{shortDescription}</div>

            {address && (
              <div className={styles.serviceInfo}>
                <div className={styles.location}>
                  <PinIcon fill="#9A9A9A" />

                  <span className={styles.address}>{address}</span>
                </div>
              </div>
            )}

            {!!types.length && (
              <div className={styles.eventsType}>
                {types.map(event => (
                  <Tag key={event._id} color={event.color}>
                    {event.title}
                  </Tag>
                ))}
              </div>
            )}
          </div>

          <div className={styles.divider}>
            <Button type="link" onClick={this.handleShowModalInfo}>
              Посмотреть полностью
            </Button>
          </div>

          <div className={styles.blockWrapper}>
            <div className={styles.bottomWrapper}>
              {createdBy && (
                <div className={styles.personWrapper}>
                  <div className={styles.personTitle}>Создал {createDate}</div>

                  <Person data={createdBy} />
                </div>
              )}

              {updatedBy && (
                <Fragment>
                  <div className={styles.separate} />

                  <div className={styles.personWrapper}>
                    <div className={styles.personTitle}>Обновил {updateDate}</div>

                    <Person data={updatedBy} />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </WhiteBlockWithShadow>
    );
  }
}

export default EventItem;

EventItem.propTypes = {
  event: PropTypes.object,
  setDuplicateEvent: PropTypes.any,
  setEditableEvent: PropTypes.func,
  setShowModalEdit: PropTypes.func,
  setShowModalInfo: PropTypes.func,
};

Person.propTypes = {
  data: PropTypes.object,
};
