import { find, findIndex } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { inc, propEq } from 'ramda';

import { ISubCategory, Maybe } from '~common';
import { openSubcategoriesModal } from '~containers/Subcategory';
import { SubcategoriesTransportLayer } from '~services/subcategoriesApi';
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotification, showErrorNotificationInPromise } from '~utils';

export class SubcategoriesStore {
  @observable subcategories: Array<ISubCategory> = [];

  @observable subcategoriesSearches: Array<ISubCategory> = [];

  @observable subcategoriesByCategories = <any>[];

  @observable isFirstLoading = true;

  @observable isShowModal = false;

  @observable isActionModal = false;

  @observable editableSubcategory: Maybe<ISubCategory> = null;

  @observable allItemsCount = 0;

  apiLayer = new SubcategoriesTransportLayer();

  @action
  fetchSubcategories = (filter = 'sort=+order') => {
    const { offset, setPageOffset } = FetchPaginationStore;

    const isNewFetch = offset === 0;
    if (isNewFetch) {
      this.subcategories = [];
      this.setAllItemsCount(0);
    }
    return this.apiLayer
      .getSubcategories(filter)
      .then(({ data: subcategories = [], meta }) => {
        if (offset === 0) {
          this.subcategories = subcategories;
        } else {
          this.subcategories = [...this.subcategories, ...subcategories];
        }

        setPageOffset(inc(offset));

        this.isFirstLoading = false;
        this.setAllItemsCount(meta.count);
        return Promise.resolve(subcategories);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки подкатегорий', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.isFirstLoading = false;
      });
  };

  @action
  fetchSubcategoriesManual = (filter = 'sort=+order') => {
    return this.apiLayer
      .getSubcategoriesManualFilter(filter)
      .then(({ data: subcategories = [] }) => {
        this.subcategories = subcategories;
        return Promise.resolve(subcategories);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки подкатегорий', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.isFirstLoading = false;
      });
  };

  @action
  fetchSubcategoriesBySearch = (term?) => {
    return this.apiLayer.getSubcategoriesBySearch(term).then(res => {
      const { data } = res;

      runInAction(() => {
        this.subcategoriesSearches = data;
      });

      return data;
    });
  };

  @action
  fetchSubcategoriesBySearchAndCategoryIds = async (categories?, term?) => {
    const { setPageOffset } = FetchPaginationStore;

    this.subcategoriesByCategories = [];

    for (let i = 0; i <= categories.length - 1; i += 1) {
      let url = `filter.category=${categories[i].key}`;

      if (term) {
        url = `${url}&search=${term}`;
      }

      setPageOffset(0);

      // eslint-disable-next-line no-await-in-loop
      const fetchedSubcategories = await this.fetchSubcategoriesManual(url);
      fetchedSubcategories.forEach(fetchedSubcategory => {
        const existedSubcategory = this.subcategoriesByCategories.find(
          subcategory => subcategory._id === fetchedSubcategory._id,
        );
        if (!existedSubcategory) {
          this.subcategoriesByCategories.push(fetchedSubcategory);
        }
      });
    }
  };

  @action
  fetchSubcategoriesByCategoryIds = async (categories?) => {
    this.subcategoriesByCategories = [];

    for (let i = 0; i <= categories.length - 1; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const fetchedSubcategories = await this.fetchSubcategoriesManual(
        `filter.category=${categories[i].key}`,
      );
      this.subcategoriesByCategories.push(...fetchedSubcategories);
    }
  };

  @action
  createSubcategory = subcategory => {
    const { resetPaginationParams } = FetchPaginationStore;

    this.isActionModal = true;

    return this.apiLayer
      .createSubcategory(subcategory)
      .then(({ data }) => {
        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.subcategories.unshift(data);
          this.setAllItemsCount(this.allItemsCount + 1);
          resetPaginationParams();
          this.fetchSubcategories();
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания подкатегории',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  updateSubcategoryById = subcategory => {
    const id = this.editableSubcategory?._id || null;

    this.isActionModal = true;

    const { data } = subcategory;

    return this.apiLayer
      .updateSubcategoryById(id, data)
      .then(({ data }) => {
        const editableCategoryIndex = this.subcategories.findIndex(propEq('_id', id));

        this.setHideModal();

        runInAction(() => {
          this.isActionModal = false;
          this.subcategories[editableCategoryIndex] = data;
        });

        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка обновления подкатегории',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  deleteSubcategoryById = () => {
    const id = this.editableSubcategory?._id || null;

    this.isActionModal = true;
    return this.apiLayer
      .deleteSubcategoryById(id)
      .then(() => {
        const editableCategoryIndex = this.subcategories.findIndex(propEq('_id', id));

        runInAction(() => {
          this.isActionModal = false;
          this.subcategories.splice(editableCategoryIndex, 1);
          this.resetEditableSubcategory();
          this.setHideModal();
          this.setAllItemsCount(this.allItemsCount - 1);
          return Promise.resolve(true);
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка удаления подкатегории',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  changeOrdering = ({ oldIndex, newIndex }) => {
    const { setPageOffset, resetPaginationParams } = FetchPaginationStore;

    if (oldIndex === newIndex) return;

    const current = find(this.subcategories, { order: oldIndex });
    const target = find(this.subcategories, { order: newIndex });

    // @ts-ignore
    const currentId = find(this.subcategories, { order: oldIndex })._id;
    const targetId = findIndex(this.subcategories, ({ order }) => order === newIndex);

    let toEnd = false;

    let targetPlacement;

    if (targetId + 1 === this.subcategories.length) {
      targetPlacement = this.subcategories[this.subcategories.length - 1]._id;
      toEnd = true;
    } else {
      targetPlacement = this.subcategories[targetId + 1]._id;
    }

    if (oldIndex > newIndex) {
      // @ts-ignore
      targetPlacement = find(this.subcategories, { order: newIndex })._id;
    }

    const changeOrderingPromise = this.apiLayer.changeOrdering(currentId, targetPlacement, toEnd);

    // @ts-ignore
    current.order = newIndex;
    // @ts-ignore
    target.order = newIndex + 0.5;

    this.subcategories = this.subcategories.slice().sort(({ order: a }, { order: b }) => a - b);

    changeOrderingPromise.then(() => {
      setPageOffset(0);
      resetPaginationParams();
      this.fetchSubcategories();
    });
  };

  @action
  setEditableSubcategory = async value => {
    this.editableSubcategory = { seo: { title: '', description: '' }, ...value };

    await openSubcategoriesModal();
  };

  @action
  resetEditableSubcategory = () => {
    this.editableSubcategory = null;
  };

  @action
  setShowModal = () => {
    this.isShowModal = true;
  };

  @action
  setHideModal = () => {
    this.isShowModal = false;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @computed
  get subcategoriesLength() {
    return this.subcategories.length;
  }
}

export default new SubcategoriesStore();
