import { AxiosResponse } from 'axios';
import { action, computed, IObservableArray, observable } from 'mobx';
import queryString from 'query-string';
import { inc } from 'ramda';

import { FilterItem, ListResponse, WithId } from '~common';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';
import { showErrorNotificationInPromise } from '~utils';

import FetchPaginationStore from './fetchPaginationStore';

export abstract class AbstractStore<Model extends WithId, CreateData = Model, UpdateData = Model> {
  @observable currentTabFilter = '';

  @observable isFirstLoading = true;

  @observable isFetching = false;

  @observable allItemsCount = 0;

  @observable isLoadingMore = false;

  @observable items: Array<Model> = [];

  @observable searches: Array<Model> = [];

  // @ts-ignore
  @observable selectedFilterItems: IObservableArray<FilterItem> = [];

  protected constructor() {
    const startUrl = new URLSearchParams(window.location.search);

    startUrl.forEach((value, key) => {
      const filterFromUrl = this.filterItems.find(item => item.name === key && !item.fix);

      if (filterFromUrl) {
        this.setFilterItemActive(filterFromUrl);
      }
    });
  }

  @action
  setFilterItemActive = (item: FilterItem) => {
    this.selectedFilterItems?.push(item);
  };

  @action
  removeFilterItem = (item: FilterItem) => {
    this.selectedFilterItems?.remove(item);
  };

  @action
  resetFilterItems = () => {
    // @ts-ignore
    this.selectedFilterItems = [];
  };

  @action
  setItems = (items: Model[]) => {
    this.items = items;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value;
  };

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value;
  };

  @action
  setSearches = value => {
    this.searches = value;
  };

  @computed
  get countItems() {
    return this.items.length;
  }

  @action
  create = async (data: CreateData): Promise<Model> => {
    try {
      const result = await this.createHandler(data);

      return result.data;
    } catch (e) {
      showErrorNotificationInPromise({
        title: 'Ошибка создания',
      });

      throw e;
    }
  };

  @action
  update = async (id: string, data: UpdateData): Promise<Model> => {
    try {
      const result = await this.updateHandler(id, data);

      return result.data;
    } catch (e) {
      showErrorNotificationInPromise({
        title: 'Ошибка обновления',
      });

      throw e;
    }
  };

  @action
  getList = async (defaultFilter: Record<string, any> | string = EMPTY_STRING) => {
    try {
      const { offset, setPageOffset } = FetchPaginationStore;
      const { getPromiseUrl } = ConfigureFetchUrlByListsStore;

      const params = getPromiseUrl({
        defaultFilter,
      });

      const result = await this.getListHandler(params);

      const { data, meta } = result.data;

      if (offset === 0) {
        this.setItems(data);
      } else {
        this.setItems([...this.items, ...data]);
      }

      setPageOffset(inc(offset));
      this.setAllItemsCount(meta.count);
      this.setIsFirstLoading(false);
      this.setIsLoadingMore(false);
    } catch (e) {
      showErrorNotificationInPromise({
        title: 'Ошибка получения списка',
      });
      this.setIsFirstLoading(false);
      this.setIsLoadingMore(false);
    }
  };

  @action
  search = async (search?: string) => {
    try {
      let params = {};

      if (search) {
        params = { search };
      }

      const { data } = await this.getListHandler(
        `?${queryString.stringify(params, { skipNull: true, skipEmptyString: true })}`,
      );

      this.setSearches(data.data);
    } catch (e) {
      showErrorNotificationInPromise({
        title: 'Ошибка получения списка',
      });
    }
  };

  @action
  resetList = async (defaultFilter: Record<string, any> | string = EMPTY_STRING) => {
    const { resetPaginationParams } = FetchPaginationStore;
    resetPaginationParams();

    await this.getList(defaultFilter);
  };

  @action
  remove = async (id: string) => {
    try {
      await this.deleteHandler(id);
    } catch (e) {
      showErrorNotificationInPromise({
        title: 'Ошибка удаления',
      });

      throw e;
    }
  };

  @action
  getItem = async (id: string) => {
    try {
      const response = await this.getHandler(id);

      return response.data;
    } catch (e) {
      showErrorNotificationInPromise({
        title: 'Ошибка получения сущности',
      });

      throw e;
    }
  };

  protected abstract createHandler(data: CreateData): Promise<AxiosResponse<Model>>;

  protected abstract updateHandler(id: string, data: UpdateData): Promise<AxiosResponse<Model>>;

  protected abstract deleteHandler(id: string): Promise<AxiosResponse>;

  protected abstract getHandler(id: string): Promise<AxiosResponse<Model>>;

  protected abstract getListHandler(filter?: string): Promise<AxiosResponse<ListResponse<Model>>>;

  abstract get filterItems(): FilterItem[];
}
