import { message, Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React from 'react';

import { IDictionary } from '~common';
import { ItemWithLabel } from '~components';
import { resourceTypes } from '~constants';
import { usePublicationStore, useSettingsStore, useUserStore } from '~hooks';
import { getAuthorityByResourceType } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const TypeSelect = observer(() => {
  const { id, type, dictionariesList, set } = usePublicationStore();
  const { checkPermissions } = useUserStore();

  const { promoId } = useSettingsStore();

  const postTypes: IDictionary[] = (
    (dictionariesList?.dictionaries?.postTypes as IDictionary[]) ?? []
  ).filter(({ id, isActive }) => id === type || isActive);

  const options: LabeledValue[] = postTypes.map(postType => ({
    value: postType.id,
    label: postType.ru,
  }));

  const onChange = type => {
    if (id === promoId && type !== 'POST_TYPE_NON_BRAND' && type !== 'POST_TYPE_NON_BRAND_ADS') {
      message.error('Изменение типа у промо-статьи запрещено');
      return;
    }

    set({ name: 'type', value: type });
  };

  return (
    <ItemWithLabel label="Тип">
      <Select
        style={inputStyle}
        placeholder="Выберите тип"
        value={type}
        options={options}
        onChange={onChange}
        disabled={!checkPermissions('posts.settings')}
      />
    </ItemWithLabel>
  );
});
