import { Input } from 'antd';
import { isArray } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ItemWithLabel from '~components/ItemWithLabel';
import CheckBoxRender from './CheckBoxRender';
import DateRangeRender from './DateRangeRender';

import DateRender from './DateRender';
import SelectRender from './SelectRender';

const style = {
  width: '100%',
};

@inject(
  'FilterParamsByListStore',
  'FetchPaginationStore',
  'TagsStore',
  'RegionsStore',
  'ConfigureFetchUrlByListsStore',
  'UserStore',
  'SubcategoriesStore',
)
@observer
class DynamicField extends Component {
  handleClick = () => {
    const { item, onRemove, FilterParamsByListStore } = this.props;
    const { setFilterValue } = FilterParamsByListStore;

    onRemove(item);
    setFilterValue(item.name, null);
  };

  handleChange = (value, valueString) => {
    const { item, FilterParamsByListStore } = this.props;
    const { setFilterValue } = FilterParamsByListStore;

    switch (item.type) {
      case 'date': {
        const dt = valueString || null;
        setFilterValue(item.name, dt);

        break;
      }
      case 'dateRange': {
        let dateRange = valueString;

        if (dateRange[0] && dateRange[1]) {
          const startDate = valueString[0];
          const endDate = valueString[1];

          dateRange = `${startDate},${endDate}`;
        } else {
          dateRange = undefined;
        }

        setFilterValue(item.name, dateRange);

        break;
      }
      case 'select': {
        setFilterValue(item.name, value);

        break;
      }
      case 'bool': {
        setFilterValue(item.name, value.target.checked);

        break;
      }
      default:
        setFilterValue(item.name, value.target.value);
    }
  };

  render() {
    const { item, FilterParamsByListStore, bool, onRemove, UserStore } = this.props;

    const { filterParams } = FilterParamsByListStore;
    const { user } = UserStore;

    const filterValue = filterParams[item.name];
    const selectRender = filterValue !== user._id ? filterValue : undefined;

    if (bool)
      return (
        <CheckBoxRender
          onChange={this.handleChange}
          label={item.label}
          checked={!!filterValue}
          onRemove={this.handleClick}
        />
      );

    return (
      <ItemWithLabel onRemove={onRemove && this.handleClick} label={item.label}>
        {item.type === 'string' && (
          <Input value={filterValue} style={style} onChange={this.handleChange} />
        )}
        {item.type === 'date' && <DateRender value={filterValue} onChange={this.handleChange} />}
        {item.type === 'dateRange' && (
          <DateRangeRender
            value={isArray(filterValue) ? filterValue.join(',') : filterValue}
            onChange={this.handleChange}
          />
        )}
        {item.type === 'select' && (
          <SelectRender item={item} value={selectRender} onChange={this.handleChange} />
        )}
      </ItemWithLabel>
    );
  }
}

export default DynamicField;

DynamicField.propTypes = {
  FilterParamsByListStore: PropTypes.any,
  UserStore: PropTypes.any,
  bool: PropTypes.bool,
  item: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};
