import { Select, Spin } from 'antd';
import propTypes from 'prop-types';
import React from 'react';
import API from '~api';
import { BlockWrapper } from '~components/Blocks';

// components
import ItemWithLabel from '~components/ItemWithLabel';

// assets
import styles from './styles.scss';

// services
const { Option } = Select;

// helpers
const delayedExecute = (f, delay) => {
  let timerId = null;

  return value => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      f(value);
    }, delay);
  };
};

class StreamBlock extends React.Component {
  constructor(props) {
    super(props);
    this.delayedRequest = delayedExecute(this.fetchVideos, 300);

    this.state = {
      data: [],
      value: {},
      fetching: false,
    };
  }

  componentDidMount() {
    if (this.props.videoId) {
      this.fetchVideo(this.props.videoId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props?.videoId !== prevProps?.videoId) {
      this.fetchVideo(this.props.videoId);
    }
  }

  handleChange = value => {
    this.setState(
      {
        value,
        data: [],
        fetching: false,
      },
      () => {
        this.props.onChange({
          videoId: value.key,
        });
      },
    );
  };

  fetchVideo = async id => {
    const { data } = await API.get(`/videos/${id}`);
    const video = data.data;

    this.setState({
      video,
      value: {
        key: video._id,
        label: video.title,
      },
    });
  };

  fetchVideos = value => {
    const searchUrl = ['/videos', value ? `?search=${value}` : ''].join('');
    this.setState({ data: [], fetching: true });

    API.get(searchUrl).then(body => {
      const { data } = body.data;

      const newData = data.map(video => ({
        text: video.title,
        value: video._id,
      }));
      this.setState({ data: newData, fetching: false });
    });
  };

  render() {
    const {
      blockProps: { title },
    } = this.props;
    const { video } = this.state;
    const { fetching, data, value } = this.state;

    return (
      <BlockWrapper title={title} {...this.props} {...this.props.blockProps}>
        <div className={styles.container}>
          <div>
            <ItemWithLabel label="Видео">
              <Select
                labelInValue
                value={value}
                showSearch={true}
                placeholder="Выберите видео"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.delayedRequest}
                onChange={this.handleChange}
                style={{ width: '100%' }}
              >
                {data.map(d => (
                  <Option value={d.value}>{d.text}</Option>
                ))}
              </Select>
            </ItemWithLabel>
          </div>
          {video && <div></div>}
        </div>
      </BlockWrapper>
    );
  }
}

StreamBlock.propTypes = {
  blockProps: propTypes.object,
  videoId: propTypes.string,

  onChange: propTypes.func,
};

StreamBlock.defaultProps = {
  videoId: null,
};

export default StreamBlock;
