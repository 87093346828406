import { observable, action } from 'mobx';

export class OverlayStore {
  @observable isVisible = false;

  @observable text = 'Идет загрузка. Пожалуйста, подождите...';

  @action
  setVisible = isVisible => {
    this.isVisible = isVisible;
  };

  @action
  setText = text => {
    this.text = text;
  };
}

export default new OverlayStore();
