import { SyncOutlined } from '@ant-design/icons';
import { Col, Divider, Popover, Row } from 'antd';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { ContentWrapper, SettingsNavigation } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { MigrationForm, MigrationsFilter } from '~containers';
import MigrationsList from '~containers/MovePosts/MigrationsList/MigrationsList';
import {
  useAuthorsStore,
  useFetchPaginationStore,
  useFlowsStore,
  useMovePublicationsStore,
  useRegionsStore,
  useSubcategoriesStore,
  useTagsStore,
} from '~hooks';

import s from './styles.scss';

const PAGE_TITLE = 'Настройка миграций';

const SettingsMovePublicationsPage = observer(() => {
  const {
    fetchDictionaries,
    fetchMovedPosts,
    isFetching,
    hasMoreHistoryItemsToFetch,
  } = useMovePublicationsStore();
  const { resetPaginationParams } = useFetchPaginationStore();

  const { fetchTagsBySearch } = useTagsStore();
  const { fetchFlowsBySearch } = useFlowsStore();
  const { fetchCategoriesBySearch, fetchSectionsBySearch } = useAuthorsStore();
  const { fetchSubcategoriesBySearch } = useSubcategoriesStore();
  const { fetchRegionsBySearch } = useRegionsStore();

  useEffect(() => {
    fetchDictionaries();
    fetchTagsBySearch();
    fetchFlowsBySearch();
    fetchSectionsBySearch();
    fetchCategoriesBySearch();
    fetchSubcategoriesBySearch();
    fetchSubcategoriesBySearch();
    fetchRegionsBySearch();
  }, []);

  const handleRefreshClick = () => {
    resetPaginationParams();
    fetchMovedPosts();
  };

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        <MigrationForm />
        <Divider />
        <Row align="top" gutter={24}>
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 24, order: 1 }}>
            <div className={s.historyHeader}>
              <Row align="middle">
                История изменений
                <Popover content="Обновить историю">
                  <SyncOutlined
                    onClick={handleRefreshClick}
                    className={cx(s.icon, s.refresh)}
                    spin={isFetching}
                  />
                </Popover>
              </Row>
            </div>
          </Col>
          <Col xs={{ span: 24, order: 3 }} lg={{ span: 16, order: 2 }}>
            <InfinityScroll
              isFetching={isFetching}
              fetchItems={fetchMovedPosts}
              hasMore={hasMoreHistoryItemsToFetch}
            >
              <MigrationsList />
            </InfinityScroll>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            lg={{ span: 8, order: 3 }}
            className={s.historyContentColumn}
          >
            <MigrationsFilter />
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
});

export default React.memo(SettingsMovePublicationsPage);
