import '@ant-design/compatible/assets/index.css';

import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Button, Input } from 'antd';
import { get, noop } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';

import { DeleteButton, ItemWithLabel, Modal } from '~components';
import { useBadWordsStore, useUserStore } from '~hooks';

import styles from './styles.scss';

const CREATE_TITLE = 'Создание нового запрещенного слова';
const UPDATE_TITLE = 'Редактирование запрещенного слова';

export const BadWordModal = Form.create({ name: 'badWordForm' })(
  observer((props: FormComponentProps) => {
    const {
      isShowModal,
      setHideModal,
      editableBadWord,
      resetEditableBadWord,
      isActionModal,
      deleteBadWord,
      updateBadWord,
      createBadWord,
    } = useBadWordsStore();
    const { checkPermissions } = useUserStore();

    const { form } = props;
    const { getFieldDecorator, getFieldsValue = noop } = form;

    const modalTitle = editableBadWord ? UPDATE_TITLE : CREATE_TITLE;
    const initialValue = get(editableBadWord, 'name', '');

    const handleSubmit = e => {
      e.preventDefault();

      const formData = getFieldsValue();

      const badWord = {
        data: formData,
      };

      const action = editableBadWord ? updateBadWord : createBadWord;

      action(badWord);
    };

    return (
      <Modal
        destroyOnClose
        width={590}
        onClose={setHideModal}
        isVisible={isShowModal}
        title={modalTitle}
        afterClose={resetEditableBadWord}
      >
        <div className={styles.root}>
          <Form onSubmit={handleSubmit}>
            <ItemWithLabel label="Запрещенное слово">
              <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('name', {
                    initialValue,
                    rules: [{ required: true, message: 'Обязательное поле!' }],
                  })(<Input autoFocus disabled={isActionModal} />)}
              </Form.Item>
            </ItemWithLabel>

            <div className={styles.modalControls}>
              <Button
                loading={isActionModal}
                disabled={
                  isActionModal ||
                  !checkPermissions(`badWords.${editableBadWord ? 'update' : 'create'}`)
                }
                htmlType="submit"
                type="primary"
              >
                Сохранить изменения
              </Button>

              {editableBadWord && (
                <div className={styles.deleteBtnWrapper}>
                  <DeleteButton
                    text="Удалить запрещенное слово"
                    onDelete={deleteBadWord}
                    disabled={!checkPermissions('badWords.delete')}
                  />
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    );
  }),
);
