import { observer } from 'mobx-react';
import React from 'react';

import { ContentWrapper, HeaderPageSearchBar } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { openRegionModal, RegionList } from '~containers/Regions';
import { useRegionsStore, useUserStore } from '~hooks';

const SEARCH_BTN_TEXT = 'Найти в регионах';
const CREATE_BTN_TEXT = 'Добавить регион';

const COUNT_DECLINATIONS = ['регион', 'региона', 'регионов'];

const RegionsPage = observer(() => {
  const { fetchRegions, allItemsCount, isFirstLoading, regionsLength } = useRegionsStore();
  const { checkPermissions } = useUserStore();

  return (
    <>
      <HeaderPageSearchBar
        searchButtonText={SEARCH_BTN_TEXT}
        createButtonText={CREATE_BTN_TEXT}
        onCreateElement={checkPermissions('regions.create') && openRegionModal}
        onSearch={fetchRegions}
      />
      <ContentWrapper
        title="Регионы"
        countItems={allItemsCount}
        countDeclinations={COUNT_DECLINATIONS}
      >
        <InfinityScroll
          fetchItems={fetchRegions}
          hasMore={allItemsCount !== regionsLength}
          isFetching={isFirstLoading}
        >
          <RegionList />
        </InfinityScroll>
      </ContentWrapper>
    </>
  );
});

export default RegionsPage;
