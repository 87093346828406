import {
  ArrowUpOutlined,
  CloseOutlined,
  EyeOutlined,
  LinkOutlined,
  SettingOutlined,
  StopOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Alert, Button, Modal } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';

import PagePreview from '~components/PagePreview';
import { EMPTY_STRING, resourceTypes } from '~constants';
import { usePublicationStore, useUserStore } from '~hooks';
import { getAuthorityByResourceType } from '~utils';

import PostponeInput from '../PostponeInput/index.js';
import styles from './styles.scss';

interface IActionsProps extends RouteProps {
  buttonSize?: 'small' | 'middle' | 'large' | undefined;
  noMargin?: boolean;
  resourceType?: string;
  type?: string;
}

export const Actions: FC<IActionsProps> = observer(props => {
  const [postponeModal, setPostponeModal] = useState(false);
  const [url, setUrl] = useState(EMPTY_STRING);

  const {
    lockSettings,
    previewPublication,
    savePublication,
    deletePublication,
    draftPublication,
    publishPublication,
    unpublishPublication,
    unlockSettings,
    validatePublication,
    postponePublication,
    setIsPublicationValidating,
    status: { id: statusId },
    id,
    isShowNotifyPlannedPost,
    showSettingsDrawer,
    isFetching,
    isQuiz,
  } = usePublicationStore();
  const { checkPermissions } = useUserStore();

  const { replace } = useHistory();

  const { buttonSize, type, noMargin, resourceType } = props;

  const linkBtnType = useMemo(() => {
    return type !== 'fixed' ? 'link' : 'ghost';
  }, [type]);

  const openPostponeModal = useCallback(() => {
    lockSettings().then(() => {
      setPostponeModal(true);
    });
  }, [lockSettings]);

  const closePostponeModal = useCallback(() => {
    unlockSettings().then(() => {
      setPostponeModal(false);
    });
  }, [unlockSettings]);

  const onClickGuestLink = useCallback(() => {
    previewPublication().then(url => {
      // @ts-ignore
      window.open(url, '_blank');
    });
  }, [previewPublication]);

  const onClickPreview = useCallback(() => {
    previewPublication().then(setUrl);
  }, [previewPublication]);

  const closePreview = () => {
    setUrl(EMPTY_STRING);
  };

  const onClickPublish = useCallback(() => {
    if (statusId !== 'POST_STATUS_PUBLISHED') {
      setIsPublicationValidating(true);
      validatePublication().then(result => result && publishPublication());
    } else {
      Modal.confirm({
        title: 'Вы действительно хотите снять с публикации?',
        cancelText: 'Отмена',
        onOk: () => {
          unpublishPublication();
        },
      });
    }
  }, [statusId, publishPublication, unpublishPublication, validatePublication]);

  const onClickPostpone = useCallback(() => {
    setIsPublicationValidating(true);
    validatePublication().then(result => {
      result &&
        postponePublication().then(() => {
          setPostponeModal(false);
        });
    });
  }, [postponePublication, validatePublication]);

  const onClickDelete = useCallback(() => {
    Modal.confirm({
      title: 'Вы действительно хотите удалить публикацию?',
      cancelText: 'Отмена',
      onOk: () => {
        deletePublication().then(() => {
          switch (resourceType) {
            case resourceTypes.textTranslation: {
              replace('/broadcasts/text');
              break;
            }

            case resourceTypes.quiz: {
              replace('/quiz');
              break;
            }

            default: {
              replace('/publications');
              break;
            }
          }
        });
      },
    });
  }, [deletePublication, replace, resourceType]);

  const onClickDraft = useCallback(() => {
    lockSettings().then(() => {
      draftPublication();
    });
  }, [draftPublication, lockSettings]);

  const canDraft = useMemo(() => {
    return statusId === 'POST_STATUS_PUBLISHED_ERROR' || statusId === 'POST_STATUS_POSTPONED';
  }, [statusId]);

  return (
    <>
      <div className={cn(styles.buttons, noMargin && styles.noMargin, styles.desktop)}>
        {checkPermissions(
          `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.publish`,
        ) && (
          <Button
            disabled={isFetching}
            size={buttonSize}
            type="primary"
            className={cn(styles.button, styles.publish)}
            onClick={onClickPublish}
          >
            {statusId === 'POST_STATUS_PUBLISHED' ? 'Снять с публикации' : 'Опубликовать'}
          </Button>
        )}

        {statusId !== 'POST_STATUS_PUBLISHED' &&
          checkPermissions(
            `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.publish`,
          ) && (
            <Button
              disabled={isFetching}
              size={buttonSize}
              type="primary"
              className={cn(styles.button, styles.postpone)}
              onClick={openPostponeModal}
            >
              Отложить
            </Button>
          )}

        {canDraft &&
          checkPermissions(
            `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.delete`,
          ) && (
            <Button
              size={buttonSize}
              type={linkBtnType}
              disabled={isFetching}
              className={cn(styles.button, styles.draft)}
              onClick={onClickDraft}
            >
              <SwapOutlined />
              {type !== 'fixed' && <span className={styles.lessMl}>В черновик</span>}
            </Button>
          )}

        {checkPermissions(
          `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.delete`,
        ) && (
          <Button
            type={linkBtnType}
            size={buttonSize}
            className={cn(styles.button, styles.delete, type === 'fixed' && styles.deleteFixed)}
            disabled={isFetching}
            onClick={onClickDelete}
          >
            <CloseOutlined />
            {type !== 'fixed' && <span className={styles.lessMl}>Удалить</span>}
          </Button>
        )}

        {!isQuiz && (
          <Button
            size={buttonSize}
            type={linkBtnType}
            disabled={isFetching}
            className={cn(styles.button, styles.guest)}
            onClick={onClickGuestLink}
          >
            <LinkOutlined />
            {type !== 'fixed' && 'Гостевая ссылка'}
          </Button>
        )}
        <Button
          size={buttonSize}
          type={linkBtnType}
          className={cn(
            styles.button,
            styles.buttonNoBorder,
            type === 'fixed' && styles.fixedPreviewBtn,
          )}
          disabled={isFetching}
          onClick={onClickPreview}
        >
          <EyeOutlined />
          {type !== 'fixed' && 'Предпросмотр'}
        </Button>
        <Button
          disabled={
            isFetching ||
            !checkPermissions(
              `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.update`,
            )
          }
          type="primary"
          size={buttonSize}
          className={cn(styles.button, styles.save)}
          onClick={savePublication}
        >
          Сохранить
        </Button>

        <Modal
          destroyOnClose
          title="Выберите дату публикации"
          visible={postponeModal}
          onOk={onClickPostpone}
          onCancel={closePostponeModal}
          okText="Отложить"
          cancelText="Отмена"
          okButtonProps={{ disabled: isFetching }}
        >
          <PostponeInput />

          {isShowNotifyPlannedPost && (
            <div className={styles.timeAlertWrapper}>
              <Alert
                showIcon
                message="Внимание. При нажатии на кнопку 'Отложить' вы опубликуете данный материал с датой предшествующей текущей."
                type="warning"
              />
            </div>
          )}
        </Modal>

        <PagePreview postId={id} url={url} visible={url !== ''} onClose={closePreview} />
      </div>

      <div className={cn(styles.mobile)}>
        {statusId === 'POST_STATUS_PUBLISHED' && (
          <div style={{ width: '100%' }}>
            <Button
              disabled={
                isFetching ||
                !checkPermissions(
                  `${getAuthorityByResourceType(
                    resourceType || resourceTypes.publication,
                  )}.publish`,
                )
              }
              icon={statusId === 'POST_STATUS_PUBLISHED' ? <StopOutlined /> : <ArrowUpOutlined />}
              size="small"
              type="link"
              className={styles.mobileCancelButton}
              onClick={onClickPublish}
            >
              Снять с публикации
            </Button>
          </div>
        )}

        <div className={styles.mobileButtonsGrid}>
          <div className={styles.buttons}>
            {statusId !== 'POST_STATUS_PUBLISHED' &&
              checkPermissions(
                `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.publish`,
              ) && (
                <Button
                  disabled={isFetching}
                  size={buttonSize}
                  type="primary"
                  className={cn(styles.button, styles.publish)}
                  onClick={onClickPublish}
                >
                  Опубликовать
                </Button>
              )}

            {statusId !== 'POST_STATUS_PUBLISHED' &&
              checkPermissions(
                `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.publish`,
              ) && (
                <Button
                  disabled={isFetching}
                  size={buttonSize}
                  type="primary"
                  className={cn(styles.button, styles.postpone)}
                  onClick={openPostponeModal}
                >
                  Отложить
                </Button>
              )}

            {canDraft &&
              checkPermissions(
                `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.delete`,
              ) && (
                <Button
                  size={buttonSize}
                  type={linkBtnType}
                  disabled={isFetching}
                  className={cn(styles.button)}
                  onClick={onClickDraft}
                >
                  <SwapOutlined />
                  {type !== 'fixed' && <span className={styles.lessMl}>В черновик</span>}
                </Button>
              )}
          </div>

          <div className={styles.buttons}>
            <Button
              size={buttonSize}
              type={linkBtnType}
              className={cn(
                styles.button,
                styles.buttonNoBorder,
                type === 'fixed' && styles.fixedPreviewBtn,
              )}
              disabled={isFetching}
              onClick={onClickPreview}
            >
              <EyeOutlined />
              {type !== 'fixed' && 'Предпросмотр'}
            </Button>

            <Button
              size={buttonSize}
              type={linkBtnType}
              disabled={isFetching}
              className={cn(styles.button, styles.guest)}
              onClick={onClickGuestLink}
            >
              <LinkOutlined />
              {type !== 'fixed' && 'Гостевая ссылка'}
            </Button>

            {checkPermissions(
              `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.delete`,
            ) && (
              <Button
                type={linkBtnType}
                size={buttonSize}
                className={cn(styles.button, styles.delete, type === 'fixed' && styles.deleteFixed)}
                disabled={isFetching}
                onClick={onClickDelete}
              >
                <CloseOutlined />
                {type !== 'fixed' && 'Удалить'}
              </Button>
            )}

            <Button
              disabled={
                isFetching ||
                !checkPermissions(
                  `${getAuthorityByResourceType(resourceType || resourceTypes.publication)}.update`,
                )
              }
              type="primary"
              size={buttonSize}
              className={cn(styles.button, styles.save)}
              onClick={savePublication}
            >
              Сохранить
            </Button>
            <div>
              <Button
                className={styles.mobileSettingButton}
                icon={<SettingOutlined />}
                size="small"
                type="link"
                onClick={showSettingsDrawer}
              >
                Настройки публикации
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
