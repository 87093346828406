import { observer } from 'mobx-react';
import React from 'react';

import { ContentSpinner, EmptyContent, GridWrapper } from '~components';
import { useEmployeesStore } from '~hooks';

import EmployeeItem from '../EmployeeItem';

const EmployeeList = observer(() => {
  const { employees, isFirstLoading, isEmptyEmployees } = useEmployeesStore();

  if (isFirstLoading) {
    return <ContentSpinner />;
  }

  if (isEmptyEmployees) return <EmptyContent text="Нет сотрудников" />;

  return (
    <GridWrapper gridSize={2}>
      {employees.map(employee => (
        <EmployeeItem key={employee._id} employee={employee} />
      ))}
    </GridWrapper>
  );
});

export default EmployeeList;
