import { Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { IBlock } from '~common';
import { BlockWrapper, ItemWithLabel } from '~components';
import SelectRender from '~containers/MovePosts/MigrationForm/SelectRender';
import {
  useAuthorsStore,
  useFlowsStore,
  useRegionsStore,
  useSubcategoriesStore,
  useTagsStore,
} from '~hooks';
import { ArrowRight } from '~icons';

import s from './styles.scss';

const { Option } = Select;

export interface INewsBlockProps extends IBlock {
  editor?: string;
  onChange?(): void;
}

const NewsBlock: React.FC<INewsBlockProps> = observer(props => {
  const { onChange, data } = props;

  const [entityType, setEntityType] = useState(data.entityType);
  const [objectId, setObjectId] = useState(data.objectId);
  const [objectLabel, setObjectLabel] = useState(data.objectLabel);
  const [objectFilter, setObjectFilter] = useState({
    label: 'Default',
    type: 'select',
    name: 'Default',
    fix: true,
  });

  const { fetchTagsBySearch, tagsSearches } = useTagsStore();
  const { fetchFlowsBySearch, flowsSearches } = useFlowsStore();
  const { fetchRegionsBySearch, regionsSearches } = useRegionsStore();
  const {
    fetchCategoriesBySearch,
    fetchSectionsBySearch,
    sectionsSearches,
    categoriesSearches,
  } = useAuthorsStore();
  const { fetchSubcategoriesBySearch, subcategoriesSearches } = useSubcategoriesStore();

  useEffect(() => {
    fetchTagsBySearch('', 'filter.blackListed=false');
    fetchFlowsBySearch();
    fetchRegionsBySearch();
    fetchSectionsBySearch();
    fetchCategoriesBySearch();
    fetchSubcategoriesBySearch();
  }, []);

  useEffect(() => {
    if (data.entityType) {
      handleSelectEntityChange(data.entityType, setObjectFilter);
    }
    if (data.objectId) {
      setObjectId(data.objectId);
    }
    if (data.objectLabel) {
      setObjectLabel(data.objectLabel);
    }
  }, [data.entityType]);

  const { blockProps = {} } = props;

  const newsObjectTypes = [
    {
      id: 'tags',
      ru: 'Тэги',
    },
    {
      id: 'section',
      ru: 'Раздел',
    },
    {
      id: 'categories',
      ru: 'Категории',
    },
    {
      id: 'subcategories',
      ru: 'Подкатегории',
    },
    {
      id: 'regions',
      ru: 'Регионы',
    },
  ];

  const entityFetchFilters = {
    fetchTagsFilter: 'filter.blackListed=false',
  };

  const handleSelectEntityChange = (value, setValue) => {
    setEntityType(value);
    switch (value) {
      case 'tags': {
        setValue({ label: 'Тэг', type: 'select', name: 'filter.tags', fix: true });
        break;
      }
      case 'categories': {
        setValue({ label: 'Категории', type: 'select', name: 'filter.categories', fix: true });
        break;
      }
      case 'section': {
        setValue({ label: 'Разделы', type: 'select', name: 'filter.section', fix: true });
        break;
      }
      case 'flows': {
        setValue({ label: 'Потоки', type: 'select', name: 'filter.flows', fix: true });
        break;
      }
      case 'regions': {
        setValue({ label: 'Регионы', type: 'select', name: 'filter.regions', fix: true });
        break;
      }
      case 'subcategories': {
        setValue({
          label: 'Подкатегории',
          type: 'select',
          name: 'filter.subcategories',
          fix: true,
        });
        break;
      }
      default: {
        setValue({});
        break;
      }
    }
  };

  const handleSelectObjectChange = object => {
    const link = object?.value ? generateEntityLink(entityType, object?.value) : '/';
    onChange &&
      // @ts-ignore
      onChange({
        entityType,
        objectId: object?.value,
        objectLabel: object?.label[0],
        link,
      });

    setObjectId(object?.value);
    setObjectLabel(object?.label[0]);
  };

  const generateEntityLink = (entityType, entityId) => {
    let link;
    let alias;

    switch (entityType) {
      case 'tags': {
        alias = tagsSearches.find(tag => tag._id === entityId)?.alias;
        link = `/t/${alias}`;
        break;
      }
      case 'categories': {
        alias = categoriesSearches.find(category => category._id === entityId)?.alias;
        link = `/c/${alias}`;
        break;
      }
      case 'section': {
        alias = sectionsSearches.find(section => section._id === entityId)?.alias;
        link = `/s/${alias}`;
        break;
      }
      case 'flows': {
        alias = flowsSearches.find(flow => flow._id === entityId)?.alias;
        link = `/f/${alias}`;
        break;
      }
      case 'regions': {
        alias = regionsSearches.find(region => region._id === entityId)?.alias;
        link = `/s/novosti/r/${alias}`;
        break;
      }
      case 'subcategories': {
        const subcategory = subcategoriesSearches.find(subcategory => subcategory._id === entityId);
        const categoryAlias = subcategory?.category?.alias;
        alias = subcategory?.alias;
        link = `/c/${categoryAlias}/${alias}`;
        break;
      }
      default: {
        break;
      }
    }

    return link;
  };

  return (
    <BlockWrapper {...blockProps} title="Новости">
      <div className={s.newsFormContent}>
        <div>
          <ItemWithLabel label="Сущность">
            <Select
              className={s.inputStyle}
              placeholder="Выберите сущность"
              value={entityType}
              onChange={value => {
                handleSelectEntityChange(value, setObjectFilter);
                setObjectId(null);
                setObjectLabel(null);
              }}
            >
              {newsObjectTypes.map(({ id, ru }) => (
                <Option value={id}>{ru}</Option>
              ))}
            </Select>
          </ItemWithLabel>
        </div>
        <ArrowRight className={s.arrowRight} />
        <div>
          <ItemWithLabel label="Объект">
            <SelectRender
              className={s.inputStyle}
              item={objectFilter}
              labelInValue
              value={objectId && { value: objectId, label: objectLabel }}
              onChange={handleSelectObjectChange}
              fetchOptions={entityFetchFilters}
            />
          </ItemWithLabel>
        </div>
      </div>
    </BlockWrapper>
  );
});

export default NewsBlock;
