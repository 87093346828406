import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { IFlow } from '~common';
import { ItemWithLabel } from '~components';
import { useFlowsStore, usePublicationStore } from '~hooks';
import { getUniqueListBy } from '~utils';

import { inputStyle } from '../../components/Settings/constants';

export const FlowsSelect = observer(() => {
  const { flows, set } = usePublicationStore();
  const { fetchFlowsBySearch, flowsSearches } = useFlowsStore();

  const selectedOptions: LabeledValue[] = flows.map((flow: IFlow) => ({
    value: flow._id,
    label: flow.title,
  }));

  const options: LabeledValue[] = getUniqueListBy<LabeledValue>(
    [...flows, ...flowsSearches].map((flow: IFlow) => ({
      value: flow._id,
      label: flow.title,
    })),
    'value',
  );

  const onChange = (flows: LabeledValue[] = []) => {
    set({ name: 'flows', value: flows.map(({ key, label }) => ({ _id: key, title: label })) });
  };

  const onSearch = term => {
    fetchFlowsBySearch(term);
  };

  useEffect(() => {
    fetchFlowsBySearch('');
  }, []);

  return (
    <ItemWithLabel label="Потоки">
      <Select
        mode="multiple"
        maxTagCount={1}
        style={inputStyle}
        placeholder="Поиск потока"
        value={selectedOptions}
        options={options}
        onChange={onChange}
        onSearch={onSearch}
        notFoundContent={null}
        filterOption={false}
        showSearch
        labelInValue
      />
    </ItemWithLabel>
  );
});
