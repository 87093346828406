import { DownloadOutlined } from '@ant-design/icons';
import { notification, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { propEq } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ContentWrapper, HeaderPageSearchBar, InfinityScroll, Sorting } from '~components';
import { Filter, FilterControl, PublicationList, ViewToggleButton } from '~components/Publications';
import { resourceTypes } from '~constants';
import {
  useFetchPaginationStore,
  useFilterParamsByListStore,
  usePublicationsStore,
  useStatusStore,
  useUserStore,
} from '~hooks';
import { resourceTypeFromAlias } from '~services/publicationApi';
import { getAuthorityByResourceType, role } from '~utils';

const PAGE_TITLE = {
  [resourceTypes.publication]: 'Все публикации',
  [resourceTypes.quiz]: 'Все опросы',
  [resourceTypes.textTranslation]: 'Все трансляции',
};

const CREATE_BTN = {
  [resourceTypes.publication]: 'Создать публикацию',
  [resourceTypes.quiz]: 'Создать опрос',
  [resourceTypes.textTranslation]: 'Создать трансляцию',
};

const SEARCH_BTN = {
  [resourceTypes.publication]: 'Найти в публикациях',
  [resourceTypes.quiz]: 'Найти в опросах',
  [resourceTypes.textTranslation]: 'Найти в трансляциях',
};

const COUNT_DECLINATIONS = {
  [resourceTypes.publication]: ['публикация', 'публикации', 'публикаций'],
  [resourceTypes.quiz]: ['опрос', 'опросы', 'опросов'],
  [resourceTypes.textTranslation]: ['трансляция', 'трансляции', 'трансляций'],
};

const SORTING = [
  {
    label: 'По дате создания',
    type: 'createdAt',
  },
  {
    label: 'По дате публикации',
    type: 'publicationDate',
    setWhenSearch: true,
  },
  {
    label: 'По названию',
    type: 'title',
  },
];

const getTypeFromSortingArray = (arr: any[], key: string) => {
  return arr.find(propEq(key, true))?.type;
};

const PublicationsPage = observer(props => {
  const { defaultFilter, hideFilters = [] } = props;
  const {
    initFilterParams,
    setDefaultFilterByCalendarView,
    resetFilters,
    setHideFilter,
    setShowFilter,
    setIsFirstLoading,
    fetchPublications,
    allItemsCount,
    publicationLength,
    isFirstLoading,
    isLoadingMore,
    isGrid,
    toggleIsShowFilter,
    getIsShowFilterFromUrlParams,
    isShowFilter,
    exportPublications,
  } = usePublicationsStore();
  const { resetPaginationParams, setPageLimit, setPageOffset } = useFetchPaginationStore();
  const { setFilterValue, setFilterParams, filterParams } = useFilterParamsByListStore();
  const { status } = useStatusStore();
  const { user, checkPermissions } = useUserStore();
  const location = useLocation();
  const history = useHistory();

  const [isExporting, setIsExporting] = useState(false);

  const { pathname } = location;
  const resourceType = resourceTypeFromAlias(pathname);

  useEffect(() => {
    initFilterParams();
    setDefaultFilterByCalendarView();

    if (getIsShowFilterFromUrlParams()) {
      setShowFilter();
    }

    return () => {
      resetPaginationParams();
      resetFilters();
      setHideFilter();
    };
  }, []);

  const fetchDidMount = useCallback(() => {
    setIsFirstLoading(true);
  }, [setIsFirstLoading]);

  const handleSearchPublications = useCallback(() => {
    setIsFirstLoading(true);
    resetPaginationParams();
    fetchPublications(defaultFilter);
  }, [defaultFilter, fetchPublications, resetPaginationParams, setIsFirstLoading]);

  const handleToggleMyPost = useCallback(() => {
    const isAllPost = filterParams['filter.createdBy'] === user._id;

    setIsFirstLoading(true);
    setFilterParams();
    resetPaginationParams();

    if (isAllPost) {
      setFilterValue('filter.createdBy');
    } else {
      setFilterValue('filter.createdBy', user._id);
    }

    fetchPublications(defaultFilter);
  }, [
    defaultFilter,
    fetchPublications,
    filterParams,
    resetPaginationParams,
    setFilterParams,
    setFilterValue,
    setIsFirstLoading,
    user._id,
  ]);

  const handleCreate = useCallback(
    ({ resourceType = 'other' }) => {
      if (status?.settings?.techMaintenance) {
        notification.warn({
          message: 'Технические работы',
          description: 'Действия с публикациями временно заблокированы',
          duration: 0,
        });
      } else if (resourceType === 'other') {
        history.push(`${location.pathname}/createMaterial`);
      } else if (resourceType === 'news') {
        history.push(`${location.pathname}/createNews`);
      }
    },
    [history, location.pathname, status?.settings?.techMaintenance],
  );

  const handleDownloadWithFilters = async () => {
    setIsExporting(true);
    setPageLimit(50000);
    setPageOffset(0);
    exportPublications(defaultFilter);
    setIsExporting(false);
    resetPaginationParams();
  };

  return (
    <>
      <HeaderPageSearchBar
        searchField="filter.title"
        searchButtonText={SEARCH_BTN[resourceType]}
        createButtonText={CREATE_BTN[resourceType]}
        onCreateElement={
          checkPermissions(`${getAuthorityByResourceType(resourceType)}.create`) && handleCreate
        }
        customButtons={<ViewToggleButton defaultFilter={defaultFilter} />}
        isGrid={isGrid}
        sortItem={getTypeFromSortingArray(SORTING, 'setWhenSearch')}
        onSearch={handleSearchPublications}
        resourceType={resourceType}
      />

      <ContentWrapper
        title={PAGE_TITLE[resourceType]}
        countItems={allItemsCount}
        countDeclinations={COUNT_DECLINATIONS[resourceType]}
        rightContent={
          <FilterControl
            onToggleMyPost={handleToggleMyPost}
            isShowFilter={isShowFilter}
            onToggleShowFilter={toggleIsShowFilter}
            isAllPost={filterParams['filter.createdBy'] === user._id}
          />
        }
      >
        {isShowFilter && <Filter hideFilters={hideFilters} defaultFilter={defaultFilter} />}

        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {SORTING.length && <Sorting onSort={handleSearchPublications} data={SORTING} />}

          {/* <Authorized authority={[role.CHIEF_EDITOR]} noMatch={null}> */}
          <Tooltip title="Выгрузить посты с фильтрами">
            <DownloadOutlined
              onClick={() => {
                !isExporting && handleDownloadWithFilters();
              }}
              style={{ marginLeft: '24px' }}
            />
          </Tooltip>
          {/* </Authorized> */}
        </div>

        <InfinityScroll
          defaultFilter={defaultFilter}
          loadItems={fetchPublications}
          isEndLoading={allItemsCount <= publicationLength}
          isFirstLoading={isFirstLoading}
          isLoadMore={isLoadingMore}
          onScrollDidMount={fetchDidMount}
        >
          <PublicationList />
        </InfinityScroll>
      </ContentWrapper>
    </>
  );
});

export default PublicationsPage;
