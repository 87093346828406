import { mixed, object, string } from 'yup';
import { EMPTY_STRING } from '~constants/index';
import { castBySchema } from '~utils/castBySchema';

/** @type object */
export const coverBlockSchema = object()
  .default(undefined)
  .shape({
    cover: mixed(),
    coverTitle: string().default(EMPTY_STRING),
    coverTitleHTML: string(),
  });

export const castByCoverBlockSchema = castBySchema(coverBlockSchema, { stripUnknown: true });
