import { Drawer, Input, message, notification, Spin } from 'antd';
import { throttle } from 'lodash';
import { observer } from 'mobx-react';
import { always, cond, equals } from 'ramda';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Prompt, useParams, useRouteMatch } from 'react-router-dom';

import EditingIcon from '~assets/icons/pencil-edit.svg';
import { Maybe } from '~common';
import { LoadingWrapper } from '~components';
import ContentWrapper from '~components/ContentWrapper';
import { ErrorWrapper } from '~components/ErrorWrapper';
import FilterToggleControl from '~components/FilterToggleControl';
import ImageEditor from '~components/ImageEditor/index.js';
import Labeled from '~components/ItemWithLabel';
import { CommentsModal } from '~components/PublicationComments';
import Validated from '~components/Publications/Validated';
import StatusLabel from '~components/StatusLabel';
import User from '~components/User';
import { EMPTY_STRING } from '~constants';
import { resourceTypes } from '~constants/publication';
import {
  useDraftStore,
  useFlagsStore,
  usePublicationStore,
  useStatusStore,
  useUserStore,
} from '~hooks';
import { useFetcher } from '~hooks/useFetcher';
import { YM } from '~services/analytics/ym';
import { noop } from '~utils';
import { format } from '~utils/date';
import getTimeByString from '~utils/getTimeByString';

import { Actions } from './components/Actions';
import EditorPublication from './components/EditorPublication';
import EditorQuiz from './components/EditorQuiz';
import EditorTextTranslation from './components/EditorTextTranslation';
import FixedMenu from './components/FixedMenu';
import Header from './components/Header';
import PriorityModal from './components/PriorityModal';
import SavingModal from './components/SavingModal';
import { Settings } from './components/Settings';
import TranslationInputs from './components/TranslationInputs';
import styles from './styles.scss';

interface IRouteParams {
  id: string;
}

type ImageEditorOnChangeHandler = (response: string) => void;

interface IImageEditorState {
  editableImage: string;
  isOpened: boolean;
  onChangeHandler: ImageEditorOnChangeHandler;
}

interface IImageEditorContext {
  closeImageEditor: () => void;
  openImageEditor: (url: string, editHandler: () => void) => void;
}

export const ImageEditorContext = createContext<IImageEditorContext>({
  openImageEditor: noop,
  closeImageEditor: noop,
});

interface IPublicationContext {
  postId: Maybe<string>;
}

export const PublicationContext = createContext<IPublicationContext>({
  postId: null,
});

const getTitle = cond<string, string>([
  [equals(resourceTypes.publication), always('публикации')],
  [equals(resourceTypes.textTranslation), always('трансляции')],
  [equals(resourceTypes.quiz), always('опроса')],
]);

export const EditorPage = observer(() => {
  const [isOpenSettings, setIsOpenSettings] = useState(true);
  const [imageEditorState, setImageEditorState] = useState<IImageEditorState>({
    isOpened: false,
    editableImage: EMPTY_STRING,
    onChangeHandler: noop,
  });

  const { id } = useParams<IRouteParams>();
  const { path, url } = useRouteMatch();

  const {
    init,
    clearPublication,
    approve,
    savePublication,
    unlockSettingsOnRouteChange,
    unlockAllMyBlock,
    set,
    restoreSavings,
    setSavingModal,
    setSavingError,
    hideSettingsDrawer,
    isFieldFetching,
    id: postId,
    publicationSavings,
    settingsBlocked,
    resourceType,
    note,
    status,
    textCount,
    publicationDate,
    createdAt,
    updatedAt,
    updatedBy,
    isNew,
    isFetching,
    isPublicationSaving,
    isPublicationLockedByMe,
    isShowSettingsDrawer,
    savingModal,
    validatedBy,
    validatedAt,
  } = usePublicationStore();
  const { useWebVisor, unlockOnExit } = useFlagsStore();
  const { employeeId } = useUserStore();
  const { clearDraft } = useDraftStore();
  const statusStore = useStatusStore();

  const [initialize, isInitFetching, isInitError] = useFetcher(init);

  const checkedShowFixMenu = useRef<HTMLDivElement>(document.createElement('div'));
  const isLockedByMe = useRef<boolean>(false);

  const formattedPublicationDate = useMemo(() => {
    return getTimeByString(publicationDate);
  }, [publicationDate]);

  const { isEditing: isSettingsEditing = false } = settingsBlocked || {};

  /**
   * Callbacks
   */

  const toggleSettings = useCallback(() => {
    setIsOpenSettings(!isOpenSettings);
  }, [isOpenSettings]);

  const onScroll = useCallback(
    throttle(() => {
      const postFixedMenu = document.getElementById('postFixedMenu');

      if (!postFixedMenu) return null;
      if (checkedShowFixMenu.current?.getBoundingClientRect().top < 0) {
        postFixedMenu.style.display = 'flex';
      } else {
        postFixedMenu.style.display = 'none';
      }

      return null;
    }, 200),
    [checkedShowFixMenu],
  );

  const onKeyDown = useCallback(
    e => {
      if (e.key === 'F9') {
        if (isFetching) {
          message.error('Сохранение недоступно. Повторите попытку позднее');
        } else if (statusStore?.status?.settings?.techMaintenance) {
          notification.warn({
            message: 'Технические работы',
            description: 'Действия с публикациями временно заблокированы',
            duration: 0,
          });
        } else {
          savePublication();
        }
      }
    },
    [isFetching, savePublication],
  );

  const onBeforeUnload = useCallback(
    e => {
      if (unlockOnExit && isLockedByMe.current) {
        e.preventDefault();
        e.returnValue = true;
      }
    },
    [unlockOnExit, isLockedByMe],
  );

  const onUnload = useCallback(() => {
    if (unlockOnExit && isLockedByMe.current) {
      unlockSettingsOnRouteChange();
      unlockAllMyBlock();
    }
  }, [unlockSettingsOnRouteChange, unlockAllMyBlock, unlockOnExit, isLockedByMe]);

  const onChangeNote = useCallback(
    ({ target: { value } }) => {
      set({ name: 'note', value });
    },
    [set],
  );

  const openImageEditor = (url, editHandler) => {
    setImageEditorState({
      isOpened: true,
      editableImage: url,
      onChangeHandler: editHandler,
    });
  };

  const closeImageEditor = () => {
    setImageEditorState({
      ...imageEditorState,
      isOpened: false,
      editableImage: EMPTY_STRING,
    });
  };

  const imageEditorContext: IImageEditorContext = { openImageEditor, closeImageEditor };
  const publicationContext: IPublicationContext = { postId };

  /**
   * Init
   */
  useEffect(() => {
    initialize({ type: path.slice(0, -4), id });

    useWebVisor && YM.sendVisit(url, { publicationId: id });
    useWebVisor && YM.sendEmployee(employeeId);

    return () => {
      /** Unlock publication when changing route */
      if (isLockedByMe.current) {
        unlockSettingsOnRouteChange();
        unlockAllMyBlock();
      }
      clearPublication();
      clearDraft();
    };
  }, []);

  /**
   * Event Listeners
   */
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload]);

  useEffect(() => {
    window.addEventListener('unload', onUnload, false);
    return () => {
      window.removeEventListener('unload', onUnload);
    };
  }, [onUnload]);

  /**
   * IsLockedByMe Listener
   */
  useEffect(() => {
    isLockedByMe.current = isPublicationLockedByMe || isPublicationSaving;
  }, [isPublicationLockedByMe, isPublicationSaving]);

  // console.log('render EditorPage...');

  return (
    <PublicationContext.Provider value={publicationContext}>
      <ImageEditorContext.Provider value={imageEditorContext}>
        <LoadingWrapper isLoading={isInitFetching} title="Получение данных о публикации...">
          <ErrorWrapper hasError={isInitError} subTitle="Ошибка загрузки публикации">
            {statusStore?.status?.settings?.techMaintenance && (
              <div className={styles.techOverlay}>
                <div>
                  Ведутся технические работы. <br /> Действия с публикациями временно заблокированы.
                </div>
              </div>
            )}
            <FixedMenu />

            <Prompt
              when={isLockedByMe.current}
              message="Часть блоков заблокирована вами и не сохранена. При выходе блоки будут разблокированы а изменения отменены!"
            />

            <Header validatedBy={validatedBy} validatedAt={validatedAt} onClick={approve} />

            <ContentWrapper
              hideMobileTitle
              title={`${isNew ? 'Создание' : 'Редактирование'} ${getTitle(resourceType)}`}
              items={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StatusLabel color={status.color} label={status.ru} />
                  {publicationDate && (
                    <div style={{ marginLeft: 15 }}>{formattedPublicationDate}</div>
                  )}
                </div>
              }
              rightContent={
                <div style={{ display: 'flex' }}>
                  {isSettingsEditing && (
                    <EditingIcon width={12} style={{ marginRight: 5, fill: '#ff0a0a' }} />
                  )}
                  <FilterToggleControl
                    label="Все настройки"
                    isActive={isOpenSettings}
                    onClick={toggleSettings}
                    isHideMobile
                    wrapperStyle={isSettingsEditing ? { color: '#ff0a0a' } : {}}
                  />
                </div>
              }
            >
              <div className={styles.mainInfo}>
                {updatedBy != null && (
                  <User
                    person={{
                      avatar:
                        updatedBy?.avatar !== null
                          ? `${process.env.API_ROOT}/files/${updatedBy.avatar}/content?height=100&width=100`
                          : '',
                      role: { name: '' },
                      name: `${updatedBy.firstName} ${updatedBy.lastName}`,
                    }}
                  />
                )}
                <span className={styles.text}>
                  <span>{format(createdAt, 'DD MMMM YYYY в HH:mm')},</span>
                  <span>{` изменено ${format(updatedAt, 'DD MMMM YYYY HH:mm')}`}</span>
                </span>

                <span className={styles.textCount}>Символов: {textCount}</span>

                <div className={styles.review}>
                  <Validated {...validatedBy} validatedAt={validatedAt} onClick={approve} />
                </div>
              </div>
              {isOpenSettings && (
                <div className={styles.mobileSettings}>
                  <Spin spinning={isFieldFetching('settings')}>
                    <Settings />
                  </Spin>
                </div>
              )}
              <Actions resourceType={resourceType} />
              <div ref={checkedShowFixMenu} />
              <div className={styles.inputsWrapper}>
                {resourceType === resourceTypes.textTranslation && <TranslationInputs />}

                <Labeled label="Примечание" outerClass={styles.note}>
                  <Input value={note} onChange={onChangeNote} />
                </Labeled>
              </div>
              {resourceType === resourceTypes.publication && (
                <EditorPublication onEditImage={openImageEditor} onKeyDown={onKeyDown} />
              )}
              {resourceType === resourceTypes.textTranslation && (
                <EditorTextTranslation onEditImage={openImageEditor} onKeyDown={onKeyDown} />
              )}
              {resourceType === resourceTypes.quiz && <EditorQuiz onKeyDown={onKeyDown} />}
            </ContentWrapper>

            <CommentsModal />
            <PriorityModal />
            <SavingModal
              visible={savingModal}
              onCancel={() => {
                restoreSavings();
                setSavingModal(false);
                setSavingError(false);
              }}
              data={publicationSavings}
            />

            <ImageEditor
              postId={postId}
              shown={imageEditorState.isOpened}
              src={imageEditorState.editableImage}
              onClose={closeImageEditor}
              onChange={imageEditorState.onChangeHandler}
            />

            <Drawer
              title="Настройки публикации"
              placement="bottom"
              closable
              onClose={hideSettingsDrawer}
              visible={isShowSettingsDrawer}
              bodyStyle={{
                overflowY: 'scroll',
                height: 'calc(80vh - 55px)',
              }}
              height="80vh"
            >
              <Settings />
            </Drawer>
          </ErrorWrapper>
        </LoadingWrapper>
      </ImageEditorContext.Provider>
    </PublicationContext.Provider>
  );
});
