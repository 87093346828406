import { notification } from 'antd';
import { noop } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { identity, inc } from 'ramda';

import API from '~api';
import { FilterItem, IDictionary, ITag, Maybe } from '~common';
import { EMPTY_STRING } from '~constants';
import { openTagModal } from '~containers';
import { showErrorNotificationInPromise } from '~utils';

import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists';
import FetchPaginationStore from './fetchPaginationStore';

const TAGS_URL = '/tags';

export const TAGS_EXPORT_URL = '/tags/exportFromRegister';

export class TagsStore {
  @observable isShowModal = false;

  @observable currentTagTabFilter = '';

  @observable isFirstLoading = true;

  @observable isActionModal = false;

  @observable editableTag: Maybe<ITag> = null;

  @observable allItemsCount = 0;

  @observable isLoadingMore = false;

  @observable tags: Array<ITag> = [];

  @observable tagsSearches: ITag[] = [];

  @observable dictionary: IDictionary[] = [];

  @observable isShowFilter = ConfigureFetchUrlByListsStore.getIsShowFilterFromUrlParams();

  @observable
  isShowFilterOnWhiteListPage = ConfigureFetchUrlByListsStore.getIsShowFilterFromUrlParams();

  // @ts-ignore
  @observable selectedFilterItems: IObservableArray<FilterItem> = [];

  constructor() {
    const startUrl = new URLSearchParams(window.location.search);

    startUrl.forEach((value, key) => {
      const filterFromUrl = this.filterItems.find(item => item.name === key && !item.fix);

      if (filterFromUrl) {
        this.setFilterItemActive(filterFromUrl);
      }
    });
  }

  @action
  fetchDictionaries = () => {
    const promise = API.get('dictionaries/tags');

    return promise.then(res => {
      const { data } = res;

      this.dictionary = data.tagsType;
    });
  };

  initFilterParams = () => {
    const { SET_FILTER_PARAMS_FOR_BACK, getDateRangeFilterParams } = ConfigureFetchUrlByListsStore;

    SET_FILTER_PARAMS_FOR_BACK({
      'filter.createdAt': params => getDateRangeFilterParams(params),
      'filter.type': identity,
    });
  };

  @action
  fetchTagsBySearch = (term = '', defaultFilter: Record<string, any> | string = EMPTY_STRING) => {
    let url = TAGS_URL;

    if (term) {
      url = `${url}?search=${term}`;
    }

    if (defaultFilter) {
      const prefix = term ? '&' : '?';

      url = `${url}${prefix}${defaultFilter}`;
    }

    return API.get(url).then(res => {
      const { data } = res.data;

      runInAction(() => {
        this.tagsSearches = data;
      });

      return data;
    });
  };

  @action
  fetchTags = (defaultFilter: Record<string, any> | string = EMPTY_STRING) => {
    const { offset, setPageOffset } = FetchPaginationStore;
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;

    const promiseUrl = getPromiseUrl({
      url: TAGS_URL,
      defaultFilter,
    });

    const fetchTagsPromise = API.get(promiseUrl);

    this.setIsLoadingMore(true);
    return fetchTagsPromise
      .then(res => {
        const { data, meta } = res.data;

        if (offset === 0) {
          this.setTags(data);
        } else {
          this.setTags([...this.tags, ...data]);
        }

        setPageOffset(inc(offset));
        this.setAllItemsCount(meta.count);
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      })
      .catch(() => {
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      });
  };

  @action
  updateTag = (data: { data: ITag }) => {
    runInAction(() => {
      this.isActionModal = true;
    });

    if (!this.editableTag) {
      throw Error('editableTag is null');
    }

    const { _id } = this.editableTag;

    const updateTagPromise = API.patch(`${TAGS_URL}/${_id}`, data);

    return updateTagPromise
      .then(res => {
        const { data } = res.data;

        if (!this.editableTag) {
          throw Error('editableTag is null');
        }

        const editableTagIndex = this.tags.indexOf(this.editableTag);

        this.setHideModal();
        runInAction(() => {
          this.isActionModal = false;
          this.tags[editableTagIndex] = data;
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка обновления тега',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  createTag = (data: { data: ITag }) => {
    runInAction(() => {
      this.isActionModal = true;
    });

    const createTagPromise = API.post(TAGS_URL, data);

    return createTagPromise
      .then(res => {
        const { data } = res.data;
        this.setHideModal();

        this.setAllItemsCount(this.allItemsCount + 1);

        runInAction(() => {
          this.isActionModal = false;
          this.tags.unshift(data);
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания тега',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @action
  deleteTag = () => {
    runInAction(() => {
      this.isActionModal = true;
    });

    if (!this.editableTag) {
      throw Error('editableTag is null');
    }

    const { _id } = this.editableTag;

    const deleteAuthorPromise = API.delete(`${TAGS_URL}/${_id}`);

    return deleteAuthorPromise
      .then(() => {
        // @ts-ignore
        this.tags.remove(this.editableTag);

        this.resetEditableTag();
        this.setHideModal();
        this.setAllItemsCount(this.allItemsCount - 1);

        runInAction(() => {
          this.isActionModal = false;
        });
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка удаления тега',
          callback: () => {
            runInAction(() => {
              this.isActionModal = false;
            });
          },
        }),
      );
  };

  @computed
  get isEmptyTags() {
    return !this.tags.length;
  }

  @action
  setEditableTag = async value => {
    this.editableTag = value;

    await openTagModal();
  };

  @action
  resetEditableTag = () => {
    this.editableTag = null;
  };

  @action
  setShowModal = () => {
    this.isShowModal = true;
  };

  @action
  setHideModal = () => {
    this.isShowModal = false;
  };

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value;
  };

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value;
  };

  @action
  setTags = data => {
    this.tags = data;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @action
  setCurrentTagTabFilter = filter => {
    this.currentTagTabFilter = filter;
  };

  @action
  toggleIsShowFilter = () => {
    this.isShowFilter = !this.isShowFilter;
  };

  @action
  toggleIsShowFilterWhiteListPage = () => {
    this.isShowFilterOnWhiteListPage = !this.isShowFilterOnWhiteListPage;
  };

  @action
  setFilterItemActive = (item: FilterItem) => {
    this.selectedFilterItems?.push(item);
  };

  @action
  removeFilterItem = (item: FilterItem) => {
    this.selectedFilterItems?.remove(item);
  };

  @action
  resetFilterItems = () => {
    this.selectedFilterItems = [];
  };

  @computed
  get tagsLength() {
    return this.tags.length;
  }

  @computed
  get filterItems(): FilterItem[] {
    return [
      {
        type: 'dateRange',
        name: 'filter.createdAt',
        label: 'Дата создания',
        fix: true,
      },
      {
        type: 'select',
        name: 'filter.type',
        label: 'Тип',
        selectOptions: {
          options: this.dictionary,
          fetch: this.fetchDictionaries,
          key: 'id',
          label: 'ru',
        },
      },
      {
        type: 'bool',
        name: 'filter.blackListed',
        label: 'Черный список',
      },
      {
        type: 'select',
        name: 'filter.isVerified',
        label: 'Статус проверки',
        selectOptions: {
          options: [
            { status: 'true', name: 'Проверенный' },
            { status: 'false', name: 'Непроверенный' },
          ],
          fetch: noop,
          key: 'status',
          label: 'name',
        },
      },
    ];
  }

  get filterItemsToWhiteListPage(): FilterItem[] {
    return [
      {
        type: 'dateRange',
        name: 'filter.createdAt',
        label: 'Дата создания',
      },
      {
        type: 'select',
        name: 'filter.type',
        label: 'Тип',
        selectOptions: {
          options: this.dictionary,
          fetch: this.fetchDictionaries,
          key: 'id',
          label: 'ru',
        },
        fix: true,
      },
      {
        type: 'select',
        name: 'filter.isVerified',
        label: 'Статус проверки',
        selectOptions: {
          options: [
            { status: 'true', name: 'Проверенный' },
            { status: 'false', name: 'Непроверенный' },
          ],
          fetch: noop,
          key: 'status',
          label: 'name',
        },
      },
    ];
  }

  @action
  exportTags = (defaultFilter = '') => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;

    const promiseUrl = getPromiseUrl({
      url: TAGS_EXPORT_URL,
      defaultFilter,
    });

    const exportTagsPromise = API.get(promiseUrl);

    notification.warn({
      message: 'Запущена выгрузка тегов с фильтрами',
      description: 'Не закрывайте и не обновляйте вкладку до получения файла выгрузки',
      duration: 0,
      placement: 'topRight',
    });

    exportTagsPromise
      .then(res => {
        let filename = '';
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(res.headers['content-disposition']);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
        return new File([res.data], filename, { type: 'text/csv;charset=utf-8;' });
      })
      .then(blob => {
        const file = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = blob.name;
        a.href = file;
        a.click();
      });
  };
}

export default new TagsStore();
