import PropTypes from 'prop-types'
import React from 'react'

import { DatePicker } from 'antd'
import moment from 'moment'

const DateRender = ({ onChange, value }) => {
  const date = value && moment(value)

  return (
    <DatePicker
      style={{
        width: '100%'
      }}
      onChange={onChange}
      value={date}
    />
  )
}

export default DateRender

DateRender.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any
}
