import React from 'react';

import { FilterItem } from '~common';

import styles from './styles.scss';

interface Props {
  filtersItem: FilterItem[];
  selectedFilterItems: FilterItem[];

  setFilterItemActive(item: FilterItem): void;
}

interface DropDownItemProps {
  item: FilterItem;

  onClick(item: FilterItem): void;
}

const DropDownItem: React.FC<DropDownItemProps> = React.memo(props => {
  const { onClick, item } = props;

  const handleClickItem = () => {
    onClick(item);
  };

  return (
    <div className={styles.option} onClick={handleClickItem}>
      {item.label}
    </div>
  );
});

const Component: React.FC<Props> = props => {
  const { filtersItem, setFilterItemActive, selectedFilterItems } = props;

  return (
    <div className={styles.root}>
      {filtersItem
        .filter(item => !item.fix)
        .map(
          (item, index) =>
            !selectedFilterItems.includes(item) && (
              <DropDownItem key={index} item={item} onClick={setFilterItemActive} />
            ),
        )}
    </div>
  );
};

export const FilterDropDown = React.memo(Component);
