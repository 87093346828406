import browserImageSize from 'browser-image-size';

import { showErrorNotification } from '~utils/getError';

const maxImageDimensions = process.env.IMGPROXY_MAX_SRC_RESOLUTION || '36';
const maxImageSize = process.env.MAX_IMG_SIZE || '5242880';

export const isFileAcceptable = async (file: File) => {
  const type = file.type.split('/')[0];
  let continueUpload = true;
  if (type === 'image') {
    await browserImageSize(file).then(size => {
      const imageSize = (size.height * size.width) / 1000000;
      if (imageSize >= parseInt(maxImageDimensions, 10) - 0.5) {
        continueUpload = false;
        showErrorNotification(
          'Ошибка загрузки файла',
          `Слишком высокое разрешение изображения. Максимальное разрешение не более ${parseInt(
            maxImageDimensions,
            10,
          )} мегапикселей.`,
        );
      }
      // if (file.size >= parseInt(maxImageSize, 10)) {
      //   continueUpload = false;
      //   showErrorNotification(
      //     'Ошибка загрузки файла',
      //     `Слишком большой вес файла. Максимальный вес ${parseInt(maxImageSize, 10) /
      //       1024 /
      //       1024} мегабайт.`,
      //   );
      // }
    });
  }
  return continueUpload;
};
