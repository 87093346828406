import { string, object } from 'yup'
import { EMPTY_STRING } from '~constants/index'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const advTypeSchema = object()
  .default({ id: EMPTY_STRING })
  .shape({
    id: string(),
    ru: string()
  })

export const isValidAdvType = isValidBySchema(advTypeSchema)
