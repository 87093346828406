import { message, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadProps } from 'antd/lib/upload/interface';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';

import { useCheckStatusUpload, useFlagsStore } from '~hooks';
import { FileTransportLayer } from '~services';
import { isFileAcceptable } from '~utils';

import Button from '../Button';

interface IProps {
  onChange?(data: unknown): void;
  title?: string;
  uploadProps?: UploadProps;
}

const ButtonUpload: React.FC<IProps> = observer(
  ({ onChange, uploadProps = {}, title = 'Кликните чтобы загрузить файл' }) => {
    const { fileUploadWorker } = useFlagsStore();
    const { checkStatus, status, isFetching } = useCheckStatusUpload(onChange);
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const token = localStorage.getItem('token') || '';

    const fileUploadProps = {
      name: 'file',
      action: `${process.env.API_ROOT}${FileTransportLayer.fileUploadUrl}`,
      withCredentials: true,
      headers: { Authorization: token ? `Bearer ${JSON.parse(token)}` : '' },
      multiple: false,
      beforeUpload: async file => {
        return isFileAcceptable(file);
      },
      onChange(info) {
        // initial FileList
        setFileList(info.fileList);
        if (info.file.status === 'done') {
          if (fileUploadWorker) {
            checkStatus(info.file.response.data.id);
          } else {
            message.success(`${info.file.name} успешно загружен!`);
            onChange && onChange(info.file.response.data);
          }
        } else if (info.file.status === 'error') {
          // remove if error
          setFileList([]);
          message.error(`${info.file.name} не загружен!`);
        } else if (info.file.status === 'removed') {
          setFileList([]);
          onChange && onChange(null);
        }

        if (!info?.file?.status) {
          // remove if no status
          setFileList([]);
        }
      },
    };

    return (
      <Upload {...fileUploadProps} {...uploadProps} fileList={fileList}>
        <Button loading={isFetching} disabled={isFetching}>
          {isFetching ? status : title}
        </Button>
      </Upload>
    );
  },
);

export default ButtonUpload;
