import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { TabItem } from '~components';

import styles from './styles.scss';

const CONTROLS = [
  {
    label: 'День',
    type: 'day',
  },
  {
    label: 'Неделя',
    type: 'week',
  },
  {
    label: 'Месяц',
    type: 'month',
  },
  {
    label: 'Год',
    type: 'year',
  },
];

@inject(stores => {
  const { setCalendarType, calendarType, fetchEventsByCalendar, setEvents } = stores.ScheduleStore;

  return {
    calendarType,
    setCalendarType,
    fetchEventsByCalendar,
    setEvents,
  };
})
@observer
class TypeControls extends Component {
  handleClick = item => {
    const { setCalendarType, fetchEventsByCalendar, setEvents } = this.props;

    setCalendarType(item.type);
    setEvents([]);
    fetchEventsByCalendar();
  };

  render() {
    const { calendarType } = this.props;

    return (
      <div className={styles.root}>
        {CONTROLS.map(item => (
          <div key={item.type} className={styles.item}>
            <TabItem isActive={item.type === calendarType} onClick={this.handleClick} data={item} />
          </div>
        ))}
      </div>
    );
  }
}

export default TypeControls;

TypeControls.propTypes = {
  calendarType: PropTypes.string,
  fetchEventsByCalendar: PropTypes.func,
  setCalendarType: PropTypes.func,
  setEvents: PropTypes.func,
};
