import { path } from 'ramda';

import API from '~api';
import FlagsStore from '~store/FlagsStore';

interface ICheckJob {
  failedReason?: string;
  finishedOn?: number;
  id: number;
  progress?: string;
  returnvalue: any;
}

export class FileTransportLayer {
  static get fileUploadUrl() {
    const { fileUploadWorker } = FlagsStore;

    return `/files${fileUploadWorker ? '/async' : ''}`;
  }

  // eslint-disable-next-line consistent-return
  static checkStatus = async (...jobsId: number[]): Promise<ICheckJob | undefined> => {
    try {
      return await API.post(`/files/status`, { jobsId }, { timeout: 10000 }).then(
        path<ICheckJob>(['data', 'data']),
      );
    } catch (e) {
      if (e.code === 'ECONNABORTED') {
        return FileTransportLayer.checkStatus(...jobsId);
      }

      console.error(e);
      throw e;
    }
  };
}
