import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { IAuthor } from '~common';
import { WhiteBlockWithShadow } from '~components';
import { Pencil } from '~icons';
import { AuthorsStore } from '~store/AuthorsStore';
import { getFromStore } from '~utils';

import Socials from '../Socials';
import styles from './styles.scss';
import AuthorInfo from './TopInfo';

interface IProps extends Partial<Pick<AuthorsStore, 'setEditableAuthor'>> {
  author: IAuthor;
}

@inject(getFromStore('AuthorsStore', ['setEditableAuthor']))
@observer
class AuthorItem extends Component<IProps> {
  handleClickItem = () => {
    const { author, setEditableAuthor } = this.props;

    setEditableAuthor && setEditableAuthor(author, 'edit');
  };

  handleSocialLink = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  handleClickMoreInfo = e => {
    e.stopPropagation();
    const { author, setEditableAuthor } = this.props;

    setEditableAuthor && setEditableAuthor(author, 'info');
  };

  render() {
    const { author } = this.props;

    const { socials } = author;

    return (
      <WhiteBlockWithShadow>
        <div className={styles.root} onClick={this.handleClickItem}>
          <div className={styles.ico}>
            <Pencil fill="#D3D3D3" />
          </div>

          <AuthorInfo data={author} />

          <div className={styles.bottom}>
            <Socials data={socials} onClick={this.handleSocialLink} />

            <div onClick={this.handleClickMoreInfo} className={styles.readMore}>
              Прочитать полностью
            </div>
          </div>
        </div>
      </WhiteBlockWithShadow>
    );
  }
}

export default AuthorItem;
