import { Button } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { GridWrapper } from '~components';
// TODO Убрать DynamicField в корень components
import DynamicField from '~components/Publications/Filter/dynamicField';
import {
  useChangeLogStore,
  useFetchPaginationStore,
  useFilterParamsByListStore,
  useSortingStore,
} from '~hooks';

import { ChangesSelect } from '../../Selects';
import styles from './styles.scss';

const ChangeLogFilter = observer(() => {
  const {
    filtersItem,
    selectedFilterItems,
    removeFileItem,
    fetchChangeLog,
    setIsFirstLoading,
  } = useChangeLogStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const { setFilterParams, filterParams } = useFilterParamsByListStore();
  const { resetOptions } = useSortingStore();

  const handleSetFilter = useCallback(() => {
    setIsFirstLoading(true);
    resetPaginationParams();
    fetchChangeLog();
  }, [setIsFirstLoading, resetPaginationParams, fetchChangeLog]);

  const handleResetFilter = useCallback(() => {
    setFilterParams();
    resetPaginationParams();
    resetOptions();
    setIsFirstLoading(true);
    fetchChangeLog();
  }, [setFilterParams, resetPaginationParams, resetOptions, setIsFirstLoading, fetchChangeLog]);

  return (
    <div className={styles.root}>
      <GridWrapper gridSize={5} maxItemWidth={285}>
        {filtersItem
          .filter(item => !!item.fix)
          .map((item, index) => (
            <DynamicField key={index} item={item} />
          ))}

        <ChangesSelect disabled={filterParams['filter.resourceType'] !== 'posts'} />

        {selectedFilterItems
          .filter(item => item.type !== 'bool')
          .map((item, index) => (
            <DynamicField onRemove={removeFileItem} key={index} item={item} />
          ))}
      </GridWrapper>

      <div className={styles.filterControls}>
        <div />

        <div className={styles.serviceControl}>
          <Button type="ghost" onClick={handleResetFilter}>
            Отменить фильтрацию
          </Button>
          <Button type="primary" onClick={handleSetFilter}>
            Применить
          </Button>
        </div>
      </div>
    </div>
  );
});

export default ChangeLogFilter;
