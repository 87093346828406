import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists';

const getData = ({ data }) => data;

const EXPERIMENTS_URL = '/experiments';

export class ExperimentsTransportLayer {
  getExperiments = (defaultFilter = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: EXPERIMENTS_URL, defaultFilter });

    return API.get(promiseUrl).then(getData);
  };

  createExperiment = experiment => {
    return API.post(`${EXPERIMENTS_URL}`, { ...experiment }).then(getData);
  };

  updateExperimentById = (id, experiment) => {
    return API.patch(`${EXPERIMENTS_URL}/${id}`, {
      data: { ...experiment },
    }).then(getData);
  };

  deleteExperimentById = id => {
    return API.delete(`${EXPERIMENTS_URL}/${id}`).then(getData);
  };
}
