import { object, string } from 'yup'
import { urlSchema } from '~schemas/url'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const mediaSchema = object()
  .default(undefined)
  .shape({
    XSS: urlSchema,
    XS: urlSchema,
    S: urlSchema,
    M: urlSchema,
    L: urlSchema,
    LQ: urlSchema,
    mp4: urlSchema,
    preview: string().url()
  })

export const isValidMedia = isValidBySchema(mediaSchema)
