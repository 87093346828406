import { Tooltip } from 'antd';
import cx from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React, { CSSProperties, ReactNode, useCallback, useMemo } from 'react';

import { ButtonShape, ButtonSize, ButtonType, EMPTY_STRING } from '~constants';

import styles from './styles.styl';

interface IProps {
  className?: string;
  icon?: string;
  iconNode?: ReactNode;
  onClick?(e: React.MouseEvent): void;
  shape?: ButtonShape;
  size?: ButtonSize;
  style?: CSSProperties;
  title?: string;
  type?: ButtonType;
}

const getButtonSize = cond<string, string>([
  [equals('large'), always('lg')],
  [equals('small'), always('sm')],
  [T, always('')],
]);

const BlockButton: React.FC<IProps> = props => {
  const {
    iconNode,
    type,
    shape,
    size,
    className,
    icon,
    style,
    children,
    onClick,
    title = EMPTY_STRING,
  } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  const sizeCls = useMemo(() => getButtonSize(size ?? ''), [size]);

  const classes = useMemo(
    () =>
      cx(styles.btn, className, {
        [styles[`btn-${type}`]]: type,
        [styles[`btn-${shape}`]]: shape,
        [styles[`btn-${sizeCls}`]]: sizeCls,
        [styles['icon-only']]: !children && children !== 0 && icon,
      }),
    [className, type, shape, sizeCls, children, icon],
  );

  const kids = useMemo(() => (children || children === 0 ? children : null), [children]);

  return (
    <Tooltip title={title}>
      <button type="button" className={classes} onClick={handleClick} style={style}>
        {iconNode}
        {kids}
      </button>
    </Tooltip>
  );
};

export default BlockButton;
