import axios from 'axios';
import faker from 'faker';

import API from '~api';
import { IAuthor } from '~common';
import { EMPTY_STRING, resourceTypes } from '~constants';
import { showErrorNotificationInPromise } from '~utils';

faker.locale = 'ru';

const API_URL_POST_DICTIONARIES = '/dictionaries/post';
const API_URL_SETTINGS_DICTIONARIES = '/dictionaries/settings';
const API_URL_CATEGORIES = '/categories';
const API_URL_SUBCATEGORIES = '/subcategories';
const API_URL_SECTIONS = '/sections';
const API_URL_REGIONS = '/regions';
const API_URL_SYMBOLS = '/settings/symbols';
const API_URL_TIMERS = '/settings/timers';

const API_URL_POSTS = '/posts';
const API_URL_BLOCKS = '/blocks';
const API_URL_GROUP_BLOCKS = '/blockgroups';

export const resourceTypeFromAlias = type => {
  switch (type) {
    case '/broadcasts/text':
      return resourceTypes.textTranslation;
    case '/quiz':
      return resourceTypes.quiz;
    case '/publications':
    default:
      return resourceTypes.publication;
  }
};

const getData = ({ data: { data } }) => data;
const { CancelToken } = axios;

interface IPostData {
  authors: IAuthor[];
  flags: { RSS: object; commentsAllowed: boolean };
  resourceType: string;
  section?: string | null;
}

export class PublicationTransportLayer {
  id = '';

  cancelToken = CancelToken.source();

  getDictionaries() {
    const promises = [
      API.get(API_URL_POST_DICTIONARIES).then(({ data }) => ({
        ...data,
        postPriorities: data.postPriorities.filter(({ id }) => id !== 'POST_PRIORITY_MAIN_LENTA'),
      })),
      API.get(`${API_URL_CATEGORIES}?limit=999`).then(getData),
      API.get(`${API_URL_SUBCATEGORIES}?limit=999`).then(getData),
      API.get(`${API_URL_SECTIONS}?limit=999`).then(getData),
      API.get(`${API_URL_REGIONS}?limit=999`).then(getData),
      API.get(`${API_URL_TIMERS}?limit=999`).then(getData),
    ];

    return Promise.all(promises).then(
      ([dictionaries, categories, subcategories, sections, regions, timers]) => ({
        dictionaries,
        categories,
        subcategories,
        sections,
        regions,
        timers,
      }),
    );
  }

  getSymbols() {
    return API.get(`${API_URL_SYMBOLS}`).then(getData);
  }

  getSettingsDictionaries() {
    return API.get(`${API_URL_SETTINGS_DICTIONARIES}`).then(({ data }) => data);
  }

  getPostPriority(id) {
    return API.get(`${API_URL_POSTS}/${id}/priority`).then(getData);
  }

  getPostPriorities(id) {
    return API.get(`${API_URL_POSTS}/${id}/priorities`).then(getData);
  }

  getEmployeesOnPage() {
    return API.get(`${API_URL_POSTS}/${this.id}/employees`).then(getData);
  }

  async createPublication(type, newsSectionId = null) {
    const author = await API.get('employees/me')
      .then(getData)
      .then(employee => {
        const [firstAuthor] = employee?.authors || [];
        return firstAuthor?._id || EMPTY_STRING;
      });
    const rss = await API.get('settings/rss').then(getData);

    const data: IPostData = {
      resourceType: resourceTypeFromAlias(type),
      authors: [author],
      flags: { commentsAllowed: true, RSS: { ...rss, yandexNews: !!newsSectionId } },
    };

    if (newsSectionId) {
      data.section = newsSectionId;
    }

    return API.post(API_URL_POSTS, { data })
      .then(({ data: { data } }) => {
        this.id = data._id;
        window.history.replaceState(null, '', `${type}/${data._id}`);
        return data;
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка создания публикации' }));
  }

  deletePublication(id) {
    return API.delete(`${API_URL_POSTS}/${id}`);
  }

  fetchPublication(id) {
    this.cancelToken = CancelToken.source();
    const cancelToken = this.cancelToken.token;
    return API.get(`${API_URL_POSTS}/${id}`, { cancelToken }).then(getData);
  }

  cancelFetchPublication() {
    this.cancelToken.cancel('Отмена запроса публикации');
  }

  save({ data, hashes }) {
    return API.patch(`${API_URL_POSTS}/${this.id}`, { data, hashes });
  }

  clearCache() {
    return API.delete(`${API_URL_POSTS}/${this.id}/cache`);
  }

  publish(id) {
    return API.post(`${API_URL_POSTS}/${id}/publish`).then(getData);
  }

  toDraft(id) {
    return API.post(`${API_URL_POSTS}/${id}/draft`).then(getData);
  }

  unpublish(id) {
    return API.post(`${API_URL_POSTS}/${id}/unpublish`).then(getData);
  }

  postpone({ id, publicationDate }) {
    return API.post(`${API_URL_POSTS}/${id}/postpone`, { data: { publicationDate } });
  }

  preview(id) {
    return API.get(`${API_URL_POSTS}/${id}/preview`);
  }

  approve({ id, approvedBy }) {
    const key = Object.keys(approvedBy)[0];
    return API.post(`${API_URL_POSTS}/${id}/approve`, { data: { approvedBy: key } });
  }

  createBlock({ groupId, before, locked, data: { type, data } }) {
    const content = data != null ? JSON.stringify(data) : undefined;

    return API.post(
      `${API_URL_POSTS}/${this.id}${
        groupId ? `${API_URL_GROUP_BLOCKS}/${groupId}` : ''
      }${API_URL_BLOCKS}`,
      {
        data: { type, content },
        meta: { before, locked },
      },
    ).then(getData);
  }

  createBlocks(blocks) {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/bulk`, {
      blocks: blocks.map(({ before, locked, data: { type, data } }) => ({
        data: { type, content: data != null ? JSON.stringify(data) : undefined },
        meta: { before, locked },
      })),
    }).then(getData);
  }

  saveBlocks(blocks) {
    return API.patch(`/posts/${this.id}/blocks`, { blocks });
  }

  deleteBlock(blockId, groupId) {
    return API.delete(
      `${API_URL_POSTS}/${this.id}${
        groupId ? `${API_URL_GROUP_BLOCKS}/${groupId}` : ''
      }${API_URL_BLOCKS}/${blockId}`,
    );
  }

  lockBlock(blockId) {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/${blockId}/lock`);
  }

  unlockBlock(blockId) {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/${blockId}/unlock`);
  }

  unlockAllMyBlocks = () => {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/unlockall`).then(getData);
  };

  moveBlock({ groupId, id: blockId = '', before }) {
    return API.patch(
      `${API_URL_POSTS}/${this.id}${
        groupId ? `${API_URL_GROUP_BLOCKS}/${groupId}` : ''
      }${API_URL_BLOCKS}/${blockId}`,
      {
        meta: { before },
      },
    );
  }

  createBlockGroup() {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_GROUP_BLOCKS}`).then(getData);
  }

  deleteBlockGroup(groupId) {
    return API.delete(`${API_URL_POSTS}/${this.id}${API_URL_GROUP_BLOCKS}/${groupId}`);
  }

  unlockAllMyBlock = () => {
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/unlockall`);
    // navigator не умеет в хедеры авторизации
    // return navigator.sendBeacon(
    //   `${process.env.API_ROOT}${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/unlockall`,
    // );
  };

  approveBlock = ({ id, approvedBy }) => {
    const [key] = Object.keys(approvedBy);
    return API.post(`${API_URL_POSTS}/${this.id}${API_URL_BLOCKS}/${id}/approve`, {
      data: { approvedBy: key },
    }).then(getData);
  };

  lockField = ({ field }) => {
    return API.post(`${API_URL_POSTS}/${this.id}/mainFields/${field}/lock`).then(getData);
  };

  lockSettings = () => {
    return API.post(`${API_URL_POSTS}/${this.id}/settings/lock`).then(getData);
  };

  unlockSettings = () => {
    return API.post(`${API_URL_POSTS}/${this.id}/settings/unlock`).then(getData);
  };

  unlockSettingsWithBeacon = () => {
    return navigator.sendBeacon(
      `${process.env.API_ROOT}${API_URL_POSTS}/${this.id}/settings/unlock`,
    );
  };

  unlockField = ({ field }) => {
    return API.post(`/posts/${this.id}/mainFields/${field}/unlock`).then(getData);
  };
}

export default new PublicationTransportLayer();
