import { get } from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { Modal } from '~components';
import { AuthorsStore } from '~store/AuthorsStore';
import { getFromStore } from '~utils';

import AuthorInfo from '../AuthorItem/TopInfo';
import Socials from '../Socials';
import styles from './styles.scss';

type IProps = FromStore<
  AuthorsStore,
  'isShowModalInfo' | 'setHideModalInfo' | 'editableAuthor' | 'resetEditableAuthor'
>;

@inject(
  getFromStore('AuthorsStore', [
    'isShowModalInfo',
    'setHideModalInfo',
    'editableAuthor',
    'resetEditableAuthor',
  ]),
)
@observer
class AuthorModalInfo extends Component<IProps> {
  render() {
    const { isShowModalInfo, setHideModalInfo, resetEditableAuthor, editableAuthor } = this.props;

    const socials = get(editableAuthor, 'socials');
    const bio = get(editableAuthor, 'bio');

    return (
      <Modal
        destroyOnClose
        width={770}
        onClose={setHideModalInfo}
        isVisible={isShowModalInfo}
        afterClose={resetEditableAuthor}
      >
        <div className={styles.root}>
          <div className={styles.header}>
            {editableAuthor && <AuthorInfo data={editableAuthor} />}
          </div>

          <div className={styles.content}>
            <h2 className={styles.title}>Биография автора</h2>
            <div className={styles.bio}>{bio}</div>
          </div>

          <Socials data={socials} />
        </div>
      </Modal>
    );
  }
}

export default AuthorModalInfo;
