import { Checkbox, Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { flatten, map, pipe, pluck, propOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { isAllTruthy } from '~utils';

import styles from './styles.module.scss';

const rightsObjects = [
  {
    rights: [
      {
        name: 'Публикации',
        path: 'posts',
      },
      {
        name: 'Опросы',
        path: 'quiz',
      },
      {
        name: 'Видео-трансляции',
        path: 'videos',
      },
      {
        name: 'Текстовые трансляции',
        path: 'textTranslations',
      },
      {
        name: 'Съемки',
        path: 'events',
      },
    ],
  },
  {
    group: 'Справочники',
    rights: [
      {
        name: 'Потоки',
        path: 'flows',
      },
      {
        name: 'Сотрудники',
        path: 'employees',
      },
      {
        name: 'Авторы',
        path: 'authors',
      },
      {
        name: 'Отделы',
        path: 'departments',
      },
      {
        name: 'Теги',
        path: 'tags',
      },
      {
        name: 'Регионы',
        path: 'regions',
      },
      {
        name: 'Запрещенные слова',
        path: 'badWords',
      },
      {
        name: 'Категории',
        path: 'categories',
      },
      {
        name: 'Подкатегории',
        path: 'subcategories',
      },
      {
        name: 'Разделы',
        path: 'sections',
      },
      {
        name: 'Рубрики',
        path: 'headings',
      },
      {
        name: 'Превью для хедера',
        path: 'headerPreviews',
      },
    ],
  },
  {
    group: 'Настройки',
    rights: [
      {
        name: 'Соцсети',
        path: 'socials',
      },
      {
        name: 'E-mail адреса',
        path: 'emails',
      },
      {
        name: 'Инфостраницы',
        path: 'infoPages',
      },
      {
        name: 'Плейлисты',
        path: 'playlists',
      },
      {
        name: 'Спецпроекты',
        path: 'specProjects',
      },
    ],
  },
];

const actions = ['view', 'update', 'create', 'delete'];

const getAllPaths = pipe(map(propOr([], 'rights')), flatten, map(propOr('', 'path')));

const RowRight = ({ name, path }) => {
  const { control, watch, setValue } = useFormContext();
  const [selectedAll, setSelectedAll] = useState(false);

  const onCheckAll = path => ({ currentTarget }) => {
    actions.forEach(action => {
      setValue(`rights.${path}.${action}`, currentTarget.checked);
    });
  };

  const values = watch(actions.map(action => `rights.${path}.${action}`));

  useEffect(() => {
    setSelectedAll(isAllTruthy(Object.values(values)));
  }, [values]);

  return (
    <Row justify="space-between" className={styles.row}>
      <Col span={9}>{name}</Col>
      {actions.map(action => (
        <Col span={3} className={styles.center} key={action}>
          <Controller
            name={`rights.${path}.${action}`}
            control={control}
            render={field => (
              <Checkbox
                {...field}
                onChange={e => field.onChange(e.target.checked)}
                checked={field.value}
              />
            )}
          />
        </Col>
      ))}
      <Col span={3} className={styles.center}>
        <Checkbox onClick={onCheckAll(path)} checked={selectedAll} />
      </Col>
    </Row>
  );
};

export const RightsObjects = () => {
  const { setValue, watch } = useFormContext();
  const [allView, setAllView] = useState(false);
  const [allUpdate, setAllUpdate] = useState(false);
  const [allCreate, setAllCreate] = useState(false);
  const [allDelete, setAllDelete] = useState(false);

  const onAllClick = action => ({
    currentTarget: { checked },
  }: React.MouseEvent<HTMLInputElement>) => {
    rightsObjects.forEach(({ rights }) => {
      rights.forEach(({ path }) => {
        setValue(`rights.${path}.${action}`, checked);
      });
    });
  };

  const values = watch();

  useEffect(() => {
    const { rights = {} } = values || {};

    const allPaths = getAllPaths(rightsObjects);

    const data = Object.entries(rights as Record<string, Record<string, boolean>>)
      .filter(([key]) => allPaths.includes(key))
      .map(([, value]) => value);

    setAllView(isAllTruthy(pluck('view', data)));
    setAllUpdate(isAllTruthy(pluck('update', data)));
    setAllCreate(isAllTruthy(pluck('create', data)));
    setAllDelete(isAllTruthy(pluck('delete', data)));
  }, [values]);

  return (
    <>
      <Row gutter={[20, 10]}>
        <Col span={24} className={styles.activeAll}>
          Применить ко всем:
        </Col>
        <Col>
          <Checkbox onClick={onAllClick('view')} checked={allView} /> Чтение
        </Col>
        <Col>
          <Checkbox onClick={onAllClick('update')} checked={allUpdate} /> Редактирование
        </Col>
        <Col>
          <Checkbox onClick={onAllClick('create')} checked={allCreate} /> Создание
        </Col>
        <Col>
          <Checkbox onClick={onAllClick('delete')} checked={allDelete} /> Удаление
        </Col>
      </Row>
      <Row justify="space-between" className={classNames(styles.row, styles.header)}>
        <Col span={9}>Объект</Col>
        <Col span={3} className={styles.center}>
          Чтение
        </Col>
        <Col span={3} className={styles.center}>
          Редактирование
        </Col>
        <Col span={3} className={styles.center}>
          Создание
        </Col>
        <Col span={3} className={styles.center}>
          Удаление
        </Col>
        <Col span={3} className={styles.center}>
          Полный доступ
        </Col>
      </Row>
      {rightsObjects.map(({ group, rights }, index) => (
        <>
          {group && (
            <Row className={styles.row}>
              <Col span={24}>
                <Typography.Text strong>{group}</Typography.Text>
              </Col>
            </Row>
          )}
          {rights.map(({ name, path }, index) => (
            <RowRight name={name} path={path} key={index} />
          ))}
        </>
      ))}
    </>
  );
};
