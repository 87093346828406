import { observer } from 'mobx-react';
import React, { ComponentType, useCallback } from 'react';

import { ISelectProps, ItemWithLabel } from '~components';
import { useFilterParamsByListStore } from '~hooks';

export const withFilterSelect = (Component: ComponentType<ISelectProps<any>>, { label, name }) =>
  observer(props => {
    const { setFilterValue, filterParams } = useFilterParamsByListStore();

    const onChange = useCallback(
      value => {
        setFilterValue(name, value);
      },
      [setFilterValue],
    );

    return (
      <ItemWithLabel label={label}>
        <Component {...props} value={filterParams[name]} onChange={onChange} />
      </ItemWithLabel>
    );
  });
