import { string, object } from 'yup'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const seoSchema = object()
  .default(undefined)
  .shape({
    description: string(),
    title: string()
  })

export const isValidSeo = isValidBySchema(seoSchema)
