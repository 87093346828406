import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import ContentWrapper from '~components/ContentWrapper';
import { VideoList, VideoFilter } from '~components/Videos';
import HeaderPageSearchBar from '~components/HeaderPageSearchBar';
import InfinityScroll from '~components/InfinityScroll';
import Sorting from '~components/Sorting';
import FilterToggleControl from '~components/FilterToggleControl';

const PAGE_TITLE = 'Видеотрансляции';
const COUNT_DECLINATIONS = ['трансляция', 'трансляции', 'трансляций'];
const SEARCH_BTN_TEXT = 'Найти в трансляциях';
const CREATE_BTN_TEXT = 'Создать трансляцию';

const SORTING = [
  {
    label: 'По дате создания',
    type: 'createdAt',
  },
  {
    label: 'По названию',
    type: 'title',
  },
];

@inject('VideosStore', 'FilterParamsByListStore', 'FetchPaginationStore', 'UserStore')
@observer
class VideosPage extends Component {
  componentWillUnmount() {
    const { FilterParamsByListStore, FetchPaginationStore } = this.props;

    FilterParamsByListStore.setFilterParams();
    FetchPaginationStore.resetPaginationParams();
  }

  handleSearchPublications = () => {
    const { fetchVideos, setIsFirstLoading } = this.props.VideosStore;
    const { resetPaginationParams } = this.props.FetchPaginationStore;

    setIsFirstLoading(true);
    resetPaginationParams();
    fetchVideos();
  };

  handleCreate = () => {
    this.props.history.push(`${this.props.match.path}/new`);
  };

  render() {
    const { VideosStore, UserStore } = this.props;
    const {
      isLoadingMore,
      isFirstLoading,
      allItemsCount,
      fetchVideos,
      videosLength,
      isShowFilter,
      toggleIsShowFilter,
    } = VideosStore;
    const { checkPermissions } = UserStore;

    return (
      <Fragment>
        <HeaderPageSearchBar
          searchButtonText={SEARCH_BTN_TEXT}
          createButtonText={CREATE_BTN_TEXT}
          onCreateElement={checkPermissions('videos.create') && this.handleCreate}
          onSearch={this.handleSearchPublications}
        />
        <ContentWrapper
          title={PAGE_TITLE}
          countItems={allItemsCount}
          countDeclinations={COUNT_DECLINATIONS}
          rightContent={
            <FilterToggleControl onClick={toggleIsShowFilter} isActive={isShowFilter} />
          }
        >
          {isShowFilter && <VideoFilter />}

          <Sorting onSort={this.handleSearchPublications} data={SORTING} />

          <InfinityScroll
            loadItems={fetchVideos}
            isEndLoading={allItemsCount <= videosLength}
            isFirstLoading={isFirstLoading}
            isLoadMore={isLoadingMore}
          >
            <VideoList />
          </InfinityScroll>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default withRouter(VideosPage);

VideosPage.propTypes = {
  FetchPaginationStore: PropTypes.any,
  FilterParamsByListStore: PropTypes.any,
  VideosStore: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
};
