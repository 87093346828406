import React, { ComponentType, ReactNode } from 'react';

import { IApprover } from '~schemas/approver';

import { IUser } from './user';

export enum BlockType {
  AudioBlock = 'AUDIO',
  ClickbaitBlock = 'CLICKBAIT',
  CoverBlock = 'COVER',
  CustomBlock = 'CUSTOM',
  FlowBlock = 'FLOW',
  GalleryBlock = 'GALLERY',
  HeadingBlock = 'HEADING',
  ImageBlock = 'IMAGE',
  IncutBlock = 'INCUT',
  NewsBlock = 'NEWS',
  QuizBlock = 'QUIZ',
  QuoteBlock = 'QUOTE',
  SpecialTitleBlock = 'SPECIAL_TITLE',
  StreamBlock = 'STREAM',
  SubtitleBlock = 'SUBTITLE',
  TextBlock = 'TEXT',
  TextDescription = 'TEXT_DESCRIPTION',
  TitleBlock = 'TITLE',
  VideoBlock = 'VIDEO',
  WidgetBlock = 'WIDGET',
}

export const NonValidatableBlocks = ['WIDGET', 'FLOW', 'NEWS', 'INCUT', 'STREAM'];

export interface IBlockProps {
  blocked?: boolean;
  classNames?: {
    block?: string;
    content?: string;
    contentWrapper?: string;
    footer?: string;
    head?: string;
  };
  footer?: ComponentType | boolean;
  header?: ComponentType | string | JSX.Element | null;
  id?: string;
  isEditing?: boolean;
  isFetching?: boolean;
  isLimitedByMaxSize?: boolean;
  lockedBy?: IUser<'full'>;
  maxSize?: string;
  onKeyDown?(event: React.KeyboardEvent, id?: string): void;
  postResourceType?: string;
  publicationStatus?: string;
  readOnly?: boolean;
  title?: ReactNode;
  type?: string;
  uploadPlaceholder?: string;
  uploadUrl?: string;
  validatedAt?: {
    corrector: string;
  };
  validatedBy?: {
    corrector: IApprover;
  };
  withCopyId?: boolean;
  withViewChanges?: boolean;
  wrapperClassname?: string;
}

export interface IBlockComponent {
  blockProps: IBlockProps;
  component: string | ComponentType;
  icon?: any;
  initialProps?: any;
  type: BlockType;
}
