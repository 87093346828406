import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import DropDownItem from './dropDownItem';

import styles from './styles.scss';

@inject(stores => {
  const { filtersItem, setFilterItemActive, selectedFilterItems } = stores.PublicationsStore;

  return {
    filtersItem,
    setFilterItemActive,
    selectedFilterItems,
  };
})
@observer
class FilterDropDown extends Component {
  render() {
    const { filtersItem, setFilterItemActive, selectedFilterItems } = this.props;

    return (
      <div className={styles.root}>
        {filtersItem
          .filter(item => !item.fix)
          .map(
            (item, index) =>
              !selectedFilterItems.find(filterItem => filterItem.name === item.name) && (
                <DropDownItem key={index} item={item} onClick={setFilterItemActive} />
              ),
          )}
      </div>
    );
  }
}

export default FilterDropDown;

FilterDropDown.propTypes = {
  filtersItem: PropTypes.object,
  setFilterItemActive: PropTypes.func,
  selectedFilterItems: PropTypes.array,
};
