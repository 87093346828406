import { Col, Row, Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { SettingsNavigation } from '~components';
import { usePushStore, useUserStore } from '~hooks';

import classes from './styles.scss';

const { Option } = Select;
const { Text, Title } = Typography;

const PAGE_TITLE = 'Уведомления';

const SettingsPushPage = observer(() => {
  const {
    getPush,
    fetchDictionaries,
    updatePush,
    webPush,
    mobilePush,
    dictionaries,
  } = usePushStore();
  const { checkPermissions } = useUserStore();

  useEffect(() => {
    fetchDictionaries();
    getPush();
  }, []);

  const onChange = useCallback(
    name => value => {
      updatePush({
        web: webPush,
        mobile: mobilePush,
        [name]: value,
      });
    },
    [mobilePush, updatePush, webPush],
  );

  return (
    <>
      <SettingsNavigation />
      <Row style={{ marginTop: '48px' }}>
        <Col>
          <Title>{PAGE_TITLE}</Title>
        </Col>
      </Row>
      <div className={classes.wrapper}>
        <Row align="middle">
          <Col span={5}>
            <Text>Браузер</Text>
          </Col>
          <Col span={7}>
            <Select
              style={{
                width: '400px',
                paddingLeft: '8px',
              }}
              optionFilterProp="children"
              onChange={onChange('web')}
              value={webPush}
              placeholder="Выбрать"
              disabled={!checkPermissions('push.update')}
            >
              {dictionaries.map(option => {
                return <Option value={option.id}>{option.ru}</Option>;
              })}
            </Select>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={5}>
            <Text>Мобильные приложения</Text>
          </Col>
          <Col span={7}>
            <Select
              style={{
                width: '400px',
                paddingLeft: '8px',
              }}
              optionFilterProp="children"
              onChange={onChange('mobile')}
              value={mobilePush}
              placeholder="Выбрать"
              disabled
            >
              {dictionaries.map(option => {
                return <Option value={option.id}>{option.ru}</Option>;
              })}
            </Select>
          </Col>
        </Row>
      </div>
    </>
  );
});

export default SettingsPushPage;
