import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';
import { createModal } from 'react-modal-promise';

import { IExperiment, InjectedModalProps } from '~common';
import { DeleteButton, ItemWithLabel, Modal } from '~components';
import s from '~containers/Subcategory/SubcategoriesModal/styles.scss';
import { useUserStore } from '~hooks';
import { useExperimentsStore } from '~hooks/useExperimentsStore';

const CREATE_TITLE = 'Создание нового эксперимента';
const UPDATE_TITLE = 'Редактирование эксперимента';

const ExperimentsModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close } = props;

  const {
    editableExperiment,
    resetEditableExperiment,
    createExperiment,
    isActionModal,
    updateExperimentById,
    deleteExperimentById,
  } = useExperimentsStore();
  const { checkPermissions } = useUserStore();

  const [form] = Form.useForm<IExperiment>();

  const onClose = useCallback((result: any = []) => {
    close && close(result);
  }, []);

  const handleClose = useCallback(() => {
    resetEditableExperiment();
    form.resetFields();
    onClose(true);
  }, [form]);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const experiment = {
        data: {
          ...values,
        },
      };
      if (editableExperiment) {
        updateExperimentById(experiment).then(handleClose);
      } else {
        createExperiment(experiment).then(handleClose);
      }
    });
  }, [editableExperiment]);

  const handleDelete = () => {
    deleteExperimentById().then(handleClose);
  };

  const ModalTitle = editableExperiment ? UPDATE_TITLE : CREATE_TITLE;

  return (
    <Modal
      destroyOnClose
      isVisible={isOpen}
      onClose={handleClose}
      title={ModalTitle}
      maskClosable={false}
    >
      <Form form={form}>
        <ItemWithLabel label="Название эксперимента">
          <Form.Item
            name="name"
            initialValue={get(editableExperiment, 'name')}
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите название эксперимента" />
          </Form.Item>
        </ItemWithLabel>
        <ItemWithLabel label="Описание">
          <Form.Item name="description" initialValue={get(editableExperiment, 'description')}>
            <Input.TextArea
              placeholder="Введите описание"
              disabled={isActionModal}
              showCount
              maxLength={350}
            />
          </Form.Item>
        </ItemWithLabel>
        <ItemWithLabel label="Переменные эксперимента">
          <Form.List
            name="variables"
            initialValue={get(editableExperiment, 'variables')}
            rules={[
              {
                // eslint-disable-next-line consistent-return
                validator: async (_, variables) => {
                  if (!variables || variables.length < 1) {
                    return Promise.reject(new Error('Добавьте хотя бы одну переменную'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} className={s.listElem} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={
                        key === 0
                          ? [{ required: true, message: 'Заполните переменную' }]
                          : undefined
                      }
                    >
                      <Input placeholder="Имя переменной" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'linkedValue']}>
                      <Input placeholder="Значение переменной" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                {fields.length < 5 && (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Добавить переменную
                    </Button>
                  </Form.Item>
                )}
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </ItemWithLabel>
        <div className={s.modalControls}>
          <Button
            onClick={handleSubmit}
            type="primary"
            loading={isActionModal}
            disabled={!checkPermissions('experiments.update')}
          >
            Сохранить изменения
          </Button>
          {editableExperiment && (
            <div className={s.deleteBtnWrapper}>
              <DeleteButton
                text="Удалить эксперимент"
                onDelete={handleDelete}
                disabled={!checkPermissions('experiments.update')}
              />
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
});

export const openExperimentsModal = createModal(ExperimentsModal);
