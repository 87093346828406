import { List, Switch } from 'antd';
import React, { useCallback, useState } from 'react';

import styles from './styles.scss';

interface IProps<T = string> {
  checked: boolean;
  disabled?: boolean;
  onClick(checked: boolean, value: T): void;
  title: string;
  value: T;
}

const FlagItem: React.FC<IProps> = props => {
  const [isFetching, setIsFetching] = useState(false);
  const { value, onClick, title, checked, disabled } = props;

  const onChange = useCallback(
    async checked => {
      try {
        setIsFetching(true);
        await onClick(checked, value);
      } catch (e) {
        console.error(e);
      } finally {
        setIsFetching(false);
      }
    },
    [onClick, value],
  );

  return (
    <List.Item>
      <div className={styles.wrapper}>
        <h3>{title}</h3>
        <Switch checked={checked} onChange={onChange} loading={isFetching} disabled={disabled} />
      </div>
    </List.Item>
  );
};

export default React.memo(FlagItem);
