import { string, object, number, array } from 'yup'
import { EMPTY_STRING } from '~constants'
import { mediaSchema } from '~schemas'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const compiledFileSchema = object()
  .default({
    _id: EMPTY_STRING,
    alt: EMPTY_STRING,
    url: EMPTY_STRING
  })
  .shape({
    _id: string().required(),
    createdAt: string(),
    hash: string(),
    height: number(),
    index: number(),
    media: mediaSchema,
    mimeType: string(),
    name: string().required(),
    posts: array(string()),
    size: number(),
    storage: string(),
    updatedAt: string(),
    url: string()
      .url()
      .required(),
    width: number()
  })

export const isValidCompiledFile = isValidBySchema(compiledFileSchema)
