import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import AddNew from '~components/AddNew';
import GridWrapper from '~components/GridWrapper';
import ContentSpinner from '~components/ContentSpinner';
import SocialsItem from '~components/Socials/SocialsItem';

@inject('SocialsStore', 'UserStore')
@observer
class SocialsList extends Component {
  componentDidMount() {
    const {
      SocialsStore: { fetchSocials },
    } = this.props;

    fetchSocials();
  }

  render() {
    const {
      SocialsStore: { socials, setShowModal, isFirstLoading },
      UserStore: { checkPermissions },
    } = this.props;

    if (isFirstLoading) {
      return <ContentSpinner />;
    }

    return (
      <GridWrapper gridSize={3} maxItemWidth={285}>
        {socials && socials.map(social => <SocialsItem key={social.title} data={social} />)}
        {checkPermissions('socials.create') && <AddNew onClick={setShowModal} />}
      </GridWrapper>
    );
  }
}

SocialsList.propTypes = {
  SocialsStore: PropTypes.any,
};

export default SocialsList;
