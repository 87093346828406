import React, { useEffect } from 'react';
import SettingsNavigation from '~components/SettingsNavigation';
import ContentWrapper from '~components/ContentWrapper';
import { SpecProjectsModal, SpecProjectsList } from '~components/SpecProjects';
import { observer } from 'mobx-react';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { useFetchPaginationStore, useFilterParamsByListStore, useSpecProjectsStore } from '~hooks';
import { Tabs } from 'antd';

const PAGE_TITLE = 'Специальные проекты';

const { TabPane } = Tabs;

export const SettingsSpecProjectsPage = observer(() => {
  const { setFilterParams } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const {
    isFetching,
    fetchSpecProjects,
    setCurrentSpecTabFilter,
    specLength,
    itemsCount,
    initFilterParams,
    fetchDictionaries,
  } = useSpecProjectsStore();

  useEffect(() => {
    initFilterParams();
    fetchDictionaries();

    return () => {
      resetPaginationParams();
    };
  }, [fetchDictionaries, initFilterParams, resetPaginationParams]);

  const handleOnTabClick = key => {
    let filter = '';
    let section = '';

    setFilterParams();
    resetPaginationParams();

    switch (key) {
      case 'common':
        filter = 'filter.section=neq(SPECIAL_PROJECT_SHOT_CHECK)';
        section = 'SPECIAL_PROJECT_COMMON';
        break;
      case 'shot':
        filter = 'filter.section=SPECIAL_PROJECT_SHOT_CHECK';
        section = 'SPECIAL_PROJECT_SHOT_CHECK';
        break;
      default:
        break;
    }

    setCurrentSpecTabFilter(filter, section);
  };

  return (
    <>
      <SettingsNavigation />
      <Tabs
        type="card"
        destroyInactiveTabPane
        onTabClick={handleOnTabClick}
        defaultActiveKey="common"
        style={{ marginTop: '30px' }}
      >
        <TabPane tab="Общие спецпроекты" key="common">
          <ContentWrapper title={PAGE_TITLE}>
            <InfinityScroll
              isFetching={isFetching}
              fetchItems={async () => {
                await fetchSpecProjects('filter.section=neq(SPECIAL_PROJECT_SHOT_CHECK)');
              }}
              hasMore={itemsCount !== specLength}
              threshold={200}
              checkInterval={2500}
            >
              <SpecProjectsList />
            </InfinityScroll>
          </ContentWrapper>
        </TabPane>
        <TabPane tab="SHOT-проверка" key="shot">
          <ContentWrapper title={'SHOT-проверка'}>
            <InfinityScroll
              isFetching={isFetching}
              fetchItems={async () => {
                await fetchSpecProjects('filter.section=SPECIAL_PROJECT_SHOT_CHECK');
              }}
              hasMore={itemsCount !== specLength}
              threshold={200}
              checkInterval={2500}
            >
              <SpecProjectsList />
            </InfinityScroll>
          </ContentWrapper>
        </TabPane>
      </Tabs>
      <SpecProjectsModal />
    </>
  );
});
