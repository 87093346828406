import { observable, action, runInAction } from 'mobx'
import { merge } from 'lodash'
import API from '~api'

const SYMBOLS_URL = '/settings/symbols'

class SymbolsStore {
  @observable symbols = []

  @observable dictionaries = []

  @observable isFirstLoading = true

  @observable isActionModal = false

  @observable isShowModal = false

  @observable editableSymbol = null

  @action
  fetchDictionaries = () => {
    const promise = API.get('/dictionaries/post')

    return promise.then(res => {
      const { data } = res

      runInAction(() => {
        this.dictionaries = data.postTypes
      })
    })
  }

  @action
  fetchSymbols = () => {
    const fetchSymbolsPromise = API.get(SYMBOLS_URL)

    return fetchSymbolsPromise
      .then(res => {
        const { data } = res.data

        runInAction(() => {
          this.symbols = data
          this.isFirstLoading = false
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false
        })
      })
  }

  @action
  updateSymbol = symbol => {
    runInAction(() => {
      this.isActionModal = true
    })

    const symbolKey = Object.keys(symbol)[0]
    let currentSymbol = {}

    if (symbolKey.includes('POST_TYPE_')) {
      currentSymbol = {
        types: symbol
      }
    } else {
      currentSymbol = symbol
    }

    const updateSymbolPromise = API.patch(`${SYMBOLS_URL}`, {
      data: merge(this.symbols, currentSymbol)
    })

    return updateSymbolPromise
      .then(res => {
        const { data } = res.data

        this.setHideModal()

        runInAction(() => {
          this.isActionModal = false
          this.symbols = data
        })
      })
      .catch(() => {
        runInAction(() => {
          this.isActionModal = false
        })
      })
  }

  @action
  setEditableSymbol = value => {
    this.editableSymbol = value

    this.setShowModal()
  }

  @action
  resetEditableSymbol = () => {
    this.editableSymbol = null
  }

  @action
  setShowModal = () => {
    this.isShowModal = true
  }

  @action
  setHideModal = () => {
    this.isShowModal = false
  }
}

export default new SymbolsStore()
