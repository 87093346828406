import React from 'react';

import { IFile } from '~common';
import { AttachFile } from '~icons';

import styles from './styles.scss';

interface IProps {
  file: IFile;
}

const AttachmentFileLink: React.FC<IProps> = ({ file }) => (
  <a className={styles.root} href={file.url} target="_blank" rel="noopener noreferrer">
    <AttachFile className={styles.icon} />
    <span className={styles.filename}>{file.name}</span>
  </a>
);

export default AttachmentFileLink;
