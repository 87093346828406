import { get } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateUtils, RangeModifier } from 'react-day-picker';

import { ItemWithLabel, SideCalendar } from '~components';
import { modifiers } from '~components/Schedule/CalendarView/SideCalendar/index.js';
import { useCommentsStore, useFetchPaginationStore, useFilterParamsByListStore } from '~hooks';

import SelectRender from './SelectRender';
import styles from './styles.scss';

const filterStatusOptions = {
  label: 'Статус',
  type: 'select',
  name: 'filter.status',
};

export const CommentsFilter = observer(() => {
  const { filterParams, setFilterValue } = useFilterParamsByListStore();
  const { resetPaginationParams } = useFetchPaginationStore();
  const { fetchComments } = useCommentsStore();
  const [filterStatus, setFilterStatus] = useState(filterParams[filterStatusOptions.name]);

  useEffect(() => {
    setFilterStatus(filterParams[filterStatusOptions.name]);
  }, [filterParams]);

  const handleSelectChange = useCallback(
    value => {
      if (value === 'COMMENT_STATUS_ALL') {
        setFilterValue(filterStatusOptions.name, null);
      } else {
        setFilterValue(filterStatusOptions.name, value);
      }
      resetPaginationParams();
      fetchComments();
      setFilterStatus(value);
    },
    [fetchComments, resetPaginationParams, setFilterValue],
  );

  const { start, end } = useMemo(() => {
    const dateArray = get(filterParams, 'filter.createdAt', '').split(',');

    const startDate = get(dateArray, '0', undefined);
    const endDate = get(dateArray, '1', undefined);

    return {
      start: startDate ? new Date(startDate) : undefined,
      end: endDate ? new Date(endDate) : undefined,
    };
  }, [filterParams['filter.createdAt']]);

  const handleDayClick = useCallback(
    day => {
      const dateArray = get(filterParams, 'filter.createdAt', '').split(',');

      const startDate = get(dateArray, '0', undefined);
      const endDate = get(dateArray, '1', undefined);

      const range = DateUtils.addDayToRange(day, {
        from: startDate ? new Date(startDate) : undefined,
        to: endDate ? new Date(endDate) : undefined,
      } as RangeModifier);

      if (range.from) {
        let formatDateByFilter = `${moment(range.from).format('YYYY-MM-DD')}`;

        if (range.to) {
          formatDateByFilter = `${formatDateByFilter},${moment(range.to).format('YYYY-MM-DD')}`;
        }
        setFilterValue('filter.createdAt', formatDateByFilter);
      } else {
        setFilterValue('filter.createdAt', null);
      }

      resetPaginationParams();
      fetchComments();
    },
    [fetchComments, filterParams, resetPaginationParams, setFilterValue],
  );

  return (
    <>
      <div className={styles.select}>
        <ItemWithLabel label={filterStatusOptions.label}>
          <SelectRender
            item={filterStatusOptions}
            value={filterStatus}
            onChange={handleSelectChange}
          />
        </ItemWithLabel>
      </div>
      <div className={styles.calendar}>
        <SideCalendar
          className="Range"
          selectedDays={[start, { from: start, to: end }]}
          modifiers={{ modifiers, start, end }}
          onDayClick={handleDayClick}
        />
      </div>
    </>
  );
});
