import { Avatar } from 'antd';
import cn from 'classnames';
import React from 'react';

import { makeAcronym } from '~utils';

import styles from './styles.scss';

interface IProps {
  avatarWrapperClassName?: string;
  infoWrapperClassName?: string;
  onlyAvatar?: boolean;
  person?: IPerson;
  wrapperClassName?: string;
}

interface IPerson {
  avatar: string;
  name: string;
  role: {
    name: string;
  };
}

const User: React.FC<IProps> = props => {
  const {
    wrapperClassName = '',
    avatarWrapperClassName = '',
    infoWrapperClassName = '',
    onlyAvatar = false,
    person = {
      avatar: '',
      name: 'Name',
      role: {
        name: 'position',
      },
    },
  } = props;

  if (person == null) return null;

  return (
    <div className={cn(styles.userWrapper, wrapperClassName)}>
      <div className={cn(styles.avatar, avatarWrapperClassName)}>
        <Avatar size={33} src={person?.avatar}>
          {makeAcronym(person?.name)}
        </Avatar>
      </div>

      {!onlyAvatar && (
        <div className={cn(infoWrapperClassName)}>
          <div className={styles.name}>{person?.name}</div>
          <div className={styles.position}>{person?.role?.name}</div>
        </div>
      )}
    </div>
  );
};

export default User;
