import { string, object } from 'yup'
import { EMPTY_STRING } from '~constants'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const statusSchema = object()
  .default(undefined)
  .shape({
    color: string().default(EMPTY_STRING),
    id: string().default(EMPTY_STRING),
    ru: string().default(EMPTY_STRING)
  })

export const isValidStatus = isValidBySchema(statusSchema)
