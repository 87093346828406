export const TOOLBAR_BUTTONS = {
  SUB: 'SUB',
  SUP: 'SUP',
  LINK: 'LINK',
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  UNDERLINE: 'UNDERLINE',
  ORDERED_LIST: 'ORDERED_LIST',
  COLOR_PICKER: 'COLOR_PICKER',
  UNORDERED_LIST: 'UNORDERED_LIST',
  STRIKE_THROUGH: 'STRIKE_THROUGH',
  ALIGNMENT_PICKER: 'ALIGNMENT_PICKER',
};

export const BUTTON_TOOLTIPS = {
  BOLD: 'Ctrl+b / Cmd+b',
  ITALIC: 'Ctrl+i / Cmd+i',
  UNDERLINE: 'Ctrl+u / Cmd+u',
  STRIKE_THROUGH: 'Ctrl+Shift+x / Cmd+Shift+x',
};

export const disabledButtonsInStandardInput = [
  TOOLBAR_BUTTONS.STRIKE_THROUGH,
  TOOLBAR_BUTTONS.UNORDERED_LIST,
  TOOLBAR_BUTTONS.ORDERED_LIST,
  TOOLBAR_BUTTONS.SUB,
  TOOLBAR_BUTTONS.SUP,
  TOOLBAR_BUTTONS.ALIGNMENT_PICKER,
  TOOLBAR_BUTTONS.COLOR_PICKER,
];

export const disabledHotkeysInStandartInput = ['strikethrough'];
