import { observable, action, set } from 'mobx';

import FilterParamsByListStore from './filterParamsByList';
import { SORT_FLAGS } from '~constants/sort';
import { propOr, propEq } from 'ramda';

const getSortProp = propOr('', 'sort');

const getOptionData = option => {
  const orientation = option.slice(0, 1);
  const hasFlag = Object.values(SORT_FLAGS).includes(orientation);
  return {
    type: hasFlag ? option.slice(1) : option,
    value: hasFlag ? orientation : SORT_FLAGS.ASC,
  };
};

const getFormatedStringFromOptions = options => {
  return options
    .filter(propOr(false, 'value'))
    .map(option =>
      encodeURIComponent(`${option.value === SORT_FLAGS.ASC ? '' : SORT_FLAGS.DESC}${option.type}`),
    )
    .join(',');
};

export class SortingStore {
  @observable options = [];

  @action
  setOptions = data => {
    this.options = data;
    const { filterParams } = FilterParamsByListStore;
    const sortStringToArray = getSortProp(filterParams).split(',');
    sortStringToArray.forEach(option => {
      const { type, value } = getOptionData(option);

      const optionFromData = this.options.find(propEq('type', type));

      if (optionFromData) {
        set(optionFromData, {
          value: value,
        });
      }
    });
    this.massToggleOptions();
  };

  @action
  resetOptions = () => {
    this.options.forEach(item => {
      set(item, {
        value: null,
      });
    });
  };

  @action
  massToggleOptions = () => {
    const { setFilterValue } = FilterParamsByListStore;
    setFilterValue('sort', getFormatedStringFromOptions(this.options));
  };

  @action
  toggleOptions = (item, value) => {
    const { setFilterValue } = FilterParamsByListStore;

    const index = this.options.indexOf(item);

    const currentItem = this.options[index];

    set(currentItem, {
      // eslint-disable-next-line no-nested-ternary
      value:
        typeof value !== 'undefined'
          ? value
          : currentItem.value === SORT_FLAGS.DESC
          ? SORT_FLAGS.ASC
          : SORT_FLAGS.DESC,
    });
    setFilterValue('sort', getFormatedStringFromOptions(this.options));
  };
}

export default new SortingStore();
