import { string, object } from 'yup'
import { isValidBySchema } from '~utils/isValidBySchema'

/** @type object */
export const compiledTagSchema = object()
  .default(undefined)
  .shape({
    _id: string().required(),
    title: string(),
    alias: string()
  })

export const isValidCompiledTag = isValidBySchema(compiledTagSchema)
