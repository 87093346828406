import { observer } from 'mobx-react';
import React, { FC, ReactNode, useMemo } from 'react';

import { Forbidden } from '~components/Forbidden';
import { useUserStore } from '~hooks';
import { InjectStores } from '~store';
import { checkPermissions } from '~utils';

interface IProps extends InjectStores<'UserStore'> {
  authority?:
    | string
    | string[]
    | ((role: Record<string, Record<string, boolean>>) => boolean | Promise<boolean>)
    | Promise<string>;
  noMatch?: ReactNode;
  role?: string;
}

const Authorized: FC<IProps> = observer(props => {
  const { roleRights } = useUserStore();
  const { children = null, authority = [], noMatch = <Forbidden /> } = props;

  const dom = useMemo(() => checkPermissions(authority, roleRights, children, noMatch), [
    authority,
    roleRights,
    children,
    noMatch,
  ]);
  return <>{dom}</>;
});

export default Authorized;
